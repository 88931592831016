import { render, staticRenderFns } from "./privacy-protocol.vue?vue&type=template&id=8caae9c6&scoped=true"
import script from "./privacy-protocol.vue?vue&type=script&lang=js"
export * from "./privacy-protocol.vue?vue&type=script&lang=js"
import style0 from "./privacy-protocol.vue?vue&type=style&index=0&id=8caae9c6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8caae9c6",
  null
  
)

export default component.exports