<template>
  <div>
    <p
      style="
        font-size: 18pt;
        line-height: 150%;
        margin: 0pt 0pt 12pt;
        orphans: 0;
        text-align: center;
        widows: 0;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 18pt"
        >《银壹供应链平台应收账款电子凭证业务服务协议》</span
      >
    </p>
    <p
      style="
        font-size: 18pt;
        line-height: 150%;
        margin: 0pt 0pt 12pt;
        orphans: 0;
        text-align: center;
        widows: 0;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 18pt">（适用</span
      ><span style="font-family: 微软雅黑; font-size: 18pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 18pt">产品）</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        orphans: 0;
        text-align: center;
        widows: 0;
      "
    >
      <span
        style="
          color: #333333;
          font-family: 微软雅黑;
          font-size: 12pt;
          font-weight: bold;
        "
        >版本：V1.0</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        orphans: 0;
        text-align: center;
        widows: 0;
      "
    >
      <span
        style="
          color: #333333;
          font-family: 微软雅黑;
          font-size: 12pt;
          font-weight: bold;
        "
        >发布时间： 2023 年 6 月 19 日</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        orphans: 0;
        text-align: center;
        widows: 0;
      "
    >
      <span
        style="
          color: #333333;
          font-family: 微软雅黑;
          font-size: 12pt;
          font-weight: bold;
        "
        >生效时间： {{ date.year }} 年 {{ date.month }} 月 {{ date.day }} 日</span
      >
    </p>
    <p
      style="
        font-size: 18pt;
        line-height: 150%;
        margin: 0pt 0pt 12pt;
        orphans: 0;
        text-align: center;
        widows: 0;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 18pt">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 7.8pt;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">尊敬的用户：</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 7.8pt;
        orphans: 0;
        text-align: justify;
        text-indent: 21pt;
        widows: 0;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >欢迎阅读银壹供应链平台服务协议（下称“本协议”），</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >您应当在使用服务之前认真阅读本协议全部内容（特别是含有加粗字的条款）。</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">如</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >果您对本协议任何条款表示异议，您可以选择不使用银壹供应链平台的服务。点击“</span
      ><span
        style="
          color: #ff0000;
          font-family: 微软雅黑;
          font-size: 12pt;
          font-weight: bold;
        "
        >同意</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >”按钮，则意味着您已经全面、准确的理解了本协议内容，认同并自愿遵守本协议。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 7.8pt;
        orphans: 0;
        text-align: justify;
        text-indent: 21pt;
        widows: 0;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >本协议阐述之条款和条件适用于用户使用银壹供应链平台所提供的在线</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >服务和衍生的线下服务。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 7.8pt;
        orphans: 0;
        text-align: justify;
        text-indent: 21pt;
        widows: 0;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >一、定义</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >平台：是专业的供应链科技服务平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >（所涉域为http://prod.scm.gxyinyi.com/的中文网站及其客户端）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">。</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >平台网站的所有权及运营权归</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >广西银壹数字科技有限公司</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">所有。</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">2</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">用户</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">/会员</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">：</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >指同意并接受本协议，根据</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >的业务规则和指引注册并通过平台或其委托机构的认证审核，并依法具备民事权利能力和民事行为能力的自然人、法人或者其他组织。</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >不具备民事行为能力或者缺乏特定许可资质的用户与</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银壹供应链</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >平台签署的协议无效</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >。仅有通过认证审核的用户可使用平台提供的服务。若用户不符合资格，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >有权随时中止或终止注册、认证程序。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >3、业务规则和指引：指</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >平台发布的届时有效的各项业务规则、操作流程、通知通告、使用说明和指引等文件，以及其不时被更新、替换、补充、修订的版本。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >4、《应收账款电子凭证业务服务协议（适用</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >产品）》：指本协议，即已注册成为</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >平台用户，并选择在本平台开立和使用</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >功能时勾选、认可并签署的《银壹供应链平台应收账款电子凭证业务服务协议（适用</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >产品）》以及不时被更新、替换、补充、修订的版本。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >5、平台协议：指用户在使用</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">服务中，与</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">签署的以及利用</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >订立的各项协议。包括但不限于《</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">会员服务协议》、《</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >应收账款电子凭证业务服务协议》（适用不同产品）、《</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >应收账款电子凭证开单协议》、《</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >应收账款电子凭证转让协议》、《</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >收费服务协议》等其他相关协议以及其任何有效的修改或补充等。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >6、应收账款电子凭证：指用户成功开具的、显示商务合同项下买卖双方之间债权债务关系的一种可流转、可融资、可拆分、可兑付的电子付款承诺凭证（到期支付款项的承诺函）。成功开具的应收账款电子凭证，可作为</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >服务的交易基础。本平台根据客户需求提供多类型的应收账款电子凭证产品，包括且不限于“</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">”、“普惠通”。</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">7、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >：指本平台开发的的应收账款电子凭证（产品）之一，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >的具体操作规则以本协议及其他相关文本为准。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >8、开单人：指根据本协议，在</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">申请开立</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">并负有</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >偿付义务的债务人。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >9、持单人：指</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >的持有人，根据本协议签收</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >并享有</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >项下到期款项债权的权利人。持单人包括原始持单人以及其他根据本协议受让</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >认证用户。</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >持单人同意并认可，开单人有权委托银壹供应链平台或其他机构将</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >项下金额支付至持单人的结算账户；开单人或其委托的机构依据银壹供应链平台的清分指令向持单人支付</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >项下到期的应付账款后，即开单人已履行完毕</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >项下的付款义务。持单人同意并认可，由于银行间系统结算等原因，持单人实际收到款项的时间可能会晚于开单人承诺付款日，因此造成的延迟不构成违约，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >持单人不得向开单人、银壹供应链平台或其他机构追究责任。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >10、原始持单人：指根据本协议，在本平台确认签收开单人开立的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >的用户，为基础合同的卖方或服务提供方，也是</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">的第一手持有人。</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">11、基础交易：指</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >对应的原始持单人和开单人之间因销售商品、提供服务或其他贸易活动产生的债权债务关系。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">12、基础合同</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">/商务合同</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >：指由原始持单人与开单人基于基础交易而签订的货物销售合同、服务合同或其他交易文件，亦包括其补充协议和构成销售/服务合同组成的附件。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >13、资金方：指根据法律法规及本协议约定，为用户提供融资服务的商业资金方、银行或其他金融机构。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >4、核心企业：即银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >的开单人，其为产业链中被公认的优势企业，该企业为了巩固其产业链发展，优化产业、提升产业的竞争力等，承诺到期准时兑付在平台上流转的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">的债务人。</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">15、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >到期日/承诺付款日：指</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >记载的到期日，即开单人与原始持单人就基础合同项下的付款义务的履行协商一致约定的应收账款的到期日。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >6、担保人：为核心企业及其成员企业开立的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >项下兑付义务提供担保，承担连带付款责任的自然人、法人或其他组织。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >17、开单额度：指资金方评估核心企业及其成员企业的资信状况，为核心企业及其成员企业核定的开单最大限额（不得高于授信额度）、并进行开单额度动态管理，该额度在有效期内可循环使用。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >8、担保额度：指担保人为核心企业及其成员企业核定的，对其开立的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >承担连带付款责任（还款保证责任）的最大限额，该额度在有效期内可循环使用。如担保额度无法覆盖担保范围的，应以担保范围确认的金额为准。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >9、担保范围：指担保人为核心企业及其成员企业核定的，对其开立的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >承担连带付款责任（还款保证责任）的担保范围，包括且不限于开单人</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >项下应偿付款项及持单人为实现现实债权的一切费用（迟延支付利息、公证费、违约金、损害赔偿金、诉讼费、仲裁费、律师费等）。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >20、电子认证服务机构：指具有认证资格，接受</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">的委托，协助</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >办理用户认证并为认证通过的用户签发电子签名认证证书的服务机构，即杭州天谷信息科技有限公司（简称“e签宝”）。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >21、电子签名：指《中华人民共和国电子签名法》所规定的电子签名，是指数据电文中以电子形式所含、所附用于识别签名人身份并标明签名人认可其中内容的数据。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >22、电子签名认证证书、e签宝证书、数字证书：指《中华人民共和国电子签名法》、《电子认证服务</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">管理</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >办法》以及其他法律法规规定的电子签名认证证书。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >23、工作日：指除国家法定节假日和休息日以外的中国大陆地区商业银行正常营业日。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">24、日：指自然日。</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >&#xa0;</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >二</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >业务流程</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">的开立</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1.1开单人</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">在</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >依据相关规则所获分配的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >额度内，将根据应付账款的金额开立</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">。</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1.2</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"> </span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">及</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">流转凭证应包括开</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">单人</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">收单人</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">转单人、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">金额、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">到期日</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">等内容，其中：</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >的开立应具备真实的贸易背景；</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >金额应根据应付账款的金额确定，不高于应付账款金额</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">；</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">到期日</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">为开单人履行</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">偿付义务，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">持单人</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">获取</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">偿付款项的日期</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">。</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银壹供应链</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >平台认证用户可通过本平台，使用以下全部或部分服务。同时，用户理解并同意，本平台公示的信息是经第三方（包括但不限于阿里云发票验证、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >e签宝实名认证和电子签章等）核查的信息，仅供本平台用户参考，本平台用户有义务对信息及交易的真实、合法、准确、风险等进行再次查验、判断分析后自行选择平台提供的服务，并完全知悉平台无义务保证用户在提出</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >交易申请后能够实际达成交易的风险。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1.2.1</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"> </span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">开立</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >提请用户注意，有且仅有本平台认证用户有权使用本平台中的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">“</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">开立</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">”</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">服务。</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >开立成功，开单人作为负有</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >偿付义务的债务人，持单人作为享有</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >项下到期款项的权利人。成功开立的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >，可作为本平台其他服务的交易基础和承诺支付凭证。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1.2.2</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"> </span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">转让</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >持单人有权将其持有的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >，全部或部分作转让给具有真实贸易交易的上游供应商，用于支付其对上游供应商的应付账款。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1.2.3</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"> </span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">融资</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">持单人有权向资金方</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">申请融资</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">，具体</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">手续依照</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">相关业务规</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">则所</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">规定的业务流程</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">为准。</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1.2.4查询</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >本平台将对用户在平台上的所有操作进行记录，用户可以通过用户账户查询名下的本平台交易记录。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1.2.</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">5 </span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">兑付</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">开单人应当在</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">到期日向持单人支付</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">项下应收账款。</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">若</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >经多次转让的，开单人应根据平台记录的流转情况，向最终持单人支付该</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">项下的金额。</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">如资金方取得</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">，则开单人应按期</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">足额</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">向资金方支付</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">款项，开单人完成</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >支付的同时，资金方受让前手持单人</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">的对价相互抵消。</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1.2.</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">6 </span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">追偿</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">若开单人在</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >到期日未按期足额兑付的，持单人有权通过法律途径向开单人追偿，并要求其承担相应的违约责任。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >三、用户的权利和义务</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >、用户在遵守本协议的前提下，依法享有</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >提供的服务，本平台欢迎用户提出服务改进建议，并会以勤勉态度为用户提供服务。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">2</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >、用户应当按照本协议和本平台的有关管理规则提交注册信息，并保证注册信息及上传的相关信息的真实性和完整性。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">3</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">、用户在平台入驻</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">期间</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >，应充分利用其核心企业的产业链优势，在本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">上</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >归集并管理其上游供应商的应付账款。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >4</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >、用户应当妥善的保存自己的用户名和密码，不得以任何形式擅自转让或授权他人使用自己在</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >的用户帐号。用户对其在</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >注册的用户名和密码的安全性负全部责任，并对以其用户名进行的所有活动和事件负全部责任，用户若发现任何非法使用其账号或存在安全漏洞的情况，应立即通知</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >5</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >、用户保证通过</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >发布的信息是真实、合法的，用户发布的信息不能含有任何违反国家有关法律、法规及中华人民共和国承认或加入的国际条约的内容，包括但不限于危害国家安全、淫秽色情、虚假、侮辱、诽谤、恐吓或骚扰、蓄意毁坏、恶意干扰、秘密地截取或侵占任何系统、数据等侵犯他人知识产权、人身权或其他合法权益以及有违社会公序良俗的内容或指向这些内容的链接。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >6</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >、用户有权享有</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >提供信息交流的各种服务，在本平台进行包括但不限于信息发布、信息浏览、信息订阅等相关活动时，应当严格遵守</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >的相关管理规则。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >7</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >、用户通过</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >进行信息发布、信息浏览、信息订阅等活动必须符合相关法律法规规定，否则由此引起的一切法律纠纷均由用户自行承担，与</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >无关。因用户违反有关法律、法规或本协议的有关规定而给</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >或者第三方造成损失的，用户同意赔偿因此而导致的全部损失，包括但不限于实际损失、可预期利益、赔偿金、为实现债权而发生的诉讼费、律师费等费用。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >8</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >、用户在</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >实施了违法行为，导致第三方主张或投诉（包括但不限于第三方以发函等形式指出</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >侵权，对</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >提起诉讼、仲裁，或使</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >面临相关主管机关的审查或质询），</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >有权先暂停用户对</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >的使用。用户应在收到</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >通知后，应以自己名义出面与第三方协商、参与诉讼或接受相关主管机关审查或质询，并承担所有费用，并赔偿因此给</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >造成的全部损失。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >9</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >、用户不得通过任何方式攻击、干扰或试图攻击、干扰</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >的正常运作及其有关活动，也不得帮助或教唆任何第三方从事上述活动。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >&#xa0;</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >四、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >的权利义务</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >1</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >为开放平台，仅提供中立的平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >服务，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >为用户提供查看</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >、发布、处理交易信息</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >服务</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >具体以</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >平台当</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >时提供的服务内容</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >为</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >准</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >2</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >将对用户发布的内容进行审核，该审查为规范性、形式性审核，客观上无法保证用户发布内容的真实性、准确性、有效性和安全性。但在本平台实施管理活动以及第三方对用户发布信息进行投诉时，本平台会对用户提供的信息进行复核，并可能会要求用户提供相应证据来证明信息的真实性、准确性、完整性及合法性，在用户无法提供有效证明时，本平台有权删除相关信息或终止服务。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >3</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >为了更好的服务用户，用户同意并授权</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >及其关联机构对用户提供的资料（包括但不限于文字、图片、视频）拥有合理使用权。用户将前述资料提供给</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >，授权持续有效。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >4</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >有权随时删除含有任何危害国家安全、淫秽色情、虚假、侮辱、诽谤、恐吓或骚扰、侵犯他人知识产权或人身权或其他合法权益等违法或有违社会公序良俗的内容或指向这些内容链接的信息，并有权终止对发布前述信息的用户的服务。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >5</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >、为了更好的服务用户，用户同意，平台可通过公开或私人资料搜集用户的额外信息，以了解甲方的实际情况。用户同意，平台有权根据实际情况对用户的信息进行评价和披露，以供其他用户参考。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >6</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >、通过</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >服务存储或传送之任何信息、通讯资料和其他内容，如被删除或未予储存，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >毋须承担任何责任。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">7</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >视情况会决定是否提供与其他互联网网站或资源进行链接；用户可通过</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >进行互动（包括但不限于参与促销活动，进行相关商品或服务之付款及交付），</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >对于前述网站或资源是否可以利用或用户与前述推广商的自主互动而遭受的任何性质的损失或损害，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >不承担任何责任。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">8</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >、用户所从事的经营业务不得对</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >利益构成任何现实或潜在的损害或冲突，否则</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >有权通知用户立即终止服务，而无需承担其他法律责任和费用补偿。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">9</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >、为了用户能够识别判断身份信息、发票信息、电子签章信息等贸易活动信息的真实性，本平台存在与外部第三方（包括但不限于阿里云发票验证、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >e签宝实名认证和电子签章等）共同合作提供的服务，可能将链接至第三方网站或将用户数据传输至第三方。注册用户在同意使用该等服务时，即视为许可上述外部第三方使用其用户数据（包括但不限于用户上传于平台的所有资料、第三方通过公开途径查询而知的信息数据等）。</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台对该等第三方及其网站对用户数据的保护措施以及取得用户数据后的行为不负任何责任，用户需自行判断风险。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">10</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >对于系统发生故障影响到本服务的正常运行，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >承诺会及时处理进行修复。但用户因此而产生的经济和精神损失，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >不承担任何直接或间接的责任。</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">对于</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >有计划的系统维修、保养、升级，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >将会以网站公告的方式事先通知用户，升级期间，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >服务将不同程度受到影响，用户因此而产生的损失，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >不承担任何直接或间接的责任。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">11</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >用户完全理解并同意，通过</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >可能会收到推广信息及服务信息，凡因接收相应推广信息而与信息发布者或推广商发生交易行为而遭受的任何性质的损失或损害，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >均不予负责。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">12、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >平台拥有对本协议条款的修改权、更新权、解释权。用户对服务之任何部分或本协议条款的任何部分之意见及建议可通过平台公共邮箱</span
      ><span
        style="
          color: #ff0000;
          font-family: 微软雅黑;
          font-size: 12pt;
          font-weight: bold;
          text-decoration: underline;
        "
        >gxyinyi@sina.com</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">与</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">平台联系。</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">13、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >有权与其他第三方机构合作，调整平台的服务内容和服务模式，并在平台上公告，无需另行单独通知用户。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">14、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >享有法律法规以及平台协议约定的其他权利。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >五、陈述</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >和保证</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >1、用户承诺并保证，平台已就本协议及其有关交易文件的全部条款和内容向其进行了详细的解释，用户已认真阅读本协议的有关条款，对有关条款不存在任何疑问或异议，并对协议各方的权利、义务、责任与风险有清楚和准确的理解。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >2、用户承诺，其提供的所有资料均是真实、合法、有效的，开具的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >有真实的贸易背景，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >项下基础交易所对应的基础合同已妥善签署、合法、有效。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >3、用户承诺，其在本平台上因持有</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >而获得的应收账款债权，仅可通过本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">发起</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >线上转让或融资，受让方必须为本平台的认证用户</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">或</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">资金方。</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >4、用户保证，其持有的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >及银e券项下应收账款债权</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >禁止通过本平台以外的其他线上或线下方式、或者与不存在真实贸易背景的任何人进行任何转让、融资、质押或以其他方式处置全部或部分</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >权利，否则平台有权对其账户及其在本平台用户权利采取任何限制措施。</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >包括但不限于：</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >4.1断开链接；</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >4.2限制其使用，停止为其提供服务；</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >4.3冻结、注销用户账户等</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">5、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >用户承诺，作为应收账款的债务人，因其与供应商在</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >项下的基础交易而依法或根据合同约定取得的任何抗辩权，不得用于对抗本平台、资金方以及因</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">转让而持有</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">的其他用户。</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >&#xa0;</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >除上述陈述与保证外，核心企业还做出以下陈述和保证：</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（1） 核心企业承诺，作为</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >开单人及应收账款债务人，知道或应当知道以下事实之日起【5】日内，应及时通知银壹供应链平台，且平台有权按照银壹供应链平台的业务规则和指引的约定进一步处理：</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（a） 其所开具的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >或银e券项下应收账款债权</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >被通过银壹供应链平台以外的其他线上或线下方式进行任何形式的转让、质押或其他处置的；</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（b） 其所开具的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券或银e券项下应收账款债权</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >被转让、质押或处置给与</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >转让人不存在真实贸易背景的任何人，使得</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >项下全部或部分权利受到限制的；</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（c）
        其收到任何法院、仲裁机构或第三人的止付和/或扣划通知，以及其他就其所开具的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >项下应收账款主张任何权利的通知的；</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（d） 其所开具的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >项下应收账款发生争议或纠纷，或者基础合同被认定无效、被撤销、被解除，或者可能导致其付款义务发生任何变化的其他情形。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（2） 核心企业承诺，作为应收账款的债务人，因其与供应商在</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >项下的基础交易而依法或根据合同约定取得的任何抗辩权及因基础合同产生的任何纠纷，不得用于对抗银壹供应链平台以及因</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >转让而持有</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >的用户或影响其对</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >持有人的偿付。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（3）核心企业充分了解并承诺，不同于普通的债权转让，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >具有应收账款高效流转的特殊性质，核心企业开具</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >电子凭证的行为本身自动视为核心企业完全且无条件地认可因不断流转而产生的一切新债权人、新受让人、新持单人身份及任一笔应收账款转让事实的存在，无论该主体的企业名称、企业性质、资产规模、股东背景、企业文化、信用背景、商业行为等，也无论该转让基于融资、贸易活动等，核心企业负有及时接收</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >流转信息的义务，核心企业不得以任何理由（包括且不限于未收到通知、拒收、不认可新持单人身份）对抗平台以及因</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >转让而持有</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >的用户或影响其对</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >持有人的偿付。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（4） 核心企业同意并承诺，核心企业作为</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >开单人系应收账款的债务人，持单人、资金方、银壹供应链平台任一有权通过平台系统消息/邮件/短信方式等向</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >开单人发送债权转让通知，视为</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >转让人或融资申请人就</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >应收账款债权转让履行了通知债务人的义务，核心企业不得以其未收到债权转让通知为由拒绝向</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >持有人支付</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >所对应的应收账款。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（5） 核心企业同意并承诺，核心企业作为</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >开单人系应收账款的债务人，如停止使用平台服务也不影响其在此之前所承担的义务的履行，及核心企业对其在停止使用平台服务之前开具的所有</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >应按照本协议的约定进行偿付。在其开具的所有</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >得到足额偿付之前，其不得按照平台业务规则注销其用户账户、开立于银壹供应链平台结算账户下的资金登记簿、用户对公账户等。</span
      >
    </p>
    <p
      style="
        font-size: 10.5pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: Calibri; font-size: 10.5pt">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >六、保密</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >为保证本协议的全面履行，双方承诺就对方提供的所有资料中所包含的商业秘密和非公开信息严格保密，未经对方同意不得向第三方披露。无论本协议是否终止或解除，本条款在保密期限内持续有效。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >七、知识产权声明</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >独立拥有或与相关内容提供者共同拥有本平台内相关内容（包括但不限于网络域名、文字、图片、音频、视频资料、设计、商标、标识、网站构架、网站版面的安排、网页设计、软件和全部专有数据库等）的知识产权，包括但不限于著作权、商标权、专利权或其它专属权利等。未经</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >书面许可，任何人不得擅自使用。否则，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >将依法追究侵权人法律责任。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >&#xa0;</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >八、协议的变更、解除和终止</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">、变更</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >可以根据客观情况的变化随时进行业务性调整或产品性调整，并变更本协议，变更后的本协议将会在</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >上重新公布。用户可以选择继续使用调整后的业务或产品，但是如果用户对变更后的本协议持有不同意见，应在公布之日起</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >3日内提出并选择退出本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >，否则视为同意继续履行，对于退出的用户</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >对此不承担任何法律责任和费用补偿。如果用户继续选择享用</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >，则视为用户已经完全接受本协议及其修改的文本。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >2</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >解除和终止</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >1）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >如发生下列任何一种情形，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银壹供应链</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >平台有权随时中断或终止向用户提供服务而无需通知用户：</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >1</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >用户提供的个人资料不真实</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >、不准确、不完整</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >；</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >2</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >用户违反法律或者本协议及其修改文本的规定；</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >3</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >按照主管部门的要求；</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >4</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >用户注册后连续12个月未登录其在</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银壹供应链</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >平台的帐号；</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >5</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银壹供应链</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >平台发现用户恶意注册帐号；</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >6</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银壹供应链</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >平台收到第三方投诉；</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >7</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >同行业者通过注册账号等行为，获取</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >资金方</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >或项目方联系方式</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >等</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >窃取商业机密</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >；</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >8</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >其他</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银壹供应链</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >平台认为是</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >不</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >符合整体服务需求的情形。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">（</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >2）如因适用法律或政策发生变更导致本协议项下的业务部分或全部违反法律或外部监管政策，平台有权修改或终止本协议。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">3、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">用户</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >主动退出本协议后所产生的任何损失、纠纷均与平台无关，相应责任由会员自行承担。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">4、在</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">用户</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >违反了本协议或相关业务规则，或在相关法律法规、政府部门的要求下，平台有权单方面解除本协议、关闭会员的账户或者限制会员使用平台。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >5、本协议解除或终止后（不论因何原因解除或终止），对于在本协议解除或终止前已开出但尚未到期付款的</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">，开单人仍应按</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >内容承担偿付责任。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >&#xa0;</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >九、费用承担</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1、用户因使用</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >相关服务所产生的所有费用，包括但不限于融资利息、平台服务费，由用户自行承担，用户应按照平台业务规则和指引明确规定及相关协议支付相关费用，否则平台有权不提供相关服务。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >2、平台拥有制定、收取及调整服务费用的权利，具体服务费用以用户使用</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >服务时平台上所公告的收费方式或用户与平台达成的其他协议为准。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">3、用户在使用</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >服务过程中与第三方机构签订协议或达成一致时，第三方机构有权按照约定</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">向</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >用户收取费用，用户可能需要向第三方机构（如平台资金方或其他第三方服务机构）支付一定的费用，具体收费标准以用户使用</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >服务时，平台所公告的收费方式或用户与第三方机构达成的其他协议为准，详见第三方机构相关页面或平台的提示以及协议。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >4、用户因进行交易而发生的所有应纳税赋，以及相关硬件、软件、通讯、网络服务及其他方面的费用均由用户自行承担。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >十、违约责任</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >在本协议有效期内，如用户违反法律、法规或本协议中的任何内容，侵犯了</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >银壹供应链平台、平台运营方</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >、平台资金方、平台其他用户或其他第三方合法权利或造成</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">前</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >述主体的损失的，该用户应当依法承担赔偿责任。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >2、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >在本协议有效期内，如用户违反法律、法规或本协议中的任何内容，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >有权根据该用户的违约情况，对其采取限制或暂停使用平台服务、暂停或关闭用户账户等措施，有权向有关监管机构汇报该用户的违约情况。</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >采取上述措施，并不排除</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >依法追究该用户违约责任的权利。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        orphans: 0;
        text-align: justify;
        text-indent: 21pt;
        widows: 0;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >十一、法律适用及争议解决</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >、本协议的订立、生效、解释、执行、管辖、争议的解决均适用中华人民共和国法律。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">2</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >、因履行本协议发生的争议应通过协商解决，协商不成的，任何一方均有权将争议提交上海国际经济贸易仲裁委员会，按照提交仲裁时该会现行有效的仲裁规则进行仲裁，仲裁裁决是终局的，对各方均有约束力。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt"
        >3、当产生任何争议及任何争议正按前条规定进行解决时，除争议事项外，双方应继续行使本协议项下的其他权利，履行本协议项下的其他义务。如本协议中的任何条款被认定为全部或部分不合法、无效或不可强制执行，并不影响其他条款的有效和执行。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >十二、其他</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">1、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >本协议适用的通知方式。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >（1）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold">
        用户平台账号（含站内信、系统消息及即时信息等）、向平台提供的企业联系地址、企业联系电话、企业电子邮件地址，向平台提供的法人/经办人联系电话、电子邮箱，任一均视为用户的有效送达地址。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（2）用户应保证所提供的上述送达地址是准确、有效的，并进行实时更新。对于送达地址的变更，用户有义务及时更新相关信息，并保持可被联系的状态。因提供信息不正确或未及时变更导致通知无法及时接收的，由用户自行承担可能产生的法律后果。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（3）平台将向用户的上述有效送达地址的任一或若干发送各类通知，而此类通知的内容可能对用户的权利义务产生重大有利或不利影响，用户务必及时关注。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（4）银壹供应链平台对于用户的通知及任何其他的协议、告示或其他关于用户使用账户及服务的通知，以及人民法院、仲裁机构或调解机构向用户送达与应收账款电子凭证项下所有交易相关的法律文书（包括但不限于诉讼文书、仲裁文书、调解文书等），用户均不可撤销地同意并确认，平台及/或法院等相关机构可通过平台公告、站内信息、系统消息、电子邮件、手机短信或电子邮件地址或无线通讯装置等电子方式或常规的信件传递等方式发送至用户的任一有效送达地址，该等通知于发送之日视为已送达用户，用户拒收/未及时接收/未及时阅读/未及时登陆账户的，不影响送达的效力，用户如需变更送达地址，应当在信息变更后3个工作日内将变更后的送达地址通知平台及/或人民法院等机构，用户未按本条约定变更送达地址的，用户提供的原联系方式仍为有效送达地址。信息传输等原因导致用户未在前述通知发出当日收到该等通知的，平台不承担责任。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        orphans: 0;
        text-align: justify;
        text-indent: 21pt;
        widows: 0;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（5）用户对平台的通知应通过平台对外正式公布的通信地址或电子邮件地址等联系地址进行送达。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >2、免责声明：</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 10.5pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">（1）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银壹供应链平台不保证平台用户上传信息的真实性、充分性、可靠性、准确性、完整性和有效性，用户应依赖于其独立判断进行交易，并对其做出的判断承担全部责任。</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >网站所展示的信息由会员自行提供，内容的真实性、准确性和合法性由发布会员负责，平台会员据此进行交易的，产生的风险由</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">平台会</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">员自行</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">承担</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">，平台会员</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">无</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">权据此向本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">提</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >出任何法律主张，在交易过程中，</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">会员</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">与</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >平台其他注册会员之间发生的纠纷，由纠纷各方自行</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">协商</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">解决</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">。</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 10.5pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（2）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >用户在使用平台服务的过程中，银壹供应链平台对</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >项下应收账款、商务合同、平台协议等的真实性、合法性不承担审查义务。用户应当自行审查核实交易对手、应收账款、商务合同及平台协议等的真实性、合法性，并自行承担因此产生的任何纠纷或损失。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 10.5pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（3）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >对于因用户未按照本协议或平台的业务规则和指引进行操作等自身原因导致的任何损失或责任，银壹供应链平台不承担责任。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 10.5pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（4）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >用户</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >开具、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >转让、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >融资由当事各方按照业务规则和指引进行处理，本平台不对该等交易的促成进行任何承诺或保证，亦不对已达成的交易的风险或纠纷进行承诺或保证。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 10.5pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（5）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >如</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >项下的应收账款通过除本平台以外的其他线上或线下方式、或者与不存在真实贸易背景的任何人进行任何转让、融资、质押或进行其他处置的，本平台不承担该</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >项下应付款项的保证责任</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >或共同责任或其他责任。</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold">
      </span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 10.5pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >（6）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >如因用户提交的银行账号信息有误，或银行账号信息发生变更后未及时通知本平台的或按照业务规则和指引进行修改的，导致该用户在</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银e券</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >融资等业务项下资金入账延迟或无法入账的，由此产生的损失由该用户自行承担。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 10.5pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >（7）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >在任何情况下，对于用户在使用平台服务过程中涉及由第三方</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >（</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >包括电子认证服务机构）</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >提供相关服务的责任由该第三方承担，平台不承担该等责任。</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >银壹供应链平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >的内容可能涉及由第三方所有、控制或者运营的其他网站（“第三方网站”）的，如平台设置进入第三方网站的链接，用户应按照第三方网站的使用协议确定相关权利义务，银壹供应链平台不承担因用户使用该等链接而引起的任何责任。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 7.8pt 0pt;
        text-align: justify;
        text-indent: 21pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">（8）本平台</span
      ><span style="font-family: 微软雅黑; font-size: 12pt">未行使或执行</span
      ><span style="font-family: 微软雅黑; font-size: 12pt"
        >本服务协议任何权利或规定，不构成对前述权利或权益之放弃。</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: normal"
        >3、</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >友情提醒：注意融资风险，建议您在投融资时务必对他人公司的资质、实力进行详细调查了解，特别警惕各类前期费用！</span
      >
    </p>
    <p
      style="
        font-size: 12pt;
        line-height: 150%;
        margin: 0pt 0pt 10pt;
        text-align: justify;
        text-indent: 24.1pt;
      "
    >
      <span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >十三、本协议一经用户点击</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >“</span
      ><span
        style="
          color: #ff0000;
          font-family: 微软雅黑;
          font-size: 12pt;
          font-weight: bold;
        "
        >同意</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >”</span
      ><span style="font-family: 微软雅黑; font-size: 12pt; font-weight: bold"
        >按钮后，立即生效。</span
      >
    </p>
  </div>
</template>
<script>
export default {
  data() {
    const date = new Date();
    return {
      date: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      }
    }
  }
}
</script>