<template>
<layout-main-view :breadcrumb='breadcrumblist'>
    <div class="common-page-dataList">
        <div class="open-account">

            <div v-show="activebtn==0" class="yfdtwo">
                <div class="yfdsize">人员基本信息</div>

                <div class="wxts-box">
                    <p class="wxts-text">
                        <i class="el-icon-info"></i>
                        温馨提示：为方便客户平台统一提供了上海银行线上便捷开户通道。请按照下列信息仔细填写和上传相关企业资料。信息提交完毕以后，需要有一段时间完成开户。开户成功前暂时无法使用上海银行的授信进行银e券业务。尽请悉知！
                    </p>
                </div>

                <div class="formstyle">
                    <div class="htbox">
                        <el-form :model="ruleform3" :rules='rules3'  ref="ruleform3" label-width="182px">
                            <el-row :gutter="20">
                                <el-col :span="24" style="margin-top:16px;">
                                <el-form-item label="办理类型：" prop='appApplyType'>
                                    <el-radio-group 
                                    v-model="ruleform3.appApplyType" 
                                    @change="handleChangeAppApplyType">
                                    <el-radio :label="1">法人亲办</el-radio>
                                    <el-radio :label="2">授权经办</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                </el-col>
                            </el-row>

                            <company-beneficiaries 
                                ref="companyBeneficiaries"
                                :dicList36="dicList36"
                                :dicList37="dicList37"
                                :bankCountryList="bankCountryList"></company-beneficiaries>


                            <div class="person-info">
                                <div class="person-type">企业法人</div>
                                <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item label="法人国籍：" prop='corpCountry'>
                                    <el-select v-model="ruleform3.corpCountry" filterable>
                                        <el-option  v-for="(item,index) in bankCountryList" :key="index" :label="item.name" :value="item.numberCode" ></el-option>
                                    </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="证件类型：" prop='legPerIdType'>
                                    <el-select v-model="ruleform3.legPerIdType" filterable>
                                        <el-option  v-for="(item,index) in dicList37" :key="index" :label="item.name" :value="item.code" ></el-option>
                                    </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="证件号码：" prop="legPerId" :rules="ruleform3.legPerIdType=='A'?rules3.legPerId:rules3.legPerId1">
                                    <el-input v-model="ruleform3.legPerId"></el-input>
                                    </el-form-item>
                                </el-col>
                                </el-row>
                                <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item label="法人姓名：" prop='corpName'>
                                    <el-input v-model="ruleform3.corpName"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="法人电话：" prop="corpMobile">
                                    <el-input v-model="ruleform3.corpMobile"></el-input>
                                    </el-form-item>
                                </el-col>
                               
                                <el-col :span="8">
                                    <el-form-item label="证件到期日：" prop='corpIdLimitDate'>
                                    <el-date-picker type="date" placeholder="选择日期" v-model="ruleform3.corpIdLimitDate" value-format="yyyyMMdd"
                                        :editable='false'>
                                    </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                </el-row>
                            </div>

                            <div class="person-info">
                                <div class="person-type">经办人</div>
                                <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item label="证件类型：" prop='checkerIdType' :rules="ruleform3.appApplyType==2 ? rules3.checkerIdType : {}">
                                    <el-select v-model="ruleform3.checkerIdType" filterable>
                                        <el-option  v-for="(item,index) in dicList37" :key="index" :label="item.name" :value="item.code" ></el-option>
                                    </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="证件号码：" prop='checkerIdCard' :rules="ruleform3.appApplyType==2 ? (ruleform3.checkerIdType=='A'? rules3.checkerIdCard11 : rules3.checkerIdCard1):(ruleform3.checkerIdType=='A'?rules3.checkerIdCard00:rules3.checkerIdCard0) ">
                                    <el-input v-model="ruleform3.checkerIdCard"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="证件到期日：" prop='checkerIdLimitDate' :rules="ruleform3.appApplyType==2 ? rules3.checkerIdLimitDate : {}">
                                    <el-date-picker type="date" placeholder="选择日期" v-model="ruleform3.checkerIdLimitDate" value-format="yyyyMMdd"
                                        :editable='false'>
                                    </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                </el-row>
                                <el-row :gutter="20">
                             
                                <el-col :span="8">
                                    <el-form-item label="经办人姓名：" prop='checkerName' :rules="ruleform3.appApplyType==2 ? rules3.checkerName : {}">
                                    <el-input v-model="ruleform3.checkerName"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="联系电话：" prop="checkerMobile" :rules="ruleform3.appApplyType==2 ? rules3.checkerMobile : rules3.checkerMobile1">
                                    <el-input v-model="ruleform3.checkerMobile"></el-input>
                                    </el-form-item>
                                </el-col>
                                </el-row>
                                <el-row :gutter="20" class="registerDemo">
                                <el-form-item label="上传身份证：" :prop='ruleform3.checkerIdBackImg ? "checkerIdFrontImg" : "checkerIdBackImg"' class="company1"
                                :rules="ruleform3.appApplyType==2 ? (ruleform3.checkerIdBackImg ? rules3.checkerIdFrontImg : rules3.checkerIdBackImg) : {}">
                                    <div class="certificates">
                                    <div class="certificates_item">
                                        <span style="color:#ccc;font-size:14px">人像面</span>
                                        <div @click="getFileType(6)" :class="{imgClass:!isEmpty(ruleform3.checkerIdBackImg)}">
                                        <el-upload
                                            :limit="1"
                                            :on-exceed=" () => {$message.info('超过上传限制')}"
                                            :action="uploadFileSftp()"
                                            list-type="picture-card"
                                            :data="videoTypeObj6"
                                            :before-upload="beforeUpload"
                                            :on-success="uploadSuccess7"
                                            :on-remove="removeFile7"
                                            :file-list="fileList7"
                                            :headers="Authorization">
                                            <i v-if="isEmpty(ruleform3.checkerIdBackImg)" class="el-icon-plus"></i>
                                            <span v-else>
                                            <i class="el-icon-loading"></i>
                                            <span>上传中</span>
                                            </span>
                                        </el-upload>
                                        </div>
                                    </div>
                                    <div class="certificates_item">
                                        <span style="color:#ccc;font-size:14px">国徽面</span>
                                        <div @click="getFileType(5)" :class="{imgClass:!isEmpty(ruleform3.checkerIdFrontImg)}">
                                        <el-upload
                                            :limit="1"
                                            :on-exceed=" () => {$message.info('超过上传限制')}"
                                            :action="uploadFileSftp()"
                                            list-type="picture-card"
                                            :data="videoTypeObj5"
                                            :before-upload="beforeUpload"
                                            :on-success="uploadSuccess8"
                                            :on-remove="removeFile8"
                                            :file-list="fileList8"
                                            :headers="Authorization">
                                            <i v-if="isEmpty(ruleform3.checkerIdFrontImg)" class="el-icon-plus"></i>
                                            <span v-else>
                                            <i class="el-icon-loading"></i>
                                            <span>上传中</span>
                                            </span>
                                        </el-upload>
                                        </div>
                                    </div>
                                    </div>
                                    <label
                                    style="font-family: PingFangSC-Regular;font-size: 14px;color: #8C8C8C;line-height: 22px;"
                                    >仅支持JPG、PNG格式，文件小于1M。请尽量使用高清质量图片。</label>
                                </el-form-item>
                                </el-row>
                                <div class="uploadlist">
                                <el-form-item label="经办人开户视频：" prop="cfoIdVideo" :rules="ruleform3.appApplyType==2 ? rules3.cfoIdVideo : {}">
                                    <div @click="getFileType(37)">
                                    <el-upload style="margin-left:0 !important;margin-top:0;"
                                        ref="viewdata2"
                                        class="upload-demo upload-contentr upload-video"
                                        :limit="1"
                                        :action="uploadFileSftp()"
                                        :headers='Authorization'
                                        accept="mp4"
                                        :data="videoTypeObj37"
                                        :before-upload='beforeUploadVideo'
                                        :on-success='uploadVideoSuccess2'
                                        :on-remove='removeVideo2'
                                        :file-list="videoList2"
                                        drag>
                                        <el-button v-if="isEmpty(ruleform3.cfoIdVideo)" size="small" type="primary">点击上传</el-button>
                                    </el-upload>
                                    </div>
                                    <label style="font-family: PingFangSC-Regular;font-size: 14px;color: #8C8C8C;line-height: 22px;display:block;">
                                    开户人财务主管需清晰匀速地朗读验证文字：本人为我司财务主管，现声明：我司已充分知晓上行普惠平台相关规定、愿意遵守相关协议，开立和使用上海银行普惠e账户为符合我司财务制度。<br><br>
                                    仅支持MP4格式，文件小于10M。
                                    </label>
                                </el-form-item>
                                </div>
                            </div>


                            <div class="person-info">
                                <div class="person-type clearfix">股东/实控人</div>
                                <br>
                                <div v-for="(ite, inde) in shrhldInfo" :key="inde">
                                <p style="display:flex;justify-content:flex-end;"><el-button type="text" @click="deleteInfo(ite, inde)">删除</el-button></p>
                                <el-form :model="ite.shrhldForm" :rules='ite.shrhldRules'  :ref="'shrhldForm'+inde" label-width="150px">
                                    <el-row :gutter="20">
                                    <el-col :span="8">
                                        <el-form-item label="股东性质：" prop='shrhldType'>
                                        <el-select v-model="ite.shrhldForm.shrhldType" filterable clearable @change="handelShrhldType(ite,inde)">
                                            <el-option  v-for="(item,index) in shrhldTypeArr" :key="index" :label="item.name" :value="item.code" ></el-option>
                                        </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item :label="ite.shrhldForm.shrhldType=='2'?'企业名称：':'股东姓名：'" prop="shrhldName" :rules="ite.shrhldForm.shrhldType=='2'?ite.shrhldRules.shrhldNameQ:ite.shrhldRules.shrhldNameG">
                                        <el-input v-model="ite.shrhldForm.shrhldName" :placeholder="ite.shrhldForm.shrhldType=='2'?'请输入企业名称':'请输入股东姓名'"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8" v-if="ite.shrhldForm.shrhldType!='2'">
                                        <el-form-item label="证件类型：" prop='shrhldIdType'>
                                        <el-select v-model="ite.shrhldForm.shrhldIdType" filterable>
                                            <el-option  v-for="(item,index) in dicList37" :key="index" :label="item.name" :value="item.code" ></el-option>
                                        </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="证件号码：" prop="shrhldIdNo" :rules="ite.shrhldForm.shrhldType=='2'?ite.shrhldRules.shrhldIdNoQ:ite.shrhldForm.shrhldIdType=='A'?ite.shrhldRules.shrhldIdNoG:ite.shrhldRules.shrhldIdNoQ">
                                        <el-input v-model="ite.shrhldForm.shrhldIdNo" :placeholder="'请输入证件号码'"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="证件到期日：" prop='shrhldIdLimitDate'>
                                        <el-date-picker type="date" placeholder="选择日期" v-model="ite.shrhldForm.shrhldIdLimitDate" value-format="yyyyMMdd"
                                            :editable='false'>
                                        </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item :label="ite.shrhldForm.shrhldType=='2'?'企业注册地：':'股东国籍：'" prop="shrhldCountry">
                                        <el-select v-model="ite.shrhldForm.shrhldCountry" filterable>
                                            <el-option  v-for="(item,index) in bankCountryList" :key="index" :label="item.name" :value="item.twoCode" ></el-option>
                                        </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8" v-if="ite.shrhldForm.shrhldType!='3'">
                                        <el-form-item label="持股比例(%)：" prop='shrhldPercent'>
                                        <el-input v-model="ite.shrhldForm.shrhldPercent" placeholder="持股比例需大于0%"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    </el-row>
                                    <el-row>
                                    <p style="height:1px;border-bottom:1px solid #D9D9D9;"></p>
                                    </el-row>
                                </el-form> 
                                </div>
                            </div>

                            <div class="addbtn" @click="addInfo">
                                <i class="el-icon-plus"></i>添加股东/实控人
                            </div>
                        </el-form>
                    </div>
                </div>
            </div>

            <div v-show="activebtn==1" class="yfdtwo">
                <div class="yfdsize">企业基本信息</div>
                <div class="wxts-box">
                <p class="wxts-text">
                    <i class="el-icon-info"></i>
                    温馨提示：为方便客户平台统一提供了上海银行线上便捷开户通道。请按照下列信息仔细填写和上传相关企业资料。信息提交完毕以后，需要有一段时间完成开户。开户成功前暂时无法使用上海银行的授信进行银e券业务。尽请悉知！
                </p>
                </div>
                <div class="yfdbaseline">
                <el-form :model="ruleform2" :rules='rules2'  ref="ruleform2" label-width="182px">
                    <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="企业注册类型：">
                        <span>企业法人营业执照</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item label="企业注册登记号：">
                        <span>{{ruleform2.agreementNumber}}</span>
                        </el-form-item>
                    </el-col>
                  
                    </el-row>
                    <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="企业中文全称：" prop='companyName'>
                        <span>{{ruleform2.companyName}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item label="企业英文全称：" prop='companyDesc'>
                        <el-input v-model="ruleform2.companyDesc" placeholder="如果没有无需填写"></el-input>
                        </el-form-item>
                    </el-col>
                    </el-row>
                    <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="企业成立日期：" prop='companyFoundDate'>
                        <el-date-picker type="date" placeholder="选择日期" v-model="ruleform2.companyFoundDate" value-format="yyyyMMdd"
                            :editable='false'>
                        </el-date-picker>
                        </el-form-item>
                    </el-col>
                 
                    <el-col :span="8">
                        <el-form-item label="营业执照到期日：" prop='confirmationDate'>
                        <el-date-picker type="date" placeholder="选择日期" v-model="ruleform2.confirmationDate" value-format="yyyyMMdd"
                            :editable='false'>
                        </el-date-picker>
                        </el-form-item>
                    </el-col>
                    </el-row>
                    <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="注册资本币种：" prop='agreementMoneyCur'>
                        <el-select v-model="ruleform2.agreementMoneyCur" filterable>
                            <el-option  v-for="(item,index) in bankCurrencyList" :key="index" :label="item.name" :value="item.code" ></el-option>
                        </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="企业注册资本：" prop='agreementMoney'>
                        <el-input v-model="ruleform2.agreementMoney">
                            <template slot="append">元</template>
                        </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="企业规模：" prop='comDibilityLimit'>
                        <el-select v-model="ruleform2.comDibilityLimit" filterable>
                            <el-option  v-for="(item,index) in dicList38" :key="index" :label="item.name" :value="item.code" ></el-option>
                        </el-select>
                        </el-form-item>
                    </el-col>
                    </el-row>
                    <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="客户类别：" prop='custType'>
                        <el-select v-model="ruleform2.custType" filterable>
                            <el-option  v-for="(item,index) in dicList35" :key="index" :label="item.name" :value="item.code" ></el-option>
                        </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="存款人类别：" prop='depositHuman'>
                        <el-select v-model="ruleform2.depositHuman" filterable>
                            <el-option  v-for="(item,index) in dicList39" :key="index" :label="item.name" :value="item.code" ></el-option>
                        </el-select>
                        </el-form-item>
                    </el-col>
                    </el-row>
                    <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="所属行业：" prop='industry1'>
                        <el-select v-model="ruleform2.industry1" filterable style="width: 100%;" @change="getShIndustryList(1, ruleform2.industry1)">
                            <el-option  v-for="(item,index) in industryList1" :key="index" :label="item.name" :value="item.code" ></el-option>
                        </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="" prop='industry2' label-width="0">
                        <el-select v-model="ruleform2.industry2" filterable style="width: 100%;" @change="getShIndustryList(2, ruleform2.industry2)">
                            <el-option  v-for="(item,index) in industryList2" :key="index" :label="item.name" :value="item.code" ></el-option>
                        </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="" prop='industry3'>
                        <el-select v-model="ruleform2.industry3" filterable style="width: 100%;" @change="getShIndustryList(3, ruleform2.industry3)">
                            <el-option  v-for="(item,index) in industryList3" :key="index" :label="item.name" :value="item.code" ></el-option>
                        </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="industryList.length" label="" prop='industry' label-width="0">
                        <el-select v-model="ruleform2.industry" filterable style="width: 100%;">
                            <el-option  v-for="(item,index) in industryList" :key="index" :label="item.name" :value="item.code" ></el-option>
                        </el-select>
                        </el-form-item>
                    </el-col>
                    </el-row>
                    <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="注册地址省市区：" prop='regDist1'>
                        <el-select v-model="ruleform2.regDist1" filterable @change="getCityList(1, ruleform2.regDist1)">
                            <el-option  v-for="(item,index) in provinceList" :key="index" :label="item.addressName" :value="item.addressId" ></el-option>
                        </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="" prop='regDist2' label-width="0">
                        <el-select v-model="ruleform2.regDist2" filterable style="width:100%;" @change="getCountyList(1, ruleform2.regDist2)">
                            <el-option  v-for="(item,index) in cityList1" :key="index" :label="item.addressName" :value="item.addressId" ></el-option>
                        </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="" prop='regDist' label-width="0">
                        <el-select v-model="ruleform2.regDist" filterable style="width:100%;">
                            <el-option  v-for="(item,index) in countyList1" :key="index" :label="item.addressName" :value="item.addressId" ></el-option>
                        </el-select>
                        </el-form-item>
                    </el-col>
                    </el-row>
                    <el-row :gutter="20">
                    <el-col :span="16">
                        <el-form-item label="注册地址：" prop="regStrentDoor">
                        <el-input v-model="ruleform2.regStrentDoor"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="注册电话号码：" prop="regTePhone">
                        <el-input v-model="ruleform2.regTePhone"></el-input>
                        </el-form-item>
                    </el-col>
                    </el-row>
                    <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="办公地址省市区：" prop='workDist1'>
                        <el-select v-model="ruleform2.workDist1" filterable @change="getCityList(2, ruleform2.workDist1)">
                            <el-option  v-for="(item,index) in provinceList" :key="index" :label="item.addressName" :value="item.addressId" ></el-option>
                        </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="" prop='workDist2' label-width="0">
                        <el-select v-model="ruleform2.workDist2" filterable style="width:100%;" @change="getCountyList(2, ruleform2.workDist2)">
                            <el-option  v-for="(item,index) in cityList2" :key="index" :label="item.addressName" :value="item.addressId" ></el-option>
                        </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="" prop='workDist' label-width="0">
                        <el-select v-model="ruleform2.workDist" filterable style="width:100%;">
                            <el-option  v-for="(item,index) in countyList2" :key="index" :label="item.addressName" :value="item.addressId" ></el-option>
                        </el-select>
                        </el-form-item>
                    </el-col>
                    </el-row>
                    <el-row :gutter="20">
                    <el-col :span="16">
                        <el-form-item label="办公地址：" prop="workStrentDoor">
                        <el-input v-model="ruleform2.workStrentDoor"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="办公电话号码：" prop="workAddressPhone">
                        <el-input v-model="ruleform2.workAddressPhone"></el-input>
                        </el-form-item>
                    </el-col>
                    </el-row>
                    <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="绑定对公账号：" prop='account'>
                        <el-input v-model="ruleform2.account"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item label="绑定账号清算行：" prop='acctBank'>
                        <el-select v-model="ruleform2.acctBankObj" filterable style="width: 100%;" value-key="code" @change="changeBank">
                            <el-option  v-for="(item,index) in bankList" :key="index" :label="item.name" :value="item" ></el-option>
                        </el-select>
                        </el-form-item>
                    </el-col>
                    </el-row>
                    <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="企业经营范围：" prop='scopeOfBus'>
                        <el-input v-model="ruleform2.scopeOfBus"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="企业经营住所：" prop='busAddress'>
                        <el-input v-model="ruleform2.busAddress"></el-input>
                        </el-form-item>
                    </el-col>
                    </el-row>
                </el-form>
                </div>
            </div>
            
            <div v-show="activebtn==2" class="yfdtwo">
                <div class="yfdsize">上传影像资料</div>
                <div class="wxts-box">
                <p class="wxts-text">
                    <i class="el-icon-info"></i>
                    温馨提示：为方便客户平台统一提供了上海银行线上便捷开户通道。请按照下列信息仔细填写和上传相关企业资料。信息提交完毕以后，需要有一段时间完成开户。开户成功前暂时无法使用上海银行的授信进行银e券业务。尽请悉知！
                </p>
                </div>
                <div class="yfdbaseline formstyle">
                <div class="htbox">
                    <el-form :model="ruleform1" :rules='rules1' ref="ruleform1" label-position='left' class="demo-ruleForm registerDemo">
                    <div class="uploadlist">
                        <el-form-item v-if="ruleform3.appApplyType !== 1" label="上行普惠企业授权委托书：" prop="delegationImg">
                        <div class="uploadfont wxts-text">
                            温馨提示：请先下载上行授权书，打印并按照文中相应信息进行准确填写，加盖企业公章以后再行上传。
                        </div>
                        <div class="downloadWord">
                            <div class="line-height: 40px; margin-top: 6px;"><i class="el-icon-document" style="font-size: 16px;"></i> 上海银行普惠平台企业授权书</div>
                            <a :href="fileHref" download style="cursor: pointer;text-decoration: none;"><i class="el-icon-download"></i>一键下载</a>
                        </div>
                        <div @click="getFileType(32)" :class="{imgClass1:!isEmpty(ruleform1.delegationImg)}">
                            <el-upload
                            ref="viewdata1"
                            class="upload-demo upload-contentr"
                            :limit="1"
                            :action="uploadFileSftp()"
                            :headers='Authorization'
                            accept="image/png,image/jpeg,application/zip,application/rar,application/pdf"
                            :data="videoTypeObj32"
                            :before-upload='beforeUpload'
                            :on-success='uploadSuccess1'
                            :on-remove='removeFile1'
                            :file-list="fileList1"
                            drag
                            list-type="picture">
                            <i v-if="isEmpty(ruleform1.delegationImg)" class="el-icon-plus"></i>
                            <div class="el-upload__text">点击上传</div>
                            </el-upload>
                        </div>
                        </el-form-item>
                    </div>
                    <div class="uploadlist">
                        <el-form-item label="企业营业执照：" prop="companyImg">
                        <div @click="getFileType(1)" :class="{imgClass1:!isEmpty(ruleform1.companyImg)}">
                            <el-upload
                            ref="viewdata2"
                            class="upload-demo upload-contentr"
                            :limit="1"
                            :action="uploadFileSftp()"
                            :headers='Authorization'
                            accept="image/png,image/jpeg,application/zip,application/rar,application/pdf"
                            :data="videoTypeObj1"
                            :before-upload='beforeUpload'
                            :on-success='uploadSuccess2'
                            :on-remove='removeFile2'
                            :file-list="fileList2"
                            drag
                            list-type="picture">
                            <i v-if="isEmpty(ruleform1.companyImg)" class="el-icon-plus"></i>
                            <div class="el-upload__text">点击上传</div>
                            </el-upload>
                        </div>
                        </el-form-item>
                    </div>
                    <div class="uploadlist">
                        <el-row>
                        <el-form-item label="上传法人身份证：" :prop='ruleform1.corpIdBackImg ? "corpIdFrontImg" : "corpIdBackImg"' class="company1">
                            <div class="certificates">
                            <div class="certificates_item">
                                <span style="color:#ccc;font-size:14px">人像面</span>
                                <div @click="getFileType(4)" :class="{imgClass:!isEmpty(ruleform1.corpIdBackImg)}">
                                <el-upload
                                    :limit="1"
                                    :on-exceed=" () => {$message.info('超过上传限制')}"
                                    :action="uploadFileSftp()"
                                    list-type="picture-card"
                                    :data="videoTypeObj4"
                                    :before-upload="beforeUpload"
                                    :on-success="uploadSuccess3"
                                    :on-remove="removeFile3"
                                    :file-list="fileList3"
                                    :headers="Authorization">
                                    <i v-if="isEmpty(ruleform1.corpIdBackImg)" class="el-icon-plus"></i>
                                    <span v-else>
                                    <i class="el-icon-loading"></i>
                                    <span>上传中</span>
                                    </span>
                                </el-upload>
                                </div>
                            </div>
                            <div class="certificates_item"> 
                                <span style="color:#ccc;font-size:14px">国徽面</span>
                                <div @click="getFileType(3)" :class="{imgClass:!isEmpty(ruleform1.corpIdFrontImg)}">
                                <el-upload
                                    :limit="1"
                                    :on-exceed=" () => {$message.info('超过上传限制')}"
                                    :action="uploadFileSftp()"
                                    list-type="picture-card"
                                    :data="videoTypeObj3"
                                    :before-upload="beforeUpload"
                                    :on-success="uploadSuccess4"
                                    :on-remove="removeFile4"
                                    :file-list="fileList4"
                                    :headers="Authorization">
                                    <i v-if="isEmpty(ruleform1.corpIdFrontImg)" class="el-icon-plus"></i>
                                    <span v-else>
                                    <i class="el-icon-loading"></i>
                                    <span>上传中</span>
                                    </span>
                                </el-upload>
                                </div>
                            </div>
                            </div>
                            <label style="font-family: PingFangSC-Regular;font-size: 14px;color: #8C8C8C;line-height: 22px;margin-left: 162px;">仅支持JPG、PNG格式，文件小于1M。请尽量使用高清质量图片。</label>
                        </el-form-item>
                        </el-row>
                        <el-row>
                        <el-form-item label="法人开户视频：" prop="corpIdVideo">
                            <div @click="getFileType(38)">
                            <el-upload
                                ref="viewdata2"
                                class="upload-demo upload-contentr upload-video"
                                :limit="1"
                                :action="uploadFileSftp()"
                                :headers='Authorization'
                                accept="mp4"
                                :data="videoTypeObj38"
                                :before-upload='beforeUploadVideo'
                                :on-success='uploadVideoSuccess1'
                                :on-remove='removeVideo1'
                                :file-list="videoList1"
                                drag>
                                <el-button v-if="isEmpty(ruleform1.corpIdVideo)" size="small" type="primary">点击上传</el-button>
                            </el-upload>
                            </div>
                            <label style="font-family: PingFangSC-Regular;font-size: 14px;color: #8C8C8C;line-height: 22px;margin-left: 136px;display:block;">
                            开户人法人需清晰匀速地朗读验证文字:本人为我司法定代表人，现声明：我司已充分知晓上行普惠平台相关规定、愿意遵守相关协议，开立和使用上海银行普惠e账户为符合我司章程的法人行为<br><br>
                            仅支持MP4格式，文件小于10M。
                            </label>
                        </el-form-item>
                        </el-row>
                    </div>
                    </el-form>
                </div>
                </div>
            </div>

            <div class="easyformbtn">
                <el-button style="margin-top: 12px;" @click="goback" v-if="activebtn==0">返 回</el-button>
                <el-button style="margin-top: 12px;" @click="prevbtn" v-else>上一步</el-button>
                <el-button style="margin-top: 12px;" @click="nextbtn">下一步</el-button>
            </div>
        </div>
    </div>
    <el-dialog
        title="输入校验码"
        :visible.sync="applyDialog"
        v-if="applyDialog"
        width="40%">
        <div>
        尊敬的用户：
        <p>您已经向上海银行申请提交开户信息，上海银行会向您尾号为 {{ruleform3.corpMobile.substring(ruleform3.corpMobile.length-4)}} 的手机发送动态验证码。</p>
        <p>请在下面的输入框中输入您收到的验证码，完成信息提交！</p>
        <el-form :model="ruleform4" :rules='rules4' ref="ruleform4" label-width="120px">
            <el-row :gutter="20">
            <el-col :span="22">
                <el-form-item label="短信验证码：" prop='dynamicCode'>
                <el-input v-model="ruleform4.dynamicCode"></el-input>
                </el-form-item>
            </el-col>
            </el-row>
        </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
        <el-button @click="applyDialog = false">取消</el-button>
        <el-button type="primary" @click="getCode" :disabled="forbidden1">{{ forbidden1 ? count + '秒后可重新发送' : '重新发送' }}</el-button>
        <el-button type="primary" @click="submit" :disabled="forbidden2">提交</el-button>
        </div>
    </el-dialog>
</layout-main-view>
</template>
<script>
import api from "@/api/api";
import upload from "@/api/upload";
import _ from "lodash";
import moment from "moment";
import { baseURL } from '@/api/sys.js';
import CompanyBeneficiaries from './companyBeneficiaries'

var check1 = (rule, value, callback) => {
  if (!/^\d+(\.\d*)?$|^\.\d+$/.test(value)) {
    callback(new Error('请输入数字'));
  }else{
    if (Number(value) <= 0 || Number(value) > 100) {
      callback(new Error("请输入大于0并小于等于100的数字"));
    } else {
      callback();
    }
  }
  
};

export default {
    name: 'openAccount',
    components: {
        CompanyBeneficiaries
    },
    data() {
        return {
            breadcrumblist: ["开立渠道账户"],
            activebtn: 0,
            Authorization: { Authorization: sessionStorage.token },
            fileHref: "/上行普惠平台企业授权书.docx",
            ruleform1: {  // 影像资料
                delegationImg: "",  //授权书文件
                delegationImgUrl: "",  //授权书文件Url
                companyImg: "", // 营业执照图片
                companyImgUrl: "", // 营业执照图片Url
                corpIdBackImg: "", //法人身份证反面（人像面）
                corpIdBackImgUrl: "", //法人身份证反面（人像面）Url
                corpIdFrontImg: "", //法人身份证正面（国徽页）
                corpIdFrontImgUrl: "", //法人身份证正面（国徽页）Url
                corpIdVideo: "",  // 法人开户视频
            },
            ruleform2: { // 企业基本账户信息
                agreementType: "A", // 企业注册类型 默认A 企业法人营业执照
                agreementNumber: "", // 企业注册登记号
                companyName: "", // 企业中文客户全称
                companyDesc: "", // 企业英文客户全称 
                companyFoundDate: "", // 企业成立日期
                // companyIdFromDate: "", // 企业营业执照证件起始日
                confirmationDate: "", // 企业营业执照证件到期日
                agreementMoneyCur: "", //注册资本币种
                agreementMoney: "", // 企业注册资本
                comDibilityLimit: "", // 企业规模
                custType: "", // 客户类别
                industry1: "", // 一级所属行业
                industry2: "", // 二级所属行业
                industry3: "", // 三级所属行业
                industry: "", // 四级所属行业
                depositHuman: "", // 存款人类别
                regDist1: "", // 企业注册地址省
                regDist2: "", // 企业注册地址市
                regDist: "", // 企业注册地址省市区代码
                regStrentDoor: "", // 企业注册街道地址（具体到门牌号）
                regTePhone: "", // 企业注册电话号码
                workDist1: "", // 企业办公省
                workDist2: "", // 企业办公市
                workDist: "", // 企业办公省市区代码
                workStrentDoor: "", // 企业办公街道地址（具体到门牌号）
                workAddressPhone: "", // 企业办公电话号码
                account: "", // 绑定银行对公账号
                acctBankObj: {
                code: '',
                name: ''
                }, // 绑定卡清算行
                acctBank: "", // 绑定卡清算行行号
                acctBankName: "", // 绑定卡清算行名称
                scopeOfBus: '', // 企业经营范围
                busAddress: '', // 企业经营住所
            }, 
            
            ruleform3: { // 人员基本信息
                appApplyType: "", // 办理类型
                cfoType: "", // 财务主管类型

                corpCountry: "156", // 法人国籍
                legPerIdType: "A", // 法人证件类型
                legPerId: "",// 法人证件号码
                corpName: "", // 法人代表姓名
                // corpIdFromDate: "", // 法人身份证起始日
                corpIdLimitDate: "", // 法人证件到期日
                corpMobile: "", // 法人代表电话
                cfoIdVideo: "", // 财务负责人开户视频
                checkerIdType: "A", // 经办人证件类型
                checkerIdCard: "", // 经办人身份证号码
                checkerName: "", // 经办人姓名
                // checkerIdFromDate: "", // 经办人身份证起始日
                checkerIdLimitDate: "", // 经办人证件到期日
                checkerMobile: "", // 经办人手机号
                checkerIdFrontImg: "", // 经办人身份证正面图片名称
                checkerIdFrontImgUrl: "", // 经办人身份证正面图片名称Url
                checkerIdBackImg: "", // 经办人身份证反面图片名称
                checkerIdBackImgUrl: "", // 经办人身份证反面图片名称Url
                
            },
            shrhldInfo: [
                {
                    shrhldRules: { // 验证规则
                        shrhldType: [
                            { required: true, message: "请选择股东性质", trigger: "change" }
                        ],
                        shrhldNameQ: [
                            { required: true, message: "请输入企业名称", trigger: "blur" }
                        ],
                        shrhldNameG: [
                            { required: true, message: "请输入股东姓名", trigger: "blur" }
                        ],
                        shrhldIdType: [
                            { required: true, message: "请选择证件类型", trigger: "change" }
                        ],
                        shrhldIdNoQ: [
                            { required: true, message: "请输入证件号码", trigger: "blur" }
                        ],
                        shrhldIdNoG: [
                            { required: true, message: "请输入证件号码", trigger: "blur" },
                            { pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: "请输入正确的证件号码", trigger: "blur"}
                        ],
                        shrhldIdLimitDate: [
                            { required: true, message: "请选择证件到期日", trigger: "change" }
                        ],
                        shrhldCountry: [
                            { required: true, message: "请选择股东国籍或者企业注册地", trigger: "change" }
                        ],
                        shrhldPercent: [
                            {  validator: check1, trigger: "blur"},
                            { required: true, message: "请输入持股比例" }
                        ],
                    },
                    shrhldForm: {  // 股东/实控人 form
                        shrhldType: '',
                        shrhldName: '',
                        shrhldIdType: '',
                        shrhldIdNo: '',
                        shrhldIdLimitDate: '',
                        shrhldPercent: '',
                        shrhldCountry: 'CN'
                    }
                }
            ],

            ruleform4: {
                dynamicCode: "", // 动态验证码
            },
            videoTypeObj32: {
                fileName: ''
            },
            videoTypeObj1: {
                fileName: ''
            },
            videoTypeObj3: {
                fileName: ''
            },
            videoTypeObj4: {
                fileName: ''
            },
            videoTypeObj5: {
                fileName: ''
            },
            videoTypeObj6: {
                fileName: ''
            },
            videoTypeObj21: {
                fileName: ''
            },
            videoTypeObj22: {
                fileName: ''
            },
            videoTypeObj37: {
                fileName: ''
            },
            videoTypeObj38: {
                fileName: ''
            },
            shrhldTypeArr: [
                {name: '占股25%自然人股东',code: '1'},
                {name: '占股25%企业股东',code: '2'},
                {name: '实控自然人',code: '3'},
            ],
            videoType: '', // 视频类型
            bankCurrencyList: [],  // 币种集合
            industryList1: [], // 一级所属行业集合
            industryList2: [], // 二级所属行业集合
            industryList3: [], // 三级所属行业集合
            industryList: [], // 四级所属行业集合
            provinceList: [],  // 省集合
            cityList1: [],  // 市集合
            cityList2: [],  // 市集合
            countyList1: [],  // 区/县集合
            countyList2: [],  // 区/县集合
            bankList: [], // 清算行集合
            dicList35: [],  // 客户类别集合
            dicList36: [],  // 受益人性质集合
            dicList37: [],  // 证件类型集合
            dicList38: [],  // 企业规模集合
            dicList39: [],  // 存款人类别集合
            bankCountryList: [],  // 国籍集合
            applyDialog: false,
            fileList1: [], //回显企业授权委托书
            fileList2: [], //回显企业营业执照
            fileList3: [], //回显法人身份证反面
            fileList4: [], //回显法人身份证正面
            fileList5: [], //回显受益人身份证反面
            fileList6: [], //回显受益人身份证正面
            fileList7: [], //回显经办人身份证反面
            fileList8: [], //回显经办人身份证正面
            videoList1: [],
            videoList2: [],
            rules1: {
                delegationImg: [
                { required: true, message: "请上传上行普惠企业授权委托书", trigger: "blur" }
                ], 
                companyImg: [
                { required: true, message: "请上传企业营业执照", trigger: "blur" }
                ], 
                corpIdBackImg: [
                { required: true, message: "请上传法人身份证人像面", trigger: "blur" }
                ],   
                corpIdFrontImg: [
                { required: true, message: "请上传法人身份证国徽面", trigger: "blur" }
                ], 
                corpIdVideo: [
                { required: true, message: "请上传法人开户视频", trigger: "blur" }
                ], 
            },
            rules2: {
                // companyName: [
                //   { required: true, message: "请输入企业中文全称", trigger: "blur" },
                //   { pattern: /^[\u4e00-\u9fa5]{1,}$/, message: "请输入正确企业中文全称", trigger: "blur"}
                // ], 
                companyDesc: [
                { required: false, message: "请输入企业英文全称", trigger: "blur" },
                { pattern: /^[a-zA-Z\·\s]{1,20}$/, message: "请输入正确企业英文全称", trigger: "blur"}
                ], 
                companyFoundDate: [
                { required: true, message: "请选择企业成立日期", trigger: "change" }
                ], 
                confirmationDate: [
                { required: false, message: "请选择营业执照到期日", trigger: "change" }
                ], 
                agreementMoneyCur: [
                { required: true, message: "请选择注册资本币种", trigger: "change" }
                ], 
                agreementMoney: [
                { required: true, message: "请输入企业注册资本", trigger: "blur" },
                { pattern: /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{1,2}$)/, message: "请输入正确金额", trigger: "blur"}
                ],
                comDibilityLimit: [
                { required: true, message: "请选择企业规模", trigger: "change" }
                ], 
                custType: [
                { required: true, message: "请选择客户类别", trigger: "change" }
                ], 
                industry1: [
                { required: true, message: "请选择所属行业", trigger: "change" }
                ], 
                industry2: [
                { required: true, message: "请选择所属行业", trigger: "change" }
                ], 
                industry3: [
                { required: true, message: "请选择所属行业", trigger: "change" }
                ], 
                industry: [
                { required: true, message: "请选择所属行业", trigger: "change" }
                ], 
                depositHuman: [
                { required: true, message: "请选择存款人类别", trigger: "change" }
                ], 
                regDist1: [
                { required: true, message: "请选择注册地址", trigger: "change" }
                ], 
                regDist2: [
                { required: true, message: "请选择注册地址", trigger: "change" }
                ], 
                regDist: [
                { required: true, message: "请选择注册地址", trigger: "change" }
                ], 
                regStrentDoor: [
                { required: true, message: "请输入注册地址", trigger: "blur" }
                ],
                regTePhone: [
                { required: true, message: "请输入注册电话号码", trigger: "blur" },
                { pattern: /(^\d{3,4}-(\d{7,8})$)|(^(1\d{10})$)/, message: "请输入正确的电话号码", trigger: "blur"}
                ],
                workDist1: [
                { required: true, message: "请选择办公地址", trigger: "change" }
                ], 
                workDist2: [
                { required: true, message: "请选择办公地址", trigger: "change" }
                ], 
                workDist: [
                { required: true, message: "请选择办公地址", trigger: "change" }
                ], 
                workStrentDoor: [
                { required: true, message: "请输入办公地址", trigger: "blur" }
                ],
                workAddressPhone: [
                { required: true, message: "请输入办公电话号码", trigger: "blur" },
                { pattern: /(^\d{3,4}-(\d{7,8})$)|(^(1\d{10})$)/, message: "请输入正确的电话号码", trigger: "blur"}
                ],
                account: [
                { required: true, message: "请输入绑定对公账号", trigger: "blur" },
                { pattern: /^\d{12,}$/, message: "请输入正确的对公账号", trigger: "blur"}
                ], 
                acctBank: [
                { required: true, message: "请选择绑定账号清算行", trigger: "change" }
                ], 
                scopeOfBus: [
                { required: true, message: "请输入企业经营范围", trigger: "blur" }
                ], 
                busAddress: [
                { required: true, message: "请输入企业经营住所", trigger: "blur" }
                ], 
            },
            rules3: {
                appApplyType: [
                { required: true, message: "请选择办理类型", trigger: "change" }
                ], 

                corpCountry: [
                { required: true, message: "请选择法人国籍", trigger: "change" }
                ], 
                legPerIdType: [
                { required: true, message: "请选择证件类型", trigger: "change" }
                ], 
                legPerId: [
                { required: true, message: "请输入证件号码", trigger: "blur" },
                { pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: "请输入正确的证件号码", trigger: "blur"}
                ], 
                legPerId1: [
                { required: true, message: "请输入证件号码", trigger: "blur" }
                ], 
                corpName: [
                { required: true, message: "请输入法人姓名", trigger: "blur" }
                ], 
                corpIdLimitDate: [
                { required: false, message: "请选择证件到期日", trigger: "change" }
                ], 
                corpMobile: [
                { required: true, message: "请输入法人电话", trigger: "blur" },
                { pattern: /^1\d{10}$/, message: "请输入正确的手机号码", trigger: "blur"}
                ], 
                cfoIdVideo: [
                { required: true, message: "请上传财务负责人开户视频", trigger: "blur" }
                ], 
                checkerIdType: [
                { required: true, message: "请选择证件类型", trigger: "change" }
                ], 
                checkerIdCard11: [
                { required: true, message: "请输入证件号码", trigger: "blur" },
                { pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: "请输入正确的证件号码", trigger: "blur"}
                ], 
                checkerIdCard00: [
                { required: false, message: "请输入证件号码", trigger: "blur" },
                { pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: "请输入正确的证件号码", trigger: "blur"}
                ],
                checkerIdCard1: [
                { required: true, message: "请输入证件号码", trigger: "blur" }
                ],
                checkerIdCard0: [
                { required: false, message: "请输入证件号码", trigger: "blur" }
                ],
                checkerName: [
                { required: true, message: "请输入经办人姓名", trigger: "blur" }
                ], 
                checkerIdLimitDate: [
                { required: false, message: "请选择证件到期日", trigger: "change" }
                ], 
                checkerMobile: [
                { required: true, message: "请输入联系电话", trigger: "blur" },
                { pattern: /^1\d{10}$/, message: "请输入正确的手机号码", trigger: "blur"}
                ], 
                checkerMobile1: [
                { required: false, message: "请输入联系电话", trigger: "blur" },
                { pattern: /^1\d{10}$/, message: "请输入正确的手机号码", trigger: "blur"}
                ], 
                checkerIdBackImg: [
                { required: true, message: "请上传身份证人像面", trigger: "blur" }
                ], 
                checkerIdFrontImg: [
                { required: true, message: "请上传身份证国徽面", trigger: "blur" }
                ]        
            },
            rules4: {
                dynamicCode: [
                { required: true, message: "请输入短信验证码", trigger: "blur" },
                { pattern: /\d{6}$/, message: "验证码格式不正确", trigger: "blur"}
                ]
            },
            corpList: JSON.parse(sessionStorage.getItem('corpList')),  // 企业认证信息
            creditCode: JSON.parse(sessionStorage.getItem('corpList')).creditCode, // 企业统一社会信用代码
            count: 0, // 倒计时读秒
            forbidden1: false,
            forbidden2: false,
            openAccountId: this.$route.query.openAccountId || '',
            isEdit: this.$route.query.isEdit,  
            details: {}
        };
    },

    created() {
        if(baseURL == 'http://106.53.104.245:8080') {
            this.fileHref = '/yirong-web/static/上行普惠平台企业授权书.docx'
        }
        if(this.isEdit) {
            this.openAccountResult(this.openAccountId)
        } else {
            this.openAccountResult(0)
        }

        this.getShBankCurrencyList()  // 获取币种
        this.getShIndustryList()  // 获取所属行业
        this.getProvinceList()  // 获取省
        this.getShBankList()  // 获取清算行
        this.getDicListByType35() // 获取客户类别 35
        this.getDicListByType36() // 获取受益人性质 36
        this.getDicListByType37() // 获取证件类型 37
        this.getDicListByType38() // 获取企业规模 38
        this.getDicListByType39() // 获取存款人类别 39
        this.getShBankCountryList() // 获取国籍
    },
    methods: {
        //选择股东性质
        handelShrhldType(val){
            //股东性质：3（自然人）的场合，（企业）持股比例非必填
            if(val.shrhldForm.shrhldType ==='3'){
                this.$set(val.shrhldForm,'shrhldPercent','')
            }
            //股东性质：2（企业）的场合，（自然人）股东证件类型非必填
            if(val.shrhldForm.shrhldType ==='2'){
                this.$set(val.shrhldForm,'shrhldIdType','')
            }
        },
        moment: moment,
        isEmpty(obj) {
            return _.isEmpty(obj);
        },
        openAccountResult(openAccountId) {
            api.openAccountResult({openAccountId}).then(res => {
                if(res.success) {
                if(res.datas.detail.material) {
                    this.details = JSON.parse(res.datas.detail.material)
                    this.details.regDist1 = this.details.regDist1 && parseInt(this.details.regDist1)
                    this.details.regDist2 = this.details.regDist2 && parseInt(this.details.regDist2)
                    this.details.regDist = this.details.regDist && parseInt(this.details.regDist)
                    this.details.workDist1 = this.details.workDist1 && parseInt(this.details.workDist1)
                    this.details.workDist2 = this.details.workDist2 && parseInt(this.details.workDist2)
                    this.details.workDist = this.details.workDist && parseInt(this.details.workDist)
                    this.details.appApplyType = this.details.appApplyType && parseInt(this.details.appApplyType)

                    // 回显用 this.details.earningOwnerList
                    if(!_.isEmpty(this.details.earningOwnerList)) {
                        this.$refs.companyBeneficiaries.setFormData(this.details.earningOwnerList)
                    }

                    if(this.details.shrhldList && this.details.shrhldList.length > 0){
                    this.shrhldInfo = [];
                    this.details.shrhldList.map(a=>{
                        this.shrhldInfo.push(
                        {
                            shrhldRules: { // 验证规则
                            shrhldType: [
                                { required: true, message: "请选择股东性质", trigger: "change" }
                            ],
                            shrhldNameQ: [
                                { required: true, message: "请输入企业名称", trigger: "blur" }
                            ],
                            shrhldNameG: [
                                { required: true, message: "请输入股东姓名", trigger: "blur" }
                            ],
                            shrhldIdType: [
                                { required: true, message: "请选择证件类型", trigger: "change" }
                            ],
                            shrhldIdNoQ: [
                                { required: true, message: "请输入证件号码", trigger: "blur" }
                            ],
                            shrhldIdNoG: [
                                { required: true, message: "请输入证件号码", trigger: "blur" },
                                { pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: "请输入正确的证件号码", trigger: "blur"}
                            ],
                            shrhldIdLimitDate: [
                                { required: true, message: "请选择证件到期日", trigger: "change" }
                            ],
                            shrhldCountry: [
                                { required: true, message: "请选择股东国籍或者企业注册地", trigger: "change" }
                            ],
                            shrhldPercent: [
                                {  validator: check1, trigger: "blur"},
                                { required: true, message: "请输入持股比例" }
                            ],
                            },
                            shrhldForm: a
                        }
                        );
                    })
                    }
                    this.fileList1 = this.details.delegationImgUrl ? [
                    { url: api.getImgURL(this.details.delegationImgUrl) }
                    ] : []
                    this.fileList2 = this.details.companyImgUrl ? [
                    { url: api.getImgURL(this.details.companyImgUrl) }
                    ] : []
                    this.fileList3 = this.details.corpIdBackImgUrl ? [
                    { url: api.getImgURL(this.details.corpIdBackImgUrl) }
                    ] : []
                    this.fileList4 = this.details.corpIdFrontImgUrl ? [
                    { url: api.getImgURL(this.details.corpIdFrontImgUrl) }
                    ] : []

                    this.fileList7 = this.details.checkerIdBackImgUrl ? [
                    { url: api.getImgURL(this.details.checkerIdBackImgUrl) }
                    ] : []
                    this.fileList8 = this.details.checkerIdFrontImgUrl ? [
                    { url: api.getImgURL(this.details.checkerIdFrontImgUrl) }
                    ] : []
                    this.videoList1 = this.details.corpIdVideo ? [
                    { name: this.details.corpIdVideo}
                    ] : []
                    this.videoList2 = this.details.cfoIdVideo ? [
                    { name: this.details.cfoIdVideo}
                    ] : []

                    for (var key in this.ruleform1) { 
                    this.ruleform1[key] = this.details[key]
                    }
                    for (var key in this.ruleform2) { 
                    this.ruleform2[key] = this.details[key]
                    }
                    for (var key in this.ruleform3) { 
                    this.ruleform3[key] = this.details[key]  // 这一步会把股东 的循环模块覆盖
                    }

                    this.ruleform2.acctBankObj = {
                    code: this.ruleform2.acctBank
                    } 

                    this.ruleform2.regDist1 && this.getCityList(1, this.ruleform2.regDist1, true)
                    this.ruleform2.regDist2 && this.getCountyList(1, this.ruleform2.regDist2, true)
                    this.ruleform2.workDist1 && this.getCityList(2, this.ruleform2.workDist1, true)
                    this.ruleform2.workDist2 && this.getCountyList(2, this.ruleform2.workDist2, true)

                    this.ruleform2.industry1 && this.getShIndustryList(1, this.ruleform2.industry1, true)
                    this.ruleform2.industry2 && this.getShIndustryList(2, this.ruleform2.industry2, true)
                    this.ruleform2.industry3 && this.getShIndustryList(3, this.ruleform2.industry3, true)
                } else {
                    this.ruleform3.legPerId = this.corpList.legalIdCard
                    this.ruleform3.corpName = this.corpList.legalName
                    this.ruleform3.corpMobile = this.corpList.phone
                }
                if(this.corpList) {
                    this.creditCode = this.corpList.creditCode  // 企业统一社会信用代码
                    this.ruleform2.agreementNumber = this.corpList.creditCode // 企业注册登记号
                    this.ruleform2.companyName = this.corpList && this.corpList.corpName
                }
                } else {
                this.$message.error(res.message);
                }
            }).catch((err) => {
                this.$message.error('操作失败');
            });
        },
        getFileType(type) {
            // 32开户授权委托书 1企业营业执照 3法人身份证正面图片 4 法人身份证反面图片 5经办人身份证正面图片 6经办人身份证反面图片 21受益人身份证正面图片 22受益人身份证反面图片
            // 37财务主管开户视频 38法人开户视频
            this.videoType = type
            let fileName = moment(new Date()).format('YYYYMMDD') + '-' + this.corpList.creditCode + '-' + type
            if(this.videoType == 32) {
                this.videoTypeObj32.fileName = fileName + '.jpg'
            } else if(this.videoType == 1) {
                this.videoTypeObj1.fileName = fileName + '.jpg'
            } else if(this.videoType == 3) {
                this.videoTypeObj3.fileName = fileName + '.jpg'
            } else if(this.videoType == 4) {
                this.videoTypeObj4.fileName = fileName + '.jpg'
            } else if(this.videoType == 5) {
                this.videoTypeObj5.fileName = fileName + '.jpg'
            } else if(this.videoType == 6) {
                this.videoTypeObj6.fileName = fileName + '.jpg'
            } else if(this.videoType == 21) {
                this.videoTypeObj21.fileName = fileName + '.jpg'
            } else if(this.videoType == 22) {
                this.videoTypeObj22.fileName = fileName + '.jpg'
            } else if(this.videoType == 37) {
                this.videoTypeObj37.fileName = fileName + '.mp4'
            } else if(this.videoType == 38) {
                this.videoTypeObj38.fileName = fileName + '.mp4'
            }
        },
        // 上海银行-文件上传
        uploadFileSftp() {
            return upload.uploadFileSftp();
        },
        beforeUpload(file) {
            let excelfileExtend = ".jpg,.png,.jpeg,.bmg"; //设置文件格式
            let fileExtend = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
            if (excelfileExtend.indexOf(fileExtend) <= -1) {
                this.$message.error("文件格式错误");
                return false;
            }
            //判断文件大小 1M 以内
            const isLimitSize = file.size / 1024 / 1024 < 1
            if(!isLimitSize) {
                this.$message.error("上传文件大小不能超过 1MB!");
                return false;
            }
        },
        beforeUploadVideo(file) {
            let excelfileExtend = ".mp4"; //设置文件格式
            let fileExtend = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
            if (excelfileExtend.indexOf(fileExtend) <= -1) {
                this.$message.error("文件格式错误");
                return false;
            }
            // 判断文件大小 5M 以内
            const isLimitSize = file.size / 1024 / 1024 < 10
            if(!isLimitSize) {
                this.$message.error("上传文件大小不能超过 10MB!");
                return false;
            }
        },
        // 上传授权委托书-成功回调
        uploadSuccess1(res) {
            if(res.success) {
                this.ruleform1.delegationImg = res.datas.fileName;
                this.ruleform1.delegationImgUrl = res.datas.uploadDate.message;
                this.$message.success('上传成功');
                this.$refs.ruleform1.validateField('delegationImg');
            } else {
                this.$message.error(res.message);
            }
        },
        // 企业营业执照-成功回调
        uploadSuccess2(res) {
            if(res.success) {
                this.ruleform1.companyImg = res.datas.fileName;
                this.ruleform1.companyImgUrl = res.datas.uploadDate.message;
                this.$message.success('上传成功');
                this.$refs.ruleform1.validateField('companyImg');
            } else {
                this.$message.error(res.message);
            }
        },
        // 法人身份证反面-成功回调
        uploadSuccess3(res) {
            if(res.success) {
                this.ruleform1.corpIdBackImg = res.datas.fileName;
                this.ruleform1.corpIdBackImgUrl = res.datas.uploadDate.message;
                this.$message.success('上传成功');
                this.$refs.ruleform1.validateField('corpIdBackImg');
            } else {
                this.$message.error(res.message);
            }
        },
        // 法人身份证正面-成功回调
        uploadSuccess4(res) {
            if(res.success) {
                this.ruleform1.corpIdFrontImg = res.datas.fileName;
                this.ruleform1.corpIdFrontImgUrl = res.datas.uploadDate.message;
                this.$message.success('上传成功');
                this.$refs.ruleform1.validateField('corpIdFrontImg');
            } else {
                this.$message.error(res.message);
            }
        },
        // 经办人身份证反面-成功回调
        uploadSuccess7(res) {
            if(res.success) {
                this.ruleform3.checkerIdBackImg = res.datas.fileName;
                this.ruleform3.checkerIdBackImgUrl = res.datas.uploadDate.message;
                this.$message.success('上传成功');
                this.$refs.ruleform3.validateField('checkerIdBackImg');
            } else {
                this.$message.error(res.message);
            }
        },
        // 经办人身份证正面-成功回调
        uploadSuccess8(res) {
            if(res.success) {
                this.ruleform3.checkerIdFrontImg = res.datas.fileName;
                this.ruleform3.checkerIdFrontImgUrl = res.datas.uploadDate.message;
                this.$message.success('上传成功');
                this.$refs.ruleform3.validateField('checkerIdFrontImg');
            } else {
                this.$message.error(res.message);
            }
        },
        // 法人开户视频-成功回调
        uploadVideoSuccess1(res) {
            if(res.success) {
                this.ruleform1.corpIdVideo = res.datas.fileName;
                this.$message.success('上传成功');
                this.$refs.ruleform1.validateField('corpIdVideo');
            } else {
                this.$message.error(res.message);
            }
        },
        // 财务负责人开户视频-成功回调
        uploadVideoSuccess2(res) {
            if(res.success) {
                this.ruleform3.cfoIdVideo = res.datas.fileName;
                this.$message.success('上传成功');
                this.$refs.ruleform3.validateField('cfoIdVideo');
            } else {
                this.$message.error(res.message);
            }
        },
        // 上传授权委托书-删除回调
        removeFile1(file) {
            this.ruleform1.delegationImg = "";
            this.ruleform1.delegationImgUrl = "";
        },
        // 企业营业执照-删除回调
        removeFile2(file) {
            this.ruleform1.companyImg = "";
            this.ruleform1.companyImgUrl = "";
        },
        // 法人身份证反面-删除回调
        removeFile3(file) {
            this.ruleform1.corpIdBackImg = "";
            this.ruleform1.corpIdBackImgUrl = "";
        },
        // 法人身份证正面-删除回调
        removeFile4(file) {
            this.ruleform1.corpIdFrontImg = "";
            this.ruleform1.corpIdFrontImgUrl = "";
        },
        // 受益人身份证反面-删除回调
        removeFile5(file) {
            this.ruleform3.earningOwnerBackImg = "";
            this.ruleform3.earningOwnerBackImgUrl = "";
        },
        // 受益人身份证正面-删除回调
        removeFile6(file) {
            this.ruleform3.earningOwnerFrontImg = "";
            this.ruleform3.earningOwnerFrontImgUrl = "";
        },
        // 经办人身份证反面-删除回调
        removeFile7(file) {
            this.ruleform3.checkerIdBackImg = "";
            this.ruleform3.checkerIdBackImgUrl = "";
        },
        // 经办人身份证正面-删除回调
        removeFile8(file) {
            this.ruleform3.checkerIdFrontImg = "";
            this.ruleform3.checkerIdFrontImgUrl = "";
        },
        // 法人开户视频-删除回调
        removeVideo1(file) {
            this.ruleform1.corpIdVideo = "";
        },
        // 财务负责人开户视频-删除回调
        removeVideo2(file) {
            this.ruleform3.cfoIdVideo = "";
        },
        // 上海银行-获取币种
        getShBankCurrencyList() {
            api.getShBankCurrencyList().then(res => {
                if (res.success) {
                    this.bankCurrencyList = res.datas.bankCurrencyList
                    this.ruleform2.agreementMoneyCur = res.datas.bankCurrencyList.find(v => v.name == '人民币').code  // 默认人民币
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                this.$message.error('操作失败');
            });
        },
        // 上海银行-获取所属行业
        getShIndustryList(type = 0, parentCode = 0, isEdit) {
            api.getShIndustryList({parentCode}).then(res => {
                if (res.success) {
                if(type == 0) { // 一级行业
                    this.industryList1 = res.datas.industryList
                } else if(type == 1) {  // 二级行业
                    this.industryList2 = res.datas.industryList
                    if(!isEdit) {
                        this.industryList3 = []
                        this.industryList = []
                        this.ruleform2.industry2 = ""
                        this.ruleform2.industry3 = ""
                        this.ruleform2.industry = ""
                    }
                } else if(type == 2) {  // 三级行业
                    this.industryList3 = res.datas.industryList
                    if(!isEdit) {
                    this.industryList = []
                    this.ruleform2.industry3 = ""
                    this.ruleform2.industry = ""
                    }
                } else if(type == 3) {  // 四级行业
                    this.industryList = res.datas.industryList
                    if(!isEdit) {
                    this.ruleform2.industry = ""
                    }
                }
                } else {
                this.$message.error(res.message);
                }
            }).catch((err) => {
                this.$message.error('操作失败');
            });
        },
        // 上海银行-获取省份
        getProvinceList() {
            api.getShAreaList({addressId: 0}).then(res => {
                if (res.success) {
                    this.provinceList = res.datas.addressList
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                this.$message.error('操作失败');
            });
        },
        // 上海银行-获取市
        getCityList(type, addressId, isEdit) {
            api.getShAreaList({addressId}).then(res => {
                if (res.success) {
                    if(type == 1) {
                        this.cityList1 = res.datas.addressList
                        if(!isEdit) {
                            this.countyList1 = []
                            this.ruleform2.regDist2 = ""
                            this.ruleform2.regDist = ""
                        }
                    } else if(type == 2) {
                        this.cityList2 = res.datas.addressList
                        if(!isEdit) {
                            this.countyList2 = []
                            this.ruleform2.workDist2 = ""
                            this.ruleform2.workDist = ""
                        } 
                    }
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                this.$message.error('操作失败');
            });
        },
        // 上海银行-获取区/县
        getCountyList(type, addressId, isEdit) {
            api.getShAreaList({addressId}).then(res => {
                if (res.success) {
                    if(type == 1) {
                        this.countyList1 = res.datas.addressList
                        if(!isEdit) {
                            this.ruleform2.regDist = ""
                        }
                    } else if(type == 2) {
                        this.countyList2 = res.datas.addressList
                        if(!isEdit) {
                            this.ruleform2.workDist = ""
                        }
                    }
                } else {
                this.$message.error(res.message);
                }
            }).catch((err) => {
                this.$message.error('操作失败');
            });
        },
        // 上海银行-获取清算行 
        getShBankList() {
            api.getShBankList().then(res => {
                if (res.success) {
                    this.bankList = res.datas.bankList
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                this.$message.error('操作失败');
            });
        },
        // 获取客户类别 参数type为35
        getDicListByType35() {
            api.getDicListByType({type: 35}).then(res => {
                if (res.success) {
                    this.dicList35 = res.datas.list
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                this.$message.error('操作失败');
            });
        },
        // 获取受益人性质 参数type为36
        getDicListByType36() {
            api.getDicListByType({type: 36}).then(res => {
                if (res.success) {
                    this.dicList36 = res.datas.list
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                this.$message.error('操作失败');
            });
        },
        // 获取证件类型 参数type为37
        getDicListByType37() {
            api.getDicListByType({type: 37}).then(res => {
                if (res.success) {
                    this.dicList37 = res.datas.list
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                this.$message.error('操作失败');
            });
        },
        // 获取企业规模 参数type为38
        getDicListByType38() {
            api.getDicListByType({type: 38}).then(res => {
                if (res.success) {
                    this.dicList38 = res.datas.list
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                this.$message.error('操作失败');
            });
        },
        // 获取存款人类别 参数type为39
        getDicListByType39() {
            api.getDicListByType({type: 39}).then(res => {
                if (res.success) {
                this.dicList39 = res.datas.list
                this.ruleform2.depositHuman = res.datas.list.find(v => v.name == '企业法人').code  // 默认企业法人
                } else {
                this.$message.error(res.message);
                }
            }).catch((err) => {
                this.$message.error('操作失败');
            });
        },
        // 获取国籍
        getShBankCountryList() {
        api.getShBankCountryList().then(res => {
            if (res.success) {
                this.bankCountryList = res.datas.bankCountryList
                let temp = res.datas.bankCountryList.find(v => v.twoCode == 'CN')  // 默认中国 内地
                // this.ruleform3.earningOwnerCountry = this.ruleform3.corpCountry = temp.twoCode  // 受益人国籍 默认CN
                // this.ruleform3.corpCountry = temp.numberCode  // 法人国籍 默认156
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            this.$message.error('操作失败');
        });
        },
        getCode() {
            api.sendShSms({mobile: this.ruleform3.corpMobile}).then(res => {
                if (res.success) {
                    this.forbidden1 = true
                    this.countDown()
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                this.$message.error('操作失败');
            })
        },
        async nextbtn() {
            if (this.activebtn == 0) {
                // 受益人 form 组件校验结果
                const companyBeneficiariesValidate = await this.$refs.companyBeneficiaries.getFormListValidate()
                this.$refs["ruleform3"].validate(valid => {
                    if (valid && companyBeneficiariesValidate) {
                        let go = [];
                        for(let e = 0; e < this.shrhldInfo.length; e++){
                            this.$refs['shrhldForm'+e][0].validate(v=>{
                                if(v){
                                go.push(true);
                                }else{
                                go.push(false);
                                }
                            })
                        }
                        let isTrue = go.every(a=>(a==true));
                        if(!isTrue){
                            this.$message.warning('请检查格式是否符合要求并重新填写');
                        }else{
                            this.activebtn++;
                        }
                    } else {
                        return false;
                    }
                });
            } else if (this.activebtn == 1) {
                this.$refs["ruleform2"].validate(valid => {
                    if (valid) {
                        this.activebtn++;
                    } else {
                        return false;
                    }
                });
            } else if (this.activebtn == 2) {
                this.$refs["ruleform1"].validate(valid => {
                    if (valid) {
                        // this.activebtn++;
                        this.applyDialog = true
                        this.ruleform4.dynamicCode = ''
                        this.forbidden1 = true
                        this.forbidden2 = false
                        // 上海银行发送短信
                        if(this.count <= 0) {
                            api.sendShSms({mobile: this.ruleform3.corpMobile}).then(res => {
                                if (res.success) {       
                                    this.countDown()
                                } else {
                                    this.countDown()
                                    this.$message.error(res.message);
                                }
                            }).catch((err) => {
                                this.$message.error('操作失败');
                            });
                        }
                    } else {
                        return false;
                    }
                });
            } else {
                this.activebtn = 0;
            }
        },
        goback() {
            this.$router.go(-1)
        },
        prevbtn() {
            this.activebtn--;
        },
        submit() {
            this.$refs["ruleform4"].validate(valid => {
                if(valid) { 
                    this.forbidden2 = true
                    this.ruleform2.companyName = this.ruleform2.companyName || '没有中文名称' // 如果客户没有中文名称,请填写"没有中文名称"
                    this.ruleform2.companyDesc = this.ruleform2.companyDesc || 'No English Name'  // 如果客户没有英文名称,请填写"No English Name"
                    this.ruleform2.confirmationDate = this.ruleform2.confirmationDate || '20990101'  // 营业执照到期日
                    this.ruleform3.corpIdLimitDate = this.ruleform3.corpIdLimitDate || '20990101'  // 法人到期日
                    this.ruleform3.checkerIdLimitDate = this.ruleform3.checkerIdLimitDate || '20990101'  // 经办人到期日
                    this.ruleform3.earningOwnerLimitDate = this.ruleform3.earningOwnerLimitDate || '20990101'  // 受益人证件到期日
                    this.ruleform3.cfoType = this.ruleform3.appApplyType

                    // 受益人信息  提交获取值  this.ruleform3.earningOwnerList
                    this.ruleform3.earningOwnerList = this.$refs.companyBeneficiaries.getFormatFormList()
                    // 股东 实控人
                    this.ruleform3.shrhldList = this.shrhldInfo.map(a=>{
                        return a.shrhldForm;
                    });
                    // 上海银行开户
                    let params = {}
                    Object.assign(params, this.ruleform1, this.ruleform2, this.ruleform3, this.ruleform4);
                    params.tranType = this.isEdit ? 2 : 1 // 1开户 2变更

                      api.openAccount(params).then(res => {
                        if (res.success) {
                          this.$alert("开户申请成功，等待审核", "", {
                            confirmButtonText: "确定",
                            callback: action => {
                              this.forbidden2 = false
                              this.applyDialog = false
                              this.goback()
                            }
                          });
                        } else {
                          this.forbidden2 = false
                          this.$message.error(res.datas.obj.resultDesc);
                        }
                      }).catch((err) => {
                        this.forbidden2 = false
                        this.$message.error('操作失败');
                      });
                } else {
                    return false;
                }
            })

        },
        countDown() {
            this.count = 60;
            this.countTimer = setInterval(() => {
                this.count --;
                if (this.count <= 0) {
                this.forbidden1 = false
                clearInterval(this.countTimer);
                }
            }, 1000);
        },
        changeBank(val) {
            this.ruleform2.acctBank = val.code
            this.ruleform2.acctBankName = val.name
        },
        // 删除股东信息
        deleteInfo(val, key) {
            if(this.shrhldInfo.length<=1){
                this.$message.warning('至少保留一个股东/实控人');
            }else{
                this.$confirm('确认删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.shrhldInfo.splice(key, 1);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.$nextTick(() => {
                        this.clearShrhldInfoValidate()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
            }
        },
        clearShrhldInfoValidate() {
            _.forEach(this.shrhldInfo, (item, index) => {
                this.$refs['shrhldForm'+ index][0].clearValidate()
            })
        },
        // 添加股东信息
        addInfo() {
            this.shrhldInfo.push(
                {
                shrhldRules: { // 验证规则
                    shrhldType: [
                    { required: true, message: "请选择股东性质", trigger: "change" }
                    ],
                    shrhldNameQ: [
                    { required: true, message: "请输入企业名称", trigger: "blur" }
                    ],
                    shrhldNameG: [
                    { required: true, message: "请输入股东姓名", trigger: "blur" }
                    ],
                    shrhldIdType: [
                    { required: true, message: "请选择证件类型", trigger: "change" }
                    ],
                    shrhldIdNoQ: [
                    { required: true, message: "请输入证件号码", trigger: "blur" }
                    ],
                    shrhldIdNoG: [
                    { required: true, message: "请输入证件号码", trigger: "blur" },
                    { pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: "请输入正确的证件号码", trigger: "blur"}
                    ],
                    shrhldIdLimitDate: [
                    { required: true, message: "请选择证件到期日", trigger: "change" }
                    ],
                    shrhldCountry: [
                    { required: true, message: "请选择股东国籍或者企业注册地", trigger: "change" }
                    ],
                    shrhldPercent: [
                    {  validator: check1, trigger: "blur"},
                    { required: true, message: "请输入持股比例" }
                    ],
                },
                shrhldForm: {  // 股东/实控人 form
                    shrhldType: '',
                    shrhldName: '',
                    shrhldIdType: '',
                    shrhldIdNo: '',
                    shrhldIdLimitDate: '',
                    shrhldPercent: '',
                    shrhldCountry: 'CN'
                }
                }
            )
        },
        // 修改办理类型
        handleChangeAppApplyType(type) {
            if(type === 1) {
                this.ruleform1.delegationImg = '';
                this.ruleform1.delegationImgUrl = '';
                this.fileList1 = [];
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import "../../styles/register/company.scss";
.open-account {
  padding-bottom: 50px;
}
.wxts-box {
  background: #ebf4ff;
  border-radius: 4px;
  padding: 6px 16px;
  border: 1px solid #cce0ff;
  line-height: 22px;
  margin-bottom: 16px;
  .wxts-text {
    display: inline-block;
    color: rgba(0, 0, 0, 0.45);
    margin: 8px 0;
    i {
      color: #2C80FF;
      font-size: 24px;
      vertical-align: middle;
    }
  }
}
.downloadWord {
  vertical-align: middle;
  width: 300px;
  height: 92px;
  // line-height: 92px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  text-align: center;
  margin-left: 8em;
  margin-top: 2em;
}
.company1 {
  margin-left: 0;
  margin-top: 0;
}
.person-info {
  padding: 12px 12px 0;
  margin: 8px 12px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  /* .person-type {

  } */
}


.yfdbaseline {
  border-bottom: none;
  padding-bottom: 18px;
  margin-bottom: 37px;
}
// .easypayby {
//   padding-bottom: 40px;
// }
.yfdtwo {
  padding: 20px 42px;
  .el-input.is-disabled .el-input__inner {
    color: rgb(96, 98, 102);
  }
}

.httotal {
  height: 35px;
  background: #fff3f3;
  border-radius: 4px;
  line-height: 35px;
  padding-left: 17px;
  margin-bottom: 18px;
  color: #828282;
  span {
    color: #da2b2b;
    line-height: 22px;
  }
}
.formstyle {
  & > div {
    margin-bottom: 20px;
  }

  .el-select,
  .el-date-editor {
    width: 100%;
  }
  .elspan {
    .el-input__inner {
      border-radius: 4px 0 0 4px;
    }
    .el-input {
      width: 80%;
    }
    .el-form-item__content {
      span {
        width: 20%;
        display: inline-block;
        height: 40px;
        background: #f7f7f7;
        border: 1px solid #d9d9d9;
        border-radius: 4px 0 0 4px;
        border-radius: 0 4px 4px 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 40px;
        border-left: none;
        position: relative;
        top: 1px;
        text-align: center;
      }
    }
  }
  .pec.elspan {
    .el-input {
      width: 90%;
    }
    .el-form-item__content {
      span {
        width: 10%;
      }
    }
  }
  .el-table__body-wrapper {
    input[type="text"] {
      border: 1px solid #fff;
    }
    .el-table__row {
      input {
        padding: 6px 10px;
      }
    }
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #fff !important;
  }
  .htbox {
    border-radius: 4px;
    border: 1px solid #cfcfcf;
    .htpad {
      padding: 0 17px;

      form {
        overflow: hidden;
      }
    }
    .htactive {
      height: 43px;
      overflow: hidden;
      background: #f1f1f1;
      line-height: 43px;
      span {
        display: inline-block;
        width: 100px;
        margin-left: 17px;
        // float: left;
        cursor: pointer;
      }
    }
    .htno {
      height: 90px;
      border-bottom: 1px solid #e6e6e6;
      padding-top: 25px;
    }
    .uploadfont {
      color: rgba(0, 0, 0, 0.45);
      line-height: 42px;
      span {
        text-indent: 8em;
        display: inline-block;
      }
    }
    .uploadlist {
      padding: 30px 16px 0 16px;
      .upload-demo {
        margin-left: 8em;
        margin-top: 2em;
      }
      .el-form-item {
        margin-bottom: 40px;
      }

      .el-form-item__label {
        font-size: 16px;
        color: #000000;
      }
      .el-upload-dragger {
        width: 300px;
      }
      .el-upload-dragger .el-upload__text,
      .el-icon-plus {
        color: rgba(0, 0, 0, 0.45);
      }
      .el-icon-plus {
        font-size: 30px;
      }
    }
    .el-icon-delete {
      float: right;
      padding: 12px 20px 0 0;
      cursor: pointer;
      font-size: 18px;
    }
    .ljje {
      text-align: right;
      margin-bottom: 40px;
      color: #595959;
      span {
        color: #f5212d;
      }
    }
  }
}
.easyformbtn {
  position: fixed;
  right: 16px;
  bottom: 0;
  width: calc(100vw - 262px);
  text-align: center;
  padding: 12px 0 24px;
  background-color: #fff;
  border: 1px solid #ccc;
  button {
    width: 160px;
    height: 40px;
    font-size: 16px;

    text-align: center;
    line-height: 18px;
    span {
      color: #ffffff;
    }
  }
  button:nth-last-child(1) {
    background-color: #2937a6;
  }
  button:nth-last-child(2) {
    span {
      color: #595959;
    }
  }
  button:hover {
    background-color: #edf5ff;
    span {
      color: #2937a6;
    }
  }
}
.formstyle {
  .active {
    height: 133px;
    overflow: hidden;
  }
  .iconactive {
    transform: scaleY(-1);
  }
}
.addbtn {
  border: 1px dashed #cfcfcf;
  height: 44px;
  text-align: center;
  border-radius: 4px;
  line-height: 44px;
  font-size: 14px;
  color: #828282;
  cursor: pointer;
  margin: 8px 12px;
  i {
    margin-right: 6px;
  }
}
.yfdsize {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  margin-bottom: 29px;
}
.wxts {
  color: rgba(0, 0, 0, 0.45);
  margin: 8px 0;
}
.pt16 {
  position: relative;
  top: -16px;
}
.appQRcodeBox {
  display: block;
  margin-top: 20px;
  .appQRcodeImg {
    width: 49%;
    text-align: center;
    img {
      width: 90%;
      display: block;
    }
  }
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
}
.account-warn {
  background-color: #fdf6ec;
  color: #e6a23c;
  padding: 8px 16px;
  margin: 10px 0;
  border-radius: 4px;
  span {
    display: inline-block;
    cursor: pointer;
    color: #2937A6;
    text-decoration:underline;
  }
}
.imgClass {
  height: 160px;
}
</style>
<style lang="scss">
  .uploadlist .upload-video .el-upload-dragger {
    width: auto !important;
    height: auto !important;
    border: none;
  }
</style>