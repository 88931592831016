<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column
            prop="payableBillFinanceNo"
            label="融资项目编号"
            min-width="170"
          ></el-table-column>
          <el-table-column
            prop="financePayableBillNo"
            label="融资子银e券编号"
            min-width="220"
          ></el-table-column>
          <el-table-column
            prop="financeCorpName"
            label="融资企业"
            min-width="170"
          ></el-table-column>
          <el-table-column prop="amount" label="融资金额（元)" min-width="150">
            <template slot-scope="scope">{{
              $changeMoneyThousand(scope.row.amount)
            }}</template>
          </el-table-column>
          <el-table-column
            prop="financeYearInterest"
            label="融资年化利率（％）"
            min-width="150"
          ></el-table-column>
          <el-table-column
            prop="preFinanceInterest"
            label="融资利息(元)"
            min-width="150"
          ></el-table-column>
          <el-table-column
            prop="preIncomeAmount"
            label="到帐金额(元)"
            min-width="150"
          ></el-table-column>
          <el-table-column
            prop="financePayStatus"
            label="融资状态"
            min-width="150"
          >
            <template slot-scope="scope">
              <span>{{ getFinancePayStatus(scope.row.financePayStatus) }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="status" label="银e券状态" width="100" align="left"></el-table-column> -->
          <el-table-column label="操作" width="160">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="preview(scope.row)"
                >预览</el-button
              >
              <el-button
                size="medium"
                type="text"
                @click="handleEdit(scope.row)"
                >查看合同</el-button
              >
              <div>
                <el-button
                  size="medium"
                  type="text"
                  @click="handleDownload(scope.row)"
                  >下载融资合同</el-button
                >
              </div>
              <!-- <el-button
                size="medium"
                type="text"
                v-if="!scope.row.flag"
                :disabled="
                  scope.row.financePayStatus == 3 ||
                    scope.row.financePayStatus == 4 ||
                    scope.row.financePayStatus == 6
                "
                @click="uploadDialog(scope.row)"
                >上传放款凭证</el-button
              > -->
              <!-- <el-popover trigger="hover" placement="top" v-if="scope.row.flag">
                <p>银e券即将到期，不能上传放款凭证</p>
                <div slot="reference" class="name-wrapper">
                  <el-button size="medium" disabled type="text"
                    >上传放款凭证</el-button
                  >
                </div>
              </el-popover> -->
              <!-- <el-upload v-if="scope.row.financePayStatus == 1"
                                       class="upload-demo upload-list"
                                       :action="uploadFile()"
                                       :before-upload="beforeUp"
                                       :on-success="(a)=>uploadOk(a)"
                                       :data="fileTypes"
                                       :headers="Authorization"
                            >
                                <el-button size="medium" type="text" v-if="!scope.row.flag" @click="loan(scope.row)">上传放款凭证</el-button>
																<el-popover trigger="hover" placement="top" v-if="scope.row.flag">
																	<p>银e券即将到期，不能上传放款凭证</p>
																	<div slot="reference" class="name-wrapper">
																		<el-button size="medium" disabled type="text" @click="loan(scope.row)">上传放款凭证</el-button>
																	</div>
																</el-popover>   
                            </el-upload> -->
              <div
                v-if="
                  scope.row.financePayStatus == 3 ||
                    scope.row.financePayStatus == 4 ||
                    scope.row.financePayStatus == 6
                "
              >
                <el-button
                  size="medium"
                  type="text"
                  @click="loanDetial(scope.row)"
                  >查看放款凭证</el-button
                >
              </div>
               <el-button size="medium" type="text" @click="handleDetail(scope.row)">详情</el-button>
              <!-- <el-button v-if="scope.row.financePayStatus == 5 || scope.row.financePayStatus == 7" size="medium" type="text" @click="change(scope.row)" style="margin-left: 0">修改放款凭证</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
    <el-dialog
      title
      :visible.sync="imgDialogVisible"
      width="80%"
      center
      class="img-dialog"
    >
      <!-- <img v-for="item in fileDatas" :key="item.fileUrl"
                    :src="api.getImgURL(item.fileUrl)"
                    alt="图片丢失"
                    width="30%"
                    height="30%"
                    :preview-text="item.fileName"
                    :preview="1"
            /> -->
      <div class="img-box" v-if="fileDatas">
        <div
          class="img-box-item"
          v-for="(item, index) in fileDatas"
          :key="item.fileUrl + index"
        >
          <img
            v-if="
              item.fileUrl
                .substring(item.fileUrl.lastIndexOf('.'))
                .toLowerCase() == '.pdf'
            "
            :src="pdfImg"
            alt="图片丢失"
            width="30%"
            height="30%"
            @click="previewPdf(item.fileUrl)"
          />
          <img 
              v-else-if="excelfileExtend.indexOf(item.fileUrl.substring(item.fileUrl.lastIndexOf('.')).toLowerCase()) == -1 && item.fileUrl.substring(item.fileUrl.lastIndexOf('.')).toLowerCase()!=='.pdf'"
              :src="zip"
                    alt="图片丢失"
                    width="30%"
                    height="30%"
                   @click="previewPdf(item.fileUrl)"
            />
          <img
            v-else
            :src="api.getImgURL(item.fileUrl)"
            alt="图片丢失"
            width="30%"
            height="30%"
            :preview-text="item.fileName"
            :preview="1"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="imgDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title
      :visible.sync="imgChangeDialogVisible"
      width="80%"
      center
      class="img-dialog dialog-table"
    >
      <div
        style="text-align: left;font-weight: bold;width: 80%;margin: 0 auto 10px;"
      >
        放款凭证审核记录
      </div>
      <div
        style="text-align: left;width: 80%;margin: 0 auto 10px;line-height: 32px;"
      >
        <div>退回原因：{{ logListData.reson }}</div>
        <div>备注：{{ logListData.remark }}</div>
      </div>
      <div
        style="text-align: left;font-weight: bold;width: 80%;margin: 0 auto 10px;"
      >
        放款凭证
      </div>
      <img
        :src="api.getImgURL(file.fileUrl)"
        alt="图片丢失"
        width="30%"
        height="30%"
        :preview-text="file.fileName"
        :preview="2"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="imgChangeDialogVisible = false">取 消</el-button>
        <el-upload
          class="upload-demo upload-list"
          style="display: inline-block;margin-left: 30px;"
          :action="uploadFile()"
          :before-upload="beforeUp"
          :on-success="(a) => uploadOk(a)"
          :data="fileTypes"
          :headers="Authorization"
        >
          <el-button>重新上传</el-button>
        </el-upload>
      </span>
    </el-dialog>
    <el-dialog title :visible.sync="centerDialogVisible" width="80%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <pdf class="pdf" ref="pdf" :src="src"></pdf>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- <el-dialog
      title="上传放款凭证"
      :visible.sync="showUpload"
      width="80%"
      center
    >
      <div
        v-if="backFlag"
        style="text-align: left;font-weight: bold;width: 90%;margin: 0 auto 10px;"
      >
        放款凭证审核记录
      </div>
      <div
        v-if="backFlag"
        style="text-align: left;width: 90%;margin: 0 auto 10px;line-height: 32px;"
      >
        <div>退回原因：{{ logListData.reson }}</div>
        <div>备注：{{ logListData.remark }}</div>
      </div>
      <div class="upload-box">
        <el-upload
          ref="uploadFile"
          class="upload-demo"
          :action="baseURL + '/web/file/uploadFile'"
          :headers="Authorization"
          list-type="picture-card"
          :on-success="handleAvatarSuccess"
          :on-remove="handleRemove"
          :on-preview="handlePreview"
          :before-upload="beforeAvatarUpload"
          :file-list="fileList"
        >
          <i class="el-icon-plus"></i>
          <span class="upload-info">上传</span>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showUpload = false">取消</el-button>
        <el-button @click="confirmSubmit">确定</el-button>
      </span>
    </el-dialog> -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/api";
import upload from "@/api/upload.js";
import moment from "moment";
import pdf from "vue-pdf";
import { baseURL } from "@/api/sys";
import pdfImg from "@/assets/file.png";

function openDownloadDialog(url, saveName) {
  // for ie 10 and later
  if (window.navigator.msSaveBlob) {
    try {
      window.navigator.msSaveBlob(url, saveName);
    } catch (e) {
      console.log(e);
    }
  }
  // 谷歌浏览器 创建a标签 添加download属性下载
  else {
    if (typeof url == "object" && url instanceof Blob) {
      url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement("a");
    aLink.href = url;
    aLink.download = saveName || ""; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) {
      event = new MouseEvent("click");
    } else {
      event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
    }
    aLink.dispatchEvent(event);
  }
}

export default {
  components: {
    pdf,
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      pdfImg,
      showUpload: false,
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: "融资项目编号:",
          type: "input",
          field: "payableBillFinanceNo",
          size: "small",
        },
        // {
        //     label: "银e券编号:",
        //     type: "input",
        //     field: "payableBillNo",
        //     size: "small"
        // },
        {
          label: "融资企业:",
          type: "input",
          field: "financeCorpName",
          size: "small",
        },
        {
          label: "融资金额: ",
          type: "inputrange",
          field: "financeAmountMin",
          size: "small",
          isSeparated: 1,
        },
        {
          type: "inputrange",
          field: "financeAmountMax",
          size: "small",
        },
        // {
        //     label: "银e券利率: ",
        //     type: "inputrange",
        //     field: "financeInterestMin",
        //     size: "small",
        //     isSeparated: 1
        // },
        // {
        //     type: "inputrange",
        //     field: "financeInterestMax",
        //     size: "small"
        // },
        // {
        //     label: "承诺付款日:",
        //     type: "daterange",
        //     field: ["promiseStartTime", "promiseEndTime"],
        //     size: "small"
        // }
      ],
      api: api,
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      pageNo: 1,
      pageSize: 10,
      fileTypes: {
        payableBillFinanceId: "",
      },
      Authorization: { Authorization: sessionStorage.token },
      fileUrl: "",
      payableBillFinanceFileList: [],
      file: {},
      imgDialogVisible: false,
      filters: {},
      src: "",
      centerDialogVisible: false,
      imgChangeDialogVisible: false,
      logListData: [],
      loading: "",
      fileList: [],
      loading: {},
      baseURL,
      excelfileExtend: ".jpg,.png,.jpeg,.bmg,.gif",
      curPayableBillFinanceId: "",
      fileDatas: [],
      backFlag: false,
      fullPaths:[]
    };
  },
  created() {
    this.activeName = this.$route.params.state;
  },
  methods: {
    // 文件回显
    getFile(list, expectList, name) {
      if (list.length > 0) {
        list.forEach((v) => {
          let filetype = v.fileUrl
            .substring(v.fileUrl.lastIndexOf("."))
            .toLowerCase();
          let temp = {};
          temp = {
            name: v.fileName,
            url:
              this.excelfileExtend.indexOf(filetype) == -1 &&
              filetype !== ".pdf"
                ? require("@/assets/zip.png")
                : filetype == ".pdf"
                ? require("@/assets/file.png")
                : api.getImgURL(v.fileUrl), // 缩略图
            trueUrl: api.getImgURL(v.fileUrl), // 真实的文件地址
          };
          expectList.push(temp);
        });
      }
      console.log(expectList.map(_=>{
       return  _.trueUrl.split("=")[1]
      }), "expectList");
    },
    previewPdf(item) {
      window.open(this.baseURL + "/web/file/downFile?filePath=" + item);
    },
    uploadDialog(row) {
      this.backFlag = false
      api
        .GetPayProof({ payableBillFinanceId: row.payableBillFinanceId })
        .then((res) => {
          if (res.success) {
            this.payableBillFinanceFileList =
              res.datas.payableBillFinanceFileList;
            this.fileDatas = this.payableBillFinanceFileList;
            if (this.payableBillFinanceFileList.length > 0) {
              this.getLogLists(row.payableBillFinanceId);
              if (row.financePayStatus == 5 || 7) {
                this.backFlag = true;
                let fileList = [];
                this.fileDatas.map((_) => {
                  fileList.push({
                    fileUrl: _.fileUrl,
                    fileName: __filename,
                  });
                });
                this.getFile(fileList, this.fileList, "付款凭证");
              }
            }
          } else {
            this.$message.error(res.message);
          }
        });
      // this.showUpload = true;
      this.curPayableBillFinanceId = row.payableBillFinanceId;
      this.fileList = [];
    },
    confirmSubmit() {
      let fullPaths = []
      if (this.fileList.length > 0 && !this.fileList.trueUrl) {
        this.fileList.map((_) => {
          if (!_.trueUrl) {
              fullPaths.push({
                fileName: _.name,
                filePath: _.response.datas.uploadDate.message,
              });
          } else {
             fullPaths.push({
                fileName: _.name,
                filePath: _.trueUrl.split("=")[1],
            });
          }
        });
      }
      let params = {
        payableBillFinanceId: this.curPayableBillFinanceId,
        fullPaths:fullPaths,
      };
      console.log(params,'params')
      if (!Boolean(params.fullPaths.length)) {
        this.$message.error("请上传放款凭证！");
        return false;
      }
      api
        .uploadProo(params)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.showUpload = false;
            this.handleRefresh(this.filters);
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {});
    },
    beforeAvatarUpload(file) {
      let excelfileExtend = ".jpg,.pdf,.png,.jpeg,.bmg"; //设置文件格式
      let fileExtend = file.name
        .substring(file.name.lastIndexOf("."))
        .toLowerCase();
      if (excelfileExtend.indexOf(fileExtend) <= -1) {
        this.$message.error("文件格式错误");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      console.log("file =======>", file);
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 10MB!");
        return false;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.loading = loading;
    },
    handleAvatarSuccess(res, file, fileList) {
      if (res.success) {
        this.$message.success("上传成功");
        this.fileList = fileList;
        console.log(this.fileList, "handleAvatarSuccess");
        let name = file.name;
        let filetype = name.substring(name.lastIndexOf(".")).toLowerCase();
        if (
          this.excelfileExtend.indexOf(filetype) == -1 &&
          filetype !== ".pdf"
        ) {
          file.url = require("@/assets/zip.png");
        } else if (filetype == ".pdf") {
          file.url = require("@/assets/file.png");
        }
      } else {
        this.$message.error(res.message);
        let uid = file.uid; // 关键作用代码，去除文件列表失败文件
        let index = this.$refs.uploadFile.fileList.length;
        let idx = this.$refs.uploadFile.fileList.findIndex(
          (item) => item.uid === uid
        ); // 关键作用代码，去除文件列表失败文件（fileList为el-upload中的ref值）
        console.log(idx, "=======", this.$refs.uploadFile.fileList);
        this.$refs.uploadFile.fileList.splice(index, 1); // 关键作用代码，去除文件列表失败文件
      }
      this.loading.close();
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      console.log(this.fileList, "fileList");
    },
    handlePreview(file) {
      console.log(file, "file");
      if (file.response) {
        let type = file.name
          .substring(file.name.lastIndexOf("."))
          .toLowerCase();
        // file.name
        // .substring(file.name.lastIndexOf("."))
        // .toLowerCase()
        if (type == ".pdf") {
          window.open(api.getImgURL(file.response.datas.uploadDate.message));
        } else {
          this.dialogImageUrl = file.url;
          this.dialogVisible = true;
        }
      } else {
        // 回显的数据
        window.open(file.trueUrl);
      }
    },
    moment: moment,
    startLoading: function() {
      this.loading = this.$loading({
        lock: true,
        text: "文件上传中",
        background: "rgba(0, 0, 0, 0.5)",
      });
    },
    endLoading: function() {
      this.loading.close();
    },
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      console.log("filters<", filters);
      (filters.review = 1), (filters.source = 2), (this.filters = filters);
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      param.type = 1;
      api.GetSignApproveList(param).then((res) => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
        this.listLoading = false;
      });
    },
    handleEdit(row) {
      this.$router.push({
        name: "资方融资签约详情",
        query: {
          payableBillFinanceId: row.payableBillFinanceId,
          payableBillFinanceNo: row.payableBillFinanceNo,
          fundCorpId: row.fundCorpId,
          isshowbtn: 2,
          financePayStatus: row.financePayStatus,
          financeSignStatus: row.financeSignStatus,
          approveStatus: row.approveStatus,
          financeSignStatus: row.financeSignStatus,
        },
      });
    },
    handleDetail(row) {
        this.$router.push({
            path: "/easypayApproveManage/easypayFinancing/recheckDetial",
            query: {
                        id: row.payableBillId,
                        type: "2",
                        payableBillFinanceId: row.payableBillFinanceId,
                        isshowbtn: 2,
                        financePayStatus: row.financePayStatus,
                        approveStatus: row.approveStatus,
                        financeSignStatus: row.financeSignStatus,
                        alrealyFlag:true
                    }
                });
            },
    uploadFile() {
      return upload.uploadProof();
    },
    uploadOk(response) {
      if (response.code == "100000") {
        // 取消蒙层
        this.endLoading();
        this.$message.success("上传成功");
        this.signDialogVisible = false;
        this.imgChangeDialogVisible = false;
        this.handleRefresh(this.filters);
      } else {
        // 取消蒙层
        this.endLoading();
        this.$message.error("上传失败");
      }
    },
    // loan(row) {
    //   this.fileTypes.payableBillFinanceId = row.payableBillFinanceId;
    // },
    //上传前配置
    beforeUp(file) {
      let excelfileExtend = ".jpg,.png,.jpeg,.bmg"; //设置文件格式
      let fileExtend = file.name
        .substring(file.name.lastIndexOf("."))
        .toLowerCase();
      if (excelfileExtend.indexOf(fileExtend) <= -1) {
        this.$message.error("文件格式错误");
        return false;
      }
      // 加蒙层
      this.startLoading();
    },
    loanDetial(row) {
      api
        .GetPayProof({ payableBillFinanceId: row.payableBillFinanceId })
        .then((res) => {
          if (res.success) {
            this.payableBillFinanceFileList =
              res.datas.payableBillFinanceFileList;
            this.fileDatas = this.payableBillFinanceFileList;
            if (this.payableBillFinanceFileList.length > 0) {
              this.file = this.payableBillFinanceFileList[0];
              console.log(
                this.payableBillFinanceFileList,
                "payableBillFinanceFileList"
              );
              this.imgDialogVisible = true;
              this.$previewRefresh();
            }
          } else {
            this.$message.error(res.message);
          }
        });
    },
    change(row) {
      this.fileTypes.payableBillFinanceId = row.payableBillFinanceId;
      this.getLogLists(row.payableBillFinanceId);
      api
        .GetPayProof({ payableBillFinanceId: row.payableBillFinanceId })
        .then((res) => {
          if (res.success) {
            this.payableBillFinanceFileList =
              res.datas.payableBillFinanceFileList;
            if (this.payableBillFinanceFileList.length > 0) {
              this.file = this.payableBillFinanceFileList[0];
              this.imgChangeDialogVisible = true;
              this.$previewRefresh();
            }
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // changeImg(row) {

    // },
    // 预览银e券
    preview(row) {
      window.open(api.getImgURL(row.evidencePath));
      // this.listLoading = true;
      // api.DownloadContract({ contractNo: row.contractNo }).then(res => {
      //   let binaryData = [];
      //   binaryData.push(res);
      //   this.src = window.URL.createObjectURL(
      //     new Blob(binaryData, { type: "application/zip" })
      //   );
      //   this.centerDialogVisible = true;
      //   this.listLoading = false;
      // });
    },
    // 获取放款凭证审核日志
    getLogLists(payableBillFinanceId) {
      let para = {
        payableBillFinanceId: payableBillFinanceId,
        pageNum: 1,
        pageSize: 10,
      };
      api.approveRecord(para).then((res) => {
        if (res.success) {
          this.logListData = res.datas.list[0];
        }
      });
    },
    getFinancePayStatus(status) {
      if (status == -1) {
        return "放款失败";
      } else if (status == 1) {
        return "未放款";
      } else if (status == 2) {
        return "放款中";
      } else if (status == 4) {
        return "企业待确认";
      } else if (status == 5) {
        return "企业退回";
      } else if (status == 6) {
        return "平台待确认";
      } else if (status == 7) {
        return "平台退回";
      } else if (status == 3) {
        return "已放款";
      }
    },
    // 比较时间(承诺付款日)
    calcDiff(row) {
      let flag = false;
      if (row.promiseFinanceTime) {
        const diff = moment(row.promiseFinanceTime).diff(moment(), "days");
        if (diff < 3) flag = true;
      }
      return flag;
    },
    // 下载融资合同
    handleDownload(row) {
      api
        .filesdownsingle({ filePath: row.financeSignUrl })
        .then((res) => {
          openDownloadDialog(res, "融资合同");
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/moreDetail/moudleDetail.scss";
.common-page-dataList {
  .v-modal {
    opacity: 0 !important;
  }
}
.path {
  margin-left: 20px;
}

.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;

  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;

    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }

  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;

    .itemTop {
      margin-top: 10px;
    }

    .fl {
      float: left;
    }

    .fr {
      float: right;
    }

    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;

      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }

      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
.img-dialog.dialog-table .el-dialog__body {
  text-align: left;
  img {
    // margin: 0 auto;
    display: block;
  }
}
.upload-box {
  margin-top: 10px;
  padding-left: 50px;
  .el-upload--picture-card {
    position: relative;
  }
  .upload-info {
    position: absolute;
    left: 39%;
    top: 32%;
  }
}
.img-box {
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  > .img-box-item {
    margin-right: 20px;
    height: 200px;
    width: 200px;
    margin-bottom: 20px;
    > img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}
</style>
<style lang="less" scoped>
.v-modal {
  opacity: 0 !important;
}
</style>
