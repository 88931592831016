<template>
  <footer class="layout-footer">
    <!--<ul class="f-nav">-->
    <!--<li><router-link to="/">帮助</router-link></li>-->
    <!--<li><router-link to="/">隐私</router-link></li>-->
    <!--<li><router-link to="/">条款</router-link></li>-->
    <!--</ul>-->
    <!-- <p class="f-info">Copyright 2022 广西银壹数字科技有限公司 版权所有</p>
		<p class="f-info">联系我们：gxyinyi@sina.com</p> -->
  </footer>
</template>
<script>
export default {
  name: 'layout-footer',
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
