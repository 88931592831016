<template>
		<div class="headerLogin" v-if="isTitle">
			<div class="HeaderLogin_Content">
				<div class="headerLogin_img"><img src="../../assets/LOGO-like-fff.png" alt="" ></div>
				<div class="headerLogin_title">银e券供应链金融</div>
				<div class="headerLogin_tag">
					<template v-if="!isEmpty(userInfo)">
						<span class="hover-hand" style="font-size:18px; margin-right:10px;">欢迎 {{userInfo.name}}</span>
						<span style="" ><span class="hover-hand cursor" @click="logout">退出登录</span><span> |</span> <span  class="hover-hand">修改密码</span></span>
					</template>
					<template v-else>
						<div class="headerLogin_login">已有账号，</div>
						<div class="h_login" @click="clean">请登录</div>
					</template>
				</div>
			</div>
		</div>
</template>

<script>
import * as types from '@/store/mutation-types';
export default {
	name: "HeaderLogin",
	data () {
		return {
			isTitle: true,
			isUse: '/login',
		}
	},
	computed: {
		userInfo(){
			return this.$store.state.userInfo
		}
	},
	methods: {
		has(obj,path){
			return _.has(obj,path)
		},
		isEmpty(obj){
			return _.isEmpty(obj)
		},
		logout () {
			this.$emit('logout', '')
		},
		clean(){
			sessionStorage.removeItem('code')
			sessionStorage.removeItem('enterpriseType')
			sessionStorage.removeItem('token')
			this.$router.push('/login')
		}
	}
}
</script>

<style lang="scss" scoped>
	.headerLogin_tag{
		color: #fff;
	}
	.headerLogin{
		width: 100%;
		height: 80px;
		background-color:#22345E;
	}
	.HeaderLogin_Content{
		width: 1000px;
		height: 80px;
		margin: auto;
		// background-color: #5e222f;
	}
	.headerLogin_img{
		height: 35px;
		margin-left: 0px;
		float: left;
		display: flex;
		align-items: center;
		height: 100%;
		img{
			height: 36px;
		}
	}
	.headerLogin_title{
		float: left;
		font-size: 18px;
		color: #ccddff;
		margin-left: 20px;
		margin-top: 30px;
	}
	.headerLogin_tag{
		float: right;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		.cursor{
			cursor: pointer;
		}
		.h_login{
			display: inline-block;
			cursor: pointer;
			margin-left: 5px;
			color: #1582e5;
		}
	}
	.headerLogin_login{
		// float: right;
		display: inline-block;
		font-size: 14px;
		color: #ccddff;
	}
	.headerLogin_account{
		float: right;
		font-size: 14px;
		color: #1582e5;
		text-decoration: none;
	}
</style>
