import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import NotFound from '../views/404.vue'
import Forbidden from '../views/403.vue'

import Home from '../views/Home.vue'


//注册
// import Register_UserInfo from '../views/register/UserInfo.vue'

import Register_Company from '../views/register/Company.vue'

import Register_Perfect from '../views/register/CompanyPerfect.vue'
import Register_Confirm from '../views/register/CompanyConfirm.vue'
import Register_Result from '../views/register/CompanyResult.vue'
import Register_Video from '../views/register/CompanyVideo.vue'
// ***********************************************核心企业开始***********************************

// 找回密码
import Retrieve_Password from '../views/update-password/RetrievePassword.vue'
//修改密码
import changepassword from '../views/update-password/changepassword.vue'


//注册
import RegisterM from '../views/update-password/register.vue'
//base
import HeaderLogin from '../views/base/headerLogin.vue'
import HeaderBase from '../views/base/headerBase.vue'

//工作台
import Workbench from '../views/workbench/workbench.vue'
// 工作台底部服务阅读协议链接
import serviceAgreement from '../components/agreementList/service-agreement.vue'
// import Company from '../views/workbench/company.vue'//工作台信息完善
//银e券管理
import Easypaymange from '../views/easypay/easypaymange.vue'
//银e券额度管理
import Amountmange from '../views/easypay/amountmange'
//银e券开立
import Easypaytoopen from '../views/easypay/easypaytoopen.vue'
//开立渠道账户
import OpenAccount from '../views/easypay/openAccount.vue'
//银e券详情19-7-17
import Easypaymessage from '../views/easypay/easypaymessage.vue'
//银e券转让
import Easypaytransfer from '../views/base/easypaytransfer.vue'
//企业管理链属
import BusinessManagement from '../views/Business/Business_management.vue'
//企业管理银行
import BankManagement from '../views/Business/Bank_management.vue'

//企业用户管理
import BusinessUserManagement from '../views/Business/Business_user_management'
// 企业信息
import enterpriseInfo from '../views/Business/enterpriseInfo'
// 渠道账户管理-列表
import AccountManagementList from '../views/Business/accountManagementList'
// 渠道账户管理-详情
import AccountManagement from '../views/Business/accountManagement'
// 账务管理
import FinancialManagement from '../views/Business/financialManagement'
// 站内信管理
import messageManagement from '../views/Business/message'
// 企业融资资料设置
import dataConfig from '../views/Business/Data_config'
// 邮寄地址管理
import addressManagement from '../views/Business/addressManagement'

// 2月10号新增
// 对账单管理
import Statement from '../views/statementManage/Statement.vue'
// 对账单详情
import StatementDetail from '../views/statementManage/StatementDetail.vue'
// 收费协议管理
import AgreementManage from '../views/agreementManage/Agreement.vue'



//银e券审批
//融资审批
import FinancingApprove from '../views/easypayApproveManage/easypayFinancing/financingApprove.vue'
//融资审批 详情
import RecheckDetial from '../views/easypayApproveManage/easypayFinancing/recheckDetial.vue'

//审批管理
//开立审批
import Openapproval from '../views/createmange/openapproval.vue'
//邀请开单审批
import Openrequestapproval from '../views/createmange/openrequestapproval.vue'

//开立审批初审详情
import EasypayfirstDetial from '../views/base/easypayfirstDetial.vue'
import Lseasypayfirstdetial from '../views/base/lseasypayfirstDetial.vue'
//开立审批请求详情
import EasypaysecondDetial from '../views/base/easypaysecondDetial.vue'
//开立审批 已退回
import Easypaygoback from '../views/base/easypaygoback.vue'
//清分审批
import Distinguishingapproval from '../views/createmange/distinguishingapproval.vue'
import FundDistinguishingapproval from '../views/createmange/fundDistinguishingapproval.vue'
//最新
import Distinguishingapprovalfix from '../views/createmange/distinguishingfix.vue'

//清分审批详情
import Distinguishingapprovaldetail from '../views/createmange/distinguishingapprovaldetail.vue'
import DistinguishingapprovalFail from '../views/createmange/checkFail.vue'

// 逐级清分审批
import CascadeClearingCheck from '../views/cascadeClearingCheck/distinguishingapproval.vue'
// 逐级清分审批详情
import CascadeClearingCheckDetail from '../views/cascadeClearingCheck/distinguishingapprovaldetail.vue'


//流转详情 母单
import Easylzdetial from '../views/base/easylzdetial.vue'
//流转详情子单
import Easylzdetialchildren from '../views/base/easylzdetialchild.vue'
// 已清分流转详情
import ClearEasylzdetial from '../views/base/clearEasylzdetial.vue'



//融资签约
import financingSign from '../views/financingProjectManage/easypaySign/financingSign.vue'
//签约详情
import SignDetial from '../views/financingProjectManage/easypaySign/signDetial.vue'

//银e券链属企业11111111111111111111111111111111

//链属企业创建银e券
import Lseasypaitoopen from '../views/lscreatedeasyopen/lseasypaitoopen.vue'

//银e券管理
import Lseasypaymange from '../views/lseasypayManager/lseasypaymange.vue'



/************* 资方机构 ************* */
//
import FundHome from '../fund/home/home.vue'
//开立审批初审
import FundOpenApprove from '../fund/easyPayApprove/openApprove/first/openApprove.vue'
//开立审批复审
import FundSecondOpenApprove from '../fund/easyPayApprove/openApprove/second/openApprove.vue'
//开立审批终审
import FundThreeOpenApprove from '../fund/easyPayApprove/openApprove/three/openApprove.vue'
//开立详情
import FundOpenApprovalDetial from '../fund/easyPayApprove/openApprove/openApprovalDetial.vue'

//银e券审批
//融资审批初审
import FundFinancingApprove from '../fund/easyPayApprove/financingApprove/first/financingApprove.vue'
//融资审批复审
import FundSecondFinancingApprove from '../fund/easyPayApprove/financingApprove/second/financingApprove.vue'
//融资审批终审
import FundThreeFinancingApprove from '../fund/easyPayApprove/financingApprove/three/financingApprove.vue'



//融资审批 详情
import FundRecheckDetial from '../fund/easyPayApprove/financingApprove/recheckDetial.vue'
//融资签约
import FundFinancingSign from '../fund/financingManage/easypaySign/financingSign.vue'
//融资签约管理
import FundFinancingProject from '../fund/financingManage/financingProject/index.vue'

//签约详情
import FundSignDetial from '../fund/financingManage/easypaySign/signDetial.vue'
import FundFinancingProjectDetial from '../fund/financingManage/financingProject/detail.vue'
//已清分详情
import ClearEasypayDetail from '../fund/financingManage/easypaySign/clearEasypayDetail.vue'

//清分管理
import ClearCloseManage from '../fund/easyPayApprove/clearClose/clearCloseManage.vue'
import ClearCloseDetial from '../fund/easyPayApprove/clearClose/clearCloseDetial.vue'

//授信申请管理（资金方）
  import fundCreditApply from '../views/fund/fundCreditApply.vue'
  import fundCreditCheck from '../views/fund/fundCreditCheck.vue'
  import fundCreditDetail from '../views/fund/fundCreditdetail.vue'
  import addCreditApply from '../views/fund/addCreditApply.vue'
  //查看授信申请详情
  import addApplyDetail from '../views/fund/fundApplydetail.vue'

//授信管理（资金机构方）
import authorizationManage from "../fund/business/authorizationManage.vue";
import authorizationDetail from '../fund/business/authorizationDetail.vue';
//企业目录（资金机构方）
import businessMange from "../fund/businessDirectory/businessMange.vue";
import businessUserDetail from "../fund/businessDirectory/businessUserDetail.vue";

//清分审批
import Fund_Distinguishingapproval from '../fund/createmange/distinguishingapproval.vue'
import Fund_FundDistinguishingapproval from '../fund/createmange/fundDistinguishingapproval.vue'
import Fund_Distinguishingapprovaldetail from '../fund/createmange/distinguishingapprovaldetail.vue'
import Fund_DistinguishingapprovalFail from '../fund/createmange/checkFail.vue'

// 逐级清分审批
import Fund_CascadeClearingCheck from '../fund/cascadeClearingCheck/distinguishingapproval.vue'
// 逐级清分审批详情
import Fund_CascadeClearingCheckDetail from '../fund/cascadeClearingCheck/distinguishingapprovaldetail.vue'



//保理融资管理
import FundFactoringManageIndex from '../fund/factoringProjetctManage/fundFactoringIndex.vue'
import FundFactoringProjectDetial from '../fund/factoringProjetctManage/fundFactoringProjectDetial.vue'
//报价详情
import FundFactoringQuoteDetial from '../fund/factoringProjetctManage/fundFactoringQuoteDetial.vue'

//审批管理
import FundFinancingApproveIndex from '../fund/factoringApproveManage/fundFinacingApprovalIndex.vue'

// 融资放款信息-资方
import FundFinancingLoan from '../fund/financingLoan/index.vue'




/*******************************************清分结算*******************************************/
//持有企业
import Distinguishingmange from '../views/distinguishing/distinguishingmange.vue'
//资金机构
import ZjDistinguishingmange from '../views/distinguishing/zjdistinguishingmange.vue'

//新的清分
import DistinguishingmangeNew from '../views/distinguishing/distinguishingmangenew.vue'

//链属
import Lsdistinguishingmange from '../views/distinguishing/lsdistinguishingmange.vue'

/********* *清分详情******/
//持有企业待清分
import Distinguishingdetial from '../views/distinguishing/distinguishingdetial.vue'
//资金机构待清分
import ZjDistinguishingdetial from '../views/distinguishing/zjdistinguishingdetial.vue'
//链属
import Lsinguishingdetial from '../views/distinguishing/lsdistinguishingdetial.vue'

/******************************************逐级清分结算*****************************************/
// 核心企业-逐级清分结算-应收
import ReceivableCascadeClearing from '../views/cascadeClearing/receivable/distinguishingmange.vue'
// 核心企业-逐级清分结算-应付
import PayableCascadeClearing from '../views/cascadeClearing/payable/distinguishingmange.vue'
// 逐级清分结算详情
import CascadeClearingDetail from '../views/cascadeClearing/payable/distinguishingdetail.vue'

// 担保方-逐级清分结算
import GuCascadeClearing from '../views/cascadeClearing/guaranteedistinguishing/gudistinguishingmange.vue'

// 资金机构-逐级清分结算-应收
import ZjReceivableCascadeClearing from '../views/cascadeClearing/zjReceivable/distinguishingmange.vue'

// 链属企业-逐级清分结算-应收
import LsReceivableCascadeClearing from '../views/cascadeClearing/lsReceivable/distinguishingmange.vue'

/*******************************************易保理************************************************/
//应收账款管理
import AccountsReceivablemanage from '../views/blAccountsReceivable/AccountsReceivablemanage.vue'
//新增应收账款
import Createdaccounts from '../views/blAccountsReceivable/createdaccounts.vue'
//应收账款可编辑详情
import Editcreatedaccounts from '../views/blAccountsReceivable/editcreatedaccounts.vue'
//应收账款详情
import Createdaccountsdetail from '../views/blAccountsReceivable/createdaccountsdetail.vue'
//补件详情
import StayPatchBoltdetial from '../views/factoringProjetctManage/stayPatchBoltdetial.vue'
//补件详情 查看
import StayPatchBoltdetialcomp from '../views/factoringProjetctManage/stayPatchBoltdetialcomp.vue'
//确权详情
import Affirmcreditordetail from '../views/affirmcreditorRight/affirmcreditordetail.vue'
//已确权详情
import Affirmcreditordetailalread from '../views/affirmcreditorRight/affirmcreditordetailalread.vue'

/* 线上签署确权文件 */
import AffirmcreditoSignDetail from '../views/affirmcreditorRight/affirmcreditoSignDetail.vue'

//补件审批

import ApprovalSupplementsmain from '../views/blapprovalsupplements/approvalsupplementsmain.vue'
//待补件审核详情
import Approvalsupplementswaitdetial from '../views/blapprovalsupplements/approvalsupplementswaitdetial.vue'



//融资申请
import Createfinancing from '../views/blfinancingapplications/createfinancing.vue'

import FactoringIndex from '../views/factoringProjetctManage/factoringIndex.vue'
import FactoringProjectDetial from '../views/factoringProjetctManage/factoringProjectDetial.vue'

import FactoringQuoteDetial from '../views/factoringProjetctManage/factoringQuoteDetial.vue'
import FactoringSignDetial from '../views/factoringProjetctManage/factoringSignDetial.vue'



import FactoringBackDetial from '../views/factoringProjetctManage/factoringBackDetial.vue'

import ReceivableIndex from '../views/factoringApproval/receivableCredit/receivableIndex.vue'
import CreditorsRightIndex from '../views/factoringApproval/creditorsRight/creditorsRightIndex.vue'
import FinacingApprovalIndex from '../views/factoringApproval/finacingApproval/finacingApprovalIndex.vue'
import FinacingApprovalDetial from '../views/factoringApproval/finacingApproval/finacingApprovalDetial.vue'
// 融资提现审批
import CashCheck from '../views/factoringApproval/cashCheck/index.vue'
import CashCheckDetail from '../views/factoringApproval/cashCheck/detail.vue'
// 银e券转换审批 
import TransCheck from '../views/factoringApproval/transCheck/index.vue'
import TransCheckDetail from '../views/factoringApproval/transCheck/detail.vue'

import AffirmcreditorRightIndex from '../views/affirmcreditorRight/affirmcreditorRightIndex.vue'
/************************* 担保********************/
//担保银e券管理
import Guaranmanagementman from '../views/guaranteemanagement/guaranmanagementman.vue'
//担保额度管理
import Guarantquotamange from '../views/guarantquotamange/guarantquotamange.vue'
//银e券担保
import Guaranteeapprovalmain from '../views/guaranteeapproval/guaranteeapprovalmain.vue'
//请分结算
//持有企业
import Gudistinguishingmange from '../views/guaranteedistinguishing/gudistinguishingmange.vue'
//资金机构
import Guzjdistinguishingmange from '../views/guaranteedistinguishing/guzjdistinguishingmange.vue'
//开立请求管理
import Lseasypaitoopenmange from '../views/lscreatedeasyopen/lseasypaitoopenmange.vue'
//开立请求编辑
import Lseasypaitoopenedit from '../views/lscreatedeasyopen/lseasypaitoopenedit.vue'
//手机页面
import Mobile from '../views/mobile/mobile.vue'
import FinanceMobile from '../views/mobile/financeMobile.vue'

/*****************易票据******************/
import releaseBill from '../views/bill/release-bill.vue'
import billDetails from '../views/bill/bill-details.vue'
import billPreference from '../views/bill/bill-preference.vue'
import billManage from '../views/bill/bill-manage.vue'
import billUnapprovaledDetails from '../views/bill/bill-unapprovaled-details.vue'
import billApprovaledDetails from '../views/bill/bill-approvaled-details.vue'
import billBackDetails from '../views/pjmybill/billbackDetails.vue'
import billStatistics from '../views/bill/bill-statistics.vue'

//********************** */票据中心**********************//
import Pjcenter from '../views/pjcenter/pjcenter.vue'
import Zjpjcenter from '../views/pjcenter/zjpjcenter.vue'

//我的票据
import Billmanage from '../views/pjmybill/billmanage.vue'
//我的票据 资金
import Billmanagezj from '../views/pjmybillzj/billmanagezj.vue'

import Invest_preference_setting from '../views/Business/invest_preference_setting.vue'
//票据待签约详情
import Billsigndetal from '../views/pjmybill/billsigndetal.vue'

//票据摘牌 审批 
import Pjcheckmanage from '../views/pjcheckedzj/pjcheckmanage.vue'
//票据摘牌 审批详情 
import Pjcheckedfirstinfo from '../views/pjcheckedzj/pjcheckedfirstinfo.vue'
//票据摘牌  已报价审批详情 
import Billquotepriceinfo from '../views/pjmybill/billquotepriceinfo.vue'
import Pjcheckedsecondinfo from '../views/pjcheckedzj/pjcheckedsecondinfo.vue'

// 活体认证
import LivingCertification from '../views/linvingCertification/LivingCertification.vue'
import LivingCertificationcus from '../views/linvingCertification/LivingCertificationcus.vue'


import AwaitResult from '../views/linvingCertification/AwaitResult.vue'
//个人活体
import Customervideo from '../views/register/customervideo.vue'




 // 已经被报价 已结束
 import Billoverxj from '../views/pjbase/billoverxj.vue'
  //个人认证身份证
  import Customerprefect from '../views/register/customerprefect.vue'
  import Customeresult from '../views/register/customeresult.vue'
  import Awaitcuresult from '../views/linvingCertification/Awaitcuresult.vue'

  

  //额度详情
const CreditRecordDetail = () => import('../views/easypay/detail.vue')

//abs 计划管理
import Absplan from '../views/absplan/absplanmanage.vue'
//abs 计划详情
import Absplaninfo from '../views/absbase/absplaninfo.vue'
//abs  资产账款详情
import Abscountinfo from '../views/absbase/abscountinfo.vue'
//abs  提交资产详情
import Abspostcount from '../views/abspost/abspostcount.vue'
//abs  提交资产详情后
//同意
import Absplanagree from '../views/absbase/absplanagree.vue'
//拒绝
import Absplanrefuse from '../views/absbase/absplanrefuse.vue'
//abs查看资产

import Absviewassetsinfo from '../views/absbase/absviewassetsinfo.vue'
import Absviewassetscheckinfo from '../views/absbase/absviewassetscheckinfo.vue'
//资产封包
import Absviewassetspackinfo from '../views/absbase/absviewassetspackinfo.vue'
//核心查看资产
import Absviewassetscoreinfo from '../views/absbase/absviewassetscoreinfo.vue'

// abs待签约

import Abswaitsigngysinfo from '../views/absbasesign/abswaitsigngysinfo.vue' 

//abs 待签约 转让通知
import Abstranertext from '../views/absbasesign/abstranertext.vue' 
//abs 待签约 合同
import Abswaitcontract from '../views/absbasesign/abswaitcontract.vue' 

/** 2020-1-02  abs 上传线上转让合同的页面路由方案。 */
import AbswaisigngysDetail from "../views/absbasesign/abswaisigngysDetail.vue";






//abs 查看资产 非保理商主导
import Absviewfundassetsinfo from '../views/absbase/absviewfundassetsinfo.vue' 
//abs 置换详情
import Abssubstitutioninfo from '../views/absbase/abssubstitutioninfo.vue' 

//abs 统计
import Absbusiness from '../views/abspost/absbusiness.vue' 



//上链信息 
const OnChainInformation = () => import('../views/onChainInformation/onChainInfomation.vue')
// 银e券协议
const CloudCreditAgreementManage = () => import('../views/agreementManage/CloudCreditAgreementManage.vue')
//应收账款转让通知书
const CloudAcountTransferInfo = () => import('../views/agreementManage/CouldAcountTransferInfo.vue')
//融资签约合同
const ContractAgreementManage = () => import('../views/agreementManage/ContractAgreementManage.vue')

// abs账款管理列表
import AbsAccountsManage from '../views/ABS/assetsManagement/accountsManage.vue'
// abs添加账款
import CreatedAbsAccounts from '../views/ABS/assetsManagement/addAccount/createdAccounts.vue'
// abs账款详情
import AbsAccountsDetail from '../views/ABS/assetsManagement/detail.vue'
// abs账款审批-自身账款审批
import AbsAccountsCheck_Publisher from '../views/ABS/check/publisher/index.vue' 
// abs账款审批-外部账款审批
import AbsAccountsCheck_Acceptor from '../views/ABS/check/acceptor/index.vue' 
// abs退回账款详情
import AbsAccountsBackDetail from '../views/ABS/assetsManagement/backDetail.vue'
// abs资产审批-保理商（资金机构）
import AbsAssetsCheck from '../views/ABS/factorCheck/check/index.vue' 
import AbsAssetsRecheck from '../views/ABS/factorCheck/recheck/index.vue' 
import AbsAssetsFinalCheck from '../views/ABS/factorCheck/finalCheck/index.vue'
import AbsAssetsDetail from '../views/ABS/factorCheck/detail.vue' 
// abs账款详情-保理商
import FundAbsAccountsDetail from '../views/ABS/factorCheck/accountsDetail.vue'
import Ukey from '../views/ukey.vue'
// 供应商额度
import SupplierQuota from '../views/supplier-quota/list.vue';






var routes = [{
        path: '/Login',
        component: Login,
        name: '登录',
        hidden: true
    },
    {
        path: '/mobile',
        component: Mobile,
        name: '手机页面',
        hidden: true
    },
    {
        path: '/financeMobile',
        component: FinanceMobile,
        name: '资金方手机页面',
        hidden: true
    },
    {
        path: '/forbidden',
        component: Forbidden,
        name: '手机页面',
        hidden: true
        
    },
    { path: '/abs/abscountinfo', component: Abscountinfo, name: '资产账款详情', meta: {}},


    {
        path: '/',
        component: Home,
        redirect: '/login',
        name: '首页',
        children: [
            { path: '/ukey', component: Ukey, name: 'ukey', meta: { isnav: true, to: '工作台' } },

            { path: '/workbench', component: Workbench, name: '工作台', meta: { isnav: true, to: '工作台' } },
            { path: '/serviceAgreement', component: serviceAgreement, name: '银e券服务协议', meta: { isnav: true, to: '工作台' } },
            { path: '/easypay/mange/:state', component: Easypaymange, name: '银e券管理', meta: { isnav: true, to: '银e券' } },
            { path: '/easypay/amountmange/:state', component: Amountmange, name: '银e券额度管理', meta: { isnav: true, to: '银e券' } },
            { path: '/easypay/toopen', component: Easypaytoopen, name: '银e券开立', meta: { isnav: true, to: '银e券' } },
            { path: '/easypay/openAccount', component: OpenAccount, name: '开立渠道账户', meta: { to: '银e券' } },
            { path: '/easypay/easypaytransfer', component: Easypaytransfer, name: '银e券转让', meta: { to: '银e券' } },
            { path: '/easypay/supplier-quota/:state', component: SupplierQuota, name: '额度记录', meta: { isnav: true, to: '银e券' } },
            { path: '/easypay/supplier-quota-audit/:state', component: SupplierQuota, name: '额度复审', meta: { isnav: true, to: '银e券' } },

            { path: '/easypay/openapproval/:state', component: Openapproval, name: '开立审批', meta: { isnav: true, to: '银e券' } },
            { path: '/easypay/openapproval1/:state', component: Openapproval, name: '银e券转让审批', meta: { isnav: true, to: '银e券' } },
            { path: '/easypay/openrequestapproval/:state', component: Openrequestapproval, name: '邀请开单审批', meta: { isnav: true, to: '银e券' } },
            { path: '/easypay/lseasypaymange/:state', component: Lseasypaymange, name: '银e券管理链属', meta: { isnav: true, to: '银e券' } },
            { path: '/easypay/lseasypaitoopen', component: Lseasypaitoopen, name: '请求银e券开立', meta: { isnav: true, to: '银e券' } },
            { path: '/easypay/onChainInformation', component: OnChainInformation, name: '上链信息', meta: { isnav: true, to: '银e券' } },


            { path: '/easypay/easypayfirstdetial', component: EasypayfirstDetial, name: '开立初审详情' },
            { path: '/easypay/easypayseconddetial', component: EasypaysecondDetial, name: '开立请求详情' },
            { path: '/easypay/easypaygoback', component: Easypaygoback, name: '开立退回详情' },
            { path: '/easypay/lseasypayfirstdetial', component: Lseasypayfirstdetial, name: '待接收详情' },

            { path: '/easypay/distinguishingmange/:state', component: Distinguishingmange, name: '清分结算持有企业', meta: { isnav: true, to: '银e券' } },
            { path: '/easypay/zjdistinguishingmange/:state', component: ZjDistinguishingmange, name: '清分结算资金机构', meta: { isnav: true, to: '银e券' } },
            { path: '/easypay/lsdistinguishingmange/:state', component: Lsdistinguishingmange, name: '清分结算链属', meta: { isnav: true, to: '银e券' } },

            //新清分
            { path: '/easypay/distinguishingmangenew/:state', component: DistinguishingmangeNew, name: '清分支付管理', meta: { isnav: true, to: '银e券' } },

            
            { path: '/easypay/cascadeClearing/distinguishingmange/:state', component: LsReceivableCascadeClearing, name: '逐级清分结算链属', meta: { isnav: true, to: '银e券' } },
            
//旧
            { path: '/easypay/distinguishingapproval/:state', component: Distinguishingapproval, name: '持有企业清分审批', meta: { isnav: true, to: '银e券' } },
            //新清分审批
            { path: '/easypay/distinguishingapprovalfix/:state', component: resolve => (require(["@/views/createmange/distinguishingfix.vue"], resolve)), name: '清分审批', meta: { isnav: true, to: '银e券' } },
            //转让通知书列表
            { path: '/easypay/transfercontractlist', component: resolve => (require(["@/views/easypay/transfercontractlist"], resolve)), name: '转让通知书', meta: { isnav: true, to: '银e券' } },
            //转让通知书列表
            { path: '/easypay/openAgreement', component: resolve => (require(["@/views/easypay/openAgreement"], resolve)), name: '开单协议', meta: { isnav: true, to: '银e券' } },
            //转让通知书列表
            { path: '/easypay/transferAgreement', component: resolve => (require(["@/views/easypay/transferAgreement"], resolve)), name: '转让协议', meta: { isnav: true, to: '银e券' } },


            
            { path: '/easypay/fundDistinguishingapproval/:state', component: FundDistinguishingapproval, name: '资金机构清分审批', meta: { isnav: true, to: '银e券' } },
            { path: '/easypay/distinguishingapprovaldetail', component: Distinguishingapprovaldetail, name: '清分审批详情', meta: {} },
            { path: '/easypay/distinguishingapprovalFail', component: DistinguishingapprovalFail, name: '审批有异议', meta: {} },
            
            // 逐级清分结算
            { path: '/easypay/cascadeClearing/receivable/distinguishingmange/:state', component: ReceivableCascadeClearing, name: '逐级清分结算-银e券应收清分', meta: { isnav: true, to: '银e券' } },
            { path: '/easypay/cascadeClearing/payable/distinguishingmange/:state', component: PayableCascadeClearing, name: '逐级清分结算-银e券应付清分', meta: { isnav: true, to: '银e券' } },
            { path: '/easypay/cascadeClearing/distinguishingdetail/:state', component: CascadeClearingDetail, name: '逐级清分结算详情', meta: { isnav: true, to: '银e券' } },
        
            // 逐级清分审批
            { path: '/easypay/cascadeClearingCheck/:state', component: CascadeClearingCheck, name: '逐级清分审批', meta: { isnav: true, to: '银e券' } },
            { path: '/easypay/cascadeClearingDetail', component: CascadeClearingCheckDetail, name: '逐级清分审批详情', meta: {} },
            



            { path: '/easypay/distinguishingdetial', component: Distinguishingdetial, name: '清分结算持有企业详情', },
            { path: '/easypay/zjinguishingdetial', component: ZjDistinguishingdetial, name: '清分结算资金机构详情', },
            { path: '/easypay/lsinguishingdetial', component: Lsinguishingdetial, name: '清分结算链属详情', },
            //清分查询

            { path: '/easypay/clearsearch/:state', component: resolve => (require(["@/views/distinguishing/clearsearchmain.vue"], resolve)), name: '清分查询', meta: { isnav: true, to: '银e券' } },

            { path: '/easypay/lsinguishingdetial',component: resolve => (require(["@/views/distinguishing/clearsearchinfo.vue"], resolve)),name: '清分查询详情', },

            { path: '/easypay/statementManage/:state', component: Statement, name: '对账单管理', meta: { } },
            { path: '/easypay/statementManageDetail/:state', component: StatementDetail, name: '对账单详情', meta: { } },

            { path: '/easypay/agreementManage', component: AgreementManage, name: '收费协议管理', meta: { } },
            
            { path: '/easypay/creditRecordDetail', component: CreditRecordDetail, name: '额度详情', meta: {} },

            { path: '/easypayApproveManage/easypayFinancing/financingApprove/:state', component: FinancingApprove, name: '融资审批', meta: { isnav: true, to: '银e券' } },
            { path: '/easypay/easylzdetial', component: Easylzdetial, name: '银e券流转详情' },
            { path: '/easypay/easylzdetialchildren', component: Easylzdetialchildren, name: '银e券流转子单详情' },
            { path: '/easypay/clearEasylzdetial', component: ClearEasylzdetial, name: '银e券已清分流转详情' },

            { path: '/easypay/easypaymessage', component: Easypaymessage, name: '银e券统计', meta: { isnav: true, to: '银e券' } }, //19-7-17

            { path: '/easypayApproveManage/easypayFinancing/recheckDetial', component: RecheckDetial, name: '复审详情' },
            { path: '/financingProjectManage/easypaySign/financingSign/:state', component: financingSign, name: '融资项目管理', meta: { isnav: true, to: '银e券' } },
            { path: '/financingProjectManage/easypaySign/SignDetial', component: SignDetial, name: '签约详情', meta: { to: '银e券' } },

            { path: '/Business/Business_management', component: BusinessManagement, name: '链属企业白名单', meta: { isnav: true, to: '企业管理' } },
            { path: '/Business/Bank_management', component: BankManagement, name: '银行', meta: { isnav: true, to: '企业管理' } },
            // { path: '/Business/user_management', component: userManagement,name:'用户',meta:{isnav:true,to:'企业管理'}},
            { path: '/Business/user_management', component: BusinessUserManagement, name: '用户', meta: { isnav: true, to: '企业管理' } },
            { path: '/Business/enterpriseInfo', component: enterpriseInfo, name: '企业信息', meta: { isnav: true, to: '企业管理' } },
            { path: '/Business/accountManagement', component: AccountManagementList, name: '渠道账户管理', meta: { isnav: true, to: '企业管理' } },
            { path: '/Business/accountManagement/detail', component: AccountManagement, name: '渠道账户详情', meta: {to: '企业管理' } },
            { path: '/Business/financialManagement', component: FinancialManagement, name: '账务管理', meta: { isnav: true, to: '企业管理' } },
            { path: '/Business/Data_config', component: dataConfig, name: '融资资料设置', meta: { isnav: true, to: '企业管理' } },
            // { path: '/Business/Business_user_management', component: BusinessUserManagement,name:'企业用户管理',meta:{isnav:true,to:'企业管理'}},
            
            { path: '/Business/message', component: messageManagement, name: '站内信管理', meta: { isnav: true, to: '企业管理' } },
            { path: '/Business/address', component: addressManagement, name: '邮寄地址管理', meta: { isnav: true, to: '企业管理' } },
            { path: '/easypay/cloudCreditAgreementManage', component: CloudCreditAgreementManage, name: '银e券协议', meta: {} },
            { path: '/easypay/couldAcountTransferInfo', component: CloudAcountTransferInfo, name: '应收账款转让通知书', meta: {isnav: true, to: '银e券' } },
            { path: '/easypay/contractAgreementManage', component: ContractAgreementManage, name: '融资签约合同', meta: {} },









            /***************************************************易保理**********************************************************************/
            { path: '/factoring/home', component: FundHome, name: '首页', meta: { isnav: true, to: '易保理' } },
            { path: '/factoring/Distinguishingmange', component: Distinguishingmange, name: '票据审批', meta: { isnav: true, to: '易保理' } },
            { path: '/factoring/accountsreceivablemanage/:state', component: AccountsReceivablemanage, name: '应收账款管理', meta: { isnav: true, to: '易保理' } },
            { path: '/factoring/createdaccounts', component: Createdaccounts, name: '应收账款', meta: { to: '易保理' } },
            { path: '/factoring/createfinancing', component: Createfinancing, name: '融资申请', meta: { isnav: true, to: '易保理' } },
            { path: '/factoring/editcreatedaccounts', component: Editcreatedaccounts, name: '应收账款退回' },
            { path: '/factoring/createdaccountsdetail', component: Createdaccountsdetail, name: '应收账款详情' },







            { path: '/factoring/FactoringManageIndex/:state', component: FactoringIndex, name: '保理融资管理', meta: { isnav: true, to: '易保理' } },
            { path: '/factoring/FactoringProjectDetial', component: FactoringProjectDetial, name: '保理融资管理详情', meta: { to: '易保理' } },

            { path: '/factoring/FactoringQuoteDetial', component: FactoringQuoteDetial, name: '保理融资报价详情', meta: { to: '易保理' } },

            { path: '/factoring/FactoringBackDetial', component: FactoringBackDetial, name: '保理融资退回详情', meta: { to: '易保理' } },

            { path: '/factoring/FactoringSignDetial', component: FactoringSignDetial, name: '保理融资签约详情', meta: { to: '易保理' } },


            //审批管理 应收账款审批
            { path: '/factoring/ReceivableIndexIndex/:state', component: ReceivableIndex, name: '应收账款审批', meta: { isnav: true, to: '易保理' } },
            { path: '/factoring/CreditorsRightIndex/:state', component: CreditorsRightIndex, name: '债权企业应付账款确认', meta: { isnav: true, to: '易保理' } },
            { path: '/factoring/FinacingApprovalIndex/:state', component: FinacingApprovalIndex, name: '保理融资审批', meta: { isnav: true, to: '易保理' } },
            { path: '/factoring/FinacingApprovalDetial', component: FinacingApprovalDetial, name: '融资审批详情', meta: { to: '易保理' } },
            { path: '/factoring/approvalsupplementsmain/:state', component: ApprovalSupplementsmain, name: '补件审批', meta: { isnav: true, to: '易保理' } },
            { path: '/factoring/cashCheck/:state', component: CashCheck, name: '融资提现审批', meta: { isnav: true, to: '易保理' } },
            { path: '/factoring/cashCheckDetail', component: CashCheckDetail, name: '融资提现审批详情', meta: { to: '易保理' } },
            { path: '/factoring/transCheck/:state', component: TransCheck, name: '银e券转换审批', meta: { isnav: true, to: '易保理' } },
            { path: '/factoring/transCheckDetail', component: TransCheckDetail, name: '银e券转换审批详情', meta: { to: '易保理' } },

            


            { path: '/factoring/AffirmcreditorRightIndex/:state', component: AffirmcreditorRightIndex, name: '融资确权管理', meta: { isnav: true, to: '易保理' } },
            { path: '/factoring/stayPatchBoltdetial', component: StayPatchBoltdetial, name: '补件详情', meta: {} },
            { path: '/factoring/stayPatchBoltdetialcomp', component: StayPatchBoltdetialcomp, name: '补件详情查看', meta: {} },
            { path: '/factoring/affirmcreditordetail', component: Affirmcreditordetail, name: '确权详情', meta: {} },
            { path: '/factoring/affirmcreditordetailalread', component: Affirmcreditordetailalread, name: '已确权详情', meta: {} },
            { path: '/factoring/approvalsupplementswaitdetial', component: Approvalsupplementswaitdetial, name: '待补件审核详情', meta: {} },
            { path: '/Business/invest_preference_setting', component: Invest_preference_setting, name: '投资偏好设置', meta: { isnav: true, to: '企业管理' } },
            
            { path: '/affirmcreditorRight/affirmcreditoSignDetail', component: AffirmcreditoSignDetail, name: '线上签署确权文件详情', meta: {} },














            /***************************************************资金机构**********************************************************************/

            { path: '/fund/home', component: FundHome, name: '首页', meta: { isnav: true, to: '银e券' } },

            { path: '/fund/easypay/openapproval/:state', component: FundOpenApprove, name: '资方开立初审', meta: { isnav: true, to: '银e券' } },
            { path: '/fund/easypay/openapproval/second/:state', component: FundSecondOpenApprove, name: '资方开立复审', meta: { isnav: true, to: '银e券' } },
            { path: '/fund/easypay/openapproval/three/:state', component: FundThreeOpenApprove, name: '资方开立终审', meta: { isnav: true, to: '银e券' } },
            { path: '/fund/easypay/openapproval/detial', component: FundOpenApprovalDetial, name: '资方开立审批详情', meta: { to: '银e券' } },



            { path: '/fund/easypayFinancing/financingApprove/:state', component: FundFinancingApprove, name: '融资初审', meta: { isnav: true, to: '银e券' } },
            { path: '/fund/easypayFinancing/financingApprove/second/:state', component: FundSecondFinancingApprove, name: '融资复审', meta: { isnav: true, to: '银e券' } },
            { path: '/fund/easypayFinancing/financingApprove/three/:state', component: FundThreeFinancingApprove, name: '融资终审', meta: { isnav: true, to: '银e券' } },


            { path: '/fund/easypayFinancing/recheckDetial', component: FundRecheckDetial, name: '资方融资审批详情', meta: { to: '银e券' } },

            { path: '/fund/easypaySign/financingSign/:state', component: FundFinancingSign, name: '资方融资项目管理', meta: { isnav: true, to: '银e券' } },
            { path: '/fund/easypaySign/financingProject', component: FundFinancingProject, name: '资方融资签约管理', meta: { isnav: true, to: '银e券' } },

            { path: '/fund/easypaySign/clearEasypayDetail', component: ClearEasypayDetail, name: '资方已清分详情', meta: { isnav: true, to: '银e券' } },
           
            { path: '/fund/easypaySign/signDetial', component: FundSignDetial, name: '资方融资签约详情', meta: { to: '银e券' } },
            { path: '/fund/easypaySign/financingProject/detail', component: FundFinancingProjectDetial, name: '资方融资签约管理详情', meta: { to: '银e券' } },
            { path: '/fund/easypay/clearCloseManage/:state', component: ClearCloseManage, name: '清分结算', meta: { isnav: true, to: '银e券' } },
            { path: '/fund/easypay/clearCloseDetial', component: ClearCloseDetial, name: '资方清分结算详情', },
            { path: '/fund/easypay/cascadeClearing/distinguishingmange/:state', component: ZjReceivableCascadeClearing, name: '资方逐级清分结算', meta: { isnav: true, to: '银e券' } },
            //资方的授信申请管理
            {path: '/fund/credit/apply', component:fundCreditApply, name: '资方授信申请',meta:{isnav: true,to:"银e券"}},
            {path: '/fund/credit/review/:state', component:fundCreditCheck, name: '资方授信复核',meta:{isnav: true,to:"银e券"}},
            {path: '/fund/credit/check', component:fundCreditDetail, name: '资方授信查看',meta:{isnav: true,to:"银e券"}},
            {path: '/fund/credit/add', component:addCreditApply, name: '新增授信申请',meta:{to:"银e券"}},
            {path: '/fund/credit/add/detail', component:addApplyDetail, name: '授信申请详情',meta:{to:"银e券"}},
            

            //资方的授信管理
            {path: '/fund/business/authorizationManage/:state', component:authorizationManage, name: '资方授信管理',meta:{isnav: true,to:"银e券"}},
            {path: '/fund/business/authorizationDetail', component:authorizationDetail, name:'资方授信管理详情',meta:{to:"银e券"}},
            //资方的企业目录
            {path: '/fund/businessDirectory/businessMange/:state', component:businessMange, name: '资方企业目录',meta:{isnav: true,to:"银e券"}},
            {path: '/fund/businessDirectory/businessUserDetail', component:businessUserDetail, name: '资方企业目录详情',meta:{isnav: true,to:"银e券"}},
            // 资方清分审批
            { path: '/fund/easypay/distinguishingapproval/:state', component: Fund_Distinguishingapproval, name: '持有企业清分结算审批', meta: { isnav: true, to: '银e券' } },
            { path: '/fund/easypay/fundDistinguishingapproval/:state', component: Fund_FundDistinguishingapproval, name: '资金机构清分结算审批', meta: { isnav: true, to: '银e券' } },
            { path: '/fund/easypay/distinguishingapprovaldetail', component: Fund_Distinguishingapprovaldetail, name: '清分结算审批详情', meta: {} },
            { path: '/fund/easypay/distinguishingapprovalFail', component: Fund_DistinguishingapprovalFail, name: '清分结算审批有异议', meta: {} },
            // 逐级清分审批
            { path: '/fund/easypay/cascadeClearingCheck/:state', component: Fund_CascadeClearingCheck, name: '逐级清分结算审批', meta: { isnav: true, to: '银e券' } },
            { path: '/fund/easypay/cascadeClearingDetail', component: Fund_CascadeClearingCheckDetail, name: '逐级清分结算审批详情', meta: {} },






            /*********************************************资方易保理 */
            //保理融资管理

            { path: '/fund/factoring/FundFactoringManageIndex/:state', component: FundFactoringManageIndex, name: '资方保理融资管理', meta: { isnav: true, to: '易保理' } },
            { path: '/fund/factoring/FundFactoringProjectDetial', component: FundFactoringProjectDetial, name: '资方保理融资详情', meta: { to: '易保理' } },
            { path: '/fund/factoring/FundFactoringQuoteDetial', component: FundFactoringQuoteDetial, name: '资方保理融资报价详情', meta: { to: '易保理' } },

            // 融资放款信息
            { path: '/fund/factoring/FundFinancingLoan/:state', component: FundFinancingLoan, name: '资方融资放款信息', meta: { isnav: true, to: '易保理' } },

            /*********************************************资方E-ABS */
            { path: '/fund/abs/assetsCheck/check/:state', component: AbsAssetsCheck, name: '资产初审', meta: {isnav: true, to: 'E-ABS' }},
            { path: '/fund/abs/assetsCheck/recheck/:state', component: AbsAssetsRecheck, name: '资产复审', meta: {isnav: true, to: 'E-ABS' }},
            { path: '/fund/abs/assetsCheck/finalCheck/:state', component: AbsAssetsFinalCheck, name: '资产终审', meta: {isnav: true, to: 'E-ABS' }},
            { path: '/fund/abs/absAssets/detail', component: AbsAssetsDetail, name: '资产详情', meta: { to: 'E-ABS' } },
            { path: '/fund/abs/absAssets/accountDetail', component: FundAbsAccountsDetail, name: '资方账款详情', meta: { to: 'E-ABS' } },
            


            { path: '/fund/factoring/FundFinancingApproveIndex/:state', component: FundFinancingApproveIndex, name: '资方审批管理', meta: { isnav: true, to: '易保理' } },
            /*********************************************担保银e券管理 */
            { path: '/dbeasypay/mange/:state', component: Guaranmanagementman, name: '担保银e券管理', meta: { isnav: true, to: '银e券' } },
            //担保额度管理
            { path: '/gteasypay/amountmange/:state', component: Guarantquotamange, name: '担保额度管理', meta: { isnav: true, to: '银e券' } },
            //银e券担保
            { path: '/gteasypay/guaranteeapprovalmain/:state', component: Guaranteeapprovalmain, name: '银e券担保', meta: { isnav: true, to: '银e券' } },
            //清分结算持有企业
            { path: '/gteasypay/gudistinguishingmange/:state', component: Gudistinguishingmange, name: '清分结算持有', meta: { isnav: true, to: '银e券' } },
            //清分结算资金机构
            { path: '/gteasypay/guzjdistinguishingmange/:state', component: Guzjdistinguishingmange, name: '清分结算资金', meta: { isnav: true, to: '银e券' } },
            { path: '/easypay/lseasypaitoopenmange/:state', component: Lseasypaitoopenmange, name: '开立请求管理', meta: { isnav: true, to: '银e券' } },
            { path: '/easypay/lseasypaitoopenedit', component: Lseasypaitoopenedit, name: '开立请求编辑', meta: {} },
            // 逐级清分结算
            { path: '/gteasypay/cascadeClearing/gudistinguishingmange/:state', component: GuCascadeClearing, name: '担保逐级清分结算', meta: { isnav: true, to: '银e券' } },

            /*************************易票据************************** */
            { path: '/bill/release-bill', component: releaseBill, name: '发布票据', meta: { isnav: true, to: '易票据' } },
            { path: '/bill/bill-details', component: billDetails, name: '票据详情', meta: { isnav: true, to: '易票据' } },
            { path: '/bill/bill-preference', component: billPreference, name: '票据偏好设置', meta: { isnav: true, to: '易票据' } },
            { path: '/bill/bill-manage/:state', component: billManage, name: '票据审批管理', meta: { isnav: true, to: '易票据' } },
            { path: '/bill/bill-unapprovaled-details', component: billUnapprovaledDetails, name: '待审批票据详情', meta: { isnav: true, to: '易票据' } },
            { path: '/bill/bill-approvaled-details', component: billApprovaledDetails, name: '已审批票据详情', meta: { isnav: true, to: '易票据' } },
            { path: '/bill/bill-manage/:state', component: billManage, name: '票据审批管理', meta: { isnav: true, to: '易票据' } },
            { path: '/bill/bill-statistics', component: billStatistics, name: '业务统计', meta: { isnav: true, to: '易票据' } },
            /*************************票据业务**************************/
            //票据中心
            { path: '/bill/billcenter', component: Pjcenter, name: '票据中心', meta: { isnav: true, to: '易票据' } },
            { path: '/bill/billcenterzj', component: Zjpjcenter, name: '票据中心资金', meta: { isnav: true, to: '易票据' } },
            { path: '/bill/billbackDetails', component: billBackDetails, name: '退回票据详情', meta: { isnav: true, to: '易票据' } },
            { path: '/bill/billmanage/:state', component: Billmanage, name: '我的票据', meta: { isnav: true, to: '易票据' } },
            { path: '/bill/billmanagezj/:state', component: Billmanagezj, name: '我的票据资金', meta: { isnav: true, to: '易票据' } },
            { path: '/bill/billsigndetal', component: Billsigndetal, name: '票据待签约', meta: {} },
            { path: '/bill/pjcheckmanage/:state', component: Pjcheckmanage, name: '审批管理摘牌', meta: { isnav: true, to: '易票据' } },
            { path: '/bill/pjcheckedfirstinfo', component: Pjcheckedfirstinfo, name: '审批管理详情摘牌', meta: {} },
            { path: '/bill/pjcheckedsecondinfo', component: Pjcheckedsecondinfo, name: '审批详情摘牌', meta: {} },

            { path: '/bill/billquotepriceinfo', component: Billquotepriceinfo, name: '已报价详情摘牌', meta: {} },
            { path: '/bill/billoverxj', component: Billoverxj, name: '被人抢了', meta: {} },
            /*************************ABS**************************/
            { path: '/abs/absplan/:state', component: Absplan, name: '专项计划管理', meta: {isnav: true, to: 'E-ABS' } },
            { path: '/abs/absplaninfo', component: Absplaninfo, name: '专项计划简介', meta: {}},
            // { path: '/abs/abscountinfo', component: Abscountinfo, name: '资产账款详情', meta: {}},
            { path: '/abs/abspostcount', component: Abspostcount, name: '提交资产详情', meta: {}},
            { path: '/abs/absplanagree', component: Absplanagree, name: 'absplanagree', meta: {}},
            { path: '/abs/absplanrefuse', component: Absplanrefuse, name: 'absplanrefuse', meta: {}},
            { path: '/abs/absviewassetsinfo', component: Absviewassetsinfo, name: 'absviewassetsinfo', meta: {}},
            { path: '/abs/accountsManage', component: AbsAccountsManage, name: '账款管理', meta: {isnav: true, to: 'E-ABS'}},
            { path: '/abs/createdaccounts', component: CreatedAbsAccounts, name: '添加账款', meta: { to: 'E-ABS' } },
            { path: '/abs/absAccounts/detail', component: AbsAccountsDetail, name: '账款详情', meta: { to: 'E-ABS' } },
            { path: '/abs/absAccounts/detail/back', component: AbsAccountsBackDetail, name: '退回账款详情', meta: { to: 'E-ABS' } },
            { path: '/abs/accountsCheck/publisher/:state', component: AbsAccountsCheck_Publisher, name: '自身账款审批', meta: {isnav: true, to: 'E-ABS'}},
            { path: '/abs/accountsCheck/acceptor/:state', component: AbsAccountsCheck_Acceptor, name: '外部账款审批', meta: {isnav: true, to: 'E-ABS'}},
            
            { path: '/abs/absviewassetscheckinfo', component: Absviewassetscheckinfo, name: 'absviewassetscheckinfo', meta: {}},
            { path: '/abs/absviewassetspackinfo', component: Absviewassetspackinfo, name: 'absviewassetspackinfo', meta: {}},
            { path: '/abs/absviewassetscoreinfo', component: Absviewassetscoreinfo, name: 'absviewassetscoreinfo', meta: {}},
            { path: '/abs/abswaitsigngysinfo', component: Abswaitsigngysinfo, name: 'abswaitsigngysinfo', meta: {}},
            { path: '/abs/abstranertext', component: Abstranertext, name: 'abstranertext', meta: {}},
            { path: '/abs/abswaitcontract', component: Abswaitcontract, name: 'abswaitcontract', meta: {}},
            { path: '/abs/absviewfundassetsinfo', component: Absviewfundassetsinfo, name: 'absviewfundassetsinfo', meta: {}},
            
            { path: '/abs/AbswaisigngysDetail', component: AbswaisigngysDetail, name: 'AbswaisigngysDetail', meta: {}},




            { path: '/abs/abssubstitutioninfo', component: Abssubstitutioninfo, name: 'abssubstitutioninfo', meta: {}},
            { path: '/abs/absbusiness', component: Absbusiness, name: 'ABS业务概况', meta: {isnav: true, to: 'E-ABS' } },

 
        ]
    },
    // {
    //     path: '/',
    //     component: Home,
    //     name:'银e券审批',
    //     children: [
    //         {path: '/easypayApproveManage/easypayFinancing/financingApprove/:state',component: FinancingApprove,name:'融资审批'},
    //     ]
    // },
    {
        path: '/retrieve_password', //忘记密码
        component: Retrieve_Password
    },
    {
        path: '/changepassword', //修改密码
        component: changepassword
    },
    {
        path: '/register',
        name: 'register',
        component: RegisterM
    },
    {
        path: '/Company', //信息完善
        name: 'Company',
        component: Register_Company
    },
    {
        path: '/CompanyPerfect', //信息完善
        name: 'CompanyPerfect',
        component: Register_Perfect
    },
    {
        path: '/CompanyConfirm', //信息完善
        name: 'CompanyConfirm',
        component: Register_Confirm
    },
    {
        path: '/CompanyResult', //信息完善
        name: 'CompanyResult',
        component: Register_Result
    },
    {
        path: '/customeresult', //个人信息完善
        name: 'ustomeresult',
        component: Customeresult
    },
    {
        path: '/CompanyVideo', //信息完善
        name: 'CompanyVideo',
        component: Register_Video
    },
    { path: '/customerprefect', component: Customerprefect, name: '个人身份证验证', meta: {} },
    { path: '/customervideo', component: Customervideo, name: '个人活体验证', meta: {} },

    // {
    //     path: '/404',
    //     component: NotFound,
    //     hidden: true
    // },


    // {
    //     path: '/application',
    //     component: Application,
    //     iconCls: 'fa fa-address-card',
    //     leaf: true,//只有一个节点
    //     root: '3',
    //     children: [
    //         { path: 'index', component: CreditStatus, name: '' },
    //         { path: 'firstStep', component: FirstStep, name: '' },
    //         { path: 'secondStep', component: SecondStep, name: '' },
    //         { path: 'thirdStep', component: ThirdStep, name: '' },
    //         { path: 'mainInfo', component: MainInfo, name: '' }
    //     ]
    // },
    // {
    //     path: '/enterprise',
    //     component: Home,
    //     iconCls: 'fa fa-building',
    //     leaf: true,//只有一个节点
    //     root: '0',
    //     meta:{title: '企业信息管理'},
    //     children: [
    //         { path: '/enterprise/index', component: Enterprise, name:'企业信息管理' }
    //     ]
    // },

    {
        path: '/headerLogin',
        name: 'HeaderLogin',
        component: HeaderLogin
    },
    {
        path: '/headerBase',
        name: 'HeaderBase',
        component: HeaderBase

    },
    {
        path: '/livingCertification',
        name: 'livingCertification',
        component: LivingCertification
    },
    {
        path: '/livingCertificationcus',
        name: 'livingCertificationcus',
        component: LivingCertificationcus
    },

    
    {
        path: '/awaitResult',
        name: 'AwaitResult',
        component: AwaitResult
    },
    {
        path: '/awaitcuresult',
        name: 'Awaitcuresult',
        component: Awaitcuresult
    },
    
    {
        path: '*',
        hidden: true,
        component: NotFound,
        // redirect: { path: '/404' }
    }
];

const router = new VueRouter({
    mode: 'hash',
    routes
})

export default router;