<!--站内信-->
<template>
  <div class="message-page">
    <layout-main-view :breadcrumb="breadcrumblist">
      <div class="common-page-dataList">
        <data-list
          :filters="filterList"
          @data-refresh="handleRefresh"
          :total="total"
          :pagination-options="paginationOptions"
          ref="datalist"
        >
          <div class="search-btn bot">
            <el-button
              type="primary"
              size="small"
              style="font-size:14px;"
              @click.native="handleRemove('multiple')"
            >删除</el-button>
          </div>
          <el-table
            :data="listData"
            :header-cell-style="headerStyle"
            highlight-current-row
            v-loading="listLoading"
            style="width: 100%;"
            :row-class-name="tableRowClassName"
            @current-change="handleCurrentChange"
            row-key="stationLetterId"
            reserve-selection
            @selection-change="getSelections"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="payableBillReminderNode" label="消息类型" min-width="100">
              <template slot-scope="scope">{{getMsgType(scope.row)}}</template>
            </el-table-column>
            <el-table-column prop="message" label="消息标题" min-width="280"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="120">
              <template
                slot-scope="scope"
              >{{scope.row.createTime ? moment(scope.row.createTime).format('YYYY-MM-DD HH:mm'): ''}}</template>
            </el-table-column>
            <el-table-column label="操作" min-width="100">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.stationLetterType == 1 ||(scope.row.payableBillReminderNode == 9 && scope.row.message.includes('转让通知书内容'))"
                  size="medium"
                  type="text"
                  @click="handleTask(getMsgType(scope.row), scope.row)"
                >去处理</el-button>
                <el-button size="medium" type="text" @click.stop="handleRemove(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </data-list>
      </div>
    </layout-main-view>
  </div>
</template>
<script>
import api from '@/api/api';
import bus from '@/util/bus.js';
import moment from 'moment';

export default {
  data() {
    return {
      breadcrumblist: ['站内信管理'],
      filterList: [],
      total: 0,
      paginationOptions: {},
      page: 1,
      listLoading: false,
      corpId: JSON.parse(sessionStorage.getItem('user')).relationCorpId,
      listData: [],
      stationLetterIds: '',
      moment: moment,
      pageUrl: ''
    };
  },
  mounted() {},
  methods: {
    handleRefresh(filters) {
      this.getLists(filters);
    },
    headerStyle({ row, rowIndex }) {
      return { background: '#FAFAFA' };
    },
    //获取列表
    getLists(param) {
      param.corpId = this.corpId;
      api.messageCenterMessage(param).then((res) => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
        this.listLoading = false;
      });
    },
    // 消息类型
    // 1资金机构 2.核心企业 3.一般企业  6.担保企业
    getMsgType(row) {
      if (
        JSON.parse(sessionStorage.user).corpType == 2 ||
        JSON.parse(sessionStorage.user).corpType == 3
      ) {
        if (row.objType == null) {
          // 银e券
          switch (row.payableBillReminderNode) {
            case 1:
              this.pageUrl = '/easypay/openapproval/1';
              return '银e券开立待审批';
            case 2:
              this.pageUrl = '/easypay/mange/2';
              return '银e券接收待确认';
            case 3:
              this.pageUrl =
                '/easypayApproveManage/easypayFinancing/financingApprove/1';
              return '银e券融资待审批';
            case 4:
              this.pageUrl = '/easypay/distinguishingapproval/1';
              return '银e券清分待审批';
            case 5:
              this.pageUrl = '/easypay/distinguishingmange/1';
              return '银e券清分待确认';
            case 6:
              this.pageUrl =
                '/financingProjectManage/easypaySign/financingSign/2';
              return '银e券融资待签约';
            case 7:
              this.pageUrl = '/easypay/mange/6';
              return '银e券被退回';
            case 8:
              this.pageUrl =
                '/financingProjectManage/easypaySign/financingSign/4';
              return '银e券融资失败';
            case 9:
              return '银e券流转通知';
            case 10:
              return '企业认证站内信';
            case 11:
              return '电子签章开户';
            case 12:
              this.pageUrl = '/easypay/openrequestapproval/1';
              return '邀请开单待审核';
            case 13:
              this.pageUrl = '/easypay/openapproval/1';
              return '银e券转单待审核';
            case 14:
              return '银e券融资签约';
            case 17:
              return '融资利率变更通知';
            case 21:
              this.pageUrl = '/financingProjectManage/easypaySign/financingSign/5';
              return '银e券融资签约待确认';
            case 36:
              this.pageUrl = '/financingProjectManage/easypaySign/financingSign/3';
              return '放款凭证待确认';
            case 39:
              return '放款成功';
            case 16:
              return '银e券清分完成'; // 银e券清分
            case 15:
              return '银e券到期通知'; // 银e券到期
            case 19:
              this.pageUrl = '/financingProjectManage/easypaySign/financingSign/4?pageNum=1&pageSize=10';
              return '银e券融资退回'; // 银e券不交平台费
            default:
              return '';
          }
        } else if (row.objType == 3 || row.objType == 4) {
          // 易保理
          switch (row.payableBillReminderNode) {
            case 21:
              this.pageUrl = '/factoring/ReceivableIndexIndex/1';
              break;
            case 22:
              this.pageUrl = '/factoring/CreditorsRightIndex/1';
              break;
            case 23:
              this.pageUrl = '/factoring/accountsreceivablemanage/2';
              break;
            case 25:
              this.pageUrl = '/factoring/accountsreceivablemanage/2';
              break;
            case 6:
              // 无需跳转
              break;
            case 27:
              this.pageUrl = '/factoring/accountsreceivablemanage/2';
              break;
            case 41:
              this.pageUrl = '/factoring/FinacingApprovalIndex/1';
              break;
            case 43:
              this.pageUrl = '/factoring/FactoringManageIndex/6';
              break;
            case 45:
              this.pageUrl = '/factoring/FactoringManageIndex/6';
              break;
            case 47:
              this.pageUrl = '/factoring/FactoringManageIndex/2';
              break;
            case 48:
              this.pageUrl = '/factoring/FactoringManageIndex/3';
              break;
            case 49:
              this.pageUrl = '/factoring/AffirmcreditorRightIndex/1';
              break;
            case 50:
              // 无需跳转
              break;
            case 51:
              this.pageUrl = '/factoring/approvalsupplementsmain/1';
              break;
            case 54:
              this.pageUrl = '/factoring/FactoringManageIndex/4';
              break;
            case 55:
              this.pageUrl = '/factoring/FactoringManageIndex/6';
              break;
          }
          return row.payableBillReminderNodeName;
        } else if (row.objType == 5) {
          // 易票据
          switch (row.payableBillReminderNode) {
            case 1:
              break;
            case 6:
              this.pageUrl = '/bill/billmanage/3';
              break;
            case 13:
              this.pageUrl = '/bill/billmanage/4';
              break;
            case 15:
              this.pageUrl = '/bill/billmanage/5';
              break;
            case 20:
              this.pageUrl = '/bill/bill-manage/1';
              break;
            case 21:
              break;
            case 22:
              this.pageUrl = '/bill/billmanage/6';
              break;
            case 42:
              this.pageUrl = '/bill/billmanage/6';
              break;
          }
          return row.payableBillReminderNodeName;
        } else if (row.objType == 6) {
          // abs
          switch (row.payableBillReminderNode) {
            case 1:
              this.pageUrl = '/abs/accountsCheck/publisher/1';
              break;
            case 2:
              this.pageUrl = '/abs/accountsCheck/acceptor/1';
              break;
            case 3:
              this.pageUrl = '/abs/accountsManage';
              break;
            case 4:
              this.pageUrl = '/abs/accountsManage';
              break;
            case 5:
              this.pageUrl = '/abs/accountsManage';
              break;
            case 6:
              this.pageUrl = '/abs/absplan/5';
              break;
            case 11:
              this.pageUrl = '/abs/absplan/1';
              break;
            case 14:
              this.pageUrl = '/abs/absplan/3';
              break;
            case 20:
              this.pageUrl = '/abs/absplan/3';
              break;
            case 33:
              this.pageUrl = '/abs/absplan/1';
              break;
            case 34:
              this.pageUrl = '/fund/abs/assetsCheck/check/1';
              break;
            case 35:
              this.pageUrl = '/abs/absplan/4';
              break;
          }
          return row.payableBillReminderNodeName;
        }
      } else if (JSON.parse(sessionStorage.user).corpType == 6) {
        if (row.objType == null) {
          // 银e券
          switch (row.payableBillReminderNode) {
            case 1:
              this.pageUrl = '/gteasypay/guaranteeapprovalmain/1';
              return '银e券开立待审批';
            case 2:
              this.pageUrl = '/easypay/mange/2';
              return '银e券接收待确认';
            case 3:
              this.pageUrl =
                '/easypayApproveManage/easypayFinancing/financingApprove/1';
              return '银e券融资待审批';
            case 4:
              this.pageUrl = '/easypay/distinguishingapproval/1';
              return '银e券清分待审批';
            case 5:
              this.pageUrl = '/easypay/distinguishingmange/1';
              return '银e券清分待确认';
            case 6:
              this.pageUrl =
                '/financingProjectManage/easypaySign/financingSign/2';
              return '银e券融资待签约';
            case 7:
              this.pageUrl = '/easypay/mange/6';
              return '银e券被退回';
            case 8:
              this.pageUrl =
                '/financingProjectManage/easypaySign/financingSign/4';
              return '银e券融资失败';
            case 9:
              return '银e券流转通知';
            case 10:
              return '企业认证站内信';
            case 11:
              return '电子签章开户';
            case 13:
              this.pageUrl = '/easypay/openapproval/1';
              return '银e券转单待审核';
            case 16:
              return row.payableBillReminderNodeName; // 银e券清分
            default:
              return '';
          }
        } else if (row.objType == 3 || row.objType == 4) {
          // 易保理
          return row.payableBillReminderNodeName;
        } else if (row.objType == 5) {
          // 易票据
          return row.payableBillReminderNodeName;
        }
      } else if (JSON.parse(sessionStorage.user).corpType == 1) {
        if (row.objType == null) {
          // 银e券
          const roleCodes = JSON.parse(row.roleCodes)[1];
          switch (row.payableBillReminderNode) {
            case 1:
              if(roleCodes === 'fund_first'){
                this.pageUrl = '/fund/easypay/openapproval/1'
              }
              if(roleCodes === 'fund_second'){
                this.pageUrl = '/fund/easypay/openapproval/second'
              }
              if(roleCodes === 'fund_third'){
                this.pageUrl = '/fund/easypay/openapproval/three'
              }
              return '银e券开立待审批';
            case 2:
              this.pageUrl = '/easypay/mange/2';
              return '银e券接收待确认';
            case 3:
              if(roleCodes === 'fund_first'){
                this.pageUrl = '/fund/easypayFinancing/financingApprove/1'
              }
              if(roleCodes === 'fund_second'){
                this.pageUrl = '/fund/easypayFinancing/financingApprove/second/1'
              }
              if(roleCodes === 'fund_third'){
                this.pageUrl = '/fund/easypayFinancing/financingApprove/three/1'
              }
              return '银e券融资待审批';
            case 4:
              this.pageUrl = '/easypay/distinguishingapproval/1';
              return '银e券清分待审批';
            case 5:
              this.pageUrl = '/easypay/distinguishingmange/1';
              return '银e券清分待确认';
            case 6:
              this.pageUrl = '/fund/easypaySign/financingSign/1';
              return '银e券融资待签约';
            case 7:
              this.pageUrl = '/easypay/mange/6';
              return '银e券被退回';
            case 8:
              this.pageUrl =
                '/financingProjectManage/easypaySign/financingSign/4';
              return '银e券融资失败';
            case 9:
              return '银e券流转通知';
            case 10:
              return '企业认证站内信';
            case 11:
              return '电子签章开户';
            case 13:
              this.pageUrl = '/easypay/openapproval/1';
              return '银e券转单待审核';
            case 38:
              this.pageUrl = '/fund/easypaySign/financingSign/2';
              return '放款凭证企业退回';
            case 40:
              this.pageUrl = '/fund/easypaySign/financingSign/2';
              return '放款凭证平台退回';
            case 39:
              return '放款成功';
            case 15:
              return '银e券清分准备'; // 银e券清分
            case 16:
              return '银e券清分完成'; // 银e券清分
            case 17:
              return '融资利率变更通知'; // 银e券清分
            default:
              return '';
          }
        } else if (row.objType == 3 || row.objType == 4) {
          // 易保理
          switch (row.payableBillReminderNode) {
            case 46:
              this.pageUrl = '/fund/factoring/FundFactoringManageIndex/1';
              break;
            case 53:
              this.pageUrl = '/factoring/approvalsupplementsmain/1';
              break;
          }
          return row.payableBillReminderNodeName;
        } else if (row.objType == 5) {
          // 易票据
          switch (row.payableBillReminderNode) {
            case 2: // 资方
              this.pageUrl = '/bill/pjcheckmanage/1';
              break;
            case 3: // 资方
              this.pageUrl = '/bill/pjcheckmanage/1';
              break;
            case 4: // 资方
              this.pageUrl = '/bill/pjcheckmanage/1';
              break;
            case 5: // 资方
              break;
            case 7: // 资方
              this.pageUrl = '/bill/billmanagezj/4';
              break;
            case 8: // 资方
              this.pageUrl = '/bill/pjcheckmanage/1';
              break;
            case 9: // 资方
              this.pageUrl = '/bill/pjcheckmanage/2';
              break;
            case 10: // 资方
              break;
            case 11: // 资方
              this.pageUrl = '/bill/billmanagezj/5';
              break;
            case 12: // 资方
              this.pageUrl = '/bill/billmanagezj/2';
              break;
            case 14: // 资方
              this.pageUrl = '/bill/billmanagezj/3';
              break;
            case 47: // 资方
              this.pageUrl = '/bill/billcenterzj';
              break;
          }
          return row.payableBillReminderNodeName;
        } else if (row.objType == 6) {
          // abs
          switch (row.payableBillReminderNode) {
            case 6:
              this.pageUrl = '/abs/absplan/5';
              break;
            case 11:
              this.pageUrl = '/abs/absplan/1';
              break;
            case 14:
              this.pageUrl = '/abs/absplan/3';
              break;
            case 17:
              this.pageUrl = '/fund/abs/assetsCheck/check/1';
              break;
            case 19:
              this.pageUrl = '/fund/abs/assetsCheck/recheck/1';
              break;
            case 20:
              this.pageUrl = '/abs/absplan/3';
              break;
            case 21:
              this.pageUrl = '/fund/abs/assetsCheck/finalCheck/1';
              break;
            case 22:
              this.pageUrl = '/fund/abs/assetsCheck/check/1';
              break;
            case 23:
              this.pageUrl = '/abs/absplan/2';
              break;
            case 33:
              this.pageUrl = '/abs/absplan/1';
              break;
            case 34:
              this.pageUrl = '/fund/abs/assetsCheck/check/1';
              break;
            case 35:
              this.pageUrl = '/abs/absplan/4';
              break;
          }
          return row.payableBillReminderNodeName;
        }
      }
    },
    // 未读消息样式处理
    tableRowClassName({ row, rowIndex }) {
      if (row.readStatus == 2) {
        return 'mark-row';
      }
      return '';
    },
    // 去处理-跳转到相应页面
    handleTask(payableBillReminderNode, row) {
      console.log(row,'row')
      if(row.payableBillReminderNode == 9 && row.message.includes('转让通知书内容')) {
        this.$router.push("/easypay/couldAcountTransferInfo")
      }else{
      this.$router.push({ path: this.pageUrl });
      }
      // this.$nextTick(()=>{
      // })
      this.handleCurrentChange(row);
      
    },
    // 删除消息
    handleRemove(row) {
      if (row != 'multiple') {
        this.stationLetterIds = row.stationLetterId;
      }
      this.$alert('确认删除该记录吗?')
        .then((_) => {
          type: 'warning';
          api
            .deleteMessage({ stationLetterIds: this.stationLetterIds })
            .then((res) => {
              if (res.success) {
                this.$message({
                  message: '删除成功',
                  type: 'success'
                });
                this.getLists({
                  pageNum: this.$route.query.pageNum,
                  pageSize: this.$route.query.pageSize
                });
                bus.$emit('getMessageCenterMessageTotal');
              }
            });
        })
        .catch(() => {});
    },
    // 获取勾选的列
    getSelections(rows) {
      let list = rows.map((v) => {
        return v.stationLetterId;
      });
      this.stationLetterIds = list.join(',');
    },
    // 置为已读
    handleCurrentChange(val) {
      if (val.readStatus == 2) {
        val.readStatus = 1;
        api.setToRead({ stationLetterId: val.stationLetterId }).then((res) => {
          if (res.success) {
            bus.$emit('getMessageCenterMessageTotal');
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.message-page {
}
.common-page-dataList {
  padding: 24px 24px;
}
.bot {
  margin-bottom: 16px;
}
</style>
<style lang="scss">
.message-page {
  .el-table .mark-row {
    font-weight: 600;
  }
}
</style>
