<template>
  <div class="content easypay-manager">
    <div class="content-inner">
      <!--<steps :stepList="stepList" :current="current"></steps>-->

      <section class="info-card-A" style="height: calc(100vh - 150px)">
        <div class="card-number">
          <span class="card-number-title">融资银e券编号:</span>
          <span class="card-number-value">{{
            payableBillFinance.financePayableBillNo
          }}</span>
          <el-button
            class="card-number-btn"
            type="primary"
            size="small"
            icon="el-icon-zoom-in"
            @click="preview"
            >预览</el-button
          >
        </div>

        <div class="card-hd sub-title">
          <span class="txbst">基本信息</span>
        </div>
        <div class="card-bd card-bd-row">
          <el-form
            :model="ruleform"
            ref="ruleform"
            label-width="150px"
            class="demo-ruleForm"
            label-position="left"
            v-if="type == 1"
          >
            <el-row class="row">
              <el-col :span="8" class="lb_body">
                <el-form-item label="银e券余额（元）:" prop>
                  <span class="info-value">{{
                    $changeMoneyThousand(financInfo.balanceAmount)
                  }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="lb_body">
                <el-form-item label="银e券净额（元）:" prop>
                  <span class="info-value">{{
                    $changeMoneyThousand(financInfo.financeAmountable)
                  }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="lb_body">
                <el-form-item label="承诺付款日:" prop>
                  <span class="info-value">{{
                    financInfo.promisePayTime
                      ? moment(financInfo.promisePayTime).format('YYYY-MM-DD')
                      : ''
                  }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="lb_body companyName">
                <el-form-item label="资金机构:" prop>
                  <span class="info-value">{{ financInfo.fundCorpName }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="lb_body">
                <el-form-item
                  :label="isFirstApproval ? '预估' : '' + '融资利率(%/年化):'"
                  prop
                >
                  <span class="info-value"
                    >{{ financInfo.financeYearInterest }}％</span
                  >
                </el-form-item>
              </el-col>
              <el-col :span="8" class="lb_body companyName">
                <el-form-item label="收款人账户名:" prop>
                  <span class="info-value">{{
                    financInfo.receivedCorpName
                  }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="lb_body">
                <span class="info-key">承诺付款天数:</span>
                <span class="info-value">{{ financInfo.promiseDay }}</span>
              </el-col>

              <el-col :span="8">
                <el-form-item label="融资总额(元): ">
                  <el-input
                    type="number"
                    v-model="ruleform.payableAmount"
                    placeholder="总额不能大于该银e券净额"
                    @change="textChange"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="8">
              <el-form-item label="开立银e券金额(大写): ">
                <el-input disabled></el-input>
              </el-form-item>
              </el-col>-->
              <el-col :span="8">
                <el-form-item label="预计融资利息（元）: " prop>
                  <el-input
                    disabled
                    v-model="financInfoFinance.preFinanceInterest"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!--              <el-col :span="8">-->
              <!--                <el-form-item class="info-key" label="信息咨询费（元）: " prop>-->
              <!--                  <span class="info-value">100</span>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
            </el-row>
            <el-row class="row">
              <el-col :span="8">
                <el-form-item label="预计融资金额（元）: ">
                  <el-input
                    v-model="financInfoFinance.preIncomeAmount"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="企业开户行: " prop>
                  <el-input
                    v-model="financInfoFinance.openBankReceiver"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item class="info-key" label="企业账号: " prop>
                  <el-input
                    v-model="financInfoFinance.publicAccountReceiver"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- <el-row class="row">
              <el-col :span="8">
                <el-form-item label="预计融资金额（元）: " class="pr12">
                  <el-input v-model="financInfoFinance.preIncomeAmount" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="企业开户行: " prop="openBank" class="pr12">
                  <el-select v-model="ruleform.openBank" placeholder="请选择" @change="getOpenBankNo">
                    <el-option
                      v-for="item in corpAccountPublicList"
                      :key="item.openBank"
                      :label="item.openBank"
                      :value="item.openBank"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item class="info-key pr12" label="企业账号: " prop="openBankNo">
                  <el-select v-model="ruleform.openBankNo" placeholder="请选择">
                    <el-option
                      v-for="item in corpAccountPublicNoList"
                      :key="item.accountNo"
                      :label="item.accountNo"
                      :value="item.accountNo"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>-->

            <p v-if="type == 1" class="info-value">费用说明：</p>
            <p>
              (1)预计融资期限＝银e券承诺付款日－预计放款日期（申请时间2天内）
            </p>
            <p>
              (2)预计融资利息＝融资金额＊融资年化利率(年化)＊预计融资期限／360
            </p>
            <p>
              (3)平台服务费＝融资金额＊0.3%（年化）＊预计融资期限／360；最低500元
            </p>
            <p>(4)平台杂费及其他＝100元，定额费用</p>
          </el-form>
          <el-form
            :model="ruleform"
            ref="ruleform"
            label-width="150px"
            class="demo-ruleForm"
            label-position="left"
            v-if="type == 2"
          >
            <el-row class="row">
              <el-col :span="8" class="lb_body">
                <span class="info-key">融资项目编号:</span>
                <span class="info-value index">{{
                  payableBillFinance.payableBillFinanceNo
                }}</span>
              </el-col>
              <el-col :span="8" class="lb_body">
                <span class="info-key">当前银e券金额（元）:</span>
                <span class="info-value index">{{
                  $changeMoneyThousand(payableBillFinance.amount)
                }}</span>
              </el-col>
              <el-col :span="8" class="lb_body">
                <span class="info-key">承诺付款日:</span>
                <span class="info-value index">{{
                  financInfo.promisePayTime | formatDate
                }}</span>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="lb_body">
                <span class="info-key">融资企业:</span>
                <span class="info-value index">{{
                  payableBillFinance.financeCorpName
                }}</span>
              </el-col>
              <el-col :span="8" class="lb_body">
                <span class="info-key">
                  {{ (isFirstApproval ? '预估' : '') + '融资利率(%/年化):' }}
                </span>
                <span class="info-value index"
                  >{{ payableBillFinance.financeYearInterest }}％</span
                >
              </el-col>
              <el-col :span="8" class="lb_body">
                <span class="info-key">融资金额（元）:</span>
                <span class="info-value index">{{
                  $changeMoneyThousand(payableBillFinance.amount)
                }}</span>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="lb_body">
                <span class="info-key">
                  {{ (isFirstApproval ? '预计' : '') + '融资利息（元）:' }}
                </span>
                <span class="info-value index">{{
                  $changeMoneyThousand(payableBillFinance.preFinanceInterest)
                }}</span>
              </el-col>
              <!-- <el-col :span="8" class="lb_body">
                <span class="info-key">技术服务费（元）:</span>
                <span class="info-value index">{{$changeMoneyThousand(payableBillFinance.platformServiceFee)}}</span>
              </el-col>
              <el-col :span="8" class="lb_body">
                <span class="info-key">信息咨询费（元）:</span>
                <span class="info-value index">{{$changeMoneyThousand(payableBillFinance.informationFee)}}</span>
              </el-col>-->
              <el-col :span="8" class="lb_body">
                <span class="info-key">
                  {{ (isFirstApproval ? '预计' : '') + '融资金额（元）:' }}
                </span>
                <span class="info-value index">{{
                  $changeMoneyThousand(payableBillFinance.preIncomeAmount)
                }}</span>
              </el-col>
              <el-col :span="8" class="lb_body">
                <span class="info-key">承诺付款天数:</span>
                <span class="info-value">{{ financInfo.promiseDay }}</span>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <!-- <div class="card-hd sub-title">
          <span class="txbst">融资收款账户</span>
        </div>
         <div  class="account-content">
              <div class="base-item">
                收款账户：
                <span style="margin-left:20px">{{ accountInfo.feeCorpName }}</span>
              </div>
              <div class="base-item">
                开户行：
                <span style="margin-left:20px">{{ accountInfo.feeBank }}</span>
              </div>
              <div class="base-item">
                收款账号：
                <span style="margin-left:20px">{{ accountInfo.feeBankNo }}</span>
              </div>
            </div>
        </div> -->
        <span v-if="type == 1" class="info-value"
          >温馨提示：还没有绑定卡，</span
        >
        <a
          v-if="type == 1"
          class="downsize"
          style="color: #2937a6; text-decoration: underline; cursor: pointer"
          size="small"
          @click="linkCompanyManage"
          >点击此处</a
        >
        <span v-if="type == 1" class="info-value">完成对公账户绑定。</span>
        <div class="card-line"></div>

        <div class="card-hd sub-title">
          <span class="txbst">合同信息</span>
        </div>
        <div
          class="maincontin"
          v-for="(item, index) in financInfo.financInfoContract"
          :key="index"
        >
          <!-- <div class="maincontin">  -->

          <span class="mainht">
            <span class="info-key">合同编号:</span>
            <span class="info-value">{{ item.contractNo }}</span>
            <a
              class="page-head-back downsize"
              style="color: #2937a6"
              size="small"
              :href="api.filesdownLoadFile(item)"
              >一键下载</a
            >
          </span>

          <div class="htmsg1">
            <el-row>
              <el-col :span="6" class="lb_body">
                <span class="info-key">合同编号:</span>
                <span class="info-value">{{ item.contractNo }}</span>
              </el-col>
              <el-col :span="6" class="lb_body">
                <span class="info-key">合同名称:</span>
                <span class="info-value">{{ item.contractName }}</span>
              </el-col>
              <el-col :span="6" class="lb_body">
                <span class="info-key">合同金额:</span>
                <span class="info-value"
                  >{{ $changeMoneyThousand(item.contractAmount) }}元</span
                >
              </el-col>
              <el-col :span="6" class="lb_body">
                <span class="info-key">合同签署时间:</span>
                <span class="info-value">{{
                  item.contractTime
                    ? moment(item.contractTime).format('YYYY-MM-DD')
                    : '--'
                }}</span>
              </el-col>
            </el-row>
          </div>
          <div class="card-hd sub-title" style="margin-bottom: 24px">
            相关合同
          </div>
          <div
            class="card-bd marrimg"
            style="padding-left: 30px; margin-top: -20px"
          >
            <transition
              name="fade"
              v-if="
                item.payableBillContractList &&
                item.payableBillContractList.length &&
                item.payableBillContractList.length > 0
              "
            >
              <el-row>
                <picfile :picfilelist="item.payableBillContractList"></picfile>
              </el-row>
            </transition>
            <!--<el-row>-->
            <!--<img-->
            <!--:src="api.getImgURL(items.fileUrl)"-->
            <!--alt-->
            <!--width="19%"-->
            <!--height="144px"-->
            <!--preview-text="合同"-->
            <!--:preview="index+'0'"-->
            <!--v-for="(items,key) in  item.htlist"-->
            <!--:key="key"-->
            <!--/>-->
            <!--</el-row>-->
            <div>
              <div>
                共有文件:
                <span class="numcolor">{{ item.htlist.length }}</span> 个
              </div>
            </div>
          </div>
          <div class="card-hd sub-title">
            相关发票
            <!-- <el-button
            type="text"
            class="page-head-back"
            style="color: #2937A6;"
            size="small"
            @click="goBack"
            >一键下载</el-button>-->
          </div>
          <div class="card-bd marrimg" style="padding: 24px 30px 51px 30px">
            <transition
              name="fade"
              v-if="item.fplist && item.fplist.length && item.fplist.length > 0"
            >
              <el-row>
                <picfile :picfilelist="item.fplist"></picfile>
              </el-row>
            </transition>
            <!--<el-row>-->
            <!--<img-->
            <!--:src="api.getImgURL(items.fileUrl)"-->
            <!--alt-->
            <!--width="20%"-->
            <!--height="144px"-->
            <!--preview-text="发票"-->
            <!--:preview="index+'2'"-->
            <!--v-for="(items,key) in  item.fplist"-->
            <!--:key="key"-->
            <!--/>-->
            <!--</el-row>-->
            <div style="padding: 0px 0px 30px 0px">
              共有文件:
              <span class="numcolor">{{ item.fplist.length }}</span> 个
              <span style="margin-left: 32px">累计发票金额：</span>
              <span class="numcolor">{{
                $changeMoneyThousand(item.invoiceAllAmount)
              }}</span>
              元
            </div>
            <div>
              <el-table
                :data="item.payableBillInvoiceList"
                highlight-current-row
                v-loading="listLoading"
                style="width: 100%"
              >
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column
                  prop="invoiceBuyer"
                  label="购买方"
                  min-width="170"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceBuyerChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceBuyer }}</el-badge
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="invoiceSeller"
                  label="销售方"
                  min-width="170"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceSellerChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceSeller }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceCode"
                  label="发票代码"
                  min-width="120"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceCodeChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceCode }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceNo"
                  label="发票号码"
                  min-width="100"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceNoChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceNo }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceCheckCode"
                  label="校验码"
                  min-width="120"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceCheckCodeChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceCheckCode }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceOpenTime"
                  label="开票日期"
                  min-width="100"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceOpenTimeChange == 2 ? '改' : ''"
                      >{{ scope.row.invoiceOpenTime }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceAmount"
                  label="开具金额(元)"
                  width="150"
                  align="left"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceAmountChange == 2 ? '改' : ''"
                      >{{
                        $changeMoneyThousand(scope.row.invoiceAmount)
                      }}</el-badge
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceAmountWithoutTax"
                  label="开具金额(不含税)"
                  width="200"
                  align="left"
                >
                  <template slot-scope="scope">
                    <el-badge
                      class="isdot"
                      :value="scope.row.invoiceAmountWithoutTaxChange == 2 ? '改' : ''"
                      >{{
                        $changeMoneyThousand(scope.row.invoiceAmountWithoutTax)
                      }}</el-badge
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="card-hd sub-title">
            其他附件
            <!-- <el-button
            type="text"
            class="page-head-back"
            style="color: #2937A6;"
            size="small"
            @click="goBack"
            >一键下载</el-button>-->
          </div>
          <div
            class="card-bd marrimg"
            style="padding-bottom: 51px; padding-left: 30px"
          >
            <!--<span class="info-key">物流类型:</span>-->
            <transition
              name="fade"
              v-if="
                item.payableBillWuliuList &&
                item.payableBillWuliuList.length &&
                item.payableBillWuliuList.length > 0
              "
            >
              <el-row>
                <picfile :picfilelist="item.payableBillWuliuList"></picfile>
              </el-row>
            </transition>
            <div>
              共有文件:
              <span class="numcolor">{{
                item.wuliulist.length + item.banklist.length
              }}</span>
              个
            </div>
          </div>
        </div>

        <div class="maincontin">
          <span class="mainht">
            <span class="info-key">融资补件资料</span>
          </span>
          <div class="card-bd marrimg" style="padding: 24px 30px 51px 30px">
            <span class="info-key">请根据资金需求补充完善融资资料:</span>
            <div v-if="type == 1">
              <el-table
                :data="financInfo.informationList"
                highlight-current-row
                v-loading="listLoading"
                style="width: 100%"
              >
                <el-table-column
                  type="index"
                  width="100"
                  label="序号"
                ></el-table-column>
                <el-table-column
                  prop="informationName"
                  label="资料名称"
                  min-width="170"
                ></el-table-column>
                <el-table-column label="模版" min-width="170">
                  <template slot-scope="scope">
                    <el-button
                      size="medium"
                      type="text"
                      @click="handleEdit(scope.row)"
                      >下载</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="claimRemark"
                  label="说明备注"
                  min-width="120"
                ></el-table-column>
                <el-table-column
                  prop="fileName"
                  label="附件"
                  min-width="100"
                ></el-table-column>
                <el-table-column label="补件" min-width="100">
                  <template slot-scope="scope">
                    <el-upload
                      class="upload-demo upload-list"
                      :action="baseURL + '/web/file/uploadFile'"
                      :before-upload="beforeUp"
                      :on-success="(a, b, c) => handleupfn(a, b, c, index)"
                      :data="fileTypes"
                      :headers="Authorization"
                    >
                      <el-button
                        size="medium"
                        type="text"
                        @click="upload(scope)"
                        >上传</el-button
                      >
                    </el-upload>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-if="type == 2">
              <el-table
                :data="modelList"
                highlight-current-row
                v-loading="listLoading"
                style="width: 100%"
              >
                <el-table-column
                  type="index"
                  width="100"
                  label="序号"
                ></el-table-column>
                <el-table-column
                  prop="informationName"
                  label="资料名称"
                  min-width="170"
                ></el-table-column>
                <el-table-column
                  prop="claimRemark"
                  label="说明备注"
                  min-width="200"
                ></el-table-column>
                <el-table-column prop="fileName" label="附件" min-width="120">
                  <template slot-scope="scope">
                    <!-- <span @click="cilckImg(scope.row)">{{scope.row.fileName}}</span> -->
                    <img
                      v-if="scope.row.fileType == '.jpg,.png,.jpeg,.bmg'"
                      :src="api.getImgURL(scope.row.fileUrl)"
                      alt="图片丢失"
                      width="50%"
                      height="50%"
                      :preview-text="scope.row.fileName"
                      :preview="1"
                    />
                    <div v-else>
                      <span>{{ scope.row.fileName }}</span>
                      <el-button type="text" @click="download(scope.row)"
                        >点击下载</el-button
                      >
                      <!-- <a
                          class="page-head-back downsize"
                          style="color: #2937A6;"
                          size="small"
                          :href="api.filesdownsingle(scope.row.fileUrl)"
                      >点击下载</a>-->
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </section>

      <div class="card-footer" v-if="$route.query.isshowbtn == 1">
        <el-button v-if="type == 1" @click="goBack" class="card-btn"
          >取消</el-button
        >
        <el-button
          v-if="type == 2"
          size="small"
          @click="reject"
          class="card-btn"
          type="primary"
          >拒绝</el-button
        >
        <el-button
          v-if="type == 1"
          size="small"
          @click="financingAction"
          class="card-btn"
          type="primary"
          >发起融资</el-button
        >
        <el-button
          v-if="type == 2"
          size="small"
          @click="showInterestFn"
          class="card-btn"
          type="primary"
          >同意</el-button
        >
      </div>
      <div class="card-footer" v-if="$route.query.isshowbtn == 2">
        <el-button class="card-btn" type="primary" @click="goBack"
          >返回</el-button
        >
      </div>
    </div>
    <!-- Form -->
    <el-dialog title="提示信息" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item
          label="拒绝原因"
          :label-width="formLabelWidth"
          prop="reason"
        >
          <el-select v-model="form.reason" placeholder="请选择拒绝理由">
            <el-option
              v-for="item in rejectList"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="填写备注信息" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            v-model="form.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="submit">提交</el-button>
      </div>
    </el-dialog>

    <el-dialog title :visible.sync="centerDialogVisible" width="80%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <pdf class="pdf" ref="pdf" :src="src"></pdf>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title :visible.sync="contractDialogVisible" width="80%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <section
        class="info-card-A"
        style="padding-top: 32px"
        v-if="contractContent"
      >
        <div class="maincontin" v-html="contractContent"></div>
      </section>
      <span slot="footer" class="dialog-footer">
        <el-button @click="contractDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitApproval">确 定</el-button>
      </span>
    </el-dialog>
    <!-- //添加融资 -->
    <el-dialog
      title="实际利率确认"
      :visible.sync="preFinanceYearInterestDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="900px"
    >
      <div class="preFinanceYearInterestDialog">
        <el-form
          :model="preFinanceInterestobj"
          ref="preFinanceInterestobj"
          label-width="135px"
          class="demo-ruleForm"
          :rules="rules2"
          label-position="right"
        >
          <el-row :gutter="0">
            <el-col :span="8">
              <span>融资金额(元)：</span>
              <em>{{
                $changeMoneyThousand(this.payableBillFinance.amount)
              }}</em>
            </el-col>
            <el-col :span="8">
              <span>预估融资利率(%/年化)：</span>
              <em>{{ this.payableBillFinance.financeYearInterest }}</em>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="融资利率(%/年)：" prop="preFinanceInterest">
                <el-input
                  v-model="preFinanceInterestobj.preFinanceInterest"
                  placeholder="请输入融资利率"
                  @change="getmoney"
                  size="mini"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="融资利息(元)：">
                <em>{{
                  $changeMoneyThousand(
                    this.preFinanceInterestobj.preFinanceInterestmoney
                  )
                }}</em>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="融资金额(元)：">
                <em>{{
                  $changeMoneyThousand(
                    this.preFinanceInterestobj.preIncomeAmount
                  )
                }}</em>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="preFinanceYearInterestDialog = false"
          >关闭</el-button
        >
        <el-button type="primary" @click="gotosubmit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import pdf from 'vue-pdf';
import picfile from '@/components/picfile';
import api from '@/api/api';
import { baseURL } from '@/api/sys';
import steps from '../../../views/base/steps.vue';
function openDownloadDialog(url, saveName) {
  // for ie 10 and later

  if (window.navigator.msSaveBlob) {
    try {
      window.navigator.msSaveBlob(url, saveName);
    } catch (e) {
      console.log(e);
    }
  }
  // 谷歌浏览器 创建a标签 添加download属性下载
  else {
    if (typeof url == 'object' && url instanceof Blob) {
      url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) {
      event = new MouseEvent('click');
    } else {
      event = document.createEvent('MouseEvents');
      event.initMouseEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
    }
    aLink.dispatchEvent(event);
  }
}

Number.prototype['adds'] = function (...arg) {
  var r1,
    r2,
    m,
    result = this;
  arg.forEach((value) => {
    try {
      r1 = result.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = value.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    result = Math.round(result * m + value * m) / m;
  });
  return result;
};
export default {
  components: {
    pdf,
    steps,
    picfile,
  },
  data() {
    return {
      Authorization: { Authorization: sessionStorage.token },
      preFinanceYearInterestDialog: false,
      preFinanceInterestobj: {
        preFinanceInterest: '', //实际利率
        preFinanceInterestmoney: '', //实际融资金额
        preIncomeAmount: '', //融资金额
      },
      ruleform: {},
      ruleform2: {
        contractList: [{}],
      },
      financInfo: {},
      financInfoFileList: [],
      financInfoFinance: {},
      payableBillFinance: {},
      listData: [],
      tocapital: '',
      listLoading: false,
      financInfo: {}, //基本信息
      accountInfo: {},
      baseURL: baseURL,
      api: api,

      type: '', //1 代表发起融资详情页 2 代表复审详情页
      payableBillId: '',
      payableBillFinanceId: '',

      dialogFormVisible: false, // 拒绝原因
      formLabelWidth: '120px',
      form: {
        reason: '',
        remark: '',
      },
      rules: {
        reason: [
          { required: true, message: '请选择拒绝原因', trigger: 'blur' },
        ],
      },
      rules2: {
        preFinanceInterest: [
          { required: true, message: '请输入融资利率', trigger: 'blur' },
          {
            pattern: /(^([1-9]{1}\d*)(\.\d{1,2})?$)|(^0\.\d{1,2}?$)/,
            message: '请输入大于0,两位小数',
            trigger: 'blur',
          },
        ],
      },
      rejectList: [], //拒绝原因
      isShowBtn: '',
      corpAccountPublicList: [], //开户行
      corpAccountPublicNoList: [], // 开户账号
      centerDialogVisible: false,
      src: '',
      modelList: [],
      fileTypes: {
        payableBillFinanceId: '',
      }, //上传参数
      row: {},
      index: '',
      contractContent: '',
      contractDialogVisible: false,
      param: {},
      imgUrl: '',
      // 融资申请－融资审核－平台审核－资方审核－融资签约－融资放款
      stepList: [
        {
          name: '融资申请',
          realName: '',
          createTime: '',
        },
        {
          name: '融资审核',
          realName: '',
          createTime: '',
        },
        {
          name: '平台审核',
          realName: '',
          createTime: '',
        },
        {
          name: '资方审核',
          realName: '',
          createTime: '',
        },
        {
          name: '融资签约',
          realName: '',
          createTime: '',
        },
        {
          name: '融资放款',
          realName: '',
          createTime: '',
        },
      ],
      current: '',
      isSub: false,
      isFirstApproval: this.$route.query.approveStatus == 45,
    };
  },
  created() {
    //this.$route.query.type 1 代表发起融资详情页 2 代表复审详情页
    this.current = 4;
    this.type = this.$route.query.type;
    this.payableBillId = this.$route.query.id;
    this.isShowBtn = this.$route.query.isshowbtn;
    if (this.type == 2) {
      this.payableBillFinanceId = this.$route.query.payableBillFinanceId;
    }

    //融资
    if (this.$route.query.financePayStatus === 3) {
      //融资申请－融资审核－平台审核－资方审核－融资签约－融资放款
      this.current = 6;
    } else if (this.$route.query.financeSignStatus === 50) {
      //融资申请－融资审核－平台审核－资方审核－融资签约
      this.current = 5;
    } else if (
      this.$route.query.financeSignStatus >= 20 ||
      this.$route.query.approveStatus >= 55
    ) {
      //融资申请－融资审核－平台审核－资方审核
      this.current = 4;
    } else if (this.$route.query.approveStatus > 43) {
      //融资申请－融资审核－平台审核
      this.current = 3;
    } else if (this.$route.query.approveStatus > 22) {
      //融资申请－融资审核
      this.current = 2;
    } else if (this.$route.query.approveStatus > 20) {
      //融资申请
      this.current = 1;
    }

    this.getInfo(); //获取基本信息
  },
  mounted() {},
  methods: {
    moment: moment,
    getmoney(val) {
      if (
        /(^([1-9]{1}\d*)(\.\d{1,2})?$)|(^0\.\d{1,2}?$)/.test(
          this.preFinanceInterestobj.preFinanceInterest
        )
      ) {
        api
          .PayableBillFinanceCompute({
            amount: this.payableBillFinance.amount,
            payableBillId: this.payableBillId,
            financeYearInterest: val,
            payableBillFinanceId: this.$route.query.payableBillFinanceId,
          })
          .then((res) => {
            if (res.success) {
              // this.financInfoFinance.preFinanceInterest
              this.preFinanceInterestobj.preFinanceInterestmoney =
                res.datas.payableBillFinance.preFinanceInterest;
              this.preFinanceInterestobj.preIncomeAmount =
                res.datas.payableBillFinance.preIncomeAmount;
            }
          });
      } else {
        // this.$message.error('请输入最多两位小数并且为正数的融资利率');
      }
    },
    //提交融资利率
    gotosubmit() {
      if (this.preFinanceInterestobj.preFinanceInterest > 100) {
        this.$message.error('请输入小于100最多2位小数');
        return;
      }
      this.$refs['preFinanceInterestobj'].validate((valid) => {
        if (valid) {
          this.agreeAction(this.preFinanceInterestobj);
          this.preFinanceYearInterestDialog = false;
        }
      });
    },
    //获取合同模版
    getFinanceContact() {
      api.GetFinanceContact().then((res) => {
        if (res.success) {
          this.contractContent = res.datas.contract.contractContent;
        }
      });
    },
    getBindAccountList() {
      api.GetBindAccountList('').then((res) => {
        if (res.success) {
          this.corpAccountPublicList = res.datas.corpAccountPublicList;
        }
      });
    },
    getOpenBankNo() {
      // 获取开户账号
      console.log('openBank ===>', this.ruleform.openBank);
      this.ruleform.openBankNo = '';
      this.corpAccountPublicNoList = [];
      api.GetBindAccountList(this.ruleform.openBank).then((res) => {
        if (res.success) {
          this.corpAccountPublicNoList = res.datas.corpAccountPublicList;
        }
      });
    },
    //返回
    goBack() {
      this.$router.go(-1); //返回上一层
    },
    //获取详情
    getInfo() {
      if (this.type == 1) {
        api
          .ToPayableBillFinance({ payableBillId: this.payableBillId })
          .then((res) => {
            console.log('res ===>', res.datas);
            if (res.success) {
              this.financInfo = res.datas.payableBill;
              this.accountInfo = res.datas.payableBillFee;
              console.log(this.accountInfo, 'account');
              this.financInfo.financInfoContract =
                res.datas.payableBillFileList;
              this.financInfo.financInfoContract.forEach((el) => {
                el.htlist = [];
                el.fplist = [];
                el.otherlist = [];
                el.htlist = el.payableBillContractList;
                el.fplist = el.payableBillInvoiceList;
                el.wuliulist = el.payableBillWuliuList;
                el.banklist = el.payableBillBankList;
                // el.otherlist.push.apply(el.otherlist, el.payableBillWuliuList);
                // el.otherlist.push.apply(el.otherlist, el.payableBillBankList);
              });
            }
          });
      } else {
        api
          .FinanceDetial({
            payableBillId: this.payableBillId,
            payableBillFinanceId: this.payableBillFinanceId,
          })
          .then((res) => {
            console.log('res ===>', res.datas);
            if (res.success) {
              this.financInfo = res.datas.payableBill;
              this.accountInfo = res.datas.payableBillFee;
              this.financInfo.financInfoContract =
                res.datas.payableBillFileList;
              this.payableBillFinance = res.datas.payableBillFinance;

              for (
                let index = 0;
                index < res.datas.informationList.length;
                index++
              ) {
                const element = res.datas.informationList[index];
                for (
                  let i = 0;
                  i <
                  res.datas.payableBillFinance.payableBillFinanceFileList
                    .length;
                  i++
                ) {
                  const ele =
                    res.datas.payableBillFinance.payableBillFinanceFileList[i];

                  console.log(
                    ' annexCode ======= fileCode',
                    element.annexCode,
                    ele.fileCode
                  );
                  if (element.annexCode == ele.fileCode) {
                    console.log('相同的code');
                    var fileTypeList = ele.fileName.split('.');
                    this.modelList.push({
                      informationName: element.informationName,
                      claimRemark: element.claimRemark,
                      fileName: ele.fileName,
                      fileUrl: ele.fileUrl,
                      fileType: _.last(fileTypeList),
                    });
                  }
                }
              }

              this.financInfo.financInfoContract.forEach((el) => {
                el.htlist = [];
                el.fplist = [];
                el.otherlist = [];
                el.htlist = el.payableBillContractList;
                el.fplist = el.payableBillInvoiceList;
                el.wuliulist = el.payableBillWuliuList;
                el.banklist = el.payableBillBankList;
              });
              console.log(
                'this.financInfo.financInfoContract ==>',
                this.financInfo.financInfoContract
              );
            }
          });
      }
    },
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    headerStyle({ row, rowIndex }) {
      return { background: '#FAFAFA' };
    },
    handleRefresh(filters) {
      console.log('filters<', filters);
      this.getLists(filters);
    },
    //查询
    getLists(param) {},
    jsmount() {
      var arr = [];
      this.ruleform2.contractList.map((el) => {
        arr.push(el.contractAmount);
      });
      this.mounttoall = (0).adds(...arr);
      console.log(arr);
      console.log(this.mounttoall);
    },
    textChange(val) {
      console.log('val ====>', val);
      if (val < 0) {
        this.$message.error('请输入正确金额');
        return;
      }
      let param = {
        payableBillId: this.payableBillId,
        amount: val,
        payableBillFinanceId: this.$route.query.payableBillFinanceId,
      };

      console.log(param, 'param');

      api.PayableBillFinanceCompute(param).then((res) => {
        console.log('res ===>', res.datas);
        if (res.success) {
          this.financInfoFinance = res.datas.payableBillFinance;
        }
      });
    },
    //发起融资
    financingAction() {
      this.$refs.ruleform.validate((valid) => {
        if (valid) {
          if (this.ruleform.payableAmount < 0) {
            this.$message.error('请输入正确金额');
            return;
          }
          var param = {
            amount: this.ruleform.payableAmount,
            financeUuid: this.financInfoFinance.financeUuid,
            // publicAccountReceiver: this.ruleform.openBankNo,
            platformServiceFee: this.financInfoFinance.platformServiceFee,
            preIncomeAmount: this.financInfoFinance.preIncomeAmount,
            preFinanceInterest: this.financInfoFinance.preFinanceInterest,
            // openBankReceiver: this.ruleform.openBank,
            promisePayTime: this.financInfo.promisePayTime,
          };
          this.param = param;
          //需要展示合同信息
          this.getFinanceContact();

          this.$confirm(
            '请仔细阅读《平台收费服务协议》相关条款，并确认签署',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              closeOnClickModal: false,
              // type: "warning"
            }
          )
            .then(() => {
              this.contractDialogVisible = false;
              this.submitApproval();
            })
            .catch(() => {});
        }
      });
    },
    showInterestFn() {
      if (this.$route.query.approveStatus == 45) {
        this.preFinanceYearInterestDialog = true;
      } else {
        this.agreeAction({
          preFinanceInterest: this.payableBillFinance.financeYearInterest,
          preFinanceInterestmoney: this.payableBillFinance.preFinanceInterest,
          preIncomeAmount: this.payableBillFinance.preIncomeAmount,
        });
      }
    },
    //同意 复核
    agreeAction(val) {
      //

      var user = JSON.parse(sessionStorage.getItem('user'));
      var operatorTypeval = 22;
      switch (this.$route.query.approveStatus) {
        case 45:
          operatorTypeval = 22;
          break;
        case 51:
          operatorTypeval = 23;
          break;
        case 53:
          operatorTypeval = 24;
          break;
      }

      var yfdMessageDto = {
        itemId: this.$route.query.payableBillFinanceId,
        itemType: 2,
        operatorAction: 1,
        operatorType: operatorTypeval,
        phone: user.phone,
      };

      api.sendPhoneMessage(yfdMessageDto).then((res) => {
        if (res.success) {
          let meessage =
            '提示信息：平台向您尾号为' +
            user.phone.slice(user.phone.length - 4) +
            '的手机号码发送了验证短信。请在下方输入框中输入短信中的验证码';
          this.$prompt(meessage, '请输入短信验证码', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal: false,
          }).then(({ value }) => {
            let param = {
              payableBillFinanceId:
                this.payableBillFinance.payableBillFinanceId,
              // payableBillFinanceNo: this.payableBillFinance.payableBillFinanceNo,
              payableBillId: this.payableBillFinance.payableBillId,
              approveNode: this.payableBillFinance.approveStatus,
              approveResult: 1,
              financeYearInterest: val.preFinanceInterest, //利率
              preFinanceInterest: val.preFinanceInterestmoney, //利息
              preIncomeAmount: val.preIncomeAmount, //实际金额
            };
            yfdMessageDto.verifyCode = value;
            var params = Object.assign({ yfdMessageDto }, param);
            api.FinanceApprove(params).then((res) => {
              console.log('res ===>', res.datas);
              if (res.success) {
                this.$alert(res.message, '', {
                  confirmButtonText: '确定',
                  callback: (action) => {
                    history.go(-1);
                  },
                });
              } else {
                this.$message.error(res.message);
              }
            });
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //拒绝 复核
    reject() {
      this.dialogFormVisible = true;
      //查询拒绝原因
      api.getDicListByType({ type: 7 }).then((res) => {
        if (res.success) {
          this.rejectList = res.datas.list;
        }
      });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          var user = JSON.parse(sessionStorage.getItem('user'));
          var operatorTypeval = 22;
          switch (this.$route.query.approveStatus) {
            case 45:
              operatorTypeval == 22;
              break;
            case 51:
              operatorTypeval = 23;
              break;
            case 53:
              operatorTypeval = 24;
              break;
          }
          let param = {
            payableBillFinanceId: this.payableBillFinance.payableBillFinanceId,
            // payableBillFinanceNo: this.payableBillFinance.payableBillFinanceNo,
            payableBillId: this.payableBillFinance.payableBillId,
            approveNode: this.payableBillFinance.approveStatus,
            approveResult: 3,
            reson: this.form.reason,
            remark: this.form.remark,
          };
          api.FinanceApprove(param).then((res) => {
            if (res.success) {
              this.$alert(res.message, '', {
                confirmButtonText: '确定',
                callback: (action) => {
                  history.go(-1);
                },
              });
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    downloadContractFile() {
      //合同文件下载
    },
    downloadInvoiceFile() {
      //发票文件下载
    },
    downloadOtherFile() {
      //其他文件下载
    },

    linkCompanyManage() {
      var item = {
        name: '企业管理',
        path: '/Business/Bank_management?pageSize=10&pageNum=1',
      };
      sessionStorage.name = item.name;
      this.$router.push({ path: item.path });
    },

    download(row) {
      api
        .filesdownsingle({
          filePath: row.fileUrl,
        })
        .then((res) => {
          openDownloadDialog(res, row.fileName);
        })
        .catch((err) => {});
      //   window.open(api.filesdownsingle({filePath:row.fileUrl}),'_blank')
    },
    //发起融资
    submitApproval() {
      api.AddPayableBillFinance(this.param).then((res) => {
        console.log('res ===>', res.datas);
        if (res.success) {
          // this.$router.go(-1); //返回上一层
          this.$alert('融资申请提交成功', '', {
            confirmButtonText: '确定',
            callback: (action) => {
              history.go(-1);
            },
          });
        }
      });
    },

    handleupfn(response, file, fileList, index) {
      this.$message.success('上传成功');
      console.log('response ------->', response);
      console.log('file ------->', file);
      console.log('fileList ------->', fileList);
      console.log('index ------->', index);
      this.$set(
        this.financInfo.informationList[this.index],
        'fileName',
        file.name
      );
      this.$set(
        this.financInfo.informationList[this.index],
        'fileUrl',
        response.datas.uploadDate.message
      );
      // this.financInfo.informationList[this.index].fileName = file.name;
      console.log(
        'informationList===>',
        this.financInfo.informationList[this.index]
      );
    },
    upload(scope) {
      console.log(scope.$index, 'scope===>');
      this.index = scope.$index;

      // this.fileTypes.payableBillFinanceId = scope.row.payableBillFinanceId
    },
    //上传前配置
    beforeUp(file) {
      console.log('file =====>', file);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    preview() {
      window.open(api.getImgURL(this.payableBillFinance.evidencePath));
      //   api
      //     .DownloadContract({ contractNo: this.payableBillFinance.contractNo })
      //     .then(res => {
      //       var binaryData = [];
      //       binaryData.push(res);
      //       this.src = window.URL.createObjectURL(
      //         new Blob(binaryData, { type: "application/zip" })
      //       );
      //       this.centerDialogVisible = true;
      //     });
    },
  },
};
</script>

<style lang="scss">
@import '../../../styles/moreDetail/moudleDetail.scss';

.content {
  //padding: 0px 0px 0px 30px;
  width: 100%;
  height: 100%;
  background-color: white;
  .infotitle {
    color: #262626;
    font-size: 16px;
    font-weight: bold;
    text-indent: 32px;
    display: inline-block;
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
    height: 60px;
    line-height: 60px;
  }
  .info-card-A {
    padding: 0 32px;
  }
  .card-bd-row {
    line-height: 24px;
  }
  .page-head-back {
    float: right;
    margin-right: 40px;
    margin-top: 16px;
    border: 0px scope #fff;
  }
  .sub-title {
    border-bottom: none;
    height: 40px;
    font-size: 16px;
    color: #262626;
    padding: 0;
    .txbst {
      position: relative;
      left: -32px;
    }
  }
  .card-line {
    height: 20px;
    border-bottom: 1px #e6e6e6 solid;
  }
  //  重写开始
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .row {
    height: 40px;
  }
  .maincontin {
    margin-top: 30px;
    .marrimg {
      img {
        margin-right: 1%;
      }
    }
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;

    border-radius: 4px;

    .mainht {
      color: #262626;
      font-size: 16px;
      font-weight: bold;
      text-indent: 32px;
      display: inline-block;
      border-bottom: 1px solid #e6e6e6;
      width: 100%;
      height: 60px;
      line-height: 60px;
    }
    .htmsg {
      margin: 10px 0 10px 32px;
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 200px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
    .isdot {
      .el-badge__content.is-fixed {
        top: 10px;
        right: 0px;
      }
      .el-badge__content {
        background-color: #cfcfcf;
      }
    }
    .numcolor {
      font-size: 18px;
      color: #f5212d;
    }
  }
  .companyName {
    .el-form-item__label {
      width: 80px !important;
    }
    .el-form-item__content {
      margin-left: 80px !important;
    }
  }
}
.upload-list {
  .el-upload-list {
    display: none;
  }
}
.preFinanceYearInterestDialog {
  div {
    margin-bottom: 10px;
    vertical-align: middle;
  }
}

.account-content {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-left: 28px;
  .base-item {
    color: #262626;
    width: 33.3%;
    line-height: 40px;
  }
}
</style>
