<template>
  <div class="content easypay-manager">
    <div class="content-inner">
      <el-tabs v-model="activetab">
        <el-tab-pane label="银e券详情" name="first">
          <steps :stepList="stepList" :current="current"></steps>
          <section class="info-card-A">
            <div class="card-number">
              <span class="card-number-title">银e券编号:</span>
              <span class="card-number-value">{{
                financInfo.payableBillNo
              }}</span>
              <el-button
                class="card-number-btn"
                type="primary"
                size="small"
                icon="el-icon-zoom-in"
                @click="preview"
                >预览</el-button
              >
            </div>
            <div v-if="showAlert" class="account-warn">
              系统检测到贵司尚未开通结算渠道的账户，请联系系统管理员进行开通。未开通则无法进行接收，融资和后续的清分结算功能。
            </div>
            <div class="card-hd sub-title">
              <span class="txbst">基本信息</span>
              <!-- <el-button
                type="text"
                class="page-head-back"
                style="color: #2937A6;"
                size="small"
                @click="goBack"
              >预览</el-button>-->
            </div>
            <div class="card-bd card-bd-row">
              <el-row>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">银e券编号:</span>
                  <span class="info-value index">{{
                    financInfo.payableBillNo
                  }}</span>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">转让方:</span>
                  <span class="info-value">{{ financInfo.openCorpName }}</span>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">接收企业:</span>
                  <span class="info-value">{{
                    financInfo.receivedCorpName
                  }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">开立日:</span>
                  <span class="info-value">{{
                    moment(financInfo.createTime).format('YYYY-MM-DD')
                  }}</span>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">承诺付款天数:</span>
                  <span class="info-value">{{
                    financInfo.promiseDay || '--'
                  }}</span>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">承诺付款日:</span>
                  <span class="info-value">{{
                    moment(financInfo.promisePayTime).format('YYYY-MM-DD')
                  }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">原始银e券编号:</span>

                  <span class="info-value">{{ financInfo.originalNo }}</span>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">开单企业:</span>
                  <span class="info-value">{{ financInfo.originalName }}</span>
                </el-col>

                <el-col :span="8" class="lb_body">
                  <span class="info-key">资金机构:</span>
                  <span class="info-value">{{ financInfo.fundCorpName }}</span>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="8" class="lb_body">
                  <span class="info-key"
                    >{{
                      $route.query.checkbtn == 1 ? '当前' : ''
                    }}银e券金额（元）:</span
                  >
                  <span class="info-value">{{
                    changeMoneyThousand(financInfo.payableAmount)
                  }}</span>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key"
                    >{{
                      $route.query.checkbtn == 1 ? '当前' : ''
                    }}银e券金额（大写）:</span
                  >
                  <span class="info-value">{{
                    convertCurrency(financInfo.payableAmount)
                  }}</span>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">预估融资利率(%/年化):</span>
                  <span class="info-value"
                    >{{ financInfo.financeYearInterest }}％</span
                  >
                </el-col>
                <!-- <el-col :span="8" class="lb_body">
                  <span class="info-key">担保方:</span>
                  <span class="info-value">{{financnum.guaranteeCorpName}}</span>
                </el-col>-->
              </el-row>
              <el-row>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">付款承诺函:</span>
                  <span class="info-value pdfpreviewc" @click="pdfpreview">{{
                    financInfo.promisePayNo
                  }}</span>
                </el-col>
              </el-row>

              <div class="card-line"></div>
            </div>
            <div class="card-hd sub-title">
              <span class="txbst">合同信息</span>
            </div>
            <div class="httotal" style="margin-top: 20px">
              已添加合同
              <span>{{
                financInfo.payableBillContract &&
                financInfo.payableBillContract.length
              }}</span>
              笔，合同总计金额
              <span>{{ changeMoneyThousand(financInfo.contractAmount) }}</span>
              元。
            </div>
            <div
              class="maincontin"
              v-for="(item, index) in financInfo.payableBillContract"
              :key="index"
            >
              <!-- <div class="maincontin">  -->

              <span class="mainht">
                <span class="info-key">合同编号:</span>
                <span class="info-value">{{ item.contractNo }}</span>
                <a
                  class="page-head-back downsize"
                  style="color: #2937a6"
                  size="small"
                  @click="downloadfn(item)"
                  >一键下载</a
                >
              </span>

              <div class="contract info">
                <el-col>
                  <span class="info-key">合同编号:</span>
                  <span class="info-value">{{ item.contractNo }}</span>
                </el-col>
                <el-col>
                  <span class="info-key">合同名称:</span>
                  <span class="info-value">{{ item.contractName }}</span>
                </el-col>

                <el-col>
                  <span class="info-key">合同金额:</span>
                  <span class="info-value"
                    >{{ changeMoneyThousand(item.contractAmount) }}元</span
                  >
                </el-col>
                <el-col>
                  <span class="info-key">合同金额（大写）:</span>
                  <span class="info-value"
                    >{{ convertCurrency(item.contractAmount) }}元</span
                  >
                </el-col>
                <el-col>
                  <span class="info-key">合同签署时间:</span>
                  <span class="info-value">{{
                    item.contractTime
                      ? moment(item.contractTime).format('YYYY-MM-DD')
                      : '--'
                  }}</span>
                </el-col>
              </div>
              <div class="card-hd sub-title" style="margin-bottom: 24px">
                相关合同
              </div>
              <div
                class="card-bd marrimg"
                style="padding-left: 30px; margin-top: -20px"
              >
                <transition
                  name="fade"
                  v-if="
                    item.payableBillContractList &&
                    item.payableBillContractList.length &&
                    item.payableBillContractList.length > 0
                  "
                >
                  <el-row>
                    <picfile
                      :picfilelist="item.payableBillContractList"
                    ></picfile>

                    <!-- <div style="overflow:hidden">
                      <div
                        v-for="(items,key) in  item.payableBillContractList"
                        :key="key"
                        class="htleft"
                      >
                        <img
                          :src="api.getImgURL(items.fileUrl)"
                          alt
                          width="100%"
                          height="144px"
                          preview-text="合同"
                          :preview="'ht'+index+'-'+key"
                          v-show="/jpg|gif|bmp|png|jpeg/.test(items.fileUrl.split('.'[1]))"
                        />
                      </div>
                    </div>
                    <div
                      v-for="(items,key) in  item.payableBillContractList"
                      :key="key"
                      class="htleft files"
                      v-show="!/jpg|gif|bmp|png|jpeg/.test(items.fileUrl.split('.'[1]))"
                    >
                      <div>
                        <a
                              @click='downloadsingle(items)'

                         download>{{items.fileName}}</a>
                        <p>
                          <i class="fa fa-file-text-o"></i>
                        </p>
                      </div>
                    </div>-->
                  </el-row>
                </transition>
                <div>
                  <div>
                    共有文件:
                    <span class="numcolor">{{
                      item.payableBillContractList.length
                    }}</span>
                    个
                  </div>
                </div>
              </div>
              <div class="card-hd sub-title">相关发票</div>
              <div class="card-bd marrimg" style="padding: 24px 30px 51px 30px">
                <el-row>
                  <picfile :picfilelist="item.payableBillInvoiceList"></picfile>

                  <!-- <img
                    :src="api.getImgURL(items.fileUrl)"
                    alt
                    style="margin-right:1%;"
                    width="19%"
                    height="144px"
                    preview-text="发票"
                    :preview="index+'2'"
                    v-for="(items,key) in  item.payableBillInvoiceList"
                    :key="key"
                  />-->
                </el-row>
                <div style="padding: 0px 0px 30px 0px">
                  共有文件:
                  <span class="numcolor">{{
                    item.payableBillInvoiceList.length
                  }}</span>
                  个
                  <span style="margin-left: 32px">累计发票金额：</span>
                  <span class="numcolor">{{
                    changeMoneyThousand(item.invoiceAllAmount)
                  }}</span>
                  元
                </div>
                <div>
                  <el-table
                    :data="item.payableBillInvoiceList"
                    highlight-current-row
                    v-loading="listLoading"
                    style="width: 100%"
                  >
                    <el-table-column
                      type="index"
                      label="序号"
                      width="80"
                    ></el-table-column>
                    <el-table-column
                      prop="invoiceBuyer"
                      label="购买方"
                      min-width="200"
                    >
                      <template slot-scope="scope">
                        <el-badge
                          class="isdot"
                          :value="scope.row.invoiceBuyerChange == 2 ? '改' : ''"
                          >{{ scope.row.invoiceBuyer }}</el-badge
                        >
                      </template>
                    </el-table-column>

                    <el-table-column
                      prop="invoiceSeller"
                      label="销售方"
                      min-width="200"
                    >
                      <template slot-scope="scope">
                        <el-badge
                          class="isdot"
                          :value="
                            scope.row.invoiceSellerChange == 2 ? '改' : ''
                          "
                          >{{ scope.row.invoiceSeller }}</el-badge
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="invoiceCode"
                      label="发票代码"
                      min-width="120"
                    >
                      <template slot-scope="scope">
                        <el-badge
                          class="isdot"
                          :value="scope.row.invoiceCodeChange == 2 ? '改' : ''"
                          >{{ scope.row.invoiceCode }}</el-badge
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="invoiceNo"
                      label="发票号码"
                      min-width="120"
                    >
                      <template slot-scope="scope">
                        <el-badge
                          class="isdot"
                          :value="scope.row.invoiceNoChange == 2 ? '改' : ''"
                          >{{ scope.row.invoiceNo }}</el-badge
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="invoiceCheckCode"
                      label="校验码"
                      min-width="120"
                    >
                      <template slot-scope="scope">
                        <el-badge
                          class="isdot"
                          :value="
                            scope.row.invoiceCheckCodeChange == 2 ? '改' : ''
                          "
                          >{{ scope.row.invoiceCheckCode }}</el-badge
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="invoiceOpenTime"
                      label="开票日期"
                      min-width="120"
                    >
                      <template slot-scope="scope">
                        <el-badge
                          class="isdot"
                          :value="
                            scope.row.invoiceOpenTimeChange == 2 ? '改' : ''
                          "
                          >{{ scope.row.invoiceOpenTime }}</el-badge
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="invoiceAmount"
                      label="开具金额(元)"
                      width="200"
                      align="left"
                    >
                      <template slot-scope="scope">
                        <el-badge
                          class="isdot"
                          :value="
                            scope.row.invoiceAmountChange == 2 ? '改' : ''
                          "
                          >{{
                            $changeMoneyThousand(scope.row.invoiceAmount)
                          }}</el-badge
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="invoiceAmountWithoutTax"
                      label="开具金额(不含税)"
                      width="200"
                      align="left"
                    >
                      <template slot-scope="scope">
                        <el-badge
                          class="isdot"
                          :value="
                            scope.row.invoiceAmountWithoutTaxChange == 2
                              ? '改'
                              : ''
                          "
                          >{{
                            $changeMoneyThousand(
                              scope.row.invoiceAmountWithoutTax
                            )
                          }}</el-badge
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <div class="card-hd sub-title">其他附件</div>
              <div
                class="card-bd marrimg"
                style="padding-bottom: 51px; padding-left: 30px"
              >
                <!--<span class="info-key">物流类型:</span>-->
                <el-row>
                  <transition
                    name="fade"
                    v-if="
                      item.payableBillWuliuList &&
                      item.payableBillWuliuList.length &&
                      item.payableBillWuliuList.length > 0
                    "
                  >
                    <el-row>
                      <picfile
                        :picfilelist="item.payableBillWuliuList"
                      ></picfile>

                      <!-- <div style="overflow:hidden">
                        <div
                          v-for="(items,key) in  item.payableBillWuliuList"
                          :key="key"
                          class="htleft"
                        >
                          <img
                            :src="api.getImgURL(items.fileUrl)"
                            alt
                            width="100%"
                            height="144px"
                            preview-text="物流文件"
                            :preview="'wl'+index+'-'+key"
                            v-show="/jpg|gif|bmp|png|jpeg/.test(items.fileUrl.split('.'[1]))"
                          />
                        </div>
                      </div>
                      <div
                        v-for="(items,key) in  item.payableBillWuliuList"
                        :key="key"
                        class="htleft files"
                        v-show="!/jpg|gif|bmp|png|jpeg/.test(items.fileUrl.split('.'[1]))"
                      >
                        <div>
                          <a
                              @click='downloadsingle(items)'


                          >{{items.fileName}}</a>
                          <p>
                            <i class="fa fa-file-text-o"></i>
                          </p>
                        </div>
                      </div>-->
                    </el-row>
                  </transition>
                </el-row>
                <!--<span class="info-key">银行类型:</span>-->
                <el-row>
                  <transition
                    name="fade"
                    v-if="
                      item.payableBillBankList &&
                      item.payableBillBankList.length &&
                      item.payableBillBankList.length > 0
                    "
                  >
                    <el-row>
                      <picfile
                        :picfilelist="item.payableBillBankList"
                      ></picfile>
                    </el-row>
                  </transition>
                </el-row>
                <div>
                  共有文件:
                  <span class="numcolor">{{
                    item.payableBillWuliuList.length +
                    item.payableBillBankList.length
                  }}</span>
                  个
                </div>
              </div>
            </div>
            <!-- <div class="subtitle" v-if="$route.query.flag">
              <span class="txbst1">清分收款账户</span>
            </div>
            <div
              v-if="$route.query.checkbtn == 2 || $route.query.flag"
              class="settlement-site"
            >
              <div style="margin-left: 28px">
                收款方式：
                <span style="margin-left: 20px"> 线下支付 </span>
              </div>
            </div>
            <div
              v-if="$route.query.checkbtn == 2 || $route.query.flag"
              class="account-content"
            >
              <div class="base-item">
                收款账户：
                <span style="margin-left: 20px">{{
                  financInfo.receivedCorpAccountName
                }}</span>
              </div>
              <div class="base-item">
                开户行：
                <span style="margin-left: 20px">{{
                  financInfo.receivedCorpOpenBank
                }}</span>
              </div>
              <div class="base-item">
                收款账号：
                <span style="margin-left: 20px">{{
                  financInfo.receivedCorpAccount
                }}</span>
              </div>
            </div>
            <div class="subtitle" v-if="!$route.query.flag">
              <span class="txbst1">清分收款账户</span>
            </div> -->
            <!-- <el-form
              v-if="!$route.query.flag"
              :model="ruleForm3"
              :rules="rules3"
              label-width="140px"
              class="demo-ruleForm"
              label-position="left"
            >
              <el-row class>
                <el-col :span="8" class="lb_body">
                  <el-form-item label="收款方式：" prop="settlementChannel"
                    >线下支付</el-form-item
                  >
                </el-col>
                <el-col :span="8" class="lb_body">
                  <el-form-item
                    label="收款账户选择："
                    prop="corpAccountPublicId"
                  >
                    <el-select
                      @change="handelAccountChange"
                      v-model="ruleForm3.corpAccountPublicId"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in accountList"
                        :key="item.id"
                        :label="`${item.openBank}  ${item.accountNo}`"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="row" v-if="accountShow">
                <el-col :span="8" class="lb_body">
                  <el-form-item label="收款账户：">{{
                    ruleForm3.accountName
                  }}</el-form-item>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <el-form-item label="开户行：">{{
                    ruleForm3.openBank
                  }}</el-form-item>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <el-form-item label="收款账号：">{{
                    ruleForm3.accountNo
                  }}</el-form-item>
                </el-col>
              </el-row>
            </el-form> -->
          </section>
        </el-tab-pane>
        <el-tab-pane label="流转详情" name="second">
          <div class="text-center">
            <vue2-org-tree
              name="test"
              :data="lzdata"
              :horizontal="horizontal"
              :collapsable="collapsable"
              :render-content="renderContent"
              labelWidth="320px"
              labelClassName="label-box"
              @on-node-click="orgclickfn"
            />
          </div>
        </el-tab-pane>
      </el-tabs>

      <div
        class="card-footer"
        v-if="$route.query.isshowbtn == 1 && $route.query.checkbtn == 1"
      >
        <el-checkbox v-model="checked" @change="showDialog(checked)"
          >我已阅读并同意</el-checkbox
        >
        <a
          href="javascript:;"
          style="display: inline-block; margin: 0 5px"
          @click="showDialog(checked, '1')"
          >《银e券转让协议》</a
        >
        <a
          href="javascript:;"
          style="display: inline-block; margin: 0 5px"
          @click="showDialog2(checked, '1')"
          >《应收账款电子凭证转让通知书》</a
        >

        <el-button class="card-btn" @click="openrefusediag">退回</el-button>
        <el-button
          class="card-btn"
          type="primary"
          @click="openagreediag"
          :disabled="forbidden"
          >同意</el-button
        >
      </div>
      <div
        class="card-footer"
        v-if="$route.query.isshowbtn == 2 || $route.query.checkbtn == 2"
      >
        <el-button class="card-btn" type="primary" @click="goBack"
          >返回</el-button
        >
      </div>
    </div>

    <!-- 弹窗 -->
    <el-dialog
      title="审核意见"
      :visible.sync="showAddDialog"
      :close-on-click-modal="false"
      :show-close="false"
      width="40%"
    >
      <el-form :model="agreedetail" label-width="60px" ref="addform">
        <el-form-item label="备注：" prop="content">
          <el-input
            v-model="agreedetail.content"
            type="textarea"
            :rows="6"
            maxlength="200"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitAddForm" :disabled="bclick"
          >提交</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="拒绝原因"
      :visible.sync="showAddDialog2"
      :close-on-click-modal="false"
      :show-close="false"
      width="40%"
    >
      <el-form :model="refusedetail" label-width="100px" ref="addform">
        <el-form-item label="拒绝原因：" prop="denialReason">
          <el-select v-model="refusedetail.denialReason" placeholder="请选择">
            <el-option
              v-for="item in refuselist"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="content">
          <el-input
            v-model="refusedetail.content"
            type="textarea"
            :rows="6"
            maxlength="200"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog2 = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitAddForm2('addform')"
          :disabled="bclick"
          >提交</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title :visible.sync="centerDialogVisible" width="80%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <pdf class="pdf" ref="pdf" :src="src"></pdf>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 融资列表 -->
    <el-dialog
      title="融资列表"
      :visible.sync="showAddDialog3"
      :close-on-click-modal="false"
      :show-close="false"
      width="1000px"
    >
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column
          prop="payableBillFinanceNo"
          label="融资项目编号"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="financePayableBillNo"
          label="融资银e券编号"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="fundCorpName"
          label="资金机构"
          width="180"
        ></el-table-column>
        <el-table-column label="融资金额(元)">
          <template slot-scope="scope">{{
            scope.row.amount | formatMoney
          }}</template>
        </el-table-column>
        <el-table-column label="融资完成时间">
          <template slot-scope="scope">{{
            scope.row.financeTime | formatDate
          }}</template>
        </el-table-column>
      </el-table>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showAddDialog3 = false"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      class="agreement-dialog2"
      :visible.sync="agreementDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="1000px"
      center
    >
      <div class="agreement-container">
        <transger-agreement
          :signAgreementDetail="signAgreementDetail"
        ></transger-agreement>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          v-show="!showType && !readDisabled"
          @click="hasRead()"
          >同意</el-button
        >
        <el-button
          type="primary"
          v-show="!showType && readDisabled"
          :disabled="true"
          >阅读并同意 {{ readCount }}秒</el-button
        >
        <el-button type="primary" @click="closeDialog">关闭</el-button>
      </span>
    </el-dialog>

    <el-dialog
      class="agreement-dialog2"
      :visible.sync="agreementDialog2"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="1000px"
      center
    >
      <div class="agreement-container">
        <pdf class="pdf" :src="api.getImgURL(transferbook)"></pdf>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          v-show="!showType && !readDisabled"
          @click="hasRead2()"
          >同意</el-button
        >
        <el-button
          type="primary"
          v-show="!showType && readDisabled"
          :disabled="true"
          >阅读并同意 {{ readCount }}秒</el-button
        >
        <el-button type="primary" @click="agreementDialog2 = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="结算渠道账户检测"
      :visible.sync="accountResultDialog"
      v-if="accountResultDialog"
      width="40%"
    >
      <accountResult1 v-if="accountResult == 1"></accountResult1>
      <accountResult2
        v-if="accountResult == 2"
        :cltNm="cltNm"
        :subNo="subNo"
      ></accountResult2>
      <accountResult3
        v-if="accountResult == 3"
        :remark="remark"
      ></accountResult3>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button
          type="primary"
          v-if="accountResult != 2"
          @click="accountResultDialog = false"
          >我知道了</el-button
        >
        <el-button
          type="primary"
          v-if="accountResult == 3"
          @click="goOpenAccount"
          >重新开户</el-button
        >
        <el-button
          type="primary"
          v-if="accountResult == 2 && isTiedCard != 2"
          @click="goAccountManagement"
          >激活账户</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/api/api';
import { baseURL } from '@/api/sys';
import { changeMoneyThousand, convertCurrency } from '@/util/common';
import pdf from 'vue-pdf';
import steps from './steps.vue';
import picfile from '@/components/picfile';
import transgerAgreement from '@/components/agreementList/transger-agreement';
import accountResult1 from '@/components/accountResult1.vue';
import accountResult2 from '@/components/accountResult2.vue';
import accountResult3 from '@/components/accountResult3.vue';
import ukey from '@/util/ukey.js';

Number.prototype['adds'] = function (...arg) {
  var r1,
    r2,
    m,
    result = this;
  arg.forEach((value) => {
    try {
      r1 = result.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = value.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    result = Math.round(result * m + value * m) / m;
  });
  return result;
};
function openDownloadDialog(url, saveName) {
  // for ie 10 and later

  if (window.navigator.msSaveBlob) {
    try {
      window.navigator.msSaveBlob(url, saveName);
    } catch (e) {
      console.log(e);
    }
  }
  // 谷歌浏览器 创建a标签 添加download属性下载
  else {
    if (typeof url == 'object' && url instanceof Blob) {
      url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) {
      event = new MouseEvent('click');
    } else {
      event = document.createEvent('MouseEvents');
      event.initMouseEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
    }
    aLink.dispatchEvent(event);
  }
}
export default {
  components: {
    pdf,
    steps,
    picfile,
    'transger-agreement': transgerAgreement,
    accountResult1,
    accountResult2,
    accountResult3,
  },
  data() {
    return {
      signAgreementDetail: {},
      agreementDialog2: false,
      transferbook: '',

      ruleForm3: {
        settlementChannel: '1',
        repaymentType: '',
        accountName: '',
        openBank: '',
        corpAccountPublicId: '',
        fundReceiveAccountId: '',
        fundReceiveAccount: '',
      },
      // rules3: {
      //   corpAccountPublicId: [
      //     { required: true, message: "请选择收款账户", trigger: "change" },
      //   ],
      // },
      checked: false,
      agreementDialog: false,
      readCount: '',
      readDisabled: true,
      activetab: 'first',
      pid: '', //pid
      state: '', //是否入库
      financInfo: {}, //基本信息
      isShowAll: false,
      isHedden: false,
      filterList: [],
      activeName: '1',
      total: 0,
      listLoading: false,
      listData: [],
      baseURL: baseURL,
      api: api,
      agreedetail: {
        approveType: 1,
        content: '',
        payableBillId: '',
        result: 1,
        status: '',
      },
      refusedetail: {
        approveType: 1,
        content: '',
        denialReason: '',
        payableBillId: '',
        result: 2,
        status: '',
      },
      showAddDialog: false,
      showAddDialog2: false,
      bclick: false,
      refuselist: [],
      lzdata: {
        payableBillFinanceList: [],
      },
      horizontal: false,
      collapsable: false,
      changeMoneyThousand: changeMoneyThousand,
      expandAll: false,
      centerDialogVisible: false,
      src: '',
      financnum: {},
      stepList: [
        {
          name: '邀请开单',
          realName: '',
          createTime: '',
        },
        {
          name: '核心企业确认',
          realName: '',
          createTime: '',
        },
        {
          name: '开立审核',
          realName: '',
          createTime: '',
        },
        // {
        //   name: "担保审核",
        //   realName: "",
        //   createTime: ""
        // },
        {
          name: '接收确认',
          realName: '',
          createTime: '',
        },
        {
          name: '平台审核',
          realName: '',
          createTime: '',
        },
        {
          name: '资方审核',
          realName: '',
          createTime: '',
        },
        {
          name: '正常持有',
          realName: '',
          createTime: '',
        },
      ],
      current: 0,
      financeFile: {},
      showAddDialog3: false,
      tableData: [],
      showType: '',
      forbidden: false,
      accountResult: '', // 开户结果
      accountResultDialog: false,
      cltNm: '',
      subNo: '',
      remark: '',
      openAccountId: '',
      isTiedCard: '', // 2已激活 无需提醒
      showAlert: false,
      isEdit: false,
      accountList: [],
      corpId: '',
      accountShow: false,
    };
  },
  created() {
    console.log(this.$route, 123);
    this.getInfo({ payableBillId: this.$route.query.id }); //获取基本信息
    this.getrefuselist(); //获取拒绝原因
    this.circulation();
    this.getSignAgreementByCorp(this.$route.query.id);
    if (this.$route.query.isshowbtn == 1 && this.$route.query.checkbtn == 1) {
      // this.showDialog();
    }
  },
  mounted() {},
  methods: {
    convertCurrency,
    async getSignAgreementByCorp(val) {
      let res = await api.getSignAgreement({ payableBillId: val });
      if (res.success) {
        this.signAgreementDetail = res.datas.data;
        console.log(this.signAgreementDetail);
      } else {
        this.$message.error(res.message);
      }
    },
    handelAccountChange(e) {
      this.accountShow = true;

      this.accountList.forEach((_) => {
        if (e == _.code) {
          console.log(_, '__');
          this.ruleForm3.accountName = _.accountName;
          this.ruleForm3.accountNo = _.accountNo;
          this.ruleForm3.openBank = _.openBank;
          this.ruleForm3.fundReceiveAccountId = _.fundReceiveAccountId;
          this.ruleForm3.fundReceiveAccount = _.fundReceiveAccount;
          // receivedCorpAccountId receivedCorpAccount
        }
      });
    },
    moment: moment,
    //返回
    goBack() {
      //   this.$router.push({name:'开立审批'})
      window.history.go(-1);
    },
    getreceivedCorpAck(val) {
      //获取企业确认时限
      var timer = '';
      switch (val) {
        case '0':
          timer = '自审批完毕24小时';
          break;
        case '1':
          timer = '自审批完毕48小时';
          break;
        case '2':
          timer = '自审批完毕72小时';
          break;
        case '3':
          timer = '无时限';
          break;
      }
      return timer;
    },
    pdfpreview() {
      // window.open(api.filesdownsingle(this.financeFile.fileUrl))
      this.downloadsingle({ fileUrl: this.financeFile.fileUrl });
    },
    getCorpAccountPublicListByCorpId() {
      let params = {
        corpId: this.corpId,
      };
      api.getCorpAccountPublicListByCorpId(params).then((res) => {
        if (res.success) {
          let list = res.datas.corpAccountPublicList;
          // this.ruleForm3 = res.datas.corpAccountPublicList
          list.forEach((_) => {
            this.accountList.push({
              name: _.accountName,
              code: _.corpAccountPublicId,
              id: _.corpAccountPublicId,
              accountName: _.accountName,
              accountNo: _.accountNo,
              openBank: _.openBank,
              fundReceiveAccountId: _.corpAccountPublicId,
              fundReceiveAccount: _.accountNo,
            });
          });
          if (this.$route.query.flag) {
            // this.financInfo.receivedCorpAccountName = res.datas.corpAccountPublicList[0].accountName;
            // this.financInfo.receivedCorpOpenBank = res.datas.corpAccountPublicList[0].openBank;
            // this.financInfo.receivedCorpAccount = res.datas.corpAccountPublicList[0].accountNo;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //获取详情
    getInfo(param) {
      api.createfirstdetail(param).then((res) => {
        if ((res.code = '100000')) {
          this.financInfo = res.datas.PayableBill;
          this.corpId = res.datas.PayableBill.receivedCorpId || '';
          console.log(this.corpId, 'corpId');
          this.getCorpAccountPublicListByCorpId();
          this.financnum = res.datas.grantCredit;
          this.financeFile = res.datas.financeFile;
          this.doOpenAccount(this.financInfo.grantCreditNo);

          //判断转让
          if (this.financInfo.payableBillNo.indexOf('-') > 0) {
            this.stepList = [
              {
                name: '转让申请',
                realName: '',
                createTime: '',
              },
              {
                name: '转让审核',
                realName: '',
                createTime: '',
              },
              {
                name: '接收确认',
                realName: '',
                createTime: '',
              },
              {
                name: '正常持有',
                realName: '',
                createTime: '',
              },
            ];
          }
          //payableBillOpenType 1 正向 2 反向 3 转让
          if (this.financInfo.payableBillOpenType == 1) {
            //判断担保 null无担保审核 否则有担保审核
            if (this.financInfo.guaranteeCorpId != null) {
              //判断是否资方审核 1 有资方 2  无资方
              if (this.financnum.fundCheck == 1) {
                this.stepList = [
                  {
                    name: '开立申请',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '开立审核',
                    realName: '',
                    createTime: '',
                  },
                  // {
                  //   name: "担保审核",
                  //   realName: "",
                  //   createTime: ""
                  // },
                  {
                    name: '接收确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '平台审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '资方审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '正常持有',
                    realName: '',
                    createTime: '',
                  },
                ];
                if (
                  this.financInfo.payableBillApproveStatus == 20 ||
                  this.financInfo.payableBillApproveStatus == 26
                ) {
                  // 内审待审核/内审审核不通过
                  this.current = 1;
                } else if (
                  this.financInfo.payableBillApproveStatus == 25 ||
                  this.financInfo.payableBillApproveStatus == 32
                ) {
                  // 担保待审核/担保审核不通过
                  this.current = 2;
                } else if (
                  this.financInfo.payableBillApproveStatus == 31 ||
                  this.financInfo.payableBillApproveStatus == 36
                ) {
                  // 接收待审核/接收审核不通过
                  this.current = 3;
                } else if (
                  (this.financInfo.payableBillApproveStatus >= 35 &&
                    this.financInfo.payableBillApproveStatus <= 44) ||
                  this.financInfo.payableBillApproveStatus == 46
                ) {
                  // 平台待审核（初审/复审/终审）/平台审核不通过
                  this.current = 4;
                } else if (
                  this.financInfo.payableBillApproveStatus == 45 ||
                  (this.financInfo.payableBillApproveStatus >= 51 &&
                    this.financInfo.payableBillApproveStatus <= 56)
                ) {
                  // 资方待审核（初审/复审/终审）/资方审核不通过
                  this.current = 5;
                } else if (this.financInfo.payableBillApproveStatus == 99) {
                  // 正常持有
                  this.current = 6;
                }
              } else {
                this.stepList = [
                  {
                    name: '开立申请',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '开立审核',
                    realName: '',
                    createTime: '',
                  },
                  // {
                  //   name: "担保审核",
                  //   realName: "",
                  //   createTime: ""
                  // },
                  {
                    name: '接收确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '平台审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '正常持有',
                    realName: '',
                    createTime: '',
                  },
                ];
                if (
                  this.financInfo.payableBillApproveStatus == 20 ||
                  this.financInfo.payableBillApproveStatus == 26
                ) {
                  // 内审待审核/内审审核不通过
                  this.current = 1;
                } else if (
                  this.financInfo.payableBillApproveStatus == 25 ||
                  this.financInfo.payableBillApproveStatus == 32
                ) {
                  // 担保待审核/担保审核不通过
                  this.current = 2;
                } else if (
                  this.financInfo.payableBillApproveStatus == 31 ||
                  this.financInfo.payableBillApproveStatus == 36
                ) {
                  // 接收待审核/接收审核不通过
                  this.current = 3;
                } else if (
                  (this.financInfo.payableBillApproveStatus >= 35 &&
                    this.financInfo.payableBillApproveStatus <= 44) ||
                  this.financInfo.payableBillApproveStatus == 46
                ) {
                  // 平台待审核（初审/复审/终审）/平台审核不通过
                  this.current = 4;
                } else if (this.financInfo.payableBillApproveStatus == 99) {
                  // 正常持有
                  this.current = 5;
                }
              }
            } else {
              if (this.financnum.fundCheck == 1) {
                this.stepList = [
                  {
                    name: '开立申请',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '开立审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '接收确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '平台审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '资方审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '正常持有',
                    realName: '',
                    createTime: '',
                  },
                ];
                if (
                  this.financInfo.payableBillApproveStatus == 20 ||
                  this.financInfo.payableBillApproveStatus == 26
                ) {
                  // 内审待审核/内审审核不通过
                  this.current = 1;
                } else if (
                  this.financInfo.payableBillApproveStatus == 31 ||
                  this.financInfo.payableBillApproveStatus == 36
                ) {
                  // 接收待审核/接收审核不通过
                  this.current = 2;
                } else if (
                  (this.financInfo.payableBillApproveStatus >= 35 &&
                    this.financInfo.payableBillApproveStatus <= 44) ||
                  this.financInfo.payableBillApproveStatus == 46
                ) {
                  // 平台待审核（初审/复审/终审）/平台审核不通过
                  this.current = 3;
                } else if (
                  this.financInfo.payableBillApproveStatus == 45 ||
                  (this.financInfo.payableBillApproveStatus >= 51 &&
                    this.financInfo.payableBillApproveStatus <= 56)
                ) {
                  // 资方待审核（初审/复审/终审）/资方审核不通过
                  this.current = 4;
                } else if (this.financInfo.payableBillApproveStatus == 99) {
                  // 正常持有
                  this.current = 6;
                }
              } else {
                this.stepList = [
                  {
                    name: '开立申请',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '开立审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '接收确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '平台审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '正常持有',
                    realName: '',
                    createTime: '',
                  },
                ];
                if (
                  this.financInfo.payableBillApproveStatus == 20 ||
                  this.financInfo.payableBillApproveStatus == 26
                ) {
                  // 内审待审核/内审审核不通过
                  this.current = 1;
                } else if (
                  this.financInfo.payableBillApproveStatus == 31 ||
                  this.financInfo.payableBillApproveStatus == 36
                ) {
                  // 接收待审核/接收审核不通过
                  this.current = 2;
                } else if (
                  (this.financInfo.payableBillApproveStatus >= 35 &&
                    this.financInfo.payableBillApproveStatus <= 44) ||
                  this.financInfo.payableBillApproveStatus == 46
                ) {
                  // 平台待审核（初审/复审/终审）/平台审核不通过
                  this.current = 3;
                } else if (this.financInfo.payableBillApproveStatus == 99) {
                  // 正常持有
                  this.current = 5;
                }
              }
            }
          } else if (this.financInfo.payableBillOpenType == 2) {
            if (this.financInfo.guaranteeCorpId != null) {
              if (this.financnum.fundCheck == 1) {
                this.stepList = [
                  {
                    name: '邀请开单',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '核心企业确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '开立审核',
                    realName: '',
                    createTime: '',
                  },
                  // {
                  //   name: "担保审核",
                  //   realName: "",
                  //   createTime: ""
                  // },
                  {
                    name: '接收确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '平台审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '资方审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '正常持有',
                    realName: '',
                    createTime: '',
                  },
                ];
                if (
                  this.financInfo.payableBillApproveStatus == 10 ||
                  this.financInfo.payableBillApproveStatus == 12
                ) {
                  // 邀请开单审核/邀请开单审核不通过
                  this.current = 1;
                } else if (
                  this.financInfo.payableBillApproveStatus == 20 ||
                  this.financInfo.payableBillApproveStatus == 26
                ) {
                  // 内审待审核/内审审核不通过
                  this.current = 2;
                } else if (
                  this.financInfo.payableBillApproveStatus == 25 ||
                  this.financInfo.payableBillApproveStatus == 32
                ) {
                  // 担保待审核/担保审核不通过
                  this.current = 3;
                } else if (
                  this.financInfo.payableBillApproveStatus == 31 ||
                  this.financInfo.payableBillApproveStatus == 36
                ) {
                  // 接收待审核/接收审核不通过
                  this.current = 4;
                } else if (
                  (this.financInfo.payableBillApproveStatus >= 35 &&
                    this.financInfo.payableBillApproveStatus <= 44) ||
                  this.financInfo.payableBillApproveStatus == 46
                ) {
                  // 平台待审核（初审/复审/终审）/平台审核不通过
                  this.current = 5;
                } else if (
                  this.financInfo.payableBillApproveStatus == 45 ||
                  (this.financInfo.payableBillApproveStatus >= 51 &&
                    this.financInfo.payableBillApproveStatus <= 56)
                ) {
                  // 资方待审核（初审/复审/终审）/资方审核不通过
                  this.current = 6;
                } else if (this.financInfo.payableBillApproveStatus == 99) {
                  // 正常持有
                  this.current = 7;
                }
              } else {
                this.stepList = [
                  {
                    name: '邀请开单',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '核心企业确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '开立审核',
                    realName: '',
                    createTime: '',
                  },
                  // {
                  //   name: "担保审核",
                  //   realName: "",
                  //   createTime: ""
                  // },
                  {
                    name: '接收确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '平台审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '正常持有',
                    realName: '',
                    createTime: '',
                  },
                ];
                if (
                  this.financInfo.payableBillApproveStatus == 10 ||
                  this.financInfo.payableBillApproveStatus == 12
                ) {
                  // 邀请开单审核/邀请开单审核不通过
                  this.current = 1;
                } else if (
                  this.financInfo.payableBillApproveStatus == 20 ||
                  this.financInfo.payableBillApproveStatus == 26
                ) {
                  // 内审待审核/内审审核不通过
                  this.current = 2;
                } else if (
                  this.financInfo.payableBillApproveStatus == 25 ||
                  this.financInfo.payableBillApproveStatus == 32
                ) {
                  // 担保待审核/担保审核不通过
                  this.current = 3;
                } else if (
                  this.financInfo.payableBillApproveStatus == 31 ||
                  this.financInfo.payableBillApproveStatus == 36
                ) {
                  // 接收待审核/接收审核不通过
                  this.current = 4;
                } else if (
                  (this.financInfo.payableBillApproveStatus >= 35 &&
                    this.financInfo.payableBillApproveStatus <= 44) ||
                  this.financInfo.payableBillApproveStatus == 46
                ) {
                  // 平台待审核（初审/复审/终审）/平台审核不通过
                  this.current = 5;
                } else if (this.financInfo.payableBillApproveStatus == 99) {
                  // 正常持有
                  this.current = 6;
                }
              }
            } else {
              if (this.financnum.fundCheck == 1) {
                this.stepList = [
                  {
                    name: '邀请开单',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '核心企业确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '开立审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '接收确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '平台审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '资方审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '正常持有',
                    realName: '',
                    createTime: '',
                  },
                ];
                if (
                  this.financInfo.payableBillApproveStatus == 10 ||
                  this.financInfo.payableBillApproveStatus == 12
                ) {
                  // 邀请开单审核/邀请开单审核不通过
                  this.current = 1;
                } else if (
                  this.financInfo.payableBillApproveStatus == 20 ||
                  this.financInfo.payableBillApproveStatus == 26
                ) {
                  // 内审待审核/内审审核不通过
                  this.current = 2;
                } else if (
                  this.financInfo.payableBillApproveStatus == 31 ||
                  this.financInfo.payableBillApproveStatus == 36
                ) {
                  // 接收待审核/接收审核不通过
                  this.current = 3;
                } else if (
                  (this.financInfo.payableBillApproveStatus >= 35 &&
                    this.financInfo.payableBillApproveStatus <= 44) ||
                  this.financInfo.payableBillApproveStatus == 46
                ) {
                  // 平台待审核（初审/复审/终审）/平台审核不通过
                  this.current = 4;
                } else if (
                  this.financInfo.payableBillApproveStatus == 45 ||
                  (this.financInfo.payableBillApproveStatus >= 51 &&
                    this.financInfo.payableBillApproveStatus <= 56)
                ) {
                  // 资方待审核（初审/复审/终审）/资方审核不通过
                  this.current = 5;
                } else if (this.financInfo.payableBillApproveStatus == 99) {
                  // 正常持有
                  this.current = 7;
                }
              } else {
                this.stepList = [
                  {
                    name: '邀请开单',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '核心企业确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '开立审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '接收确认',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '平台审核',
                    realName: '',
                    createTime: '',
                  },
                  {
                    name: '正常持有',
                    realName: '',
                    createTime: '',
                  },
                ];
                if (
                  this.financInfo.payableBillApproveStatus == 10 ||
                  this.financInfo.payableBillApproveStatus == 12
                ) {
                  // 邀请开单审核/邀请开单审核不通过
                  this.current = 1;
                } else if (
                  this.financInfo.payableBillApproveStatus == 20 ||
                  this.financInfo.payableBillApproveStatus == 26
                ) {
                  // 内审待审核/内审审核不通过
                  this.current = 2;
                } else if (
                  this.financInfo.payableBillApproveStatus == 31 ||
                  this.financInfo.payableBillApproveStatus == 36
                ) {
                  // 接收待审核/接收审核不通过
                  this.current = 3;
                } else if (
                  (this.financInfo.payableBillApproveStatus >= 35 &&
                    this.financInfo.payableBillApproveStatus <= 44) ||
                  this.financInfo.payableBillApproveStatus == 46
                ) {
                  // 平台待审核（初审/复审/终审）/平台审核不通过
                  this.current = 4;
                } else if (this.financInfo.payableBillApproveStatus == 99) {
                  // 正常持有
                  this.current = 6;
                }
              }
            }
          } else {
            //转让
            if (this.financInfo.payableBillApproveStatus < 31) {
              this.current = 1;
            } else if (
              this.financInfo.payableBillApproveStatus >= 31 &&
              this.financInfo.payableBillApproveStatus < 99
            ) {
              this.current = 2;
            } else {
              this.current = 4;
            }
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    openagreediag() {
      // if (!this.ruleForm3.corpAccountPublicId) {
      //   this.$message.error("请选择收款账户");
      //   return false;
      // }
      this.showAddDialog = true;
      this.agreedetail.content = '';
      this.bclick = false;
    },
    getrefuselist() {
      api.getDicListByType({ type: 6 }).then((res) => {
        if (res.code == '100000') {
          this.refuselist = res.datas.list;
        }
      });
    },
    openrefusediag() {
      this.showAddDialog2 = true;
      this.refusedetail.content = '';
      this.refusedetail.denialReason = this.refuselist[0].name;
      this.bclick = false;
    },
    async submitAddForm() {
      // if (!this.ruleForm3.fundReceiveAccountId) {
      //   this.$message.error("请选择收款账户！");
      //   return;
      // }
      var user = JSON.parse(sessionStorage.getItem('user'));
      if (!this.checked) {
        this.$message.error(
          '请阅读并同意银e券转让协议,应收账款电子凭证转让通知书'
        );
        return;
      }
      var isUkey = JSON.parse(sessionStorage.corpList).signType;
      var yfdMessageDto = {
        itemId: this.$route.query.id,
        itemType: 1,
        operatorAction: 1,
        operatorType: 12,
        phone: user.phone,
        payableBillId: this.$route.query.id,
      };
      if (isUkey == 2) {
        let ukeyRes = await ukey.LoadObj();
        if (!ukeyRes.result) {
          return;
        }
        let certContent = await ukey.GetCertInfoOnClick('CertContent');
        yfdMessageDto.x509Cert = certContent.result;
      }
      if (isUkey == 2) {
        //不发送短信
        api.sendPhoneMessage(yfdMessageDto).then((res) => {
          if (res.success) {
            yfdMessageDto.verifyCode = '';
            this.showAddDialog = false;
            let param = {
              corpId: user.relationCorpId,
            };
            //signType=1承诺付款协议
            //p1 p2是短信校验
            var p1;
            var p3;
            p3 = api
              .CheckverifyOperatorMessage({
                ...yfdMessageDto,
                signType: 9,
                signRole: 2,
              })
              .then((res) => {
                return res;
              });
            //signType=2开立/转让凭证
            var p2 = api
              .CheckSendMessage({ ...yfdMessageDto, signType: 2 })
              .then((res) => {
                return res;
              });
            //吧p2放第一个是因为转让 没有付款协议
            Promise.all([p2, p3])
              .then((values) => {
                //这里就是处理错误逻辑 比如短信验证码不正确
                if (!values[0].success) {
                  this.$message.error(values[0].message);
                  return false;
                }
                var pdfSealHashXY;
                var pdfSealHash;
                var pdfSealHashKD;
                var pdfSealHashZR;
                //开启签章模式 pdfSign=1是开启.0是关闭
                //关闭以后  校验接口 返回的data就是{},values是{}
                if (user.pdfSign == 1 && isUkey == 2) {
                  pdfSealHashZR = ukey.SignHashOnClick(
                    values[1].datas.data.pdfSealHash
                  );
                  pdfSealHash = ukey.SignHashOnClick(
                    values[0].datas.data.pdfSealHash
                  );
                }
                return Promise.all([pdfSealHash, pdfSealHashZR]).then(
                  (hash) => {
                    var pdfobj = {};
                    if (user.pdfSign == 1 && isUkey == 2) {
                      //转让接收
                      return {
                        pdfId: values[0].datas.data.pdfId,
                        pdfSealHash: hash[0].result,
                        pdfIdZR: values[1].datas.data.pdfId,
                        pdfSealHashZR: hash[1].result,
                      };
                    } else {
                      return pdfobj;
                    }
                  }
                );
              })
              .then((pdfobj) => {
                console.log(pdfobj, 'pdf');
                if (!pdfobj) {
                  return;
                }
                this.bclick = true;
                this.agreedetail.payableBillId = this.financInfo.payableBillId;
                this.agreedetail.status =
                  this.financInfo.payableBillApproveStatus;
                this.agreedetail.projectCode = this.projectCode;
                this.agreedetail.checkCode = this.code;
                if (this.$route.query.isguantee == 1) {
                  this.agreedetail.approveType = 5;
                }
                var params = Object.assign(
                  { yfdMessageDto },
                  this.agreedetail,
                  pdfobj
                );
                const loading = this.$loading({
                  lock: true,
                  text: 'Loading',
                  spinner: 'el-icon-loading',
                  background: 'rgba(0, 0, 0, 0.7)',
                });
                api
                  .submitPayableBillResult(params)
                  .then((res) => {
                    loading.close();
                    if (res.code == '100000') {
                      this.showAddDialog = false;
                      var msga = '';
                      if (
                        this.financInfo.payableBillNo.indexOf('-') == -1 &&
                        this.financInfo.guaranteeCorpId != null
                      ) {
                        msga = '审核通过';
                      } else {
                        msga = '审核通过';
                      }
                      this.$alert(msga, '', {
                        confirmButtonText: '确定',
                        callback: (action) => {
                          history.go(-1);
                        },
                      });
                    } else {
                      this.$message.error(res.message);
                      this.bclick = false;
                    }
                  })
                  .catch((err) => {
                    loading.close();
                    this.bclick = false;
                  });
              });
          }
        });
      } else {
        api.sendPhoneMessage(yfdMessageDto).then((res) => {
          if (res.success) {
            let meessage =
              '提示信息：平台向您尾号为' +
              user.phone.slice(user.phone.length - 4) +
              '的手机号码发送了验证短信。请在下方输入框中输入短信中的验证码';
            this.$prompt(meessage, '请输入短信验证码', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              closeOnClickModal: false,
            })
              .then(({ value }) => {
                yfdMessageDto.verifyCode = value;
                const loading = this.$loading({
                  lock: true,
                  text: 'Loading',
                  spinner: 'el-icon-loading',
                  background: 'rgba(0, 0, 0, 0.7)',
                });
                api
                  .CheckSendMessage({ ...yfdMessageDto, signType: 2 })
                  .then((res) => {
                    if (res.success) {
                      if (this.bclick) {
                        return;
                      }
                      this.bclick = true;
                      this.agreedetail.payableBillId =
                        this.financInfo.payableBillId;
                      this.agreedetail.status =
                        this.financInfo.payableBillApproveStatus;

                      var params = Object.assign(
                        { yfdMessageDto },
                        this.agreedetail,
                        {
                          receivedCorpAccountId:
                            this.ruleForm3.fundReceiveAccountId,
                          receivedCorpAccount:
                            this.ruleForm3.fundReceiveAccount,
                        }
                      );
                      console.log(params, 'params');
                      api
                        .submitPayableBillResult(params)
                        .then((res) => {
                          loading.close();
                          if (res.code == '100000') {
                            this.showAddDialog = false;
                            this.$alert('接收成功，正常持有', '', {
                              confirmButtonText: '确定',
                              callback: (action) => {
                                history.go(-1);
                              },
                            });
                          } else {
                            this.$message.error(res.message);
                            this.bclick = false;
                          }
                        })
                        .catch((err) => {
                          loading.close();

                          this.bclick = false;
                        });
                    } else {
                      loading.close();
                      this.$message.error(res.message);
                      return;
                    }
                  });
              })
              .catch((err) => {
                loading.close();
              });
          } else {
            this.$message.error(res.message);
            loading.close();
          }
        });
      }
    },
    submitAddForm2() {
      var user = JSON.parse(sessionStorage.getItem('user'));
      if (this.bclick) {
        return;
      }
      this.bclick = true;
      this.refusedetail.payableBillId = this.financInfo.payableBillId;
      this.refusedetail.status = this.financInfo.payableBillApproveStatus;
      var params = Object.assign(this.refusedetail, {
        receivedCorpAccountId: this.ruleForm3.fundReceiveAccountId,
        receivedCorpAccount: this.ruleForm3.fundReceiveAccount,
      });

      api
        .submitPayableBillResult(params)
        .then((res) => {
          // loading.close();

          if (res.code == '100000') {
            this.showAddDialog2 = false;
            this.$alert('操作成功，退回重新修改', '', {
              confirmButtonText: '确定',
              callback: (action) => {
                history.go(-1);
              },
            });
          } else {
            this.$message.error(res.message);
            this.bclick = false;
          }
        })
        .catch((err) => {
          // loading.close();

          this.bclick = false;
        });

      //           })
      //                 }else{

      //       this.$message.error(res.message);

      //                 }
      //  })
    },
    downloadfn(item) {
      api
        .htwjdownLoadFile({
          payableBillId: item.payableBillId,
          payableBillContractId: item.payableBillContractId,
          fileName: item.contractName,
        })
        .then((res) => {
          openDownloadDialog(res, item.contractName + '.zip');
        })
        .catch((err) => {});
    },
    circulation() {
      api
        .circulation({ payableBillId: this.$route.query.id })
        .then((res) => {
          if (res.code == '100000') {
            this.lzdata = res.datas.payableBillDetail[0];
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {});
    },
    renderContent(h, data) {
      if (!data.hild) {
        let currentData = '';
        if (this.$route.query.id == data.payableBillId) {
          currentData = 'flow-style active';
        } else {
          currentData = 'flow-style';
        }
        let content = [
          h('div', {}, '银e券编号:' + data.payableBillNo),
          h('div', {}, '原始开立方:' + data.originalName),
          data.openCorpName ? h('div', {}, '转让方:' + data.openCorpName) : '',
          h('div', {}, '持有方:' + data.receiveName),
          h(
            'div',
            {},
            '金额：' + changeMoneyThousand(data.payableBillAmount) + ' 元'
          ),
          h(
            'div',
            {},
            '承诺付款日：' +
              (data.paymentDate
                ? moment(data.paymentDate).format('YYYY-MM-DD')
                : '')
          ),
          h(
            'div',
            {},
            '是否已融资：' +
              (data.payableBillFinanceList.length > 0 ? '是(点击查看)' : '否')
          ),
        ];
        return h('div', { domProps: { className: currentData } }, content);
      } else {
        return '多次流转';
      }
    },
    preview(row) {
      this.row = row;
      // api
      //   .DownloadContract({ contractNo: this.financInfo.contractNo })
      //   .then(res => {
      //     var binaryData = [];
      //     binaryData.push(res);
      //     this.src = window.URL.createObjectURL(
      //       new Blob(binaryData, { type: "application/zip" })
      //     );
      //     this.centerDialogVisible = true;
      //   });
      window.open(api.getImgURL(this.financInfo.evidencePath));
    },
    orgclickfn(val, data) {
      if (data.payableBillFinanceList.length > 0) {
        this.showAddDialog3 = true;
        this.tableData = data.payableBillFinanceList;
      }
    },
    downloadsingle(row) {
      api
        .filesdownsingle({
          filePath: row.fileUrl,
        })
        .then((res) => {
          openDownloadDialog(res, row.fileName);
        })
        .catch((err) => {});
    },
    // 弹出协议
    showDialog(isRead, type) {
      this.showType = type;
      this.readDisabled = true;

      if (type) {
        if (type == '1') {
          this.agreementDialog = true;
        }
      } else {
        // if (!this.ruleForm3.corpAccountPublicId) {
        //   this.$message.error("请先选择收款账户！");
        //   this.checked = false;
        //   return false;
        // }
        if (this.checked) {
          // if (
          //   (this.$route.query.flag && !this.ruleForm3.corpAccountPublicId) ||
          //   (this.$route.query.flag2 && !this.ruleForm3.corpAccountPublicId)
          // ) {
          //   this.$message.error("请先选择收款账户");
          //   return false;
          // }
          this.checked = false;
          this.agreementDialog = true;
          this.readCountDown();
        }
      }
    },
    // 弹出转让通知书
    showDialog2(isRead, type) {
      // if (!this.ruleForm3.corpAccountPublicId) {
      //   this.$message.error("请先选择收款账户！");
      //   return false;
      // }
      var obj = {
        amount: this.financInfo.payableAmount,
        corpAccountPublicId: this.ruleForm3.corpAccountPublicId,
        payableBillId: this.$route.query.id,
        type: 2,
      };
      api.createTransferNotification(obj).then((res) => {
        if (res.code == 100000) {
          //  window.open(api.getImgURL(res.datas.data));
          this.transferbook = res.datas.data;
        } else {
          this.$message.error(res.message);
        }
      });
      this.showType = type;
      if (type) {
        if (type == '1') {
          this.agreementDialog2 = true;
        }
      } else {
        if (this.checked2) {
          this.checked2 = false;
          this.agreementDialog2 = true;
          this.readCountDown();
          this.readDisabled = true;
        }
      }
    },
    // 阅读倒计时
    readCountDown() {
      this.readCount = 3;
      var readTimer = setInterval(() => {
        this.readCount--;
        if (this.readCount <= 0) {
          this.readDisabled = false;
          clearInterval(readTimer);
        }
      }, 1000);
    },
    // 同意
    async hasRead() {
      this.agreementDialog = false;

      this.readDisabled = false;

      var obj = {
        amount: this.financInfo.payableAmount,
        corpAccountPublicId: this.ruleForm3.corpAccountPublicId,
        payableBillId: this.$route.query.id,

        type: 2,
      };
      api.createTransferNotification(obj).then((res) => {
        if (res.code == 100000) {
          //  window.open(api.getImgURL(res.datas.data));
          this.transferbook = res.datas.data;
          this.agreementDialog2 = true;
          this.readCountDown();
          this.readDisabled = true;
        } else {
          this.$message.error(res.message);
        }
      });

      // this.checked = true;
    },
    async hasRead2() {
      this.agreementDialog2 = false;
      this.readDisabled = false;
      this.checked = true;
      var isUkey = JSON.parse(sessionStorage.corpList).signType;
      var user = JSON.parse(sessionStorage.getItem('user'));

      var certContent = {
        result: '',
      };
      if (isUkey == 2) {
        let ukeyRes = await ukey.LoadObj();
        if (!ukeyRes.result) {
          return;
        }
        certContent = await ukey.GetCertInfoOnClick('CertContent');
      }
      // if (!this.ruleForm3.corpAccountPublicId) {
      //   this.$message.error("请选择收款账户");
      //   return;
      // }

      var obj = {
        amount: this.financInfo.payableAmount,
        corpAccountPublicId: this.ruleForm3.corpAccountPublicId,
        payableBillId: this.$route.query.id,

        type: 2,
      };

      var p1 = await api
        .getSignHash({
          signType: 5,
          payableBillId: this.$route.query.id,
          x509Cert: certContent.result,
          corpAccountPublicId: this.ruleForm3.corpAccountPublicId,
        })
        .then((res) => {
          return res;
        });

      Promise.all([p1])
        .then((values) => {
          if (!values[0].success) {
            this.$message.error(values[0].message);
            return false;
          }
          var pdfSealHash;

          if (user.pdfSign == 1 && isUkey == 2) {
            pdfSealHash = ukey.SignHashOnClick(
              values[0].datas.data.pdfSealHash
            );
          }

          return Promise.all([pdfSealHash]).then((hash) => {
            if (user.pdfSign == 1 && isUkey == 2) {
              return {
                pdfId: values[0].datas.data.pdfId,
                pdfHash: hash[0].result,
              };
            } else {
              return {};
            }
          });
        })
        .then((hash) => {
          let param = {
            corpAccountPublicId: this.ruleForm3.corpAccountPublicId,
            signType: 5, //1 融资企业  2  资方
            payableBillId: this.$route.query.id,
            ...hash,
          };
          api.transferpdfEsign(param).then((res) => {
            if (res.success) {
              this.$message.success('操作成功');
            } else {
              this.$message.error(res.message);
            }
          });
        });
    },
    // 关闭
    closeDialog() {
      this.agreementDialog = false;
    },
    // 判断是否开户
    doOpenAccount(grantCreditNo) {
      api
        .doOpenAccount({ grantCreditNo })
        .then((res) => {
          if (res.success) {
            // msg为true 资金结算渠道为上海银行
            // status 0是未开户 1开户中 2开户成功  3开户失败
            // openPrompt 0提示开户成功 2不提示
            if (res.datas.msg) {
              this.accountResult = res.datas.status;
              if (res.datas.status == 0) {
                this.showAlert = true;
                this.forbidden = true;
              } else if (res.datas.status == 1) {
                this.showAlert = false;
                this.forbidden = true;
                // 提示开户中
                this.accountResultDialog = true;
              } else if (res.datas.status == 2) {
                this.showAlert = false;
                // if(res.datas.openPrompt == 0) {
                //   // 提示开户成功
                //   this.accountResultDialog = true
                //   this.cltNm = res.datas.cltNm
                //   this.subNo = res.datas.subNo
                //   // 调用开户提示接口，下次不再提示开户成功
                //   api.openPrompt({openAccountId: res.datas.openAccountId}).then(res => {
                //     if(res.success) {

                //     } else {
                //       this.$message.error(res.message);
                //     }
                //   }).catch((err) => {
                //     this.$message.error('操作失败');
                //   })
                // }
                // 提示激活账户
                if (res.datas.isTiedCard != 2) {
                  this.forbidden = true;
                  this.openAccountId = res.datas.openAccountId;
                  this.isTiedCard = res.datas.isTiedCard;
                  this.accountResultDialog = true;
                  this.cltNm = res.datas.cltNm;
                  this.subNo = res.datas.subNo;
                } else {
                  this.forbidden = false;
                }
              } else if (res.datas.status == 3) {
                // 提示开户失败
                this.forbidden = true;
                this.showAlert = true;
                this.accountResultDialog = true;
                this.remark = res.datas.remark;
                this.openAccountId = res.datas.openAccountId;
                this.isEdit = true;
              }
            } else {
              this.forbidden = false;
              this.showAlert = false;
            }
          } else {
            this.$alert('温馨提示', res.message, {
              confirmButtonText: '确定',
              callback: (action) => {},
            });
          }
        })
        .catch((err) => {
          this.$message.error('操作失败');
        });
    },
    // 跳转到开户页面
    goOpenAccount() {
      if (this.isEdit) {
        // 编辑
        this.$router.push({
          name: '开立渠道账户',
          query: { openAccountId: this.openAccountId, isEdit: true },
        });
      } else {
        // 开户
        this.$router.push({ name: '开立渠道账户' });
      }
    },
    // 跳转到渠道账户详情页
    goAccountManagement() {
      // accountType 2上海银行
      this.$router.push({
        name: '渠道账户管理',
        query: {
          openAccountId: this.openAccountId,
          accountType: 2,
          toDetail: 1,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/moreDetail/moudleDetail.scss';

.content {
  //padding: 0px 0px 0px 30px;
  width: 100%;
  height: 100%;
  &.easypay-manager {
    background-color: #f1f1f1 !important;
    padding: 16px;
  }
  &-inner {
    background-color: #fff;
    .el-tabs {
      &__nav-wrap {
        &.is-top {
          background-color: #f1f1f1;
        }
      }
    }
  }
  .el-tabs__nav-wrap.is-top {
    padding: 0 32px;
  }
  .infotitle {
    color: #262626;
    font-size: 16px;
    font-weight: bold;
    text-indent: 32px;
    display: inline-block;
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
    height: 60px;
    line-height: 60px;
  }
  .info-card-A {
    padding: 0 32px;
    height: calc(100vh - 203px);
    overflow-y: auto;
  }
  .card-bd-row {
    line-height: 24px;
  }
  .page-head-back {
    color: #2937a6;
    float: right;
    margin-right: 40px;
    margin-top: 16px;
    border: 0px scope #fff;
    i {
      font-size: 14px;
    }
    &.downsize {
      margin-top: 0;
      font-weight: normal;
      font-size: 12px;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .sub-title {
    border-bottom: none;
    height: 40px;
    font-size: 16px;
    color: #262626;
    padding: 0;
    .txbst {
      position: relative;
      padding-left: 11px;
      &::before {
        content: '';
        display: inline-block;
        width: 3px;
        height: 17px;
        background-color: #d19f53;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  .card-number {
    // display: inline-block;
    position: relative;
    padding: 0 24px;
    background: #f4f5f9;
    border-radius: 2px 2px 0 0;
    height: 72px;
    border-bottom: 2px #2937a6 solid;
    line-height: 72px;
    &-title {
      display: inline-block;
      font-size: 14px;
      color: #595959;
    }
    &-value {
      display: inline-block;
      font-size: 22px;
      color: rgba(0, 0, 0, 0.85);
    }
    &-btn {
      position: absolute;
      top: 36px;
      right: 24px;
      margin-top: -16px;
    }
  }
  //  重写开始
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .maincontin {
    margin-top: 30px;
    .marrimg {
      img {
        margin-right: 1%;
      }
    }
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;

    border-radius: 4px;

    .mainht {
      background-color: #f1f1f1;
      color: #262626;
      font-size: 16px;
      font-weight: bold;
      text-indent: 32px;
      display: inline-block;
      border-bottom: 1px solid #e6e6e6;
      width: 100%;
      height: 60px;
      line-height: 60px;
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 200px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
    .isdot {
      .el-badge__content.is-fixed {
        top: 10px;
        right: 0px;
      }
      .el-badge__content {
        background-color: #cfcfcf;
      }
    }
    .numcolor {
      font-size: 18px;
      color: #f5212d;
    }
  }

  .label-box {
    line-height: 24px;
    text-align: left !important;
    cursor: pointer;
  }
  .org-tree-node-label .org-tree-node-label-inner {
    padding: 0px;
  }
  .flow-style {
    padding: 10px 15px;
    &.active {
      background-color: #409eff;
      color: #fff;
      border-radius: 3px;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    }
  }

  .text-center {
    text-align: center;
    min-height: calc(100vh - 203px);
  }
}
.account-content {
  margin-bottom: 40px;
}
.card-footer {
  z-index: 10;
}
</style>
