<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="payableBillNo" label="银e券编号" min-width="200"></el-table-column>
          <el-table-column v-if="corpType==2" prop="receivedCorpName" label="接收企业" min-width="170"></el-table-column>
          <el-table-column v-if="corpType==3" prop="openCorpName" label="开立企业" min-width="170"></el-table-column>
          <el-table-column label="清分金额(元)" min-width="170">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.amount) }}</template>
          </el-table-column>
          <el-table-column label="清分付款日期" min-width="150">
            <template slot-scope="scope">{{scope.row.createTime | formatDate }}</template>
          </el-table-column>
          <el-table-column prop="sorting" label="清分方式" :formatter="sortingfn" min-width="150"></el-table-column>
          <el-table-column prop="status" label="清分状态" :formatter="statusfn" min-width="150"></el-table-column>
          
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <!-- <el-button size="medium" type="text" @click="preview(scope.row)">预览</el-button> -->
              <el-button size="medium" type="text" @click="handleEdit(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
    <el-dialog title="" :visible.sync="centerDialogVisible" width="80%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <pdf class="pdf" ref="pdf" :src="src"></pdf>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from "../../api/api.js";
import pdf from "vue-pdf";
import { changeMoneyThousand } from "@/util/common";
import moment from 'moment'

export default {
  components: {
    pdf
  },
  props:['corpType'],
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: "银e券编号",
          type: "input",
          field: "payableBillNo",
          size: "small"
        },
        {
          label: "接收企业名称",
          type: "input",
          field: "receivedCorpName",
          size: "small"
        },
        {
          label: "清分付款日期:",
          type: "daterange",
          field: ["payTimeStart", "payTimeEnd"],
          size: "small"
        },
       
      ],
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      pageNo: 1,
      pageSize: 10,
      changeMoneyThousand:changeMoneyThousand,
      centerDialogVisible: false,
      api: api,
      row: {},
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      fileType: "pdf", // 文件类型
      src: "" // pdf文件地址
    };
  },
  created() {
    if(this.corpType == 3) {
      this.filterList = [
        {
          label: "银e券编号",
          type: "input",
          field: "payableBillNo",
          size: "small"
        },
        {
          label: "开立企业名称",
          type: "input",
          field: "openCorpName",
          size: "small"
        },
        {
          label: "清分付款日期:",
          type: "daterange",
          field: ["payTimeStart", "payTimeEnd"],
          size: "small"
        },
       
      ]
    }
  },
  methods: {
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
      console.log("filterShowAll  -----", this.isShowAll);
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      console.log("filters<", filters);
      this.getLists(filters);
    },
    sortingfn(val) {
      console.log(val, '---------val')
      var sorting=''
      switch(val.sorting) {
        case '1':
          sorting = '逐级清分'
          break;
        case '2':
          sorting = '直接清分'
          break;
      }
      return sorting
    },
    statusfn(val) {
      var status=''
      switch(val.status) {
        case '1':
          status = '正常清分完成'
          break;
        case '2':
          status = '逾期清分完成'
          break;
      }
      return status
    },
    //查询
    getLists(param) {
      console.log("param ====>", param);
      // param.corpId = "1";
      api.getClearingListByPage(param).then(res => {
        console.log("res ===>", res);
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
          console.log("list ===>", this.listData);
        }
        this.listLoading = false;
      });
    },
    handleEdit(row){
      this.$router.push({name:'银e券已清分流转详情',query: { id:row.payableBillId, sorting:row.sorting, isshowbtn:2}})
    },
    preview(row) {
      this.row = row;
      window.open(api.getImgURL(row.evidencePath))
      // this.listLoading = true;
      // api.DownloadContract({ contractNo: row.contractNo }).then(res => {
      //   var binaryData = [];
      //   binaryData.push(res);
      //   this.src = window.URL.createObjectURL(
      //     new Blob(binaryData, { type: "application/zip" })
      //   );
      //   console.log("this.src ===>", this.src);
        // this.scr = pdf.createLoadingTask(this.src);
        // window.open('/static/pdf/web/viewer.html?file=' +encodeURIComponent(url) + '&.pdf');

        // console.log('res ===>',res);
        // this.src = res;
        // this.centerDialogVisible = true;
        // this.listLoading = false;
      // });
    }
  }
};
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
