<template>
  <div>
    <div class="main-contentTop1"></div>
    <div class="main-TopContentBottom">
      <div class="steps">
        <el-steps :active="3" align-center>
          <el-step title="企业信息认证"></el-step>
          <el-step title="个人信息认证"></el-step>
          <el-step title="企业资料补充"></el-step>
          <el-step title="用户人脸识别"></el-step>
          <el-step title="注册申请成功"></el-step>
        </el-steps>
      </div>
    </div>

    <div class="register_main">
      <el-form ref="registerForm" label-position="right" label-width="140px"
        class="demo-ruleForm registerDemo login-container"></el-form>
      <div class="main-TopContent" style="text-align: center; height: 80%">
        <!-- <img src="../../assets/login/rz_cg.png" style="margin-top: 50px;" alt /> -->
        <div id="qrcode" ref="qrcode"></div>
        <!-- 创建一个div，并设置id为qrcode -->
        <div>
          <h3>请使用微信扫一扫功能，扫描以上二维码，在手机端完成视频验证</h3>
          <h3>请确认为当前注册人本人进行视频验证</h3>
          <h4>
            人脸认证成功后，请
            <el-button @click="$router.go(0)" type="primary">刷新页面</el-button>
          </h4>
          <!-- <el-button @click="pass" type="primary" style="width:238px;margin-top: 20px; ">手动通过人脸识别</el-button> -->
        </div>
        <el-button type="primary" size="small" @click.native="back()" style="
            width: 160px;
            height: 40px;
            background: white;
            color: black;
            margin-right: 32px;
          ">上一步</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../api/api";
import upload from "../../api/upload";
import _ from "lodash";
import QRCode from "qrcodejs2"; // 引入qrcode

export default {
  data() {
    return {
      type: "",
      step: "",
      corpId: JSON.parse(sessionStorage.getItem("corpList")).corpId,
    };
  },
  mounted() {
    this.getCorpByCorpId();
    // this.qrcode();
  },

  methods: {
    back() {
      this.$router.push({
        path: "/CompanyConfirm",
        query: {
          corpId: this.$route.query.corpId,
        },
      });
    },
    async getCorpByCorpId() {
      var user = JSON.parse(sessionStorage.getItem("user"));
      const res = await api.GetCorpByCorpId({
        relationCorpId: user.relationCorpId,
      });
      console.log("res", res);
      if (res.code == 100000) {
        this.corpList = res.datas.corp;
        sessionStorage.setItem("corpList", JSON.stringify(this.corpList));
        //信息完善之后 需要判断企业是否已经认证 "企业信息审核： 40初始状态 41平台初审通过 43 平台复审通过" registerIdentify 1 已检测 2 未检测 3检查失败
        if (
          res.datas.corp.registerIdentify == null ||
          res.datas.corp.registerIdentify !== 1
        ) {
          // console.log("registerIdentify ===>", res.datas.corp.registerIdentify);
          this.qrcode();
        } else {
          this.$router.push({
            path: "/CompanyResult",
            query: {
              corpId: user.relationCorpId,
            },
          });
        }
      }
    },
    async qrcode() {
      const res = await api.faceVerify(this.corpId);
      console.log("res", res);
      if (res.datas?.url) {
        // let textStr = api.livingQRCodeUrl(this.corpId);
        const textStr = res?.datas?.url;
        let text = encodeURI(textStr);
        let qrcode = new QRCode("qrcode", {
          width: 300,
          height: 300,
          // text: 'http://192.168.21.68:9999/#/livingCertification?corpId=122',//二维码地址
          text: text,
          colorDark: "#000",
          colorLight: "#fff",
        });
        // 去掉二维码的title内容: qrcode插件暂时没有找到提供title的配置,
        // 只有操作dom属性
        this.$refs.qrcode.title = "";
      } else if (res.code === "100000") {
        var user = JSON.parse(sessionStorage.getItem("user"));
        this.$router.push({
          path: "/CompanyResult",
          query: {
            corpId: user.relationCorpId,
          },
        });
      } else {
        this.$message.error("未查询到二维码信息");
      }
    },
    pass() {
      var user = JSON.parse(sessionStorage.getItem("user"));
      window.location.reload();
      api.changeIdentify({ corpId: user.relationCorpId }).then((res) => {
        if (res.success) {
          this.$alert(res.message, "", {
            confirmButtonText: "确定",
            callback: (action) => {
              this.$router.push({
                path: "/CompanyResult",
                query: {
                  corpId: user.relationCorpId,
                },
              });
            },
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/register/company.scss";

.el-form-item__content {
  line-height: 30px !important;
}

.register_main {
  margin: auto;
  width: 1110px;
  background: white;
  height: 600px;
}

.required:before {
  content: "*";
  color: red;
  position: absolute;
  padding-top: 13px;
  padding-left: -10px;
}

.required1:before {
  content: "*";
  color: red;
  position: absolute;
  padding-top: 13px;
  padding-left: 20px;
}

.el-input {
  width: 400px;
  height: 32px;
}
</style>
<style lang="scss">
.item-input {
  .el-input {
    float: left;
    width: 59%;
  }
}

.of-hidden {
  margin-left: -10px;
}

.main-contentTop1 {
  height: 239px;
  /*background-color: red;*/
  background: url("../../assets/login/cusrz.jpg") center;
  background-size: cover;
}

.main-TopContentBottom {
  width: 1110px;
  height: 130px;
  background: white;
  margin: auto;
  margin-top: 0px;
  border-bottom: 1px solid gray;
}

.steps {
  padding: 34px 40px 24px 40px;
  margin-top: -60px;

  .el-step__icon {
    width: 32px;
    height: 32px;
  }
}

.back {
  text-align: none;
  margin-left: 150px;
  margin-top: 30px;
  width: 800px;
  height: 192px;
  background: rgba(250, 250, 250, 1);

  .title {
    margin-left: 30px;
    padding-top: 30px;
    text-align: left;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: rgba(89, 89, 89, 1);
    line-height: 22px;
  }

  .sub-title {
    margin-left: 30px;
    padding-top: 10px;
    text-align: left;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: rgba(38, 38, 38, 1);
    line-height: 22px;
  }
}

#qrcode {
  display: inline-block;
  margin-top: 20px;

  img {
    width: 300px;
    height: 300px;
    background-color: #fff; //设置白色背景色
    padding: 6px; // 利用padding的特性，挤出白边
  }
}
</style>
