<template>
  <div class="common-page-dataList">
      <el-tabs type="border-card" v-model="activeName" class="n-tabs">
                  <data-list
        :filters="filterList"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
      
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="payableBillNo" label="银e券编号" min-width="170"></el-table-column>
          <el-table-column prop="openCorpName" label="原始开单企业" min-width="170"></el-table-column>

          <el-table-column prop="receivedCorpName" label="转让企业" min-width="170"></el-table-column>
          <el-table-column prop="corpIndustrySecondId" label="所属行业" min-width="120"></el-table-column>
          <el-table-column label="银e券金额" min-width="100" >
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.payableAmount) }}</template>
          </el-table-column>
          <el-table-column label="承诺付款日" min-width="170">
            <template slot-scope="scope">{{scope.row.promisePayTime | formatDate }}</template>
          </el-table-column>
          <el-table-column prop="approveStatusName" label="审批状态" width="100" align="left"></el-table-column>
          <el-table-column label="操作" width="120" >
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
      </el-tabs>
  </div>
</template>
<script>
  import api from "@/api/api";
  import moment from 'moment'


export default {
    components: {
 
  },
  data() {
    return {
      
          filterList: [

        {
          label: "承诺付款日:",
          type: "daterange",
          field: ["promisePayTimeStr", "promisePayTimeEnd"],
          size: "small"
        },
        {
          label: "接收企业名称",
          type: "input",
          field: "receivedCorpName",
          size: "small"
        },
          {
          label: "银e券编号",
          type: "input",
          field: "payableBillNo",
          size: "small"
        },
        {
          label: "银e券金额: ",
          type: "inputrange",
          field: "startAmount",
          size: "small",
          isaa: 1
        },
        {
          type: "inputrange",
          field: "endAmount",
          size: "small"
        },

      ],
      activeName: "1",
 
  
      total: 0,
      listLoading: false,
      listData: [],
      handleEditShow: false,
      moment:moment
    };
  },
  created() {
    // this.activeName = this.$route.params.state;
    sessionStorage.name='银e券'
  },
  methods: {
 
      headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
   handleRefresh(filters) {
            this.getLists(filters);
    },
    //查询
    getLists(param) {
           
      api.ReceiveList(param).then(res => {
        console.log('res ===>',res);
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
        this.listLoading = false;
      });
    },
    handleEdit(data){
      this.$router.push({name:'开立初审详情',query: { id:data.payableBillId,isshowbtn:1}})
    }
  }
};
</script>

<style lang="scss">

.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>