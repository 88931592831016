<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList">
      <div class="easypayby bl">
        <div style="padding-left: 40px" v-if="showBack">
          <div class="yfdsize rback">退回原因</div>
          <div class="card-bd card-bd-row refuseback">
            <el-row>
              <el-col :span="16" class="lb_body">
                <img
                  src="../../assets/cuowu.png"
                  alt=""
                  style="margin-left: -27px; vertical-align: middle"
                />
                <span class="info-key">被退回原因:</span>
                <span class="info-value index">{{ refusereason.reason }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" class="lb_body">
                <span class="info-key">备注:</span>
                <span class="info-value index">{{ refusereason.remark }}</span>
              </el-col>
            </el-row>
          </div>
        </div>

        <div class="yfdtwo">
          <div class="yfdsize" style="font-weight: bold">基本信息</div>
          <div class="formstyle">
            <el-form
              :model="PayableBillAddRequest"
              :rules="rules"
              ref="PayableBillAddRequest"
              label-width="198px"
              class="demo-ruleForm keytext"
            >
              <el-row :gutter="20">
                <el-col :span="8">
                  <span class="info-key">账款债权企业: </span>
                  <span class="info-value index">{{
                    PayableBillAddRequest.claimsCorpName
                  }}</span>
                </el-col>
                <el-col :span="8">
                  <span class="info-key">统一社会信用代码: </span>
                  <span class="info-value index">{{
                    PayableBillAddRequest.claimsCorpCreditNo
                  }}</span>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="8">
                  <span class="info-key">账款债务企业: </span>
                  <span class="info-value index">{{
                    PayableBillAddRequest.debtCorpName
                  }}</span>
                </el-col>
                <el-col :span="8">
                  <span class="info-key">统一社会信用代码: </span>
                  <span class="info-value index">{{
                    PayableBillAddRequest.deptCorpCreditNo
                  }}</span>
                </el-col>
              </el-row>

              <el-row :gutter="20">
                <el-col :span="8">
                  <span class="info-key">账款开始时间: </span>
                  <span class="info-value index">{{
                    moment(PayableBillAddRequest.accountSignDate).format(
                      'YYYY-MM-DD'
                    )
                  }}</span>
                </el-col>
                <el-col :span="8">
                  <span class="info-key">账款结束时间: </span>
                  <span class="info-value index">{{
                    moment(PayableBillAddRequest.accountCloseDate).format(
                      'YYYY-MM-DD'
                    )
                  }}</span>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="8">
                  <span class="info-key">应收账款金额(元): </span>
                  <span class="info-value index">{{
                    changeMoneyThousand(PayableBillAddRequest.accountAmount)
                  }}</span>
                </el-col>
                <el-col :span="8">
                  <span class="info-key">贸易合同编号: </span>
                  <span class="info-value index">{{
                    PayableBillAddRequest.contractNo
                  }}</span>
                </el-col>
                <el-col :span="8">
                  <span class="info-key">大写: </span>
                  <span class="info-value index">{{
                    PayableBillAddRequest.accountAmountCpt
                  }}</span>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <div class="yfdtwo">
          <div class="yfdsize">合同信息</div>

          <div class="formstyle">
            <div class="htbox" :class="{ active: ruleform2.isactive }">
              <div class="htactive bl">
                <span>交易合同</span>
                <a
                  class="page-head-back downsize right"
                  @click="downloadfn"
                  size="small"
                >
                  <span> <i class="el-icon-download"></i>一键下载</span>
                </a>
              </div>
              <div class="htpad">
                <el-form
                  :model="ruleform2"
                  :rules="ruleform2.rules"
                  label-position="left"
                  ref="ruleform2"
                  class="demo-ruleForm"
                >
                  <div class="marbtext">相关合同</div>
                  <div>
                    <el-row>
                      <picfile
                        v-if="
                          PayableBillAddRequest.receivableAccountFileDtoList
                            .length > 0
                        "
                        :picfilelist="
                          PayableBillAddRequest.receivableAccountFileDtoList
                        "
                      ></picfile>
                    </el-row>
                  </div>
                  <div class="marbo22">
                    共有文件:
                    <span class="numcolor">{{
                      PayableBillAddRequest.receivableAccountFileDtoList.length
                    }}</span>
                    个
                  </div>

                  <div class="uploadlist">
                    <div class="marbtext">相关发票</div>
                    <div>
                      <el-row>
                        <div style="overflow: hidden">
                          <picfile
                            v-if="PayableBillAddRequest.invoiceList.length > 0"
                            :picfilelist="PayableBillAddRequest.invoiceList"
                          ></picfile>
                        </div>
                      </el-row>
                    </div>
                    <div class="marbo22">
                      共有文件:
                      <span class="numcolor">{{
                        PayableBillAddRequest.invoiceList.length
                      }}</span>
                      个
                    </div>
                    <el-form-item>
                      <el-table
                        :data="PayableBillAddRequest.invoiceList"
                        highlight-current-row
                        style="width: 100%; margin-top: 20px"
                      >
                        <el-table-column type="index" label="序号" width="80">
                        </el-table-column>
                        <el-table-column
                          prop="invoiceBuyer"
                          label="购买方"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <el-badge
                              class="isdot"
                              :value="
                                scope.row.invoiceBuyerUpdate == 2 ? '改' : ''
                              "
                            >
                              {{ scope.row.invoiceBuyer }}
                            </el-badge>
                          </template>
                        </el-table-column>

                        <el-table-column
                          prop="invoiceSeller"
                          label="销售方"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <el-badge
                              class="isdot"
                              :value="
                                scope.row.invoiceSellerUpdate == 2 ? '改' : ''
                              "
                            >
                              {{ scope.row.invoiceSeller }}
                            </el-badge>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="invoiceCode"
                          label="发票代码"
                          min-width="120"
                        >
                          <template slot-scope="scope">
                            <el-badge
                              class="isdot"
                              :value="
                                scope.row.invoiceCodeUpdate == 2 ? '改' : ''
                              "
                            >
                              {{ scope.row.invoiceCode }}
                            </el-badge>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="invoiceNo"
                          label="发票号码"
                          min-width="120"
                        >
                          <template slot-scope="scope">
                            <el-badge
                              class="isdot"
                              :value="
                                scope.row.invoiceNoUpdate == 2 ? '改' : ''
                              "
                            >
                              {{ scope.row.invoiceNo }}
                            </el-badge>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="invoiceCheckCode"
                          label="校验码"
                          min-width="120"
                        >
                          <template slot-scope="scope">
                            <el-badge
                              class="isdot"
                              :value="
                                scope.row.invoiceCheckCodeChange == 2
                                  ? '改'
                                  : ''
                              "
                              >{{ scope.row.invoiceCheckCode }}</el-badge
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="invoiceOpenTime"
                          label="开票日期"
                          min-width="120"
                        >
                          <template slot-scope="scope">
                            <el-badge
                              class="isdot"
                              :value="
                                scope.row.invoiceOpenTimeChange == 2 ? '改' : ''
                              "
                              >{{ scope.row.invoiceOpenTime }}</el-badge
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="invoiceAmount"
                          label="开具金额(元)"
                          width="200"
                          align="left"
                        >
                          <template slot-scope="scope">
                            <el-badge
                              class="isdot"
                              :value="
                                scope.row.invoiceAmountChange == 2 ? '改' : ''
                              "
                              >{{
                                $changeMoneyThousand(scope.row.invoiceAmount)
                              }}</el-badge
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="invoiceAmountWithoutTax"
                          label="开具金额(不含税)"
                          width="200"
                          align="left"
                        >
                          <template slot-scope="scope">
                            <el-badge
                              class="isdot"
                              :value="
                                scope.row.invoiceAmountWithoutTaxChange == 2
                                  ? '改'
                                  : ''
                              "
                              >{{
                                $changeMoneyThousand(
                                  scope.row.invoiceAmountWithoutTax
                                )
                              }}</el-badge
                            >
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-form-item>
                    <div class="ljje">
                      累计发票金额:
                      <span>
                        {{ changeMoneyThousand(PayableBillAddRequest.fptotal) }}
                      </span>
                      元
                    </div>
                  </div>

                  <div class="marbtext">其他附件</div>
                  <div
                    class="card-bd marrimg"
                    style="padding-bottom: 51px; padding-left: 30px"
                  >
                    <span class="info-key">物流类型:</span>
                    <el-row>
                      <picfile
                        v-if="PayableBillAddRequest.wuLiuList.length > 0"
                        :picfilelist="PayableBillAddRequest.wuLiuList"
                      ></picfile>
                    </el-row>
                    <span class="info-key">银行类型:</span>
                    <div>
                      <el-row>
                        <picfile
                          v-if="PayableBillAddRequest.bankList.length > 0"
                          :picfilelist="PayableBillAddRequest.bankList"
                        ></picfile>
                      </el-row>
                    </div>
                    <div class="marbo22">
                      共有文件:
                      <span class="numcolor">{{
                        PayableBillAddRequest.wuLiuList.length +
                        PayableBillAddRequest.bankList.length
                      }}</span>
                      个
                    </div>
                  </div>

                  <div v-if="PayableBillAddRequest.ackFilesList.length > 0">
                    <div class="marbtext">
                      应收账款确权:<span>确权完成</span>
                    </div>
                    <div
                      class="card-bd marrimg"
                      style="padding-bottom: 51px; padding-left: 30px"
                    >
                      <el-row>
                        <picfile
                          v-if="PayableBillAddRequest.ackFilesList.length > 0"
                          :picfilelist="PayableBillAddRequest.ackFilesList"
                        ></picfile>
                      </el-row>
                      <div class="marbo22">
                        共有文件:
                        <span class="numcolor">{{
                          PayableBillAddRequest.ackFilesList.length
                        }}</span>
                        个
                      </div>
                    </div>
                  </div>

                  <div v-if="PayableBillAddRequest.supplyFiles.length > 0">
                    <div class="marbtext">其余补充资料:</div>
                    <div
                      class="card-bd marrimg"
                      style="padding-bottom: 10px; padding-left: 30px"
                      v-for="(iten, ket) in PayableBillAddRequest.supplyFiles"
                      :key="ket"
                    >
                      <el-row>
                        <p>{{ iten.name }}</p>

                        <picfile
                          v-if="iten.accountFileList.length > 0"
                          :picfilelist="iten.accountFileList"
                        ></picfile>
                      </el-row>
                    </div>
                    <div style="padding-bottom: 20px; padding-left: 30px">
                      共有文件:
                      <span class="numcolor">{{ totalfilenum }}</span> 个
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
        </div>

        <div class="card-bottom" v-if="$route.query.isshowbtn == 1">
          <el-button class="card-btn" type="primary" @click="openrefusediag"
            >退回</el-button
          >
          <el-button class="card-btn" type="primary" @click="openagreediag"
            >同意</el-button
          >
        </div>
        <div class="card-bottom" v-if="$route.query.isshowbtn == 2">
          <el-button class="card-btn" type="primary" @click="goBack"
            >返回</el-button
          >
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      title="审核意见"
      :visible.sync="showAddDialog"
      :close-on-click-modal="false"
      :show-close="false"
      width="40%"
    >
      <el-form :model="agreedetail" label-width="60px" ref="addform">
        <el-form-item label="备注：">
          <el-input
            v-model="agreedetail.remark"
            type="textarea"
            :rows="6"
            maxlength="200"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitAddForm" :disabled="bclick"
          >提交</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="拒绝原因"
      :visible.sync="showAddDialog2"
      :close-on-click-modal="false"
      :show-close="false"
      width="40%"
    >
      <el-form :model="refusedetail" label-width="100px" ref="addform">
        <el-form-item label="拒绝原因：" prop="reason">
          <el-select v-model="refusedetail.reason" placeholder="请选择">
            <el-option
              v-for="item in refuselist"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            v-model="refusedetail.remark"
            type="textarea"
            :rows="6"
            maxlength="200"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog2 = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitAddForm2('addform')"
          :disabled="bclick"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </layout-main-view>
</template>
<script>
import api from '@/api/api';
import { convertCurrency, adds } from '@/util/common.js';
import { baseURL } from '@/api/sys';
import moment from 'moment';
import { changeMoneyThousand } from '@/util/common';
import { debug } from 'util';
import picfile from '@/components/picfile';

Number.prototype['adds'] = function (...arg) {
  var r1,
    r2,
    m,
    result = this;
  arg.forEach((value) => {
    try {
      r1 = result.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = value.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    result = Math.round(result * m + value * m) / m;
  });
  return result;
};

function openDownloadDialog(url, saveName) {
  // for ie 10 and later

  if (window.navigator.msSaveBlob) {
    try {
      window.navigator.msSaveBlob(url, saveName);
    } catch (e) {
      console.log(e);
    }
  }
  // 谷歌浏览器 创建a标签 添加download属性下载
  else {
    if (typeof url == 'object' && url instanceof Blob) {
      url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) {
      event = new MouseEvent('click');
    } else {
      event = document.createEvent('MouseEvents');
      event.initMouseEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
    }
    aLink.dispatchEvent(event);
  }
}

export default {
  components: {
    picfile: picfile,
  },

  data() {
    var checkmaxmount = (rule, value, callback) => {
      if (!/^\d{1,12}(\.\d{1,2})?$/.test(value)) {
        callback(new Error('请输入12位以内的数字,小数点后最多2位'));
      } else {
        if (value > this.PayableBillAddRequest.grantableAmount) {
          callback(new Error('银e券金额必须小于等于可用授信额度'));
        } else {
          callback();
        }
      }
    };

    return {
      checked: true,
      baseURL: baseURL,
      loading: false,
      breadcrumblist: ['应收账款详情'],
      activebtn: 0,
      rules: {
        fundCorpName: [
          { required: true, message: '请选择债务企业', trigger: 'change' },
        ],

        contractNo: [
          { required: true, message: '请输入合同编号' },
          { pattern: /^.{1,50}$/, message: '请输入50位以内的编号' },
        ],
        accountAmount: [
          { required: true, message: '请输入应收账款金额' },
          {
            pattern: /^\d{1,12}(\.\d{1,2})?$/,
            message: '请输入12位以内的数字,小数点后最多2位',
          },
        ],

        zktime: [
          { required: true, message: '请选择账款时限', trigger: 'change' },
        ],
      },
      rules2: {
        payableAmount: [
          { validator: checkmaxmount },
          { required: true, message: '请输入银e券金额' },
        ],
        promisePayTime: [{ required: true, message: '请选择承诺付款日' }],
      },
      bclick: false,
      PayableBillAddRequest: {
        debtCorpId: '',
        debtCorpName: '',
        deptCorpCreditNo: '',
        contractNo: '',
        accountAmount: '',
        accountAmountCpt: '',
        accountSignDate: '',
        accountCloseDate: '',
        zktime: '',
        receivableAccountFileDtoList: [],
        invoiceList: [],
        bankList: [],
        wuLiuList: [],
        fptotal: 0,
        ackFilesList: [],
        supplyFiles: [],
      },
      zjlist: [],
      sxbhlist: [],
      qyallname: [],

      ruleform2: {},
      agreedetail: {
        receivableAccountId: this.$route.query.id,
        approveResult: 1,
        remark: '',
        approveNode: this.$route.query.node,
        ackSuccess: 1,
      },
      refusedetail: {
        receivableAccountId: this.$route.query.id,
        approveResult: 2,
        remark: '',
        approveNode: this.$route.query.node,
        reason: '',
        ackSuccess: 1,
      },
      refuselist: [],
      refuselist2: [], //枚举其余补充资料

      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return (
            time.getTime() >
            new Date(this.PayableBillAddRequest.grantCloseTime).getTime()
          );
        }.bind(this),
      },
      listfile: [],
      convertCurrency: convertCurrency,
      changeMoneyThousand: changeMoneyThousand,
      moment: moment,
      api: api,
      showAddDialog: false,
      showAddDialog2: false,
      totalfilenum: 0,
      refusereason: {},
      showBack: this.$route.query.showBack,
    };
  },

  created() {
    this.receivableAccountdetail();
    this.getrefuselist(); //获取拒绝原因
  },
  methods: {
    receivableAccountdetail() {
      //获取详情
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      api
        .receivableAccountdetail({ receivableAccountId: this.$route.query.id })
        .then((res) => {
          if (res.code == 100000) {
            this.refusereason = res.datas.approve;
            this.PayableBillAddRequest = res.datas.ReceivableAccountDto;
            var arr = [];
            this.PayableBillAddRequest.invoiceList.map((el) => {
              arr.push(el.invoiceAmount);
            });

            this.PayableBillAddRequest.fptotal = (0).adds(...arr);
            loading.close();
            this.$previewRefresh();
            this.totalfilenum = 0;
            api.getDicListByType({ type: 17 }).then((res) => {
              if (res.code == '100000') {
                this.refuselist2 = res.datas.list;
                this.refuselist2.map((el) => {
                  this.PayableBillAddRequest.supplyFiles.map((ele) => {
                    if (ele.fileCode == el.code) {
                      this.$set(ele, 'name', el.name);
                    }
                  });
                });
              }
            });
            this.PayableBillAddRequest.supplyFiles.map((el) => {
              el.accountFileList.map((ele) => {
                ++this.totalfilenum;
              });
            });
          } else {
            loading.close();
          }
        })
        .catch((err) => {
          loading.close();

          this.bclick = false;
        });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    cellhover(row, column, cell, event) {
      cell.children[0].children[0].style.border = '1px solid #ccc';
    },
    cellleave(row, column, cell, event) {
      cell.children[0].children[0].style.border = '1px solid #fff';
    },
    goBack() {
      history.go(-1);
    },
    openagreediag() {
      this.showAddDialog = true;
      this.agreedetail.remark = '';

      this.bclick = false;
    },
    getrefuselist() {
      api.getDicListByType({ type: 15 }).then((res) => {
        if (res.code == '100000') {
          this.refuselist = res.datas.list;
        }
      });
    },
    openrefusediag() {
      this.showAddDialog2 = true;
      this.refusedetail.remark = '';
      this.refusedetail.reason = this.refuselist[0].name;
      this.bclick = false;
    },
    submitAddForm() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      if (this.bclick) {
        return;
      }
      this.bclick = true;

      api
        .submitReceivableAccountResult(this.agreedetail)
        .then((res) => {
          loading.close();
          if (res.code == '100000') {
            this.showAddDialog = false;
            this.$alert('应收账款审核通过，等待审核', '', {
              confirmButtonText: '确定',
              callback: (action) => {
                history.go(-1);
              },
            });
            // history.go(-1)
          } else {
            this.$message.error(res.message);
            this.bclick = false;
          }
        })
        .catch((err) => {
          loading.close();

          this.bclick = false;
        });
    },
    submitAddForm2() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      if (this.bclick) {
        return;
      }
      this.bclick = true;

      api
        .submitReceivableAccountResult(this.refusedetail)
        .then((res) => {
          loading.close();

          if (res.code == '100000') {
            this.$alert(res.message, '', {
              confirmButtonText: '确定',
              callback: (action) => {
                history.go(-1);
                this.showAddDialog2 = false;
              },
            });
          } else {
            this.$message.error(res.message);
            this.bclick = false;
          }
        })
        .catch((err) => {
          loading.close();

          this.bclick = false;
        });
    },
    downloadfn(item) {
      api
        .yszkdownLoadFile({
          receivableAccountId: this.$route.query.id,
          fileName: this.$route.query.id,
        })
        .then((res) => {
          openDownloadDialog(res, '应收账款.zip');
        })
        .catch((err) => {});
    },
    downloadsingle(row) {
      api
        .filesdownsingle({
          filePath: row.fileUrl,
        })
        .then((res) => {
          openDownloadDialog(res, row.fileName);
        })
        .catch((err) => {});
    },
  },
};
</script>
<style lang="scss">
.yfdbaseline {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 18px;
  margin-bottom: 37px;
}

.easypayby {
  padding-bottom: 40px;
}

.yfdtwo {
  padding: 0 42px;

  .el-input.is-disabled .el-input__inner {
    color: rgb(96, 98, 102);
  }
}

.httotal {
  height: 35px;
  background: #fff3f3;
  border-radius: 4px;
  line-height: 35px;
  padding-left: 17px;
  margin-bottom: 18px;
  color: #828282;

  span {
    color: #da2b2b;
    line-height: 22px;
  }
}

.formstyle {
  & > div {
    margin-bottom: 20px;
  }

  .el-select,
  .el-date-editor {
    width: 100%;
  }

  .elspan {
    .el-input__inner {
      border-radius: 4px 0 0 4px;
    }

    .el-input {
      width: 80%;
    }

    .el-form-item__content {
      span {
        width: 20%;
        display: inline-block;
        height: 40px;
        background: #f7f7f7;
        border: 1px solid #d9d9d9;
        border-radius: 4px 0 0 4px;
        border-radius: 0 4px 4px 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 40px;
        border-left: none;
        position: relative;
        top: 1px;
        text-align: center;
      }
    }
  }

  .pec.elspan {
    .el-input {
      width: 90%;
    }

    .el-form-item__content {
      span {
        width: 10%;
      }
    }
  }

  .el-table__body-wrapper {
    input[type='text'] {
      border: 1px solid #fff;
    }

    .el-table__row {
      input {
        padding: 6px 10px;
      }
    }
  }

  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #fff !important;
  }

  .htbox {
    border-radius: 4px;
    border: 1px solid #cfcfcf;

    .htpad {
      padding: 0 17px;

      form {
        overflow: hidden;
      }
    }

    .htactive {
      height: 43px;
      overflow: hidden;
      background: #f1f1f1;
      line-height: 43px;

      span {
        display: inline-block;
        width: 100px;
        margin-left: 17px;
        float: left;
        cursor: pointer;
      }
    }

    .htno {
      height: 90px;
      border-bottom: 1px solid #e6e6e6;
      padding-top: 25px;
    }

    .uploadfont {
      color: rgba(0, 0, 0, 0.45);
      line-height: 42px;

      span {
        text-indent: 8em;
        display: inline-block;
      }
    }

    .uploadlist {
      padding: 30px 16px 0 16px;

      .upload-demo {
        margin-left: 8em;
        margin-top: 2em;
      }

      .el-form-item {
        margin-bottom: 40px;
      }

      .el-form-item__label {
        font-size: 16px;
        color: #000000;
      }

      .el-upload-dragger {
        width: 300px;
      }

      .el-upload-dragger .el-upload__text,
      .el-icon-plus {
        color: rgba(0, 0, 0, 0.45);
      }

      .el-icon-plus {
        font-size: 30px;
      }
    }

    .el-icon-delete {
      float: right;
      padding: 12px 20px 0 0;
      cursor: pointer;
      font-size: 18px;
    }

    .ljje {
      text-align: right;
      margin-bottom: 40px;
      color: #595959;

      span {
        color: #f5212d;
      }
    }
  }
}

.easyformbtn {
  text-align: center;

  button {
    width: 160px;
    height: 40px;
    font-size: 16px;

    text-align: center;
    line-height: 18px;

    span {
      color: #ffffff;
    }
  }

  button:nth-last-child(1) {
    background-color: #2937a6;
  }

  button:nth-last-child(2) {
    span {
      color: #595959;
    }
  }

  button:hover {
    background-color: #edf5ff;

    span {
      color: #67a9fd;
    }
  }
}

.formstyle {
  .active {
    height: 133px;
    overflow: hidden;
  }

  .iconactive {
    transform: scaleY(-1);
  }
}

.addbtn {
  border: 1px dashed #cfcfcf;
  height: 44px;
  text-align: center;
  border-radius: 4px;
  line-height: 44px;
  font-size: 14px;
  color: #828282;
  cursor: pointer;

  i {
    margin-right: 6px;
  }
}

.yfdsize {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  margin-bottom: 29px;
}

.wxts {
  color: rgba(0, 0, 0, 0.45);
  margin: 8px 0;
}

.pt16 {
  position: relative;
  top: -16px;
}

.easypayby.bl {
  margin-top: 20px;
}

.htactive.bl {
  margin-bottom: 20px;
}

.marbtext {
  margin-bottom: 24px;
  font-size: 16px;
  color: #262626;
  font-weight: bold;

  span {
    opacity: 0.6;
  }
}

.card-bottom {
  text-align: center;
  border: 1px solid #d9d9d9;
  height: 70px;
  z-index: 20;
  width: calc(100vw - 230px);
  line-height: 70px;
  position: fixed;
  bottom: 0;
  background-color: #fff;

  .card-btn {
    width: 160px;
    height: 40px;
  }
}

.bl {
  .numcolor {
    font-size: 18px;
    color: #f5212d;
  }

  img {
    margin-right: 1%;
  }

  .isdot {
    .el-badge__content.is-fixed {
      top: 10px;
      right: 0px;
    }

    .el-badge__content {
      background-color: #cfcfcf;
    }
  }

  .marbo22 {
    margin-top: 22px;
  }

  .keytext .el-row {
    margin-bottom: 15px;
  }

  .info-key {
    padding-right: 10px;
    color: #262626;
  }

  .info-value {
    color: #595959;
  }
}
</style>
