<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList">
      <el-steps :active="activebtn" align-center>
        <el-step title="基本信息"></el-step>
        <el-step title="交易文件上传"></el-step>
      </el-steps>
      <div class="easypayby">
        <div v-show="activebtn == 0" class="yfdtwo">
          <div class="yfdsize">基本信息</div>
          <div class="formstyle">
            <el-form
              :model="absAccountDetail"
              :rules="rules"
              ref="absAccountDetail"
              label-width="198px"
              class="demo-ruleForm"
            >
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="账款类型: " prop="absAccountType">
                    <el-select
                      v-model="absAccountDetail.absAccountType"
                      placeholder="请选择账款类型"
                      filterable
                      @change="getAbsAccountType"
                    >
                      <el-option
                        v-for="(item, index) in absAccountTypeList"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item
                    :label="
                      absAccountDetail.absAccountType == 1
                        ? '债务企业:'
                        : '债权企业:'
                    "
                    prop="companyInfo"
                  >
                    <el-select
                      v-model="absAccountDetail.companyInfo"
                      placeholder="请选择企业"
                      value-key="corpId"
                      filterable
                      @change="getcreditCodefn"
                    >
                      <el-option
                        v-for="(item, index) in companyList"
                        :key="index"
                        :label="item.corpName"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="统一社会信用代码: ">
                    <el-input
                      v-model="absAccountDetail.corpCreditNo"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="贸易合同编号: " prop="contractNo">
                    <el-input v-model="absAccountDetail.contractNo"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="账款起止时间: " prop="zktime">
                    <el-date-picker
                      v-model="absAccountDetail.zktime"
                      type="daterange"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      @change="zktimefn"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item
                    :label="
                      (absAccountDetail.absAccountType == 1 ? '应收' : '应付') +
                      '账款金额（元）:'
                    "
                    prop="absAccountAmount"
                  >
                    <el-input
                      v-model="absAccountDetail.absAccountAmount"
                      @blur="tocapital"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="大写: ">
                    <el-input
                      v-model="absAccountDetail.accountAmountCpt"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <div v-show="activebtn == 1" class="yfdtwo">
          <div class="yfdsize">合同信息</div>
          <div class="formstyle">
            <div class="htbox" :class="{ active: ruleform2.isactive }">
              <div class="htactive">
                <span>交易合同</span>
              </div>
              <div class="htpad">
                <el-form
                  :model="ruleform2"
                  label-position="left"
                  ref="ruleform2"
                  class="demo-ruleForm"
                >
                  <div class="uploadlist">
                    <el-form-item
                      label="上传相关合同: "
                      prop="files"
                      class="upload-text-requird"
                    >
                      <div class="uploadfont wxtsk">
                        温馨提示：仅支持JPG、PNG、PDF、ZIP、RAR格式。上传的合同必须加盖骑缝章，按照合同页数进行排列。<br /><span
                          class="pt16"
                        >
                          使用高质量图片，尽量拍摄清晰防止审批造成不必要的时间损失。由于拍摄质量造成的审批不合格平台概不负责。</span
                        >
                      </div>
                      <el-upload
                        multiple
                        class="upload-demo upload-contentr"
                        :action="baseURL + '/web/file/uploadFile'"
                        :on-success="(a, b, c) => dragupfn(a, b, c)"
                        :on-remove="(a, b) => dragremovefn(a, b)"
                        :headers="Authorization"
                        :file-list="ruleform2.filelist"
                        accept="image/png,image/jpeg,application/zip,application/rar,application/pdf"
                        :before-upload="beforeupfnfile"
                        drag
                        list-type="picture"
                      >
                        <i class="el-icon-plus"></i>
                        <div class="el-upload__text">上传合同</div>
                      </el-upload>
                    </el-form-item>
                  </div>
                  <div class="uploadlist">
                    <el-form-item
                      label="上传相关发票: "
                      prop="img"
                      class="upload-text-requird"
                    >
                      <div class="uploadfont wxtsk">
                        温馨提示：仅支持JPG、PNG格式。上传的发票必须真实不能重复。<br /><span
                          class="pt16"
                        >
                          使用高质量图片，尽量拍摄清晰防止审批造成不必要的时间损失。由于拍摄质量造成的审批不合格平台概不负责。</span
                        >
                      </div>
                      <el-upload
                        class="upload-demo upload-contentr"
                        multiple
                        :action="baseURL + '/web/file/uploadAbsAccountInvoice'"
                        :on-success="(a, b, c) => handleupfn(a, b, c)"
                        :on-remove="(a, b) => handleRemove(a, b)"
                        :on-error="erruploadfp"
                        :headers="Authorization"
                        :file-list="ruleform2.filelistfp"
                        accept="image/png,image/jpeg,image/jpg"
                        :before-upload="beforeupfn"
                        list-type="picture"
                        drag
                      >
                        <i class="el-icon-plus"></i>
                        <div class="el-upload__text">上传发票</div>
                      </el-upload>
                    </el-form-item>
                    <el-form-item>
                      <el-table
                        :data="ruleform2.absAccountInvoiceList"
                        @cell-mouse-enter="cellhover"
                        @cell-mouse-leave="cellleave"
                        style="width: 100%; top: 20px"
                      >
                        <el-table-column type="index" label="序号" width="80">
                        </el-table-column>
                        <el-table-column
                          label="购买方"
                          align="left"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model="scope.row.invoiceBuyer"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="销售方"
                          align="left"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model="scope.row.invoiceSeller"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="发票代码"
                          align="left"
                          min-width="180"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model="scope.row.invoiceCode"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="发票号码"
                          align="left"
                          min-width="180"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model="scope.row.invoiceNo"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="检验码"
                          align="left"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model.trim="scope.row.invoiceCheckCode"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="开票日期"
                          align="left"
                          min-width="120"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model="scope.row.invoiceOpenTime"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="不含税开具金额（元）"
                          align="left"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model.trim="scope.row.invoiceAmountWithoutTax"
                              class="tabright"
                              
                            />
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="开具金额（元）"
                          align="right"
                          min-width="200"
                        >
                          <template slot-scope="scope">
                            <input
                              type="text"
                              v-model="scope.row.invoiceAmount"
                              class="tabright"
                              @blur="blurfptotal(scope.row, scope.$index, item)"
                            />
                          </template> </el-table-column
                        >v
                      </el-table>
                    </el-form-item>
                    <div class="ljje">
                      累计发票金额:
                      <span>
                        {{ changeMoneyThousand(ruleform2.fptotal) }}
                      </span>
                      元
                    </div>
                  </div>
                  <div class="uploadlist">
                    <el-form-item label="上传其他资料: ">
                      <div class="uploadfont wxtsk">
                        温馨提示：仅支持JPG、PNG、PDF、ZIP、RAR格式。<br /><span
                          class="pt16"
                        >
                          使用高质量图片，尽量拍摄清晰防止审批造成不必要的时间损失。由于拍摄质量造成的审批不合格平台概不负责。</span
                        >
                      </div>
                      <el-upload
                        class="upload-demo upload-contentr"
                        :action="baseURL + '/web/file/uploadFile'"
                        :on-success="(a, b, c) => dragupotherfn(a, b, c)"
                        :on-remove="(a, b) => dragremoveotherfn(a, b)"
                        :on-error="erruploadfp"
                        :file-list="ruleform2.otherListfp"
                        :headers="Authorization"
                        accept="image/png,image/jpeg,image/jpg,application/zip,application/rar,application/pdf"
                        :before-upload="beforeupfnfile"
                        list-type="picture"
                        drag
                        multiple
                      >
                        <i class="el-icon-plus"></i>
                        <div class="el-upload__text">上传资料</div>
                      </el-upload>
                    </el-form-item>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <div v-show="activebtn == 2">
          <div class="easybaseline">银e券开立</div>
        </div>
        <div class="easyformbtn btn-box">
          <el-button
            style="margin-top: 12px"
            @click="prevbtn"
            v-text="activebtn == 0 ? '返回' : '上一步'"
          ></el-button>
          <el-button style="margin-top: 12px" @click="nextbtn">提交</el-button>
        </div>
      </div>
    </div>
  </layout-main-view>
</template>
<script>
import api from '@/api/api';
import { convertCurrency, adds } from '@/util/common.js';
import { baseURL } from '@/api/sys';
import moment from 'moment';
import { changeMoneyThousand } from '@/util/common';
import dataFilterVue from '@/components/datalist/dataFilter.vue';
Number.prototype['adds'] = function (...arg) {
  var r1,
    r2,
    m,
    result = this;
  arg.forEach((value) => {
    try {
      r1 = result.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = value.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    result = Math.round(result * m + value * m) / m;
  });
  return result;
};
export default {
  data() {
    return {
      baseURL: baseURL,
      loading: false,
      breadcrumblist: ['添加账款'],
      activebtn: 0,
      absAccountTypeList: [
        { label: '应收账款', value: 1 },
        { label: '应付账款', value: 2 },
      ],
      companyList: [],
      rules: {
        absAccountType: [
          { required: true, message: '请选择账款类型', trigger: 'change' },
        ],
        companyInfo: [
          { required: true, message: '请选择企业', trigger: 'change' },
        ],
        contractNo: [
          { required: true, message: '请输入合同编号' },
          { pattern: /^.{1,50}$/, message: '请输入50位以内的编号' },
        ],
        absAccountAmount: [
          { required: true, message: '请输入账款金额' },
          {
            pattern:
              /^([1-9]\d{0,11}(.\d{1,2})?)$|^(0.\d?[1-9])$|^(0.[1-9]\d?)$/,
            message: '请输入12位以内的数字,小数点后最多2位',
          },
        ],
        zktime: [
          { required: true, message: '请选择账款时限', trigger: 'change' },
        ],
      },
      bclick: false,
      absAccountDetail: {
        companyInfo: '',
        zktime: '',
        absAccountSignDate: '',
        absAccountCloseDate: '',
        debtCorpId: '',
        debtCorpName: '',
        deptCorpCreditNo: '',
        contractNo: '',
        absAccountAmount: '',
        accountAmountCpt: '',
      },
      ruleform2: {
        absAccountContactList: [],
        absAccountInvoiceList: [],
        absAccountOthersList: [],
        fptotal: 0,
        absAccountOthersListfp: [],
        filelist: [],
        filelistfp: [],
        isactive: false,
      },
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return (
            time.getTime() >
            new Date(this.absAccountDetail.grantCloseTime).getTime()
          );
        }.bind(this),
      },
      listfile: [],
      convertCurrency: convertCurrency,
      mounttoall: 0,
      Authorization: { Authorization: sessionStorage.token },
      changeMoneyThousand: changeMoneyThousand,
    };
  },
  created() {
    this.getCoreCorpListExcludeSelf(); //获取债务企业/债务企业
  },
  methods: {
    cellhover(row, column, cell, event) {
      cell.children[0].children[0].style.border = '1px solid #ccc';
    },
    cellleave(row, column, cell, event) {
      cell.children[0].children[0].style.border = '1px solid #fff';
    },
    getCoreCorpListExcludeSelf() {
      // 5 核心企业+链属企业 （不包括自身）
      api.getCoreCorpListExcludeSelf({ type: 5 }).then((res) => {
        if (res.success) {
          this.companyList = res.datas.corpList;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 选择账款类型
    getAbsAccountType(val) {
      this.absAccountDetail.companyInfo = '';
      this.absAccountDetail.corpCreditNo = '';
    },
    // 选择债权/债务企业
    getcreditCodefn(val) {
      this.absAccountDetail.corpCreditNo = val.creditCode;
    },
    // 处理账款起止日
    zktimefn(val) {
      this.absAccountDetail.absAccountSignDate = val[0];
      this.absAccountDetail.absAccountCloseDate = val[1];
    },
    // 金额大写
    tocapital() {
      this.absAccountDetail.accountAmountCpt = this.convertCurrency(
        this.absAccountDetail.absAccountAmount
      );
    },
    prevbtn() {
      if (this.activebtn == 0) {
        this.$router.back();
      } else {
        this.activebtn--;
      }
    },
    nextbtn() {
      if (this.activebtn == 0) {
        this.$refs['absAccountDetail'].validate((valid) => {
          if (valid) {
            this.activebtn++;
          } else {
            return false;
          }
        });
      } else if (this.activebtn == 1) {
        var amount = 0,
          issome = false,
          amountarr = [];
        this.ruleform2.absAccountInvoiceList.map((ele) => {
          amountarr.push(Number(ele.invoiceAmount));
          // Object.keys(ele).forEach((els) => {
          //   if (ele[els] == '') {
          //     issome = true;
          //   }
          // });
        });
        amount = (0).adds(...amountarr);
        if (this.ruleform2.absAccountContactList.length == 0) {
          this.$message.error('请上传合同');
          return;
        }
        if (this.ruleform2.absAccountInvoiceList.length == 0) {
          this.$message.error('请上传发票');
          return;
        }
        if (issome) {
          this.$message.error('发票信息不全');
          return;
        }
        if (this.ruleform2.fptotal < +this.absAccountDetail.absAccountAmount) {
          this.$message.warning('发票金额小于账款金额');
          return;
        }
        this.$refs['ruleform2'].validate((valid) => {
          if (valid) {
            let invoiceInfoList = this.ruleform2.absAccountInvoiceList;
            let tempArr = [];
            let flag = true;

            // 发票验重
            invoiceInfoList.forEach((v) => {
              v.unique = v.invoiceCode + v.invoiceNo;
              if (tempArr.indexOf(v.unique) == -1) {
                tempArr.push(v.unique);
              } else {
                flag = false;
                this.$alert(
                  '发票代码：' + v.invoiceCode + ' 发票号码：' + v.invoiceNo,
                  '发票信息重复',
                  {
                    confirmButtonText: '确定',
                    callback: (action) => {},
                  }
                );
                return false;
              }
            });
            if (!flag) {
              return false;
            }

            if (this.absAccountDetail.absAccountType == 1) {
              // 应收账款 选择债务企业
              this.absAccountDetail.debtCorpId =
                this.absAccountDetail.companyInfo.corpId;
              this.absAccountDetail.debtCorpName =
                this.absAccountDetail.companyInfo.corpName;
              this.absAccountDetail.deptCorpCreditNo =
                this.absAccountDetail.corpCreditNo;
            } else if (this.absAccountDetail.absAccountType == 2) {
              // 应付账款 选择债权企业
              this.absAccountDetail.claimsCorpId =
                this.absAccountDetail.companyInfo.corpId;
              this.absAccountDetail.claimsCorpName =
                this.absAccountDetail.companyInfo.corpName;
              this.absAccountDetail.claimsCorpCreditNo =
                this.absAccountDetail.corpCreditNo;
            }
            this.absAccountDetail.absAccountSource = 1; // 1手动新增 2导入
            let params = Object.assign(
              {},
              params,
              this.ruleform2,
              this.absAccountDetail
            );
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)',
            });
            if (this.bclick) {
              return;
            }
            this.bclick = true;
            api
              .addAccount(params)
              .then((res) => {
                loading.close();
                if (res.success) {
                  this.$alert('账款新增成功，提交内部审核', '', {
                    confirmButtonText: '确定',
                    callback: (action) => {
                      history.go(-1);
                    },
                  });
                } else {
                  this.bclick = false;
                  this.$message.error(res.message);
                }
              })
              .catch((err) => {
                loading.close();
                this.bclick = false;
              });
          } else {
            return false;
          }
        });
      } else {
        this.activebtn = 0;
      }
    },
    dragupfn(response, file, fileList, index) {
      //上传的合同  fileType 1合同 2发票 3补充文件
      if (response.code == '100000') {
        var data = response.datas.uploadDate.message;
        this.ruleform2.filelist[this.ruleform2.filelist.length] = file;
        this.ruleform2.absAccountContactList.push({
          fileType: 1,
          fileUrl: data,
          uid: file.uid,
          fileName: file.name,
        });
      } else {
        this.ruleform2.filelist = this.ruleform2.filelist;
        this.$forceUpdate();
        this.$message.error(file.name + '文件上传失败，请重新上传');
      }
    },
    dragremovefn(file, fileList, index) {
      //移除合同
      if (
        this.ruleform2.absAccountContactList.findIndex(
          (el) => el.uid == file.uid
        ) != -1
      ) {
        this.ruleform2.absAccountContactList.splice(
          this.ruleform2.absAccountContactList.findIndex(
            (el) => el.uid == file.uid
          ),
          1
        );
        this.ruleform2.filelist.splice(
          this.ruleform2.filelist.findIndex((el) => el.uid == file.uid),
          1
        );
      }
    },
    //上传发票
    handleupfn(response, file, fileList, item) {
      var data = response.message.datas.absAccountFileDto;
      item.filelistfp[item.filelistfp.length] = file;
      item.filelistfp.forEach((el, index) => {
        el.name = index + 1;
      });
      item.invoiceList.push({
        ...data,
        fileCode: 15,
        uid: file.uid,
        fileName: file.name,
      });
      item.fptotal = (+item.fptotal).adds(data.invoiceAmount);

      if (response.code === -1) {
        this.$message.error('发票识别错误，请删除后重新上传或手动填写更新。');
      } else {
        this.$message.success(response.message);
      }
    },
    handleRemove(file, fileList) {
      //移除发票
      if (
        this.ruleform2.absAccountInvoiceList.findIndex(
          (el) => el.uid == file.uid
        ) != -1
      ) {
        this.ruleform2.filelistfp.splice(
          this.ruleform2.filelistfp.findIndex((el) => el.uid == file.uid),
          1
        );
        this.ruleform2.filelistfp.forEach((el, index) => {
          el.name = index + 1;
        });
        this.ruleform2.absAccountInvoiceList.splice(
          this.ruleform2.absAccountInvoiceList.findIndex(
            (el) => el.uid == file.uid
          ),
          1
        );
        var arr = [];
        this.ruleform2.absAccountInvoiceList.map((el) => {
          arr.push(Number(el.invoiceAmount));
        });
        this.ruleform2.fptotal = (0).adds(...arr);
      }
    },
    dragupotherfn(response, file, fileList, item, index) {
      //上传其他资料 fileType 1合同 2发票 3补充文件
      if (response.code == '100000') {
        var data = response.datas.uploadDate.message;
        this.ruleform2.absAccountOthersListfp[
          this.ruleform2.absAccountOthersListfp.length
        ] = file;
        this.ruleform2.absAccountOthersList.push({
          fileType: 3,
          fileUrl: data,
          uid: file.uid,
          fileName: file.name,
        });
      } else {
        this.$message.error(file.name + '文件上传失败，请重新上传');
      }
    },
    dragremoveotherfn(file, fileList, item, index) {
      //移除其他资料
      if (
        this.ruleform2.absAccountOthersList.findIndex(
          (el) => el.uid == file.uid
        ) != -1
      ) {
        this.ruleform2.absAccountOthersList.splice(
          this.ruleform2.absAccountOthersList.findIndex(
            (el) => el.uid == file.uid
          ),
          1
        );
        this.ruleform2.absAccountOthersListfp.splice(
          this.ruleform2.absAccountOthersListfp.findIndex(
            (el) => el.uid == file.uid
          ),
          1
        );
      }
    },
    blurfptotal(val, index, item) {
      var arr = [];
      this.ruleform2.absAccountInvoiceList.map((el) => {
        arr.push(Number(el.invoiceAmount));
      });
      this.ruleform2.fptotal = (0).adds(...arr);
    },
    beforeupfn(file) {
      let index = file.name.lastIndexOf('.');
      let suffix = file.name.substr(index + 1);
      if (file.name.substr(0, index).length > 40) {
        this.$message.error('上传文件名长度不得超过40！');
        return false;
      }
      if (!/jpg|gif|bmp|png|jpeg|JPG|GIF|BMP|PNG|JPEG/.test(suffix)) {
        this.$message.error('请上传png,jpg,jpeg格式的图片');
        return false;
      }
      if (file.size > 1048576) {
        this.$message.error('请上传1M以内的图片');
        return false;
      }
    },
    beforeupfnfile(file) {
      let index = file.name.lastIndexOf('.');
      let suffix = file.name.substr(index + 1);
      if (
        !/jpg|gif|bmp|png|jpeg|zip|rar|pdf|JPG|GIF|BMP|PNG|JPEG|ZIP|RAR|PDF/.test(
          suffix
        )
      ) {
        this.$message.error('请上传png,jpg,jpeg,zip,rar格式的文件');
        return false;
      }
    },
    erruploadfp(err) {
      this.$message.error('服务器错误');
    },
  },
};
</script>
<style lang="scss" scoped>
.easyformbtn.btn-box {
  position: fixed;
  z-index: 10;
  width: calc(100vw - 230px);
  height: 70px;
  bottom: 0;
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
</style>

<style lang="scss">
.yfdbaseline {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 18px;
  margin-bottom: 37px;
}
.easypayby {
  padding-bottom: 40px;
}
.yfdtwo {
  padding: 0 42px;
  .el-input.is-disabled .el-input__inner {
    color: rgb(96, 98, 102);
  }
}
.httotal {
  height: 35px;
  background: #fff3f3;
  border-radius: 4px;
  line-height: 35px;
  padding-left: 17px;
  margin-bottom: 18px;
  color: #828282;
  span {
    color: #da2b2b;
    line-height: 22px;
  }
}
.formstyle {
  & > div {
    margin-bottom: 20px;
  }
  .el-select,
  .el-date-editor {
    width: 100%;
  }
  .elspan {
    .el-input__inner {
      border-radius: 4px 0 0 4px;
    }
    .el-input {
      width: 80%;
    }
    .el-form-item__content {
      span {
        width: 20%;
        display: inline-block;
        height: 40px;
        background: #f7f7f7;
        border: 1px solid #d9d9d9;
        border-radius: 4px 0 0 4px;
        border-radius: 0 4px 4px 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 40px;
        border-left: none;
        position: relative;
        top: 1px;
        text-align: center;
      }
    }
  }
  .pec.elspan {
    .el-input {
      width: 90%;
    }
    .el-form-item__content {
      span {
        width: 10%;
      }
    }
  }
  .el-table__body-wrapper {
    input[type='text'] {
      border: 1px solid #fff;
    }
    .el-table__row {
      input {
        padding: 6px 10px;
      }
    }
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #fff !important;
  }
  .htbox {
    border-radius: 4px;
    border: 1px solid #cfcfcf;
    .htpad {
      padding: 0 17px;
      form {
        overflow: hidden;
      }
    }
    .htactive {
      height: 43px;
      overflow: hidden;
      background: #f1f1f1;
      line-height: 43px;
      span {
        display: inline-block;
        width: 100px;
        margin-left: 17px;
        float: left;
        cursor: pointer;
      }
    }
    .htno {
      height: 90px;
      border-bottom: 1px solid #e6e6e6;
      padding-top: 25px;
    }
    .uploadfont {
      color: rgba(0, 0, 0, 0.45);
      line-height: 42px;
      span {
        text-indent: 8em;
        display: inline-block;
      }
    }
    .uploadlist {
      padding: 30px 16px 0 16px;
      .upload-demo {
        margin-left: 8em;
        margin-top: 2em;
      }
      .el-form-item {
        margin-bottom: 40px;
      }
      .el-form-item__label {
        font-size: 16px;
        color: #000000;
      }
      .el-upload-dragger {
        width: 300px;
      }
      .el-upload-dragger .el-upload__text,
      .el-icon-plus {
        color: rgba(0, 0, 0, 0.45);
      }
      .el-icon-plus {
        font-size: 30px;
      }
    }
    .el-icon-delete {
      float: right;
      padding: 12px 20px 0 0;
      cursor: pointer;
      font-size: 18px;
    }
    .ljje {
      text-align: right;
      margin-bottom: 40px;
      color: #595959;
      span {
        color: #f5212d;
      }
    }
  }
}
.easyformbtn {
  text-align: center;
  button {
    width: 160px;
    height: 40px;
    font-size: 14px;
    text-align: center;
    line-height: 18px;
    span {
      color: #ffffff;
    }
  }
  button:nth-last-child(1) {
    background-color: #2937a6;
  }
  button:nth-last-child(2) {
    span {
      color: #595959;
    }
  }
  button:hover {
    background-color: #edf5ff;
    span {
      color: #67a9fd;
    }
  }
}
.formstyle {
  .active {
    height: 133px;
    overflow: hidden;
  }
  .iconactive {
    transform: scaleY(-1);
  }
}
.addbtn {
  border: 1px dashed #cfcfcf;
  height: 44px;
  text-align: center;
  border-radius: 4px;
  line-height: 44px;
  font-size: 14px;
  color: #828282;
  cursor: pointer;
  i {
    margin-right: 6px;
  }
}
.yfdsize {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  margin-bottom: 29px;
}
.wxts {
  color: rgba(0, 0, 0, 0.45);
  margin: 8px 0;
}
.pt16 {
  position: relative;
  top: -16px;
}
</style>
