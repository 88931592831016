<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList">
      <div class="easypayby goback yfdtwo">

                         <div
              class="card-bd card-bd-row refuseback"
              style="font-size: 14px;padding: 0px; background:rgba(255,242,244,1);border:1px solid rgba(255,204,205,1);
"
            >
                   <div class="card-bd card-bd-row refuseback">
                           <el-row>
                              <el-col :span="16" class="lb_body">
                                                 <img src="../../assets/cuowu.png" alt="" style="
                                          margin-left: -27px;
                                          vertical-align: middle;
                                          margin-right:0;
                                          ">
                                    <span class="info-key">被退回原因:</span>
                                    <span class="info-value index">{{info.reason}}</span>
                              </el-col>

                         </el-row>
                         <el-row>
                                 <el-col :span="24" class="lb_body">
                                    <span class="info-key">备注:</span>
                                    <span class="info-value index">{{info.remark}}</span>
                              </el-col>
                         </el-row>
                   </div>
                   </div>
        <div class="yfdsize">基本信息</div>
        <div class="formstyle">
          <el-form :model="info" ref="info" label-width="160px" class="demo-ruleForm">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="融资项目编号: ">
                  <span>{{info.receivableAccountFinanceNo}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="被退回原因: " class="red-color">
                  <span> {{info.reason}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="备注: " class="red-color">
                  <span>{{info.remark}}</span>
                </el-form-item>
              </el-col>
            </el-row> -->
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="应收账款能否确权: ">
                  <el-radio v-model="info.corpAck" label="1" :disabled="forbidden">能</el-radio>
                  <el-radio v-model="info.corpAck" label="2" :disabled="forbidden">否</el-radio>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="期望融资金额(元): " prop="preFinanceAmount">
                  <el-input :disabled="forbidden"
                    v-model="info.preFinanceAmount"
                    placeholder="请输入银e券金额"
                    @change="tocapital"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="期望融资金额(大写): " class="minsizer">
                  <el-input disabled v-model="info.upper"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="inlinelv">
              <el-col :span="6" class="firlabwid">
                <el-form-item label="期望融资年化利率(%): " prop="expectFinanceInterestMin">
                  <el-input v-model="info.expectFinanceInterestMin" placeholder="请输入期望融资年化利率最小值" :disabled="forbidden"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1" class="perwidth">
                <em>~</em>
              </el-col>
              <el-col :span="6" class="nolabwid">
                <el-form-item label=" " prop="expectFinanceInterestMax">
                  <el-input v-model="info.expectFinanceInterestMax" placeholder="请输入期望融资年化利率最大值" :disabled="forbidden"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="收款开户行: " prop="payableAmount">
                  <el-select v-model="info.payeeOpenBank" placeholder="请选择" @change="getOpenBankNo" :disabled="forbidden">
                    <el-option
                      v-for="item in corpAccountPublicList"
                      :key="item.openBank"
                      :label="item.openBank"
                      :value="item.openBank"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="收款账户: " class="minsizer">
                  <el-select v-model="info.payeePublicAccountNo" placeholder="请选择" :disabled="forbidden">
                    <el-option
                      v-for="item in corpAccountPublicNoList"
                      :key="item.accountNo"
                      :label="item.accountNo"
                      :value="item.accountNo"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-if="!forbidden">
              <el-col :span="12">
                <el-form-item label="温馨提示: ">
                  <span class="info-value">还没有绑定卡，</span>
                  <a
                    class="downsize"
                    style="color: #2937A6; text-decoration: underline; cursor: pointer"
                    size="small"
                    @click="linkCompanyManage"
                  >点击此处</a>
                  <span class="info-value">完成对公账户绑定。</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div class="yfdbaseline"></div>
        <div class="yfdsize">绑定应收账款</div>
        <div class="httotal">
          已添加应收账款
          <span>{{tableList.length}}</span> 笔，账款总计金额
          <span>{{ $changeMoneyThousand( info.relationReceivedAccountAmounts ) }}</span> 元。
        </div>
        <div>
              
          <el-table
v-if="returnNodeName != '补件退回'" 
            ref="multipleTable"
            :data="tableList"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"

          >
            <el-table-column type="selection" width="70"></el-table-column>
            <el-table-column prop="receivableAccountNo" label="应收账款编号" min-width="200"></el-table-column>
            <el-table-column prop="debtCorpName" label="债务企业" min-width="170"></el-table-column>
            <el-table-column prop="accountAmount" label="应收账款金额(元)" min-width="120">
              <template slot-scope="scope">{{ $changeMoneyThousand( scope.row.accountAmount) }}</template>
            </el-table-column>
            <el-table-column prop="accountSignDate" label="账款起始日期" min-width="120">
              <template
                slot-scope="scope"
              >{{scope.row.accountSignDate ? moment(scope.row.accountSignDate).format('YYYY-MM-DD'): ''}}</template>
            </el-table-column>
            <el-table-column prop="accountCloseDate" label="账款到期日期" min-width="120">
              <template
                slot-scope="scope"
              >{{scope.row.accountCloseDate ? moment(scope.row.accountCloseDate).format('YYYY-MM-DD'): ''}}</template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
                  <el-table
            v-else
            ref="multipleTable"
            :data="tableList"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"

          >
            <el-table-column type="selection" width="70" :selectable='selectInit'></el-table-column>
            <el-table-column prop="receivableAccountNo" label="应收账款编号" min-width="200"></el-table-column>
            <el-table-column prop="debtCorpName" label="债务企业" min-width="170"></el-table-column>
            <el-table-column prop="accountAmount" label="应收账款金额(元)" min-width="120">
              <template slot-scope="scope">{{ $changeMoneyThousand( scope.row.accountAmount) }}</template>
            </el-table-column>
            <el-table-column prop="accountSignDate" label="账款起始日期" min-width="120">
              <template
                slot-scope="scope"
              >{{scope.row.accountSignDate ? moment(scope.row.accountSignDate).format('YYYY-MM-DD'): ''}}</template>
            </el-table-column>
            <el-table-column prop="accountCloseDate" label="账款到期日期" min-width="120">
              <template
                slot-scope="scope"
              >{{scope.row.accountCloseDate ? moment(scope.row.accountCloseDate).format('YYYY-MM-DD'): ''}}</template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- <el-table
            v-else
            ref="multipleTable"
            :data="tableList"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column prop="receivableAccountNo" label="应收账款编号" min-width="200"></el-table-column>
            <el-table-column prop="debtCorpName" label="债务企业" min-width="170"></el-table-column>
            <el-table-column prop="accountAmount" label="应收账款金额" min-width="120">
              <template slot-scope="scope">{{ $changeMoneyThousand( scope.row.accountAmount) }}</template>
            </el-table-column>
            <el-table-column prop="accountSignDate" label="签署日期" min-width="120">
              <template
                slot-scope="scope"
              >{{scope.row.accountSignDate ? moment(scope.row.accountSignDate).format('YYYY-MM-DD'): ''}}</template>
            </el-table-column>
            <el-table-column prop="accountCloseDate" label="到期日期" min-width="120">
              <template
                slot-scope="scope"
              >{{scope.row.accountCloseDate ? moment(scope.row.accountCloseDate).format('YYYY-MM-DD'): ''}}</template>
            </el-table-column>
            <el-table-column label="操作" width="160">
              <template slot-scope="scope">
        
                <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table> -->
          <div class="card-hd" style="height: 150px;" v-if="$route.query.accountBackStatus!='D'">
            <el-row style="padding-top: 40px;">
              <el-col :span="7" class="lb_body" :offset="7">
                <el-button
                  style="width: 160px;height: 40px;font-size:16px;"
                  size="small"
                  @click="reject"
                >该项目作废</el-button>
              </el-col>
              <el-col :span="8" class="lb_body">
                <el-button
                  style="width: 160px;height: 40px;background: #2937A6;color:#ffffff;font-size:16px;"
                  size="small"
                  @click="agree"
                >重新编辑</el-button>
              </el-col>
            </el-row>
          </div>

             <div class="card-hd" style="height: 150px;" v-if="$route.query.accountBackStatus=='D'">
            <el-row style="padding-top: 40px;">

              <el-col :span="8" class="lb_body" :offset='10'>
                <el-button
                  style="width: 160px;height: 40px;background: #2937A6;color:#ffffff;font-size:16px;"
                  size="small"
                  @click="goback"
                >返回</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      title="上传其他资料"
      :visible.sync="showAddDialog"
      :close-on-click-modal="false"
      :show-close="false"
      width="1200px"
      class="otherfileform">
      <addFile ref="addFile"></addFile>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog=false">取 消</el-button>
        <el-button type="primary" @click="submitAddForm">提交</el-button>
      </div>
    </el-dialog>
  </layout-main-view>
</template>
<script>
import api from "@/api/api";
import { convertCurrency, adds } from "@/util/common.js";
import { baseURL } from "@/api/sys";
import moment from "moment";
import { changeMoneyThousand } from "@/util/common";
import addFile from './addFile'

export default {
  components: {
    addFile
  },
  data() {
    var expectFinanceInterestMin = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入利息最小值"));
      } else if (!/^100$|^(\d|[1-9]\d)(\.\d{1,2})?$/g.test(value)) {
        callback(new Error("请输入100以内最多2位小数的自然数"));
      } else {
        if (this.info.expectFinanceInterestMin !== "") {
          this.$refs.info.validateField("expectFinanceInterestMax");
        }
        callback();
      }
    };
    var expectFinanceInterestMax = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入利息最大值"));
      } else if (value <= this.info.expectFinanceInterestMin) {
        callback(new Error("最大值要比最小值大"));
      } else if (!/^100$|^(\d|[1-9]\d)(\.\d{1,2})?$/g.test(value)) {
        callback(new Error("请输入100以内最多2位小数的自然数"));
      } else {
        callback();
      }
    };
    return {
      moment: moment,
      convertCurrency: convertCurrency,
      baseURL: baseURL,
      loading: false,
      breadcrumblist: ["保理融资"],
      info: {},
      tableList: [],
      corpAccountPublicNoList: [],
      corpAccountPublicList: [],
      info: {
        upper: ""
      },
      receivableAccountFinanceId: 0,
      dialogFormVisible: false, // 拒绝原因
      formLabelWidth: "120px",
      corpAccountPublicList: [], //开户行
      corpAccountPublicNoList: [], // 开户账号
      multipleSelection: [], //选中的表格
      returnNodeId: "",
      returnNodeName: "",
      showAddDialog: false,
      refuselist2:[],
      forbidden: false
    };
  },

  created() {
    this.receivableAccountFinanceId = this.$route.query.receivableAccountFinanceId;
    this.returnNodeId = this.$route.query.returnNodeId;
    this.returnNodeName = this.$route.query.returnNodeName;
    this.forbidden = this.returnNodeName == '补件退回' ? true : false // 是否是补件退回
    this.getfilename()
  },
  mounted() {
    this.getInfo(this.$route.query.receivableAccountFinanceId);
    this.getBindAccountList(); //获取开户行
  },
  methods: {
        selectInit(row,index){
                return false 
       
        },

    getInfo(receivableAccountFinanceId) {
      api
        .AssetDetails({
          receivableAccountFinanceId: receivableAccountFinanceId,
          returnNodeId: this.returnNodeId
        })
        .then(res => {
          if (res.success) {
            this.info = res.datas.data;
            this.info.corpAck = this.info.corpAck + "";
            this.tableList = res.datas.list;
            let upper = this.convertCurrency(this.info.preFinanceAmount);
            this.$set(this.info, "upper", upper);
            // 需要判断列表选中状态
            let rows = JSON.parse(this.info.relationReceivedAccountIds);
            this.$nextTick(() => {
              this.tableList.forEach((ele, index) => {
                rows.forEach(val => {
                  if (val == ele.receivableAccountId) {
                    console.log("选中啦 ====>", ele);
                    this.$refs.multipleTable.toggleRowSelection(ele, true);
                  }
                });
              });
            });
          }
        });
    },
    getBindAccountList() {
      api.GetBindAccountList("").then(res => {
        if (res.success) {
          this.corpAccountPublicList = res.datas.corpAccountPublicList;
        }
      });
    },
    getOpenBankNo() {
      // 获取开户账号
      console.log("openBank ===>", this.info.payeeOpenBank);
      this.info.payeePublicAccountNo = "";
      this.corpAccountPublicNoList = [];
      api.GetBindAccountList(this.info.payeeOpenBank).then(res => {
        if (res.success) {
          this.corpAccountPublicNoList = res.datas.corpAccountPublicList;
        }
      });
    },
    tocapital() {
      console.log("this.info.upper ==> :", this.info.upper);
      this.info.upper = this.convertCurrency(this.info.preFinanceAmount);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    linkCompanyManage() {
      var item = {
        name: "企业管理",
        path: "/Business/Bank_management?pageSize=10&pageNum=1"
      };
      sessionStorage.name = item.name;
      this.$router.push({ path: item.path });
    },
    //重新编辑
    agree() {
      // console.log("relationReceivedAccountIds ===>", this.multipleSelection);
      let relationReceivedAccountIds = [];
      let relationReceivedAccountAmounts = 0;
      this.multipleSelection.forEach(ele => {
        // relationReceivedAccountIds += ele.receivableAccountId + ",";
        relationReceivedAccountIds.push(ele.receivableAccountId);
        relationReceivedAccountAmounts += ele.accountAmount;
      });
      let str = JSON.stringify(relationReceivedAccountIds);
      // relationReceivedAccountIds = relationReceivedAccountIds.substring(0,relationReceivedAccountIds.length - 1);
      console.log(
        "relationReceivedAccountIds =======>",
        relationReceivedAccountIds
      );
      this.$refs.info.validate(valid => {
        if (valid) {
          let param = {
            corpAck: this.info.corpAck,
            preFinanceAmount: this.info.preFinanceAmount,
            expectFinanceInterestMin: this.info.expectFinanceInterestMin,
            expectFinanceInterestMax: this.info.expectFinanceInterestMax,
            payeeOpenBank: this.info.payeeOpenBank,
            payeePublicAccountNo: this.info.payeePublicAccountNo,
            relationReceivedAccountIds: str,
            relationReceivedAccountAmounts: relationReceivedAccountAmounts,
            receivableAccountFinanceId:this.$route.query.receivableAccountFinanceId,
            returnNode:this.$route.query.reason=='补件退回'?2:1
          };
               const loading = this.$loading({
                  lock: true,
                  text: "Loading",
                  spinner: "el-icon-loading",
                  background: "rgba(0, 0, 0, 0.7)"
                });
          api.ReceivableAccountFinanceupdate(param).then(res => {
            loading.close()
            if (res.success) {
              var msgers=this.$route.query.reason=='补件退回'?'修改成功,请等待补件':'修改成功，提交等待审核'
              this.$alert(msgers, "", {
              confirmButtonText: "确定",
              callback: action => {
                history.go(-1);
              }
            });
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    refuseReasonfn(val) {
      // var name = "";
      // name = this.refuselist.find(el => {
      //   return el.code == val;
      // });
      // return name && name.name;
    },
    //项目作废
    reject() {
      api
        .ProjectVoid({
          receivableAccountFinanceId: this.receivableAccountFinanceId
        })
        .then(res => {
          if (res.success) {
            this.$alert("项目作废成功", "", {
              confirmButtonText: "确定",
              callback: action => {
                this.$router.go(-1);
              }
            });
          } else {
            this.$message.error(res.$message);
          }
        });
    },
    handleEdit(data) {
      this.$router.push({name: '应收账款详情', query: {id: data.receivableAccountId, isshowbtn: 2}})
    },
    handleAddFile(data) {
      this.showAddDialog = true;
      this.$nextTick(() => {
        this.$refs.addFile.uploadfilelist = [];
        this.$refs.addFile.subsingle.fileDtoList = [];
        this.$refs.addFile.formupload = data;
        this.$refs.addFile.uploadfilelist = data.supplyFiles;
        this.$refs.addFile.uploadfilelist.map(el => {
          el.arr = [];
          el.filelist = [];
          if (el.accountFileList.length > 0) {
            el.accountFileList.map(item => {
              el.arr.push({
                fileCode: item.fileCode,
                fileUrl: item.fileUrl,
                uid: item.uid,
                fileName: item.fileName
              });
              this.$refs.addFile.subsingle.fileDtoList.push({
                fileCode: item.fileCode,
                fileUrl: item.fileUrl,
                uid: item.uid,
                fileName: item.fileName
              });
              el.filelist.push({
                url: api.getImgURL(item.fileUrl),
                name: item.fileName,
                uid: item.uid
              });
            });
          }
        });
        this.refuselist2.map(el=>{
          this.$refs.addFile.uploadfilelist.map(ele=>{
            if(ele.fileCode==el.code){
              this.$set(ele,'name',el.name)
            }
          })
        })
        this.$refs.addFile.subsingle.receivableAccountId = data.receivableAccountId;
      })
    },
    submitAddForm() {
      if (
        this.$refs.addFile.uploadfilelist.some(el => {
          return el.arr.length == 0;
        })
      ) {
        this.$message.error("请上传完整的资料");
      } else {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        api
          .submitAddDocument(this.$refs.addFile.subsingle)
          .then(res => {
            if (res.success) {
              this.$alert("补件成功");
              loading.close();
              this.getInfo(this.$route.query.receivableAccountFinanceId);
              this.showAddDialog = false;
            } else {
              this.$message.error(res.message);
              loading.close();
            }
          })
          .catch(err => {
            loading.close();
          });
      }
    },
    goback(){
      history.go(-1)
    },
    getfilename(){
      api.getDicListByType({type:17}).then(res => {
        if(res.success){
          this.refuselist2=res.datas.list
        }
      })
    }
  }
};
</script>
<style lang="scss">
.yfdbaseline {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 18px;
  margin-bottom: 37px;
}
.easypayby {
  padding-bottom: 40px;
  &.goback {
    padding-top: 32px;
  }
}

.yfdtwo {
  padding: 0 42px;
  .el-input.is- .el-input__inner {
    color: rgb(96, 98, 102);
  }
}

.httotal {
  height: 35px;
  background: #F0F7FF;
  border-radius: 4px;
  line-height: 35px;
  padding-left: 17px;
  margin-bottom: 18px;
  color: #828282;
  span {
    color: #da2b2b;
    line-height: 22px;
  }
}
.formstyle {
  & > div {
    margin-bottom: 20px;
  }

  .el-select,
  .el-date-editor {
    width: 100%;
  }
  .elspan {
    .el-input__inner {
      border-radius: 4px 0 0 4px;
    }
    .el-input {
      width: 80%;
    }
    .el-form-item__content {
      span {
        width: 20%;
        display: inline-block;
        height: 40px;
        background: #f7f7f7;
        border: 1px solid #d9d9d9;
        border-radius: 4px 0 0 4px;
        border-radius: 0 4px 4px 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 40px;
        border-left: none;
        position: relative;
        top: 1px;
        text-align: center;
      }
    }
  }
  .pec.elspan {
    .el-input {
      width: 90%;
    }
    .el-form-item__content {
      span {
        width: 10%;
      }
    }
  }
  .el-table__body-wrapper {
    input[type="text"] {
      border: 1px solid #fff;
    }
    .el-table__row {
      input {
        padding: 6px 10px;
      }
    }
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #fff !important;
  }
  .htbox {
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    .htpad {
      padding: 0 17px;

      form {
        overflow: hidden;
      }
    }
    .htactive {
      height: 43px;
      overflow: hidden;
      background: #f1f1f1;
      line-height: 43px;
      span {
        display: inline-block;
        width: 100px;
        margin-left: 17px;
        float: left;
        cursor: pointer;
      }
    }
    .htno {
      height: 90px;
      border-bottom: 1px solid #e6e6e6;
      padding-top: 25px;
    }
    .uploadfont {
      color: rgba(0, 0, 0, 0.45);
      line-height: 42px;
      span {
        text-indent: 8em;
        display: inline-block;
      }
    }
    .uploadlist {
      padding: 30px 16px 0 16px;
      .upload-demo {
        margin-left: 8em;
      }
      .el-form-item {
        margin-bottom: 40px;
      }

      .el-form-item__label {
        font-size: 16px;
        color: #000000;
      }
      .el-upload-dragger {
        width: 236px;
        height: 92px;
      }
      .el-upload-dragger .el-upload__text,
      .el-icon-plus {
        color: rgba(0, 0, 0, 0.45);
      }
      .el-icon-plus {
        font-size: 30px;
      }
    }
    .el-icon-delete {
      float: right;
      padding: 12px 20px 0 0;
      cursor: pointer;
      font-size: 18px;
    }
    .ljje {
      text-align: right;
      margin-bottom: 70px;
      color: #595959;
      span {
        color: #f5212d;
      }
    }
  }
}
.easyformbtn {
  text-align: center;
  button {
    width: 160px;
    height: 40px;
    font-size: 16px;

    text-align: center;
    line-height: 18px;
    span {
      color: #ffffff;
    }
  }
  button:nth-last-child(1) {
    background-color: #2937A6;
  }
  button:nth-last-child(2) {
    span {
      color: #595959;
    }
  }
  button:hover {
    background-color: #edf5ff;
    span {
      color: #67a9fd;
    }
  }
}
.formstyle {
  .active {
    height: 133px;
    overflow: hidden;
  }
  .iconactive {
    transform: scaleY(-1);
  }
}
.addbtn {
  border: 1px dashed #cfcfcf;
  height: 44px;
  text-align: center;
  border-radius: 4px;
  line-height: 44px;
  font-size: 14px;
  color: #828282;
  cursor: pointer;
  i {
    margin-right: 6px;
  }
}
.yfdsize {
  text-indent: 0px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  margin-bottom: 29px;
}
.upload-contentr {
  .el-upload-dragger {
    line-height: 26px;
  }
}
.pt16 {
  position: relative;
  top: -16px;
}
.inlinelv {
  .firlabwid {
    width: calc(25% + 80px);
  }
  .el-form-item {
    width: calc(100% - 20px);
  }
  .perwidth {
    width: 20px;
    padding-left: 0 !important;
    position: relative;
    top: 15px;
  }
  .nolabwid {
    width: calc(25% - 80px);
    .el-form-item__content {
      margin-left: 0 !important;
    }
    .el-form-item__label {
      width: 0 !important;
    }
  }
}
.textindl {
  text-indent: 160px;
}
.red-color {
  color: red;
  .el-form-item__label {
    color: red;
  }
}
</style>
