<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList">
      <el-tabs type="border-card" v-model="activeName" class="n-tabs">
        <data-list
          :filters="filterList"
          :isShowAll="isShowAll"
          :isHedden="isHedden"
          @data-showAll="filterShowAll"
          @data-refresh="handleRefresh"
          :total="total"
          ref="datalist"
          :otherFunc="otherFunc"
        >
          <!--<el-button type="primary" class="btn-doFilter" @click="openDialog">新建</el-button>-->
          <!-- <el-button>批量导入</el-button> -->
          <!--<el-button type="primary" class="btn-doFilter" @click="openDialog">邀请</el-button>-->
          <!-- <el-button class="an">下载导入模板</el-button> -->
          <!--<div class="Tips-title">-->
          <!--<div  class="Tips"></div>-->
          <!--<p>链属企业总 180 家, 已邀请 100 家 ,邀请已注册认证 80 家 </p>-->
          <!--</div>-->
          <el-table
            :data="listData"
            :header-cell-style="headerStyle"
            highlight-current-row
            v-loading="listLoading"
            style="width: 100%"
          >
            <!--<el-table-column  label="全选" type="selection" min-width="170"></el-table-column>-->
            <el-table-column
              prop="corpName"
              label="企业名称"
              min-width="200"
            ></el-table-column>
            <el-table-column
              prop="corpCreditCode"
              label="企业统一信用代码"
              min-width="170"
            ></el-table-column>
            <el-table-column
              prop="contactsName"
              label="联系人"
              min-width="120"
            ></el-table-column>
            <!-- <el-table-column prop="approvalAmount" label="合同编号" min-width="150"></el-table-column> -->
            <el-table-column
              prop="mobile"
              label="手机号码"
              min-width="120"
            ></el-table-column>
            <el-table-column prop="regStatus" label="注册状态" min-width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.regStatus == 1">已注册</span>
                <span v-if="scope.row.regStatus == 2">未注册</span>
              </template>
            </el-table-column>
            <el-table-column prop="authStatus" label="认证状态" min-width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.authStatus == 1">已认证</span>
                <span v-if="scope.row.authStatus == 2">未认证</span>
              </template></el-table-column
            >
            <el-table-column
              prop="flwStSt"
              label="流程状态"
              min-width="120"
              :formatter="flowState"
            >
            </el-table-column>
            <el-table-column
              prop="invitationStatus"
              label="邀请状态"
              min-width="120"
            >
              <template slot-scope="scope">
                <span
                  v-if="scope.row.regStatus == 1 && scope.row.authStatus == 1"
                  >已完成</span
                >

                <span
                  v-if="
                    (scope.row.invitationStatus == 2 ||
                      scope.row.invitationStatus == null) &&
                    !(scope.row.regStatus == 1 && scope.row.authStatus == 1)
                  "
                  >未邀请</span
                >
                <span
                  v-if="
                    scope.row.invitationStatus == 1 &&
                    !(scope.row.regStatus == 1 && scope.row.authStatus == 1)
                  "
                  >已邀请</span
                >
                <span v-if="scope.row.invitationStatus == 3">邀请失败</span>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="status" label="银e券状态" width="100" align="left"></el-table-column> -->
            <el-table-column label="操作" width="230" fixed="right">
              <template slot-scope="scope">
                <el-button
                  size="medium"
                  type="text"
                  :disabled="scope.row.authStatus == 1"
                  @click="editHandle(scope.row)"
                  >修改</el-button
                >
                <el-button
                  size="medium"
                  type="text"
                  :disabled="scope.row.authStatus == 1"
                  @click="deleteHandle(scope.row)"
                  >删除</el-button
                >
                <el-button
                  size="medium"
                  type="text"
                  :disabled="
                    scope.row.regStatus == 1 ||
                    (scope.row.invitationStatus &&
                      scope.row.invitationStatus != '2') ||
                    (!scope.row.invitationStatus &&
                      scope.row.invitationStatus != null)
                  "
                  @click="handleEdit(scope.row)"
                  >邀请入驻</el-button
                >
                <el-button
                  size="medium"
                  type="text"
                  :disabled="
                    scope.row.flwStSt == 'O' || scope.row.flwStSt == 'S'
                  "
                  @click="syncCompany(scope.row)"
                  >同步</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </data-list>
      </el-tabs>
      <el-dialog
        title="链属企业添加白名单"
        :visible.sync="dialogVisible"
        width="600px"
        class="dislog"
      >
        <el-form
          :model="ruleForm"
          status-icon
          ref="ruleForm"
          label-width="150px"
          class="demo-ruleForm"
          :rules="rules"
        >
          <el-form-item label="链属企业名称" prop="corpName">
            <el-input
              v-model="ruleForm.corpName"
              placeholder="请输入链属企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业统一信用代码" prop="corpCreditCode">
            <el-input
              v-model="ruleForm.corpCreditCode"
              placeholder="请输入链属企业统一信用代码"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人姓名" prop="contactsName">
            <el-input
              v-model="ruleForm.contactsName"
              placeholder="请输入联系人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="mobile">
            <el-input
              v-model="ruleForm.mobile"
              placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="fr">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitAdd">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="链属企业批量添加白名单"
        :visible.sync="dialogVisible1"
        width="600px"
        class="dislog"
      >
        <!-- <el-form :model="ruleForm1" status-icon ref="ruleForm1" label-width="150px" class="demo-ruleForm" :rules="rules"> -->
        <!-- <el-form-item label="链属企业白名单" prop="whiteList"> -->
        <el-upload
          ref="upload"
          :action="baseURL + '/web/enterprise/white/submitUploadChain'"
          :before-upload="beforeUp"
          :on-success="uploadOk"
          :on-remove="uploadRm"
          :headers="Authorization"
        >
          <!-- <i  class="el-icon-plus "></i> -->
          <el-button size="small" type="primary"
            ><i class="el-icon-upload2" style="margin-left: 0"></i
            >点击上传</el-button
          >
        </el-upload>
        <!-- </el-form-item> -->
        <!-- </el-form> -->
        <!-- <div class="fr">
          <el-button @click="dialogVisible1 = false"  >取 消</el-button>
          <el-button type="primary" @click="submitAdd1" >确 定</el-button>
        </div> -->
      </el-dialog>
    </div>
  </layout-main-view>
</template>
<script>
import api from '../../api/api.js';
import { baseURL } from '@/api/sys';
import moment from 'moment';

export default {
  data() {
    return {
      Authorization: { Authorization: sessionStorage.token },
      baseURL: baseURL,
      breadcrumblist: ['链属企业管理'],
      ruleForm: {
        corpName: '',
        corpCreditCode: '',
        contactsName: '',
        mobile: '',
      },
      rules: {
        corpName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        corpCreditCode: [
          {
            required: true,
            message: '请输入企业统一信用代码',
            trigger: 'blur',
          },
        ],
        contactsName: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' },
        ],
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
      },
      dialogVisible: false,
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: '企业名称',
          type: 'input',
          field: 'corpName',
          size: 'small',
        },
        {
          label: '联系人: ',
          type: 'input',
          field: 'contactsName',
          size: 'small',
        },
        {
          label: '邀请状态:',
          type: 'select',
          field: 'invitationStatus',
          options: [
            { label: '未邀请', value: '2' },
            { label: '已邀请', value: '1' },
          ],
          size: 'small',
        },
      ],
      activeName: '1',
      total: 0,
      listLoading: false,
      listData: [],
      otherFunc: [
        { text: '+ 新建', func: this.openDialog },
        { text: '+ 批量导入', class: 'upload', func: this.openDialog1 },
        {
          text: '+ 下载导入模版',
          class: 'download',
          func: this.downloadTemplate,
        },
      ],
      pageNo: 1,
      pageSize: 10,
      ruleForm1: {
        whiteList: '',
      },
      dialogVisible1: false,
      flwStatus: [
        {
          label: 'D',
          value: '初始状态',
        },
        {
          label: 'S',
          value: '已提交',
        },
        {
          label: 'J',
          value: '预准入拒绝',
        },
        {
          label: 'O',
          value: '预准入通过',
        },
      ],
    };
  },
  created() {},
  computed: {
    flwStatu() {
      return flwStatus.find((item) => {
        // item.value===
      });
    },
  },
  methods: {
    moment,
    deleteHandle(row) {
      this.$confirm('是否删除该条记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          api.deleteCorpWhite({ corpWhiteId: row.corpWhiteId }).then((res) => {
            if (res.success) {
              // this.$alert(res.message);
              // this.dialogVisible = false;
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
              this.getLists({ pageSize: '10', pageNum: 1 });
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    editHandle(row) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
        this.ruleForm.contactsName = row.contactsName;
        this.ruleForm.corpWhiteId = row.corpWhiteId;
        this.ruleForm.corpCreditCode = row.corpCreditCode;
        this.ruleForm.corpName = row.corpName;
        this.ruleForm.mobile = row.mobile;
      });

      // api.updateCorpWhite(this.ruleForm).then(res => {
      //   if (res.success) {
      //     // this.$alert(res.message);
      //     // this.dialogVisible = false;
      //     // this.getLists({ pageSize: "10", pageNum: 1 });
      //   } else {
      //     // this.$message.error(res.message);
      //   }
      // });
    },
    syncCompany(row) {
      this.$confirm('是否确定同步上下游关系至资金机构?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
      })
        .then(() => {
          api.addToShBank({ corpWhiteId: row.corpWhiteId }).then((res) => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: '同步成功!',
              });
              this.getLists({ pageSize: '10', pageNum: 1 });
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消同步',
          });
        });
    },
    submitAdd() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let userInfo = sessionStorage.getItem('user');
          userInfo =
            _.trim(userInfo) && userInfo != 'undefined' && userInfo != 'null'
              ? JSON.parse(userInfo)
              : {};
          this.ruleForm.corpId = userInfo ? userInfo.corpId : null;
          // 修改
          if (this.ruleForm.corpWhiteId !== '') {
            api.updateCorpWhite(this.ruleForm).then((res) => {
              if (res.success) {
                this.$alert(res.message);
                this.dialogVisible = false;
                this.getLists({ pageSize: '10', pageNum: 1 });
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            api.AddWhiteLits(this.ruleForm).then((res) => {
              if (res.success) {
                this.$alert(res.message);
                this.dialogVisible = false;
                this.getLists({ pageSize: '10', pageNum: 1 });
              } else {
                this.$message.error(res.message);
              }
            });
          }
        }
      });
    },
    openDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.ruleForm = {
          corpName: '',
          corpCreditCode: '',
          contactsName: '',
          mobile: '',
          corpWhiteId: '',
        };
        this.$refs.ruleForm.resetFields();
      });
    },
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    headerStyle({ row, rowIndex }) {
      return { background: '#FAFAFA' };
    },
    handleRefresh(filters) {
      console.log(filters, '=/...>>>');
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      let userInfo = sessionStorage.getItem('user');
      userInfo =
        _.trim(userInfo) && userInfo != 'undefined' && userInfo != 'null'
          ? JSON.parse(userInfo)
          : {};
      param.corpId = userInfo ? userInfo.corpId : null;
      api.GetWhiteLits(param).then((res) => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
        this.listLoading = false;
      });
    },
    handleEdit(row) {
      api.inviteSettledIn({ corpId: row.corpWhiteId }).then((res) => {
        if (res.success) {
          //  this.getLists({ pageSize: "10", pageNum: 1 });
          this.$router.push({ path: this.$route.path });
        }
      });
    },
    downloadTemplate() {
      let params = {
        // payableBillCheckAccountId: 72,
        // accountCorpType: 1,
        // corpName: '白名单模版文件',
      };
      api.downTemplate().then((res) => {
        if (res) {
          const content = res;
          const blob = new Blob([content]);
          const fileName = '白名单模版文件.xlsx';
          if ('download' in document.createElement('a')) {
            // 非IE下载
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        }
      });
    },
    submitAdd1() {},
    openDialog1() {
      this.dialogVisible1 = true;
      this.$nextTick(() => {
        this.$refs.upload.clearFiles();
      });
    },
    //链属企业白名单批量上传
    // fileUpload() {
    //   return api.submitUploadChain()
    // },
    //上传前配置
    beforeUp(file) {
      let excelfileExtend = '.xlsx'; //设置文件格式
      let fileExtend = file.name
        .substring(file.name.lastIndexOf('.'))
        .toLowerCase();
      if (excelfileExtend.indexOf(fileExtend) <= -1) {
        this.$message.error('文件格式错误');
        return false;
      }
    },
    //上传成功
    uploadOk(response) {
      console.log(response, '-----------response');
      if (response.code == '100000') {
        this.$message.success('上传成功');
        this.dialogVisible1 = false;
        this.handleRefresh({ pageSize: '10', pageNum: 1 });
      } else {
        this.$message.error('上传失败');
      }
    },
    //文件删除
    uploadRm(file, fileList) {},

    flowState(val) {
      if (val.flwStSt) {
        return this.flwStatus.find((item) => item.label === val.flwStSt)?.value;
      }
      return '初始状态';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/moreDetail/moudleDetail.scss';
.Tips {
  width: 98%;
  height: 40px;
  line-height: 40px;
  background-color: #2937a6;
  border-radius: 5px;
  /*margin: 20px 0 20px 0;*/
  /*padding-left: 50px;*/
  opacity: 0.1;
  position: absolute;
}
.Tips-title {
  margin-bottom: 20px;
  height: 40px;
  width: 100%;
  // background-image: url("../../assets/gt.png");
  background-repeat: no-repeat;
  background-position: 1.5% 50%;
}
p {
  padding-left: 50px;
  color: #2937a6;
  width: 100%;
  position: relative;
  line-height: 40px;
  /*top:20px*/
}
.el-dialog .el-dialog__header {
  border-bottom: 1px solid #e6e6e6 !important;
}
.demo-ruleForm {
  margin-bottom: 80px;
  margin-top: 20px;
  /*padding-bottom: 20px;*/
  /*margin-bottom: 20px;*/
  /*padding-left: 30px;*/
}
.el-button {
  line-height: 32px;
  height: 32px;
  padding-top: 0;
  padding-bottom: 0;
}
.el-dialog {
  height: 65% !important;
}
.fr {
  position: absolute;
  right: 40px;
  bottom: 20px;
}
.an {
  border: 1px solid transparent; //自定义边框
  outline: none; //消除默认点击蓝色边框效果
  color: #2937a6;
}
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
