<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">

      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="payableBillNo" label="银e券编号" min-width="200"></el-table-column>
          <el-table-column prop="originalName" label="原始开单企业" min-width="150"></el-table-column>
          <el-table-column prop="corpName" label="转让企业" min-width="150"></el-table-column>
          <!--<el-table-column prop="corpIndustrySecondName" label="所属行业" min-width="120"></el-table-column>-->
          <el-table-column label="银e券金额(元)" min-width="150">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.payableAmount)}}</template>
          </el-table-column>
          <el-table-column prop="promisePayTime" label="承诺付款日" min-width="130">
            <template slot-scope="scope">{{ scope.row.promisePayTime | formatDate }}</template>
          </el-table-column>
          <el-table-column prop="createTime" label="开立日" min-width="130">
            <template slot-scope="scope">{{ scope.row.createTime | formatDate }}</template>
          </el-table-column>
          <!--<el-table-column prop="confirmTimeLimit" label="确认时限" width="180">-->
            <!--<template slot-scope="scope">-->
              <!--<span v-if="scope.row.confirmTimeLimit == 24">自审批完毕24小时</span>-->
              <!--<span v-if="scope.row.confirmTimeLimit == 48">自审批完毕48小时</span>-->
              <!--<span v-if="scope.row.confirmTimeLimit == 72">自审批完毕72小时</span>-->
            <!--</template>-->
          <!--</el-table-column>-->
          <el-table-column prop="remainTimeLimit" label="剩余接收时限(时)" width="170">
            <template slot-scope="scope">
              <span v-if="scope.row.remainTimeLimit == 100">已确认</span>
              <span v-else>{{scope.row.remainTimeLimit}}</span>
              <!--<span v-if="scope.row.remainTimeLimit == 2">未注册</span>-->
            </template>
          </el-table-column>
          <el-table-column prop="payableBillApproveStatusName" label="银e券状态" min-width="120">
            <!--<template slot-scope="scope">-->
            <!--<span v-if="scope.row.payableBillApproveStatus == 25">接收审核</span>-->
            <!--<span v-if="scope.row.payableBillApproveStatus == 35">平台审核</span>-->
            <!--</template>-->
          </el-table-column>
          <el-table-column label="操作" width="130" align="left">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="preview(scope.row)">预览</el-button>
              <el-button size="medium" type="text" @click="handleEdit(scope.row)">{{scope.row.remainTimeLimit == 100 || scope.row.payableBillApproveStatusName!='接收审核'?'详情':'接收'}}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
      <el-dialog title :visible.sync="centerDialogVisible" width="80%" center>
        <!-- <span>需要注意的是内容是默认不居中的</span> -->
        <pdf class="pdf" ref="pdf" :src="src"></pdf>
        <span slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-tabs>
  </div>
</template>
<script>
import api from "../../api/api.js";
import pdf from "vue-pdf";
import { changeMoneyThousand } from "@/util/common";
import moment from 'moment'


export default {
  components: {
    pdf
  },
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: "银e券编号",
          type: "input",
          field: "payableBillNo",
          size: "small"
        },
        {
          label: "转让企业名称",
          type: "input",
          field: "corpName",
          size: "small"
        },
        // {
        //   label: "确认时限:",
        //   type: "select",
        //   field: "receivedCorpAck",
        //   options: [
        //     { label: "自审批完毕24小时", value: "0" },
        //     { label: "自审批完毕48小时", value: "1" },
        //     { label: "自审批完毕72小时", value: "2" },
        //     { label: "无时限", value: "3" },
        //     { label: "全部", value: "" }
        //   ],
        //   size: "small"
        // },
        // {
        //   label: "银e券状态:",
        //   type: "select",
        //   field: "payableBillApproveStatusName",
        //   options: [
        //     { label: "外审审核", value: "25" },
        //     { label: "平台审核", value: "1" },
        //     { label: "资方审核", value: "2" },
        //     { label: "全部", value: "" }
        //   ],
        //   size: "small"
        // },
        {
          label: "承诺付款日:",
          type: "daterange",
          field: ["promisePayTimeStr", "promisePayTimeEnd"],
          size: "small"
        }
      ],
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      centerDialogVisible: false,
      api: api,
      row: {},
      changeMoneyThousand:changeMoneyThousand,
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      fileType: "pdf", // 文件类型
      src: "" // pdf文件地址
    };
  },
  created() {},
  methods: {
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      console.log("param ====>", param);
      param.corpId = "1";
      api.ReceiveList(param).then(res => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
          console.log("list ===>", this.listData);
        }
        this.listLoading = false;
      });
    },
    handleEdit(row) {
     var checkbtn=row.payableBillApproveStatusName=='接收审核'?1:2
     if(row.payableBillNo.indexOf('-')!=-1){
                this.$router.push({
        name: "银e券流转子单详情",
        query: { id: row.payableBillId, isshowbtn: 1,checkbtn:checkbtn ,flag2:true}
      });
     }else{
          this.$router.push({
        name: "银e券流转详情",
        query: { id: row.payableBillId, isshowbtn: 1,checkbtn:checkbtn ,flag2:true}
      });
     }

    },
    preview(row) {
       window.open(api.getImgURL(row.evidencePath))
      // this.listLoading = true;
      // api.DownloadContract({ contractNo: row.contractNo }).then(res => {
      //   var binaryData = [];
      //   console.log(res,'res')
      //   binaryData.push(res);
      //   this.src = window.URL.createObjectURL(
      //     new Blob(binaryData, { type: "application/zip" })
      //   );
        // console.log(this.src,'src')
        // this.centerDialogVisible = true;
        // this.listLoading = false;
      // });
    }
  }
};
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
