<template>
    <div class="common-page-dataList">
        <!-- 历史额度页面内容。。 -->
        <el-tabs type="border-card" v-model="activeName" class="n-tabs">
            <data-list
                :filters="filterList"
                :isShowAll="isShowAll"
                :isHedden="isHedden"
                @data-showAll="filterShowAll"
                @data-refresh="handleRefresh"
                :total="total"
                ref="datalist"
            >
                <el-table
                :data="listData"
                :header-cell-style="headerStyle"
                highlight-current-row
                v-loading="listLoading"
                style="width: 100%;"
                >
                    <el-table-column prop="grantCreditNo" label="授信编号" min-width="140"></el-table-column>
                    <el-table-column prop="grantCorpName" label="授信企业" min-width="250" />
                    <!-- <el-table-column prop="payableBillAmount" label="银e券金额(元)" min-width="170" align='right'></el-table-column> -->
                    <el-table-column prop="availableAmount" label="授信金额(元)"  min-width="140">
                        <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.availableAmount)}}</template>
                    </el-table-column>
                    <el-table-column
                      prop="closeDate"
                      label="到期日期"
                      min-width="140"
                      :formatter="el=>moment(el.closeDate).format('YYYY-MM-DD')"
                    />
                    <!-- <el-table-column  label="到期日期" min-width="200">
                        <template slot-scope="scope">
                            {{moment(scope.row.effectiveTime).format('YYYY-MM-DD')}}-{{moment(scope.row.closeDate).format('YYYY-MM-DD')}}
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="fundYearInterest" label="年化利率" min-width="120">
                        <template slot-scope="scope">
                            {{scope.row.fundYearInterest}}%
                        </template>
                    </el-table-column>
                    <el-table-column prop="usedAmount" label="总使用额度(元)"  min-width="160" >
                         <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.usedAmount)}}</template>
                    </el-table-column>

                    <!--  已用授信额度 usedAmount    剩余授信额度 remainAmount    授信总额度 availableAmount  -->
                    <!-- <el-table-column prop="remainAmount" label="授信使用率" min-width="120"/> -->
                    <el-table-column  label="授信使用率" prop="creditGrantingRate"  min-width="150" >
                        <template slot-scope="scope">
                            {{scope.row.creditGrantingRate}}%
                        </template>
                    </el-table-column>


                    <el-table-column prop="financeAmount" label="总融资金额（元)"  min-width="160" >
                         <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.financeAmount)}}</template>
                    </el-table-column>

                    <el-table-column prop="financeRate" label="融资比率" min-width="120">
                        <template slot-scope="scope">
                            {{scope.row.financeRate}}%
                        </template>
                    </el-table-column>
                </el-table>
            </data-list>


            
        </el-tabs>
    </div>
</template>

<script>
import api from "@/api/api";
import moment from 'moment';

export default {
    data(){
        return{
            isShowAll: false,
            isHedden: false,
            optionsList:[],

            total: 0,
            activeName: "1",


            listData:[],
            listLoading: false,
            
        }
    },
    computed: {
        message(){
            return this.optionsList.map((el,index)=>{
                return {label:el.corpName, value:el.corpId}
            })
        },

        filterList(){
            return [
                    {
                        label: "授信企业:",
                        type: "selectSearch",
                        field: "grandCorpId",
                        size: "small",
                        options:this.message
                    },
                    {
                        label: "到期日期",
                        type: "daterange",
                        field: ["closeDateStr","closeDateEnd"],
                        size: "small"
                    },
                    // {
                    //     label: "授信金额: ",
                    //     type: "inputrange",
                    //     field: "endPayableBillAmount",
                    //     size: "small",
                    // },
                ]
        } 
    },
    props:{
        // optionsList:[]
    },
    created(){
        this.getCorpList();
    },
    methods:{
        getCorpList(){
            let para = {
                tableType:2
            }
            api.getFundList(para).then((res)=>{
                if(res.success){
                    this.optionsList = res.datas.list;
                }
            })
        },
        moment: moment,
        filterShowAll() {
            this.isShowAll = !this.isShowAll;
        },
        handleRefresh(filters) {
            this.getLists(filters);
        },
        headerStyle({ row, rowIndex }) {
            return { background: "#FAFAFA" };
        },
        getLists(param){
            let data = Object.assign({
                tableType:2
            }, param)

            api.getFundListByPage(data).then((res)=>{
                this.listLoading = true;
                console.log(res);
                if(res.success){
                    this.listData = res.datas.list;
                    this.total = res.datas.total;
                    this.listLoading = false;
                }else{
                    this.$message({
                        message:res.message,
                        type: "error"
                    })
                }
                
            })
        },
    }
}
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>