<template>
  <div class="content add-apply">
    <div class="content-inner">
      <section class="info-card-A" style="height: calc(100vh - 150px)">
        <div class="title txbst">授信申请</div>
        <div class="bottom">
          <span class="txbst">基本信息</span>
        </div>
        <div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleform"
            label-width="auto"
            class="demo-ruleForm"
            label-position="left"
          >
            <el-row class="row">
              <el-col :span="6" class="lb_body">
                <el-form-item label="授信产品：" prop="product">
                  <span>{{ ruleForm.product }}</span>
                  <el-input
                    v-model="ruleForm.product"
                    v-show="false"
                    type="text"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10" class="lb_body">
                <!-- <el-form-item label="资金机构：" prop="fundCorpName">
                  <span>{{ ruleForm.fundCorpName }}</span>
                </el-form-item> -->
                <el-form-item label="资金机构：" prop="fundCorp">
                  <!-- <el-select
                    @change="handelFundCorpList"
                    v-model="ruleForm.fundCorp"
                    filterable
                    placeholder="请选择"
                    value-key="id"
                  >
                    <el-option
                      v-for="item in fundCorpList"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                    >
                    </el-option>
                  </el-select> -->
                  <el-input
                    disabled
                    v-model="ruleForm.fundCorp"
                    style="width: 90%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="lb_body">
                <el-form-item label="授予企业：" prop="grantCorp">
                  <el-select
                    @change="handelGrantCorpList"
                    v-model="ruleForm.grantCorp"
                    filterable
                    placeholder="请选择"
                    value-key="id"
                  >
                    <el-option
                      v-for="item in grantCorpList"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="lb_body">
                <el-form-item label="授信额度（元）：" prop="availableAmount">
                  <el-input
                    style="width: 90%"
                    v-model="ruleForm.availableAmount"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="lb_body">
                <el-form-item label="授信有效期：" prop="validDate">
                  <el-date-picker
                    style="width: 90%"
                    v-model="ruleForm.validDate"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    @change="changeSlect"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="lb_body">
                <el-form-item
                  label="供应商融资限额(元)："
                  prop="maxFinanceAmount"
                >
                  <el-input
                    style="width: 90%"
                    v-model="ruleForm.maxFinanceAmount"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="lb_body">
                <el-form-item label="资金用途：" prop="fundUsePurpose">
                  <el-select
                    v-model="ruleForm.fundUsePurpose"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in purposeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="lb_body">
                <el-form-item label="资金年化利率(%)：" prop="fundYearInterest">
                  <el-input
                    style="width: 90%"
                    v-model="ruleForm.fundYearInterest"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10" class="lb_body">
                <el-form-item
                  label="融资年化利率(%)："
                  prop="financeYearInterest"
                >
                  <el-input
                    style="width: 90%"
                    v-model="ruleForm.financeYearInterest"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="lb_body">
                <el-form-item label="银e券清分方式：" prop="sorting">
                  <span>直接清分</span>
                  <!-- <el-radio-group v-model="ruleForm.sorting">
                    <el-radio label="2" border>直接清分</el-radio>
                  </el-radio-group> -->
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="6" class="lb_body">
                <el-form-item label="授信协议编号：" prop="creditAgreementNo">
                  <el-input
                    style="width: 90%"
                    v-model="ruleForm.creditAgreementNo"
                    @input="validateInput"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10" class="lb_body">
                <el-form-item
                  label="融资收款账户类型："
                  prop="financeReceiveAccountType"
                >
                  <el-radio-group v-model="ruleForm.financeReceiveAccountType">
                    <el-radio @change="changeType" label="1"
                      >需本机构银行账户</el-radio
                    >
                    <el-radio @change="changeType" label="0">无要求</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col v-if="showBank" :span="8" class="lb_body">
                <el-form-item label="融资收款卡银行：" prop="bankGroup">
                  <el-select
                    :disabled="ruleForm.financeReceiveAccountType == 0"
                    v-model="ruleForm.bankGroup"
                    placeholder="请选银行"
                    @change="selectBank"
                    filterable
                    value-key="id"
                  >
                    <el-option
                      v-for="item in bankList"
                      :key="item.value"
                      :label="item.name"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="subtitle">
          <span class="txbst1">授信流转设置</span>
        </div>
        <el-form
          :model="ruleForm2"
          :rules="rules2"
          label-width="140px"
          class="demo-ruleForm"
          label-position="left"
        >
          <el-form-item label="资方清分审核：" prop="fundClear">
            <el-switch
              v-model="ruleForm2.fundClear"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
            <span class="switch-text"
              >设置开则资金机构需要进行清分审核，设置关则不需要进行审核。</span
            >
          </el-form-item>
          <el-form-item label="资金审查：" prop="fundCheck">
            <el-switch
              v-model="ruleForm2.fundCheck"
              :active-value="1"
              :inactive-value="2"
            ></el-switch>
            <span class="switch-text"
              >设置打开则使用该授信开立银e券需要资方参与资料审查</span
            >
          </el-form-item>
        </el-form>
        <div class="subtitle">
          <span class="txbst1">结算渠道设置</span>
        </div>
        <el-form
          :model="ruleForm3"
          :rules="rules3"
          label-width="152px"
          class="demo-ruleForm"
          label-position="left"
        >
          <el-row class="">
            <el-col :span="8" class="lb_body">
              <el-form-item label="收款方式：" prop="settlementChannel">
                <el-select
                  @change="handelSettlementChannel"
                  v-model="ruleForm3.settlementChannel"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in settlementChannelList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="8" class="lb_body">
              <el-form-item label="清分收款账户选择：" prop="accountGroup">
                <el-select
              @change="handelAccountChange"
              v-model="ruleForm3.accountGroup"
              placeholder="请选择"
              value-key="id"
            >
              <el-option
                v-for="item in accountList"
                :key="item.id"
                :label="`${item.openBank}  ${item.accountNo}`"
                :value="item"
              >
              </el-option>
            </el-select>
              </el-form-item>
            </el-col> -->
          </el-row>
          <!-- <el-form-item v-if="isShow1" label="机构还款方式：" prop="repaymentType">
        <el-radio-group v-model="ruleForm3.repaymentType" @change="selectRepaymentType" >
          <el-radio label="1" border>虚拟账户</el-radio>
          <el-radio label="2" border>对公账户</el-radio>
        </el-radio-group>
      </el-form-item> -->
          <el-row class="row" v-if="accountShow">
            <el-col :span="8" class="lb_body">
              <el-form-item label="收款账户：">
                {{ ruleForm3.accountName }}
              </el-form-item>
            </el-col>
            <el-col :span="8" class="lb_body">
              <el-form-item label="开户行：">
                {{ ruleForm3.openBank }}
              </el-form-item>
            </el-col>
            <el-col :span="8" class="lb_body">
              <el-form-item label="收款账号：">
                {{ ruleForm3.accountNo }}
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="subtitle">
          <span class="txbst1"
            >授信协议上传（支持扩展名：.doc .docx .pdf .jpg ...）</span
          >
        </div>
        <el-form
          label-width="140px"
          class="demo-ruleForm"
          label-position="left"
        >
          <el-form-item label="">
            <template slot="label"> 授信三方协议： </template>
            <el-upload
              ref="uploadFile"
              class="upload-demo"
              :action="baseURL + '/web/file/uploadFile'"
              :headers="Authorization"
              list-type="picture"
              :on-success="handleAvatarSuccess"
              :on-remove="handleRemove"
              :on-preview="handlePreview"
              :before-upload="beforeAvatarUpload"
              :file-list="fileList"
            >
              <el-button size="small"
                ><i class="el-icon-upload2" style="margin-left: 0"></i>
                上传文件</el-button
              >
            </el-upload>
          </el-form-item>
        </el-form>
      </section>
      <footer class="footer">
        <el-button @click="$router.go(-1)">返回</el-button>
        <!-- <el-button type="primary" @click="save">保存</el-button> -->
        <el-button type="primary" @click="submit">提交</el-button>
      </footer>
    </div>
  </div>
</template>

<script>
import upload from "./upload";
import api from "@/api/api";
import { baseURL } from "@/api/sys";
import moment from "moment";
export default {
  name: "addCreditApply",
  components: {
    upload,
  },
  data() {
    return {
      bankList: [],
      accountShow: false,
      grantCheck: this.$route.query.grantCheck,
      grantAgreementFileUrl: [],
      fileList: [],
      loading: {},
      accountCloseDate: "",
      baseURL,
      Authorization: { Authorization: sessionStorage.token },
      ruleForm: {
        grantCorp: {},
        bankGroup: {},
        fundCorp: "",
        creditCorp: "",
        availableAmount: null,
        maxFinanceAmount: null,
        fundUsePurpose: "",
        fundYearInterest: "",
        financeYearInterest: "",
        sorting: "2",
        creditAgreementNo: "",
        fundCheck: "",
        product: "银e券",
        creditCorp: "",
        // fundCorpName: "",
        validDate: "",
        creditType: 2,
        financeReceiveAccountType: "",
        financeReceiveBankId: "",
        financeReceiveBankName: "",
      },
      ruleForm2: {
        fundClear: "",
        fundCheck: "",
      },
      ruleForm3: {
        settlementChannel: "0",
        repaymentType: "",
        accountName: "",
        openBank: "",
        corpAccountPublicId: "",
        fundReceiveAccountId: "",
        fundReceiveAccount: "",
        accountGroup: {},
      },
      ruleForm4: {},
      rules: {
        bankGroup: [
          {
            required: true,
            message: "请选择融资收款卡银行",
            trigger: "change",
          },
          { validator: this.validateList },
        ],
        grantCorp: [
          { required: true, message: "请选择被授信企业", trigger: "change" },
          { validator: this.validateList },
        ],
        fundCorp: [
          { required: true, message: "请选择资金机构", trigger: "change" },
        ],
        financeReceiveAccountType: [
          {
            required: true,
            message: "请选择融资收款账户类型",
            trigger: "change",
          },
        ],
        //  financeReceiveBankId: [
        //   { required: true, message: "请选择融资收款卡银行", trigger: "change" },
        // ],
        guaranteeCorp: [
          { required: true, message: "请选择担保方", trigger: "change" },
          { validator: this.validateList },
        ],
        guaranteeCode: [
          {
            required: true,
            message: "请填写授信担保协议编号",
            trigger: "blur",
          },
        ],
        payableGuaranteeCode: [
          {
            required: true,
            message: "请填写银e券担保协议编号",
            trigger: "blur",
          },
        ],
        availableAmount: [
          { required: true, message: "请填写授信额度", trigger: "blur" },
          {
            max: 18,
            pattern: /^(([1-9]{1}\d*)|(0{1}))(.\d{1,2})?$/,
            message: "请输入合法的数字",
            trigger: "blur",
          },
        ],
        maxFinanceAmount: [
          {
            required: true,
            message: "请填写供应商融资限额",
            trigger: "change",
          },
          {
            max: 18,
            pattern: /^(([1-9]{1}\d*)|(0{1}))(.\d{1,2})?$/,
            message: "请输入合法的数字",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              console.log("value", value, this);
              if (+value > +this.ruleForm.availableAmount) {
                callback(new Error("供应商融资限额不能大于授信额度"));
              } else {
                callback();
              }
            },
          },
        ],
        validDate: [
          { required: true, message: "请选择授信有效期", trigger: "change" },
        ],
        // fundYearInterest: [
        //   { required: true, message: "请填写资金年化利率", trigger: "blur" },
        //   {
        //     max: 18,
        //     pattern: /(^(\d|[1-9]\d)(\.\d{1,2})?$)|(^100$)/,
        //     message: "请输入合法的数字",
        //     trigger: "blur",
        //   },
        // ],
        financeYearInterest: [
          { required: true, message: "请填写融资年化利率", trigger: "blur" },
          // {
          //   max: 18,
          //   pattern: /(^(\d|[1-9]\d)(\.\d{1,2})?$)|(^100$)/,
          //   message: "请输入合法的数字",
          //   trigger: "blur",
          // },
          { validator: this.validate },
        ],
        platformServiceInterest: [
          { required: true, message: "请填写技术服务费率", trigger: "blur" },
          {
            max: 18,
            pattern: /(^(\d|[1-9]\d)(\.\d{1,2})?$)|(^100$)/,
            message: "请输入合法的数字",
            trigger: "blur",
          },
        ],
        informationServiceInterest: [
          { required: true, message: "请填写信息咨询费率", trigger: "blur" },
          {
            max: 18,
            pattern: /(^(\d|[1-9]\d)(\.\d{1,2})?$)|(^100$)/,
            message: "请输入合法的数字",
            trigger: "blur",
          },
        ],
        fundUsePurpose: [
          { required: true, message: "请选择资金用途", trigger: "change" },
        ],
        // creditAgreementNo: [
        //   { required: true, message: "请填写授信协议编号", trigger: "blur" },
        // ],
        repaymentAccount: [
          { required: true, message: "请选择机构还款账户", trigger: "change" },
        ],
        // sorting: [
        //   { required: true, message: "请选择银e券清分方式", trigger: "change" },
        // ],
        repaymentType: [
          { required: true, message: "请选择机构还款方式", trigger: "change" },
        ],
        creditCorp: [
          { required: true, message: "请选择授予企业", trigger: "change" },
        ],
        fundCorpName: [{ required: true, trigger: "change" }],
        product: [{ required: true, trigger: "change" }],
      },
      rules2: {},
      rules3: {
        // corpAccountPublicId:[
        //   {required:true,message:"请选择收款账户",trigger:"change"}
        // ]
        accountGroup: [
          { required: true, message: "请选择清分收款账户", trigger: "change" },
          { validator: this.validateList },
        ],
      },
      rules4: {},
      purposeList: [
        { label: "支付货款", value: 1 },
        { label: "贸易交易", value: 2 },
        { label: "企业经营", value: 3 },
        { label: "其他", value: 4 },
      ],
      creditCorpList: [
        {
          id: 1,
          name: "tencent",
        },
      ],
      settlementChannelList: [],
      accountList: [],
      grantCorpList: [],
      fundCorpList: [],
      excelfileExtend: ".jpg,.png,.jpeg,.bmg,.gif",
      corpId: "",
      showBank: false,
    };
  },
  created() {
    this.ruleForm.fundCorpId = JSON.parse(
      sessionStorage.getItem("corpList")
    ).corpId;
    this.ruleForm.fundCorp = JSON.parse(
      sessionStorage.getItem("corpList")
    ).corpName;
    console.log(this.ruleForm.fundCorp, "this.ruleForm.fundCorp");
    this.getDicListByType();
    this.getCorpList(1, this.fundCorpList); // 获取资金机构列表
    this.selectCreditCorp(5, this.grantCorpList); // 获取被授信企业
    this.getCorpAccountPublicListByCorpId(true);
    console.log(this.$route.query.row, "grantCheck");
    if (this.grantCheck == 10 || this.grantCheck == 26) {
      this.ruleForm.availableAmount = this.$route.query.row.availableAmount;
      this.ruleForm.fundUsePurpose = this.$route.query.row.fundUsePurpose;
      this.ruleForm.fundYearInterest = this.$route.query.row.fundYearInterest;
      this.ruleForm.financeYearInterest =
        this.$route.query.row.financeYearInterest;
      // this.ruleForm.sorting = this.$route.query.row.sorting;
      this.ruleForm.creditAgreementNo = this.$route.query.row.creditAgreementNo;
      this.ruleForm2.fundClear = this.$route.query.row.fundClear;
      this.ruleForm2.fundCheck = this.$route.query.row.fundCheck;
      this.ruleForm3.settlementChannel =
        this.$route.query.row.settlementChannel;
      this.ruleForm3.accountName = this.$route.query.row.accountName;
      this.ruleForm.financeReceiveBankName =
        this.$route.query.row.financeReceiveBankName;
      this.GetOpenBank();
      this.ruleForm.financeReceiveBankId =
        this.$route.query.row.financeReceiveBankId;
      this.getCorpAccountPublicListByCorpId();
      this.ruleForm.financeReceiveAccountType = String(
        this.$route.query.row.financeReceiveAccountType
      );
      if (this.ruleForm.financeReceiveAccountType == 1) {
        this.showBank = true;
      }
      let effectiveTime = moment(this.$route.query.row.effectiveTime).format(
        "YYYY-MM-DD"
      );
      let closeDate = moment(this.$route.query.row.closeDate).format(
        "YYYY-MM-DD"
      );
      this.ruleForm.validDate = [effectiveTime, closeDate];
      this.ruleForm3.accountGroup = {
        id: this.$route.query.row.fundReceiveAccountId,
        accountNo: this.$route.query.row.fundReceiveAccount,
        code: this.$route.query.row.fundReceiveAccountId,
      };
      this.ruleForm.bankGroup = {
        name: this.$route.query.row.financeReceiveBankName,
        value: this.$route.query.row.financeReceiveBankId,
        id: this.$route.query.row.financeReceiveBankId,
      };
      console.log(this.accountList, "acccco");
      // this.accountList.forEach((_) => {
      //   if (this.ruleForm.fundReceiveAccountId == _.code) {
      //     this.ruleForm.accountName = _.accountName;
      //     this.ruleForm.accountNo = _.accountNo;
      //     this.ruleForm.openBank = _.openBank;
      //     this.ruleForm.fundReceiveAccountId = _.id;
      //     this.ruleForm.fundReceiveAccount = _.accountNo;
      //   }
      // });
      this.ruleForm.grantCorp = {
        id: this.$route.query.row.corpCreId,
        name: this.$route.query.row.grantCorpName,
      };
      let fileList = this.$route.query.row.grantAgreementFileUrl
        ? this.$route.query.row.grantAgreementFileUrl.split(",")
        : [];
      this.getFile(fileList, this.fileList, "授信三方协议");
      console.log(this.$route.query.row, "fileList");
      console.log(this.ruleForm, "ruleform");
    }
  },
  watch: {
    immediate: true,
    "ruleForm.financeReceiveAccountType": {
      handler() {
        if (this.ruleForm.financeReceiveAccountType == 0) {
          this.ruleForm.financeReceiveBankName = "";
          this.ruleForm.financeReceiveBankId = "";
        }
      },
    },
  },
  methods: {
    validate(rule, value, callback) {
      let reg = /(^(\d|[1-9]\d)(\.\d{1,2})?$)|(^100$)/;
      if (value <= 0) {
        callback(new Error("请输入合法的数字"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入合法的数字"));
      } else {
        callback();
      }
    },
    selectBank(e) {
      this.bankList.map((_) => {
        if (e == _.value) {
          this.ruleForm.financeReceiveBankName = _.name;
          this.ruleForm.financeReceiveBankId = _.value;
          this.ruleform.bankGroup.push({
            id: _.value,
            name: _.name,
            value: _.value,
          });
        }
      });
      console.log(
        this.ruleForm.financeReceiveBankName,
        this.ruleForm.financeReceiveAccountType,
        "------"
      );
    },
    changeType(e) {
      console.log(e, "ttttttttttttttttt");
      console.log(this.ruleForm, "this.ruleForm.financeReceiveBankName");
      if (e == 1) {
        this.showBank = true;
        this.GetOpenBank();
      } else {
        this.showBank = false;
        this.bankList = [];
        this.ruleForm.financeReceiveBankName = "";
        this.ruleForm.financeReceiveBankId = "";
        this.ruleForm.bankGroup = {};
      }
    },
    GetOpenBank() {
      api
        .GetOpenBank()
        .then((res) => {
          if (res.success) {
            res.datas.corpBankList.map((_) => {
              this.bankList.push({
                name: _.name,
                value: _.bankId,
                id: _.bankId,
              });
            });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {});
    },
    getCorpAccountPublicListByCorpId(flag) {
      let params = {
        corpId: this.ruleForm.fundCorpId,
      };
      api.getCorpAccountPublicListByCorpId(params).then((res) => {
        if (res.success) {
          let list = res.datas.corpAccountPublicList;
          //  if(list.length == 0){
          //   this.$message.error("该资金机构暂未添加企业对公账户，请联系资金方添加后再进行此操作")
          //   return false
          // }
          this.accountList = [];
          // this.ruleForm3 = res.datas.corpAccountPublicList
          list.forEach((_) => {
            this.accountList.push({
              name: _.accountName,
              code: _.corpAccountPublicId,
              id: _.corpAccountPublicId,
              accountName: _.accountName,
              accountNo: _.accountNo,
              openBank: _.openBank,
              fundReceiveAccountId: _.corpAccountPublicId,
              fundReceiveAccount: _.accountNo,
            });
          });
          this.$nextTick(() => {
            this.handelAccountChange(this.ruleForm3.accountGroup, flag);
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    validateInput(value) {
      if (!value) {
        this.ruleForm.creditAgreementNo = "";
      }
    },
    changeSlect() {
      console.log(this.ruleForm.validDate, "this.ruleForm.validDate");
    },
    handlePreview(file) {
      console.log(file, "file");
      if (file.response) {
        window.open(api.getImgURL(file.response.datas.uploadDate.message));
      } else {
        // 回显的数据
        window.open(file.trueUrl);
      }
    },
    // 文件回显
    getFile(list, expectList, name) {
      if (list.length > 0) {
        list.forEach((v) => {
          let filetype = v.substring(v.lastIndexOf(".")).toLowerCase();
          let temp = {};
          temp = {
            name: "授信三方协议",
            url:
              this.excelfileExtend.indexOf(filetype) == -1 &&
              filetype !== ".pdf"
                ? require("@/assets/zip.png")
                : filetype == ".pdf"
                ? require("@/assets/file.png")
                : api.getImgURL(v), // 缩略图
            trueUrl: api.getImgURL(v), // 真实的文件地址
          };
          expectList.push(temp);
        });
      }
    },
    validateList(rule, value, callback) {
      console.log(rule, "rule");
      let name = "";
      if (rule.field === "grantCorp") {
        name = "被授信企业";
      } else if (rule.field === "accountGroup") {
        name = "清分收款账户";
      } else if (rule.field === "bankGroup") {
        name = "融资收款卡银行";
      }
      if (value.id || value.id == 0) {
        callback();
      } else {
        callback(new Error("请选择" + name));
      }
    },
    beforeAvatarUpload(file) {
      // let excelfileExtend = ".jpg,.txt,.pdf,.png,.jpeg,.bmg,.zip"; //设置文件格式
      // let fileExtend = file.name
      //   .substring(file.name.lastIndexOf("."))
      //   .toLowerCase();
      // if (excelfileExtend.indexOf(fileExtend) <= -1) {
      //   this.$message.error("文件格式错误");
      //   return false;
      // }
      //判断文件大小 2M 以内
      const isLt2M = file.size / 1024 / 1024 < 10;
      console.log("file =======>", file);
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 10MB!");
        return false;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.loading = loading;
    },
    handleAvatarSuccess(res, file, fileList) {
      if (res.success) {
        this.$message.success("上传成功");
        this.fileList = fileList;
        console.log(this.fileList, "handleAvatarSuccess");
        let name = file.name;
        let filetype = name.substring(name.lastIndexOf(".")).toLowerCase();
        if (
          this.excelfileExtend.indexOf(filetype) == -1 &&
          filetype !== ".pdf"
        ) {
          file.url = require("@/assets/zip.png");
        } else if (filetype == ".pdf") {
          file.url = require("@/assets/file.png");
        }
      } else {
        console.log(this.fileList, "handleAvatarSuccess大撒大撒");
        this.$message.error(res.message);
        let uid = file.uid; // 关键作用代码，去除文件列表失败文件
        let index = this.$refs.uploadFile.fileList.length;
        let idx = this.$refs.uploadFile.fileList.findIndex(
          (item) => item.uid === uid
        ); // 关键作用代码，去除文件列表失败文件（fileList为el-upload中的ref值）
        console.log(idx, "=======", this.$refs.uploadFile.fileList);
        this.$refs.uploadFile.fileList.splice(index, 1); // 关键作用代码，去除文件列表失败文件
      }
      this.loading.close();
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      console.log(this.fileList, "fileList");
    },
    save() {
      if (this.fileList.length > 0 && !this.fileList.trueUrl) {
        this.fileList.map((_) => {
          if (!_.trueUrl) {
            this.grantAgreementFileUrl.push(
              _.response.datas.uploadDate.message
            );
          } else {
            this.grantAgreementFileUrl.push(_.trueUrl.split("=")[1]);
          }
        });
      }
      // if(!this.ruleForm3.fundReceiveAccountId){
      //   this.$message.error("请选择清分收款账户！")
      //   return false
      // }
      console.log(this.grantAgreementFileUrl, "grantAgreementFileUrl");
      this.$refs.ruleform.validate((valid) => {
        if (valid) {
          this.handleParams();
          this.ruleForm.availableAmount = Number(this.ruleForm.availableAmount);
          this.ruleForm.maxFinanceAmount = Number(this.ruleForm.maxFinanceAmount);
          //  validDate,
          const { financeReceiveAccountType, repaymentType, ...result } =
            this.ruleForm;
          let params = {
            ...result,
            ...this.ruleForm2,
            ...this.ruleForm3,
            financeReceiveAccountType: Number(
              this.ruleForm.financeReceiveAccountType
            ),
            effectiveTime: this.ruleForm.validDate[0],
            closeDate: this.ruleForm.validDate[1],
            grantAgreementFileUrl: this.grantAgreementFileUrl.join(","),
            grantSource: 1,
          };
          if (this.$route.query.grantCreditId) {
            params.grantCreditId = this.$route.query.grantCreditId;
          }
          console.log(params, "params");
          api.addGrantCredit(params).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.grantAgreementFileUrl = [];
              // this.$router.go(-1);
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    handleParams() {
      console.log(this.ruleForm, "ruleForm");
      this.ruleForm.grandCorpId = this.ruleForm.grantCorp.id;
      this.ruleForm.grantCorpName = this.ruleForm.grantCorp.name;
      this.ruleForm.financeReceiveBankId = this.ruleForm.bankGroup.value;
      this.ruleForm.financeReceiveBankName = this.ruleForm.bankGroup.name;
      // this.ruleForm.fundCorpId = this.ruleForm.fundCorp.id;
      this.ruleForm.fundCorpName = this.ruleForm.fundCorp;
      this.ruleForm.effectiveTime = this.ruleForm.validDate[0];
      this.ruleForm.closeDate = this.ruleForm.validDate[1];
      // this.ruleForm.fundCorpType = this.ruleForm.fundCorp.type; //资方添加type,以便编辑时控制资方清分按钮
    },
    submit() {
      if (this.fileList.length > 0 && !this.fileList.trueUrl) {
        this.fileList.map((_) => {
          if (!_.trueUrl) {
            this.grantAgreementFileUrl.push(
              _.response.datas.uploadDate.message
            );
          } else {
            this.grantAgreementFileUrl.push(_.trueUrl.split("=")[1]);
          }
        });
      }
      // if(!this.ruleForm3.fundReceiveAccountId){
      //   this.$message.error("请选择清分收款账户！")
      //   return false
      // }
      this.$refs.ruleform.validate((valid) => {
        if (valid) {
          this.handleParams();
          this.ruleForm.availableAmount = Number(this.ruleForm.availableAmount);
          this.ruleForm.maxFinanceAmount = Number(this.ruleForm.maxFinanceAmount);
          const {
            financeReceiveAccountType,
            validDate,
            repaymentType,
            ...result
          } = this.ruleForm;
          let params = {
            ...result,
            ...this.ruleForm2,
            ...this.ruleForm3,
            financeReceiveAccountType: Number(
              this.ruleForm.financeReceiveAccountType
            ),
            effectiveTime: this.ruleForm.validDate[0],
            closeDate: this.ruleForm.validDate[1],
            grantAgreementFileUrl: this.grantAgreementFileUrl.join(","),
            grantSource: 1,
          };
          if (this.$route.query.grantCreditId) {
            params.grantCreditId = this.$route.query.grantCreditId;
          }
          console.log(params, this.ruleForm3, "params");
          api.submitGrantCredit(params).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.$router.go(-1);
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    selectCreditCorp(type, list) {
      api.selectCreditCorp({ type }).then((res) => {
        if (res.success) {
          let corpList = res.datas.data;
          if (corpList.length > 0) {
            corpList.forEach((v) => {
              // 资方加企业类型字段
              if (type == 1) {
                if (v.corpType == 1) {
                  list.push({ name: v.creCorpName, id: v.corpCreId, type: 0 }); //type: 0正常入驻资方  1未入驻资方
                } else if (v.corpType == 7) {
                  list.push({ name: v.creCorpName, id: v.corpCreId, type: 1 }); //corpType 1正常入驻资方企业  7未入驻资方企业
                }
              } else {
                list.push({ name: v.creCorpName, id: v.corpCreId });
              }
            });
          }
        }
      });
    },
    getCorpList(type, list) {
      api.getCorpList({ type }).then((res) => {
        if (res.success) {
          let corpList = res.datas.corpList;
          if (corpList.length > 0) {
            corpList.forEach((v) => {
              // 资方加企业类型字段
              if (type == 1) {
                if (v.corpType == 1) {
                  if (this.ruleForm.fundCorp == v.corpName) {
                    // this.ruleForm.fundCorpId = v.corpId,
                    this.ruleForm.fundCorpType = 0;
                  }
                  list.push({ name: v.corpName, id: v.corpId, type: 0 }); //type: 0正常入驻资方  1未入驻资方
                } else if (v.corpType == 7) {
                  this.ruleForm.fundCorpType = 0;
                  list.push({ name: v.corpName, id: v.corpId, type: 1 }); //corpType 1正常入驻资方企业  7未入驻资方企业
                }
              } else {
                list.push({ name: v.corpName, id: v.corpId });
              }
            });
          }
        }
      });
    },
    handelFundCorpList(e) {
      if (e.type == 1) {
        this.ruleForm.fundCheck = 2;
        this.fundFlag = true;
      } else {
        this.fundFlag = false;
      }
      this.handelCorpAccountList();
      if (this.ruleForm.fundCorp.type == 1) {
        this.ruleForm.fundClear = 0;
      }
    },
    handelGrantCorpList(e) {
      this.handelCorpAccountList();
    },
    handelCorpAccountList() {
      // 用户切换资金企业或者授信企业后，清除之前选择的机构还款账户
      if (this.ruleForm.repaymentAccount) {
        this.ruleForm.repaymentAccount = "";
        let fields = this.$refs.ruleForm.fields;
        fields.forEach((el) => {
          if (el.prop === "repaymentAccount") {
            el.resetField();
            return;
          }
        });
      }
      this.ruleForm.repaymentAccount = "";
      this.ruleForm.repaymentId = "";
    },
    // pickerOptions: {
    //   disabledDate: function disabledDate(time) {
    //     return (
    //       time.getTime() > new Date(this.ruleForm.accountCloseDate).getTime()
    //     );
    //   }.bind(this),
    // },
    handelAccountChange(e, flag) {
      if (!flag) {
        this.accountShow = true;
      }
      console.log(this.accountList, "accountList");
      this.accountList.forEach((_) => {
        if (e.id == _.code) {
          this.ruleForm3.accountName = _.accountName;
          this.ruleForm3.accountNo = _.accountNo;
          this.ruleForm3.openBank = _.openBank;
          // this.ruleForm3.fundReceiveAccountId = _.fundReceiveAccountId;
          this.ruleForm3.fundReceiveAccount = _.fundReceiveAccount;
        }
      });
      console.log(this.ruleForm3, "ruleForm");
    },
    handelSettlementChannel() {
      // 用户选择上海银行等专属结算渠道，则隐藏机构还款账户，选择中金则显示
      if (this.ruleForm.settlementChannel !== "1") {
        this.isShow1 = false;
        // this.handelCorpAccountList();
        this.ruleForm.repaymentType = null;

        this.ruleForm.sorting = "2"; // 用户选择上海银行等专属结算渠道，清分方式只能为直接清分
        this.ruleForm.fundCheck = 2; // 用户选择上海银行等专属结算渠道，不允许开启资金审查
      } else {
        this.isShow1 = true;
      }
    },
    // 获取专属结算渠道列表 settlementChannel
    getDicListByType() {
      api.getDicListByType({ type: 34 }).then((res) => {
        if (res.success) {
          let corpList = res.datas.list;
          if (corpList.length > 0) {
            corpList.forEach((v) => {
              this.settlementChannelList.push({
                name: v.name,
                id: v.sysDicId,
                code: v.code,
              });
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-left: 12px;
  border-bottom: 2px solid #eee;
  &::before {
    content: "";
    display: inline-block;
    width: 3px;
    height: 16px;
    background-color: #d19f53;
    position: absolute;
    left: 0;
    right: 0;
    top: 15px;
  }
}
.switch-text {
  color: red;
  margin-left: 50px;
}
.sub-title1 {
  line-height: 40px;
  width: 100%;
  border-bottom: 2px solid #eee;
}
.bottom {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 2px solid #eee;
  line-height: 40px;
  width: 100%;
  padding-left: 12px;
  margin-bottom: 20px;
}
.subtitle {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 2px solid #eee;
  line-height: 40px;
  width: 100%;
  padding-left: 12px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  border-top: 1px solid #ccc;
  padding: 36px;
}
</style>
<style lang="less">
.add-apply {
  .el-range-separator {
    width: 12%;
  }
  .el-radio {
    margin-right: 10px;
  }
}
</style>
