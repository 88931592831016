<!--银e券转让协议-->
<template>
      <div>
        <p style="font-size:14pt; line-height:23pt; margin:0pt 0pt 12pt; orphans:0; widows:0"><span
                style="font-family:微软雅黑; font-size:14pt">协议编号：</span><span
                style="font-family:微软雅黑; font-size:14pt">【{{signAgreementDetail&&signAgreementDetail.no ? signAgreementDetail.no :'' }}】</span></p>
        <p style="font-size:18pt; line-height:23pt; margin:0pt 0pt 12pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:微软雅黑; font-size:18pt">银壹供应链平台银e券</span><span
                style="font-family:微软雅黑; font-size:18pt">转让协议</span></p>
        <p style="font-size:16pt; line-height:23pt; margin:0pt 0pt 12pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:微软雅黑; font-size:16pt">版本：v1.0</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">持单</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">人</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">/转让方</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">：{{signAgreementDetail&&signAgreementDetail.openCorpName ? signAgreementDetail.openCorpName :''}}</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">法定代表人：{{signAgreementDetail&&signAgreementDetail.openUserName ? signAgreementDetail.openUserName :''}}</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">联系地址：{{signAgreementDetail&&signAgreementDetail.openAddress ? signAgreementDetail.openAddress :''}}</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">联系电话：{{signAgreementDetail&&signAgreementDetail.openPhone? signAgreementDetail.openPhone :'' }}</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">受让方</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">：{{signAgreementDetail&&signAgreementDetail.receiveCorpName ? signAgreementDetail.receiveCorpName :''}}</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">法定代表人：{{signAgreementDetail&&signAgreementDetail.receiveUserName ? signAgreementDetail.receiveUserName :''}}</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">联系地址：{{signAgreementDetail&&signAgreementDetail.receiveAddress ? signAgreementDetail.receiveAddress :''}}</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">联系电话：{{signAgreementDetail&&signAgreementDetail.receivePhone? signAgreementDetail.receivePhone :'' }}</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">鉴于：</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; text-indent:24pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p
            style="font-size:12pt; line-height:23pt; margin:0pt; orphans:0; text-align:justify; text-indent:24pt; widows:0">
            <span style="font-family:微软雅黑; font-size:12pt">持单人</span><span
                style="font-family:微软雅黑; font-size:12pt">为应收账款电子凭证（银e券，银e券编号为：</span><span
                style="font-family:微软雅黑; font-size:12pt; text-decoration:underline"> {{signAgreementDetail&&signAgreementDetail.payableBillNo ? signAgreementDetail.payableBillNo :''}}  </span><span
                style="font-family:微软雅黑; font-size:12pt">）的现持有人，现有意将该银e券全部/部分转让给本协议受让方，受让方同意受让，对应转让金额/应收账款债权金额为人民币（大写）</span><span
                style="font-family:微软雅黑; font-size:12pt; text-decoration:underline">    {{amount ? amount : signAgreementDetail&&signAgreementDetail.amountUpper? signAgreementDetail.amountUpper: ''}}  </span><span
                style="font-family:微软雅黑; font-size:12pt"> ，对应银e券到期日为</span><span
                style="font-family:微软雅黑; font-size:12pt; text-decoration:underline">  {{signAgreementDetail&&signAgreementDetail.promisePayTime? signAgreementDetail.promisePayTime :promiseTime? promiseTime:'' }}  </span></p>
       
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">基于此，</span><span
                style="font-family:微软雅黑; font-size:12pt">持单人</span><span
                style="font-family:微软雅黑; font-size:12pt">（</span><span
                style="font-family:微软雅黑; font-size:12pt">即转让方</span><span
                style="font-family:微软雅黑; font-size:12pt">）</span><span
                style="font-family:微软雅黑; font-size:12pt">与受让方在平等自愿、友好协商的基础上，根据《中华人民共和国民法典》及相关法律规定，在</span><span
                style="font-family:微软雅黑; font-size:12pt">认可并签</span><span
                style="font-family:微软雅黑; font-size:12pt">署</span><span
                style="font-family:微软雅黑; font-size:12pt">《应收账款电子</span><span
                style="font-family:微软雅黑; font-size:12pt">凭证</span><span
                style="font-family:微软雅黑; font-size:12pt">业务服务协议（适用</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt">和</span><span
                style="font-family:微软雅黑; font-size:12pt">《</span><span
                style="font-family:微软雅黑; font-size:12pt">银壹供应链平台银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">开单协议》前提</span><span
                style="font-family:微软雅黑; font-size:12pt">下，</span><span
                style="font-family:微软雅黑; font-size:12pt">达成《</span><span
                style="font-family:微软雅黑; font-size:12pt">银壹供应链平台银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">转让协议》，本协议</span><span
                style="font-family:微软雅黑; font-size:12pt; text-decoration:none">在</span><span
                style="font-family:微软雅黑; font-size:12pt; text-decoration:none">银壹供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">签署：</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">在本协议中，除非上下文另有解释或文义另作说明，本协议中的词语或简称以及所述的解释规则与</span><span
                style="font-family:微软雅黑; font-size:12pt">《</span><span
                style="font-family:微软雅黑; font-size:12pt">应收账款电子凭证</span><span
                style="font-family:微软雅黑; font-size:12pt">业务服务协议</span><span
                style="font-family:微软雅黑; font-size:12pt">（适用银e券产品）</span><span
                style="font-family:微软雅黑; font-size:12pt">》</span><span
                style="font-family:微软雅黑; font-size:12pt">中所定义的词语以及所列示的解释规则，具有相同的含义。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">一</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">转让概述</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><a name="_Ref419399897"><span
                    style="font-family:微软雅黑; font-size:12pt">1、按照本协议规定的条件和条款，持单人可以将持有的</span><span
                    style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                    style="font-family:微软雅黑; font-size:12pt">全部或部分通过平台转让给其债权人（下称“受让方”），即</span><span
                    style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                    style="font-family:微软雅黑; font-size:12pt">转让行为。</span></a></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2、为避免歧义，持单人和受让方在此确认，</span><a name="_Hlk15586248"><span
                    style="font-family:微软雅黑; font-size:12pt">本次</span><span
                    style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                    style="font-family:微软雅黑; font-size:12pt">转让仅涉及</span><span
                    style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                    style="font-family:微软雅黑; font-size:12pt">对应基础合同项下的</span><span
                    style="font-family:微软雅黑; font-size:12pt">应付</span><span
                    style="font-family:微软雅黑; font-size:12pt">款项</span><span
                    style="font-family:微软雅黑; font-size:12pt">权利，其他权利与义务仍由基础合同的当事人继续履行和承担。</span></a></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">3. 平台禁止无真实贸易合同或虚构贸易合同的</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">转让行为。</span><span
                style="font-family:微软雅黑; font-size:12pt">银壹供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">如查实存在此等情形的，</span><span
                style="font-family:微软雅黑; font-size:12pt">银壹供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">有权依据本协议及/或平台协议及/或平台规定及/或法律规定的措施追究其违约责任。</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">转让方及</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">受让方之间因</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">转让行为而产生的纠纷由双方自行解决。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">二</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">转让流程</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1、</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">转让前提</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（1）</span><span
                style="font-family:微软雅黑; font-size:12pt">持单人为银e券的合法持有者并向平台提出线上转让申请；</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（2）</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">的转让应具备真实、合法、有效的贸易背景；</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（</span><span
                style="font-family:微软雅黑; font-size:12pt">3</span><span
                style="font-family:微软雅黑; font-size:12pt">）</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">转让金额应根据</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">记载的金额确定，不得高于</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">记载的金额。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2、</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">转让申请</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（1）持单人提出</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">转让申请的，应通过</span><span
                style="font-family:微软雅黑; font-size:12pt">银壹供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">，根据</span><span
                style="font-family:微软雅黑; font-size:12pt">《应收账款电子凭证业务服务协议（适用银e券产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt">的规则，填写完毕</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">转让申请资料，上传完毕全部</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">转让申请文件，并勾选本协议。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（2）持单人勾选本协议，视为持单人同意本协议全部内容。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（3）</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">转让</span><span
                style="font-family:微软雅黑; font-size:12pt">完成</span><span
                style="font-family:微软雅黑; font-size:12pt">后，</span><span
                style="font-family:微软雅黑; font-size:12pt">银壹供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">自动生成</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">转让记录，持单人可通过</span><span
                style="font-family:微软雅黑; font-size:12pt">银壹供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">的</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">查询功能进行查询。该等记录为本协议的组成部分，</span><span
                style="font-family:微软雅黑; font-size:12pt">与本协议具有同等法律效力</span><span
                style="font-family:微软雅黑; font-size:12pt">。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">3、</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">的受让</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1、受让方收到持单人</span><span
                style="font-family:微软雅黑; font-size:12pt">转让的</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">后</span><span
                style="font-family:微软雅黑; font-size:12pt">，应通过</span><span
                style="font-family:微软雅黑; font-size:12pt">银壹供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">，根据</span><span
                style="font-family:微软雅黑; font-size:12pt">《应收账款电子凭证业务服务协议（适用银e券产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt">的规则，对受让的</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">进行签收，并点击“同意”或“拒绝”。若点击“同意”，则</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">受让完成。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2</span><span
                style="font-family:微软雅黑; font-size:12pt">、</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">受让完成后，</span><span
                style="font-family:微软雅黑; font-size:12pt">银壹供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">自动生成</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">受让记录，受让方可通过</span><span
                style="font-family:微软雅黑; font-size:12pt">银壹供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">的</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">查询功能进行查询。该等记录为本协议的组成部分，与本协议具有同等法律效力。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">3、</span><span
                style="font-family:微软雅黑; font-size:12pt">应收账款电子凭证转让通知书</span><span
                style="font-family:微软雅黑; font-size:12pt">。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">转让方应</span><span
                style="font-family:微软雅黑; font-size:12pt">通过</span><span
                style="font-family:微软雅黑; font-size:12pt">平台</span><span
                style="font-family:微软雅黑; font-size:12pt">系统消息/邮件/短信等任一种方式向</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">的开单人发送</span><span
                style="font-family:微软雅黑; font-size:12pt">《</span><span
                style="font-family:微软雅黑; font-size:12pt">应收账款电子凭证转让通知书</span><span
                style="font-family:微软雅黑; font-size:12pt">》</span><span
                style="font-family:微软雅黑; font-size:12pt">，</span><span
                style="font-family:微软雅黑; font-size:12pt">该等通知于发送之日</span><span
                style="font-family:微软雅黑; font-size:12pt">（以任一较早</span><span
                style="font-family:微软雅黑; font-size:12pt">发送</span><span
                style="font-family:微软雅黑; font-size:12pt">为准）</span><span
                style="font-family:微软雅黑; font-size:12pt">视为已送达开单人，</span><span
                style="font-family:微软雅黑; font-size:12pt">即视为债权转让通知已经送达</span><span
                style="font-family:微软雅黑; font-size:12pt">。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">4、</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">转让</span><span
                style="font-family:微软雅黑; font-size:12pt">业务</span><span
                style="font-family:微软雅黑; font-size:12pt">，</span><span
                style="font-family:微软雅黑; font-size:12pt">持单人</span><span
                style="font-family:微软雅黑; font-size:12pt">及</span><span
                style="font-family:微软雅黑; font-size:12pt">受让方</span><span
                style="font-family:微软雅黑; font-size:12pt">均勾选了本协议，视为双方同意本协议</span><span
                style="font-family:微软雅黑; font-size:12pt">的条款</span><span
                style="font-family:微软雅黑; font-size:12pt">。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">5、</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">转让方、受让方同意，</span><span
                style="font-family:微软雅黑; font-size:12pt">受让方有权</span><span
                style="font-family:微软雅黑; font-size:12pt">通过中国人民银行征信中心动产融资统一登记系统办理应收账款转让登记，转让方同意配合</span><span
                style="font-family:微软雅黑; font-size:12pt">受让方</span><span
                style="font-family:微软雅黑; font-size:12pt">办理转让登记手续。</span><span
                style="font-family:微软雅黑; font-size:12pt">因</span><span
                style="font-family:微软雅黑; font-size:12pt">登记</span><span
                style="font-family:微软雅黑; font-size:12pt">产生的相关</span><span
                style="font-family:微软雅黑; font-size:12pt">费用支付由转让方、受让方协商确认。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">6、</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">受让方未在签收有效期内签收</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">，或</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">受让方拒绝签收</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">的，</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">转让失败。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">三</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、转让结果</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1、受让方为持单人的供应商，双方已签署了关于购买货物或服务的基础合同，根据该等合同，持单人对受让方负有一定金额的债务。</span>
        </p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2、</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">可作为到期付款承诺使用，受让方到期可要求开单人付款，受让方与</span><span
                style="font-family:微软雅黑; font-size:12pt">转让方</span><span
                style="font-family:微软雅黑; font-size:12pt">的</span><span
                style="font-family:微软雅黑; font-size:12pt">该笔</span><span
                style="font-family:微软雅黑; font-size:12pt">债权债务关系在</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">转让完成</span><span
                style="font-family:微软雅黑; font-size:12pt">后灭失</span><span
                style="font-family:微软雅黑; font-size:12pt">。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">四</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">的使用与管理</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1</span><span
                style="font-family:微软雅黑; font-size:12pt">、</span><span
                style="font-family:微软雅黑; font-size:12pt">在平台限定的</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">到期前的一定期限内，持单人须持有</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">到期，等待开单人的资金偿付。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2、</span><span
                style="font-family:微软雅黑; font-size:12pt">受让方可以向</span><span
                style="font-family:微软雅黑; font-size:12pt">银行</span><span
                style="font-family:微软雅黑; font-size:12pt">或</span><span
                style="font-family:微软雅黑; font-size:12pt">保理公司</span><span
                style="font-family:微软雅黑; font-size:12pt">等</span><span
                style="font-family:微软雅黑; font-size:12pt">申请融资，具体手续依照平台相关业务规则所规定的流程办理。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">3</span><span
                style="font-family:微软雅黑; font-size:12pt">、受让方可以持有</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">直至到期兑付。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">4</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">银壹供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">无责任或义务将</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">兑换为任意额度现金或有价证券，亦不为</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">承担任何形式的担保或偿还责任。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">五</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、承诺与保证</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1、本协议持单人、受让方双方在此相互承诺和保证如下：</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（1）双方均为</span><span
                style="font-family:微软雅黑; font-size:12pt">银壹供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">认证用户，双方承诺遵守</span><span
                style="font-family:微软雅黑; font-size:12pt">《应收账款电子凭证业务服务协议（适用银e券产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt">。双方各自在</span><span
                style="font-family:微软雅黑; font-size:12pt">《应收账款电子凭证业务服务协议（适用银e券产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt">项下的陈述和保证为本协议的一部分，其一旦违反将需承担</span><span
                style="font-family:微软雅黑; font-size:12pt">《应收账款电子凭证业务服务协议（适用银e券产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt">和本协议项下的违约责任。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（2）双方各自具有签署本协议和履行本协议所规定各项义务的完整的合法资格，并取得了签署和履行本协议的所有内部授权及批准，本协议经签署后对双方是合法、有效、有约束力并可强制执行的。</span>
        </p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（3）双方签署和履行本协议并不违反其所适用的任何法律、法规、规范性文件或政府机构的许可或批准，亦不违反任何对其有约束力的组织文件或其与任何第三方签订的任何合同或协议（或者已取得第三方的同意或认可）。</span>
        </p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（4）双方承诺，开立和签收的</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">有真实、合法、有效的贸易背景，</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">项下基础交易所对应的基础合同已妥善签署、合法、有效。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2、持单人承诺：</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（1）持单人承诺所转让的</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">不存在任何瑕疵</span><span
                style="font-family:微软雅黑; font-size:12pt">，也不存在受让方行使权利的限制，</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">项下债权真实、合法、有效；持单人未对债权设立抵押、质押或其他任何优先权益，且未曾向他人转让。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（2）持单人承诺所转让的</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">项下债权未曾与债务人有约定抵销、禁止或限制向受让方转让等事宜；在受让方签收</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">后，持单人、开单人、受让方不得对</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">项下债权的任何内容做出变更、终止、宽延或放弃。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（3）除本协议的转让外，持单人不曾亦保证不会就</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">项下应收账款的全部或任何部分，通过</span><span
                style="font-family:微软雅黑; font-size:12pt">银壹供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt">以外的途径转让或质押给任何第三方。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">（4）持单人承诺、保证并同意</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">转让后，受让方对</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">享有完全的权利，包括但不限于到期收到本金、利息权益、违约金权益、损害赔偿权益、担保权益以及再转让权等。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">3、受让方承诺：</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">受让方知悉</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">的转让为无追索权转让，如</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">到期未能得到偿付，或出现</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">项下基础交易所对应的基础合同有关的任何纠纷，受让方对持单人或其前手持单人（如有）不具有追索权，持单人亦无义务对</span><span
                style="font-family:微软雅黑; font-size:12pt">银e券</span><span
                style="font-family:微软雅黑; font-size:12pt">项下债权的实现提供任何保证。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">六</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、税费</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">受让方、持单人和保理商按照中国法律、行政法规、规章的规定承担各自因履行本协议而产生的相应税负，或承担履行代扣代缴义务。受让方和持单人因履行本协议过程中所产生的税负，除非本协议另有约定，应由受让方和持单人依法各自承担。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">七</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、保密</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">为保证本协议的全面履行，双方承诺就对方提供的所有资料中所包含的商业秘密和非公开信息严格保密，未经对方同意不得向第三方披露。无论本协议是否终止或解除，本条款在保密期限内持续有效。</span>
        </p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">八</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、违约责任</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1、持单人违反本协议第</span><span
                style="font-family:微软雅黑; font-size:12pt">五</span><span
                style="font-family:微软雅黑; font-size:12pt">条“承诺和保证”中的任一事项，或违反本协议其他约定义务的事项的，构成持单人违约，已支付的平台费用不予退还，并承担由此给平台造成的一切损失。</span>
        </p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2</span><span
                style="font-family:微软雅黑; font-size:12pt">、守约方有权要求违约方承担根据法律、法规的规定承担违约责任的权利，但该其他违约责任的承担将与本条的约定相抵触的情况除外。</span>
        </p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><a name="_Hlk16090149"><span
                    style="font-family:微软雅黑; font-size:12pt; font-weight:bold">九</span><span
                    style="font-family:微软雅黑; font-size:12pt; font-weight:bold">、适用法律及争议的解决</span></a></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">1、本协议的订立、生效、解释、执行、管辖、争议的解决均适用中华人民共和国法律。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">2、因履行本协议发生的争议应通过协商解决，协商不成的，任何一方均有权将争议提交上海国际经济贸易仲裁委员会，按照提交仲裁时该会现行有效的仲裁规则进行仲裁，仲裁裁决是终局的，对各方均有约束力。</span>
        </p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">3、当产生任何争议及任何争议正按前条规定进行解决时，除争议事项外，双方应继续行使本协议项下的其他权利，履行本协议项下的其他义务。如本协议中的任何条款被认定为全部或部分不合法、无效或不可强制执行，并不影响其他</span><span
                style="font-family:微软雅黑; font-size:12pt">条款的有效和执行。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="-aw-bookmark-end:_Hlk16090149"></span><a name="_Hlk16090184"><span
                    style="font-family:微软雅黑; font-size:12pt; font-weight:bold">十、其他</span></a></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:normal">1、</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:normal">除本协议另有约定外，本协议列明的联系地址及联系电话为转让方/受让方有效送达地址；除此之外，其他有效送达地址及送达方式应以</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:normal">《应收账款电子凭证业务服务协议（适用银e券产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:normal">第十二条第一款为准。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:normal">2、</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:normal">本协议自</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:normal">转让方、</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:normal">受让方</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:normal">加盖公章（包括电子签章形式）</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:normal">之日起成立并生效。如通过</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:normal">银壹供应链平台</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:normal">以电子文本形式制成的，可以有一份或者多份并且每一份具有同等法律效力，双方均认可该形式的协议效力。</span><span
                style="font-family:微软雅黑; font-size:12pt; font-weight:normal">&#xa0;</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">3</span><span
                style="font-family:微软雅黑; font-size:12pt">、本协议未尽之事宜</span><span
                style="font-family:微软雅黑; font-size:12pt">，</span><span
                style="font-family:微软雅黑; font-size:12pt">如</span><span
                style="font-family:微软雅黑; font-size:12pt">《应收账款电子</span><span
                style="font-family:微软雅黑; font-size:12pt">凭证业务服务协议（适用银e券产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt">已有约定的，适用</span><span
                style="font-family:微软雅黑; font-size:12pt">《应收账款电子凭证业务服务协议（适用银e券产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt">的约定</span><span
                style="font-family:微软雅黑; font-size:12pt">；</span><span
                style="font-family:微软雅黑; font-size:12pt">如</span><span
                style="font-family:微软雅黑; font-size:12pt">与</span><span
                style="font-family:微软雅黑; font-size:12pt">《应收账款电子凭证业务服务协议（适用银e券产品）》</span><span
                style="font-family:微软雅黑; font-size:12pt">不一致或有冲突的，应以本协议为准。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">4</span><span
                style="font-family:微软雅黑; font-size:12pt">、双方应秉承诚实信用之原则，采取一切必要的行为以确保本协议之生效及获完全执行，并避免采取任何与本协议条款不一致的行为。</span>
        </p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">5</span><span
                style="font-family:微软雅黑; font-size:12pt">、除非本协议另有约定，本协议生效后，任何一方不得单方面变更或解除本协议；对本协议的任何修改或变更必须经协议各方协商一致，并达成书面协议。</span><span
                style="-aw-bookmark-end:_Hlk16090184"></span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">6、</span><span
                style="font-family:微软雅黑; font-size:12pt">本协议各方签署日期以电子签章实际所携带的落款签名时间为准。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:31.5pt"><span
                style="font-family:微软雅黑; font-size:12pt">本协议签署日期以最后一方电子签章实际所携带的落款签名时间为准。</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">(以下无正文）</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify"><span
                style="color:#222222; font-family:等线; font-size:10.5pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="color:#222222; font-family:等线; font-size:10.5pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:42pt"><span
                style="font-family:微软雅黑; font-size:12pt">持单人/转让方：{{signAgreementDetail&&signAgreementDetail.openCorpName? signAgreementDetail.openCorpName :'' }}                    <span >原始持单人：</span>{{signAgreementDetail&&signAgreementDetail.receiveCorpName? signAgreementDetail.receiveCorpName :'' }}</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span>
        </p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:31.5pt"><span
                style="font-family:微软雅黑; font-size:12pt"> （盖章）                                <span :style="stylelist">（盖章）</span></span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:31.5pt"><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:31.5pt"><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt"><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
    </div>
</template>
<script>
export default {
       data(){
                return {
                        margin:'100px'
                }
        },
        props:{
                signAgreementDetail:Object,
                amount:String,
                promiseTime:String
        },
        created(){
                console.log(this.promiseTime,'promiseTime')
        },
        computed: {
                stylelist() {
                        if(this.signAgreementDetail&&this.signAgreementDetail.openCorpName) {
                        return {'margin-left':this.margin}
                        }
                }
        }
}
</script>
