<template>
  <div>
    <layout-main-view
      :breadcrumb="breadcrumblist"
      :tabOptions="tabOptions"
      @tabclick="tabclick"
    >
          <open-easypay v-if="showtab == 1"></open-easypay>

          <accept-easypay v-if="showtab==2"></accept-easypay>

          <hold-easypay v-if="showtab==3"></hold-easypay>

          <financing-easypay v-if="showtab==4"></financing-easypay>

          <transfer-easypay v-if="showtab==5"></transfer-easypay>

          <back-easypay v-if="showtab==6"></back-easypay>
          
          <clear-easypay v-if="(corpType==2||corpType==3)&&showtab==7" :corpType="corpType"></clear-easypay>

      <!-- <el-tabs v-model="activeName" type="card" @tab-click="tabClick">
        <el-tab-pane label="已开单" name="first">
          <div class="common-page-dataList">
            <open-easypay></open-easypay>
          </div>
        </el-tab-pane>
        <el-tab-pane label="待接收" name="second">
          <div class="common-page-dataList">
            <accept-easypay></accept-easypay>
          </div>
        </el-tab-pane>
        <el-tab-pane label="持有中" name="three">
          <div class="common-page-dataList">
            <hold-easypay></hold-easypay>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已融资" name="four">
          <div class="common-page-dataList">
            <financing-easypay></financing-easypay>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已转让" name="five">
          <div class="common-page-dataList">
            <transfer-easypay></transfer-easypay>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已退回" name="six">
          <div class="common-page-dataList">
            <back-easypay></back-easypay>
          </div>
        </el-tab-pane>
      </el-tabs> -->
    </layout-main-view>
  </div>
</template>
<script>
import { searchEntryList } from "../../api/api";
import OpenEasypay from "./openEasypay.vue";
import AcceptEasypay from "./acceptEasypay.vue";
import HoldEasypay from "./holdEasypay.vue";
import FinancingEasypay from "./financingEasypay.vue";
import TransferEasypay from "./transferEasypay.vue";
import BackEasypay from "./backEasypay.vue";
import ClearEasypay from "./clearEasypay.vue";

export default {
  components: {
    "open-easypay": OpenEasypay,
    "accept-easypay": AcceptEasypay,
    "hold-easypay": HoldEasypay,
    "financing-easypay": FinancingEasypay,
    "transfer-easypay": TransferEasypay,
    "back-easypay": BackEasypay,
    "clear-easypay": ClearEasypay,

  },
  data() {
    return {
      breadcrumblist: ["银e券管理"],
      showtab: 1,
      tabOptions: [
        { label: "已开单", value: "1", path: "/easypay/mange/1" },
        { label: "待接收", value: "2", path: "/easypay/mange/2" },
        { label: "持有中", value: "3", path: "/easypay/mange/3" },
        // { label: "已融资", value: "4", path: "/easypay/mange/4" },
        { label: "已转让", value: "5", path: "/easypay/mange/5" },
        { label: "已退回", value: "6", path: "/easypay/mange/6" },
      ],
      activeName: "first",
      total: 0,
      listLoading: false,
      listData: [],
      handleEditShow: false,
      corpType: '',
    };
  },
  created() {
    // corpType  1资金机构 2.核心企业 3.一般企业  6.担保企业'
    this.corpType = JSON.parse(sessionStorage.user).corpType
    if(this.corpType === 2||this.corpType === 3) {
      this.tabOptions = [
        { label: "已开单", value: "1", path: "/easypay/mange/1" },
        { label: "待接收", value: "2", path: "/easypay/mange/2" },
        { label: "持有中", value: "3", path: "/easypay/mange/3" },
        // { label: "已融资", value: "4", path: "/easypay/mange/4" },
        { label: "已转让", value: "5", path: "/easypay/mange/5" },
        { label: "已退回", value: "6", path: "/easypay/mange/6" },
        { label: "已清分", value: "7", path: "/easypay/mange/7" }
      ]
    }
  },
  mounted(){
      this.showtab = this.$route.params.state;
      console.log('this.showtab ===>',this.showtab);
  },

  methods: {
    tabclick(val) {
      this.showtab = val;
      this.handleEditShow = false;
    },

    // tabClick(val){
    //   console.log("val ===>",val);
    // }
  }
};
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
