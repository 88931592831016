<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column prop="payableBillFinanceNo" label="融资项目编号" min-width="170"></el-table-column>
          <el-table-column prop="financePayableBillNo" label="融资子银e券编号" min-width="220"></el-table-column>
          <el-table-column prop="financeCorpName" label="融资企业" min-width="170"></el-table-column>
          <el-table-column prop="amount" label="融资总额(元)" min-width="130">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.amount)}}</template>
          </el-table-column>
          <el-table-column prop="financeYearInterest" label="融资年化利率(％)" min-width="130"></el-table-column>
          <el-table-column prop="preFinanceInterest" label="融资利息(元)" min-width="140">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.preFinanceInterest)}}</template>
          </el-table-column>
          <el-table-column prop="preIncomeAmount" label="预计融资金额(元)" min-width="150">
            <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.preIncomeAmount)}}</template>
          </el-table-column>
          <el-table-column prop="financeApplyTime" label="申请日期" min-width="150">
            <template
                    slot-scope="scope"
            >{{scope.row.financeApplyTime ? moment(scope.row.financeApplyTime).format('YYYY-MM-DD HH:mm'): ''}}</template>
          </el-table-column>
          <!-- <el-table-column prop="status" label="银e券状态" width="100" align="left"></el-table-column> -->
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="handleEdit(scope.row)">审批</el-button>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>
  </div>
</template>
<script>
import api from "@/api/api";
import moment from 'moment'

export default {
  data() {
    return {
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: "融资项目编号:",
          type: "input",
          field: "payableBillFinanceNo",
          size: "small"
        },
        {
          label: "融资企业:",
          type: "input",
          field: "financeCorpName",
          size: "small"
        },
        {
          label: "银e券金额: ",
          type: "inputrange",
          field: "financeAmountMin",
          size: "small",
          isSeparated: 1
        },
        {
          type: "inputrange",
          field: "financeAmountMax",
          size: "small"
        },
        // {
        //   label: "银e券利率: ",
        //   type: "inputrange",
        //   field: "financeInterestMin",
        //   size: "small",
        //   isSeparated: 1
        // },
        // {
        //   type: "inputrange",
        //   field: "financeInterestMax",
        //   size: "small"
        // },
        {
          label: "申请日期:",
          type: "daterange",
          field: ["financeStartTime", "financeEndTime"],
          size: "small"
        }
      ],
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      pageNum: 1,
      pageSize: 10
    };
  },
  created() {
  },
  methods: {
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
      console.log("filterShowAll  -----", this.isShowAll);
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      console.log("filters<", filters);
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      console.log("param ====>", param);
      param.review = 1;
      param.checkLevel = 2; //1 初审 2 复审 3 终审
      api.GetFundFinanceApproveList(param).then(res => {
        console.log("res ===>", res);
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
          console.log("list ===>", this.listData);
        }
        this.listLoading = false;
      });
    },
    handleEdit(row) {
      console.log("融资审批 复核", row);
      this.$router.push({
        name:"资方融资审批详情",
        query: { id: row.payableBillId ,type: '2',payableBillFinanceId: row.payableBillFinanceId, isshowbtn: 1,
        financeSignStatus: row.financeSignStatus,
          approveStatus: row.approveStatus,
          financeSignStatus: row.financeSignStatus}
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
