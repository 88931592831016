<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList blrzstep">
          <steps :stepList="stepList" :current="current"></steps>

      <div class="easypayby goback yfdtwo">
        <div class="yfdsize">基本信息</div>
        <div class="formstyle">
          <el-form
            :model="payableBillAddRequest"
            ref="payableBillAddRequest"
            label-width="160px"
            class="demo-ruleForm"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="应收账款能否确权: ">
                  <el-radio disabled v-model="info.corpAck" label="1">能</el-radio>
                  <el-radio disabled v-model="info.corpAck" label="2">否</el-radio>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="期望融资金额(元): " prop="preFinanceAmount">
                  <el-input disabled v-model="info.preFinanceAmount" placeholder="请输入银e券金额"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="期望融资金额(大写): " class="minsizer">
                  <el-input disabled v-model="info.upper" ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="inlinelv">
              <el-col :span="6" class="firlabwid">
                <el-form-item label="期望融资年化利率(%): " prop="expectFinanceInterestMin">
                  <el-input disabled v-model="info.expectFinanceInterestMin" placeholder="请输入期望融资年化利率最小值"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1" class="perwidth">
                <em>~</em>
              </el-col>
              <el-col :span="6" class="nolabwid">
                <el-form-item label=" " prop="expectFinanceInterestMax">
                  <el-input disabled v-model="info.expectFinanceInterestMax" placeholder="请输入期望融资年化利率最大值"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="收款开户行: " prop="payableAmount">
                  <!-- <el-select
                    v-model="payableBillAddRequest.payeeOpenBank"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in corpAccountPublicList"
                      :key="item.openBank"
                      :label="item.openBank"
                      :value="item.openBank"
                    ></el-option>
                  </el-select>-->

                  <el-input disabled v-model="info.payeeOpenBank" placeholder="请输入期望融资年化利率最大值"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="收款账户: " class="minsizer">
                  <!-- <el-select v-model="payableBillAddRequest.payeePublicAccountNo" placeholder="请选择">
                    <el-option
                      v-for="item in corpAccountPublicNoList"
                      :key="item.accountNo"
                      :label="item.accountNo"
                      :value="item.accountNo"
                    ></el-option>
                  </el-select>-->
                  <el-input disabled v-model="info.payeePublicAccountNo" placeholder="请输入期望融资年化利率最大值"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="温馨提示: ">
                  <span class="info-value">还没有绑定卡，</span>
                  <a
                    class="downsize"
                    style="color: #2937A6; text-decoration: underline;"
                    size="small"
                    @click="linkCompanyManage"
                  >点击此处</a>
                  <span class="info-value">完成对公账户绑定。</span>
                </el-form-item>
              </el-col>
            </el-row>-->
          </el-form>
        </div>

        <div class="yfdbaseline"></div>
        <div class="yfdsize">绑定应收账款</div>
        <div class="httotal">
          已添加应收账款
          <span>{{tableList.length}}</span> 笔，账款总计金额
          <span>{{ $changeMoneyThousand(info.relationReceivedAccountAmounts) }}</span> 元。

        </div>
        <div>
          <el-table ref="multipleTable" :data="tableList" tooltip-effect="dark" style="width: 100%">
            <el-table-column prop="receivableAccountNo" label="应收账款编号" min-width="200"></el-table-column>
            <el-table-column prop="debtCorpName" label="债务企业" min-width="170"></el-table-column>
            <el-table-column prop="accountAmount" label="应收账款金额" min-width="120">
              <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.accountAmount)}}</template>
            </el-table-column>
            <el-table-column prop="accountSignDate" label="账款起始日期" min-width="120">
              <template slot-scope="scope">{{scope.row.accountSignDate ? moment(scope.row.accountSignDate).format('YYYY-MM-DD'): ''}}</template>

            </el-table-column>

            <el-table-column prop="accountCloseDate" label="账款到期日期" min-width="120">
              <template slot-scope="scope">{{scope.row.accountCloseDate ? moment(scope.row.accountCloseDate).format('YYYY-MM-DD'): ''}}</template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="card-hd" style="height: 150px;" v-if='$route.query.isshowbtn!=2'>
            <el-row style="padding-top: 40px;">
              <el-col :span="7" class="lb_body" :offset="7">
                <el-button
                  style="width: 160px;height: 40px;font-size:16px;"
                  size="small"
                  @click="reject"
                >拒绝</el-button>
              </el-col>
              <el-col :span="8" class="lb_body">
                <el-button
                  style="width: 160px;height: 40px;background: #2937A6;color:#ffffff;font-size:16px;"
                  size="small"
                  @click="agree"
                >同意</el-button>
              </el-col>
            </el-row>
          </div>
               <div class="card-hd" style="height: 150px;" v-if='$route.query.isshowbtn==2'>
            <el-row style="padding-top: 40px;">
              <el-col :span="7" class="lb_body" :offset="11">
                <el-button
                  style="width: 160px;height: 40px;background: #2937A6;color:#ffffff;font-size:16px;"

                  size="small"
                  @click="goback"
                >返回</el-button>
              </el-col>

            </el-row>
          </div>
        </div>
      </div>
    </div>
    <!-- Form -->
    <el-dialog title="提示信息" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="拒绝原因" :label-width="formLabelWidth" prop="reason">
          <el-select v-model="form.reason" placeholder="请选择拒绝理由">
            <el-option
              v-for="item in rejectList"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="填写备注信息" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="form.remark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">提 交</el-button>
      </div>
    </el-dialog>
  </layout-main-view>
</template>
<script>
import api from "@/api/api";
import { convertCurrency, adds } from "@/util/common.js";
import { baseURL } from "@/api/sys";
import moment from "moment";
import { changeMoneyThousand } from "@/util/common";
import steps from "@/views/base/steps.vue";

export default {
    components: {
            steps
        },
  data() {
    return {
      moment:moment,
      convertCurrency: convertCurrency,
      baseURL: baseURL,
      loading: false,
      breadcrumblist: ["保理融资"],
      payableBillAddRequest: {},
      tableList: [],
      corpAccountPublicNoList: [],
      corpAccountPublicList: [],
      info: {
        upper: ""
      },
      receivableAccountFinanceId: 0,
      dialogFormVisible: false, // 拒绝原因
      formLabelWidth: "120px",
      form: {
        reason: "",
        remark: ""
      },
      rules: {
        reason: [{ required: true, message: "请选择拒绝原因", trigger: "blur" }]
      },
      rejectList: [], //拒绝原因,
         stepList: [
                                    {
                                    name: "发起融资",
                                    realName: "",
                                    createTime: ""
                                    },
                                    {
                                    name: "项目内审",
                                    realName: "",
                                    createTime: ""
                                    },
                                    {
                                    name: "平台撮合",
                                    realName: "",
                                    createTime: ""
                                    },
                                       {
                                    name: "机构报价",
                                    realName: "",
                                    createTime: ""
                                    },
                                    {
                                    name: "选择报价",
                                    realName: "",
                                    createTime: ""
                                    },
                                    {
                                    name: "补件确权",
                                    realName: "",
                                    createTime: ""
                                    },
                                     {
                                    name: "等待签约",
                                    realName: "",
                                    createTime: ""
                                    },
                                      {
                                    name: "签约完成",
                                    realName: "",
                                    createTime: ""
                                    },
                                
                                    
                                ],
                            current:1
    };
  },

  created() {
    this.receivableAccountFinanceId = this.$route.query.receivableAccountFinanceId;
    this.getInfo(this.$route.query.receivableAccountFinanceId);
  },
  methods: {
    getInfo(receivableAccountFinanceId) {
      api
        .FinancingProjectDetails({
          receivableAccountFinanceId: receivableAccountFinanceId
        })
        .then(res => {
          if (res.success) {
            this.info = res.datas.data;
            this.info.corpAck = this.info.corpAck + "";
            this.tableList = res.datas.list;
            let upper = this.convertCurrency(this.info.preFinanceAmount);
            this.$set(this.info, "upper", upper);
                            this.getstatus()

          }
        });
    },
    agree() {
      let param = {
        receivableAccountFinanceId: Number(this.receivableAccountFinanceId),
        type: 1,
        sysDicId: "",
        remark: ""
      };
      api.ToCombined({},param).then(res => {
        if (res.success) {


                 this.$alert("融资审批通过，等待平台资金匹配", "", {
              confirmButtonText: "确定",
              callback: action => {
                history.go(-1);
              }
            });
        }else {
            this.$message.error(res.message);
        }
      });
    },
    //拒绝
    reject() {
      this.dialogFormVisible = true;
      //查询拒绝原因
      api.getDicListByType({ type: 19 }).then(res => {
        if (res.success) {
          this.rejectList = res.datas.list;
        }
      });
    },
    handleEdit(data){
      this.$router.push({name:'应收账款详情',query: { id:data.receivableAccountId,isshowbtn:2}})
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let param = {
            receivableAccountFinanceId: Number(this.receivableAccountFinanceId),
            type: 2,
            reason: this.form.reason,
            remark: this.form.remark
          };
          api.ToCombined({},param).then(res => {
            if (res.success) {

                 this.$alert(res.message, "", {
              confirmButtonText: "确定",
              callback: action => {
                history.go(-1);
              }
            });
            }else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    goback(){
      history.go(-1)
    },
             getstatus(){
          if(this.info.state == 'D'){
                // 作废
                this.current=-1
                }else{
                if(this.info.financeSignStatus == 50){
                  this.current=8
                // 签约完成
                }else if(this.info.financeSignStatus >= 20 && this.info.financeSignStatus < 50){
                  this.current=6
                // 等待签约
                }else if(this.info.financeSignStatus < 20 && this.info.financeSupplyApproveStatus >= 20 && this.info.financeSupplyApproveStatus <= 57 && this.info.corpAckStatus > 1 && this.info.corpAckStatus <= 6){
                // 补件确权
                this.current=5
                }else if(this.info.financeQuoteStatus == 3 && this.info.financeSupplyApproveStatus < 20 ){
                  this.current=4
                // 选择报价
                }else if(this.info.financeQuoteStatus == 2){
                  this.current=3
                // 机构报价
                }else if(this.info.approveStatus >= 41 && this.info.approveStatus <= 49){
                  this.current=2
                // 平台撮合
                }else if(this.info.approveStatus > 20 && this.info.approveStatus <= 23){
                // 项目内审
                this.current=1
                }else if(this.info.approveStatus == 20){
                  this.current=1
                // 发起融资
                }else{
                  this.current=6

                // 等待签约
                }
                }


    },
  }
};
</script>
<style lang="scss">
.yfdbaseline {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 18px;
  margin-bottom: 37px;
}
.easypayby {
  padding-bottom: 40px;
  &.goback {
    padding-top: 32px;
  }
}

.yfdtwo {
  padding: 0 42px;
  .el-input.is-disabled .el-input__inner {
    color: rgb(96, 98, 102);
  }
}

.httotal {
  height: 35px;
  background: #F0F7FF;
  border-radius: 4px;
  line-height: 35px;
  padding-left: 17px;
  margin-bottom: 18px;
  color: #828282;
  span {
    color: #da2b2b;
    line-height: 22px;
  }
}
.formstyle {
  & > div {
    margin-bottom: 20px;
  }

  .el-select,
  .el-date-editor {
    width: 100%;
  }
  .elspan {
    .el-input__inner {
      border-radius: 4px 0 0 4px;
    }
    .el-input {
      width: 80%;
    }
    .el-form-item__content {
      span {
        width: 20%;
        display: inline-block;
        height: 40px;
        background: #f7f7f7;
        border: 1px solid #d9d9d9;
        border-radius: 4px 0 0 4px;
        border-radius: 0 4px 4px 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 40px;
        border-left: none;
        position: relative;
        top: 1px;
        text-align: center;
      }
    }
  }
  .pec.elspan {
    .el-input {
      width: 90%;
    }
    .el-form-item__content {
      span {
        width: 10%;
      }
    }
  }
  .el-table__body-wrapper {
    input[type="text"] {
      border: 1px solid #fff;
    }
    .el-table__row {
      input {
        padding: 6px 10px;
      }
    }
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #fff !important;
  }
  .htbox {
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    .htpad {
      padding: 0 17px;

      form {
        overflow: hidden;
      }
    }
    .htactive {
      height: 43px;
      overflow: hidden;
      background: #f1f1f1;
      line-height: 43px;
      span {
        display: inline-block;
        width: 100px;
        margin-left: 17px;
        float: left;
        cursor: pointer;
      }
    }
    .htno {
      height: 90px;
      border-bottom: 1px solid #e6e6e6;
      padding-top: 25px;
    }
    .uploadfont {
      color: rgba(0, 0, 0, 0.45);
      line-height: 42px;
      span {
        text-indent: 8em;
        display: inline-block;
      }
    }
    .uploadlist {
      padding: 30px 16px 0 16px;
      .upload-demo {
        margin-left: 8em;
      }
      .el-form-item {
        margin-bottom: 40px;
      }

      .el-form-item__label {
        font-size: 16px;
        color: #000000;
      }
      .el-upload-dragger {
        width: 236px;
        height: 92px;
      }
      .el-upload-dragger .el-upload__text,
      .el-icon-plus {
        color: rgba(0, 0, 0, 0.45);
      }
      .el-icon-plus {
        font-size: 30px;
      }
    }
    .el-icon-delete {
      float: right;
      padding: 12px 20px 0 0;
      cursor: pointer;
      font-size: 18px;
    }
    .ljje {
      text-align: right;
      margin-bottom: 70px;
      color: #595959;
      span {
        color: #f5212d;
      }
    }
  }
}
.easyformbtn {
  text-align: center;
  button {
    width: 160px;
    height: 40px;
    font-size: 16px;

    text-align: center;
    line-height: 18px;
    span {
      color: #ffffff;
    }
  }
  button:nth-last-child(1) {
    background-color: #2937A6;
  }
  button:nth-last-child(2) {
    span {
      color: #595959;
    }
  }
  button:hover {
    background-color: #edf5ff;
    span {
      color: #67a9fd;
    }
  }
}
.formstyle {
  .active {
    height: 133px;
    overflow: hidden;
  }
  .iconactive {
    transform: scaleY(-1);
  }
}
.addbtn {
  border: 1px dashed #cfcfcf;
  height: 44px;
  text-align: center;
  border-radius: 4px;
  line-height: 44px;
  font-size: 14px;
  color: #828282;
  cursor: pointer;
  i {
    margin-right: 6px;
  }
}
.yfdsize {
  text-indent: 0px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  margin-bottom: 29px;
}
.upload-contentr {
  .el-upload-dragger {
    line-height: 26px;
  }
}
.pt16 {
  position: relative;
  top: -16px;
}
.inlinelv {
  .firlabwid {
    width: calc(25% + 80px);
  }
  .el-form-item {
    width: calc(100% - 20px);
  }
  .perwidth {
    width: 20px;
    padding-left: 0 !important;
    position: relative;
    top: 15px;
  }
  .nolabwid {
    width: calc(25% - 80px);
    .el-form-item__content {
      margin-left: 0 !important;
    }
    .el-form-item__label {
      width: 0 !important;
    }
  }
}
.textindl {
  text-indent: 160px;
}
</style>
