<template>
  <div class="common-page-dataList">
    <el-tabs type="border-card" v-model="activeName" class="n-tabs">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist"
      >
        <el-table
          :data="listData"
          :header-cell-style="headerStyle"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
        >
          <el-table-column
            prop="payableBillNo"
            label="银e券编号"
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="originalName"
            label="原始开单企业"
            min-width="170"
          ></el-table-column>
          <el-table-column
            prop="corpName"
            label="转让企业"
            min-width="150"
          ></el-table-column>
          <el-table-column label="银e券金额(元)" min-width="150">
            <template slot-scope="scope">{{
              $changeMoneyThousand(scope.row.payableAmount)
            }}</template>
          </el-table-column>
          <el-table-column label="银e券余额(元)" min-width="150">
            <template slot-scope="scope">{{
              $changeMoneyThousand(scope.row.balanceAmount)
            }}</template>
          </el-table-column>
          <el-table-column label="银e券净额(元)" min-width="150">
            <template slot-scope="scope">{{
              $changeMoneyThousand(scope.row.payableBillNet)
            }}</template>
          </el-table-column>
          <el-table-column label="已融资金额(元)" min-width="150">
            <template slot-scope="scope">{{
              $changeMoneyThousand(scope.row.payableBillFinanceAmount)
            }}</template>
          </el-table-column>
          <el-table-column label="已转让金额(元)" min-width="150">
            <template slot-scope="scope">{{
              $changeMoneyThousand(scope.row.assignAmount)
            }}</template>
          </el-table-column>
          <el-table-column label="承诺付款日" min-width="130">
            <template slot-scope="scope">{{
              scope.row.promisePayTime | formatDate
            }}</template>
          </el-table-column>
          <!-- <el-table-column prop="payableBillApproveStatusName" label="银e券状态" min-width="120">
          </el-table-column> -->
          <el-table-column label="操作" width="200" align="left" fixed="right">
            <template slot-scope="scope">
              <el-button size="medium" type="text" @click="preview(scope.row)"
                >预览</el-button
              >
              <el-button
                size="medium"
                type="text"
                @click="handleEdit(scope.row)"
                >详情</el-button
              >
              <el-tooltip
                class="item"
                effect="dark"
                content="可全部或部分转让"
                placement="top"
              >
                <el-button
                  size="medium"
                  :disabled="scope.row.flag"
                  type="text"
                  @click="handlecredt(scope.row)"
                  >转让</el-button
                >
              </el-tooltip>

              <el-button
                v-if="!scope.row.flag"
                size="medium"
                type="text"
                @click="financing(scope.row)"
                >融资
              </el-button>

              <el-popover
                trigger="hover"
                placement="top"
                v-if="scope.row.flag"
                class="rzif"
              >
                <p>银e券即将到期，不能流转和融资</p>
                <div slot="reference" class="name-wrapper">
                  <el-button
                    size="medium"
                    :disabled="scope.row.flag || (+scope.row.payableBillNet || 0) === 0"
                    type="text"
                    @click="financing(scope.row)"
                    >融资
                  </el-button>
                </div>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </data-list>
    </el-tabs>

    <el-dialog title="" :visible.sync="centerDialogVisible" width="80%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <pdf class="pdf" ref="pdf" :src="src"></pdf>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from "../../api/api.js";
import pdf from "vue-pdf";
import { changeMoneyThousand } from "@/util/common";

import moment from "moment";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      showDetail: true,
      isShowAll: false,
      isHedden: false,
      loading: false,
      filterList: [
        {
          label: "银e券编号",
          type: "input",
          field: "payableBillNo",
          size: "small",
        },
        {
          label: "开单企业",
          type: "input",
          field: "originalName",
          size: "small",
        },
        {
          label: "转让方",
          type: "input",
          field: "corpName",
          size: "small",
        },
        {
          label: "承诺付款日:",
          type: "daterange",
          field: ["promisePayTimeStr", "promisePayTimeEnd"],
          size: "small",
        },
        // {
        //   label: "银e券金额(元): ",
        //   type: "inputrange",
        //   field: "payableAmountStr",
        //   size: "small",
        //   isSeparated: 1
        // },
        // {
        //   type: "inputrange",
        //   field: "payableAmountEnd",
        //   size: "small"
        // },
      ],
      activeName: "1",
      total: 0,
      listLoading: false,
      listData: [],
      centerDialogVisible: false,
      api: api,
      row: {},
      changeMoneyThousand: changeMoneyThousand,
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      fileType: "pdf", // 文件类型
      src: "", // pdf文件地址
    };
  },
  created() {},
  methods: {
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
      console.log("filterShowAll  -----", this.isShowAll);
    },
    headerStyle({ row, rowIndex }) {
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      console.log("filters<", filters);
      this.getLists(filters);
    },
    //查询
    getLists(param) {
      param.corpId = "1";
      api.HoldList(param).then((res) => {
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
        this.listLoading = false;
      });
    },
    financing(row) {
      this.$router.push({
        path: "/easypayApproveManage/easypayFinancing/recheckDetial",
        query: {
          id: row.payableBillId,
          type: "1",
          isshowbtn: 1,
          settlementChannel: row.settlementChannel, // 0上海银行 1中金支付
        },
      });
    },
    handleEdit(row) {
      var checkbtn = row.payableBillApproveStatusName == "外审审核" ? 1 : 2;
      if (row.payableBillNo.indexOf("-") != -1) {
        this.$router.push({
          name: "银e券流转子单详情",
          query: { id: row.payableBillId, isshowbtn: 2, flag: true },
        });
      } else {
        this.$router.push({
          name: "银e券流转详情",
          query: {
            id: row.payableBillId,
            isshowbtn: 2,
            showDetail: this.showDetail,
            detail: true,
          },
        });
      }
    },
    handlecredt(row) {
      this.$router.push({
        name: "银e券转让",
        query: { id: row.payableBillId },
      });
    },
    preview(row) {
      this.row = row;
      window.open(api.getImgURL(row.evidencePath));
      // this.listLoading = true;
      // api.DownloadContract({contractNo:row.contractNo}).then(res =>{
      //   var binaryData = []
      //   binaryData.push(res)
      //   this.src = window.URL.createObjectURL(new Blob(binaryData, {type:"application/zip"}))
      //   console.log('this.src ===>',this.src);
      // this.scr = pdf.createLoadingTask(this.src);
      // window.open('/static/pdf/web/viewer.html?file=' +encodeURIComponent(url) + '&.pdf');

      // console.log('res ===>',res);
      // this.src = res;
      // this.centerDialogVisible = true;
      // this.listLoading = false;
      // })
    },
    // 比较时间(承诺付款日)
    calcDiff(row) {
      let flag = false;
      if (row.promisePayTime) {
        const diff = moment(row.promisePayTime).diff(moment(), "days");
        if (diff < 7) flag = true;
      }
      return flag;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
.pdf {
  display: block !important;
  max-width: 1000px !important;
  margin: auto !important;
}
.rzif {
  .name-wrapper.el-popover__reference {
    display: inline-block;
    width: 20px;
    margin-left: 10px;
  }
}
</style>
