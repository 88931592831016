<template>
  <div>
    <div>
      <p style="line-height: 17.7pt; margin: 0pt">
        <a name="page1"></a
        ><span style="font-family: 楷体; font-size: 12pt">&#xa0;</span>
      </p>
      <p style="line-height: 16.55pt; margin: 0pt; text-align: center">
        <span style="font-family: 楷体; font-size: 14.5pt"
          >应收账款电子凭证</span
        ><span style="font-family: 楷体; font-size: 14.5pt"
          >转让通知书(银e券）</span
        >
      </p>

      <p style="line-height: 10pt; margin: 0pt">
        <span style="font-family: 楷体; font-size: 12pt">&#xa0;</span>
      </p>
      <p style="line-height: 17.15pt; margin: 0pt">
        <span style="font-family: 楷体; font-size: 12pt"> </span
        ><span style="font-family: 楷体; font-size: 12pt"
          >                                                        
        </span>
      </p>
      <p
        style="
          line-height: 13.15pt;
          margin: 0pt 19pt 0pt 0pt;
          text-indent: 19.2pt;
        "
      >
        <span style="font-family: 楷体; font-size: 10.5pt"
          >致：{{ fillData.openCorpName }}</span
        >
      </p>
      <p style="line-height: 13.35pt; margin: 0pt">
        <span style="font-family: 楷体; font-size: 16pt">&#xa0;</span>
      </p>
      <p style="margin: 0pt; text-align: justify; text-indent: 19.2pt">
        <span style="font-family: 楷体; font-size: 10.5pt"
          >鉴于我司（债权人）已与</span
        ><span style="font-family: 楷体; font-size: 10.5pt">{{
          fillData.receviedCorpName
        }}</span
        ><span style="font-family: 楷体; font-size: 10.5pt">（以下简称“</span
        ><span style="font-family: 楷体; font-size: 10.5pt">新债权人</span
        ><span style="font-family: 楷体; font-size: 10.5pt"
          >”）于{{ fillData.openTime }}</span
        ><span style="font-family: 楷体; font-size: 10.5pt"
          >签署了编号为{{ fillData.payableBillNo }}的《</span
        ><span style="font-family: 楷体; font-size: 10.5pt"
          >平台银e券转让协议</span
        ><span style="font-family: 楷体; font-size: 10.5pt">》</span
        ><span style="font-family: 楷体; font-size: 10.5pt">或《融资协议》</span
        ><span style="font-family: 楷体; font-size: 10.5pt"
          >，并就下表所列应收账款债权达成相应应收账款债权转让事宜。根据前述协议，下列表格所列的应收账款债权及因该应收账款债权享有的全部权利已由我司转让给</span
        ><span style="font-family: 楷体; font-size: 10.5pt">新债权人</span
        ><span style="font-family: 楷体; font-size: 10.5pt">：</span>
      </p>
      <p style="margin: 0pt; text-align: justify; text-indent: 19.2pt">
        <span style="font-family: 楷体; font-size: 10.5pt">&#xa0;</span>
      </p>

      <div style="text-align: center">
        <table class="mailTable" cellspacing="0" cellpadding="0">
          <tr>
            <td class="column">新债权人（现持单人）</td>
            <td>{{ fillData.receviedCorpName }}</td>
          </tr>
          <tr>
            <td class="column">债权人(前持单人)</td>
            <td>{{ fillData.transferCorpName }}</td>
          </tr>
          <tr>
            <td class="column">债务人(开单人)</td>
            <td>{{ fillData.openCorpName }}</td>
          </tr>
          <tr>
            <td class="column">银e券编号</td>
            <td>{{ fillData.payableBillNo }}</td>
          </tr>
          <tr>
            <td class="column">转让金额/应收账款债权金额</td>
            <td>{{ fillData.amount }}</td>
          </tr>
          <tr>
            <td class="column">银e券到期日</td>
            <td>{{ fillData.promisePayTime }}</td>
          </tr>
          <tr>
            <td class="column">转让金额/应收账款债权金额合计(大写)</td>
            <td>{{ fillData.amountUpper }}</td>
          </tr>
        </table>
      </div>
    </div>
    <p style="line-height: 12.4pt; margin: 0pt">
      <span style="font-family: 楷体; font-size: 16pt">&#xa0;</span>
    </p>
    <p
      style="
        line-height: 13.15pt;
        margin: 0pt;
        text-align: justify;
        text-indent: 19.2pt;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5pt">通过以上转让，</span
      ><span style="font-family: 楷体; font-size: 10.5pt">新债权人</span
      ><span style="font-family: 楷体; font-size: 10.5pt">已成为</span
      ><span style="font-family: 楷体; font-size: 10.5pt">贵司</span
      ><span style="font-family: 楷体; font-size: 10.5pt"
        >的债权人，享有我司原作为该应收账款债权人的所有权利和权益。现将有关具体事宜知会贵司，敬请</span
      ><span style="font-family: 楷体; font-size: 10.5pt">配合</span
      ><span style="font-family: 楷体; font-size: 10.5pt">。</span>
    </p>
    <p style="line-height: 7.35pt; margin: 0pt">
      <span style="font-family: 楷体; font-size: 16pt">&#xa0;</span>
    </p>
    <p style="margin: 0pt; text-align: justify; text-indent: 19.2pt">
      <span style="font-family: 楷体; font-size: 10.5pt"
        >我司确认，我司仅转让</span
      ><span style="font-family: 楷体; font-size: 10.5pt">基础合同</span
      ><span style="font-family: 楷体; font-size: 10.5pt"
        >项下我方享有的应收账款债权的权益而不转让相关的义务及责任，基础合同项下对</span
      ><span style="font-family: 楷体; font-size: 10.5pt">贵司</span
      ><span style="font-family: 楷体; font-size: 10.5pt"
        >的所有承诺、保证、义务和责任仍由我司履行</span
      ><span style="font-family: 楷体; font-size: 10.5pt">。</span>
    </p>
    <p style="line-height: 7.35pt; margin: 0pt">
      <span style="font-family: 楷体; font-size: 16pt">&#xa0;</span>
    </p>
    <p
      style="
        line-height: 15.6pt;
        margin: 0pt;
        text-align: justify;
        text-indent: 19.2pt;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5pt">我司同意，除取得</span
      ><span style="font-family: 楷体; font-size: 10.5pt">新债权人</span
      ><span style="font-family: 楷体; font-size: 10.5pt"
        >事先书面同意外，否则我司不得从贵司收取我司对贵司享有的上述表格所列的相应应收账款债权下的任何款项或就此获得清偿，我司不再就该笔应收账款债权向贵司直接请求付款。请</span
      ><span style="font-family: 楷体; font-size: 10.5pt">贵</span
      ><span style="font-family: 楷体; font-size: 10.5pt">司</span
      ><span style="font-family: 楷体; font-size: 10.5pt">按照</span
      ><span style="font-family: 楷体; font-size: 10.5pt"
        >上述表格所列基础合同及出具的《付款</span
      ><span style="font-family: 楷体; font-size: 10.5pt">承诺函</span
      ><span style="font-family: 楷体; font-size: 10.5pt"
        >》（编号为：{{ fillData.sourceNo }}）的约定，于</span
      ><span style="font-family: 楷体; font-size: 10.5pt">银e券</span
      ><span style="font-family: 楷体; font-size: 10.5pt">到期</span
      ><span style="font-family: 楷体; font-size: 10.5pt">日</span
      ><span style="font-family: 楷体; font-size: 10.5pt">当日</span
      ><span style="font-family: 楷体; font-size: 10.5pt">1</span
      ><span style="font-family: 楷体; font-size: 10.5pt">5</span
      ><span style="font-family: 楷体; font-size: 10.5pt">:00前向</span
      ><span style="font-family: 楷体; font-size: 10.5pt">新债权人</span
      ><span style="font-family: 楷体; font-size: 10.5pt">或</span
      ><span style="font-family: 楷体; font-size: 10.5pt">新债权人</span
      ><span style="font-family: 楷体; font-size: 10.5pt"
        >指定的第三方履行上述表格所列相应应收账款债权项下的付款义务。</span
      >
    </p>
    <p
      style="
        line-height: 15.6pt;
        margin: 0pt;
        text-align: justify;
        text-indent: 19.2pt;
      "
    >
      <span style="font-family: 楷体; font-size: 16pt">&#xa0;</span>
    </p>
    <p
      style="
        line-height: 15.6pt;
        margin: 0pt;
        text-align: justify;
        text-indent: 19.2pt;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5pt">新债权人</span
      ><span style="font-family: 楷体; font-size: 10.5pt">书面指定的</span
      ><span style="font-family: 楷体; font-size: 10.5pt">下列</span
      ><span style="font-family: 楷体; font-size: 10.5pt"
        >收款账户为贵司付款的唯一目标账户，未经</span
      ><span style="font-family: 楷体; font-size: 10.5pt">新债权人</span
      ><span style="font-family: 楷体; font-size: 10.5pt"
        >书面通知，账户不得增加和变更</span
      ><span style="font-family: 楷体; font-size: 10.5pt">：</span>
    </p>
    <p
      style="
        line-height: 15.6pt;
        margin: 0pt;
        text-align: justify;
        text-indent: 19.2pt;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5pt">&#xa0;</span>
    </p>
    <p
      style="
        line-height: 15.6pt;
        margin: 0pt;
        text-align: justify;
        text-indent: 19.2pt;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5pt">户名：</span
      ><span
        style="font-family: 楷体; font-size: 10.5pt; text-decoration: underline"
      ></span
      ><span style="font-family: 楷体; font-size: 10.5pt">{{
        fillData.accountName
      }}</span
      ><span
        style="font-family: 楷体; font-size: 10.5pt; text-decoration: underline"
      ></span>
    </p>
    <p
      style="
        line-height: 15.6pt;
        margin: 0pt;
        text-align: justify;
        text-indent: 19.2pt;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5pt"
        >账号：{{ fillData.accountNo }}</span
      >
    </p>
    <p
      style="
        line-height: 15.6pt;
        margin: 0pt;
        text-align: justify;
        text-indent: 19.2pt;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5pt"
        >开户行：{{ fillData.openBankName }}</span
      >
    </p>
    <p
      style="
        line-height: 15.6pt;
        margin: 0pt;
        text-align: justify;
        text-indent: 19.2pt;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5pt">&#xa0;</span>
    </p>
    <p style="line-height: 15.6pt; margin: 0pt; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5pt"
        >    如贵司未按上述回款账户回款，则视为贵司未履行相应付款义务。</span
      >
    </p>
    <p
      style="
        line-height: 15.6pt;
        margin: 0pt;
        text-align: justify;
        text-indent: 19.2pt;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5pt">本通知自我方</span
      ><span style="font-family: 楷体; font-size: 10.5pt">加盖签电子签章</span
      ><span style="font-family: 楷体; font-size: 10.5pt"
        >之日起生效，且不可撤销。</span
      ><span style="font-family: 楷体; font-size: 10.5pt"
        >基础合同与本通知书有不一致的，包括但不限于应收账款到期日等，以本通知书为准。</span
      >
    </p>
    <p
      style="
        line-height: 15.6pt;
        margin: 0pt;
        text-align: justify;
        text-indent: 19.2pt;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5pt">&#xa0;</span>
    </p>
    <p style="line-height: 12.6pt; margin: 0pt">
      <span style="font-family: 楷体; font-size: 16pt">&#xa0;</span>
    </p>
    <p
      style="
        line-height: 15.6pt;
        margin: 0pt;
        text-align: right;
        text-indent: 19.2pt;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5pt"
        >债权人:{{ fillData.transferCorpName }}</span
      >
    </p>
    <p
      style="
        line-height: 15.6pt;
        margin: 0pt;
        text-align: right;
        text-indent: 19.2pt;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5pt">（盖章）</span>
    </p>
    <p
      style="
        line-height: 15.6pt;
        margin: 0pt;
        text-align: right;
        text-indent: 19.2pt;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5pt">&#xa0;</span>
    </p>
    <p style="line-height: 10.85pt; margin: 0pt; text-align: right">
      <span style="font-family: 楷体; font-size: 10.5pt">{{
        fillData.signDate
      }}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    fillData: {
      openCorpName: null,
      receviedCorpName: null,
      openTime: null,
      payableBillNo: null,
      transferCorpName: null,
      openCorpName: null,
      payableBillNo: null,
      amount: null,
      promisePayTime: null,
      amountUpper: null,
      sourceNo: null,
      accountName: null,
      openBankName: null,
      accountNo: null,
      signDate: null,
    },
  },
};
</script>
