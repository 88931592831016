<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList">
      <el-steps :active="activebtn" align-center>
        <el-step title="基本信息"></el-step>
        <el-step title="交易文件上传"></el-step>
        <el-step title="银e券开立"></el-step>
      </el-steps>
      <div class="easypayby">
        <div v-show="activebtn == 0" class="yfdtwo">
          <div class="yfdsize">基本信息</div>
          <div class="formstyle">
            <el-form
              :model="PayableBillAddRequest"
              :rules="rules"
              ref="PayableBillAddRequest"
              label-width="198px"
              class="demo-ruleForm"
            >
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="资金机构: " prop="fundCorpId">
                    <el-select
                      v-model="PayableBillAddRequest.fundCorpId"
                      placeholder="请选择资金机构"
                      filterable
                      @change="changefundCorpName"
                    >
                      <el-option
                        v-for="(item, index) in zjlist"
                        :key="index"
                        :label="item.corpName"
                        :value="item.corpId"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="授信编号: " prop="grantCreditId">
                    <el-select
                      v-model="PayableBillAddRequest.grantCreditId"
                      placeholder="请选择授信编号"
                      @change="changesxName"
                    >
                      <el-option
                        v-for="item in sxbhlist"
                        :key="item.grantCreditId"
                        :label="item.grantCreditNo"
                        :value="item.grantCreditId"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="授信截止日期: ">
                    <el-input
                      v-model="PayableBillAddRequest.grantCloseTime"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="可用授信额度(元): " class="">
                    <el-input
                      v-model="PayableBillAddRequest.grantableAmount"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="可用授信额度(大写): " class="">
                    <el-input
                      v-model="PayableBillAddRequest.grantableAmountCap"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <!-- <el-col :span="12">
                <el-form-item label="资金年化利率: "  class="elspan pec">
                  <el-input v-model="PayableBillAddRequest.fundYearInterest"  disabled></el-input><span>%</span>
                </el-form-item>
              </el-col> -->
                <el-col :span="12">
                  <el-form-item
                    label="预估融资利率(%/年化): "
                    class="elspan pec"
                  >
                    <el-input
                      v-model="PayableBillAddRequest.financeYearInterest"
                      disabled
                    ></el-input
                    ><span>%</span>
                  </el-form-item>
                  <!-- <p style="color:red">
                        注：以融资审批通过后，合同签署时，实际放款利率为准
                </p> -->
                </el-col>
                <el-col :span="12">
                  <p style="color: red">
                    注：以融资审批通过后，合同签署时，实际放款利率为准
                  </p>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="接收企业全称: " prop="receivedCorpId">
                    <el-select
                      v-model="PayableBillAddRequest.receivedCorpName"
                      filterable
                      placeholder="请选择"
                      @change="receivedCorpIdfn"
                    >
                      <el-option
                        v-for="(item, index) in qyallname"
                        :key="index"
                        :label="item.corpName"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="接收企业社会统一信用代码: ">
                    <el-input
                      v-model="PayableBillAddRequest.receivedCorpCreditCode"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item
                    label="接收企业确认时限: "
                    prop="receivedCorpAck"
                  >
                    <el-select
                      v-model="PayableBillAddRequest.receivedCorpAck"
                      placeholder="请选择确认时限"
                    >
                      <el-option label="自审批完毕24小时" value="0"></el-option>
                      <el-option label="自审批完毕48小时" value="1"></el-option>
                      <el-option label="自审批完毕72小时" value="2"></el-option>
                      <!--<el-option label="无时限" value="3"></el-option>-->
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div v-if="showAlert" class="account-warn">
              系统检测到贵司尚未开通结算渠道的账户，请联系系统管理员进行开通。未开通则无法进行接收，融资和后续的清分结算功能。
              <!-- <router-link to="/Business/Bank_management">点击这里</router-link>绑定银行账户。 -->
            </div>
            <div class="wxtsk">
              <p class="wxts">
                温馨提示：选择确认时限后，如果链属企业没有在规定的时间内完成确认，银e券会自动退回。
              </p>
              <p class="wxts" style="text-indent: 5em">
                没有找到
                <el-link
                  style="text-indent: 0"
                  :underline="false"
                  type="primary"
                  @click="goLink"
                  >可接收企业</el-link
                >？请先在链属企业名单添加该企业。并邀请企业完成注册。
              </p>
            </div>
          </div>
        </div>
        <div v-show="activebtn == 1" class="yfdtwo">
          <div class="yfdsize">金额信息</div>
          <div class="yfdbaseline">
            <el-form
              :model="ruleform"
              :rules="rules2"
              ref="ruleform"
              label-width="182px"
              class="demo-ruleForm"
            >
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="银e券金额(元): " prop="payableAmount">
                    <el-input
                      v-model="ruleform.payableAmount"
                      :placeholder="'最多可输入' + quotaMin"
                      @change="tocapital"
                    >
                      <template #suffix>
                        <el-tooltip class="item" placement="top">
                          <template #content>
                            <p v-if="quota.remainAmount !== null">
                              当前授信剩余可开立额度
                              {{ quota.remainAmount || 0 }} 元
                            </p>
                            <p v-if="quota.maxFinanceAmount !== null">
                              当前银行允许单笔最大可开立额度
                              {{ quota.maxFinanceAmount || 0 }} 元
                            </p>
                            <p v-if="quota.availableAmount !== null">
                              贵核心企业允许当前供应商单笔最大可开立额度
                              {{ quota.availableAmount || 0 }} 元
                            </p>
                          </template>
                          <i
                            class="el-icon-edit el-input__icon"
                            style="cursor: pointer"
                          ></i>
                        </el-tooltip>
                      </template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="银e券金额(大写): " class="minsizer">
                    <el-input v-model="ruleform.remark" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="承诺付款天数:"
                    class="minsizer"
                    prop="promiseDay"
                  >
                    <el-input
                      placeholder="最少1天"
                      v-model="ruleform.promiseDay"
                      @input="
                        (v) => (ruleform.promiseDay = v.replace(/[^\d]/g, ''))
                      "
                      @change="changeHandle('day', ruleform.promiseDay)"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="承诺付款日: " prop="promisePayTime">
                    <el-date-picker
                      type="date"
                      @change="changeHandle('other', ruleform.promiseDay)"
                      placeholder="选择日期"
                      v-model="ruleform.promisePayTime"
                      style="width: 100%"
                      :editable="false"
                      :picker-options="expireTimerOptions"
                      value-format="yyyy-MM-dd"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="yfdsize">合同信息</div>
          <div class="httotal">
            已添加合同
            <span>{{ ruleform2.contractList.length }}</span> 笔，合同总计金额
            <span>{{ changeMoneyThousand(mounttoall) }}</span> 元。
          </div>
          <div class="formstyle">
            <div v-for="(item, index) in ruleform2.contractList" :key="index">
              <div class="htbox" :class="{ active: item.isactive }">
                <div class="htactive">
                  <span>
                    <i
                      :class="
                        item.isactive
                          ? 'fa fa-plus-circle'
                          : 'fa fa-minus-circle'
                      "
                      @click="item.isactive = !item.isactive"
                    ></i>
                    交易合同
                  </span>
                  <i class="el-icon-delete" @click="delDomain(index)"></i>
                </div>
                <div class="htpad">
                  <el-form
                    :model="item"
                    :rules="item.rules"
                    label-position="right"
                    :ref="item.name + index"
                    class="demo-ruleForm"
                  >
                    <div class="info">
                      <el-col :span="12">
                        <el-form-item
                          label="合同编号: "
                          prop="contractNo"
                          label-width="100px"
                        >
                          <el-input v-model="item.contractNo"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item
                          label="合同名称: "
                          prop="contractName"
                          label-width="100px"
                        >
                          <el-input v-model="item.contractName"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item
                          label="合同金额: "
                          prop="contractAmount"
                          label-width="100px"
                        >
                          <el-input
                            v-model="item.contractAmount"
                            @blur="jsmount(item.contractAmount)"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item
                          label="合同金额(大写): "
                          prop="contractAmount"
                          label-width="120px"
                        >
                          <el-input
                            v-model="item.contractAmountCap"
                            disabled
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item
                          label="签署日期: "
                          prop="contractTime"
                          label-width="100px"
                        >
                          <el-date-picker
                            type="date"
                            placeholder="选择日期"
                            v-model="item.contractTime"
                            value-format="yyyy-MM-dd"
                            :editable="false"
                            :picker-options="expireTimerOptions2"
                          ></el-date-picker>
                        </el-form-item>
                      </el-col>
                    </div>
                    <div class="uploadlist">
                      <el-form-item
                        label="上传相关合同: "
                        prop="files"
                        class="upload-text-requird"
                      >
                        <!-- <el-input v-model="item.files[0]" v-show="false" type='file'></el-input>                          -->
                        <div class="uploadfont wxtsk">
                          温馨提示：仅支持JPG、PNG、PDF、ZIP格式。上传的合同必须加盖骑缝章，按照合同页数进行排列。
                          <br />
                          <span class="pt16"
                            >使用高质量图片，尽量拍摄清晰防止审批造成不必要的时间损失。由于拍摄质量造成的审批不合格平台概不负责。</span
                          >
                        </div>
                        <el-upload
                          :ref="item.name + index + 'a'"
                          class="upload-demo upload-contentr"
                          :action="baseURL + '/web/file/uploadFile'"
                          :on-success="
                            (a, b, c) => dragupfn(a, b, c, item, index)
                          "
                          :on-remove="(a, b) => dragremovefn(a, b, item, index)"
                          :headers="Authorization"
                          :file-list="item.filelist"
                          :on-preview="handlePreview"
                          accept="image/png, image/jpeg, application/zip, application/rar, application/pdf"
                          :before-upload="beforeupfnfile"
                          drag
                          multiple
                          list-type="picture"
                        >
                          <i class="el-icon-plus"></i>
                          <div class="el-upload__text">上传合同</div>
                        </el-upload>
                      </el-form-item>
                    </div>
                    <div class="uploadlist">
                      <el-form-item
                        label="上传相关发票: "
                        prop="img"
                        class="upload-text-requird"
                      >
                        <div class="uploadfont wxtsk">
                          温馨提示：仅支持JPG、PNG格式。上传的发票必须真实不能重复。
                          <br />
                          <span class="pt16"
                            >使用高质量图片，尽量拍摄清晰防止审批造成不必要的时间损失。由于拍摄质量造成的审批不合格平台概不负责。</span
                          >
                        </div>
                        <el-upload
                          class="upload-demo upload-contentr"
                          :action="baseURL + '/web/file/uploadInvoice'"
                          :on-success="
                            (a, b, c) => handleupfn(a, b, c, item, index)
                          "
                          :on-remove="(a, b) => handleRemove(a, b, item, index)"
                          :on-preview="handlePreview"
                          :on-error="erruploadfp"
                          :headers="Authorization"
                          :file-list="item.filelistfp"
                          accept="image/png, image/jpeg, image/jpg"
                          :before-upload="beforeupfn"
                          multiple
                          list-type="picture"
                          drag
                          ref="uploadFile1"
                        >
                          <i class="el-icon-plus"></i>
                          <div class="el-upload__text">上传发票</div>
                        </el-upload>
                      </el-form-item>
                      <el-form-item>
                        <el-table
                          :data="item.invoiceList"
                          @cell-mouse-enter="cellhover"
                          @cell-mouse-leave="cellleave"
                          style="width: 100%; top: 20px"
                        >
                          <el-table-column
                            type="index"
                            label="序号"
                            width="80"
                          ></el-table-column>
                          <el-table-column
                            label="购买方"
                            align="left"
                            min-width="200"
                          >
                            <template slot-scope="scope">
                              <input
                                type="text"
                                v-model.trim="scope.row.invoiceBuyer"
                              />
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="销售方"
                            align="left"
                            min-width="200"
                          >
                            <template slot-scope="scope">
                              <input
                                type="text"
                                v-model.trim="scope.row.invoiceSeller"
                              />
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="发票代码"
                            align="left"
                            min-width="200"
                          >
                            <template slot-scope="scope">
                              <input
                                type="text"
                                v-model.trim="scope.row.invoiceCode"
                              />
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="发票号码"
                            align="left"
                            min-width="200"
                          >
                            <template slot-scope="scope">
                              <input
                                type="text"
                                v-model.trim="scope.row.invoiceNo"
                              />
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="检验码"
                            align="left"
                            min-width="200"
                          >
                            <template slot-scope="scope">
                              <input
                                type="text"
                                v-model.trim="scope.row.invoiceCheckCode"
                              />
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="开票日期"
                            align="left"
                            min-width="200"
                          >
                            <template slot-scope="scope">
                              <input
                                type="text"
                                v-model.trim="scope.row.invoiceOpenTime"
                              />
                            </template>
                          </el-table-column>

                          <el-table-column
                            label="不含税开具金额（元）"
                            align="left"
                            min-width="200"
                          >
                            <template slot-scope="scope">
                              <input
                                type="text"
                                v-model.trim="scope.row.invoiceAmountWithoutTax"
                                class="tabright"
                              />
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="开具金额（元）"
                            align="right"
                            min-width="200"
                          >
                            <template slot-scope="scope">
                              <input
                                type="text"
                                v-model="scope.row.invoiceAmount"
                                class="tabright"
                                @blur="
                                  blurfptotal(scope.row, scope.$index, item)
                                "
                              />
                            </template>
                          </el-table-column>
                        </el-table>
                      </el-form-item>
                      <div class="ljje">
                        累计发票金额:
                        <span>{{ changeMoneyThousand(item.fptotal) }}</span> 元
                      </div>
                    </div>
                    <div class="uploadlist">
                      <el-form-item label="上传其他资料: ">
                        <!-- <div class="uploadfont ">
                      <span class="pt16">仅支持JPG、PNG、PDF、ZIP格式。</span>
                      <div class="uploadfont wxtsk">
                        温馨提示：上传其他补充资料包括但不限于出入库单，物流单，结算单，银行流水等。
                        <br />
                      </div>
                        </div>-->
                        <div class="uploadfont wxtsk">
                          温馨提示：仅支持JPG、PNG、PDF、ZIP格式。
                          <br />
                          <span class="pt16"
                            >上传其他补充资料包括但不限于出入库单，物流单，结算单，银行流水等。</span
                          >
                        </div>
                        <el-upload
                          ref="uploadFile"
                          class="upload-demo upload-contentr"
                          :action="baseURL + '/web/file/uploadFile'"
                          :on-success="
                            (a, b, c) => dragupotherfn(a, b, c, item, index)
                          "
                          :on-remove="
                            (a, b) => dragremoveotherfn(a, b, item, index)
                          "
                          :on-error="erruploadfp"
                          :file-list="item.otherListfp"
                          :headers="Authorization"
                          accept="image/png, image/jpeg, application/zip, application/rar, application/pdf"
                          :before-upload="beforeupfnfile"
                          :on-preview="handlePreview"
                          list-type="picture"
                          drag
                          multiple
                        >
                          <i class="el-icon-plus"></i>
                          <div class="el-upload__text">上传</div>
                        </el-upload>
                        <!-- <el-upload
                        class="upload-demo upload-contentr"
                        :action="baseURL+'/web/file/uploadFile'"
                        :on-success='(a,b,c)=>dragupotherfn2(a,b,c,item,index)'
                        :on-remove='(a,b)=>dragremoveotherfn2(a,b,item,index)'
                        :on-error='erruploadfp'
                        :file-list="item.otherListfp2"
                        list-type="picture"
                        accept="image/png,image/jpeg,application/zip,application/rar,application/pdf"
                        :before-upload='beforeupfnfile'
                        drag
                        &gt;
                        <i class="el-icon-plus"></i>
                        &lt;!&ndash;<div class="el-upload__text">上传附件</div>&ndash;&gt;
                        </el-upload>-->
                      </el-form-item>
                    </div>
                    <!-- <el-button @click="delDomain(index)">删除</el-button> -->
                  </el-form>
                </div>
              </div>
            </div>
            <div class="addbtn" @click="addDomain">
              <i class="el-icon-plus"></i>
              <span>添加合同</span>
            </div>
            <div style="text-align: center" class="xytext">
              <el-checkbox v-model="checked" @change="showDialog(checked)"
                >我已阅读并同意</el-checkbox
              >
              <a
                href="javascript:;"
                style="display: inline-block; margin: 0 5px"
                @click="showDialog(checked, '1')"
                >《银e券开单协议》</a
              >
              <a href="javascript:;" @click="showDialog(checked, '2')"
                >《付款承诺函》</a
              >
            </div>
            <!-- <el-button @click="addDomain">新增域名</el-button> -->
          </div>
        </div>
        <div v-show="activebtn == 2">
          <div class="easybaseline">银e券开立</div>
        </div>
        <div class="easyformbtn">
          <el-button
            style="margin-top: 12px"
            @click="prevbtn"
            v-show="activebtn != 0"
            >上一步</el-button
          >
          <el-button
            style="margin-top: 12px"
            @click="nextbtn"
            :disabled="forbidden"
            >开立</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog
      id="dialog2"
      v-if="agreementDialog"
      class="agreement-dialog2"
      :visible.sync="agreementDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="50%"
      center
    >
      <div class="agreement-container">
        <open-order
          :amount="ruleform.remark"
          :promisePayTime="ruleform.promisePayTime"
          :signAgreementDetail="signAgreementDetail"
          v-if="fileType == '1'"
        ></open-order>
        <promise-agreement
          v-if="fileType == '2'"
          :payInfo="payInfo"
        ></promise-agreement>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          v-show="!showType && !readDisabled"
          @click="hasRead()"
          >同意</el-button
        >
        <el-button
          type="primary"
          v-show="!showType && readDisabled"
          :disabled="true"
          >阅读并同意 {{ readCount }}秒</el-button
        >
        <el-button type="primary" @click="closeDialog">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="结算渠道账户检测"
      :visible.sync="accountResultDialog"
      v-if="accountResultDialog"
      width="40%"
    >
      <accountResult1 v-if="accountResult == 1"></accountResult1>
      <accountResult2
        v-if="accountResult == 2"
        :cltNm="cltNm"
        :subNo="subNo"
      ></accountResult2>
      <accountResult3
        v-if="accountResult == 3"
        :remark="remark"
      ></accountResult3>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button
          type="primary"
          v-if="accountResult != 2"
          @click="accountResultDialog = false"
          >我知道了</el-button
        >
        <el-button
          type="primary"
          v-if="accountResult == 3"
          @click="goOpenAccount"
          >重新开户</el-button
        >
        <el-button
          type="primary"
          v-if="accountResult == 2 && isTiedCard !== 2"
          @click="goAccountManagement"
          >激活账户</el-button
        >
      </div>
    </el-dialog>
  </layout-main-view>
</template>
<script>
import api from "@/api/api";
import { convertCurrency } from "@/util/common.js";
import { baseURL } from "@/api/sys";
import moment from "moment";
import { changeMoneyThousand } from "@/util/common";
import openOrder from "@/components/agreementList/open-order";
import promiseAgreement from "@/components/agreementList/pormise-agreement";
import accountResult1 from "@/components/accountResult1.vue";
import accountResult2 from "@/components/accountResult2.vue";
import accountResult3 from "@/components/accountResult3.vue";
import pdfimg from "@/assets/zip.png";
import ukey from "@/util/ukey.js";

Number.prototype["adds"] = function (...arg) {
  var r1,
    r2,
    m,
    result = this;
  arg.forEach((value) => {
    try {
      r1 = result.toString().split(".")[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = value.toString().split(".")[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    result = Math.round(result * m + value * m) / m;
  });
  return result;
};
function openDownloadDialog(url, saveName) {
  // for ie 10 and later

  if (window.navigator.msSaveBlob) {
    try {
      window.navigator.msSaveBlob(url, saveName);
    } catch (e) {
      console.log(e);
    }
  }
  // 谷歌浏览器 创建a标签 添加download属性下载
  else {
    if (typeof url == "object" && url instanceof Blob) {
      url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement("a");
    aLink.href = url;
    aLink.download = saveName || ""; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) {
      event = new MouseEvent("click");
    } else {
      event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
    }
    aLink.dispatchEvent(event);
  }
}

export default {
  components: {
    "open-order": openOrder,
    "promise-agreement": promiseAgreement,
    accountResult1,
    accountResult2,
    accountResult3,
  },
  data() {
    var checkmaxmount = (rule, value, callback) => {
      if (!/^\d{1,12}(\.\d{1,2})?$/.test(value)) {
        callback(new Error("请输入12位以内的数字,小数点后最多2位"));
      } else {
        if (this.quotaMin !== null && value > this.quotaMin) {
          callback(new Error("银e券金额必须小于等于可用授信额度"));
        } else {
          callback();
        }
      }
    };
    return {
      signAgreementDetail: {},
      fileType: "1",
      showType: "",
      agreementDialog: false,
      readCount: "",
      readDisabled: true,
      checked: false,
      baseURL: baseURL,
      loading: false,
      breadcrumblist: ["银e券开立"],
      activebtn: 0,
      rules: {
        fundCorpId: [
          { required: true, message: "请选择资金机构", trigger: "change" },
        ],
        grantCreditId: [
          { required: true, message: "请选择授信编号", trigger: "change" },
        ],
        grantCloseTime: [{ required: true }],
        fundYearInterest: [{ required: true }],
        financeYearInterest: [{ required: true }],
        receivedCorpId: [
          { required: true, message: "请选择接收企业", trigger: "change" },
        ],
        receivedCorpCreditCode: [{ required: true }],
        receivedCorpAck: [
          { required: true, message: "请选择确认时限", trigger: "change" },
        ],
      },
      rules2: {
        payableAmount: [
          { validator: checkmaxmount, trigger: "change" },
          { required: true, message: "请输入银e券金额", trigger: "change" },
        ],
        promisePayTime: [{ required: true, message: "请选择承诺付款日" }],
        promiseDay: [
          { required: true, message: "请输入承诺付款天数" },
          {
            message: "请输入至少为1的整数",
            // pattern: /^([1-9]{1}|[1-9]{1}[0-9]{1}|1[0-7]{1}[0-9]{1}|180)$/,
            pattern: /^[1-9]\d*$/,
          },
        ],
      },
      bclick: false,
      PayableBillAddRequest: {
        fundCorpId: "",
        fundCorpName: "",
        grantCreditId: "",
        grantCloseTime: "",
        fundYearInterest: "",
        financeYearInterest: "",
        receivedCorpId: "",
        receivedCorpName: "",
        receivedCorpCreditCode: "",
        receivedCorpAck: "",
        grantCreditNo: "",
      },
      zjlist: [],
      sxbhlist: [],
      qyallname: [],
      ruleform: {
        remark: "",
        payableAmount: "",
        promiseDay: "",
        promisePayTime: "",
      },
      ruleform2: {
        contractList: [
          {
            name: "form",
            contractAmount: "",
            contractAmountCap: "",
            contractNo: "",
            contractName: "",
            contractTime: "",
            imageList: [],
            invoiceList: [],
            fptotal: 0,
            otherList: [],
            rules: {
              contractNo: [
                { required: false, message: "请输入合同编号" },
                { pattern: /^.{1,50}$/, message: "请输入50位以内的编号" },
              ],
              contractName: [{ required: true, message: "请输入合同名称" }],
              contractAmount: [
                { required: true, message: "请输入合同金额" },
                {
                  pattern: /^\d{1,12}(\.\d{1,2})?$/,
                  message: "请输入12位以内的数字,小数点后最多2位",
                },
              ],
              contractTime: [{ required: false, message: "请选择签署日期" }],
            },
            filelist: [],
            filelistfp: [],
            otherListfp: [],
            otherListfp2: [],
            isactive: false,
          },
        ],
      },
      expireTimerOptions: this.expireTimerOptionsFun(),

      // expireTimerOptions: {
      //               disabledDate(time) {
      //                   return time.getTime() < new Date(new Date().toLocaleDateString()).getTime()
      //               }
      // },
      expireTimerOptions2: {
        disabledDate(time) {
          return (
            time.getTime() > new Date(new Date().toLocaleDateString()).getTime()
          );
        },
      },
      listfile: [],
      convertCurrency: convertCurrency,
      mounttoall: 0,
      Authorization: { Authorization: sessionStorage.token },
      changeMoneyThousand: changeMoneyThousand,
      payInfo: {
        receiveCompanyName: "",
        amountMoney: "",
        promisePayDate: "",
        currentComapnyName: "",
      },
      invoiceAmounttotal: 0,
      penaltyInterest: "",
      forbidden: false,
      accountResult: "", // 开户结果
      accountResultDialog: false,
      cltNm: "",
      subNo: "",
      remark: "",
      openAccountId: "",
      isTiedCard: "", // 2已激活 无需提醒
      showAlert: false,
      isEdit: false,
      // 额度
      quota: {
        // 剩余授信额度
        remainAmount: null,
        // 最大融资额度
        maxFinanceAmount: null,
        // 供应商最大融资额度
        availableAmount: null,
      },
    };
  },
  computed: {
    /** 额度最小值 */
    quotaMin() {
      const quotaDatas = Object.values(this.quota).filter(
        (item) => item !== null
      );
      if (quotaDatas.length) {
        return Math.min(...quotaDatas);
      }
      return null;
    },
  },
  created() {
    this.getGrantCreditCorpList();
    this.getCorpList3();
  },
  methods: {
    handlePreview(file, fileList) {
      if (
        /jpg|jpeg|png/gi.test(
          file.raw.name.split(".")[file.raw.name.split(".").length - 1]
        )
      ) {
        window.open(file.url);
      } else {
        openDownloadDialog(file.raw, file.raw.name);
      }
    },
    DateDifference(faultDate, completeTime) {
      // let d1 = new Date(faultDate);
      // let d2 = new Date(completeTime);
      const stime = new Date(faultDate).getTime();
      const etime = new Date(completeTime).getTime();
      const usedTime = etime - stime; //两个时间戳相差的毫秒数
      const days = Math.floor(usedTime / (24 * 3600 * 1000));
      //计算出小时数
      const leave1 = usedTime % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      const hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      const leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      const minutes = Math.floor(leave2 / (60 * 1000));
      // var time = days + "天"+hours+"时"+minutes+"分";
      return days;
    },
    changeHandle(type, newValue) {
      if (type === "day") {
        // 天数 算 日期
        if (!newValue) {
          this.ruleform.promisePayTime = "";
          return;
        }
        let dateTime = new Date();
        dateTime = new Date(
          dateTime.setDate(dateTime.getDate() + parseInt(newValue))
        );
        this.ruleform.promisePayTime = dateTime;
        console.log(this.ruleform.promisePayTime, this.ruleform.promiseDay);
        if (this.ruleform.promiseDay) {
          this.getTime(this.ruleform.promisePayTime);
        }
      } else {
        let date = new Date(this.ruleform.promisePayTime);
        if (
          this.ruleform.promisePayTime &&
          date instanceof Date &&
          !isNaN(date.getTime())
        ) {
          this.getTime(this.ruleform.promisePayTime);
          this.ruleform.promiseDay =
            this.DateDifference(new Date(), this.ruleform.promisePayTime) + 1;
        } else {
          this.ruleform.promiseDay = "";
        }
      }
    },
    getTime(val) {
      let dat = Date.parse(val);
      let timer = new Date(parseInt(dat));
      let repYear = timer.getFullYear();
      let repMonth = timer.getMonth() + 1;
      let repDay = timer.getDate();
      let params = {
        repYear,
        repMonth,
        repDay,
      };
      this.checkPromisePayTime(params);
    },
    checkPromisePayTime(params) {
      console.log(params, "params");
      api.checkPromisePayTime(params).then((res) => {
        if (res.success) {
          console.log(res);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 超链接跳转
    goLink() {
      this.$router.push("/Business/Business_management");
    },
    expireTimerOptionsFun() {
      let _this = this;
      return {
        disabledDate: (time) => {
          return (
            time.getTime() <=
              new Date(new Date().toLocaleDateString()).getTime() ||
            time.getTime() >
              new Date(_this.PayableBillAddRequest.grantCloseTime).getTime()
          );
        },
      };
    },
    tocapital() {
      this.ruleform.remark = this.convertCurrency(this.ruleform.payableAmount);
    },
    addDomain() {
      this.ruleform2.contractList.push({
        name: "form",
        contractAmount: "",
        contractAmountCap: "",
        contractNo: "",
        contractName: "",
        contractTime: "",
        imageList: [],
        invoiceList: [],
        otherList: [],

        fptotal: 0,
        rules: {
          contractNo: [
            { required: false, message: "请输入合同编号" },
            { pattern: /^.{1,50}$/, message: "请输入50位以内的编号" },
          ],
          contractName: [{ required: true, message: "请输入合同名称" }],
          contractAmount: [
            { required: true, message: "请输入合同金额" },
            {
              pattern: /^\d{1,12}(\.\d{1,2})?$/,
              message: "请输入12位以内的数字,小数点后最多2位",
            },
          ],
          contractTime: [{ required: false, message: "请选择签署日期" }],
        },
        filelist: [],
        filelistfp: [],
        otherListfp: [],
        otherListfp2: [],
        isactive: false,
      });
    },
    delDomain(index) {
      this.$confirm("此操作将删除该合同, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          this.ruleform2.contractList.splice(index, 1);
          var arr = [];
          this.ruleform2.contractList.map((el) => {
            arr.push(Number(el.contractAmount));
          });
          this.mounttoall = (0).adds(...arr);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    cellhover(row, column, cell, event) {
      cell.children[0].children[0].style.border = "1px solid #ccc";
    },
    cellleave(row, column, cell, event) {
      cell.children[0].children[0].style.border = "1px solid #fff";
    },

    async nextbtn() {
      switch (+this.activebtn) {
        /** 基本信息 */
        case 0: {
          if (
            !(await this.$refs["PayableBillAddRequest"]
              .validate()
              .catch(() => false))
          ) {
            return false;
          }
          const res = await api.getFinancingMountMaximum({
            // 供应商id
            receivedCordId: this.PayableBillAddRequest.receivedCorpId,
            // 授信编号
            grantCreditNo: this.PayableBillAddRequest.grantCreditNo,
            // 资方id
            fundCorpId: this.PayableBillAddRequest.fundCorpId,
          });
          if (!res.success) {
            return false;
          }
          Object.assign(this.quota, res.datas?.maxCorpAmount || {});
          await this.$nextTick();
          this.activebtn++;
          break;
        }
        /** 合同 */
        case 1: {
          if (!(await this.$refs["ruleform"].validate().catch(() => false))) {
            return false;
          }
          if (!this.checked) {
            this.$message.error("请同意银e券开单协议和付款承诺函");
            return;
          }
          if (this.ruleform.payableAmount <= 0) {
            this.$message.error("银e券金额必须大于0");
            return false;
          }
          if (this.ruleform.payableAmount > this.mounttoall) {
            this.$message.error("银e券金额必须小于等于合同金额");
            return;
          }
          if (!this.ruleform2.contractList.length) {
            this.$message.error("请添加合同!");
            return false;
          }
          const tempInvoiceList = [];
          let invoiceAmounttotal = 0;
          /** 校验合同事宜 */
          await Promise.all(
            this.ruleform2.contractList.map(async (item, index) => {
              const [formRef] = this.$refs[item.name + index];
              await formRef.validate();

              /** 校验合同图片 */
              if (!item.imageList.length) {
                this.$alert(`请上传合同编号: ${item.contractNo} 的合同！`);
                throw new Error("发票内容缺失");
              }

              /** 迭代发票 */
              for (const invoice of item.invoiceList) {
                // console.log("item", {
                //   item,
                //   invoice,
                // });
                // /** 校验发票内容缺失 */
                // const emptyField = Object.keys(invoice).find(
                //   (field) => invoice[field] === ""
                // );
                // if (emptyField) {
                //   this.$alert(`合同编号: ${item.contractNo} 发票信息不全！`);
                //   throw new Error("发票内容缺失");
                // }
                const { invoiceCode, invoiceNo, invoiceAmount } = invoice;
                const code = invoiceCode + invoiceNo;
                if (tempInvoiceList.includes(code)) {
                  this.$alert(
                    "发票代码：" + invoiceCode + " 发票号码：" + invoiceNo,
                    "发票信息重复"
                  );
                  throw new Error("发票重复");
                } else {
                  // 发票code
                  tempInvoiceList.push(code);
                  // 发票金额
                  invoiceAmounttotal += +invoiceAmount;
                }
              }
            })
          );
          if (invoiceAmounttotal < this.ruleform.payableAmount) {
            this.$message.error("发票总金额必须大于银e券金额");
            return;
          }

          const user = JSON.parse(sessionStorage.getItem("user"));
          const { signType, creditCode } = JSON.parse(sessionStorage.corpList);
          const isUkey = +signType === 2;
          const yfdMessageDto = {
            itemId: 1,
            itemType: 1,
            operatorAction: 1,
            operatorType: 1,
            phone: user.phone,
          };
          if (isUkey) {
            try {
              /** 加载ukey */
              let ukeyRes = await ukey.LoadObj();
              if (!ukeyRes.result) {
                return false;
              }
            } catch (error) {
              return false;
            }
            const getSubjectCN = await ukey.GetCertInfoOnClick("SubjectCN");
            if (getSubjectCN.errorcode !== 0 || !getSubjectCN.result) {
              this.$message.error("UKey加载失败，请联系管理员");
              return false;
            }
            if (!new RegExp(creditCode).test(getSubjectCN.result)) {
              this.$message.error("选择证书不匹配，请重新选择!");
              return false;
            }
            const getCertInfoOnClick = await ukey.GetCertInfoOnClick(
              "CertContent"
            );
            if (
              getCertInfoOnClick.errorcode !== 0 ||
              !getCertInfoOnClick.result
            ) {
              this.$message.error("UKey加载失败，请联系管理员");
              return false;
            } else {
              yfdMessageDto.x509Cert = getCertInfoOnClick.result;
            }
            /** 随便加密个东西，让ukey调用密码输入 */
            await ukey.SignHashOnClick(`${Date.now()}`);
          } else {
            const res = await api.sendPhoneMessage(yfdMessageDto);
            if (!res.success) {
              this.$message.error(res.message);
              return false;
            }
            const meessage = `提示信息：平台向您尾号为${user.phone.slice(
              user.phone.length - 4
            )}的手机号码发送了验证短信。请在下方输入框中输入短信中的验证码`;
            const { value } = await this.$prompt(meessage, "请输入短信验证码", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              closeOnClickModal: false,
            });
            yfdMessageDto.verifyCode = value;
          }

          const formData = Object.assign(
            { contractAmount: this.mounttoall },
            this.ruleform2,
            this.PayableBillAddRequest,
            this.ruleform,
            { yfdMessageDto }
          );
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          try {
            const submitRes = await api.addPayableBill(formData);
            if (submitRes.code == 100000) {
              this.$alert("开单申请成功，等待内部审核");
              this.$router.push({ path: "/easypay/mange/1" });
            } else {
              this.$message.error(submitRes.message);
            }
          } finally {
            loading.close();
          }
          break;
        }
        default: {
          this.activebtn = 0;
          break;
        }
      }
    },
    prevbtn() {
      this.activebtn--;
    },
    dragupfn(response, file, fileList, item, index) {
      //上传的合同

      if (response.code == "100000") {
        var data = response.datas.uploadDate.message;
        if (/PDF|ZIP/gi.test(file.raw.name)) {
          file.url = pdfimg;
          item.filelist[item.filelist.length] = file;
        } else {
          item.filelist[item.filelist.length] = file;
        }
        item.imageList.push({
          fileCode: 14,
          fileUrl: data,
          uid: file.uid,
          fileName: file.name,
        });
      } else {
        item.filelist = item.filelist;
        this.$forceUpdate();
        this.$message.error(file.name + "发票识别错误，请重新上传");
      }
    },
    dragremovefn(file, fileList, item, index) {
      //移除合同
      if (item.imageList.findIndex((el) => el.uid == file.uid) != -1) {
        item.imageList.splice(
          item.imageList.findIndex((el) => el.uid == file.uid),
          1
        );
        item.filelist.splice(
          item.filelist.findIndex((el) => el.uid == file.uid),
          1
        );
      }
    },
    //上传发票
    handleupfn(response, file, fileList, item) {
      var data = response.message.datas.payableBillFile;
      item.filelistfp[item.filelistfp.length] = file;
      item.filelistfp.forEach((el, index) => {
        el.name = index + 1;
      });
      item.invoiceList.push({
        ...data,
        fileCode: 15,
        uid: file.uid,
        fileName: file.name,
      });
      item.fptotal = (+item.fptotal).adds(data.invoiceAmount);

      if (response.code === -1) {
        this.$message.error("发票识别错误，请删除后重新上传或手动填写更新。");
      } else {
        this.$message.success(response.message);
      }

      // let uid = file.uid; // 关键作用代码，去除文件列表失败文件
      // let idx = this.$refs.uploadFile1[0].uploadFiles.findIndex(
      //   (item) => item.uid === uid
      // ); // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
      // this.$refs.uploadFile1[0].uploadFiles.splice(idx, 1); // 关键作用代码，去除文件列表失败文件
    },
    handleRemove(file, fileList, item) {
      //移除发票
      if (item.filelistfp.findIndex((el) => el.uid == file.uid) != -1) {
        item.filelistfp.splice(
          item.filelistfp.findIndex((el) => el.uid == file.uid),
          1
        );
        item.filelistfp.forEach((el, index) => {
          el.name = index + 1;
        });
        item.invoiceList.splice(
          item.invoiceList.findIndex((el) => el.uid == file.uid),
          1
        );
        var arr = [];
        item.invoiceList.map((el) => {
          arr.push(Number(el.invoiceAmount));
        });
        item.fptotal = (0).adds(...arr);
      }
    },
    dragupotherfn(response, file, fileList, item, index) {
      //上传的其他附
      if (response.code == "100000") {
        var data = response.datas.uploadDate.message;
        if (/PDF|ZIP/gi.test(file.raw.name)) {
          file.url = pdfimg;
          item.otherListfp[item.otherListfp.length] = file;
        } else {
          item.otherListfp[item.otherListfp.length] = file;
        }
        item.otherList.push({
          fileCode: 16,
          fileUrl: data,
          uid: file.uid,
          fileName: file.name,
        });
      } else {
        this.$message.error(file.name + "发票识别错误，请重新上传");
      }
    },
    dragremoveotherfn(file, fileList, item, index) {
      //移除其他的附件
      if (item.otherList.findIndex((el) => el.uid == file.uid) != -1) {
        item.otherList.splice(
          item.otherList.findIndex((el) => el.uid == file.uid),
          1
        );
        item.otherListfp.splice(
          item.otherListfp.findIndex((el) => el.uid == file.uid),
          1
        );
      }
    },
    dragupotherfn2(response, file, fileList, item, index) {
      if (response.code == "100000") {
        var data = response.datas.uploadDate.message;
        item.otherListfp2[item.otherListfp2.length] = file;
        item.otherList.push({
          fileCode: 17,
          fileUrl: data,
          uid: file.uid,
          fileName: file.name,
        });
      } else {
        this.$message.error(file.name + "发票识别错误，请重新上传");
      }
    },
    dragremoveotherfn2(file, fileList, item, index) {
      //移除其他的附件
      if (item.otherListfp2.findIndex((el) => el.uid == file.uid) != -1) {
        item.otherListfp2.splice(
          item.otherListfp2.findIndex((el) => el.uid == file.uid),
          1
        );
        item.otherList.splice(
          item.otherList.findIndex((el) => el.uid == file.uid),
          1
        );
      }
    },
    erruploadfp(err) {
      this.$message.error("服务器错误");
    },
    jsmount() {
      var arr = [];
      this.ruleform2.contractList.map((el) => {
        el.contractAmountCap = convertCurrency(el.contractAmount);
        arr.push(el.contractAmount);
      });
      this.mounttoall = (0).adds(...arr);
    },
    getCorpList3() {
      //调取企业全称
      api.getSubordinateList().then((res) => {
        if (res.code == 100000) {
          this.qyallname = res.datas.list;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getGrantCreditCorpList() {
      //调取资金机构数据
      api.getGrantCreditCorpList().then((res) => {
        if (res.code == 100000) {
          this.zjlist = res.datas.list;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    changefundCorpName(val) {
      //获取授信编号
      const item = this.zjlist.find((el) => {
        return el.corpId === val;
      });
      this.PayableBillAddRequest.fundCorpName = item.corpName;
      this.sxbhlist = [];
      this.PayableBillAddRequest.grantCreditId = "";
      this.PayableBillAddRequest.grantCloseTime = "";
      // this.PayableBillAddRequest.grantableAmount = "";
      this.PayableBillAddRequest.fundYearInterest = "";
      this.PayableBillAddRequest.financeYearInterest = "";
      this.showAlert = false;
      api.getGrantCreditListByCorp({ fundCorpId: val }).then((res) => {
        if (res.code == 100000) {
          this.sxbhlist = res.datas.grantCreditlist;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    changesxName(val) {
      //获取四项值
      var item = this.sxbhlist.find((el) => {
        return el.grantCreditId === val;
      });
      this.penaltyInterest = item.penaltyInterest || 0;
      this.PayableBillAddRequest.grantCloseTime = moment(item.closeDate).format(
        "YYYY-MM-DD"
      );
      this.PayableBillAddRequest.grantableAmount = item.remainAmount;
      this.PayableBillAddRequest.grantableAmountCap = convertCurrency(
        item.remainAmount
      );
      this.PayableBillAddRequest.fundYearInterest = item.fundYearInterest;
      this.PayableBillAddRequest.financeYearInterest = item.financeYearInterest;
      this.PayableBillAddRequest.grantCreditNo = item.grantCreditNo;
      this.doOpenAccount(item.grantCreditNo);
    },
    receivedCorpIdfn(val) {
      //获取信用代码
      this.PayableBillAddRequest.receivedCorpName = val.corpName;
      this.PayableBillAddRequest.receivedCorpId = val.corpId;
      this.getSignAgreementByCorp(val);
      var item = this.qyallname.find((el) => {
        return el.corpId == val.corpId;
      });
      this.PayableBillAddRequest.receivedCorpCreditCode = item.creditCode;
    },
    async getSignAgreementByCorp({ corpId }) {
      let res = await api.getSignAgreementByCorp({ receivedCordId: corpId });
      if (res.success) {
        this.signAgreementDetail = res.datas.data;
      } else {
        this.$message.error(res.message);
      }
    },
    blurfptotal(val, index, item) {
      var arr = [];
      item.invoiceList.map((el) => {
        arr.push(Number(el.invoiceAmount));
      });
      item.fptotal = (0).adds(...arr);
    },
    beforeupfn(file) {
      let index = file.name.lastIndexOf(".");
      if (file.name.substr(0, index).length > 40) {
        this.$message.error("上传文件名长度不得超过40！");
        return false;
      }
      let suffix = file.name.substr(index + 1);
      if (!/jpg|gif|bmp|JPG|PNG|PDF|ZIP|png|jpeg/.test(suffix)) {
        this.$message.error("请上传png,jpg,jpeg格式的图片");
        return false;
      }
      if (file.size > 5242880) {
        this.$message.error("请上传5M以内的图片");
        return false;
      }
    },
    beforeupfnfile(file) {
      let index = file.name.lastIndexOf(".");
      if (file.name.substr(0, index).length > 40) {
        this.$message.error("上传文件名长度不得超过40！");
        return false;
      }
      let suffix = file.name.substr(index + 1);
      if (!/jpg|gif|bmp|png|jpeg|JPG|PNG|PDF|ZIP|zip|rar|pdf/.test(suffix)) {
        this.$message.error("请上传png,jpg,jpeg,zip,rar,pdf格式的文件");
        return false;
      }
      if (file.size > 31457280) {
        this.$message.error("请上传30M以内的文件");
        return false;
      }
    },
    // 弹出协议
    showDialog(isRead, type) {
      this.showType = type;
      if (type) {
        if (type == "1") {
          this.$set(this.$data, "fileType", "1");
          this.agreementDialog = true;
          // this.readCountDown();  // 单独打开协议时，不进行倒计时，不展示同意按钮
        } else if (type == "2") {
          this.$set(this.$data, "fileType", "2");
          this.agreementDialog = true;
          // this.readCountDown();
        }
      } else {
        if (this.checked) {
          if (!this.ruleform.payableAmount) {
            this.$message.error("请输入银e券金额!");
            this.checked = false;
            return false;
          }
          if (!this.ruleform.promisePayTime) {
            this.checked = false;
            this.$message.error("请先选择承诺付款日！");
            return false;
          }
          this.checked = false;
          this.$set(this.$data, "fileType", "1"); // 将fileType重置为1，勾选同意，从第一个协议开始打开
          this.agreementDialog = true;
          this.readDisabled = true;
          this.readCountDown();
        }
      }
      this.payInfo = {
        receiveCompanyName: this.PayableBillAddRequest.receivedCorpName,
        amountMoney: this.ruleform.remark,
        promisePayDate: this.ruleform.promisePayTime,
        currentComapnyName: JSON.parse(sessionStorage.getItem("corpList"))
          .corpName,
        penaltyInterest: this.penaltyInterest,
      };
    },
    // 阅读倒计时
    readCountDown() {
      this.readCount = 3;
      var readTimer = setInterval(() => {
        this.readCount--;
        if (this.readCount <= 0) {
          this.readDisabled = false;
          clearInterval(readTimer);
        }
      }, 1000);
    },
    // 同意
    hasRead() {
      if (this.showType) {
        if (this.showType == "1") {
          this.showType1 = true;
        } else if (this.showType == "2") {
          this.showType2 = true;
        }
        this.agreementDialog = false;
        this.readDisabled = true;
        if (this.showType1 && this.showType2) {
          this.checked = true;
          this.$set(this.$data, "fileType", "1");
        }
      } else {
        var Id = document.getElementById("dialog2");
        Id.getElementsByClassName("el-dialog")[0].scrollTop = 0;
        if (this.fileType == "1") {
          this.$set(this.$data, "fileType", "2");
          this.readCountDown();
          this.readDisabled = true;
          this.agreementDialog = true;
        } else if (this.fileType == "2") {
          // this.readCountDown();
          this.agreementDialog = false;
          this.$set(this.$data, "fileType", "1");
          this.checked = true;
          this.readDisabled = false;
        }
      }
    },
    // 关闭
    closeDialog() {
      this.agreementDialog = false;
    },
    // 判断是否开户
    doOpenAccount(grantCreditNo) {
      api
        .doOpenAccount({ grantCreditNo })
        .then((res) => {
          if (res.success) {
            // msg为true 资金结算渠道为上海银行
            // status 0是未开户 1开户中 2开户成功  3开户失败
            // openPrompt 0提示开户成功 2不提示
            if (res.datas.msg) {
              this.accountResult = res.datas.status;
              if (res.datas.status == 0) {
                this.showAlert = true;
                this.forbidden = true;
              } else if (res.datas.status == 1) {
                this.showAlert = false;
                this.forbidden = true;
                // 提示开户中
                this.accountResultDialog = true;
              } else if (res.datas.status == 2) {
                this.showAlert = false;
                // if(res.datas.openPrompt == 0) {
                //   // 提示开户成功
                //   this.accountResultDialog = true
                //   this.cltNm = res.datas.cltNm
                //   this.subNo = res.datas.subNo
                //   // 调用开户提示接口，下次不再提示开户成功
                //   api.openPrompt({openAccountId: res.datas.openAccountId}).then(res => {
                //     if(res.success) {

                //     } else {
                //       this.$message.error(res.message);
                //     }
                //   }).catch((err) => {
                //     this.$message.error('操作失败');
                //   })
                // }
                // 提示激活账户
                if (res.datas.isTiedCard != 2) {
                  this.forbidden = true;
                  this.openAccountId = res.datas.openAccountId;
                  this.isTiedCard = res.datas.isTiedCard;
                  this.accountResultDialog = true;
                  this.cltNm = res.datas.cltNm;
                  this.subNo = res.datas.subNo;
                } else {
                  this.forbidden = false;
                }
              } else if (res.datas.status == 3) {
                // 提示开户失败
                this.forbidden = true;
                this.showAlert = true;
                this.accountResultDialog = true;
                this.remark = res.datas.remark;
                this.openAccountId = res.datas.openAccountId;
                this.isEdit = true;
              }
            } else {
              this.forbidden = false;
              this.showAlert = false;
            }
          } else {
            this.$alert("温馨提示", res.message, {
              confirmButtonText: "确定",
              callback: (action) => {},
            });
          }
        })
        .catch((err) => {
          this.$message.error("操作失败");
        });
    },
    // 跳转到开户页面
    goOpenAccount() {
      if (this.isEdit) {
        // 编辑
        this.$router.push({
          name: "开立渠道账户",
          query: { openAccountId: this.openAccountId, isEdit: true },
        });
      } else {
        // 开户
        this.$router.push({ name: "开立渠道账户" });
      }
    },
    // 跳转到渠道账户详情页
    goAccountManagement() {
      // accountType 2上海银行
      this.$router.push({
        name: "渠道账户管理",
        query: {
          openAccountId: this.openAccountId,
          accountType: 2,
          toDetail: 1,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.yfdbaseline {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 18px;
  margin-bottom: 37px;
}
.easypayby {
  padding-bottom: 40px;
}
.yfdtwo {
  padding: 0 42px;
  .el-input.is-disabled .el-input__inner {
    color: rgb(96, 98, 102);
  }
}

.httotal {
  height: 35px;
  background: #fff3f3;
  border-radius: 4px;
  line-height: 35px;
  padding-left: 17px;
  margin-bottom: 18px;
  color: #828282;
  span {
    color: #da2b2b;
    line-height: 22px;
  }
}
.formstyle {
  & > div {
    margin-bottom: 20px;
  }

  .el-select,
  .el-date-editor {
    width: 100%;
  }
  .elspan {
    .el-input__inner {
      border-radius: 4px 0 0 4px;
    }
    .el-input {
      width: 80%;
    }
    .el-form-item__content {
      span {
        width: 20%;
        display: inline-block;
        height: 40px;
        background: #f7f7f7;
        border: 1px solid #d9d9d9;
        border-radius: 4px 0 0 4px;
        border-radius: 0 4px 4px 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 40px;
        border-left: none;
        position: relative;
        top: 1px;
        text-align: center;
      }
    }
  }
  .pec.elspan {
    .el-input {
      width: 90%;
    }
    .el-form-item__content {
      span {
        width: 10%;
      }
    }
  }
  .el-table__body-wrapper {
    input[type="text"] {
      border: 1px solid #fff;
    }
    .el-table__row {
      input {
        padding: 6px 10px;
      }
    }
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #fff !important;
  }
  .htbox {
    border-radius: 4px;
    border: 1px solid #cfcfcf;
    .htpad {
      padding: 0 17px;

      form {
        overflow: hidden;
      }
    }
    .htactive {
      height: 43px;
      overflow: hidden;
      background: #f1f1f1;
      line-height: 43px;
      span {
        display: inline-block;
        width: 100px;
        margin-left: 17px;
        float: left;
        cursor: pointer;
      }
    }
    .uploadfont {
      color: rgba(0, 0, 0, 0.45);
      line-height: 42px;
      span {
        text-indent: 8em;
        display: inline-block;
      }
    }
    .uploadlist {
      padding: 30px 16px 0 16px;
      .upload-demo {
        margin-left: 8em;
        margin-top: 2em;
      }
      .el-form-item {
        margin-bottom: 40px;
      }

      .el-form-item__label {
        font-size: 16px;
        color: #000000;
      }
      .el-upload-dragger {
        width: 300px;
      }
      .el-upload-dragger .el-upload__text,
      .el-icon-plus {
        color: rgba(0, 0, 0, 0.45);
      }
      .el-icon-plus {
        font-size: 30px;
      }
    }
    .el-icon-delete {
      float: right;
      padding: 12px 20px 0 0;
      cursor: pointer;
      font-size: 18px;
    }
    .ljje {
      text-align: right;
      margin-bottom: 40px;
      color: #595959;
      span {
        color: #f5212d;
      }
    }
  }
}
.easyformbtn {
  text-align: center;
  button {
    width: 160px;
    height: 40px;
    font-size: 16px;

    text-align: center;
    line-height: 18px;
    span {
      color: #ffffff;
    }
  }
  button:nth-last-child(1) {
    background-color: #2937a6;
  }
  button:nth-last-child(2) {
    span {
      color: #595959;
    }
  }
  button:hover {
    background-color: #edf5ff;
    span {
      color: #2937a6;
    }
  }
}
.formstyle {
  .active {
    height: 133px;
    overflow: hidden;
  }
  .iconactive {
    transform: scaleY(-1);
  }
}
.addbtn {
  border: 1px dashed #cfcfcf;
  height: 44px;
  text-align: center;
  border-radius: 4px;
  line-height: 44px;
  font-size: 14px;
  color: #828282;
  cursor: pointer;
  i {
    margin-right: 6px;
  }
}
.yfdsize {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  margin-bottom: 29px;
}
.wxts {
  color: rgba(0, 0, 0, 0.45);
  margin: 8px 0;
}
.pt16 {
  position: relative;
  top: -16px;
}
.appQRcodeBox {
  display: block;
  margin-top: 20px;
  .appQRcodeImg {
    width: 49%;
    text-align: center;
    img {
      width: 90%;
      display: block;
    }
  }
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
}
.account-warn {
  background-color: #fdf6ec;
  color: #e6a23c;
  padding: 8px 16px;
  margin: 10px 0;
  border-radius: 4px;
  span {
    display: inline-block;
    cursor: pointer;
    color: #2937a6;
    text-decoration: underline;
  }
}
.info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    width: 400px;
  }
}
</style>
