<template>
  <div class="content easypay-manager distingduishing-detail">
    <div class="content-inner">
      <el-tabs v-model="activetab">
        <el-tab-pane label="清分详情" name="first">
          <section class="info-card-A">
            <div class="card-hd sub-title">
              <span class="txbst">基本信息</span>
              <el-button type="text" class="page-head-back" style="color: #2937A6;" size="small" @click="previewEasypay">银e券编号:{{financInfo.payableBillNo}}预览</el-button>
            </div>
            <div class="card-bd card-bd-row">
              <el-row>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">资金机构:</span>
                  <span class="info-value">{{financInfo.fundCorpName}}</span>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">授信编号:</span>
                  <span class="info-value">{{financInfo.grantCreditNo}}</span>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">授信截止日期:</span>
                  <span class="info-value">{{moment(financInfo.grantCloseTime).format('YYYY-MM-DD')}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">原始银e券额度:</span>
                  <span class="info-value">{{changeMoneyThousand(financInfo.payableBillAmount)}}元人民币</span>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">承诺付款日:</span>
                  <span class="info-value">{{moment(financInfo.promisePayTime).format('YYYY-MM-DD')}}</span>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">已融资金额:</span>
                  <span class="info-value">{{changeMoneyThousand(financInfo.payableBillFinanceAmountTotal)}}</span>
                </el-col>
              </el-row>
              <el-row>
             
                <el-col :span="8" class="lb_body">
                  <span class="info-key">已逾期天数:</span>
                  <span class="info-value">{{financInfo.overdueDays || 0}}</span>
                </el-col>
        
              </el-row>
              <el-row>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">需清分金额:</span>
                  <span class="info-value">{{changeMoneyThousand(financInfo.payableBillClearAmountTotal)}}元人民币</span>
                </el-col>
              </el-row>
              <div class="card-line"></div>
            </div>
            <div class="card-hd sub-title">
              <span class="txbst">需清分企业名单</span>
            </div>
            <div class="card-bd card-bd-row">
              <el-table :data="datalist" style="width: 100%">
                <el-table-column type="index" label="序号" width="80">
                </el-table-column>
                <el-table-column prop="payableBillNo" label="子银e券号" width="240">
                </el-table-column>
                <el-table-column prop="originalName" label="开单企业" min-width="200"></el-table-column>
                <el-table-column prop="receivedCorpName" label="企业名称" width="140">
                </el-table-column>
                <el-table-column prop="receivedCorpCreditCode" label="社会统一信用代码" width="200">
                </el-table-column>
                <el-table-column prop="payableBillAmount" align='right' :formatter='el=>changeMoneyThousand(el.payableBillAmount)' label="银e券金额(元)" width="145">
                </el-table-column>
                <el-table-column prop="payableBillFinanceAmount" align='right' :formatter='el=>changeMoneyThousand(el.payableBillFinanceAmount)' label="已融资总额(元)" width="145">
                </el-table-column>
                <el-table-column v-if="financInfo.overdueDays > 0" prop="penalty" align='right' label="罚息金额(元)" :formatter='el=>changeMoneyThousand(el.penalty)' width="145">
                </el-table-column>
                <el-table-column prop="payableBillClearAmount" align='right' :formatter='el=>changeMoneyThousand(el.payableBillClearAmount)' label="清分总额(元)" width="145">
                </el-table-column>
                <el-table-column prop="zfStatus" label="付款状态" :formatter="statusfn" width="140">
                </el-table-column>
                <el-table-column prop="remark" label="失败原因" show-overflow-tooltip v-if="isshowbtn == 1" width="200">
                </el-table-column>
                <el-table-column label="操作" width="170" v-if="settlementChannel == 0">
                  <template slot-scope="scope">
                    <el-button size="medium" type="text" @click="loanDetial(scope.row)" :disabled="scope.row.zfStatus != 3">查看放款凭证</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </section>
        </el-tab-pane>
        <el-tab-pane label="流转详情" name="second" class="info-card-A">
          <div class="text-center">
            <vue2-org-tree
              name="test"
              :data="lzdata"
              :horizontal="horizontal"
              :collapsable="collapsable"
              :render-content="renderContent"
              labelWidth="320px"
              labelClassName="label-box"
              @on-node-click="orgclickfn"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="card-footer">
        <el-row>
          <el-col  :span='14' class="lb_body" :offset="5" v-if="isshowbtn == 1">
            <el-button style="width: 160px;height: 40px;font-size:16px;background: #2937A6;color:#ffffff;" size="small" @click="goback" >返回</el-button>
            <el-button style="width: 160px;height: 40px;font-size:16px;background: #2937A6;color:#ffffff;" size="small" @click="payBtn" v-if="settlementChannel == 0" :disabled="!payableBillLiquidationIdList.length">发出付款指令</el-button>
          </el-col>
          <el-col  :span='8' class="lb_body" :offset="8" v-if="isshowbtn == 2">
            <el-button style="width: 160px;height: 40px;font-size:16px;background: #2937A6;color:#ffffff;" size="small" @click="goback" >返回</el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog title="付款指令确认" :visible.sync="showDialog" :close-on-click-modal="false" :show-close="false" width="60%">
      <el-alert title="请注意，你已经发起了清分付款指令。点击确认支付以后将会向下列账户进行付款操作。确认支付指令一旦下达将不可逆转。请仔细确认下面的账号和相关金额" type="warning" show-icon :closable="false"></el-alert>
      <el-table :data="billPaylist" style="width: 100%; margin-top: 20px;">
        <el-table-column type="index" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="openCorpName" label="付款企业">
        </el-table-column>
        <el-table-column prop="receivedCorpName" label="收款企业">
        </el-table-column>
        <el-table-column prop="beneficiaryFundAccount" label="资金账号">
        </el-table-column>
        <el-table-column prop="amount" align='right' :formatter='el=>changeMoneyThousand(el.amount)' label="收款金额(元)">
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog=false">取 消</el-button>
        <el-button type="primary" @click="confirmPayment" :disabled="bclick">确认付款</el-button>
      </div>
    </el-dialog>
    <el-dialog title :visible.sync="imgDialogVisible" width="80%" center class="img-dialog">
      <img :src="fileUrl" alt="图片丢失" width="30%" height="30%" preview-text="放款凭证" :preview="1"/>
      <span slot="footer" class="dialog-footer">
        <el-button @click="imgDialogVisible = false">取 消</el-button>
        <el-button @click="imgDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title :visible.sync="centerDialogVisible" width="80%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <pdf class="pdf" ref="pdf" :src="src"></pdf>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="融资列表" :visible.sync="showAddDialog3" :close-on-click-modal="false" :show-close="false" width="1000px">
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="payableBillFinanceNo" label="融资项目编号" width="180"></el-table-column>
        <el-table-column prop="financePayableBillNo" label="融资银e券编号" width="180"></el-table-column>
        <el-table-column prop="fundCorpName" label="资金机构" width="180"></el-table-column>
        <el-table-column label="融资金额">
          <template slot-scope="scope">{{scope.row.amount|formatMoney}}</template>
        </el-table-column>
        <el-table-column label="融资完成时间">
          <template slot-scope="scope">{{scope.row.financeTime|formatDate}}</template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showAddDialog3=false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import moment from "moment";
import pdf from "vue-pdf";
import api from "@/api/api";
import { changeMoneyThousand } from "@/util/common";

export default {
  components: {
    pdf
  },
  data() {
    return {
      moment: moment,
      api: api,
      financInfo: {},
      datalist: [],
      changeMoneyThousand: changeMoneyThousand,
      changeMoneyThousand: changeMoneyThousand,
      activetab: "first",
      horizontal: false,
      collapsable: false,
      expandAll: false,
      centerDialogVisible: false,
      src: "",
      lzdata: {},
      payableBillLiquidationIdList: [],
      showDialog: false,
      billPaylist: [],
      bclick: false,
      isshowbtn: this.$route.query.isshowbtn,
      settlementChannel: this.$route.query.settlementChannel,  // 1中金 0上海银行
      imgDialogVisible: false,
      fileUrl: {},
      status: this.$route.query.status,  // 1 担保方
      payableBillId: this.$route.query.payableBillId,
      isOverdue: this.$route.query.isOverdue,  // 1逾期 否则正常
      showAddDialog3:false,
      tableData:[],
    };
  },
  created() {
    this.getDetail();
    this.circulation();
  },
  mounted() {},
  methods: {
    getDetail(param) {
       // type 1 持有企业 2资金机构
       // typeStr 1 正常清分/已清分 2逾期清分
      let typeStr = this.isshowbtn == 1 && this.isOverdue == 1 ? 2 : 1
      api.getPayableBillLiquidationDetailByPay({ payableBillNo: this.$route.query.payableBillNo, type: 2, typeStr }).then(res => {
        if (res.code == "100000") {
          this.financInfo = res.datas.single;
          this.datalist = res.datas.list;
          res.datas.list.forEach(v => {
            if(v.zfStatus == 1 || v.zfStatus == 4) {
              this.payableBillLiquidationIdList.push(v.payableBillLiquidationId)
            }
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    goback() {
      history.go(-1);
    },
    loanDetial(row) {
      // type: 1持有企业 2资金机构
      api
        .selectVoucher({payableBillLiquidationId: row.liquidationFinanceId, type: 2})
        .then(res => {
          if (res.success) {
            this.fileUrl = res.datas.url;
            window.open(this.fileUrl)
            // this.imgDialogVisible = true;
            // this.$previewRefresh()
          } else {
            this.$message.error(res.message);
          }
        });
    },
    previewEasypay() {
      api
        .DownloadContract({ contractNo: this.financInfo.contractNo })
        .then(res => {
          let binaryData = [];
          binaryData.push(res);
          this.src = window.URL.createObjectURL(
            new Blob(binaryData, { type: "application/zip" })
          );
          this.centerDialogVisible = true;
        });
    },
    circulation() {
      api
        .circulation({ payableBillId: this.payableBillId })
        .then(res => {
          if (res.code == "100000") {
            this.lzdata = res.datas.payableBillDetail[0];
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(err => {});
    },
    renderContent(h, data) {
      if (!data.hild) {
        let currentData = "";
        if (this.payableBillId == data.payableBillId) {
          currentData = "flow-style active";
        } else {
          currentData = "flow-style";
        }
        let content = [
          h("div", {}, "银e券编号:" + data.payableBillNo),
          h("div", {}, "原始开立方:" + data.originalName),
          data.openCorpName ? h("div", {}, "转让方:" + data.openCorpName) : "",
          h("div", {}, "持有方:" + data.receiveName),
          h(
            "div",
            {},
            "金额：" + changeMoneyThousand(data.payableBillAmount) + " 元"
          ),
          h(
            "div",
            {},
            "承诺付款日：" +
              (data.paymentDate
                ? moment(data.paymentDate).format("YYYY-MM-DD")
                : "")
          ),
           h("div", {}, "是否已融资：" + (data.payableBillFinanceList.length > 0 ? "是(点击查看)" : "否"))
        ];
        return h("div", { domProps: { className: currentData } }, content);
      } else {
        return "多次流转";
      }
    },
    orgclickfn(val, data) {
      if (data.payableBillFinanceList.length > 0) {
        this.showAddDialog3 = true;
        this.tableData = data.payableBillFinanceList;
      }
    },
    payBtn() {
      this.showDialog = true
      // type 1持有企业 2资金机构
      let params = {
        payableBillLiquidationIds: this.payableBillLiquidationIdList, type: 2
      }
      if(this.status == 1) {  // 担保方
        params.status = this.status
      }
      api.paymentConfirmationList(params).then(res => {
        if(res.success) {
          this.billPaylist = res.datas.list
          if(this.status == 1) {  // 担保方
            this.billPaylist.map(v => {
              v.type = 2 // 1持有方清分 2资金机构清分
              v.status = this.status
            })
          } else {
            this.billPaylist.map(v => {
              v.type = 2 // 1持有方清分 2资金机构清分
            })
          }
        }
      })
    },
    // 确认付款
    confirmPayment() {
      this.bclick = true
      api.confirmPayment({ payableBillPayList: this.billPaylist }).then(res => {
        if(res) {
          this.getDetail()
          this.bclick = false
          this.showDialog = false
          this.$alert('付款指令已发送，请稍后查看付款结果', '', {
            confirmButtonText: '确定',
            callback: action => {
              history.go(-1);
            }
          });
        }
      })
    },
    statusfn(val){
      let state=''
      switch (val.zfStatus) {
        case 1:
          state='等待付款'
          break;
        case 2:
          state='付款中'
          break;
        case 3:
          state='付款成功'
          break;
        case 4:
          state='付款失败'
          break;
      }
      return state
    }
  }
};
</script>

<style lang='scss' >
@import "../../styles/moreDetail/moudleDetail.scss";

.content {
  //padding: 0px 0px 0px 30px;
  width: 100%;
  height: 100%;
  background-color: white;
  .infotitle {
    color: #262626;
    font-size: 16px;
    font-weight: bold;
    text-indent: 32px;
    display: inline-block;
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
    height: 60px;
    line-height: 60px;
  }
  .info-card-A {
    padding: 0 32px;
  }
  .card-bd-row {
    line-height: 24px;
  }
  .page-head-back {
    float: right;
    margin-right: 40px;
    margin-top: 16px;
    border: 0px scope #fff;
    &.downsize {
      margin-top: 0;
      font-weight: normal;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .sub-title {
    border-bottom: none;
    height: 40px;
    font-size: 16px;
    color: #262626;
    padding: 0;
    .txbst {
      position: relative;
      left: -32px;
    }
  }
  .card-line {
    height: 20px;
    border-bottom: 1px #e6e6e6 solid;
  }
  //  重写开始
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .maincontin {
    margin-top: 30px;
    .marrimg {
      img {
        margin-right: 1%;
      }
    }
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;

    border-radius: 4px;

    .mainht {
      color: #262626;
      font-size: 16px;
      font-weight: bold;
      text-indent: 32px;
      display: inline-block;
      border-bottom: 1px solid #e6e6e6;
      width: 100%;
      height: 60px;
      line-height: 60px;
    }
    .htmsg {
      margin: 10px 0 10px 32px;
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 200px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
    .isdot {
      .el-badge__content.is-fixed {
        top: 10px;
        right: 0px;
      }
      .el-badge__content {
        background-color: #cfcfcf;
      }
    }
    .numcolor {
      font-size: 18px;
      color: #f5212d;
    }
  }
}
</style>
<style lang='scss' scoped>
.distingduishing-detail {
  .text-center {
    min-height: calc(100vh - 300px);
  }
  .info-card-A {
    min-height: calc(100vh - 324px);
  }
}
</style>