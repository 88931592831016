<template>
  <div class="page-login">
    <div class="main-contentTop login-bgc">
      <div class="main-contentTop1">
        <div class="main-TopContent">
          <!-- <img src="../../assets/login/login_logo.png" alt class="main-contentImg fl" /> -->
          <span class="fr">
            <router-link to="/login">
              <span style="color: white">已有账户？点此登录</span>
            </router-link>
          </span>
        </div>
        <div class="main-TopContentBottom">
          <div
            class="fl main-ContentBottom"
            @click="enterprise('3')"
            :class="{ changeColor: corpType === '3' }"
          >
            <div class="main-information">
              <h3>我是企业</h3>
              <span class="information">您可以通过平台进行供应链融资</span>
            </div>
          </div>
          <div
            class="fl main-ContentBottom1"
            @click="enterprise('1')"
            :class="{ changeColor: corpType === '1' }"
          >
            <div class="main-information">
              <h3>我是资金方</h3>
              <span class="information">您可以通过平台进行资产投资</span>
            </div>
          </div>
          <!-- <div
            class="fl main-ContentBottom1"
            @click="enterprise('6')"
            :class="{'changeColor':corpType === '6'}"
          >
            <div class="main-information">
              <h3>我是担保方</h3>
              <span class="information">您可以通过平台进行担保管理</span>
            </div>
          </div> -->
        </div>
      </div>

      <div class="main-ContentMessgae">
        <div style="text-align: center; padding-top: 40px">
          <el-radio-group v-model="form.registerType" @change="radioChange">
            <el-radio label="1" value="1">我是企业法人</el-radio>
            <el-radio label="2" value="2">我是法人经办人</el-radio>
          </el-radio-group>
          <el-form
            ref="form"
            :model="form"
            label-width="125px"
            style="margin-top: 32px"
            v-show="form.registerType === '1'"
            :rules="rules"
          >
            <el-form-item label="企业名称：" prop="registerCorpName">
              <el-input
                v-model="form.registerCorpName"
                placeholder="请输入您的企业全称"
              ></el-input>
            </el-form-item>
            <el-form-item label="法人姓名：" prop="realName">
              <el-input
                v-model="form.realName"
                placeholder="请输入您的法人姓名"
                autoComplete="off"
                :name="Date.now()"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号码：" prop="idCard">
              <el-input
                v-model="form.idCard"
                placeholder="请输入您的身份证号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="登录密码：" prop="password">
              <el-input
                v-model="form.password"
                placeholder="请输入您的登录密码"
                :type="password"
                autoComplete="new-password"
                :name="Date.now()"
                >></el-input
              >
              <i
                class="fa fa-eye eyetab reg"
                aria-hidden="true"
                @click="eyefn"
              ></i>
            </el-form-item>
            <el-form-item label="确认登录密码：" prop="confirmPassword">
              <el-input
                v-model="form.confirmPassword"
                placeholder="请输入您的确认登录密码"
                :type="passwordConfirm"
              ></el-input>
              <i
                class="fa fa-eye eyetab reg"
                aria-hidden="true"
                @click="eyefnConfirm"
              ></i>
            </el-form-item>
            <el-form-item label="公司邮箱：" prop="email">
              <el-input
                v-model="form.email"
                placeholder="请输入公司邮箱"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号码：" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="请输入您的手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="图形验证码：" prop="captcha">
              <el-row :gutter="20">
                <el-col
                  :span="10"
                  style="padding-left: 0px; padding-right: 0px"
                >
                  <el-input
                    v-model="form.captcha"
                    placeholder="验证码"
                    style="width: 200px"
                  ></el-input>
                </el-col>
                <el-col :span="10" :offet="1" class="login-captcha">
                  <img :src="captchaPath" @click="getCaptcha()" alt />
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="短信验证码：" prop="agentPhone">
              <el-input
                v-model="form.agentPhone"
                placeholder="请输入您的验证码"
                style="width: 280px"
              ></el-input>
              <el-button
                v-if="sendCode"
                @click.native="getMessage"
                type="primary"
                style="width: 120px; height: 40px; background: #2937a6"
                >获取验证码</el-button
              >
              <el-button
                v-if="!sendCode"
                :disabled="sendCode"
                style="width: 120px; height: 40px"
                >{{ sendTime }}s后重新发送</el-button
              >
            </el-form-item>
            <el-form-item label="邀请码（选填）：">
              <el-input
                v-model="form.inviteCode"
                placeholder="请输入您的邀请码"
              ></el-input>
            </el-form-item>
            <el-form-item prop="type">
              <el-checkbox-group v-model="checked">
                <el-checkbox name="type" @change="showDialog(checked)">
                  我已阅读并同意
                  <a
                    href="javascript:;"
                    class="little_font blue"
                    @click="showDialog(checked, '1')"
                    >《会员服务协议》</a
                  >
                  <a
                    href="javascript:;"
                    class="little_font blue"
                    @click="showDialog(checked, '2')"
                    >《隐私协议》</a
                  >
                  <a
                    href="javascript:;"
                    class="little_font blue"
                    @click="showDialog(checked, '3')"
                    >《免责声明》</a
                  >
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="onSubmit"
                style="width: 160px; height: 40px; background: #2937a6"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
          <el-form
            ref="form2"
            :model="form"
            label-width="125px"
            style="margin-top: 32px"
            v-show="form.registerType === '2'"
            :rules="rules1"
          >
            <el-form-item label="企业名称：" prop="registerCorpName">
              <el-input
                v-model="form.registerCorpName"
                placeholder="请输入您的企业全称"
              ></el-input>
            </el-form-item>
            <el-form-item label="经办人姓名：" prop="realName">
              <el-input
                v-model="form.realName"
                placeholder="请输入您的经办人姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号码：" prop="idCard">
              <el-input
                v-model="form.idCard"
                placeholder="请输入您的身份证号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="登录密码：" prop="password">
              <el-input
                v-model="form.password"
                placeholder="请输入您的登录密码"
                :type="password"
                autoComplete="new-password"
              ></el-input>
              <i
                class="fa fa-eye eyetab reg"
                aria-hidden="true"
                @click="eyefn"
              ></i>
            </el-form-item>
            <el-form-item label="确认登录密码：" prop="confirmPassword">
              <el-input
                v-model="form.confirmPassword"
                placeholder="请输入您的确认登录密码"
                :type="password"
              ></el-input>
              <i
                class="fa fa-eye eyetab reg"
                aria-hidden="true"
                @click="eyefn"
              ></i>
            </el-form-item>
            <el-form-item label="公司邮箱：" prop="email">
              <el-input
                v-model="form.email"
                placeholder="请输入公司邮箱"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号码：" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="请输入您的手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="图形验证码：" prop="captcha">
              <el-row :gutter="20">
                <el-col
                  :span="10"
                  style="padding-left: 0px; padding-right: 0px"
                >
                  <el-input
                    v-model="form.captcha"
                    placeholder="验证码"
                    style="width: 200px"
                  ></el-input>
                </el-col>
                <el-col :span="10" :offet="1" class="login-captcha">
                  <img :src="captchaPath" @click="getCaptcha()" alt />
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="短信验证码：" prop="agentPhone">
              <el-input
                v-model="form.agentPhone"
                placeholder="请输入您的验证码"
                style="width: 280px"
              ></el-input>
              <!-- <el-button type="primary" style="width: 120px;height: 40px;background: #2937A6" @click="getCode">获取验证码</el-button> -->

              <el-button
                v-if="sendCode2"
                @click.native="getMessage2"
                type="primary"
                style="width: 120px; height: 40px; background: #2937a6"
                >获取验证码</el-button
              >
              <el-button
                v-if="!sendCode2"
                :disabled="sendCode2"
                style="width: 120px; height: 40px"
                >{{ sendTime2 }}s后重新发送</el-button
              >
            </el-form-item>
            <el-form-item label="邀请码（选填）：" prop="code">
              <el-input
                v-model="form.code"
                placeholder="请输入您的邀请码"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox-group v-model="checked">
                <el-checkbox name="type" @change="showDialog(checked)">
                  我已阅读并同意
                  <a
                    href="javascript:;"
                    class="little_font blue"
                    @click="showDialog(checked, '1')"
                    >《会员服务协议》</a
                  >
                  <a
                    href="javascript:;"
                    class="little_font blue"
                    @click="showDialog(checked, '2')"
                    >《隐私协议》</a
                  >
                  <a
                    href="javascript:;"
                    class="little_font blue"
                    @click="showDialog(checked, '3')"
                    >《免责声明》</a
                  >
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="onSubmit"
                style="width: 160px; height: 40px; background: #2937a6"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <el-dialog
      id="dialog2"
      class="agreement-dialog2"
      :visible.sync="agreementDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="50%"
      center
    >
      <div class="agreement-container">
        <member-services v-if="this.fileType == '1'"></member-services>
        <privacy-protocol v-if="this.fileType == '2'"></privacy-protocol>
        <disclaimer v-if="this.fileType == '3'"></disclaimer>
      </div>
      <span slot="footer">
        <el-button
          type="primary"
          v-show="!showType && !readDisabled"
          @click="hasRead()"
          >同意</el-button
        >
        <el-button
          type="primary"
          v-show="!showType && readDisabled"
          :disabled="true"
          >阅读并同意 {{ readCount }}秒</el-button
        >
        <el-button type="primary" @click="closeDialog">关闭</el-button>
      </span>
    </el-dialog>

    <layout-footer></layout-footer>
  </div>
</template>
<script>
import api from "../../api/api";
import { getUUID } from "../../util/index.js";
import LayoutFooter from "@/components/layout-footer";
import Disclaimer from "@/components/agreementList/disclaimer";
import privacyProtocol from "@/components/agreementList/privacy-protocol";
import memberServices from "@/components/agreementList/member-services";
export default {
  components: {
    "layout-footer": LayoutFooter,
    disclaimer: Disclaimer,
    "privacy-protocol": privacyProtocol,
    "member-services": memberServices,
  },
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      password: "password",
      passwordConfirm: "password",
      agreementDialog: false,
      fileType: "1",
      showType: "",
      readCount: "",
      readDisabled: true,
      captchaPath: "",
      corpType: "",
      form: {
        inviteCode: "",
        phone: "",
        registerType: "1",
        corpType: "",
        password: "",
        confirmPassword: "",
        agentPhone: "",
        registerCorpName: "",
        realName: "",
        uuid: "",
        delivery: false,
        verifyCode: "",
        captcha: "",
        idCard: "",
        email: "",
      },
      form2: {
        inviteCode: "",
        phone: "",
        registerType: "1",
        corpType: "",
        password: "",
        confirmPassword: "",
        agentPhone: "",
        registerCorpName: "",
        realName: "",
        uuid: "",
        captcha: "",
        idCard: "",
        email: "",
      },

      loginForm: {
        name: "",
        pass: "",
        captcha: "",
        uuid: "",
      },
      corpId: "", //企业id
      // changeColor: false,
      // changeColor2: false,
      sendCode: true,
      sendCode2: true,
      sendTime: 0,
      sendTime2: 0,
      steps: true,
      retrieveForm: {
        ipone: "",
        verificationCode: "",
      },
      retrieveForm2: {
        password: "",
        password1: "",
      },
      checked: false,
      rules: {
        realName: [
          { required: true, message: "请输入法人姓名", trigger: "blur" },
        ],
        name1: [
          { required: true, message: "请输入经办人姓名", trigger: "blur" },
        ],
        idCard: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
        ],
        registerCorpName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "电子邮箱不能为空", trigger: "blur" },
          {
            pattern:
              /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/,
            message: "请输入正确的电子邮箱",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message:
              "请输入8位以上且必须为英文大小写字母加数字加特殊符号组合的密码",
            trigger: "blur",
          },
          {
            min: 8,
            max: 8,
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*]).{9,}$/,
            message:
              "请输入8位以上且必须为英文大小写字母加数字加特殊符号组合的密码",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        checked: [{ required: true, message: "请勾选已阅读", trigger: "blur" }],
        captcha: [
          { required: true, message: "请输入图形验证码", trigger: "blur" },
        ],
        agentPhone: [
          { required: true, message: "请输入正确的验证码", trigger: "blur" },
        ],
      },
      rules1: {
        realName: [
          { required: true, message: "请输入经办人姓名", trigger: "blur" },
        ],
        registerCorpName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        idCard: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "电子邮箱不能为空", trigger: "blur" },
          {
            pattern:
              /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/,
            message: "请输入正确的电子邮箱",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message:
              "请输入8位以上且必须为英文大小写字母加数字加特殊字符组合的密码",
            trigger: "blur",
          },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*]).{9,}$/,
            message:
              "请输入8位以上且必须为英文大小写字母加数字加特殊字符组合的密码",
            trigger: "blur",
          },
        ],
        checked: [{ required: true, message: "请勾选已阅读", trigger: "blur" }],
        captcha: [
          { required: true, message: "请输入图形验证码", trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        agentPhone: [
          { required: true, message: "请输入正确的验证码", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getCaptcha();
    this.corpType = "3";
  },
  methods: {
    radioChange() {
      this.$refs.form.clearValidate();
      this.$refs.form2.clearValidate();
      const initData = {
        inviteCode: "",
        phone: "",
        corpType: "",
        password: "",
        confirmPassword: "",
        agentPhone: "",
        registerCorpName: "",
        realName: "",
        captcha: "",
        email: "",
      };
      this.password = "password";
      this.form = { ...this.form, ...initData };
    },
    enterprise(target) {
      console.log("target ====>", target);
      this.corpType = target;
      this.form.registerType = "1";
    },

    async getMessage() {
      var regu = /^1\d{10}$/;
      var re = new RegExp(regu);
      if (re.test(this.form.phone)) {
        await this.getCode(1);
        var auth_timetimer = setInterval(() => {
          this.sendTime--;
          if (this.sendTime <= 0) {
            this.sendCode = true;
            clearInterval(auth_timetimer);
          }
        }, 1000);
      }
    },

    async getMessage2() {
      var regu = /^1\d{10}$/;
      var re = new RegExp(regu);
      if (re.test(this.form.phone)) {
        await this.getCode();
        var auth_timetimer = setInterval(() => {
          this.sendTime2--;
          if (this.sendTime2 <= 0) {
            this.sendCode2 = true;
            clearInterval(auth_timetimer);
          }
        }, 1000);
      } else {
        this.$message.error("手机号格式错误");
        return;
      }
    },
    async getCode(val) {
      let param = {
        uuid: this.form.uuid,
        realName: this.form.realName,
        idCard: this.form.idCard,

        phone: this.form.phone,
        verifyCode: this.form.captcha,
        channelCode: "YRY", // 渠道
        smsType: 1,
        registerCorpName: this.form.registerCorpName,
      };
      const res = await api.GetPhoneVerification(param);
      if (res.success) {
        if (val) {
          this.sendCode = false;
          this.sendTime = 60;
        } else {
          this.sendCode2 = false;
          this.sendTime2 = 60;
        }
        this.$message.success("发送成功");
      } else {
        this.$message.error(res.message);
        this.sendCode = true;
      }
    },

    onSubmit(ev) {
      if (!this.checked) {
        this.$message.error("请先阅读并同意相关协议");
        return;
      }
      const form = this.form.registerType === "1" ? "form" : "form2";
      this.$refs[form].validate((valid) => {
        if (valid) {
          //这里还需要判断密码是否一直
          if (this.form.confirmPassword == this.form.password) {
            let LoginAdminUser = {
              inviteCode: this.form.inviteCode,
              phone: this.form.phone,
              email: this.form.email,
              registerType: this.form.registerType,
              // pass:this.form.pass,
              uuid: this.form.uuid,
              password: this.form.password,
              confirmPassword: this.form.confirmPassword,
              registerCorpName: this.form.registerCorpName, //企业名称
              realName: this.form.realName,
              verifyCode: this.form.agentPhone,
              corpType: this.corpType,
              corpId: this.corpId,
              idCard: this.form.idCard,
              channelCode: "YRY", // 渠道
            };
            const loading = this.$loading({
              lock: true,
              text: "Loading",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            api
              .register(LoginAdminUser)
              .then((res) => {
                // this.form = res
                // console.log(form,12121)
                loading.close();
                if (res.code === "100000") {
                  this.$alert("注册成功", "", {
                    confirmButtonText: "确定",
                    callback: (action) => {
                      history.go(-1);
                    },
                  });
                } else {
                  this.$message({
                    message: res.message,
                    type: "error",
                  });
                }
              })
              .catch((err) => {
                loading.close();
              });
          } else {
            this.$message.error("两次密码不一致,请检查");
          }
        }
      });
    },
    // changeColor(index, text) {
    //     this.changeblue = index;
    // },
    nextStep() {
      this.steps = false;
    },
    // onSubmit () {
    //     this.$router.push('/Company')
    // },
    // onSubmit() {
    //     console.log('submit!');
    // },
    // 获取验证码
    getCaptcha() {
      this.form.uuid = getUUID();
      this.captchaPath = api.verifycode(this.form.uuid);
    },
    // 弹出协议
    showDialog(isRead, type) {
      this.showType = type;
      if (type) {
        if (type == "1") {
          this.$set(this.$data, "fileType", "1");
          this.agreementDialog = true;
          // this.readCountDown();  // 单独打开协议时，不进行倒计时，不展示同意按钮
        } else if (type == "2") {
          this.$set(this.$data, "fileType", "2");
          this.agreementDialog = true;
          // this.readCountDown();
        } else if (type == "3") {
          this.$set(this.$data, "fileType", "3");
          this.agreementDialog = true;
          // this.readCountDown();
        }
      } else {
        if (this.checked) {
          this.$set(this.$data, "fileType", "1"); // 将fileType重置为1，勾选同意，从第一个协议开始打开
          this.checked = false;
          this.agreementDialog = true;
          this.readCountDown();
          this.readDisabled = true;
        }
      }
    },
    // 阅读倒计时
    readCountDown() {
      this.readCount = 3;
      var readTimer = setInterval(() => {
        this.readCount--;
        if (this.readCount <= 0) {
          this.readDisabled = false;
          clearInterval(readTimer);
        }
      }, 1000);
    },
    // 同意
    hasRead() {
      if (this.showType) {
        if (this.showType == "1") {
          this.showType1 = true;
        } else if (this.showType == "2") {
          this.showType2 = true;
        } else if (this.showType == "3") {
          this.showType3 = true;
        }
        this.agreementDialog = false;
        this.readDisabled = true;
        if (this.showType1 && this.showType2 && this.showType3) {
          this.checked = true;
          this.$set(this.$data, "fileType", "1");
        }
      } else {
        var Id = document.getElementById("dialog2");
        Id.getElementsByClassName("el-dialog")[0].scrollTop = 0;
        if (this.fileType == "1") {
          this.$set(this.$data, "fileType", "2");
          this.readCountDown();
          this.readDisabled = true;
        } else if (this.fileType == "2") {
          this.$set(this.$data, "fileType", "3");
          this.readCountDown();
          this.readDisabled = true;
        } else {
          this.$set(this.$data, "fileType", "1");
          this.checked = true;
          this.agreementDialog = false;
          // this.readCountDown();
          this.readDisabled = false;
        }
      }
    },
    // 关闭
    closeDialog() {
      this.agreementDialog = false;
    },
    eyefn() {
      this.password = this.password == "password" ? "text" : "password";
    },
    eyefnConfirm() {
      this.passwordConfirm =
        this.passwordConfirm == "password" ? "text" : "password";
    },
  },
};
</script>
<style lang="scss" scoped>
.fl {
  float: left;
}
.fr {
  float: right;
}
.little_font {
  font-size: 16px;
}
.blue {
  color: #2f5597;
}
.login-captcha {
  overflow: hidden;
  height: 40px;
  > img {
    width: 100%;
    cursor: pointer;
    height: 100%;
    margin-left: 10px;
  }
}
.page-login {
  display: flex;
  flex-direction: column;
  height: 100%;
  /*background-color: #8c8c8c;*/
}
.main-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // margin-top: -30px;
}
/*.main-contentTop{*/
/*width: 100%;*/
/*height: 100%;*/

/*}*/
.main-contentTop1 {
  height: 239px;
  /*background-color: red;*/
  background-image: url("../../assets/resager.png");
}
.main-TopContent {
  width: 1110px;
  height: 28px;
  margin: auto;
  padding-top: 64px;
  padding-bottom: 16px;
}
.main-contentImg {
  width: 208px;
  height: 28px;
  display: inline-block;
}
.main-TopContentBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1110px;
  height: 130px;
  background: white;
  margin: auto;
  border-bottom: 2px solid #e6e6e6;
  margin-top: 30px;
}
.main-ContentBottom {
  width: 33%;
  height: 130px;
  background-image: url("../../assets/login/wsqy_nor.png");
  background-repeat: no-repeat;
  background-position: 20% 70%;
  border-bottom: 1px solid #e6e6e6;
}
.main-ContentBottom1 {
  background-image: url("../../assets/login/wstzf_nor.png");
  width: 33%;
  background-repeat: no-repeat;
  background-position: 20% 70%;
  height: 130px;
  // border-bottom: 1px solid #e6e6e6;
}
h3 {
  margin: 0;
  font-family: PingFang-SC-Bold;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 28px;
}
.main-information {
  margin-left: 38%;
  margin-top: 61px;
  cursor: pointer;
}
a {
  text-decoration: none;
}
.information {
  opacity: 0.65;
  font-family: PingFang-SC-Regular;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 18px;
}
.main-ContentMessgae {
  width: 1110px;
  // height: 800px;
  padding-bottom: 20px;
  margin: auto;
  background-color: white;
}
.el-checkbox-group {
  float: left;
}
.el-form {
  margin-top: 32px;
  margin: auto;
  width: 530px;
}
.el-input {
  width: 400px;
  height: 32px;
}
.el-form-item__content {
  margin-left: 0;
}
.login-bgc {
  /*width: 100%;*/
  /*height: 80%;*/
  /*background-image: url("../assets/login/login_bg.png");*/
  /*background-size: 100% 100%;*/
  flex: 1;
  position: relative;
}
.changeColor h3 {
  color: #2937a6;
}
.changeColor span {
  color: #2937a6;
}
.changeColor2 h3 {
  color: #2937a6;
}
.changeColor2 span {
  color: #2937a6;
}
</style>
<style lang="scss">
.btn-s {
  .el-input-group__append {
    width: 70px;
    padding-left: 5px;
    padding-right: 25px;
  }
}
.agreement-dialog2 {
  .el-dialog {
    height: 700px;
    overflow: auto;
    &__header {
      display: none;
    }
  }
}
</style>
