<template>
  <div class="apply-detail">
    <div class="content add-apply">
      <div class="content-inner">
        <section class="info-card-A" style="height:calc(100vh - 150px)">
          <div class="title txbst">授信申请</div>
          <div class="review-flag" v-if="reviewFlag">
            <el-button class="button" type="primay" @click="reviewBtn(2)">通过</el-button>
            <el-button class="button" @click="reviewBtn(1)">回退</el-button>
            <el-button class="button" @click="$router.go(-1)">返回</el-button>
          </div>
          <div class="bottom">
            <span class="txbst">授信基本信息</span>
          </div>
          <div class="base-info">
            <div class="base-info-left">
              <div class="base-item">
                授信编号：
                <span>{{ detailList.grantCreditNo }}</span>
              </div>
              <div class="base-item">
                授信产品：
                <span>银e券</span>
              </div>
              <div class="base-item">
                资金机构：
                <span>{{ detailList.fundCorpName }}</span>
              </div>
              <div class="base-item">
                授予企业：
                <span>{{ detailList.grantCorpName }}</span>
              </div>
              <div class="base-item">
                授信额度（元）：
                <span>
                  {{
                  $changeMoneyThousand(detailList.availableAmount)
                  }}
                </span>
              </div>
              <div class="base-item">
                授信有效期：
                <span>
                  {{ moment(detailList.effectiveTime).format("YYYY-MM-DD") }} ~
                  {{ moment(detailList.closeDate).format("YYYY-MM-DD") }}
                </span>
              </div>
              <div class="base-item">
                供应商融资限额（元）：
                <span>
                  {{
                  $changeMoneyThousand(detailList.maxFinanceAmount)
                  }}
                </span>
              </div>
              <div class="base-item">
                资金用途：
                <span>{{ detailList.fundUsePurpose }}</span>
              </div>
              <div class="base-item">
                资金年化利率（%）：
                <span>
                  {{
                  detailList.fundYearInterest?detailList.fundYearInterest:'--'
                  }}
                </span>
              </div>
              <div class="base-item">
                融资年化利率（%）：
                <span>
                  {{
                  detailList.financeYearInterest
                  }}
                </span>
              </div>
              <div class="base-item">
                清分方式：
                <span>
                  {{
                  detailList.sorting == 2 ? "直接清分" : "逐级清分"
                  }}
                </span>
              </div>
              <div class="base-item">
                授信协议编号：
                <span>{{ detailList.creditAgreementNo }}</span>
              </div>
              <div class="base-item">
                融资收款账户类型：
                <span>{{ detailList.financeReceiveAccountType==1 ? "需本机构银行": "无要求" }}</span>
              </div>
              <div class="base-item" v-if="detailList.financeReceiveAccountType==1">
                融资收款卡银行：
                <span>{{ bankName }}</span>
              </div>
            </div>
            <div class="base-info-right" v-if="!reviewFlag">
              <el-button class="button" type="primay" @click="$router.go(-1)">返回</el-button>
              <div class="status-small">状态</div>
              <div class="status-large">{{ getCheckStatus(detailList.grantCheck) }}</div>
            </div>
          </div>
          <div class="bottom">
            <span class="txbst">资金流转设置</span>
          </div>
          <div class="fund-circulation">
            <p>
              资方清分审核： {{ detailList.fundClear == 0 ? "否" : "是" }}
              <span
                class="switch-text"
              >设置开则资金机构需要进行清分审核，设置关则不需要进行审核。</span>
            </p>
            <p>
              资金审查：
              <span style="margin-left:25px">
                {{
                detailList.fundCheck == 2 ? "否" : "是"
                }}
              </span>
              <span class="switch-text">设置打开则使用该授信开立银e券需要资方参与资料审查。</span>
            </p>
          </div>
          <div class="bottom">
            <span class="txbst">结算渠道设置</span>
          </div>
          <div class="settlement-site">
            <div>
              收款方式：
              <span style="margin-left:20px">
                {{ detailList.settlementChannel | parseSettlementChannel(settlementChannelList) }}
              </span>
            </div>
           
            <!-- <div>
              还款账户类型：<span style="margin-left:20px">{{
                detailList.repaymentType == 2 ? "对公账户" : "虚拟账户"
              }}</span>
            </div>
            <div>
              还款账户：<span style="margin-left:20px">{{
                detailList.repaymentAccount
              }}</span>
            </div>-->
          </div>
           <div class="account-content">
              <div class="base-item">
                收款账户：
                <span style="margin-left:20px">{{ detailList.fundReceiveAccountName }}</span>
              </div>
              <div class="base-item">
                开户行：
                <span style="margin-left:20px">{{ detailList.fundReceiveOpenBank }}</span>
              </div>
              <div class="base-item">
                收款账号：
                <span style="margin-left:20px">{{ detailList.fundReceiveAccount }}</span>
              </div>
            </div>
          <div class="bottom">
            <span class="txbst">
              授信协议
              <span class="gray">(支持上传拓展名：Office文件、*.txt、*.pdf、*.jpg、*.png。单个文件不超过10M）</span>
            </span>
          </div>
          <div class="picture-box">
            <div class="picture-box-item" v-for="item in imgList" :key="item">
              <!-- <img src="../../assets/login/login_bg.png"  alt="" /> -->
              <img
                v-if="imgType.includes(item.substring(item.lastIndexOf('.')))"
                :src="`${baseURL}` + `/web/file/downFile?filePath=${item}`"
                @click="$imgPreview"
                :data-img="`${baseURL}` + `/web/file/downFile?filePath=${item}`"
              />
              <img
                v-else-if="
                  item.substring(item.lastIndexOf('.')).toLowerCase() == '.pdf'
                "
                src="../../assets/file.png"
                @click="previewPdf(item)"
              />
              <img
                v-else
                v-show="item.length > 0"
                src="../../assets/zip.png"
                @click="downFile(item)"
              />
            </div>
          </div>

          <div class="bottom">
            <span class="txbst">利率调整记录</span>
          </div>
          <el-table
            :data="grantInterestList"
            :header-cell-style="headerStyle"
            highlight-current-row
            style="width: 100%;margin-top:20px"
          >
            <el-table-column type="index" label="序号" width="100"></el-table-column>
            <el-table-column
              prop="createTime"
              label="调整时间"
              min-width="80"
              value-format="yyyy-MM-dd"
            >
              <template slot-scope="scope">{{ moment(scope.row.createTime).format("YYYY-MM-DD") }}</template>
            </el-table-column>
            <el-table-column prop='operatorName' label="操作人" min-width="80">

            </el-table-column>
            <el-table-column prop='financeYearInterestOld' label="调整前利率(%/年化)" min-width="80"></el-table-column>

            <el-table-column prop='financeYearInterestNew' label="调整后利率(%/年化)" min-width="80"></el-table-column>
            <el-table-column prop="remark" label="备注" min-width="80">
              <template slot-scope="scope">{{scope.row.reason }} {{ scope.row.remark }}</template>
            </el-table-column>
          </el-table>
          <div class="bottom">
            <span class="txbst">审核记录</span>
          </div>
          <el-table
            :data="grantCreditApproveList"
            :header-cell-style="headerStyle"
            highlight-current-row
            style="width: 100%;margin-top:20px"
          >
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column
              prop="createTime"
              label="审核时间"
              min-width="80"
              value-format="yyyy-MM-dd"
            >
              <template slot-scope="scope">{{ moment(scope.row.createTime).format("YYYY-MM-DD") }}</template>
            </el-table-column>
            <el-table-column prop label="操作人" min-width="80">
              <template slot-scope="scope">{{scope.row.approveName}}</template>
            </el-table-column>
            <el-table-column prop label="审核结果" min-width="80">
              <template slot-scope="scope">{{scope.row.approveResult == 1 ? "退回" : "通过"}}</template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" min-width="80">
              <template slot-scope="scope">{{scope.row.reason }} {{ scope.row.remark }}</template>
            </el-table-column>
          </el-table>
        </section>
      </div>
    </div>
    <el-dialog
      title="审核确认"
      :visible.sync="dialogVisible"
      width="600px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="reviewForm"
        status-icon
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
        :rules="reviewRules"
      >
        <el-form-item
          label="审核结果："
          prop="approveResult"
        >{{ reviewForm.approveResult == 1 ? "审核回退" : "审核通过" }}</el-form-item>
        <el-form-item label="争议结果：" v-if="reviewForm.approveResult == 1" prop="reason">
          <el-select v-model="reviewForm.reason" placeholder="请选择">
            <el-option
              v-for="item in purposeList"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input type="textarea" maxlength="200" v-model="reviewForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confrimReview">确认审核</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/api';
import { baseURL } from '../../api/sys';
import moment from 'moment';
export default {
  name: 'fundApplyDetail',
  data() {
    return {
      grantCreditApproveList: [],
      grantInterestList: [],
      currentType: 2,
      purposeList: [
        { label: '提交的认证授信文件', value: 1 },
        { label: '认证授信书与注册信息不一致', value: 2 },
        { label: '其他', value: 3 }
      ],
      reviewForm: {
        approveResult: 1,
        reason: '',
        remark: ''
      },
      reviewRules: {},
      dialogTitle: '',
      dialogVisible: false,
      reviewFlag: this.$route.query.reviewFlag,
      imgType: [
        '.png',
        '.jpg',
        '.jpeg',
        '.bmp',
        '.gif',
        '.PNG',
        '.JPG',
        '.JPEG',
        '.BMP',
        '.GIF'
      ],
      baseURL: baseURL,
      baseURL,
      ruleForm: {
        creditCorp: '',
        availableAmount: '',
        accountCloseDate: '',
        fundUsePurpose: '',
        fundYearInterest: '',
        financeYearInterest: '',
        sorting: '',
        creditAgreementNo: '',
        fundCheck: '',
        settlementChannel: '',
        product: '银e券',
        creditCorp: '',
        fundCorpName: 'tencent',
        repaymentType: ''
      },
      detailList: {},
      imgList: [],
      bankName:'',
      settlementChannelList: []
    };
  },
  created() {
    this.getFinanceInterestUpdateList()
    this.getGrantCreditDetail();
    this.getDicListByType()
  },
  filters: {
    parseSettlementChannel(value, settlementChannelList) {
      if (value) {
        const findItem = settlementChannelList.find(
          (item) => item.code === value
        );
        return findItem?.name ?? "";
      }
    },
  },
  methods: {
    async getDicListByType() {
      const res = await api.getDicListByType({ type: 34 });
      if (res.success) {
        this.settlementChannelList = res.datas?.list || [];
      }
    },
    GetOpenBank() {
      api.GetOpenBank().then(res=>{
        if(res.success) {
            res.datas.corpBankList.map(_ =>{
            if(this.detailList.financeReceiveBankId == _.bankId) {
              this.bankName = _.name
            }
           })
           console.log(this.bankName,this.detailList.financeReceiveBankId,'bankName')
        }else {
          this.$message.error(res.message)
        }
      }).catch(err=>{})
    },
    getCheckStatus(v) {
      var state = '';
      switch (v) {
        case 10:
          state = '授信待提交';
          break;
        case 20:
          state = '资金方授信待复核';
          break;
        case 25:
          state = '资金方复核通过';
          break;
        case 26:
          state = '资金方复核回退';
          break;
        case 40:
          state = '平台待初审';
          break;
        case 41:
          state = '平台初审通过';
          break;
        case 42:
          state = '平台初审回退';
          break;
        case 43:
          state = '复审通过';
          break;
        case 44:
          state = '复审拒绝';
          break;
        case 46:
          state = '平台授信回退';
          break;
        case 99:
          state = '授信通过';
          break;
        case 999:
          state = '授信过期';
          break;
      }
      return state;
    },
    headerStyle({ row, rowIndex }) {
      return { background: '#FAFAFA' };
    },
    moment: moment,
    confrimReview() {
      console.log(this.reviewForm, 'reviewForm');
      let prams = {
        ...this.reviewForm,
        grantCreditId: this.$route.query.grantCreditId,
        fundCorpName: this.detailList.fundCorpName
      };
      api.submitFundGrantCreditReviewApprove(prams).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.dialogVisible = false;
          this.$router.go(-1);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    cancel() {
      this.dialogVisible = false;
      // this.$refs.reviewForm.clearValidate();
      this.reviewForm = {};
    },
    reviewBtn(type) {
      this.reviewForm.approveResult = type;
      this.dialogVisible = true;
    },
    previewPdf(item) {
      window.open(this.baseURL + '/web/file/downFile?filePath=' + item);
    },
    downFile(item) {
      window.open(this.baseURL + '/web/file/downFile?filePath=' + item);
    },
    getGrantCreditDetail() {
      let param = {
        grantCreditId: this.$route.query.grantCreditId
      };
      api.getGrantCreditDetail(param).then((res) => {
        if (res.success) {
          this.grantCreditApproveList =
            res.datas.GrantCredit.grantCreditApproveList;
          this.detailList = res.datas.GrantCredit;
          this.GetOpenBank()
          console.log(this.detailList,'detailList')
          this.imgList = res.datas.GrantCredit.grantAgreementFileUrl.split(',');
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getFinanceInterestUpdateList() {
      api
        .getFinanceInterestUpdateList({
          grantCreditId: this.$route.query.grantCreditId
        })
        .then((res) => {
          if (res.success) {
            this.grantInterestList =
              res.datas.list;
          } else {
            this.$message.error(res.message);
          }
        });
    }
  }
};
</script>

<style lang="less" scoped>
.base-info {
  display: flex;
  width: 100%;
  padding: 10px 30px;
  .base-info-left {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .base-item {
      width: 33.3%;
      line-height: 40px;
      padding-right: 10px;
    }
  }
  .base-info-right {
    width: 150px;
    height: 100%;
    float: left;
    // display: flex;
    // flex-wrap: wrap;
    .status-large {
      font-size: 16px;
      font-weight: bold;
      text-align: right;
    }
    .status-small {
      font-size: 14px;
      color: grey;
      text-align: right;
      margin: 10px 0;
    }
    .button {
      margin-left: 85px;
    }
  }
}
.account-content{
  flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 20px;
    .base-item {
      width: 33.3%;
      line-height: 40px;
    }
}
.title {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-left: 12px;
  border-bottom: 2px solid #eee;
  &::before {
    content: '';
    display: inline-block;
    width: 3px;
    height: 16px;
    background-color: #d19f53;
    position: absolute;
    left: 0;
    right: 0;
    top: 15px;
  }
}
.switch-text {
  color: red;
  margin-left: 50px;
}
.bottom {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 2px solid #eee;
  line-height: 40px;
  width: 100%;
  padding-left: 12px;
  margin-bottom: 20px;
}
.fund-circulation {
  padding: 0 20px;
}
.settlement-site {
  display: flex;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 20px;
  > div {
    width: 33%;
  }
}
.gray {
  color: gray;
  font-size: 12px;
  margin-left: 20px;
}
.picture-box {
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  > .picture-box-item {
    margin-right: 20px;
    height: 100px;
    width: 100px;
    > img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}
.review-flag {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
