<template>
  <div>
       <layout-main-view :breadcrumb="breadcrumblist" :tabOptions="tabOptions" @tabclick="tabclick">
            <waitget v-if="showtab==1"></waitget>
            <acceptlist v-if="showtab==2"></acceptlist>
            <!-- <assignment v-if="showtab==3"></assignment>
            <financing v-if="showtab==4"></financing>
            <refusereturn v-if="showtab==5"></refusereturn> -->

    </layout-main-view>


  </div>
</template>
<script>
import { searchEntryList } from "../../api/api";
import waitget from "./waitget";
import acceptlist from "./acceptlist";
// import assignment from "./assignment";
// import financing from "./financing";
// import refusereturn from "./refusereturn";






export default {
  components: {
    "waitget": waitget,
    "acceptlist": acceptlist,
    // "assignment": assignment,
    // "financing": financing,
    // "refusereturn": refusereturn,

  },
  data() {
    return{
      breadcrumblist: ["银e券管理"],
          tabOptions: [
        { label: "待接收", value: "1", path: "/easypay/lseasypaymange/1" },
        { label: "已持有", value: "2", path: "/easypay/lseasypaymange/2" },
        { label: "已转让", value: "3", path: "/easypay/lseasypaymange/3" },
        // { label: "已融资", value: "4", path: "/easypay/lseasypaymange/4" },
        { label: "被退回", value: "5", path: "/easypay/lseasypaymange/5" },

      ],
            showtab:1
    }
  },
  created() {
        this.showtab=this.$route.params.state
  },
  methods: {
     tabclick(val) {
      this.showtab = val;
      this.handleEditShow = false;
    },
  }
};
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
