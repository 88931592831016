<template>
  <div class="content easypay-manager distingduishing-detail">
    <div class="content-inner">
      <el-tabs v-model="activetab">
        <el-tab-pane label="清分详情" name="first">
          <section class="info-card-A">
            <div class="card-hd sub-title">
              <span class="txbst">基本信息</span>
              <el-button
                type="text"
                class="page-head-back"
                style="color: #2937a6"
                size="small"
                @click="previewEasypay"
                >银e券编号:{{ financInfo.payableBillNo }}预览</el-button
              >
            </div>
            <div class="card-bd card-bd-row">
              <el-row>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">资金机构:</span>
                  <span class="info-value">{{ financInfo.fundCorpName }}</span>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">授信编号:</span>
                  <span class="info-value">{{ financInfo.grantCreditNo }}</span>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">授信截止日期:</span>
                  <span class="info-value">{{
                    moment(financInfo.grantCloseTime).format('YYYY-MM-DD')
                  }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">原始银e券额度:</span>
                  <span class="info-value"
                    >{{
                      changeMoneyThousand(financInfo.payableBillAmount)
                    }}元人民币</span
                  >
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">承诺付款日:</span>
                  <span class="info-value">{{
                    moment(financInfo.promisePayTime).format('YYYY-MM-DD')
                  }}</span>
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">已融资金额:</span>
                  <span class="info-value"
                    >{{
                      changeMoneyThousand(
                        financInfo.payableBillFinanceAmountTotal
                      )
                    }}元人民币</span
                  >
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">需清分金额:</span>
                  <span class="info-value"
                    >{{
                      changeMoneyThousand(
                        financInfo.payableBillClearAmountTotal
                      )
                    }}元人民币</span
                  >
                </el-col>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">需清分企业:</span>
                  <span class="info-value">
                    {{ financInfo.beClearNum }}家
                  </span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" class="lb_body">
                  <span class="info-key">已逾期天数:</span>
                  <span class="info-value">{{
                    financInfo.overdueDays || 0
                  }}</span>
                </el-col>
              </el-row>
              <div class="card-line"></div>
            </div>
            <div class="card-hd sub-title">
              <span class="txbst">需清分企业名单</span>
            </div>
            <div class="card-bd card-bd-row">
              <el-table :data="datalist" style="width: 100%">
                <el-table-column type="index" label="序号" width="80">
                </el-table-column>
                <el-table-column
                  prop="payableBillNo"
                  label="子银e券号"
                  width="240"
                >
                </el-table-column>
                <el-table-column
                  prop="originalName"
                  label="开单企业"
                  min-width="200"
                ></el-table-column>
                <el-table-column
                  prop="receivedCorpName"
                  label="收款企业"
                  width="200"
                >
                </el-table-column>
                <el-table-column
                  prop="receivedCorpCreditCode"
                  label="社会统一信用代码"
                  width="200"
                >
                </el-table-column>
                <el-table-column
                  prop="payableBillAmount"
                  align="right"
                  :formatter="(el) => changeMoneyThousand(el.payableBillAmount)"
                  label="银e券金额(元)"
                  width="145"
                >
                </el-table-column>
                <el-table-column
                  prop="payableBillFinanceAmount"
                  align="right"
                  :formatter="
                    (el) => changeMoneyThousand(el.payableBillFinanceAmount)
                  "
                  label="已融资总额(元)"
                  width="145"
                >
                </el-table-column>

                <el-table-column
                  prop="payableBillClearAmount"
                  align="right"
                  :formatter="
                    (el) => changeMoneyThousand(el.payableBillClearAmount)
                  "
                  label="清分总额(元)"
                  width="145"
                >
                </el-table-column>
                <el-table-column
                  prop="lsStatus"
                  label="付款状态"
                  :formatter="statusfn"
                  width="145"
                >
                </el-table-column>
                <!-- <el-table-column
                  prop="remark"
                  label="失败原因"
                  show-overflow-tooltip
                  v-if="isshowbtn == 1"
                  width="200"
                >
                </el-table-column> -->
                <el-table-column
                  label="操作"
                  width="170"
                  v-if="settlementChannel != 0"
                >
                  <template slot-scope="scope">
                    <el-button
                      size="medium"
                      type="text"
                      @click="loanDetial(scope.row)"
                      v-if="
                        scope.row.liquidationType == 1
                          ? scope.row.lsStatus == 3
                          : scope.row.zfStatus == 3
                      "
                      >查看付款凭证</el-button
                    >
                    <el-button
                      size="medium"
                      v-else
                      type="text"
                      @click="upload(scope.row)"
                      >上传付款凭证</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </section>
        </el-tab-pane>
        <el-tab-pane label="流转详情" name="second" class="info-card-A">
          <div class="text-center">
            <vue2-org-tree
              name="test"
              :data="lzdata"
              :horizontal="horizontal"
              :collapsable="collapsable"
              :render-content="renderContent"
              labelWidth="320px"
              labelClassName="label-box"
              @on-node-click="orgclickfn"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="card-footer">
        <el-row>
          <el-col :span="14" class="lb_body" :offset="5" v-if="isshowbtn == 1">
            <el-button
              style="
                width: 160px;
                height: 40px;
                font-size: 16px;
                background: #2937a6;
                color: #ffffff;
              "
              size="small"
              @click="goback"
              >返回</el-button
            >
            <!-- <el-button style="width: 160px;height: 40px;font-size:16px;background: #2937A6;color:#ffffff;" size="small" @click="paybutton" v-if="settlementChannel != 0" :disabled="!payableBillLiquidationIdList.length">清分完成</el-button> -->
            <el-button
              style="
                width: 160px;
                height: 40px;
                font-size: 16px;
                background: #2937a6;
                color: #ffffff;
              "
              size="small"
              v-if="settlementChannel == 0"
              @click="payBtn"
              >发出付款指令</el-button
            >
          </el-col>
          <el-col :span="8" class="lb_body" :offset="8" v-if="isshowbtn == 2">
            <el-button
              style="
                width: 160px;
                height: 40px;
                font-size: 16px;
                background: #2937a6;
                color: #ffffff;
              "
              size="small"
              @click="goback"
              >返回</el-button
            >
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog
      title="付款指令确认"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      :show-close="false"
      width="60%"
    >
      <el-alert
        title="请注意，你已经发起了清分付款指令。点击确认支付以后将会向下列账户进行付款操作。确认支付指令一旦下达将不可逆转。请仔细确认下面的账号和相关金额"
        type="warning"
        show-icon
        :closable="false"
      ></el-alert>
      <el-table :data="billPaylist" style="width: 100%; margin-top: 20px">
        <el-table-column type="index" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="openCorpName" label="付款企业">
        </el-table-column>
        <el-table-column prop="receivedCorpName" label="收款企业">
        </el-table-column>
        <el-table-column prop="beneficiaryFundAccount" label="资金账号">
        </el-table-column>
        <el-table-column
          prop="amount"
          align="right"
          :formatter="(el) => changeMoneyThousand(el.amount)"
          label="收款金额(元)"
        >
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmPayment"
          :disabled="billPaylist.length == 0"
          >确认付款</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title
      :visible.sync="imgDialogVisible"
      width="80%"
      center
      class="img-dialog"
    >
      <!-- <img :src="fileUrl" alt="图片丢失" width="30%" height="30%" preview-text="放款凭证" :preview="1"/> -->
      <div class="img-box">
        <div
          class="img-box-item"
          v-for="(item, index) in fileList"
          :key="item.url + index"
        >
          <img
            v-if="
              item.trueUrl &&
              item.trueUrl
                .substring(item.trueUrl.lastIndexOf('.'))
                .toLowerCase() == '.pdf'
            "
            :src="pdfImg"
            alt="图片丢失"
            width="30%"
            height="30%"
            @click="previewPdf(item.trueUrl)"
          />
          <img
            v-else-if="
              item.trueUrl &&
              excelfileExtend.indexOf(
                item.trueUrl
                  .substring(item.trueUrl.lastIndexOf('.'))
                  .toLowerCase()
              ) == -1 &&
              item.trueUrl
                .substring(item.trueUrl.lastIndexOf('.'))
                .toLowerCase() !== '.pdf'
            "
            :src="zip"
            alt="图片丢失"
            width="30%"
            height="30%"
            @click="previewPdf(item.trueUrl)"
          />
          <!-- excelfileExtend.indexOf(filetype) == -1 && filetype!=='.pdf' -->
          <img
            v-else
            :src="item.trueUrl"
            alt="图片丢失"
            :data-img="item.trueUrl"
            width="30%"
            height="30%"
            :preview-text="item.fileName"
            @click="$imgPreview"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="imgDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog title :visible.sync="centerDialogVisible" width="80%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <pdf class="pdf" ref="pdf" :src="src"></pdf>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="融资列表"
      :visible.sync="showAddDialog3"
      :close-on-click-modal="false"
      :show-close="false"
      width="1000px"
    >
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column
          prop="payableBillFinanceNo"
          label="融资项目编号"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="financePayableBillNo"
          label="融资银e券编号"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="fundCorpName"
          label="资金机构"
          width="180"
        ></el-table-column>
        <el-table-column label="融资金额">
          <template slot-scope="scope">{{
            scope.row.amount | formatMoney
          }}</template>
        </el-table-column>
        <el-table-column label="融资完成时间">
          <template slot-scope="scope">{{
            scope.row.financeTime | formatDate
          }}</template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showAddDialog3 = false"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="清分付款确认"
      :visible.sync="uploadDialog"
      :close-on-click-modal="false"
      :show-close="false"
      width="880px"
    >
      <div class="base-info">
        <span class="txbst">清分基本信息</span>
      </div>
      <div class="base-info-content">
        <div class="item">
          收费企业：<span>{{ payInfo.receivedCorpName }}</span>
        </div>
        <div class="item">
          企业类型：<span>{{ getCorpType(payInfo.corpType) }}</span>
        </div>
        <div class="item">
          金额（元）：<span>{{
            changeMoneyThousand(payInfo.payableBillClearAmount)
          }}</span>
        </div>
        <div class="item">
          账名：<span>{{ payInfo.accountName }}</span>
        </div>
        <div class="item">
          开户行：<span>{{ payInfo.openBank }}</span>
        </div>
        <div class="item">
          收款账号：<span>{{ payInfo.accountNo }}</span>
        </div>
      </div>
      <div class="base-info border-top">
        <span class="txbst"
          >付款凭证上传<span class="txbst2"
            >（支持上传拓展名：Office文件 *txt *.pdf *.jpg
            *.png。单个文件不超过30M）</span
          ></span
        >
      </div>
      <div class="upload-box">
        <el-upload
          ref="uploadFile"
          class="upload-demo"
          :action="baseURL + '/web/file/uploadFile'"
          :headers="Authorization"
          list-type="picture-card"
          :on-success="handleAvatarSuccess"
          :on-remove="handleRemove2"
          :on-preview="handlePreview"
          :before-upload="beforeAvatarUpload"
          :file-list="fileList"
        >
          <i class="el-icon-plus"></i>
          <span class="upload-info">上传</span>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="uploadDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmUpload">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="infoDialog"
      :close-on-click-modal="false"
      :show-close="false"
      width="520px"
    >
      <div>请再次确认您已完成对清分企业的转账和凭证上传操作</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="infoDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitBtn">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import pdf from 'vue-pdf';
import { baseURL } from '@/api/sys';
import api from '@/api/api';
import pdfImg from '@/assets/file.png';
import zip from '@/assets/zip.png';
import { changeMoneyThousand } from '@/util/common';

export default {
  components: {
    pdf,
  },
  data() {
    return {
      currentNo: '',
      viewFlag: false,
      dialogVisible: false,
      dialogImageUrl: '',
      infoDialog: false,
      fileList: [],
      loading: {},
      pdfImg,
      zip,
      imgType: [
        '.png',
        '.jpg',
        '.jpeg',
        '.bmp',
        '.gif',
        '.PNG',
        '.JPG',
        '.JPEG',
        '.BMP',
        '.GIF',
      ],
      fileDatas: [],
      baseURL,
      baseURL,
      Authorization: { Authorization: sessionStorage.token },
      uploadDialog: false,
      uploadVouchers: [],
      moment: moment,
      api: api,
      financInfo: {},
      datalist: [],
      changeMoneyThousand: changeMoneyThousand,
      activetab: 'first',
      horizontal: false,
      collapsable: false,
      expandAll: false,
      centerDialogVisible: false,
      src: '',
      lzdata: {},
      payableBillLiquidationIdList: [],
      showDialog: false,
      billPaylist: [],
      bclick: false,
      isshowbtn: this.$route.query.isshowbtn, // 1未清分 2已清分
      settlementChannel: this.$route.query.settlementChannel, // 1中金 0上海银行
      imgDialogVisible: false,
      fileUrl: '',
      status: this.$route.query.status, // 1 担保方
      isOverdue: this.$route.query.isOverdue, // 1 逾期
      showAddDialog3: false,
      tableData: [],
      excelfileExtend: '.jpg,.png,.jpeg,.bmg,.gif',
      payInfo: {},
      payableBillLiquidationFileId: '',
      payableBillFinanceFileList: [],
    };
  },
  created() {
    this.getDetail();
    this.circulation();
  },
  mounted() {},
  methods: {
    previewPdf(item) {
      window.open(item);
    },
    // 文件回显
    getFile(list, expectList, name) {
      if (list.length > 0) {
        list.forEach((v) => {
          let filetype = v.substring(v.lastIndexOf('.')).toLowerCase();
          let temp = {};
          temp = {
            name: '付款凭证',
            url:
              this.excelfileExtend.indexOf(filetype) == -1 &&
              filetype !== '.pdf'
                ? require('@/assets/zip.png')
                : filetype == '.pdf'
                ? require('@/assets/file.png')
                : api.getImgURL(v), // 缩略图
            trueUrl: api.getImgURL(v), // 真实的文件地址
          };
          expectList.push(temp);
          console.log(this.fileList, 'fileList');
        });
      }
    },
    getCorpType(v) {
      let state = '';
      switch (v) {
        case 1:
          state = '资金方';
          break;
        case 2:
          state = '核心企业';
          break;
        case 3:
          state = '链属企业';
          break;
        case 5:
          state = '核心+链属';
          break;
      }
      return state;
    },
    confirmUpload() {
      //  if (this.fileList.length > 0) {
      //        this.fileList.map((_) => {
      //          this.uploadVouchers.push(_.response.datas.uploadDate.message);
      //         });
      //      } else {
      //        this.$message.error("请上传转账凭证！")
      //        return;
      //      }
      let uploadVouchers = [];
      console.log(this.fileList, 'fileList');
      if (this.fileList.length > 0 && !this.fileList.trueUrl) {
        this.fileList.map((_) => {
          if (!_.trueUrl) {
            uploadVouchers.push(_.response.datas.uploadDate.message);
          } else {
            uploadVouchers.push(_.trueUrl.split('=')[1]);
          }
        });
      } else {
        this.$message.error('请上传转账凭证！');
        return;
      }
      let params = {
        payableBillLiquidationFileId: this.payableBillLiquidationFileId
          ? this.payableBillLiquidationFileId
          : '',
        payableBillId: this.$route.query.payableBillId,
        payableBillNo: this.currentNo,
        uploadVouchers: uploadVouchers.join(','),
      };
      console.log(params, 'params');
      api
        .uploadVoucher(params)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.uploadDialog = false;
            console.log(res, 'res');
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {});
    },
    downFile() {
      return api.downFile();
    },
    // 下载TXT文件
    downloadFile(url) {
      console.log(url);
      const link = document.createElement('a');
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          link.href = URL.createObjectURL(blob);
          // console.log(link.href)
          link.download = '';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },
    handlePreview(file) {
      // if (file.response) {
      //   window.open(api.getImgURL(file.response.datas.uploadDate.message));
      // }
      console.log(file, 'file');
      function arrayTolowerCase(data) {
        var s = data;
        var pos = s.search(/\.[A-Z]+$/);
        var m = s.match(/\.[A-Z]+$/);
        if (m !== null) {
          data = s.slice(0, pos) + m[0].toLowerCase();
        }
        return data;
      }
      let excelfileExtend = '.jpg,.png,.jpeg,.bmg,.gif'; //设置文件格式
      let fileExtend = file.name
        .substring(file.name.lastIndexOf('.'))
        .toLowerCase();
      if (file.response) {
        if (
          excelfileExtend.indexOf(fileExtend) <= -1 &&
          fileExtend !== '.txt'
        ) {
          window.open(
            this.downFile() +
              '?filePath=' +
              arrayTolowerCase(file.response.datas.uploadDate.message) +
              '&fileName=file'
          );
        } else if (excelfileExtend.indexOf(fileExtend) != -1) {
          this.dialogImageUrl = file.url;
          this.dialogVisible = true;
          // window.open(api.getImgURL(arrayTolowerCase(file.response.datas.uploadDate.message)));
        } else {
          this.downloadFile(
            arrayTolowerCase(
              api.getImgURL(file.response.datas.uploadDate.message)
            )
          );
        }
      } else {
        // 回显的数据
        let excelfileExtend2 = '.jpg,.pdf,.png,.jpeg,.bmg,.gif'; //设置文件格式
        let fileExtend2 = file.trueUrl
          .substring(file.trueUrl.lastIndexOf('.'))
          .toLowerCase();
        if (
          excelfileExtend2.indexOf(fileExtend2) <= -1 &&
          fileExtend2 !== '.txt'
        ) {
          window.open(file.trueUrl);
        } else if (excelfileExtend2.indexOf(fileExtend2) != -1) {
          this.dialogImageUrl = file.url;
          this.dialogVisible = true;
          // window.open(file.trueUrl);
        } else {
          this.downloadFile(file.trueUrl);
        }
        // window.open(file.trueUrl);
      }
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 30;
      console.log('file =======>', file);
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 30MB!');
        return false;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.loading = loading;
    },
    handleAvatarSuccess(res, file, fileList) {
      if (res.success) {
        this.$message.success('上传成功');
        this.fileList = fileList;
        console.log(this.fileList, 'handleAvatarSuccess');
        let name = file.name;
        let filetype = name.substring(name.lastIndexOf('.')).toLowerCase();
        if (
          this.excelfileExtend.indexOf(filetype) == -1 &&
          filetype !== '.pdf'
        ) {
          file.url = require('@/assets/zip.png');
        } else if (filetype == '.pdf') {
          file.url = require('@/assets/file.png');
        }
      } else {
        this.$message.error(res.message);
        let uid = file.uid; // 关键作用代码，去除文件列表失败文件
        let index = this.$refs.uploadFile.fileList.length;
        let idx = this.$refs.uploadFile.fileList.findIndex(
          (item) => item.uid === uid
        ); // 关键作用代码，去除文件列表失败文件（fileList为el-upload中的ref值）
        console.log(idx, '=======', this.$refs.uploadFile.fileList);
        this.$refs.uploadFile.fileList.splice(index, 1); // 关键作用代码，去除文件列表失败文件
      }
      this.loading.close();
    },
    handleRemove2(file, fileList) {
      this.fileList = fileList;
      console.log(this.fileList, 'fileList');
    },
    getInfo(row) {
      let params = {
        payableBillNo: row.payableBillNo,
        payableBillLiquidationId: row.payableBillLiquidationId,
      };
      api
        .getPayableBillLiquidationFileByPayableBillNo(params)
        .then((res) => {
          if (res.success) {
            this.payInfo = res.datas.payableBillLiquidationFile;
            this.payInfo.payableBillClearAmount = row.payableBillClearAmount;
            if (this.payInfo.payableBillLiquidationFileId) {
              this.payableBillLiquidationFileId =
                this.payInfo.payableBillLiquidationFileId;
            } else {
              this.payableBillLiquidationFileId = null;
            }
            if (res.datas.payableBillLiquidationFile.uploadVouchers) {
              this.viewFlag = true;
              let fileList =
                res.datas.payableBillLiquidationFile.uploadVouchers.split(',');
              this.getFile(fileList, this.fileList, '转账凭证');
            }
            console.log(this.payInfo, 'payInfo');
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {});
    },
    upload(row) {
      this.currentNo = row.payableBillNo;
      this.uploadDialog = true;
      this.fileList = [];
      this.getInfo(row);
      console.log(row, 'row');
    },
    submitBtn() {
      let params = {
        payableBillNo: this.$route.query.payableBillNo,
        type: this.$route.query.type,
      };
      console.log(params, 'papp');
      api
        .commitPayableBillLiquidation(params)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.$router.go(-1);
            this.infoDialog = false;
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {});
    },
    paybutton() {
      this.submitBtn();
      // this.infoDialog = true
      // this.$alert("请再次确认您已完成对清分企业的转账和凭证上传操作","提示",{
      //   confirmButtonText: '确定',
      //         closeOnClickModal: false,
      //             callback: (action) => {
      //               let params = {
      //                 payableBillNo:this.$route.query.payableBillNo,
      //                 type:this.$route.query.type
      //               }
      //               console.log(params,'papp')
      //               api.commitPayableBillLiquidation(params).then(res=>{
      //                 if(res.success) {
      //                   this.$message.success(res.message)
      //                 }else {
      //                   this.$message.error(res.message)
      //                 }
      //               })
      //             }
      // })
    },
    getDetail(param) {
      // type 1 持有企业 2资金机构
      // typeStr 1 正常清分/已清分 2逾期清分
      let typeStr = this.isshowbtn == 1 && this.isOverdue == '是' ? 2 : 1;
      api
        .getPayableBillLiquidationDetailByPay({
          payableBillNo: this.$route.query.payableBillNo,
          type: this.$route.query.type,
          typeStr,
        })
        .then((res) => {
          if (res.code == '100000') {
            this.financInfo = res.datas.single;
            this.datalist = res.datas.list;
            res.datas.list.forEach((v) => {
              if (
                (v.lsStatus == 1 || v.lsStatus == 4) &&
                this.$route.query.type == 1
              ) {
                this.payableBillLiquidationIdList.push(
                  v.payableBillLiquidationId
                );
              } else if (
                (v.zfStatus == 1 || v.zfStatus == 4) &&
                this.$route.query.type == 2
              ) {
                this.payableBillLiquidationIdList.push(
                  v.payableBillLiquidationId
                );
              }
            });
          } else {
            this.$message.error(res.message);
          }
        });
    },
    goback() {
      history.go(-1);
    },
    loanDetial(row) {
      this.fileList = [];
      this.imgDialogVisible = true;
      this.getInfo(row);
    },
    //getImgURL
    previewEasypay() {
      window.open(api.getImgURL(this.financInfo.evidencePath));
      // console.log(this.financInfo.contractNo ,'this.financInfo.contractNo ',this.financInfo)
      // api
      //   .DownloadContract({ contractNo: this.financInfo.contractNo })
      //   .then(res => {
      //     console.log(res,'res')
      //     let binaryData = [];
      //     binaryData.push(res);
      //     this.src = window.URL.createObjectURL(
      //       new Blob(binaryData, { type: "application/zip" })
      //     );
      //     // this.centerDialogVisible = true;
      //     window.open('/static/pdf/web/viewer.html?file=' +encodeURIComponent(this.src) + '&.pdf');//this.centerDialogVisible = true;;
      //   });
    },
    circulation() {
      api
        .circulation({ payableBillId: this.$route.query.payableBillId })
        .then((res) => {
          if (res.code == '100000') {
            this.lzdata = res.datas.payableBillDetail[0];
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {});
    },
    renderContent(h, data) {
      if (!data.hild) {
        let currentData = '';
        if (this.$route.query.payableBillId == data.payableBillId) {
          currentData = 'flow-style active';
        } else {
          currentData = 'flow-style';
        }
        let content = [
          h('div', {}, '银e券编号:' + data.payableBillNo),
          h('div', {}, '原始开立方:' + data.originalName),
          data.openCorpName ? h('div', {}, '转让方:' + data.openCorpName) : '',
          h('div', {}, '持有方:' + data.receiveName),
          h(
            'div',
            {},
            '金额：' + changeMoneyThousand(data.payableBillAmount) + ' 元'
          ),
          h(
            'div',
            {},
            '承诺付款日：' +
              (data.paymentDate
                ? moment(data.paymentDate).format('YYYY-MM-DD')
                : '')
          ),
          h(
            'div',
            {},
            '是否已融资：' +
              (data.payableBillFinanceList?.length > 0 ? '是(点击查看)' : '否')
          ),
        ];
        return h('div', { domProps: { className: currentData } }, content);
      } else {
        return '多次流转';
      }
    },
    orgclickfn(val, data) {
      if (data.payableBillFinanceList.length > 0) {
        this.showAddDialog3 = true;
        this.tableData = data.payableBillFinanceList;
      }
    },
    payBtn() {
      this.showDialog = true;
      // type 1持有企业 2资金机构
      let params = {
        payableBillLiquidationIds: this.payableBillLiquidationIdList,
        type: this.$route.query.type,
      };
      if (this.status == 1) {
        // 担保方
        params.status = this.status;
      }
      api.paymentConfirmationList(params).then((res) => {
        if (res.success) {
          this.billPaylist = res.datas.list;
          if (this.status == 1) {
            // 担保方
            this.billPaylist.map((v) => {
              v.type = this.$route.query.type; // 1持有方清分 2资金机构清分
              v.status = this.status;
            });
          } else {
            this.billPaylist.map((v) => {
              v.type = this.$route.query.type; // 1持有方清分 2资金机构清分
            });
          }
        }
      });
    },
    confirmPayment() {
      this.bclick = true;
      api
        .confirmPayment({ payableBillPayList: this.billPaylist })
        .then((res) => {
          if (res) {
            this.getDetail();
            this.bclick = false;
            this.showDialog = false;
            this.$alert('付款指令已发送，请稍后查看付款结果', '', {
              confirmButtonText: '确定',
              callback: (action) => {
                history.go(-1);
              },
            });
          }
        });
    },
    statusfn(val) {
      let state = '';
      let zfOrls = this.$route.query.type == 1 ? val.lsStatus : val.zfStatus;
      switch (zfOrls) {
        case 1:
          state = '等待付款';
          break;
        case 2:
          state = '付款中';
          break;
        case 3:
          state = '付款成功';
          break;
        case 4:
          state = '付款失败';
          break;
      }
      return state;
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/moreDetail/moudleDetail.scss';

.content {
  //padding: 0px 0px 0px 30px;
  width: 100%;
  height: 100%;
  background-color: white;
  .infotitle {
    color: #262626;
    font-size: 16px;
    font-weight: bold;
    text-indent: 32px;
    display: inline-block;
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
    height: 60px;
    line-height: 60px;
  }
  .info-card-A {
    padding: 0 32px;
  }
  .card-bd-row {
    line-height: 24px;
  }
  .page-head-back {
    float: right;
    margin-right: 40px;
    margin-top: 16px;
    border: 0px scope #fff;
    &.downsize {
      margin-top: 0;
      font-weight: normal;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .sub-title {
    border-bottom: none;
    height: 40px;
    font-size: 16px;
    color: #262626;
    padding: 0;
    .txbst {
      position: relative;
      left: -32px;
    }
  }
  .card-line {
    height: 20px;
    border-bottom: 1px #e6e6e6 solid;
  }
  //  重写开始
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .maincontin {
    margin-top: 30px;
    .marrimg {
      img {
        margin-right: 1%;
      }
    }
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;

    border-radius: 4px;

    .mainht {
      color: #262626;
      font-size: 16px;
      font-weight: bold;
      text-indent: 32px;
      display: inline-block;
      border-bottom: 1px solid #e6e6e6;
      width: 100%;
      height: 60px;
      line-height: 60px;
    }
    .htmsg {
      margin: 10px 0 10px 32px;
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 200px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
    .isdot {
      .el-badge__content.is-fixed {
        top: 10px;
        right: 0px;
      }
      .el-badge__content {
        background-color: #cfcfcf;
      }
    }
    .numcolor {
      font-size: 18px;
      color: #f5212d;
    }
  }
}
</style>
<style lang="scss" scoped>
.distingduishing-detail {
  .text-center {
    min-height: calc(100vh - 300px);
  }
  .info-card-A {
    min-height: calc(100vh - 324px);
  }
}
.base-info {
  color: #262626;
  font-weight: bold;
  font-size: 18px;
  margin-left: 10px;
  line-height: 45px;
  border-top: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
}
.border-top {
  border-top: none;
}
.base-info-content {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 0;
  .item {
    width: 33%;
    line-height: 50px;
  }
}
.txbst2 {
  font-size: 12px;
  color: #7c7878;
}
.upload-box {
  margin-top: 10px;
  padding-left: 50px;
  .el-upload--picture-card {
    position: relative;
  }
  .upload-info {
    position: absolute;
    left: 39%;
    top: 32%;
  }
}
.img-box {
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  > .img-box-item {
    margin-right: 20px;
    height: 200px;
    width: 200px;
    margin-bottom: 20px;
    > img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}
</style>
