<template>
    <div class="common-page-dataList">
        <el-tabs type="border-card" v-model="activeName" class="n-tabs">
            <data-list
                    :filters="filterList"
                    :isShowAll="isShowAll"
                    :isHedden="isHedden"
                    @data-showAll="filterShowAll"
                    @data-refresh="handleRefresh"
                    :total="total"
                    ref="datalist"
            >
                <el-table
                        :data="listData"
                        :header-cell-style="headerStyle"
                        highlight-current-row
                        v-loading="listLoading"
                        style="width: 100%;"
                >
                    <el-table-column prop="payableBillFinanceNo" label="融资项目编号" min-width="170"></el-table-column>
                    <el-table-column prop="financePayableBillNo" label="融资子银e券编号" min-width="220"></el-table-column>
                    <el-table-column prop="financeCorpName" label="融资企业" min-width="170"></el-table-column>
                    <el-table-column prop="amount" label="融资金额（元)" min-width="150">
                        <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.amount)}}</template>
                    </el-table-column>
                    <el-table-column prop="financeYearInterest" label="融资年化利率（％）" min-width="150"></el-table-column>
                    <el-table-column prop="preFinanceInterest" label="融资利息(元)" min-width="150"></el-table-column>
                    <el-table-column prop="preIncomeAmount" label="到帐金额(元)" min-width="150"></el-table-column>
                    <el-table-column prop="financePayStatus" label="融资状态" min-width="150">
                        <template slot-scope="scope">
                            <span>{{scope.row.financePayStatus == 1 ?'未放款':'已放款'}}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="status" label="银e券状态" width="100" align="left"></el-table-column> -->
                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button size="medium" type="text" @click="preview(scope.row)">预览</el-button>
                            <el-button size="medium" type="text" @click="handleEdit(scope.row)">查看合同</el-button>
                            <el-upload v-if="scope.row.financePayStatus == 1"
                                       class="upload-demo upload-list"
                                       :action="uploadFile()"
                                       :before-upload="beforeUp"
                                       :on-success="(a)=>uploadOk(a)"
                                       :data="fileTypes"
                                       :headers="Authorization"
                            >
                                <!-- <el-button size="medium" type="text" @click="loan(scope.row)">上传放款凭证</el-button> -->
                            </el-upload>
                            <div v-else>
                                <el-button size="medium" type="text" @click="loanDetial(scope.row)">查看放款凭证</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </data-list>
        </el-tabs>
        <el-dialog title :visible.sync="imgDialogVisible" width="80%" center class="img-dialog">
            <!-- <img
                    :src="api.getImgURL(file.fileUrl)"
                    alt="图片丢失"
                    width="30%"
                    height="30%"
                    :preview-text="file.fileName"
                    :preview="1"
            /> -->
             <div class="img-box" v-if="fileDatas">
        <div
          class="img-box-item"
          v-for="(item, index) in fileDatas"
          :key="item.fileUrl + index"
        >
          <img
            v-if="
              item.fileUrl
                .substring(item.fileUrl.lastIndexOf('.'))
                .toLowerCase() == '.pdf'
            "
            :src="pdfImg"
            alt="图片丢失"
            width="30%"
            height="30%"
            @click="previewPdf(item.fileUrl)"
          />
          <img 
              v-else-if="excelfileExtend.indexOf(item.fileUrl.substring(item.fileUrl.lastIndexOf('.')).toLowerCase()) == -1 && item.fileUrl.substring(item.fileUrl.lastIndexOf('.')).toLowerCase()!=='.pdf'"
              :src="zip"
                    alt="图片丢失"
                    width="30%"
                    height="30%"
                   @click="previewPdf(item.fileUrl)"
            />
          <img
            v-else
            :src="api.getImgURL(item.fileUrl)"
            alt="图片丢失"
            width="30%"
            height="30%"
            :preview-text="item.fileName"
            :preview="1"
          />
        </div>
      </div>
            <span slot="footer" class="dialog-footer">
        <el-button @click="imgDialogVisible = false">关 闭</el-button>
      </span>
        </el-dialog>
        <el-dialog title :visible.sync="centerDialogVisible" width="80%" center>
            <!-- <span>需要注意的是内容是默认不居中的</span> -->
            <pdf class="pdf" ref="pdf" :src="src"></pdf>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import api from "@/api/api";
    import upload from "@/api/upload.js";
    import moment from 'moment'
    import pdf from "vue-pdf";
import pdfImg from '@/assets/file.png';
import { baseURL } from "@/api/sys";
import zip from '@/assets/zip.png';
    export default {
        components: {
            pdf
        },
        data() {
            return {
                baseURL,
                excelfileExtend: ".jpg,.png,.jpeg,.bmg,.gif",
                 pdfImg,
                zip,
                fileDatas:[],
                isShowAll: false,
                isHedden: false,
                filterList: [
                    {
                        label: "融资项目编号:",
                        type: "input",
                        field: "payableBillFinanceNo",
                        size: "small"
                    },
                    // {
                    //     label: "银e券编号:",
                    //     type: "input",
                    //     field: "payableBillNo",
                    //     size: "small"
                    // },
                    {
                        label: "融资企业:",
                        type: "input",
                        field: "financeCorpName",
                        size: "small"
                    },
                    {
                        label: "融资金额: ",
                        type: "inputrange",
                        field: "financeAmountMin",
                        size: "small",
                        isSeparated: 1
                    },
                    {
                        type: "inputrange",
                        field: "financeAmountMax",
                        size: "small"
                    },
                    // {
                    //     label: "银e券利率: ",
                    //     type: "inputrange",
                    //     field: "financeInterestMin",
                    //     size: "small",
                    //     isSeparated: 1
                    // },
                    // {
                    //     type: "inputrange",
                    //     field: "financeInterestMax",
                    //     size: "small"
                    // },
                    // {
                    //     label: "承诺付款日:",
                    //     type: "daterange",
                    //     field: ["promiseStartTime", "promiseEndTime"],
                    //     size: "small"
                    // }
                ],
                api: api,
                activeName: "1",
                total: 0,
                listLoading: false,
                listData: [],
                pageNo: 1,
                pageSize: 10,
                fileTypes: {
                    payableBillFinanceId: ""
                },
                Authorization: {Authorization: sessionStorage.token},
                fileUrl: "",
                payableBillFinanceFileList: [],
                file: {},
                imgDialogVisible: false,
                filters: {},
                src: "",
                centerDialogVisible: false

            };
        },
        created() {
            this.activeName = this.$route.params.state;
        },
        methods: {
             previewPdf(item) {
             window.open(this.baseURL + '/web/file/downFile?filePath=' + item);
              },
            moment: moment,
            filterShowAll() {
                this.isShowAll = !this.isShowAll;
                console.log("filterShowAll  -----", this.isShowAll);
            },
            headerStyle({row, rowIndex}) {
                return {background: "#FAFAFA"};
            },
            handleRefresh(filters) {
                console.log("filters<", filters);
                filters.review = 1,
                    filters.source = 2,
                    this.filters = filters;
                this.getLists(filters);
            },
            //查询
            getLists(param) {
                console.log("param ====>", param);
                param.type=2
                api.GetSignApproveList(param).then(res => {
                    console.log("res ===>", res);
                    if (res.success) {
                        this.listData = res.datas.list;
                        this.total = res.datas.total;
                        console.log("list ===>", this.listData);
                    }
                    this.listLoading = false;
                });
            },
            handleEdit(row) {
                this.$router.push({
                    name: "资方融资签约详情",
                    query: {
                        payableBillFinanceId: row.payableBillFinanceId,
                        payableBillFinanceNo: row.payableBillFinanceNo,
                        fundCorpId: row.fundCorpId,
                        isshowbtn: 2,
                        financePayStatus: row.financePayStatus,
                                financeSignStatus: row.financeSignStatus,
          approveStatus: row.approveStatus,
          financeSignStatus: row.financeSignStatus,
                    }
                });
            },

            uploadFile() {
                return upload.uploadProof();
            },
            uploadOk(response) {
                console.log("res ==>", response);
                if (response.code == "100000") {
                    this.$message.success('上传成功');
                    this.signDialogVisible = false;
                    this.handleRefresh(this.filters);
                } else {
                    this.$message.error("上传失败");
                }
            },
            // loan(row) {
            //     this.fileTypes.payableBillFinanceId = row.payableBillFinanceId;
            // },
            //上传前配置
            beforeUp(file) {
                let excelfileExtend = ".jpg,.png,.jpeg,.bmg"; //设置文件格式
                let fileExtend = file.name
                    .substring(file.name.lastIndexOf("."))
                    .toLowerCase();
                if (excelfileExtend.indexOf(fileExtend) <= -1) {
                    this.$message.error("文件格式错误");
                    return false;
                }
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handlePreview(file) {
                console.log(file);
            },
            loanDetial(row) {
                api
                    .GetPayProof({payableBillFinanceId: row.payableBillFinanceId})
                    .then(res => {
                        if (res.success) {
                            this.payableBillFinanceFileList =
                                res.datas.payableBillFinanceFileList;
                                this.fileDatas = this.payableBillFinanceFileList;
                            if (this.payableBillFinanceFileList.length > 0) {
                                this.file = this.payableBillFinanceFileList[0];
                                this.imgDialogVisible = true;
                                this.$previewRefresh()
                            }
                        } else {
                            this.$message.error(res.message);
                        }
                    });
            },
            // 预览银e券
            preview(row) {
                window.open(api.getImgURL(row.evidencePath))
                // this.listLoading = true;
                // api.DownloadContract({ contractNo: row.contractNo }).then(res => {
                //     let binaryData = [];
                //     binaryData.push(res);
                //     this.src = window.URL.createObjectURL(
                //     new Blob(binaryData, { type: "application/zip" })
                //     );
                //     this.centerDialogVisible = true;
                //     this.listLoading = false;
                // });
            }
        }
    };
</script>

<style lang="scss">
    @import "../../../styles/moreDetail/moudleDetail.scss";

    .path {
        margin-left: 20px;
    }

    .header-content {
        background-color: white;
        border: 1px solid #999;
        width: 100%;
        height: 175px;

        .search-content {
            width: 100%;
            height: 36px;
            background-color: #2ea1ff;

            .text {
                line-height: 36px;
                height: 36px;
                text-align: center;
                color: #ffffff;
            }
        }

        .header-content-body {
            background-color: white;
            width: 100%;
            height: 139px;

            .itemTop {
                margin-top: 10px;
            }

            .fl {
                float: left;
            }

            .fr {
                float: right;
            }

            .header-content-body-item {
                margin-left: 45px;
                width: 40%;
                background-color: red;
                height: 30px;

                .text {
                    line-height: 30px;
                    height: 30px;
                    text-align: center;
                }

                .text-input {
                    margin-left: 10px;
                    width: 300px;
                }
            }
        }
    }
    .img-box {
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  > .img-box-item {
    margin-right: 20px;
    height: 200px;
    width: 200px;
    margin-bottom: 20px;
    > img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}
</style>
