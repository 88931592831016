<template>
  <div>
    <layout-main-view
      :breadcrumb="breadcrumblist"
      :tabOptions="tabOptions"
      @tabclick="tabclick"
    >
          <guarantopeneasypay v-if="showtab == 1"></guarantopeneasypay>
          <guarantnormalend v-if="showtab == 2"></guarantnormalend>
          <guarantvoid v-if="showtab == 3"></guarantvoid>





    </layout-main-view>
  </div>
</template>
<script>

import guarantopeneasypay from "./guarantopenEasypay";
import guarantnormalend from "./guarantnormalend";
import guarantvoid from "./guarantvoid";



export default {
  components: {
    "guarantopeneasypay": guarantopeneasypay,
    'guarantnormalend':guarantnormalend,
    'guarantvoid':guarantvoid

  },
  data() {
    return {
      breadcrumblist: ["担保银e券管理"],
      showtab: 1,
      tabOptions: [
        { label: "已开单", value: "1", path: "/dbeasypay/mange/1" },
        // { label: "正常结束", value: "2", path: "/dbeasypay/mange/2" },
        { label: "已作废", value: "3", path: "/dbeasypay/mange/3" },
      ],
      activeName: "first",
      total: 0,
      listLoading: false,
      listData: [],
      handleEditShow: false
    };
  },
  created() {
  },
  mounted(){
      this.showtab = this.$route.params.state;
      console.log('this.showtab ===>',this.showtab);
  },

  methods: {
    tabclick(val) {
      this.showtab = val;
      this.handleEditShow = false;
    },

    // tabClick(val){
    //   console.log("val ===>",val);
    // }
  }
};
</script>

<style lang="scss">
@import "../../styles/moreDetail/moudleDetail.scss";
.path {
  margin-left: 20px;
}
.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;
  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;
    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }
  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;
    .itemTop {
      margin-top: 10px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;
      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }
      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
</style>
