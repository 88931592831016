<template>

        <div class="common-page-dataList">
            <el-tabs type="border-card" v-model="activeName" class="n-tabs">
                <data-list
                        :filters="filterList"
                        :isShowAll="isShowAll"
                        :isHedden="isHedden"
                        @data-showAll="filterShowAll"
                        @data-refresh="handleRefresh"
                        :total="total"
                        ref="datalist"
                >
                    <el-table
                            :data="listData"
                            :header-cell-style="headerStyle"
                            highlight-current-row
                            v-loading="listLoading"
                            style="width: 100%;"
                    >
                        <el-table-column prop="payableBillNo" label="银e券编号" min-width="200"></el-table-column>
                        <el-table-column prop="openCorpName" label="开单企业" min-width="150"></el-table-column>
                        <el-table-column label="社会统一信用代码" min-width="200" prop='openCorpCreditCode'>
                        </el-table-column>
                        <el-table-column label="申请开立金额(元)" min-width="170">
                            <template slot-scope="scope">{{ $changeMoneyThousand( scope.row.payableAmount) }}</template>
                        </el-table-column>
                        <el-table-column label="申请时间" width="170" align="left">
                            <template slot-scope="scope">{{scope.row.createTime ? moment(scope.row.createTime).format('YYYY-MM-DD HH:mm'): ''}}</template>
                        </el-table-column>
                        <el-table-column prop="approveStatusName" label="申请状态" width="150" align="left"></el-table-column>
                        <el-table-column prop="cancelStatus" label="作废状态" width="130" align="left">
                            <template slot-scope="scope">
                                <span v-if="scope.row.cancelStatus == 1">未作废</span>
                                <span v-if="scope.row.cancelStatus == 2">已作废</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button size="medium" type="text"  @click="handleEdit2(scope.row)">详情</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </data-list>
            </el-tabs>

        </div>

</template>
<script>
    import api from "../../api/api.js";
    import pdf from "vue-pdf";
    import {changeMoneyThousand} from "@/util/common";
import moment from 'moment'


    export default {
        components: {
            pdf
        },
        data() {
            return {

                isShowAll: false,
                isHedden: false,
                filterList: [
                    {
                        label: "银e券编号",
                        type: "input",
                        field: "payableBillNo",
                        size: "small"
                    },
                    {
                        label: "开单企业",
                        type: "input",
                        field: "openCorpName",
                        size: "small"
                    },
                    {
                        label: "申请日期:",
                        type: "daterange",
                        field: ["createTimeStr", "createTimeEnd"],
                        size: "small"
                    },
                ],
                activeName: "1",
                total: 0,
                listLoading: false,
                listData: [],
                pageNo: 1,
                pageSize: 10,
                changeMoneyThousand: changeMoneyThousand,
                centerDialogVisible: false,
                api: api,
                row: {},
                currentPage: 0, // pdf文件页码
                pageCount: 0, // pdf文件总页数
                fileType: "pdf", // 文件类型
                src: "" // pdf文件地址
            };
        },
        created() {
        },
        methods: {
            moment: moment,
            filterShowAll() {
                this.isShowAll = !this.isShowAll;
                console.log("filterShowAll  -----", this.isShowAll);
            },
            headerStyle({row, rowIndex}) {
                return {background: "#FAFAFA"};
            },
            handleRefresh(filters) {
                console.log("filters<", filters);
                this.getLists(filters);
            },
            //查询
            getLists(param) {

                api.getApplyReturnList(param).then(res => {
                    console.log("res ===>", res);
                    if (res.success) {
                        this.listData = res.datas.list;
                        this.total = res.datas.total;

                    }

                });
            },
            handleEdit(row) {
                this.$router.push({name: '开立请求详情', query: {id: row.payableBillId, isshowbtn: 2}})
            },
             handleEdit2(row) {
                this.$router.push({name: '开立请求编辑', query: {id: row.payableBillId,isshowbtn:row.cancelStatus}})
            },

        }
    };
</script>

<style lang="scss">
    @import "../../styles/moreDetail/moudleDetail.scss";

    .path {
        margin-left: 20px;
    }

    .header-content {
        background-color: white;
        border: 1px solid #999;
        width: 100%;
        height: 175px;

        .search-content {
            width: 100%;
            height: 36px;
            background-color: #2ea1ff;

            .text {
                line-height: 36px;
                height: 36px;
                text-align: center;
                color: #ffffff;
            }
        }

        .header-content-body {
            background-color: white;
            width: 100%;
            height: 139px;

            .itemTop {
                margin-top: 10px;
            }

            .fl {
                float: left;
            }

            .fr {
                float: right;
            }

            .header-content-body-item {
                margin-left: 45px;
                width: 40%;
                background-color: red;
                height: 30px;

                .text {
                    line-height: 30px;
                    height: 30px;
                    text-align: center;
                }

                .text-input {
                    margin-left: 10px;
                    width: 300px;
                }
            }
        }
    }
</style>
