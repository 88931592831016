<!--资料配置-->
<template>
    <div class="data-config">
        <layout-main-view :breadcrumb="breadcrumblist">
            <el-button type="primary" class="btn-doFilter" @click="addNew">新 增</el-button>
            <el-table
                    :data="listData"
                    :header-cell-style="headerStyle"
                    highlight-current-row
                    v-loading="listLoading"
                    style="width: 100%;"
                >
                <el-table-column prop="product" label="产品" min-width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.product == 1">银e券</span>
                        <!-- <span v-if="scope.row.product == 2">保理</span> -->
                    </template>
                </el-table-column>
                <el-table-column prop="informationName" label="资料名称" min-width="150"></el-table-column>
                <el-table-column prop="annexName" label="模板附件" width="300">
                    <template slot-scope="scope">
                        {{scope.row.provideTemplate === 1 ? scope.row.annexName : ''}}
                    </template>
                </el-table-column>
                <el-table-column prop="claimRemark" label="要求备注" width="450"></el-table-column>
                <el-table-column label="操作" width="220" >
                    <template slot-scope="scope">
                        <el-button size="medium" type="text" @click="edit(scope.row)">编辑</el-button>
                        <el-button size="medium" type="text" @click="del(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-dialog  :title="isAdd ? '新增' : '编辑'"  :visible.sync="dialogVisible"  width="600px"  class="dislog">
                <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="150px" class="demo-ruleForm" :rules="rules">
                    <el-form-item label="产品" prop="product">
                        <el-select v-model="ruleForm.product" placeholder="请选择产品类型">
                            <el-option label="银e券" :value="1"></el-option>
                            <!-- <el-option label="保理" :value="2"></el-option> -->
                        </el-select>
                    </el-form-item>
                    <el-form-item label="资料名称" prop="informationName">
                        <el-input  v-model="ruleForm.informationName" placeholder="请输入资料名称"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="资料类型" prop="informationType">
                        <el-select v-model="ruleForm.informationType" placeholder="请选择资料类型">
                            <el-option label="文档" :value="1"></el-option>
                            <el-option label="图片" :value="2"></el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="是否提供模板" prop="provideTemplate">
                        <el-select v-model="ruleForm.provideTemplate" placeholder="请选择是否提供模板" @change="deleteOhter">
                            <el-option label="是" :value="1"></el-option>
                            <el-option label="否" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="上传模板" prop="annexUrl" v-if="ruleForm.provideTemplate == 1">
                        <!-- <el-upload
                            class="upload-demo"
                            :action="upload()"
                            :limit="1"
                            :show-file-list="false"
                            :before-upload="beforeUpload"
                            :on-success="handleSuccess"
                            ref="upload"
                            :file-list="uploadList"
                            :headers="Authorization"

                            >
                            <el-button size="small" type="primary">上传</el-button>
                            <div class="file-name">{{fileName}}</div>
                        </el-upload> -->
                        <el-upload
                            class="upload-demo upload-contentr"
                            :action="upload()"
                            :limit="1"
                            :on-exceed="handleExceed"
                            :before-upload="beforeUpload"
                            :on-success="handleSuccess"
                            :on-remove="handleRemove"
                            :on-preview="handlePreview"
                            ref="upload"
                            :file-list="uploadList"
                            :headers="Authorization"
                            list-type="picture"
                            drag>
                            <div class="el-upload__text">上传模板</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="要求备注" prop="claimRemark">
                        <el-input  v-model="ruleForm.claimRemark" placeholder="请输入备注"></el-input>
                    </el-form-item>
                    <el-form-item >
                        <el-button @click="dialogVisible = false"  >取 消</el-button>
                        <el-button type="primary" @click="submit" :loading="isLoaing">确 定</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </layout-main-view>
    </div>
</template>
<script>
    import api from "../../api/api.js";
    import upload from "../../api/upload";
    export default {
        data() {
            return {
                Authorization: {Authorization: sessionStorage.token},
                breadcrumblist: ["融资资料配置"],
                listData: [],
                listLoading: false,
                ruleForm: {
                    product: '',
                    informationName: '',
                    informationType: '',
                    provideTemplate: '',
                    annexUrl: '',
                    claimRemark: ''
                },
                rules: {
                    product: [{ required: true, message: '请选择产品类型', trigger: 'change' }],
                    informationName: [{ required: true, message: '请输入资料名称', trigger: 'blur' }],
                    // informationType: [{ required: true, message: '请选择资料类型', trigger: 'change' }],
                    provideTemplate: [{ required: true, message: '请选择提供模板', trigger: 'change' }],
                    //annexUrl: [{ required: true, message: '请上传模板', trigger: 'blur' }],
                    // claimRemark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
                },
                dialogVisible: false,
                isAdd: true,
                isLoaing: false,
                uploadList: [],
                fileName: '',
                excelfileExtend: ".jpg,.png,.jpeg,.bmg,.gif"
            }
        },
        created() {
            this.init();
        },
        mounted() {
            console.log(this.$refs);
        },
        methods: {
            deleteOhter() {
                console.log(222)
                if(this.ruleForm.provideTemplate === 1){
                    this.ruleForm.annexName = ''
                    this.ruleForm.annexUrl = ''
                }
            },
            init() {
                api.GetFinanceInformationList().then(res => {
                    this.listData = res.datas.payableBillFinanceInformationDtoList
                })
            },
            headerStyle({ row, rowIndex }) {
                return { background: "#FAFAFA" };
            },
            addNew () {
                this.dialogVisible = true
                this.isAdd = true
                this.uploadList = []
                this.$nextTick(() => {
                    this.$refs.ruleForm.resetFields();
                    // this.fileName = '';
                })
            },
            handleExceed() {
                this.$message.warning('当前限制选择1个文件')
            },
            upload() {
                return upload.eqptImgSrc();
            },
            beforeUpload(file) {
                const fileType = file.name.slice(file.name.lastIndexOf(".")+1).toLowerCase();
                const imgList = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
                const docList = ['doc','docx','pdf','xls','xlsx'];
                const isImg = imgList.some(function(item) {
                    return item == fileType
                })
                const isDoc = docList.some(function(item) {
                    return item == fileType
                })
                const isLt5M = file.size / 1024 / 1024 < 5;
                if (!isImg&&!isDoc) {
                    this.$message.error('上传类型只能是文档或图片');
                    return false;
                }else if(!isLt5M) {
                    this.$message.error('上传图片大小不能超过 5MB!');
                    return false;
                }
                if(isImg) {
                    return isImg && isLt5M;
                }else {
                    return isDoc;
                }
            },
            handlePreview(file) {
                if(file.response) {
                    window.open(api.getImgURL(file.response.datas.uploadDate.message))
                } else {
                    // 回显的数据
                    window.open(file.trueUrl)
                }
            },
            handleRemove(file, fileList) {
                this.ruleForm.annexUrl = ''
                this.ruleForm.annexName = ''
                this.uploadList = []
            },
            handleSuccess(res,file, fileList) {
                if (res.code == 100000) {
                    this.ruleForm.annexUrl = res.datas.uploadDate.message;
                    this.ruleForm.annexName = file.name
                    this.$message.success('上传成功');
                    this.uploadList = fileList
                    this.$refs.ruleForm.validateField('annexUrl')

                    let name = file.name
                    let filetype = name.substring(name.lastIndexOf(".")).toLowerCase()
                    if(this.excelfileExtend.indexOf(filetype) == -1) {
                        file.url = require("@/assets/zip.png");
                    }
                } else {
                    this.$message.message(res.message)
                }
            },
            submit() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        if(this.isAdd) {
                            const {payableBillFinanceInformationId,...newForm} = this.ruleForm
                            this.ruleForm = newForm
                        }
                        console.log(this.ruleForm,'ruleForm')
                        api.AddFinanceInformation(this.ruleForm).then(res => {
                            if(res.code == 100000) {
                                this.$alert(res.message)
                                this.dialogVisible = false
                                this.$refs.ruleForm.resetFields();
                                this.init();
                                this.fileName = '';
                            }else{
                                this.$message.error(res.message)
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            edit(row) {
                this.dialogVisible = true
                this.isAdd = false
                this.ruleForm = {}
                this.uploadList = []
                this.$nextTick(() => {
                    this.$refs.ruleForm.resetFields()
                    this.ruleForm = {...this.ruleForm, ...row}
                    if(this.ruleForm.provideTemplate == 1) {
                        let name = row.annexName
                        let filetype = name.substring(name.lastIndexOf(".")).toLowerCase()
                        this.uploadList = [
                            {
                                name: row.annexName,
                                url: this.excelfileExtend.indexOf(filetype) == -1 ? require("@/assets/zip.png") : api.getImgURL(row.annexUrl), // 缩略图
                                trueUrl: api.getImgURL(row.annexUrl)   // 真实的文件地址
                            }
                        ]
                    }
                    console.log(this.ruleForm,'ruleForm');
                })
            },
            del(row) {
                this.$confirm('是否删除该产品?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    api.DeleteFinanceInformation({payableBillFinanceInformationId: row.payableBillFinanceInformationId}).then(res => {
                        if (res.success) {
                            this.$alert(res.message);
                            this.init();
                        }
                    })
                }).catch(() => {
                });
            },
            handleChange(val) {
                if(val == 2) {
                    this.uploadList = []
                    this.ruleForm.annexName = ''
                    this.ruleForm.annexUrl = ''
                }
            }
        }
    }
</script>
<style lang="scss">
    .data-config {
        .el-button--primary {
            color: #fff;
            background-color: #2937A6;
            border-color: #2937A6;
        }
        .btn-doFilter {
            line-height: 32px;
            height: 32px;
            padding-top: 0;
            padding-bottom: 0;
            width: 74px;
            margin-bottom: 20px;
        }
        .el-form-item {
            &:last-child {
                margin-bottom: 0;
            }
        }
        .el-upload  {
            display: flex;
            .file-name {
                margin-left: 20px;
            }
        }
        .upload-demo {
            .el-upload-dragger {
                width: auto;
                height: auto;
                padding: 0 20px;
            }
            .el-upload-list__item {
                width: auto;
            }
        }
        .el-upload-list--picture{
            overflow: auto;
        }
    }
</style>
