<template>
  <div class="mainbd">
      <div class="m-title">
          银e券
      </div>
      <div class="m-czxx">
            <div class="m-czxx-t">

            </div>
            <div class="m-czxx-b">
                <div class="m-czxx-b-c">
                  <div>
                      <i></i><em></em><span>存证信息</span><em></em><i></i>
                  </div>
                  <div id="m-circle">
                    <span></span> <i></i> <span></span>
                  </div>
                    <ul>
                      <li>
                        <span>区块HASH:</span> <span>{{billinfo.txHash}}</span>
                      </li>
                       <li>
                        <span>上链时间:</span> <span>{{billinfo.outBlockTime ? moment(billinfo.outBlockTime).format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
                      </li>
                       <li>
                        <span>存证机构:</span> <span>{{billinfo.mchName}}</span>
                      </li>
                       <li>
                        <span>存证ID:</span> <span>{{billinfo.evId}}</span>
                      </li>
                       <li>
                        <span>存证文件HASH:</span> <span>{{billinfo.evHash}}</span>
                      </li>
                       <li>
                        <span>区块高度:</span> <span>{{billinfo.blockHeight}}</span>
                      </li>
                    </ul>
                </div>
            </div>
      </div>
      <!-- <div class="m-cs">
              <p>你可复制区块HASH参数，在腾讯区块链平台进行验证</p>
              <div class="center"><a href="javascript:;">http://tx.com</a></div>
      </div> -->
      <div class="m-czxx">
            <div class="m-czxx-t">

            </div>
            <div class="m-czxx-b">
                <div class="m-czxx-b-c">
                  <div>
                      <i></i><em></em><span>银e券信息</span><em></em><i></i>
                  </div>
                  <div id="m-circle">
                    <span></span> <i></i> <span></span>
                  </div>
                    <ul>
                      <li>
                        <span>银e券编号:</span> <span>{{ext4}}</span>
                      </li>
                        <li>
                            <span>银e券金额(元):</span> <span>{{$changeMoneyThousand(ext5)}}</span>
                        </li>
                       <li>
                        <span>原始银e券编号:</span> <span>{{yfdBaseInfo.oriPayableBillNo || yfdBaseInfo.payableBillNo}}</span>
                      </li>
                       <li>
                        <span>开单人:</span> <span>{{yfdBaseInfo.openCorpName}}</span>
                      </li>
                       <li>
                        <span>转单人:</span> <span>{{yfdBaseInfo.receivedCorpName}}</span>
                      </li>
                
                       <li>
                        <span>收单人:</span> <span>{{yfdBaseInfo.fundCorpName}}</span>
                      </li>
                       <li>
                        <span>开立日:</span> <span>{{yfdBaseInfo.openTime}}</span>
                      </li>
                       <li>
                        <span>承诺付款日:</span> <span>{{yfdBaseInfo.promisePayTime}}</span>
                      </li>
                    </ul>
                </div>
            </div>
      </div>
  <!-- <el-dialog title='' :visible.sync="showAddDialog" :close-on-click-modal="false" :show-close='false'  class="mobildialog">

              <p>该银e券数据暂未上链</p>

        </el-dialog> -->

  </div>

</template>
<script>
import api from "@/api/api";
import moment from "moment";

export default {
  components: {},
  data() {
    return {
      yfdBaseInfo: {},
      yfdFinanceBaseInfo: {},
      ext4: '',
      ext5: '',
      billinfo: {},
      showAddDialog: true
    };
  },
  created() {
    this.getLists({ itemId: this.$route.query.itemId });
  },
  mounted() {},

  methods: {
    moment: moment,
    //查询
    getLists(param) {
      api.getFinanceBlockChainInfo(param).then(res => {
        if (res.success) {
          this.ext4 = res.datas.blockChain.ext4
          this.ext5 = res.datas.blockChain.ext5
          this.billinfo = res.datas.blockChain;
          let extendInfoObj = JSON.parse(
            res.datas.blockChain.extendInfo
          );
          if (extendInfoObj.yfdEvidenceInfo) {
            this.yfdBaseInfo = extendInfoObj.yfdEvidenceInfo.yfdBaseInfo;
          }
          if(extendInfoObj.yfdFinanceEvidenceInfoList || extendInfoObj.yfdFinanceEvidenceInfoList.length > 0) {
            this.yfdFinanceBaseInfo = extendInfoObj.yfdFinanceEvidenceInfoList[0].yfdFinanceBaseInfo
          }
        } else {
          this.open4();
        }
      });
    },
    open4() {
      const h = this.$createElement;
      this.$msgbox({
        title: "",
        message: h("p", { class: "fonthei" }, [
          h("span", { class: "fontss" }, "该银e券数据暂未上链 ")
        ]),
        showCancelButton: false,
        showConfirmButton: false,
        showClose: false,
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",closeOnClickModal:false,
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }, 3000);
          } else {
            done();
          }
        }
      }).then(action => {
        this.$message({
          type: "info",
          message: "action: " + action
        });
      });
    }
  }
};
</script>

<style lang="scss" >
@function fun($width) {
  @return ($width/$rem) + rem;
}
$rem: 75px;

.mainbd {
  width: 100%;
  box-sizing: border-box;
  background: url("../../assets/mobilebg.png") no-repeat;
  height: 100%;
  padding: 0 fun(18px) fun(65px) fun(18px);
  background-size: cover;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  .m-title {
    font-size: fun(56px);
    color: #fff;
    line-height: fun(72px);
    text-align: center;
    padding: fun(120px) 0 fun(88px) 0;
  }
  .m-czxx {
    .m-czxx-t {
      width: 100%;
      background: url("../../assets/top_shuchu.png") no-repeat;
      background-size: 100%;
      height: 30px;
    }
    .m-czxx-b {
      position: relative;
      top: -15px;
      padding: 0 fun(14px);
      width: 100%;
      height: 100%;
      .m-czxx-b-c {
        background: #fff;
        border-radius: 0 0 fun(16px) fun(16px);
        div:nth-of-type(1) {
          font-size: fun(40px);
          color: #d38b1d;
          text-align: center;
          padding-top: fun(25px);
          margin-bottom: fun(10px);

          span {
            display: inline-block;
            vertical-align: middle;
          }
          em {
            width: fun(10px);
            height: fun(10px);
            background: #d38b1d;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
          }
          em:nth-of-type(1) {
            margin: 0 fun(48px) 0 fun(12px);
          }
          em:nth-of-type(2) {
            margin: 0 fun(12px) 0 fun(48px);
          }
          i {
            vertical-align: middle;
            display: inline-block;
            width: fun(6px);
            height: fun(6px);
            background: #d38b1d;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
    #m-circle {
      height: fun(28px);
      text-align: center;
      content: "";
      display: block;
      clear: both;
      overflow: hidden;
      padding-top: 0;
      span:nth-of-type(1) {
        width: fun(14px);
        height: fun(28px);
        background-color: #1d2d81;
        border-radius: fun(14px) 0 0 fun(14px);
        float: right;
      }
      span:nth-of-type(2) {
        width: fun(14px);
        height: fun(28px);
        background-color: #1d2d81;
        border-radius: 0 fun(14px) fun(14px) 0;
        float: left;
      }
      i {
        width: 82%;
        height: 1px;
        background-image: linear-gradient(
          to right,
          #1a2a80 0%,
          #1a2a80 50%,
          transparent 50%
        );
        background-size: fun(30px) 1px;
        background-repeat: repeat-x;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: fun(6px);
      }
    }
    ul {
      margin-left: calc(9% - 0.02rem);
      padding-left: 0;
      padding-bottom: fun(48px);
      li {
        content: "";
        display: block;
        clear: both;
        overflow: hidden;
        margin-bottom: fun(24px);
      }
      li:last-child {
        margin-bottom: 0;
      }
      span {
        font-size: fun(30px);
        line-height: fun(45px);
        float: left;
        word-wrap: break-word;
      }
      span:nth-of-type(1) {
        width: fun(210px);
        color: #808080;
        margin-right: fun(10px);
      }
      span:nth-of-type(2) {
        width: fun(320px);
        color: #101010;
        margin-right: fun(20px);
      }
    }
  }
  .m-cs {
    width: 100%;
    padding: 0 fun(72px);
    margin-bottom: fun(64px);
    p {
      font-size: fun(24px);
      color: #99b2ff;
      text-align: center;
      line-height: fun(32px);
      margin: 0 0 fun(6px) 0;
    }
    div {
      text-align: center;
    }
    a {
      font-size: fun(24px);
      color: #33bbff;
      text-align: center;
      line-height: fun(36px);
    }
  }
}
.fontss {
  font-size: fun(30px);
  color: #000;
  text-align: center;
}
</style>
