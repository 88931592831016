<template>
    <div class="common-page-dataList">
        
        <el-tabs type="border-card" v-model="activeName" class="n-tabs">
            <data-list
                    :filters="filterList"
                    :isShowAll="isShowAll"
                    :isHedden="isHedden"
                    @data-showAll="filterShowAll"
                    @data-refresh="handleRefresh"
                    :total="total"
                    ref="datalist"
            >
                <el-table
                        :data="listData"
                        :header-cell-style="headerStyle"
                        highlight-current-row
                        v-loading="listLoading"
                        style="width: 100%;"
                >
                    <el-table-column prop="payableBillNo" label="银e券编号" min-width="200"></el-table-column>
                    <!--<el-table-column prop="contractAmount" label="合同金额(万元)" min-width="150"></el-table-column>-->
                    <el-table-column label="银e券金额(元)" min-width="150">
                        <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.payableAmount) }}</template>
                    </el-table-column>
                    <el-table-column prop="returnStateName" label="退回节点" min-width="130"></el-table-column>
                    <el-table-column prop="returnTime" label="退回时间" min-width="150">
                        <template slot-scope="scope">{{ scope.row.returnTime | formatHour }}</template>
                    </el-table-column>
                    <el-table-column prop="reason" label="退回理由" min-width="150"></el-table-column>
                    <el-table-column prop="cancelStatus" label="作废状态" min-width="100">
                        <template slot-scope="scope">
                        <span v-if="scope.row.cancelStatus == 1">未作废</span>
                        <span v-if="scope.row.cancelStatus == 2">已作废</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150" align="left">
                        <template slot-scope="scope">
                            <!-- <el-button size="medium" type="text" @click="preview(scope.row)">预览</el-button> -->
                            <el-button size="medium" type="text" @click="handleEdit(scope.row)">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </data-list>
        </el-tabs>
        <el-dialog title="" :visible.sync="centerDialogVisible" width="80%" center>
            <!-- <span>需要注意的是内容是默认不居中的</span> -->
            <pdf class="pdf" ref="pdf" :src="src"></pdf>
            <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>
<script>
    import api from "../../api/api.js";
    import pdf from "vue-pdf";
    import {changeMoneyThousand} from "@/util/common";
    import moment from 'moment'


    export default {
        components: {
            pdf
        },
        data() {
            return {
                isShowAll: false,
                isHedden: false,
                filterList: [
                    // {
                    //   label: "退回企业",
                    //   type: "input",
                    //   field: "corpName",
                    //   size: "small"
                    // },
                    {
                        label: "银e券编号",
                        type: "input",
                        field: "payableBillNo",
                        size: "small"
                    },
                    {
                        label: "退回节点:",
                        type: "select",
                        field: "returnState",
                        options: [
                            {label: "内审审核", value: "1"},
                            // {label: "担保审核", value: "5"},
                            {label: "接收审核", value: "2"},
                            {label: "平台审核", value: "3"},
                            {label: "资方审核", value: "4"},
                            {label: "全部", value: "0"}
                        ],
                        size: "small"
                    },
                    {
                        label: "作废状态:",
                        type: "select",
                        field: "cancelStatus",
                        options: [
                            {label: "全部", value: ""},
                            {label: "未作废", value: "1"},
                            {label: "已作废", value: "2"}
                        ],
                        size: "small"
                    },
                    {
                        label: "银e券金额（元）：",
                        type: "inputrange",
                        field: "payableAmountStr",
                        size: "small",
                        isSeparated: 1
                    },
                    {
                        type: "inputrange",
                        field: "payableAmountEnd",
                        size: "small"
                    }
                ],
                activeName: "1",
                total: 0,
                listLoading: false,
                listData: [],
                centerDialogVisible: false,
                api: api,
                row: {},
                changeMoneyThousand: changeMoneyThousand,
                currentPage: 0, // pdf文件页码
                pageCount: 0, // pdf文件总页数
                fileType: "pdf", // 文件类型
                src: "" // pdf文件地址
            };
        },
        created() {
        },
        methods: {
            filterShowAll() {
                this.isShowAll = !this.isShowAll;
                console.log("filterShowAll  -----", this.isShowAll);
            },
            headerStyle({row, rowIndex}) {
                return {background: "#FAFAFA"};
            },
            handleRefresh(filters) {
                console.log("filters<", filters);
                this.getLists(filters);
            },
            moment: moment,
            //查询
            getLists(param) {
                console.log("param ====>", param);
                param.corpId = "1";
                api.ReturnList(param).then(res => {
                    if (res.success) {
                        this.listData = res.datas.list;
                        this.total = res.datas.total;
                        console.log("list ===>", this.listData);
                    }
                    this.listLoading = false;
                });
            },
            handleEdit(row) {
               
                this.$router.push({
                    path: "/easypay/easypaygoback",
                    query: {id: row.payableBillId, type: row.payableBillOpenType,isshowbtn:row.cancelStatus}
                });
            },
            preview(row) {
                this.row = row;
                window.open(api.getImgURL(row.evidencePath))
                // this.listLoading = true;
                // api.DownloadContract({contractNo: row.contractNo}).then(res => {
                //     var binaryData = [];
                //     binaryData.push(res);
                //     this.src = window.URL.createObjectURL(
                //         new Blob(binaryData, {type: "application/zip"})
                //     );
                //     console.log("this.src ===>", this.src);
                    // this.scr = pdf.createLoadingTask(this.src);
                    // window.open('/static/pdf/web/viewer.html?file=' +encodeURIComponent(url) + '&.pdf');

                    // console.log('res ===>',res);
                    // this.src = res;
                    this.centerDialogVisible = true;
                    // this.listLoading = false;
                // });
            }
        }
    };
</script>

<style lang="scss">
    @import "../../styles/moreDetail/moudleDetail.scss";

    .path {
        margin-left: 20px;
    }

    .header-content {
        background-color: white;
        border: 1px solid #999;
        width: 100%;
        height: 175px;

        .search-content {
            width: 100%;
            height: 36px;
            background-color: #2ea1ff;

            .text {
                line-height: 36px;
                height: 36px;
                text-align: center;
                color: #ffffff;
            }
        }

        .header-content-body {
            background-color: white;
            width: 100%;
            height: 139px;

            .itemTop {
                margin-top: 10px;
            }

            .fl {
                float: left;
            }

            .fr {
                float: right;
            }

            .header-content-body-item {
                margin-left: 45px;
                width: 40%;
                background-color: red;
                height: 30px;

                .text {
                    line-height: 30px;
                    height: 30px;
                    text-align: center;
                }

                .text-input {
                    margin-left: 10px;
                    width: 300px;
                }
            }
        }
    }
</style>
