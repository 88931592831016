<template>
  <div class="page-login">
    <div class="ly-retrieve">
      <div class="main-content">
        <!--<div class="logo">-->
        <!--<img src="../../assets/login/logo_login.png" alt="">-->
        <!--<div class="logo-title">供应链金融系统</div>-->
        <!--</div>-->
        <el-form
          v-if="steps"
          ref="retrieveForm"
          :model="retrieveForm"
          :rules="rules"
          label-position="left"
          label-width="100px"
          class="demo-ruleForm login-container"
        >
          <h3 style="margin-bottom: 25px; margin-top: 0">修改密码</h3>
          <input type="password" class="hide" id="loginPassword" />
          <input type="text" class="hide" id="loginUserName" />
          <el-form-item label="手机号" class="item-input" prop="userName">
            <el-input
              v-model="retrieveForm.userName"
              placeholder="请输入手机号"
              @change="getcompname"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item class="item-input"  prop="ipone">
        <el-input v-model="retrieveForm.ipone" placeholder="请输入已绑定的手机号码"></el-input>
          </el-form-item>-->
          <el-form-item label="企业" prop="relationCorpId">
            <el-select
              v-model="retrieveForm.relationCorpId"
              placeholder="请选择企业"
              style="width: 360px"
            >
              <el-option
                v-for="item in options"
                :key="item.corpId"
                :label="item.corpName ? item.corpName : item.registerCorpName"
                :value="item.corpId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="图形验证码"
            class="item-input btn-s"
            prop="verifyCode"
          >
            <el-row>
              <el-col :span="14">
                <el-input
                  v-model="retrieveForm.verifyCode"
                  placeholder="输入图形验证码"
                ></el-input>
              </el-col>
              <el-col :span="10" class="captcha">
                <img :src="captchaPath" @click="getCaptcha()" alt />
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            label="手机验证码"
            class="item-input btn-s"
            prop="smsVerifyCode"
          >
            <el-input
              v-model="retrieveForm.smsVerifyCode"
              placeholder="输入手机验证码"
            >
              <el-button
                v-if="sendCode"
                @click.native="getMessage"
                slot="append"
                style="width: 20px"
                >发送验证码</el-button
              >
              <el-button
                v-if="!sendCode"
                :disabled="sendTime != 60"
                slot="append"
                style="width: 20px"
                >{{ sendTime }}s后重新发送</el-button
              >
            </el-input>
          </el-form-item>
          <el-form-item
            class="item-input"
            label="输入新密码"
            prop="newPassword"
          >
            <el-input
              type="password"
              v-model="retrieveForm.newPassword"
              placeholder="8位以上且必须为英文大小写字母加数字加特殊字符组合"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item
            class="item-input"
            label="再次输入密码"
            prop="password1"
          >
            <el-input
              type="password"
              v-model="retrieveForm.password1"
              placeholder="再次输入密码"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item style="width: 100%" class="form-btn">
            <el-button @click.native="cancel">取消</el-button>
            <el-button type="primary" @click.native="submit"
              >确认修改</el-button
            >
          </el-form-item>
        </el-form>
        <el-form
          v-if="!steps"
          :model="retrieveForm2"
          :rules="rules1"
          ref="retrieveForm2"
          label-position="left"
          label-width="100px"
          class="demo-ruleForm login-container"
        >
          <h3 style="margin-bottom: 25px; margin-top: 0">设置新密码</h3>
          <el-form-item class="item-input" label="输入新密码" prop="password">
            <el-input
              v-model="retrieveForm2.password"
              placeholder="请输入8位以上且必须为英文大小写字母加数字加特殊字符组合的密码"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="item-input"
            label="再次输入密码"
            prop="password1"
          >
            <el-input
              v-model="retrieveForm2.password1"
              placeholder="再次输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item style="width: 100%">
            <el-button
              type="primary"
              style="width: 238px; margin-left: 10px"
              @click.native="submit"
              >确定</el-button
            >
          </el-form-item>
        </el-form>
        <div class="layout-footer">
          <!-- <p class="f-info">Copyright 2022 广西银壹数字科技有限公司 版权所有</p>
		<p class="f-info">联系我们：gxyinyi@sina.com</p> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../../api/api';
import { getUUID } from '../../util/index.js';
export default {
  data() {
    var checkPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.retrieveForm.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      sendCode: true,
      sendTime: 0,
      steps: true,
      retrieveForm: {
        newPassword: '',
        userName: '',
        verifyCode: '',
        smsVerifyCode: '',
        uuid: '',
        password1: '',
        relationCorpId: '',
      },
      captchaPath: '',
      retrieveForm2: {
        password: '',
        password1: '',
      },
      rules: {
        userName: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
        verifyCode: [
          { required: true, message: '图形验证码不能为空', trigger: 'blur' },
        ],
        newPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },

          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*]).{9,}$/,
            message:
              '请输入8位以上且必须为英文大小写字母加数字加特殊符号组合的密码',
            trigger: 'blur',
          },
        ],
        password1: [{ validator: checkPassword, trigger: 'blur' }],
        relationCorpId: [
          { required: true, message: '请选择企业', trigger: 'change' },
        ],
        smsVerifyCode: [
          { required: true, message: '手机验证码不能为空', trigger: 'blur' },
        ],
      },
      options: [],
    };
  },
  created() {
    this.getCaptcha();
  },
  methods: {
    getMessage() {
      var regu = /^1\d{10}$/;
      var re = new RegExp(regu);
      if (re.test(this.retrieveForm.userName)) {
        this.sendCode = false;

        if (this.sendCode == false) {
          this.getCode();
        }
      } else {
        this.$message.error('手机号格式错误');
        return;
      }
    },
    getCode() {
      let param = {
        uuid: this.retrieveForm.uuid,
        phone: this.retrieveForm.userName,
        verifyCode: this.retrieveForm.verifyCode,
        channelCode: 'YRY', // 渠道
        smsType: 2,
      };
      api.GetPhoneVerification(param).then((res) => {
        if (res.success) {
          this.$message.success('发送成功');
          this.sendTime = 60;
          var auth_timetimer;
          clearInterval(auth_timetimer);

          auth_timetimer = setInterval(() => {
            this.sendTime--;
            if (this.sendTime <= 0) {
              this.sendCode = true;
              clearInterval(auth_timetimer);
            }
          }, 1000);
        } else {
          this.$message.error(res.message);
          this.sendCode = true;
        }
      });
    },
    // nextStep () {
    //   this.steps = false
    // },
    // 获取验证码
    getCaptcha() {
      this.retrieveForm.uuid = getUUID();
      this.captchaPath = api.verifycode(this.retrieveForm.uuid);
    },
    // 提交
    submit() {
      this.$refs.retrieveForm.validate((valid) => {
        if (valid) {
          api.toResetPassword(this.retrieveForm).then((res) => {
            if (res.success) {
              this.$alert('修改成功', '提示', {
                confirmButtonText: '确定',
                callback: (action) => {
                  this.$router.push('/login');
                },
              });
            } else {
              this.$message.error(`${res.message}`);
            }
          });
        }
      });
    },
    cancel() {
      this.$router.push('/login');
    },
    getcompname() {
      this.options = [];
      this.retrieveForm.relationCorpId = '';
      api
        .getCorpIdByUserName({ userName: this.retrieveForm.userName })
        .then((res) => {
          if (res.code == '100000') {
            this.options = res.datas.list;
          } else {
            this.$message.error(res.message);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.login-container {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin: 30px auto;
  width: 100%;
  padding: 10px 35px 0 35px;
  background: #fff;
  border: 1px solid #eaeaea;
}
.login-container {
  .title {
    margin: 0px auto 40px auto;
    text-align: center;
    color: #505458;
  }
  .remember {
    margin: 0px 0px 35px 0px;
  }
  h3 {
    width: 100%;
    text-align: center;
  }
  .el-form-item__content {
    margin-left: 90px !important;
  }
}

.ly-retrieve {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/login/login_bg.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  position: relative;
}
.item-input {
  //width:300px;
  .el-input {
    width: 100%;
  }
}
.page-login {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.main-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // margin-top: -30px;
}
.logo {
  text-align: center;
  .logo-title {
    margin-top: 20px;
    font-size: 24px;
  }
}
.form-btn {
  .el-button {
    width: 100px;
    & + .el-button {
      margin-left: 20px;
    }
  }
}
.captcha {
  overflow: hidden;
  img {
    display: block;
    float: right;
    height: 40px;
  }
}
.hide {
  position: absolute;
  top: 0;
  width: 0;
  opacity: 0;
}
.layout-footer {
  position: absolute;
  bottom: 10px;
  color: white;
  left: -350px;
}
</style>
<style lang="scss">
.btn-s {
  .el-input-group__append {
    width: 100px !important;
    padding-left: 5px;
    padding-right: 25px;
  }
}
.login-container .el-form-item {
  margin-bottom: 30px;
}
</style>
