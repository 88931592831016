<template>
  <div class="page-login father">
    <div class="login-bgc childern">
      <!--<div class="login-1" >-->
      <!--<p id="bgp" class="comms-41"></p>-->
      <!--</div>-->
      <div class="connters-1">
        <div class="banxin">
          <div class="comms-21"></div>
          <div class="main-content content-font-first">
            <el-tabs v-model="activeName" class="fr activer">
              <el-form
                :model="loginForm"
                :rules="rules"
                ref="loginForm"
                label-position="left"
                label-width="0px"
                class="demo-ruleForm login-container"
              >
                <h3 class="title-1">企业用户登录</h3>
                <!--<h3 class="title">登录</h3>-->
                <el-form-item prop="name">
                  <el-input
                    type="text"
                    maxlength="11"
                    v-model.trim="loginForm.name"
                    placeholder="请输入手机号"
                    @change="getcompname"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="relationCorpId">
                  <el-select
                    @change="handelChange"
                    v-model="loginForm.relationCorpId"
                    placeholder="请选择企业"
                    style="width: 360px"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.corpId"
                      :label="
                        item.corpName ? item.corpName : item.registerCorpName
                      "
                      :value="item.corpId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="pass">
                  <el-input
                    :type="password"
                    v-model="loginForm.pass"
                    placeholder="请输入密码"
                    class="content-fontPass"
                  >
                  </el-input>
                  <i
                    class="fa fa-eye eyetab"
                    aria-hidden="true"
                    @click="eyefn"
                  ></i>
                </el-form-item>
                <el-form-item prop="captcha">
                  <el-row :gutter="20" style="line-height: 0">
                    <el-col :span="14">
                      <el-input
                        v-model="loginForm.captcha"
                        placeholder="请输入图形验证码"
                        style="width: 200px"
                      ></el-input>
                    </el-col>
                    <el-col :span="10" class="login-captcha">
                      <img :src="captchaPath" @click="getCaptcha()" alt />
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-row>
                  <div class="grid-content bg-purple-dark fr mima">
                    <!-- <span>
                      <router-link to="/retrieve_password" style="color:#1A2891">忘记密码 ?</router-link>
                    </span> -->
                  </div>
                </el-row>
                <el-form-item style="width: 100%">
                  <el-button
                    type="text"
                    style="
                      width: 100%;
                      background: linear-gradient(to right, #cc0000, #ff9933);
                    "
                    @click.native.prevent="loginSubmit"
                    ><span style="color: #fff">企业账户登录</span></el-button
                  >
                </el-form-item>
                <el-row style="margin-bottom: 40px">
                  <el-col :span="24">
                    <div class="grid-content bg-purple-dark fl">
                      <span class="fr">
                        <router-link to="/register">
                          没有账号?
                          <span style="color: #cc3333">立即注册</span>
                        </router-link>
                      </span>
                    </div>
                    <span style="margin-left: 160px">
                      <router-link
                        to="/retrieve_password"
                        style="color: #999999"
                        >忘记密码 ?
                      </router-link>
                    </span>
                  </el-col>
                </el-row>
              </el-form>
              <div class="left-1"></div>
            </el-tabs>
          </div>
        </div>
      </div>
      <div class="layout-footer">
        <!-- <p>Copyright 2022 广西银壹数字科技有限公司 版权所有</p>

        	<p >联系我们：gxyinyi@sina.com</p> -->
      </div>
    </div>
    <!--<layout-footer></layout-footer>-->
  </div>
</template>

<script>
// import { Login } from "../api/api";
import api from '../api/api';
import { getUUID } from '../util/index.js';
import { getPassword } from '@/util/common.js';
import { JSEncrypt } from 'jsencrypt';
// import LayoutFooter from "@/components/layout-footer";
import * as types from '@/store/mutation-types';
export default {
  components: {
    // "layout-footer": LayoutFooter
  },
  data() {
    return {
      activeName: 'first',
      loginForm: {
        name: '',
        pass: '',
        captcha: '',
        uuid: '',
        relationCorpId: '',
      },
      captchaPath: '',
      rules: {
        pass: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        name: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
        captcha: [
          { required: true, message: '请输入图形验证码', trigger: 'blur' },
        ],
        relationCorpId: [
          { required: true, message: '请选择企业', trigger: 'change' },
        ],
      },
      password: 'password',
      options: [],
      corpType: '',
    };
  },
  created() {
    this.getCaptcha();
  },
  methods: {
    handelChange(e) {
      this.options.map((_) => {
        if (_.corpId == e) {
          this.corpType = _.isChainCorp == 1 ? 5 : _.corpType;
        }
      });
    },
    loginSubmit(ev) {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          let password = getPassword(this.loginForm.pass);
          let LoginAdminUser = {
            userName: this.loginForm.name,
            password: password,
            uuid: this.loginForm.uuid,
            verifyCode: this.loginForm.captcha,
            corpType: this.corpType,
            relationCorpId: this.loginForm.relationCorpId,
            channelCode: 'YRY', // 渠道
          };
          api.Login(LoginAdminUser).then((res) => {
            if (res.code == '100000') {
              let tokens =
                res.datas.token.token_type + ' ' + res.datas.token.access_token;
              sessionStorage.setItem(
                'user',
                JSON.stringify(res.datas.token.user)
              );
              sessionStorage.setItem('userId', JSON.stringify(password));
              sessionStorage.setItem('token', tokens);
              sessionStorage.logtimer = Date.now();
              sessionStorage.setItem(
                'layoutToken',
                res.datas.token.access_token
              );
              sessionStorage.refreshtoken = res.datas.token.refresh_token;
              this.$store.commit(types.UPDATE_userInfo, res.datas.token.user); //更新store中的用户信息
              this.getCorpByCorpId(); //获取企业信息
            } else if (res.code == '10001') {
              this.$message({
                message: '密码有效期已过期，请及时修改密码',
                type: 'error',
              });
              this.$router.push({
                path: '/retrieve_password',
              });
            } else {
              this.$message({
                message: res.message,
                type: 'error',
              });
            }
          });
        }
      });
    },
    // 获取验证码
    getCaptcha() {
      this.loginForm.uuid = getUUID();
      this.captchaPath = api.verifycode(this.loginForm.uuid);
    },
    getCorpByCorpId(item) {
      var user = JSON.parse(sessionStorage.getItem('user'));
      api
        .GetCorpByCorpId({
          relationCorpId: user.relationCorpId,
        })
        .then((res) => {
          if (res.code == 100000) {
            console.log(res, 'de g');
            this.corpList = res.datas.corp;
            sessionStorage.setItem('corpList', JSON.stringify(this.corpList));
            sessionStorage.setItem(
              'logoURL',
              JSON.stringify(res.datas.logoURL)
            );
            sessionStorage.setItem('name', JSON.stringify('工作台'));
            this.$store.commit(types.UPDATE_logoURL, res.datas.logoURL);
            this.$store.commit(types.UPDATE_corpName, res.datas.corp.corpName);
            this.$store.commit(types.UPDATE_Name, '工作台');
            // 企业信息完善 1 已完善 2 未完善 3企业资料完善完成 4个人资料完善完成 registerIdentify 1 已检测 2 未检测
            if (
              (res.datas.corp.corpInfoComplete == 2 ||
                res.datas.corp.corpInfoComplete == 3 ||
                res.datas.corp.corpInfoComplete == 4) &&
              (res.datas.corp.registerIdentify == 2 ||
                res.datas.corp.registerIdentify == null)
            ) {
              if (res.datas.corp.corpInfoComplete == 2) {
                this.$router.push({
                  path: '/Company',
                  query: {
                    corpId: this.corpList.corpId,
                  },
                });
                return;
              }
              if (res.datas.corp.corpInfoComplete == 3) {
                this.$alert('企业信息未完善，请继续完善', '企业认证状态', {
                  confirmButtonText: '确定',
                  callback: (action) => {
                    if (action == 'confirm') {
                      console.log('corpId ===>', this.corpList.corpId);
                      this.$router.push({
                        path: '/CompanyPerfect',
                        query: {
                          corpId: this.corpList.corpId,
                        },
                      });
                    }
                  },
                });
                return;
              }
              if (res.datas.corp.corpInfoComplete == 4) {
                this.$router.push({
                  name: 'CompanyConfirm',
                  query: {
                    corpId: this.corpList.corpId,
                  },
                });
                return;
              }
              //人脸识别
              if (
                res.datas.corp.registerIdentify == 2 ||
                res.datas.corp.registerIdentify == 3 ||
                res.datas.corp.registerIdentify == null
              ) {
                this.$router.push({
                  name: 'CompanyVideo',
                  query: {
                    corpId: this.corpList.corpId,
                  },
                });
                return;
              }
            } else {
              //信息完善之后 需要判断企业是否已经认证 "企业信息审核： 40初始状态 41平台初审通过 43 平台复审通过"
              //  if (res.datas.corp.corpInfoCheck == 43) {
              if (
                res.datas.corp.corpInfoCheck == 43 &&
                user.personalInfoAuth == 3
              ) {
                // this.isCfvcaUserId();
                sessionStorage.name = '工作台';
                this.$router.push({ path: '/workbench' });
                sessionStorage.name = '工作台';
                if (JSON.parse(sessionStorage.user).corpType == 2) {
                  sessionStorage.name = '工作台';
                  sessionStorage.oldpath = '/workbench';
                  this.$router.push({ path: '/workbench' });
                } else {
                  sessionStorage.oldpath = '/workbench';
                  this.$router.push({ path: '/workbench' });
                }
                if (res.datas.token?.user?.corpId) {
                  //如果没有企业id 代表没有完善企业信息
                  sessionStorage.name = '银e券';
                  this.$router.push({ path: '/easypay/easypaymessage' });
                } else {
                  sessionStorage.name = '工作台';
                  this.$router.push({ path: '/workbench' });
                }
              } else {
                if (res.datas.corp.corpInfoCheck == 40) {
                  if (res.datas.corp.registerIdentify === 1) {
                    this.$router.push({
                      path: '/CompanyResult',
                      query: {
                        corpId: this.corpList.corpId,
                      },
                    });
                  } else {
                    this.$router.push({
                      path: '/companyVideo',
                      query: {
                        corpId: this.corpList.corpId,
                      },
                    });
                  }
                } else if (res.datas.corp.corpInfoCheck == 41) {
                  this.$router.push({
                    path: '/CompanyResult',
                    query: {
                      corpId: this.corpList.corpId,
                    },
                  });
                } else if (res.datas.corp.corpInfoCheck == 42) {
                  this.$router.push({
                    path: '/CompanyResult',
                    query: {
                      corpId: this.corpList.corpId,
                    },
                  });
                } else if (res.datas.corp.corpInfoCheck == 44) {
                  this.$router.push({
                    path: '/CompanyResult',
                    query: {
                      corpId: this.corpList.corpId,
                    },
                  });
                }
              }
              //判断个人认证
              if (user.personalInfoAuth == 1) {
                this.$router.push({
                  path: '/customerprefect',
                  query: {
                    corpId: user.userId,
                  },
                });
              } else if (
                user.personalInfoAuth == 2 ||
                user.personalInfoAuth == 4
              ) {
                this.$router.push({
                  path: '/customervideo',
                  query: {
                    corpId: user.userId,
                  },
                });
              }
            }
          }
        });
    },
    eyefn() {
      this.password = this.password == 'password' ? 'text' : 'password';
    },
    getcompname() {
      this.options = [];
      this.loginForm.relationCorpId = '';
      api.getCorpIdByUserName({ userName: this.loginForm.name }).then((res) => {
        if (res.code == '100000') {
          this.options = res.datas.list;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-font-first {
  width: 440px;
  height: 560px;
  background: #ffffff;
  box-shadow: 0 8px 20px 0 #041866;
  border-radius: 8px;
  border-radius: 8px;
}
.main-content {
  position: absolute;
  top: 50%;
  left: 82%;
  transform: translate(-50%, -50%);
  // margin-top: -30px;
}
.page-login {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.login-bgc {
  /*<!--background: url(//betacdn.lanhuapp.com/web/21f7ca7….png) left top/100% no-repeat;-->*/
  width: 100%;
  height: 100%;
  min-width: 1200px;
  background-image: url('../assets/login/login_bg.jpg');
  /*url(//betacdn.lanhuapp.com/web/21f7ca70e900efcffd724b7707957071.png) left top/100% no-repeat*/
  background-size: 100% 100%;
  flex: 1;
  position: relative;
}
.login-container {
  -moz-border-radius: 5px;
  background-clip: padding-box;
  width: 400px;
  .title {
    margin: 0px auto 20px auto;
    text-align: center;
    color: #2937a6;
  }
  .title-content {
    opacity: 0.65;
    font-family: PingFang-SC-Regular;
    font-size: 12px;
    color: #2937a6;
    text-align: center;
    line-height: 18px;
    text-align: center;
  }
  .remember {
    margin: 0px 0px 35px 0px;
  }
}
a {
  text-decoration: none;
  color: #ccc;
}
.link {
  width: 100%;
  background-color: #32333b;
}
h3 {
  color: #ccc;
  font-size: 18px;
}
ul {
  list-style: none;
  font-size: 18px;
}

span {
  color: #ccc;
}
.footer p {
  text-align: center;
}

.app {
  margin-top: 30px;
  margin-left: 20px;
}
.wei_app {
  width: 300px;
  height: 138px;
}
.login-captcha {
  > img {
    width: 150px;
    cursor: pointer;
    height: 40px;
  }
}

.logo {
  text-align: center;
  .logo-title {
    margin-top: 20px;
    font-size: 24px;
  }
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.new-color {
  margin-bottom: 24px;
  display: inline-block;
}
.loginContent {
  margin-top: 40px;
  display: inline-block;
  width: 258px;
  height: 40px;
}
.el-input__inner {
  background-color: none !important;
}
.content-fontName {
  /*background-image: url("../assets/login/shoujizh.png");*/
  background: red !important;
}
.el-input {
  width: 360px;
}
.el-button {
  width: 360px !important;
}
.title-1 {
  font-family: PingFang-SC-Bold;
  font-size: 24px;
  color: #1a2891;
  text-align: center;
  line-height: 36px;
}
.left-1 {
  width: 205px;
  height: 38px;
  background-size: 100% 100%;
  margin: auto;
}
.login-captcha {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.banxin {
  width: 1200px;
  height: 515px;
  margin: auto;
  position: fixed;
}
.connters-1 {
  width: 1200px;
  height: 515px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.comms-41 {
  width: 537px;
  height: 511px;
  background: url('../assets/login/login_dq.png');
  background-size: cover;
  position: absolute;
  top: 12px;
  left: 100px;
}
.comms-43 {
  width: 397px;
  height: 511px;
  background: url('../assets/login/login_dq3.png');
  background-size: cover;
  position: absolute;
  top: 12px;
  left: 100px;
}
.comms-21 {
  width: 660px;
  height: 203px;
  // background-image: url("../assets/login/login_wa.png");
  background-size: 100% 100%;
  position: absolute;
  top: 110px;
  left: 70px;
}
.login-1 {
  width: 600px;
  height: 515px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 100px;
  transform: translate(-20%, -55%);
}
.mima {
  margin-bottom: 16px;
  margin-right: 44px;
}
.el-form-item__content {
  line-height: 0 !important;
}
.layout-footer {
  color: #ffffff;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 25px 0 30px;
  /*margin: auto;*/
  text-align: center;
  margin-top: 10px;
}
</style>
