<template>
  <div>
    <!--<Step class="m-top" v-bind:step-value="stepValues"></Step>-->
    <div class="main-contentTop1"></div>

    <div class="main-TopContentBottom">
      <div class="steps">
        <el-steps :active="0" align-center>
          <el-step title="企业信息认证"></el-step>
          <el-step title="个人信息认证"></el-step>
          <el-step title="企业资料补充"></el-step>
          <el-step title="用户人脸识别"></el-step>
          <el-step title="注册申请成功"></el-step>
        </el-steps>
      </div>
    </div>

    <div class="register_main">
      <el-form
        :model="form"
        :data="form"
        :rules="rules"
        ref="form"
        label-position="right"
        label-width="210px"
        class="demo-form registerDemo login-container"
      >
        <div class="first_step">
          <h2>企业资料完善</h2>
          <el-form-item label="上传企业LOGO：" class="company1">
            <div
              class="img-class"
              @click="getFileType('fastdfs-file-service')"
              :class="{ imgClass: !isEmpty(logoUrl) }"
              style="margin-left: 10px"
            >
              <el-upload
                :file-list="logoUrlList"
                :limit="1"
                :on-exceed="
                  () => {
                    $message.info('超过上传限制');
                  }
                "
                :action="logoUpload()"
                list-type="picture-card"
                :data="fileType"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
                :on-remove="companyseRemove"
                :headers="Authorization"
              >
                <i v-if="isEmpty(logoUrl)" class="el-icon-plus"></i>
                <span v-else>
                  <i class="el-icon-loading"></i>
                  <span>上传中</span>
                </span>
              </el-upload>
            </div>
            <span style="color: #8c8c8c; font-size: 14px"
              >1、企业LOGO可以多次上传，只保留最新提交的LOGO图案</span
            >
            <br />
            <span style="color: #8c8c8c; font-size: 14px"
              >2、上传文件可以为.jpg或者.png格式</span
            >
          </el-form-item>

          <el-form-item class="company1">
            <span class="require" slot="label">上传营业执照：</span>
            <div
              class="img-class"
              @click="getFileType('license')"
              :class="{ imgClass: !isEmpty(businessLicense) }"
              style="margin-left: 10px"
            >
              <el-upload
                ref="uploadFile"
                :action="businessLicenseUpload()"
                list-type="picture-card"
                :data="fileType"
                :before-upload="beforeAvatarUpload"
                :on-success="uploadBusinessLicenseCallBack"
                :on-remove="licenseRemove"
                :file-list="businessLicenseUrlList"
                :headers="Authorization"
              >
                <i v-if="isEmpty(businessLicense)" class="el-icon-plus"></i>
                <span v-else>
                  <i class="el-icon-loading"></i>
                  <span>上传中</span>
                </span>
              </el-upload>
            </div>
            <span style="color: #8c8c8c; font-size: 14px"
              >仅支持JPG、PNG格式，文件小于5M。请尽量使用高清质量图片。</span
            >
            <br />
          </el-form-item>

          <el-form-item
            label="社会统一信用代码："
            prop="creditCode"
            class="company1"
          >
            <el-input v-model="form.creditCode"></el-input>
          </el-form-item>

          <el-form-item
            label="证件有效期： "
            class="company1"
            prop="usccExpirationDate"
            v-if="!form.checked"
          >
            <el-date-picker
              style="width: 192px"
              v-model="form.usccExpirationDate[0]"
              format="yyyy年MM月dd日"
              :picker-options="identIssuDtOptions"
              value-format="yyyy-MM-dd"
              placeholder="开始日期"
            />
            ~
            <el-date-picker
              style="width: 192px"
              v-model="form.usccExpirationDate[1]"
              :picker-options="exprtnDtOptions"
              format="yyyy年MM月dd日"
              value-format="yyyy-MM-dd"
              placeholder="结束日期"
            />
            <!-- <span v-show="flag2">长期</span> -->
            <!-- <span v-show="testShow">长期</span> -->
          </el-form-item>
          <el-form-item
            label="证件有效期： "
            class="company1"
            prop="usccExpirationDate"
            v-if="form.checked"
          >
            <span style="color: #606266">长期</span>
          </el-form-item>
          <el-checkbox class="check-box-class" v-model="form.checked"
            >长期</el-checkbox
          >
          <el-form-item label="企业全称：" prop="corpName" class="company1">
            <el-input v-model="form.corpName"></el-input>
          </el-form-item>

          <el-form-item
            label="企业成立日期："
            prop="foundTime"
            class="company1"
          >
            <el-date-picker
              style="width: 400px"
              v-model="form.foundTime"
              format="yyyy年MM月dd日"
              value-format="yyyy年MM月dd日"
              type="date"
              placeholder="选择日期"
            />
          </el-form-item>
          <el-form-item label="从业人数：" class="company1" prop="prctnrNum">
            <el-select v-model.trim="form.prctnrNum" placeholder="请选择">
              <el-option
                v-for="item in NumberOfPeople"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="注册资本："
            class="company1"
            prop="registerCapital"
          >
            <el-input v-model.trim="form.registerCapital">
              <template slot="append">万人民币</template>
            </el-input>
          </el-form-item>

          <el-form-item label="实缴资本：" class="company1" prop="payInCapital">
            <el-input v-model.trim="form.payInCapital">
              <template slot="append">万人民币</template>
            </el-input>
          </el-form-item>

          <el-form-item label="营业收入：" class="company1" prop="bsnIncmAmt">
            <el-input v-model.trim="form.bsnIncmAmt">
              <template slot="append">元人民币</template>
            </el-input>
          </el-form-item>

          <el-form-item label="资产总额：" class="company1" prop="astTotAmt">
            <el-input v-model.trim="form.astTotAmt">
              <template slot="append">元人民币</template>
            </el-input>
          </el-form-item>

          <el-form-item label="净资产：" class="company1" prop="netAst">
            <el-input v-model.trim="form.netAst">
              <template slot="append">元人民币</template>
            </el-input>
          </el-form-item>

          <el-form-item label="企业性质：" prop="corpNature" class="company1">
            <el-select
              v-model="form.corpNature"
              placeholder="请选择企业性质"
              filterable
            >
              <el-option
                v-for="(item, index) in zjlist"
                :key="index.name"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="企业出资人经济成分："
            class="company1"
            prop="entpEcnmCmpt"
          >
            <el-select
              v-model="form.entpEcnmCmpt"
              placeholder="请选择"
              class=""
            >
              <template v-for="item in options">
                <div :key="item.label">
                  <el-option :label="item.label" :value="item.value" disabled />
                  <div
                    v-for="sub in item.options"
                    :key="sub.label"
                    style="padding-left: 20px"
                  >
                    <el-option :label="sub.label" :value="sub.value" disabled />
                    <div style="padding-left: 20px">
                      <el-option
                        v-for="finalSub in sub.options"
                        :key="finalSub.value"
                        :label="finalSub.label"
                        :value="finalSub.value"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </el-select>
          </el-form-item>

          <el-form-item
            label="所属行业："
            prop="corpIndustrySecondName"
            class="company1"
          >
            <el-select
              v-model="form.corpIndustryName"
              placeholder="请选择所属行业"
              style="width: 200px"
              @change="getSecondIndustry"
            >
              <el-option
                v-for="(item, index) in sulist"
                :key="index.name"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
            <el-select
              v-model="form.corpIndustrySecondName"
              placeholder="请选择所属行业"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in ejlist"
                :key="index.name"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="是否上市公司： "
            class="company1"
            prop="lstdCoFlg"
          >
            <el-radio-group v-model="form.lstdCoFlg">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="电话：" class="company1" prop="telephone">
            <el-input v-model.trim="form.telephone"></el-input>
          </el-form-item>
          <el-form-item label="传真：" class="company1" prop="fax">
            <el-input v-model.trim="form.fax"></el-input>
          </el-form-item>
          <el-form-item label="邮编：" class="company1" prop="zipCode">
            <el-input v-model.trim="form.zipCode"></el-input>
          </el-form-item>
          <el-form-item label="注册地址：" class="company1" prop="registerAddr">
            <el-input v-model.trim="form.registerAddr"></el-input>
          </el-form-item>

          <el-form-item
            label="经营地址："
            class="company1"
            prop="businessAddrProvinceIdShow"
          >
            <el-select
              v-model="form.businessAddrProvinceIdShow"
              placeholder="请选择省"
              style="width: 200px"
              @change="getCityList"
            >
              <el-option
                v-for="item in provinceList"
                :key="item.areaId"
                :label="item.name"
                :value="item.areaId"
              ></el-option>
            </el-select>
            <el-select
              v-model="form.businessAddrCityIdShow"
              placeholder="请选择市"
              style="width: 200px"
              @change="getDistrictList"
              @visible-change="getCityInfo"
            >
              <el-option
                v-for="item in cityList"
                :key="item.areaId"
                :label="item.name"
                :value="item.areaId"
              ></el-option>
            </el-select>
            <el-select
              v-model="form.businessAddrRegionIdShow"
              placeholder="请选择区/县"
              style="width: 200px"
              @visible-change="getDistrictInfo"
            >
              <el-option
                v-for="item in districtList"
                :key="item.areaId"
                :label="item.name"
                :value="item.areaId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="经营详细地址："
            class="company1"
            prop="businessAddr"
          >
            <el-input v-model.trim="form.businessAddr"></el-input>
          </el-form-item>

          <el-form-item
            label="企业介绍："
            class="company1"
            prop="corpDescribe "
          >
            <el-input
              v-model.trim="form.corpDescribe"
              type="textarea"
              :rows="5"
              style="width: 400px"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="主营业务："
            class="company1"
            prop="businessScope"
          >
            <el-input
              v-model.trim="form.businessScope"
              type="textarea"
              :rows="5"
              style="width: 400px"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业名称：" class="company1">
            <el-input v-model.trim="form.accountName" disabled></el-input>
          </el-form-item>
          <el-form-item label="税号：" class="company1" prop="taxNum">
            <el-input v-model.trim="form.taxNum"></el-input>
          </el-form-item>
          <el-form-item label="企业账号：" class="company1" prop="accountNo">
            <el-input
              placeholder="请输入企业账号"
              v-model="form.accountNo"
            ></el-input>
          </el-form-item>
          <el-form-item label="联行号：" class="company1" prop="bankNumber">
            <el-input
              placeholder="请输入联行号"
              v-model="form.bankNumber"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业开户行：" class="company1" prop="openBank">
            <el-select v-model="form.openBank" placeholder="请选择" filterable>
              <el-option
                v-for="item in option"
                :key="item.bankId"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分支行省份：" class="company1" prop="bankAddr">
            <el-select
              v-model="form.bankAddr"
              @change="getBankCity"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in provinceList"
                :key="item.areaId"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分支行城市：" class="company1" prop="bankCity">
            <el-select
              v-model="form.bankCity"
              @visible-change="getBankCityList"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in address"
                :key="item.areaId"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="item-btn">
            <el-button
              type="primary"
              size="small"
              style="
                width: 160px;
                height: 40px;
                background: white;
                color: black;
                margin-right: 32px;
              "
              @click="cancel()"
              >取 消</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click.native="nextStep('form')"
              style="width: 160px; height: 40px; background: #2937a6"
              >下一步</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import api from "../../api/api";
import Step from "./Step";
import entpEcnmCmpt from "../../const/entpEcnmCmpt.js";
import upload from "../../api/upload";
import _ from "lodash";
import moment from "moment";
import { nextTick } from "vue";

export default {
  name: "CorporateAuthStepOne",
  data() {
    return {
      provinceList: [],
      address: [],
      option: [],
      testShow: false,
      flag1: true,
      flag2: false,
      NumberOfPeople: [
        {
          value: 0,
          label: "小于 20 人",
        },
        {
          value: 1,
          label: "20-50 人",
        },
        {
          value: 2,
          label: "50-100 人",
        },
        {
          value: 3,
          label: "100-300 人",
        },
        {
          value: 4,
          label: "300-500 人",
        },
        {
          value: 5,
          label: "500 人-1000 人",
        },
        {
          value: 6,
          label: "1000 人以上",
        },
      ],
      options: entpEcnmCmpt.getOptions(),
      Authorization: { Authorization: sessionStorage.token },
      form: {
        checked: false,
        accountName: "",
        telephone: "",
        zipCode: "",
        fax: "",
        taxNum: "",
        accountNo: "",
        bankId: "",
        bankAddr: "",
        bankCity: "",
        openBank: "",
        bankNumber: "",
        corpIndustryId: "",
        corpIndustrySecondId: "",
        corpName: "",
        creditCode: "",
        corpDescribe: "",
        payInCapital: "",
        registerAddr: "",
        corpNature: "",
        registerCapital: "",
        businessScope: "",
        corpId: "",
        region: "",
        corpDescribe: "",
        corpDetailId: "",
        createTime: "",
        fileCode: "",
        legalIdCard: "",
        payInCapital: "",
        remark: "",
        state: "",
        updateTime: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        businessLicense: "", // 营业执照
        logoUrl: "", // 企业Logo
        corpIndustryName: "",
        corpIndustrySecondName: "",
        businessAddrProvinceId: "",
        businessAddrProvinceIdShow: "",
        businessAddrCityId: "",
        businessAddrCityIdShow: "",
        businessAddrRegionId: "",
        businessAddrRegionIdShow: "",
        businessAddr: "",
        foundTime: "",
        // 新增部分
        prctnrNum: "",
        astTotAmt: "",
        bsnIncmAmt: "",
        netAst: "",
        lstdCoFlg: "",
        usccExpirationDate: ["", ""],
        entpEcnmCmpt: "",
        identIssuDt: "",
        exprtnDt: "",
      },
      dialogImageUrl: "",
      dialogVisible: false,
      stepValues: 2,
      fileType: {
        type: "",
        registerCode: "",
        fullPath: "",
      },
      Id: "",
      registerForm: {
        name: "",
        enpsLicense: "",
        provinceregisterAddr: "",
        cityregisterAddr: "",
        legalregisterAddr: "",
        controller: 1,
        fillPersion: 1,
      },
      corpId: "",
      value: "",
      provinceList: [],
      cityList: [],
      districtList: [],
      zjlist: [], //企业性质
      sulist: [], //所属行业一级
      ejlist: [], //所属行业二级
      businessLicense: "", // 营业执照
      logoUrl: "", // 企业Logo
      IDjustUrl: "", // 法人身份证正面
      IDReverseUrl: "", // 法人身份证反面
      agentJustUrl: "", // 代理人正面
      agentReverseUrl: "", // 代理人反面
      entrustUrl: "", // 委托代理书
      logoUrlList: [], //回显logo
      businessLicenseUrlList: [], //回显营业执照
      loading: {},
      identIssuDtOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
    };
  },
  components: {
    Step,
  },
  mounted() {
    this.getCorpNature();
    this.getFirstIndustry();
    this.corpId = this.$route.query.corpId;
    this.getInfo();
    this.getProvince();
    this.getOpenBank();
    this.getProvinceList();
  },
  watch: {
    // "form.bankAddr": {
    //   immediate:true,
    //   handler: function() {
    //     this.getCity();
    //     this.form.bankCity = "";
    //     console.log(222);
    //   },
    // },
    "form.corpName": {
      immediate: true,
      handler() {
        this.form.accountName = this.form.corpName;
      },
    },
  },
  computed: {
    exprtnDtOptions() {
      return {
        disabledDate(date) {
          return date < new Date(new Date().getTime() - 86400000);
        },
      };
    },
    supName() {
      return this.$store.getters.supName;
    },
    rules() {
      return {
        taxNum: [
          { required: true, message: "请输入税号", trigger: "change" },
          {
            pattern:
              /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/,
            message: "请输入正确的税号",
            trigger: "change",
          },
        ],
        upFlag: false,
        accountName: [
          { required: true, message: "请输入企业户名", trigger: "change" },
        ],
        accountNo: [
          { required: true, message: "请输入企业账户", trigger: "change" },
        ],
        bankNumber: [
          { required: true, message: "请输入联行号", trigger: "change" },
        ],
        openBank: [
          { required: true, message: "请选择企业开户行", trigger: "change" },
        ],
        bankAddr: [
          { required: true, message: "请选择分支行省份", trigger: "change" },
        ],
        bankCity: [
          { required: true, message: "请选择分支行城市", trigger: "change" },
        ],
        name: [
          { required: true, message: "请输入法人姓名", trigger: "change" },
          this.phoneFormatRule,
        ],
        name1: [
          { required: true, message: "请输入经办人姓名", trigger: "change" },
          this.phoneFormatRule,
        ],
        companyname: [
          { required: true, message: "请输入企业名称", trigger: "change" },
          this.phoneFormatRule,
        ],
        phone: [
          { required: true, message: "手机号不能为空", trigger: "change" },
          this.phoneFormatRule,
        ],
        telephone: [
          { required: true, message: "电话不能为空", trigger: "change" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的电话号码",
            trigger: "change",
          },
        ],
        password: [this.passwordRequireRule, this.passwordFormatRule],
        pass: [this.passwordRequireRule, this.passwordFormatRule],
        agentPhone: [
          { required: true, message: "请输入正确的验证码", trigger: "change" },
          this.passwordFormatRule,
        ],
        corpNature: [
          { required: true, message: "请选择企业性质", trigger: "change" },
        ],
        corpIndustryName: [
          { required: true, message: "请选择所属行业", trigger: "change" },
        ],
        corpIndustrySecondName: [
          { required: true, message: "请选择所属行业", trigger: "change" },
        ],
        corpName: [
          { required: true, message: "请输入企业名称", trigger: "change" },
        ],
        foundTime: [
          { required: true, message: "请选择企业成立日期", trigger: "change" },
        ],
        creditCode: [
          {
            required: true,
            message: "请输入社会统一信用代码",
            trigger: "change",
          },
          {
            pattern:
              /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/,
            message: "请输入正确的统一社会信用代码",
            trigger: "change",
          },
        ],
        prctnrNum: [
          { required: true, message: "请选择从业人数" },
          {
            pattern: /^\d+$/,
            message: "从业人数必须为整数",
            trigger: "change",
          },
        ],
        bsnIncmAmt: [
          { required: true, message: "请输入营业收入" },
          this.moneyFormatRule,
        ],
        astTotAmt: [
          { required: true, message: "请输入资产总额" },
          this.moneyFormatRule,
        ],
        netAst: [
          { required: true, message: "请输入净资产" },
          this.moneyFormatRule,
        ],
        lstdCoFlg: [{ required: true, message: "请选择是否上市公司" }],
        businessAddrProvinceIdShow: [
          { required: true, message: "请选择经营地址" },
        ],
        businessAddr: [{ required: true, message: "请输入经营详细地址" }],
        usccExpirationDate: [
          { required: true, message: "请选择证件有效期" },
          {
            validator: this.usccExpirationDateRule,
            trigger: ["change", "change"],
          },
        ],
        entpEcnmCmpt: [
          {
            required: true,
            message: "请选择企业出资人经济成分",
            trigger: "change",
          },
        ],
      };
    },
    phoneFormatRule() {
      return {
        pattern: /^1[34578]\d{9}$/,
        message: "请输入正确的手机号码",
        trigger: "change",
      };
    },
    moneyFormatRule() {
      return {
        pattern: /^\d+(\.\d{0,2})?$/,
        message: "请输入数字且最多保留两位小数",
        trigger: "change",
      };
    },
    passwordRequireRule() {
      return {
        required: true,
        message: "请输入8位以上且必须为英文大小写字母加数字组合的密码",
        trigger: "change",
      };
    },
    passwordFormatRule() {
      return {
        min: 8,
        max: 8,
        pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,18}$/,
        message: "请输入8位以上且必须为英文大小写字母加数字组合的密码",
        trigger: "change",
      };
    },
  },
  methods: {
    getBankCity() {
      this.getCity();
      this.form.bankCity = "";
    },
    getOpenBank() {
      api.GetOpenBank().then((res) => {
        if (res.success) {
          this.option = res.datas.corpBankList;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getCity() {
      const areaId = this.provinceList.find(
        (val) => val.name === this.form.bankAddr
      ).areaId;
      api.GetCorpNature({ action: 2, areaId }).then((res) => {
        if (res.success) {
          this.address = res.datas.sysAreaList;
        }
      });
    },
    getProvinceList() {
      api.GetCorpNature({ action: 1 }).then((res) => {
        if (res.success) {
          this.provinceList = res.datas.sysAreaList;
        }
      });
    },
    _testDisplay() {
      if (this.form.usccExpirationDate[1] == "长期") {
        this.form.checked = true;
        this.testShow = true;
        this.flag1 = false;
      } else if (
        !!this.form.usccExpirationDate[0] &&
        this.form.usccExpirationDate[1] == ""
      ) {
        console.log(this.form.usccExpirationDate, "==========");
      } else {
        this.testShow = false;
        this.flag1 = true;
      }
    },
    _testDisplay2() {
      if (
        this.form.usccExpirationDate[1] == "2200-01-01" &&
        this.form.usccExpirationDate[0] == "2200-01-01"
      ) {
        this.form.checked = true;
        this.form.usccExpirationDate = ["", ""];
        this.testShow = true;
        this.flag1 = false;
      } else if (
        this.form.usccExpirationDate[1] == "2200-01-01" &&
        this.form.usccExpirationDate[0] !== "2200-01-01"
      ) {
        this.form.usccExpirationDate[1] = "";
      } else {
        this.testShow = false;
        this.flag1 = true;
      }
    },
    usccExpirationDateRule(rule, value, callback) {
      // let startDate = value[0];
      // let endDate = value[1];
      // let maxDate = moment(new Date()).format("YYYY-MM-DD");
      // if (startDate > maxDate) {
      //   callback(new Error("证件起始日期小于或等于当前日期"));
      // }
      // if (_.isEmpty(startDate) || _.isEmpty(endDate)) {
      //   callback(new Error("请选择证件起止日期"));
      // }
      callback();
    },
    isEmpty(obj) {
      return _.isEmpty(obj);
    },
    //LOGo图片上传
    logoUpload() {
      return upload.eqptImgSrc();
    },
    //营业执照图片上传
    businessLicenseUpload() {
      return upload.uploadFile();
    },
    //获取省
    getProvince() {
      api.getProvince().then((res) => {
        if (res.success) {
          this.provinceList = res.datas.provinceList;
        }
      });
    },
    // businessAddrRegionIdShow
    //改省
    getCityList(val) {
      this.cityList = [];
      this.form.businessAddrCityIdShow = "";
      this.form.businessAddrCityId = "";

      this.districtList = [];
      this.form.businessAddrRegionId = "";
      this.form.businessAddrRegionIdShow = "";

      api.getCity({ provinceId: val }).then((res) => {
        if (res.success) {
          this.cityList = res.datas.cityList;
        }
      });
    },
    getDistrictInfo(val) {
      if (val) {
        if (this.districtList.length === 0 && this.form.businessAddrCityId) {
          api
            .getRegion({ cityId: this.form.businessAddrCityId })
            .then((res) => {
              if (res.success) {
                this.districtList = res.datas.regionList;
              }
            });
        }
      }
    },
    getBankCityList(val) {
      if (val) {
        const areaId = this.provinceList.find(
          (val) => val.name === this.form.bankAddr
        ).areaId;
        api.GetCorpNature({ action: 2, areaId }).then((res) => {
          if (res.success) {
            this.address = res.datas.sysAreaList;
          }
        });
      }
    },
    getCityInfo(val) {
      // 获取市
      if (val) {
        if (this.cityList.length === 0) {
          api
            .getCity({ provinceId: this.form.businessAddrProvinceId })
            .then((res) => {
              if (res.success) {
                this.cityList = res.datas.cityList;
              }
            });
        }
      }
    },

    getDistrictList(val) {
      this.districtList = [];
      this.form.businessAddrRegionId = "";
      this.form.businessAddrRegionIdShow = "";
      this.form.businessAddrCityId = "";
      api.getRegion({ cityId: val }).then((res) => {
        if (res.success) {
          this.districtList = res.datas.regionList;
        }
      });
    },
    //回显数据
    getInfo() {
      api
        .getCorpTrialDetail({ corpId: this.corpId })
        .then((res) => {
          if (res.success) {
            this.form.foundTime = res.datas.corpDetailList[0].foundData;
            this.form.corpIndustryName =
              res.datas.corpDetailList[0].corpIndustryIdShow;
            this.form.corpIndustrySecondName =
              res.datas.corpDetailList[0].corpIndustrySecondIdShow;

            //再转名字
            this.form.businessAddrProvinceIdShow = this.translatePcaName(
              res.datas.corpDetailList[0].businessAddrProvinceId,
              this.provinceList
            );
            this.form.businessAddrCityId =
              res.datas.corpDetailList[0].businessAddrCityId;
            this.form.businessAddrCityIdShow = this.translatePcaName(
              res.datas.corpDetailList[0].businessAddrCityId,
              this.cityList
            );
            // this.form.businessAddrRegionIdShow = this.translatePcaName(
            //   res.datas.corpDetailList[0].businessAddrRegionId,
            //   this.districtList
            // );
            const { identIssuDt, exprtnDt } = res.datas.corpDetailList[0];
            this.form.usccExpirationDate = [identIssuDt, exprtnDt];
            let resultObj = res.datas.corpDetailList[0];
            let resultObjKeys = _.keys(resultObj);

            _.forEach(resultObjKeys, (key) => {
              if (_.has(this.form, key) && !_.isNull(resultObj[key])) {
                this.form[key] = resultObj[key];
              }
            });
            // this.changeNm()
            this._testDisplay2();

            res.datas.fileUrlByCorpList.forEach((el) => {
              if (el.fileName == "营业执照") {
                this.businessLicenseUrlList.push({
                  name: el.fileName,
                  url: api.getImgURL(el.fileUrl),
                });
                this.businessLicense = el.fileUrl;
                this.form.businessLicense = el.fileUrl;
              } else if (el.fileName == "企业logo") {
                this.logoUrlList.push({
                  name: el.fileName,
                  url: api.getImgURL(el.fileUrl),
                });
                this.logoUrl = el.fileUrl;
                this.form.logoUrl = el.fileUrl;
              }
            });
          }

          this.$nextTick(() => {
            // 清除初始化的时候触发的表单校验
            this.$refs.form.clearValidate();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 省市区转id
    translatePca(name, list) {
      var id = "";
      list.forEach((element) => {
        if (element.name == name) {
          id = element.areaId;
        }
      });
      return id;
    },
    //省市区转名字
    translatePcaName(id, list) {
      var name = "";
      list.forEach((element) => {
        if (element.areaId == id) {
          name = element.name;
        }
      });
      return name;
    },
    // 行业 名字 转 id
    translate(name, list) {
      var id = "";
      list.forEach((element) => {
        if (element.name == name) {
          id = element.corpIndustryId;
        }
      });
      return id;
    },
    // 行业 id 转 名字
    translateName(id, list) {
      var name = "";
      list.forEach((element) => {
        if (element.areaId == id) {
          name = element.name;
        }
      });
      return name;
    },
    //获取企业性质
    getCorpNature() {
      api.getCorpNature().then((res) => {
        if (res.code == 100000) {
          this.zjlist = res.datas.corpNatureList;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //获取所属行业接口一级
    getFirstIndustry() {
      api.getFirstIndustry().then((res) => {
        this.sulist = res.datas.corpIndustryList;
      });
    },
    // //获取所属行业接口二级GET /admin/industry/getSecondIndustry
    getSecondIndustry() {
      this.ejlist = [];
      this.form.corpIndustrySecondId = "";
      this.form.corpIndustrySecondName = "";
      this.form.corpIndustryId = this.translate(
        this.form.corpIndustryName,
        this.sulist
      );
      api.getSecondIndustry(this.form.corpIndustryId).then((res) => {
        this.ejlist = res.datas.corpIndustryList;
      });
    },
    uploadBusinessLicenseCallBack(res, file) {
      if (res.code == 100000) {
        this.loading.close();
        this.form.businessLicense = res.datas.uploadResult;
        this.businessLicense = res.datas.uploadResult;
        this.form.corpIndustryName = res.datas.corpDetail.corpIndustryIdShow;
        this.form.corpIndustrySecondName =
          res.datas.corpDetail.corpIndustrySecondIdShow;
        // this.form.usccExpirationDate = [ res.datas.corpDetail.identIssuDt, res.datas.corpDetail.exprtnDt];

        let resultObj = res.datas.corpDetail;
        let resultObjKeys = _.keys(resultObj);

        _.forEach(resultObjKeys, (key) => {
          if (_.has(this.form, key) && !_.isNull(resultObj[key])) {
            this.form[key] = resultObj[key];
          }
        });
        this.form.usccExpirationDate = [
          resultObj.identIssuDt,
          resultObj.exprtnDt,
        ];
        this._testDisplay();
        // this.changeNm()

        this.$message.success("上传成功");
        // this.$refs.form.validate();
      } else {
        this.$message.error(res.message);
        this.loading.close();
        this.form.businessLicense = "";
        this.businessLicense = "";
        let index = this.$refs.uploadFile.fileList.length;
        let uid = file.uid; // 关键作用代码，去除文件列表失败文件
        let idx = this.$refs.uploadFile.fileList.findIndex(
          (item) => item.uid === uid
        ); // 关键作用代码，去除文件列表失败文件（businessLicenseUrlList为el-upload中的ref值）
        this.$refs.uploadFile.fileList.splice(0); // 关键作用代码，去除文件列表失败文件
      }
    },
    getPersion(val) {
      if (val === 1) {
        this.agentJustUrl = "";
        this.agentReverseUrl = "";
        this.entrustUrl = "";
      }
    },
    getFileType(val) {
      this.fileType = {
        type: val,
        registerCode: sessionStorage.getItem("code"),
      };
    },
    handleAvatarSuccess(res) {
      if (res.code == 100000) {
        this.form.logoUrl = res.datas.uploadDate;
        this.logoUrl = res.datas.uploadDate;
        (this.upFlag = true), this.loading.close();
        this.$message.success("上传成功");
      } else {
        this.loading.close();
      }
    },
    // 企业logo删除
    companyseRemove(file) {
      this.form.logoUrl = "";
      this.logoUrl = "";
    },
    // 营业执照删除
    licenseRemove(file) {
      this.form.businessLicense = "";
      this.businessLicense = "";
      this.form.checked = false;
    },
    beforeAvatarUpload(file) {
      let excelfileExtend = ".jpg,.png,.jpeg,.bmg"; //设置文件格式
      let fileExtend = file.name
        .substring(file.name.lastIndexOf("."))
        .toLowerCase();

      if (excelfileExtend.indexOf(fileExtend) <= -1) {
        this.$message.error("文件格式错误");
        return false;
      }
      //判断文件大小 2M 以内
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 5MB!");
        return false;
      }

      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.loading = loading;

      //上传前配置
      if (this.fileType.type === "license") {
        this.businessLicense = "1";
        return;
      }
      if (this.fileType.type === "legalFacade") {
        this.IDjustUrl = "1";
        return;
      }
      if (this.fileType.type === "legalReverse") {
        this.IDReverseUrl = "1";
        return;
      }
      if (this.fileType.type === "agentFacade") {
        this.agentJustUrl = "1";
        return;
      }
      if (this.fileType.type === "agentReverse") {
        this.agentReverseUrl = "1";
        return;
      }
      if (this.fileType.type === "entrustTable") {
        this.entrustUrl = "1";
        return;
      }
    },
    cancel() {
      this.$router.push("/login");
    },
    nextStep(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.bankId = this.option.find(
            (val) => val.name === this.form.openBank
          ).bankId;
          if (_.isEmpty(this.businessLicense)) {
            this.$message({
              message: "请上传营业执照",
              type: "error",
            });
            return;
          }

          if (
            (this.districtList.length > 0 &&
              this.form.businessAddrRegionIdShow === "") ||
            this.form.businessAddrProvinceIdShow === "" ||
            this.form.businessAddrCityIdShow === ""
          ) {
            this.$message({
              message: "请将省市区补充完整",
              type: "error",
            });
            return;
          }

          this.form.corpIndustryId = this.translate(
            this.form.corpIndustryName,
            this.sulist
          );

          if (this.ejlist.length != 0) {
            this.form.corpIndustrySecondId = this.translate(
              this.form.corpIndustrySecondName,
              this.ejlist
            );
          }

          //省市区 名字转换成id

          //先转id
          this.form.businessAddrProvinceId = this.translatePca(
            this.form.businessAddrProvinceIdShow,
            this.provinceList
          );
          if (this.cityList.length > 0) {
            this.form.businessAddrCityId = this.translatePca(
              this.form.businessAddrCityIdShow,
              this.cityList
            );
          }

          if (this.districtList.length > 0) {
            this.form.businessAddrRegionId = this.translatePca(
              this.form.businessAddrRegionIdShow,
              this.districtList
            );
          }

          if (!this.form.businessAddrProvinceId) {
            this.form.businessAddrProvinceId =
              this.form.businessAddrProvinceIdShow;
          }
          if (!this.form.businessAddrCityId) {
            this.form.businessAddrCityId = this.form.businessAddrCityIdShow;
          }
          if (!this.form.businessAddrRegionId) {
            this.form.businessAddrRegionId = this.form.businessAddrRegionIdShow;
          }
          //再转名字
          this.form.businessAddrProvinceIdShow = this.translatePcaName(
            this.form.businessAddrProvinceId,
            this.provinceList
          );
          this.form.businessAddrCityIdShow = this.translatePcaName(
            this.form.businessAddrCityId,
            this.cityList
          );
          this.form.businessAddrRegionIdShow = this.translatePcaName(
            this.form.businessAddrRegionId,
            this.districtList
          );
          const initData = {
            corpId: this.corpId,
          };
          if (this.upFlag) {
            const { message } = this.logoUrl;
            this.form.logoUrl = message;
          }

          //   const [identIssuDt, exprtnDt] = this.form.usccExpirationDate;
          let identIssuDt = "";
          let exprtnDt;
          if (this.form.checked) {
            identIssuDt = "2200-01-01";
            exprtnDt = "2200-01-01";
          } else {
            identIssuDt = this.form.usccExpirationDate[0];
            if (this.form.usccExpirationDate[1] == "长期") {
              exprtnDt = "2200-01-01";
            } else if (
              !!this.form.usccExpirationDate[0] &&
              this.form.usccExpirationDate[1] == ""
            ) {
              exprtnDt = "2200-01-01";
            } else {
              exprtnDt = this.form.usccExpirationDate[1];
            }
          }
          let para;
          if (!exprtnDt || !identIssuDt) {
            const { exprtnDt, identIssuDt, ...form2 } = this.form;
            para = { ...form2, ...initData };
          } else {
            para = { ...this.form, ...initData, identIssuDt, exprtnDt };
          }
          api.corpDetailAdd(para).then((res) => {
            if (res.code === "100000") {
              this.$router.push({
                path: "/CompanyPerfect",
                query: {
                  corpId: this.corpId,
                },
              });
            } else {
              this.$message({
                message: res.message,
                type: "error",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/register/company.scss";
.el-form-item__content {
  line-height: 30px !important;
}
.register_main {
  padding-bottom: 20px;
}
.el-input {
  width: 400px;
  height: 32px;
}
.el-select {
  width: 400px;
  height: 32px;
}
.first_step {
  position: relative;
}
.check-box-class {
  position: absolute;
  top: 680px;
  right: 345px;
}
.img-class {
  height: 148px;
}
</style>

<style lang="scss">
.item-input {
  .el-input {
    float: left;
    width: 59%;
  }
}
.of-hidden {
  margin-left: -10px;
}
.main-contentTop1 {
  height: 239px;
  background: url("../../assets/login/renzheng_bg.png") center;
  background-size: cover;
}
.main-TopContentBottom {
  width: 1110px;
  height: 130px;
  background: white;
  margin: auto;
  margin-top: 0px;
  border-bottom: 1px solid gray;
}
.steps {
  padding: 34px 40px 24px 40px;
  margin-top: -60px;
  .el-step__icon {
    width: 32px;
    height: 32px;
  }
}
</style>
