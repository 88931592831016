<!--隐私协议 -->
<template>
      <div>
        <h5 style="line-height:23pt; margin:0pt; text-align:center"><span
                style="font-family:宋体; font-size:16pt; font-weight:bold">隐私协议</span></h5>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; orphans:0; text-align:center; widows:0"><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">版本：V1.0</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; orphans:0; text-align:center; widows:0"><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">发布时间：  </span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">2022</span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">年 </span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">6</span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold"> 月 </span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">22</span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold"> 日</span></p>
        <p style="font-size:12pt; line-height:150%; margin:7.8pt 0pt; orphans:0; text-align:center; widows:0"><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">生效时间：</span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">  {{ date.year }}</span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">年 </span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">{{ date.month }}</span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold"> 月 </span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold">{{ date.day }}</span><span
                style="color:#333333; font-family:宋体; font-size:12pt; font-weight:bold"> 日</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
        <h5 style="line-height:23pt; margin:0pt; text-align:justify"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">引言：</span></h5>
        <p style="line-height:23pt; margin:0pt; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">银壹供应链平台尊重并保护用户隐私，平台将按照本隐私协议（以下简称“本规则”）收集、存储、保护、使用及对外提供您的个人信息。本规则包含了我们收集、存储、保护、使用和对外提供您的个人信息的条款，我们建议您完整地阅读本规则，以帮助您了解维护自己隐私权的方式。为了使您充分理解本规则，本规则中与您的权益存在或可能存在重大关系的条款，</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">我们已采用粗体字进行标注提示您注意</span><span
                style="font-family:宋体; font-size:12pt">。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">我们特别提醒您，当您点击“同意”按钮，则意味着您对本协议各项条款的含义及相应法律后果已全部知悉并完全理解，认同并自愿遵守本协议，同意并许可我们按照本协议内容合法使用（包括但不限于整理、储存、收集等）和保护您的个人信息。</span>
        </p>
        
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">我们收集、使用您的信息是出于遵守国家法律法规的规定以及向您提供服务及提升服务质量的目的（包括支持我们开发新产品或完善已有产品功能，为您和其他用户提供更为优质的服务）。若您向我们提供了本规则中列明的信息，您可使用更多的银壹供应链平台服务，和（或）享受更便捷的客户服务，和（或）让我们更好地保护您的账户及资金安全，和（或）避免我们无法及时与您取得联系而产生对您不利的影响等。</span>
        </p>
        <h5 style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">我们如何收集个人信息</span></h5>
        <p style="line-height:23pt; margin:0pt; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">当您访问银壹供应链平台网站及其相关网站时，或您使用银壹供应链平台服务时，您主动向我们提供信息，同时我们也可能会自行、或从银壹供应链平台关联公司、商业合作伙伴处收集您的信息，以此补充完善我们自己收集的信息。</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">您对此完全知悉，认可并无异议</span><span
                style="font-family:宋体; font-size:12pt">。</span></p>
        <h5 style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">我们可能收集的个人信息</span></h5>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">我们提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">为使您便捷了解我们有关您的个人信息收集、使用的主要情况，我们制定了《个人信息收集与使用清单》。点击查看《</span><span
                style="font-family:宋体; font-size:12pt; font-style:italic; font-weight:bold;cursor: pointer; text-decoration:underline" @click="showDialog('1')">个人信息收集与使用清单</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">》。</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">1、为了遵守法律法规及监管规定的要求，履行反洗钱义务，进行实名制管理，以及向您提供更个性化、更便捷的服务，我们需要识别您的身份。在您注册银壹供应链平台账户或使用银壹供应链平台提供的各项服务时，您可能要向我们提供一些个人信息，例如您的姓名、身份证明、地址、电话号码和电子邮件地址、生物特征等信息及相关附加信息（如您所在的省份和城市、邮政编码等）。根据相关法律法规的规定，如果您需通过身份基本信息多重交叉验证，您还需提供银行卡信息并进行绑定，如您未提供前述信息或绑定银行卡，您将无法使用银壹供应链平台提供的需通过多重身份验证方能使用的服务。同时，为了验证您提供信息的准确性和完整性，我们会与合法留存您的信息的国家机关、金融机构、企事业单位进行核对；如在验证核对过程中我们需要向前述验证机构收集您的信息，我们会依照法律法规的规定要求相关验证机构说明其个人信息来源，并对其个人信息来源的合法性予以确认。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">2、如您使用的</span><span
                style="font-family:宋体; font-size:12pt">银壹供应链平台</span><span
                style="font-family:宋体; font-size:12pt">服务需与您的银行账户或其他支付工具的账户关联方能实现时，您需要向我们提供您的银行账户信息或其他支付工具的账户信息。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（</span><span
                style="font-family:宋体; font-size:12pt">1）您在开通</span><span
                style="font-family:宋体; font-size:12pt">银壹供应链平台</span><span
                style="font-family:宋体; font-size:12pt">账户，将您的银行卡与</span><span
                style="font-family:宋体; font-size:12pt">银壹供应链平台</span><span
                style="font-family:宋体; font-size:12pt">账户绑定时，需向我们提供您的银行卡信息。</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（</span><span
                style="font-family:宋体; font-size:12pt">2）为了您能使用快捷支付服务，实现使用银行卡对</span><span
                style="font-family:宋体; font-size:12pt">银壹供应链平台</span><span
                style="font-family:宋体; font-size:12pt">账户余额的充值、提现以及基于银行卡的消费、转账功能，您需要将您的银行卡与您的</span><span
                style="font-family:宋体; font-size:12pt">银壹供应链平台</span><span
                style="font-family:宋体; font-size:12pt">账户进行绑定，您需要提供您的</span><span
                style="font-family:宋体; font-size:12pt">企业名称</span><span
                style="font-family:宋体; font-size:12pt">、银行卡号</span><span
                style="font-family:宋体; font-size:12pt">、开户行信息</span><span
                style="font-family:宋体; font-size:12pt">、</span><span
                style="font-family:宋体; font-size:12pt">统一社会信用</span><span
                style="font-family:宋体; font-size:12pt">、银行预留手机号</span><span
                style="font-family:宋体; font-size:12pt">等信息</span><span
                style="font-family:宋体; font-size:12pt">，我们会将该等信息向发卡银行进行验证。如您不提供上述信息，将无法使用快捷支付服务，但不影响您使用我们提供的其他功能。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（</span><span
                style="font-family:宋体; font-size:12pt">3）为了您能使用转账功能，明确并执行您的支付指令，您需要按照支付页面的提示提供收款人账户、收款人姓名、转账金额。如您不提供上述信息，将无法使用转账功能，但不影响您使用我们提供的其他功能。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">3、为便于您查询您的交易状态或历史记录，也为了遵守法律法规的规定，我们会保存您使用银壹供应链平台服务产生的交易信息（包括交易编号、交易双方姓名、交易金额、交易时间），并严格按照法律法规的规定对这些信息进行妥善保管。如您不同意提供前述信息，则可能导致交易无法完成。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">4、为了充分保护您的账户安全，当您访问银壹供应链平台网站及其相关网站时，或使用银壹供应链平台提供的服务时，我们可能会记录您操作的相关信息，包括但不限于您的计算机IP地址、设备标识符、硬件型号、操作系统版本、您的位置以及其他与银壹供应链平台服务相关的日志信息，这些信息可帮助我们更好地识别您的身份以及保护您的账户安全，例如您的账户在可疑操作环境下登录的，银壹供应链平台系统可能监控到您的账户风险，采取一些措施避免您的资金损失。如您不同意提供前述信息，则可能导致无法完成风控验证。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">5、为了保障您的账户和资金安全，在您需要找回密码、挂失账户、冻结交易资金、索取电子回单时，我们需要验证您的身份，您需要向我们提供一些可识别您身份的信息如身份证件影印件、手持身份证照片、联系方式、能够证明交易发生的凭证、能够证明您对银壹供应链平台账户和账户资金拥有合法权利的证明文件等。如您不同意提供前述信息，则可能导致无法完成找回密码、挂失账户、冻结交易资金、索取电子回单等操作，但不影响您完成其他操作或使用我们提供的其他功能。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">6、日志信息，指您使用我们的服务时，系统可能通过cookies、web
                beacon或其他方式自动采集的技术信息，包括：设备或软件信息，例如您的移动设备、网页浏览器或用于接入我们服务的其他程序所提供的配置信息、您的IP地址和移动设备所用的版本和设备识别码；在使用我们服务时搜索或浏览的信息，例如您使用的网页搜索词语、访问的社交媒体页面url地址，以及您在使用我们服务时浏览或要求提供的其他信息和内容详情；有关您曾使用的移动应用和其他软件的信息，以及您曾经使用该等移动应用和软件的信息；您通过我们的服务进行通讯的信息，例如曾通讯的账号，以及通讯时间、数据和时长；您通过我们的服务分享的内容所包含的信息（元数据），例如拍摄或上传的共享照片或录像的日期、时间或地点等。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">7、位置信息，指您开启设备定位功能并使用我们基于位置提供的相关服务时，收集的有关您位置的信息，包括：您通过具有定位功能的移动设备使用我们的服务时，通过GPS或WiFi等方式收集的您的地理位置信息；您或其他用户提供的包含您所处地理位置的实时信息，例如您提供的账户信息中包含的您所在地区信息，您或其他人上传的显示您当前或曾经所处地理位置的共享信息，您或其他人共享的照片包含的地理标记信息；您可以通过关闭定位功能，停止对您的地理位置信息的收集。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">8、除上述信息外，我们还可能为了提供服务及改进服务质量的合理需要而收集您的其他信息，包括您与我们的客户服务团队联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与银壹供应链平台关联公司互动时我们收集的相关信息。与此同时，为提高您使用银壹供应链平台提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会通过了解一些您的网络使用习惯、您常用的软件信息等手段来判断您账户的风险，并可能会记录一些我们认为有风险的URL。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">9、根据相关法律法规及国家标准，在以下情形中，我们可能会依法收集并使用您的个人信息且无需征得您的同意:</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（1）与国家安全、国防安全直接相关的；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（2）与公共安全、公共卫生、重大公共利益直接相关的；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（4）出于维护您或他人的生命、财产等重大合法权益但又很难得到您本人同意的；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（5）所收集的个人信息是您自行向社会公众公开的；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（6）从合法公开披露的信息中收集个人信息，例如：合法的新闻报道、政府信息公开等渠道；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（7）根据您的要求签订和履行合同所必需的；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（8）用于维护所提供的服务的安全稳定运行所必需的，例如：发现、处置产品或服务的故障；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（9）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（10）法律法规规定的其他情形。</span></p>
        <h5 style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">我们如何使用Cookie</span></h5>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">为使您获得更轻松的访问体验，您访问银壹供应链平台网站或使用银壹供应链平台提供的服务时，我们可能会通过小型数据文件识别您的身份，这么做主要是为了帮助判断您的账户安全。这些数据文件可能是Cookie，Flash
                Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">请您理解，我们的某些服务只能通过使用“Cookie”才可得到实现。如果您拒绝银壹供应链平台的Cookie，可能会影响您对银壹供应链平台服务的使用，或是您无法享受到更加安全、快捷、顺畅、个性化的服务，但不影响您正常使用我们提供的其他服务。</span>
        </p>
        <h5 style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">我们如何保护和存储个人信息</span></h5>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们建立了专门的管理制度、流程和组织以保障您的信息安全，我们对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">银壹供应链平台会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，尽力做到使您的信息不被泄漏、毁损或丢失。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">您的信息存储于中国的服务器上，为了安全及备份的需要，银壹供应链平台可能将您的信息和资料储存到银壹供应链平台关联公司的服务器上。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">我们制定了个人信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。若发生个人信息泄露等安全事件，我们将及时启动应急安全预案，并按照法律法规的要求，及时将事件相关情况以推送通知或公告等方式告知您。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">请您务必妥善保管好您的银壹供应链平台账号及其他身份要素。您在使用银壹供应链平台服务时，我们会通过您的账号及其他身份要素来识别您的身份。一旦您泄漏了前述信息，您可能会丢失您的信息，并可能会蒙受损失，产生对您不利的法律后果。如您发现银壹供应链平台账号及/或其他身份要素因任何原因可能或已经泄露时，您应立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">在您终止使用银壹供应链平台服务后，我们会停止对您的信息的收集和使用，法律法规或监管部门另有规定的除外。如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一推送告知或直接官网公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。</span>
        </p>
        <h5 style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">我们可能如何使用个人信息</span></h5>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">因收集您的信息是出于遵守国家法律法规的规定以及向您提供服务及提升服务质量的目的，为了实现这一目的，我们会把您的信息用于下列用途：</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">1、向您提供银壹供应链平台的各项服务，并维护、改进这些服务。</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">2、核查信息的准确性，并与第三方进行验证。例如，将您向我们提交的身份信息与身份验证的服务机构进行验证。</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">3、为使您知晓自己使用银壹供应链平台服务的情况或了解银壹供应链平台的服务，向您发送服务状态的通知、营销活动及其他商业性电子信息。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">4、对银壹供应链平台用户的身份数据、交易信息等进行综合统计、分析或加工，并出于销售、奖励或为了让您拥有更广泛的社交圈的需要而使用、共享或披露；例如我们可能会统计某个时间段注册银壹供应链平台账户的新用户，对这些新用户提供专享的优惠活动。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">5、预防或禁止非法的活动。</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">6、在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">7、软件认证或管理软件升级或让您参与有关我们产品和服务的调查。</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">8、经您许可的其他用途。</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">为了让您有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某一项服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他服务。例如，在您使用我们的一项服务时所收集的信息，可能在另一服务中用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。如果我们在相关服务中提供了相应选项，您勾选了，视为您授权我们将该服务所提供和储存的信息用于我们的其他服务。</span>
        </p>
        <h5 style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">您如何对外提供个人信息</span></h5>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">1、共享</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">我们对您的信息承担保密义务，除法律法规及监管部门另有规定外，我们仅在下列情况下将您的信息与第三方共享，第三方包括我们的关联公司、合作金融机构以及其他合作伙伴。在将信息提供给第三方前，我们将尽商业上合理的努力评估该第三方收集信息的合法性、正当性、必要性。我们会与第三方签订相关法律文件并要求第三方处理您的个人信息时遵守法律法规，尽力要求第三方对您的信息采取保护措施。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">我们制定了《第三方共享及SDK清单》向您清晰、简洁的介绍我们主要的有关您个人信息共享及接入的软件工具开发包（SDK）的情况。点击查看《</span><span
                style="font-family:宋体; font-size:12pt; font-style:italic; font-weight:bold;cursor: pointer; text-decoration:underline" @click="showDialog('2')">第三方共享及SDK清单</span><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">》。</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（1）获得您的同意或授权。</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（2）为了向您提供或推荐服务、产品，或为了向您提供更完善的服务，或者为了让您拥有更广泛的社交体验，我们会与包括银壹供应链平台关联公司在内的第三方共享您的相关信息。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（3）某些情况下，只有共享您的信息，才能提供您需要的服务和（或）产品，或处理您与他人的交易纠纷或争议。例如，与您交易的相对方或您本人否认交易为本人操作时，我们可能会将您提交的凭证、签收单据，或在银壹供应链平台系统中的交易信息等信息共享给银行或您的交易相对方，以证明交易的真实存在，维护您的合法权益。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（4）为了判断您的银壹供应链平台账户或交易是否安全。</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（5）某些服务和（或）产品由我们的合作伙伴提供或由我们与合作伙伴、供应商共同提供，我们会与其共享提供服务和（或）产品需要的信息。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（6）我们与第三方进行联合推广活动，我们可能与其共享活动过程中产生的、为完成活动所必要的个人信息，如参加活动的用户数、中奖名单、中奖人联系方式等，以便第三方能及时向您发放奖品。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（7）为了便于您与您的好友进行更加灵活、安全的资金往来，避免因手动输入银壹供应链平台登录名错误而产生损失，当您的银壹供应链平台账户所关联的手机号码被保存在他人手机通讯录中时，您的银壹供应链平台登录名可以被通讯录存有该手机号码的好友查询到。本规则中所称的“银壹供应链平台账户关联的手机号码”，是指作为银壹供应链平台登录名的手机号码或银壹供应链平台账户绑定的手机号码。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（8）为维护银壹供应链平台及银壹供应链平台关联公司和其他银壹供应链平台用户的合法权益。</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（9）根据法律规定及合理商业习惯，在我们计划与其他公司合并或被其收购或进行其他资本市场活动（包括但不限于IPO，债券发行）时，以及其他情形下我们需要接受来自其他主体的尽职调查时，我们会把您的信息提供给必要的主体，但我们会通过和这些主体签署保密协议等方式要求其对您的个人信息采取合理的保密措施。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（10）为了便于您与您的好友进行更加灵活、安全的资金往来，避免因手动输入银壹供应链平台登录名错误而产生损失，当您的银壹供应链平台账户所关联的手机号码被保存在他人手机通讯录中时，您的银壹供应链平台登录名可以被通讯录存有该手机号码的好友查询到。本规则中所称的“银壹供应链平台账户关联的手机号码”，是指作为银壹供应链平台登录名的手机号码或银壹供应链平台账户绑定的手机号码。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（11）同本部分第7项之目的，他人可以通过您银壹供应链平台账户关联手机号码查询到对应的银壹供应链平台登录名。</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（12）您与他人通过银壹供应链平台服务发生资金往来的，交易相对方可在付款过程中，或付款成功后可查看到您使用的银壹供应链平台账户的部分信息（包括银壹供应链平台登录名、银壹供应链平台账户对应的姓名或昵称、银壹供应链平台账户头像等，具体以实际可查看信息为准）。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（13）如您授权第三方向银壹供应链平台查询、采集您银壹供应链平台账户相关信息，我们有权在法律法规和您的授权许可范围内向第三方分享您银壹供应链平台账户的部分信息。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（14）为了维护和改善我们的服务。</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（15）根据法律法规的规定或有权机关的要求。</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">2、转让</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（1）事先获得您的明确同意；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（2）根据法律法规或强制性的行政或司法要求；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（3）在涉及资产转让、收购、兼并、重组或破产清算时，如涉及到个人信息转让，我们会向您告知有关情况，并要求新的持有您个人信息的公司、组织继续受本规则的约束。如变更个人信息使用目的时，我们将要求该公司、组织重新取得您的明确同意。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">3、公开披露</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">除在公布中奖活动名单时会脱敏展示中奖者手机号或支付宝登录名外，原则上我们不会将您的信息进行公开披露。如确需公开披露时，我们会向您告知公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的同意。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">4、根据相关法律法规及国家标准，在以下情形中，我们可能会依法共享、转让、公开披露您的个人信息无需征得您的同意：</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（1）与国家安全、国防安全直接相关的；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（2）与公共安全、公共卫生、重大公共利益直接相关的；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（5）您自行向社会公众公开的个人信息；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（6）从合法公开披露的信息中收集的个人信息，例如：合法的新闻报道、政府信息公开等渠道。</span></p>
        <h5 style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">您如何查询和管理个人信息</span></h5>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">在您使用银壹供应链平台服务期间，您可随时登录您的银壹供应链平台账户查询并管理该账户下您的个人信息，如您遇到任何障碍或疑问，可联系银壹供应链平台客服或银壹供应链平台网站在线客服。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">1. 访问您的账户信息</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（1）您可以通过银壹供应链平台官方网站登录银壹供应链平台账户，查询您的身份信息、账户信息等。</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">2.
                修改您的个人信息：您对您平台的信息有义务进行核查，若存在信息有误或更新的，应通过向平台官方联系方式申请修改，否则因信息有误导致的任何不利后果均由您个人承担。</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（1）出于安全性和身份识别的考虑或根据法律法规及监管规定，您需通过验证方可修改您已认证的姓名或已绑定的手机号码。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（2）您确认并同意，因个人信息的修改可能会影响用户资质认证，平台有权要求其作出合理说明，并审核其是否仍满足用户资质条件并可以要求其作出与之相关的整改措施（包括且不限于重新注册）。如信息修改后平台审核其确不再符合用户资格的，平台有权采取终止服务、要求用户结清平台/业务费用等可能的合理措施。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">3. 注销银壹供应链平台账户</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（1）您可以发起账户注销申请，平台将根据您在平台开展业务的履行情况综合予以审查。</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（2）银壹供应链平台账户注销完成后，您相关账户内的所有信息将被清空，我们将不会再收集、使用或共享与该账户相关的个人信息，但您在使用银壹供应链平台服务期间提供货产生的信息我们仍需按照监管要求的时间进行保存，且在该依法保存的时间内有权机关仍有权依法查询。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">4.
                如您发现我们采集、使用您个人信息的行为，违反了法律、行政法规规定或违反了与您的约定，您可通过客服电话联系我们，要求删除该违反行为下采集的您的个人信息。</span></p>
        <h5 style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">对第三方责任的声明</span></h5>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">请您注意，您的交易相对方、您访问的第三方网站经营者、您使用的第三方服务提供者和由银壹供应链平台处接受您的个人信息的第三方可能有自己的隐私权保护规则；当您查看第三方创建的网页或使用第三方开发的应用程序时，可能会发现该网页或应用程序放置的Cookie或像素标签。同样，这些第三方可能会放置他们自己的Cookie或像素标签，这些Cookie或标签不受我们的控制，而且它们的使用不受本规则的约束。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">我们会尽商业上的合理努力去要求这些主体对您的个人信息采取保护措施，但我们无法保证这些主体一定会按照我们的要求采取保护措施，亦不对这些主体的行为及后果承担任何责任。如果您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益</span><span
                style="font-family:宋体; font-size:12pt">。</span></p>
        <h5 style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">特别注意事项</span></h5>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">我们仅向年满十六周岁的人士提供服务，如果您未满十六周岁，请您不要向我们提供任何个人信息，也不建议您使用任何银壹供应链平台提供的服务。</span>
        </p>
        <h5 style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">本规则适用范围</span></h5>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">本规则属于《银壹供应链平台会员服务协议》不可分割的一部分，本规则与《银壹供应链平台会员服务协议》的约定不一致的，以本规则为准。除需适用银壹供应链平台其他单独制订的隐私权规则的服务外，本规则适用于您访问银壹供应链平台网站及其相关网站、银壹供应链平台移动设备客户端和银壹供应链平台提供的任一服务。</span>
        </p>
        <h5 style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">本规则如何修订及更新</span></h5>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">我们可能随时会对本规则进行变更，由于银壹供应链平台的用户群过于庞大和分散，因此如发生变更，我们将选择在银壹供应链平台网站以公告的方式予以公布以便您即使了解本隐私协议的最新版本。但未经您明确同意，我们不会限制您按照本协议所享有的权利。</span>
        </p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">出现重大变化情形时，我们会适时对本隐私权规则进行更新，重大变化的情形包括但不限于以下情况：</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（1）我们的基本情况发生变化，例如：兼并、收购、重组引起的所有者变更；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（2）收集、存储、使用个人信息的范围、目的、规则发生变化；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（3）对外提供个人信息的对象、范围、目的发生变化；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（4）您访问和管理个人信息的方式发生变化；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（5）数据安全能力、信息安全风险发生变化；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（6）用户询问、投诉的渠道和机制，以及外部纠纷解决机构及联络方式发生变化；</span></p>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">（7）其他可能对您的个人信息权益产生重大影响的变化。</span></p>
        <h5 style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt; font-weight:bold">联系我们</span></h5>
        <p style="line-height:23pt; margin:0pt; text-align:justify; text-indent:21pt"><span
                style="font-family:宋体; font-size:12pt">如您对本规则存在任何疑问，或任何相关的投诉、意见，<span>您可通过平台公共邮箱gxyinyi@sina.com与银壹供应链平台联系。</span></span></p>
        <p style="line-height:23pt; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
        <el-dialog
      id="dialog"
      :modal="false"
      class="agreement-dialog"
      :visible.sync="agreementDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="60%"
      center
    >
      <div class="agreement-container">
        <selfInfoGather v-if="this.fileType == '1'"></selfInfoGather>
        <sharedList v-if="this.fileType == '2'"></sharedList>
      </div>
      <span slot="footer" >
        <el-button type="primary" @click="closeDialog">关闭</el-button>
      </span>
    </el-dialog>
    </div>
    
</template>
<script>
import selfInfoGather from "./selfInfoGather.vue";
import sharedList from "./sharedList.vue";
export default {
        components:{
            selfInfoGather,
                sharedList
        },
        data(){
                const date = new Date();
                return {
                        agreementDialog:false,
                        fileType:'',
                        date: {
                                year: date.getFullYear(),
                                month: date.getMonth() + 1,
                                day: date.getDate()
                        }               
                }
        },
        methods:{
                showDialog(v) {
                       this.agreementDialog = true
                       this.fileType = v
                },
                closeDialog() {
                        this.agreementDialog = false
                }
        }
}
</script>
<style lang="scss" scoped>
.agreement-dialog {
  .el-dialog {
    height: 700px;
    overflow: auto;
    &__header {
      display: none;
    }
  }
}
</style>

