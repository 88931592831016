<template>
  <div class="content">
    <div class="card-hd infotitle">
      
签署质押合同

      <el-button class="page-head-back" size="small" @click="goBack">返回</el-button>
    </div>

    <section class="info-card-A" style="padding-top: 32px;">
      <div class="card-hd sub-title">
        <span class="txbst">资产质押合同</span>
      </div>
      <div class="card-bd card-bd-row">
        <el-row>
          <el-col :span="8" class="lb_body">
            <span class="info-key">甲方:</span>
            <span class="info-value">{{fundCorp.corpName}}</span>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key">社会统一信用代码:</span>
            <span class="info-value">{{fundCorp.creditCode}}</span>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key">企业法人:</span>
            <span class="info-value">{{fundCorp.legalName}}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" class="lb_body">
            <span class="info-key">乙方:</span>
            <span class="info-value index">{{financeCorp.corpName}}</span>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key">社会统一信用代码:</span>
            <span class="info-value">{{financeCorp.creditCode}}</span>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key">企业法人:</span>
            <span class="info-value">{{financeCorp.legalName}}</span>
          </el-col>
        </el-row>


        <div class="card-line"></div>
      </div>
      <div class="card-hd sub-title">
        <span class="txbst">合同信息</span>
      </div>
      <div class="maincontin">
        <pdf
        v-if="payableBillFinanceFile.fileUrl"
          class="pdf"
          :src="api.getImgURL(payableBillFinanceFile.fileUrl)"
          :page="currentPage"
          @num-pages="pageCount=$event"
          @page-loaded="currentPage=$event"
          @loaded="loadPdfHandler"
        ></pdf>
      </div>
      <el-row>
        <el-col :span="10" class="lb_body">
          <span class="info-key"></span>
          <span class="info-value"></span>
        </el-col>
        <el-col :span="8" class="lb_body">
          <p>
            <span @click="changePdfPage(0)" class="turn" :class="{grey: currentPage==1}">上一页</span>
            {{currentPage}} / {{pageCount}}
            <span
              @click="changePdfPage(1)"
              class="turn"
              :class="{grey: currentPage==pageCount}"
            >下一页</span>
          </p>
        </el-col>
        <el-col :span="6" class="lb_body">
          <span class="info-key"></span>
          <span class="info-value"></span>
        </el-col>
      </el-row>
      <div class="card-hd" style="height: 150px;" v-if="$route.query.isshowbtn==1">
        <el-row>
          <el-col :span="8" class="lb_body">
            <span class="info-key"></span>
            <span class="info-value"></span>
          </el-col>
          <el-col :span="8" class="lb_body">
            <el-checkbox v-model="checked">我已阅读并完全了解合同的所有义务和权利</el-checkbox>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key"></span>
            <span class="info-value"></span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" class="lb_body">
            <span class="info-key"></span>
            <span class="info-value"></span>
          </el-col>
          <el-col :span="8" :offset="1" class="lb_body">
            <el-button
              type="primary"
              style="width:238px;margin-top: 10px; "
              @click.native="submit"
            >电子签章签约</el-button>
          </el-col>
          <el-col :span="8" class="lb_body">
            <span class="info-key"></span>
            <span class="info-value"></span>
          </el-col>
        </el-row>
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";
import api from "@/api/api";
import { baseURL } from "@/api/sys";
import pdf from "vue-pdf";

export default {
  components: {
    pdf
  },
  data() {
    return {
      financInfo: {}, //基本信息
      financInfoFinance: {},
      baseURL: baseURL,
      api: api,
      payableBillFinanceId: "",
      financeCorp: {},
      fundCorp: {},
      payableBillFinanceFile: {},
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      fileType: "pdf", // 文件类型
      src: "", // pdf文件地址
      checked: false,
      payableBillFinanceNo: "", //融资编号
      fundCorpId: "", //资方Id
      checkCode: "", //验证码
      projectCode:'',
      receivableAccountFinanceId:'',
      fundCorpId:'',
      corpType:'', // 区分核心企业 和 资金机构
      signSource: 1,
    };
  },
  created() {

    this.getSigninfo();
  },
  mounted() {},
  methods: {
    moment: moment,
    getSigninfo() {
      api
        .getAbsSignContract({
          absAccountId: this.$route.query.id,
          signType: this.$route.query.type
        })
        .then(res => {
          console.log("res ===>", res.datas);
          if (res.success) {
            this.financeCorp = res.datas.financeCorp;
            this.fundCorp = res.datas.fundCorp;
            this.payableBillFinanceFile = res.datas.financeFile;
          }else{
              this.$message.error(res.message)
          }
        });
    },
    getFiles() {},
    goBack() {
      this.$router.go(-1); //返回上一层
    },
    // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
    changePdfPage(val) {
      // console.log(val)
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--;
        // console.log(this.currentPage)
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        this.currentPage++;
        // console.log(this.currentPage)
      }
    },

    // pdf加载时
    loadPdfHandler(e) {
      this.currentPage = 1; // 加载的时候先加载第一页
    },

    submit() {
      var user = JSON.parse(sessionStorage.getItem("user"));
      //电子签约
      if(!this.checked){
        this.$message.error('请同意协议')
        return
      }
      let param = {
        absAccountId: this.$route.query.id,
        signSource:JSON.parse(sessionStorage.user).corpType==1?2:1,
        signType:this.$route.query.type,
        absPlanId:this.$route.query.absPlanId

      };
              api.postabsSign(param).then(res => {
              if (res.success) {
                       this.$alert(res.message, '', {
                            confirmButtonText: '确定',
                            callback: action => {
                                    history.go(-1);
                            }
                        });
                                        


                  } else {
                    this.$message.error(res.message);
                  }
                });
           
    //   api.SignSendMessage(param).then(res => {

    //     if (res.success) {
    //       if (res.datas.sendMessage) {
   
    //         this.$message.success('发送成功');
    //         this.projectCode = res.datas.projectCode;
    //         var user = JSON.parse(sessionStorage.getItem("user"));
    //         let meessage =
    //           "提示信息：平台向您尾号为" +
    //           user.phone +
    //           "的手机号码发送了验证短信。请在下方输入框中输入短信中的验证码";
    //         this.$prompt(meessage, "提示", {
    //           confirmButtonText: "确定",
    //           cancelButtonText: "取消",closeOnClickModal:false
    //           // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
    //           // inputErrorMessage: "邮箱格式不正确"
    //         })
    //           .then(({ value }) => {
    //             console.log("value ====>", value);
    //             let param = {
    //               receivableAccountFinanceId: this.receivableAccountFinanceId,
    //               signSource: this.signSource, //1 融资企业  2  资方
    //               checkCode: value,
    //               projectCode: this.projectCode
    //             };
    //             api.AccountFinanceSign(param).then(res => {
    //           if (res.success) {
    //                  this.$alert(res.message);
  

    //               } else {
    //                 this.$message.error(res.message);
    //               }
    //             });
    //           })
    //           .catch(() => {});
    //       } else {
    //         let param = {
    //           receivableAccountFinanceId: this.receivableAccountFinanceId,
    //           signSource: this.signSource, //1 融资企业  2  资方
    //           checkCode: "",
    //           projectCode:"",
    //         };
    //         api.AccountFinanceSign(param).then(res => {
    //           if (res.success) {
    //               this.$alert(res.message, '', {
    //                   confirmButtonText: '确定',
    //                   callback: action => {
    //                   history.go(-1);
    //           }
    //           });
    //           } else {
    //             this.$message.error(res.message);
    //           }
    //         });
    //       }
    //     } else {
    //       this.$message.error(res.message);
    //     }
    //   });
    }
  }
};
</script>

<style lang='scss' scoped>
@import "../../styles/moreDetail/moudleDetail.scss";

.content {
  width: 100%;
  height: 100%;
  background-color: white;

  .page-head-back {
    float: right;
    margin-right: 40px;
    margin-top: 16px;
  }
  .sub-title {
    border-bottom: none;
    height: 40px;
    font-size: 16px;
    color: #262626;
    padding: 0;
  }
  .card-line {
    height: 20px;
    border-bottom: 1px #e6e6e6 solid;
  }
  .img-slide {
    background: #4390ee;
    width: 192px;
    height: 144px;
    margin-left: 20px;
  }
  .swiper-slide {
    line-height: 144px;
    color: #fff;
    font-size: 36px;
    text-align: center;
  }
  .row {
    height: 45px;
    .el-input {
      width: 90%;
    }
  }
  .pdf {
    display: block !important;
    max-width: 1000px !important;
    margin: auto !important;
  }
}
</style>
