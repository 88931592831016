<!-- 票据审批管理 -->
<template>
    <layout-main-view
      :breadcrumb="breadcrumblist"
      :tabOptions="tabOptions"
      @tabclick="tabclick"
    >   
        <!-- 右上角按钮 -->
        <div class="rightInfo">
            <el-button type="primary" @click="exportStatement" :disabled="isDisabled">下载对账单</el-button>
            <el-button type="danger" v-if="statementData.corpAckStatus===1" @click="openDialog(1)">账单有误</el-button>
            <el-button type="text" v-if="statementData.corpAckStatus===3" @click="openDialog(2)">错误申报详情</el-button>
        </div>

        <div class="reminder">
            <div class="reminder-container">
                <img src="../../assets/u215.png" alt="">
                <span class="info">{{`温馨提示，下面为贵司在${getMonth(statementData.checkAccoutDateCn)}发生的银e券对账单。`}}</span>
            </div>
        </div>
        <created-Easypay v-if="corpType !=1 && showtab == 1" :statementData="statementData" :updateStatementData="updateStatementData"></created-Easypay>
        <received-Easypay v-if="corpType !=1 && showtab == 2" :statementData="statementData" :updateStatementData="updateStatementData"></received-Easypay>
        <transfered-Easypay v-if="corpType !=1 && showtab == 3" :statementData="statementData" :updateStatementData="updateStatementData"></transfered-Easypay>
        <financing-Easypay v-if="corpType !=1 && showtab == 4" :corpType="corpType" :statementData="statementData" :updateStatementData="updateStatementData"></financing-Easypay>  
        <financing-Easypay v-if="corpType ==1 && showtab == 1" :corpType="corpType" :statementData="statementData" :updateStatementData="updateStatementData"></financing-Easypay>  
        
        <!-- 错误表单对话框 -->
        <el-dialog class="common-Dialog" title="错误申报信息" :visible.sync="dialogVisible" width="800px" left>
            <!-- <div class="common-top">
                <h2 class="top-title">错误申报信息</h2>
                <img class="top-close" src="../../assets/u167.png" alt="" @click="dialogVisible=false">
            </div> -->
            <div class="common-title">
                <i></i>
                <span class="fl" style="color: #333;">错误信息</span>
            </div>

            <div class="common-info">
                <img src="../../assets/u215.png" alt="">
                <div>
                    <p>{{`您申诉${getMonth(statementData.checkAccoutDateCn)}的账单有误，请详细描述错误。以便快速核对`}}</p>
                </div>
            </div>
            
            <el-form class="errorForm" :model="errorForm" :rules="rules" ref="errorForm" label-position="left">
                <el-form-item label="疑似错误模块:" label-width="120px" prop="checkboxValue">
                    <el-checkbox-group
                    :disabled="statementData.corpAckStatus===3? true : false"
                     v-model="errorForm.checkboxValue">
                        <el-checkbox 
                        v-for="item in checkboxOptions"
                        :label="item.value" true-label
                        :key="item.label"
                        >{{item.label}}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item class="errorInfo"  label="错误详情信息:" prop="errorInformation">
                    <el-input
                    type="textarea"
                    resize="none"
                    :disabled="statementData.corpAckStatus===3? true : false"
                    placeholder="请尽可能详细的录入疑似错误的账单，以便快速核对。"
                    v-model="errorForm.errorInformation">
                    </el-input>
                </el-form-item>
            </el-form>

            <div v-if="statementData.corpAckStatus===1" slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitError">提交</el-button>
            </div>

            <div v-if="statementData.corpAckStatus===2||statementData.corpAckStatus===3" slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">返 回</el-button>
            </div>
        </el-dialog>
    </layout-main-view>
</template>
<script>
    import createdEasypay from './createdEasypay'
    import receivedEasypay from './receivedEasypay'
    import transferedEasypay from './transferedEasypay'
    import financingEasypay from './financingEasypay'
    import api from "@/api/api";

    export default {
         components: {
            'created-Easypay': createdEasypay,
            'received-Easypay': receivedEasypay,
            'transfered-Easypay': transferedEasypay,
            'financing-Easypay': financingEasypay
        },
        data() {
            return {
                breadcrumblist: [],
                showtab: 1,
                tabOptions: [
                    { label: "银e券开立", value: "1", path: "/easypay/statementManageDetail/1" },
                    { label: "银e券接收", value: "2", path: "/easypay/statementManageDetail/2" },
                    { label: "银e券转让", value: "3", path: "/easypay/statementManageDetail/3" },
                    { label: "银e券融资", value: "4", path: "/easypay/statementManageDetail/4" },
                ],
                statementData: {},
                dialogVisible: false,
                errorForm: {
                    checkboxValue: [],
                    errorInformation: '',
                    payableBillCheckAccountId: ''
                },
                rules: {
                    checkboxValue: [
                        { type: 'array', required: true, message: "请选择至少一种错误模块类型", trigger: "change" }
                    ],
                    errorInformation: [
                        { required: true, message: "请输入错误账单", trigger: "blur" }
                    ]
                },
                checkboxOptions: [
                    { label: "银e券开立", value: 1 },
                    { label: "银e券接收", value: 2 },
                    { label: "银e券转让", value: 3 },
                    { label: "银e券融资", value: 4 },
                ],
                checkboxValue: [],
                statementData: {},
                corpType: '',
                isDisabled: false,
            }
        },
        created() {
            // corpType  1资金机构 2.核心企业 3.一般企业  6.担保企业'
            this.corpType = JSON.parse(sessionStorage.user).corpType
            if(this.corpType === 1) {
                this.tabOptions = [
                    { label: "银e券融资", value: "1", path: "/easypay/statementManageDetail/1" },
                ]
                this.checkboxOptions = [
                    { label: "银e券融资", value: 4 },
                ]
            }
            // 获取对账单
            this.statementData = JSON.parse(sessionStorage.getItem('statementData'))

        },
        mounted(){
            this.showtab = this.$route.params.state;
        },
        methods: {
            tabclick(val) {
                this.showtab = val;
            },
            openDialog (state) {
                this.errorForm.checkAccountType = ''
                // state为2则是查询
                if(state === 2) {
                    let params = {
                        payableBillCheckAccountId: this.statementData.payableBillCheckAccountId
                    }
                    api.getFeedback(params).then(res => {
                        this.loading = true
                        if(res.success) {
                            let temp = res.datas.payableBillCheckAccountFeedback
                            temp.checkAccountType.split(',').forEach(el => {
                                this.errorForm.checkboxValue.push(parseInt(el))
                            })
                            this.errorForm.errorInformation = temp.errorInformation
                            this.dialogVisible = true
                            this.loading = false
                        }
                    })
                }
                if(state === 1) { 
                    this.dialogVisible = true
                    this.$nextTick(() => {
                        this.$refs.errorForm.resetFields()
                    })
                }
            },
            submitError() {
                this.$refs.errorForm.validate((valid) => {
                    if(!valid) {
                        return false
                    }
                    if(valid) {
                        this.errorForm.checkAccountType = this.errorForm.checkboxValue.join(',')
                        this.errorForm.payableBillCheckAccountId = this.statementData.payableBillCheckAccountId
                        // 请求接口将表单信息提交到后台
                        api.addFeedback(this.errorForm).then(res => {
                             if (res.success) {
                                this.updateStatementData()
                                this.dialogVisible = false
                                this.$message.success('提交成功')
                             }else {
                                this.$message.error('提交失败')
                             }
                        })
                    }
                })
            },
            updateStatementData() {
                api.getCheckAccountListPage().then(res => {
                    if (res.success) {
                        let temp = res.datas.list.filter(el => {
                            if(el.payableBillCheckAccountId === this.statementData.payableBillCheckAccountId) {
                                return el
                            }
                        })
                        this.statementData.corpAckStatus = temp[0].corpAckStatus
                    }
                })
            },
            getMonth(month) {
                return month.slice(0, 7)
            },
            exportStatement() {
                this.isDisabled = true
                // 再定点击禁用3秒后解开
                // 期望：下载结束后解开
                setTimeout(() => {
                    this.isDisabled = false
                }, 3000)
                let params = {
                    payableBillCheckAccountId: this.statementData.payableBillCheckAccountId,
                    accountCorpType: this.statementData.accountCorpType,
                    corpName: this.statementData.corpName,
                }

                api.downloadAccount(params).then(res => {
                    if(res) {
                        const content = res
                        const blob = new Blob([content])
                        const fileName = params.corpName + '对账单.xlsx'
                        if ('download' in document.createElement('a')) { // 非IE下载
                        const elink = document.createElement('a')
                        elink.download = fileName
                        elink.style.display = 'none'
                        elink.href = URL.createObjectURL(blob)
                        document.body.appendChild(elink)
                        elink.click()
                        URL.revokeObjectURL(elink.href) // 释放URL 对象
                        document.body.removeChild(elink)
                        } else { // IE10+下载
                        navigator.msSaveBlob(blob, fileName)
                        }
                    }
                })
            }
        },
    }
</script>
<style lang="scss" scoped>
.rightInfo {
    float: right;
    margin-top: -60px;
}
.errorForm {
    padding: 0 15px;
}
.errorForm h2 {
    font-size: 18px;    
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}
.errorForm .el-row {
    margin-top: 25px;
}
// .errorTypeTitle {
//     margin-right: 20px;
//     font-size: 15px;
//     font-weight: 700;
// }
.titleInfo {
    .el-form-item__content {
        margin-left: 0!important;
    }
    .el-alert__title {
        font-size: 14px;
    }
}

.reminder{
    padding: 20px 24px 0;
    background-color: #fff;
    .reminder-container {
        display: flex;
        padding: 0 25px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        border: 1px solid #797979;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.349);
        &>img {
            width: 25px;
            height: 25px;
            margin: 8px 15px 8px 0;
        }
        .info {
            font-size: 14px;    
        }
    }
}
.common-Dialog {
  position: absolute;
  color: #333;
  .el-dialog__body {
    padding: 6px 25px 10px;
    color: #333!important;
  }
//   .el-dialog__headerbtn {
//     display: none;
//   }
  .el-form-item {
      margin-bottom: 10px;
  }
  .errorInfo {
    .errorInfo-title {
        font-size: 15px;
        font-weight: 700;
        color: #333!important;
    }
  }
  .top-title {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 4px 20px 0;
    height: 28px;
    width: 100%;
    font-size: 16px;
    // color: #fff;
    // background-color: #2937a6;
  }
  .top-close {
    position: absolute;
    top: 4px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .common-title {
    position: relative;
    height: 44px;
    line-height: 44px;
    border-bottom: 1px solid #333;
    &>i {
      position: absolute;
      top: 8px;
      left: 0;
      width: 7px;
      height: 28px;
      background-color: #d19f53;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    }
    &>.fl {
      font-size: 16px;
      font-weight: 700;
      padding-left: 16px;
    }
  }
  .common-info {
    margin: 20px 15px;
    padding: 5px 25px;
    display: flex;
    align-items: center;
    color: #333;
    border-radius: 5px;
    border: 1px solid #797979;
    box-shadow: 1px 1px 5px #00000059;
    img {
      width: 25px;
      height: 25px;
    }
    p {
      margin: 0;
      padding-left: 14px;
      &:nth-of-type(2) {
        margin-top: -5px;
      }
    }
  }
  .el-checkbox,
  .el-input {
      color: #333;
  }
  .dialog-footer {
    text-align: center;
    margin-top: -30px;
    .el-button {
      width: 140px;
      margin: 0 65px;
    }
  }
}
</style>
<style lang="scss">
.errorForm {
    textarea {
        height: 250px;
    }
    .el-form-item__label,
    .errorInfo-title {
        color: #333;
    }
}
</style>