<template>
  <layout-main-view :breadcrumb="breadcrumblist">
    <div class="common-page-dataList" style="padding: 10px 24px;">
      <data-list
        :filters="filterList"
        :isShowAll="isShowAll"
        :isHedden="isHedden"
        @data-showAll="filterShowAll"
        @data-refresh="handleRefresh"
        :total="total"
        ref="datalist">

        <el-table :data="listData" :header-cell-style="headerStyle" highlight-current-row v-loading="listLoading" style="width: 100%;">
          <el-table-column prop="agreementNo" label="协议编号" min-width="220"></el-table-column>
          <el-table-column prop="itemNo" label="融资银e券编号" min-width="230"></el-table-column>
          <el-table-column prop="feeAllAmount" label="收费金额(元)"  min-width="150">
              <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.feeAllAmount)}}</template>
          </el-table-column>
          <el-table-column prop="feeTime" label="收费日期"  min-width="180">
              <template slot-scope="scope">{{ scope.row.feeTime ? moment(scope.row.feeTime).format('YYYY-MM-DD HH:mm') : '' }}</template>
          </el-table-column>
          <el-table-column prop="applyStatus" label="开票申请"  min-width="120">
              <template slot-scope="scope">{{ scope.row.applyStatus === 1 ? "已申请" : '未申请' }}</template>
          </el-table-column>
          <el-table-column prop="openStatus" label="开票状态"  min-width="120">
              <template slot-scope="scope">{{ scope.row.openStatus === 1 ? "已开票" : '未开票' }}</template>
          </el-table-column>
          <el-table-column prop="sendStatus" label="邮寄状态"  min-width="120">
              <template slot-scope="scope">{{ scope.row.sendStatus === 1 ? "已邮寄" : '未邮寄' }}</template>
          </el-table-column>
           <el-table-column prop="payStatusDetail" label="收费状态"  min-width="120">
            
          </el-table-column>
          <el-table-column label="操作" width="250">
            <template slot-scope="scope">
              <!-- <el-button size="small" type="text" @click="openInfoDialog(scope.row, '快递信息')">发票信息</el-button> -->
              <!-- <el-button size="small" type="text" @click="openInvoiceDialog(scope.row, '申请开票')">申请开票</el-button> -->

              <!-- 未申请 -->
              <el-button v-if="scope.row.applyStatus===0&&scope.row.financePayStatus==3" size="small" type="text" @click="openInvoiceDialog(scope.row, '申请开票')">申请开票</el-button>
              <!-- 已申请未开票 -->
              <el-button v-if="scope.row.applyStatus===1&&scope.row.openStatus===0" size="small" type="text" disabled>发票信息</el-button>
              <!-- 已申请已开票 -->
              <el-button v-if="scope.row.applyStatus===1&&scope.row.openStatus===1" size="small" type="text" @click="openInfoDialog(scope.row, '发票信息')">发票信息</el-button>
              <!-- 已开票未邮寄 -->
              <el-button v-if="scope.row.applyStatus===1&&scope.row.sendStatus===0" size="small" type="text" disabled>快递信息</el-button>
              <!-- 已开票已邮寄 -->
              <el-button v-if="scope.row.applyStatus===1&&scope.row.sendStatus===1" size="small" type="text" @click="openInfoDialog(scope.row, '快递信息')">快递信息</el-button>

              <el-button size="small" type="text" @click="download(scope.row)">下载</el-button>
              <el-button size="small" type="text" @click="preview(scope.row)">详情</el-button>
              <!-- 参考银e券管理 view/base/easylzdetial.vue -->
            </template>
          </el-table-column>
        </el-table>

        <!-- 申请开票 -->
        <el-dialog class="common-Dialog invoiceDialog" title="输入开票信息" :visible.sync="invoiceDialog" width="800px" left>
          <div class="common-title">
            <i></i>
            <span class="fl">申请开票信息</span>
            <span class="fr">协议编号：{{feeApplyOpenInvoice.agreementNo}}</span>
          </div>

          <div class="common-info">
            <img src="../../assets/u215.png" alt="">
            <div>
              <p style="margin: 5px;">提交申请开票后，平台将在3-4个工作日内，将快递至邮寄地址。</p>
              <p style="margin: 5px;">邮费使用到付的方式由客户自理，请确认后点击申请开票按钮。</p>
            </div>
          </div>

          <span class="detail-title">平台收费细则: </span>
          <div class="common-form2">
            <el-form  label-width="198px" class="common-form keytext">
              <!-- 销售方科技公司 -->
              <el-row :gutter="20">
                <el-col :span="11">
                  <span class="info-key">销售方: </span>
                  <span class="info-value index">{{feeApplyOpenInvoice.sellerCorpName}}</span>
                </el-col>
                <el-col :span="8">
                  <span class="info-key">纳税人识别码: </span>
                  <span class="info-value index">{{feeApplyOpenInvoice.sellerCreditCode}}</span>
                </el-col>
                <el-col :span="5">
                  <span class="info-key">收费金额: </span>
                  <span class="info-value index">{{feeApplyOpenInvoice.selllerAmount ? $changeMoneyThousand(feeApplyOpenInvoice.selllerAmount) + "元" : ""}}</span>
                </el-col>
              </el-row>

            </el-form>
          </div>

          <div class="common-form1 applyInvoice">
            <el-form  :model="invoiceForm" :rules="rules" ref="invoiceForm" label-width="90px" resclass="common-form1 keytext">
              <!-- 购买方公司 -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <span class="info-key">单位名称: </span>
                  <span class="info-value index">{{feeApplyOpenInvoice.buyerCorpName}}</span>
                </el-col>
                <el-col :span="12">
                  <span class="info-key">纳税人识别码: </span>
                  <span class="info-value index">{{feeApplyOpenInvoice.buyerCorpCreditCode}}</span>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="24">
                  <span class="info-key">联系电话: </span>
                  <span class="info-value index">{{feeApplyOpenInvoice.buyerCorpPhone}}</span>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="24">
                  <span class="info-key">企业注册地址: </span>
                  <span class="info-value index">{{feeApplyOpenInvoice.buyerCorpAddr}}</span>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="发票类型: " prop="applyInvoiceType">
                      <el-select v-model="invoiceForm.applyInvoiceType" popper-class="account-select-option" placeholder="请选择开户行" style="" >
                          <el-option
                          v-for="item in invoiceTypeList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                          </el-option>
                      </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="电子邮件: " prop="applyEmail">
                    <el-input v-model="invoiceForm.applyEmail" placeholder="请输入电子邮件" style="width:220px"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="开户行: " prop="applyOpenBank">
                      <el-select v-model="invoiceForm.applyOpenBank" popper-class="account-select-option" placeholder="请选择开户行" style="" @change="getOpenBankNo">
                          <el-option
                          v-for="item in corpAccountPublicList"
                          :key="item.openBank"
                          :label="item.openBank"
                          :value="item.openBank">
                          </el-option>
                      </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="银行帐号: " prop="applyOpenAccount">
                      <el-select v-model="invoiceForm.applyOpenAccount" popper-class="account-select-option" placeholder="请选择账号" style="">
                          <el-option
                          v-for="item in corpAccountPublicNoList"
                          :key="item.accountNo"
                          :label="item.accountNo"
                          :value="item.accountNo"
                          >
                          </el-option>
                      </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="24">
                  <el-form-item  class="applySendAddr" label="邮寄地址: " prop="applySendAddr">
                    
                    <span v-if="isShow">系统检测到您还未设置邮寄地址，请<a href="javascript:;" @click="handelClick">点击此处</a>添加邮寄地址</span>
                    <el-select v-model="invoiceForm.applySendAddr" placeholder="请选择" style=""  @change="getAddressId">
                      <el-option
                      v-for="item in applySendAddrList"
                      :key="item.addressId"
                      :label="item.address"
                      :value="item.address">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>

          <div slot="footer" class="dialog-footer">
            <el-button @click="invoiceDialog=false">取 消</el-button>
            <el-button type="primary" @click="submit">申请开票</el-button>
          </div>
        </el-dialog>

        <!-- 信息查询 -->
        <el-dialog class="common-Dialog infoDialog" :title="infoDialogTitle" :visible.sync="infoDialog" width="800px" left>
          <div class="common-title">
            <i></i>
            <span class="fl">查看{{infoDialogTitle}}</span>
            <span class="fr">协议编号：{{feeApplyOpenInvoice.agreementNo || feeExpressInformation.agreementNo}}</span>
          </div>

          <!-- 发票信息 -->
          <div v-if="infoDialogTitle === '发票信息'">
            <div class="common-form1">
              <el-form label-width="80px" resclass="common-form1 keytext">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <span class="info-key">单位名称: </span>
                    <span class="info-value index">{{feeApplyOpenInvoice.buyerCorpName}}</span>
                  </el-col>
                  <el-col :span="12">
                    <span class="info-key">纳税人识别码: </span>
                    <span class="info-value index">{{feeApplyOpenInvoice.buyerCorpCreditCode}}</span>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <!-- <el-col :span="12">
                    <span class="info-key">联系电话: </span>
                    <span class="info-value index">{{feeApplyOpenInvoice.buyerCorpPhone}}</span>
                  </el-col> -->
                  <el-col :span="12">
                    <span class="info-key">发票类型: </span>
                    <span class="info-value index">{{applyInvoiceTypeFn(feeApplyOpenInvoice.applyInvoiceType)}}</span>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24">
                    <span class="info-key">企业注册地址: </span>
                    <span class="info-value index">{{feeApplyOpenInvoice.buyerCorpAddr}}</span>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <span class="info-key">开户行: </span>
                    <span class="info-value index">{{feeApplyOpenInvoice.applyOpenBank}}</span>
                  </el-col>
                  <el-col :span="12">
                    <span class="info-key">银行账号: </span>
                    <span class="info-value index">{{feeApplyOpenInvoice.applyOpenAccount}}</span>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <div class="space-line"></div>
            <div class="common-form1 invoiceInfo">
              <el-form label-width="80px" resclass="common-form1 keytext">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <span class="info-key">销售方: </span>
                    <span class="info-value index">{{feeApplyOpenInvoice.sellerTechCorpName}}</span>
                  </el-col>
                  <el-col :span="12">
                    <span class="info-key">纳税人识别码: </span>
                    <span class="info-value index">{{feeApplyOpenInvoice.sellerTechCorpCreditCode}}</span>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <el-table :data="sellerTechInvoiceList" border style="width: 740px;margin: 0 10px;">
              <el-table-column prop="invoiceCode" label="发票代码">
              </el-table-column>
              <el-table-column prop="invoiceNo" label="发票号码">
              </el-table-column>
              <el-table-column prop="invoiceAmount" label="发票不含税金额(元)" min-width="110px">
                <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.invoiceAmount)}}</template>
              </el-table-column>
              <el-table-column prop="amount" label="发票含税金额(元)" min-width="100px">
                <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.amount)}}</template>
              </el-table-column>
              <el-table-column prop="invoiceDate" label="开票日期">
                <template slot-scope="scope" >
                  {{scope.row.invoiceDate ? moment(scope.row.invoiceDate).format('YYYY-MM-DD'): ''}}
                  </template>
              </el-table-column>
            </el-table>

            <!-- <div class="space-line"></div>
            <div class="common-form1 invoiceInfo">
              <el-form label-width="80px" resclass="common-form1 keytext">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <span class="info-key">销售方: </span>
                    <span class="info-value index">{{feeApplyOpenInvoice.sellerInformationCorpName}}</span>
                  </el-col>
                  <el-col :span="12">
                    <span class="info-key">纳税人识别码: </span>
                    <span class="info-value index">{{feeApplyOpenInvoice.sellerInformationCreditCode}}</span>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <el-table :data="sellerInformationInvoiceList" border style="width: 740px;margin: 0 10px;">
              <el-table-column prop="invoiceCode" label="发票代码">
              </el-table-column>
              <el-table-column prop="invoiceNo" label="发票号码">
              </el-table-column>
              <el-table-column prop="invoiceAmount" label="发票不含税金额(元)" min-width="110px">
                <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.invoiceAmount)}}</template>
              </el-table-column>
              <el-table-column prop="amount" label="发票含税金额(元)" min-width="100px">
                <template slot-scope="scope">{{ $changeMoneyThousand(scope.row.amount)}}</template>
              </el-table-column>
              <el-table-column prop="invoiceDate" label="开票日期">
                <template slot-scope="scope" >
                  {{scope.row.invoiceDate ? moment(scope.row.invoiceDate).format('YYYY-MM-DD'): ''}}
                  </template>
              </el-table-column>
            </el-table> -->
          </div>
          
          <!-- 快递信息 -->
          <div v-if="infoDialogTitle === '快递信息'">
            <div class="common-form1">
              <el-form label-width="80px" resclass="common-form1 keytext">
                <el-row :gutter="20">
                  <el-col :span="24">
                    <span class="info-key">单位名称: </span>
                    <span class="info-value index">{{feeExpressInformation.corpName}}</span>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <span class="info-key">联系人: </span>
                    <span class="info-value index">{{feeExpressInformation.contactPerson}}</span>
                  </el-col>
                  <el-col :span="12">
                    <span class="info-key">联系电话: </span>
                    <span class="info-value index">{{feeExpressInformation.contactPhone}}</span>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24">
                    <span class="info-key">邮寄地址: </span>
                    <span class="info-value index">{{feeExpressInformation.postAddr}}</span>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <div class="space-line"></div>
            <div class="common-form1 expressInfo">
              <el-form label-width="80px" resclass="common-form1 keytext">
                <el-row :gutter="20">
                  <el-col :span="24">
                    <span class="info-key">快递公司: </span>
                    <span class="info-value index">{{feeExpressInformation.expressCorp}}</span>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24">
                    <span class="info-key">快递单号: </span>
                    <span class="info-value index">{{feeExpressInformation.expressNo}}</span>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24">
                    <span class="info-key">快递单据: </span>
                    <img :src="getImgURL() + feeExpressInformation.expressFilePath" :data-img="getImgURL() + feeExpressInformation.expressFilePath" @click="$imgPreview" :title="feeExpressInformation.expressFileName">
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </div>

          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="infoDialog=false">我知道了</el-button>
          </div>
        </el-dialog>
      </data-list>
    </div>
  </layout-main-view>
</template>
<script>
import api from "@/api/api";
import moment from 'moment'
import pdf from "vue-pdf";

function openDownloadDialog(url, saveName) {
  // for ie 10 and later
  if (window.navigator.msSaveBlob) {
    try {
      window.navigator.msSaveBlob(url, saveName);
    } catch (e) {
      console.log(e);
    }
  } else {
    // 谷歌浏览器 创建a标签 添加download属性下载
  console.log(typeof url == "object",url instanceof Blob, '---------url')

    if (typeof url == "object" && url instanceof Blob) {
      url = URL.createObjectURL(url); // 创建blob地址
      console.log(url, '--------------url')
    }
    var aLink = document.createElement("a");
    aLink.href = url;
    aLink.download = saveName || ""; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) {
      event = new MouseEvent("click");
    } else {
      event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
    }
    aLink.dispatchEvent(event);
  }
}

export default {
  components: {
    pdf
  },
  data() {
    return {
      breadcrumblist: ["收费协议管理"],
      isShowAll: false,
      isHedden: false,
      filterList: [
        {
          label: "协议编号: ",
          type: "input",
          field: "agreementNo",
          size: "small"
        },
         {
          label: "收费期限: ",
          type: "daterangeMore",
          field: ["feeTimeStart","feeTimeEnd"],
          size: "small"
        },
         {
            label: "收费金额: ",
            type: "input",
            field: "feeAllAmount",
            size: "small",
        },
       
      ],
      api: api,
      total: 0,
      listLoading: false,
      listData: [],
      pageNo: 1,
      pageSize: 10,
      filters: {},
      id: '',
      invoiceForm: {
        applyInvoiceType: '2',
        applyOpenBank: '',
        applyOpenAccount: '',
        applySendAddr: '',
        applySendAddrId: '',
      },
      rules: {
        applyEmail: [
          { required: true, message: '请填写电子邮件', trigger: 'blur' },
          { pattern: /^[a-zA-Z.0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '请输入合法的邮箱', trigger: 'blur'}
        ],
        applyOpenBank: [
          { required: true, message: '请选择开户行', trigger: 'change' }
        ],
        applyOpenAccount: [
          { required: true, message: '请选择银行帐号', trigger: 'change' }
        ],
        applySendAddr: [
          { required: true, message: '请选择邮寄地址', trigger: 'change' }
        ],
      },
      invoiceDialog: false,
      infoDialog: false,
      centerDialogVisible: false,
      infoDialogTitle: '',
      isShow: false,
      src: '',
      fileUrl: '',
      info: '',
      corpAccountPublicList: [], //开户行
      corpAccountPublicNoList: [], //银行账号
      invoiceTypeList: [
        {label: '企业增值税专用发票', value: '1'},
        {label: '普通发票', value: '2'},
      ], //发票类型
      applySendAddrList: [], //邮寄地址
      feeApplyOpenInvoice: {}, 
      feeExpressInformation: {},
      sellerTechInvoiceList: [],
      sellerInformationInvoiceList: [],
      feeAgreementManagementId:'',
    };
  },
  created() {
  },
  methods: {
    moment: moment,
    filterShowAll() {
      this.isShowAll = !this.isShowAll;
    },
    headerStyle({ row, rowIndex }) {  
      return { background: "#FAFAFA" };
    },
    handleRefresh(filters) {
      // (filters.review = 1), (filters.source = 2), (this.filters = filters);
      this.getLists(filters);
    },
    applyInvoiceTypeFn(val){
      let state=''
      switch (val) {
        case '1':
          state='企业增值税专用发票'
          break;
        case '2':
          state='普通发票'
          break;
        // default: 
        //   state="银e券收费协议"
        //   break;
      }
      return state
    },
    //查询
    getLists(param) {
      api.getFeeAgreementManagementListPage(param).then(res => {
        console.log(res.datas.list, '--------list')
        if (res.success) {
          this.listData = res.datas.list;
          this.total = res.datas.total;
        }
        this.listLoading = false;
      });
    },
    openInvoiceDialog(row, info) {
      this.feeApplyOpenInvoice = {}
      this.feeAgreementManagementId=row.feeAgreementManagementId
      let params = {
        feeAgreementManagementId: row.feeAgreementManagementId
      }
      // 查询申请开票信息
      api.ApplyInvoice(params).then(res => {
        console.log(res.datas.feeApplyOpenInvoice, '----------res.datas.feeApplyOpenInvoice')
        if(res.success) {
          this.feeApplyOpenInvoice = res.datas.data
        }
      })

      // 查询开户行信息
      api.GetBindAccountList('').then(res => {
        if (res.success) {
          this.corpAccountPublicList = res.datas.corpAccountPublicList;
        }
      });
      // 获取邮寄地址
      api.getCorpAddressList({corpId:row.corpId}).then(res => {
        if (res.success) {
          this.applySendAddrList = res.datas.list;
          if(this.applySendAddrList.length === 0) {
            this.isShow = true
          }else{
            this.isShow = false
          }
          this.applySendAddrList.map(el => {
            if(el.isDefault === '1') {
              this.invoiceForm.applySendAddr = el.address
              this.invoiceForm.applySendAddrId = el.addressId
            }
          })
        }
      })
      this.invoiceDialog = true


      if (this.$refs.invoiceForm !== undefined) {
        this.$refs.invoiceForm.resetFields();
      }
      // this.$nextTick(()=>{
      //     this.$refs.invoiceForm.resetFields();
      // })  
    },
    // 获取开户行账号
    getOpenBankNo() {
      this.invoiceForm.applyOpenAccount = "";
      this.corpAccountPublicNoList = [];
      api.GetBindAccountList(this.invoiceForm.applyOpenBank).then(res => {

        if (res.success) {
          this.corpAccountPublicNoList = res.datas.corpAccountPublicList
        }
      });
    },
    getAddressId() {
      this.applySendAddrList.forEach(el => {
        if(el.address === this.invoiceForm.applySendAddr) {
          this.invoiceForm.applySendAddrId = el.addressId
        }
      })
    },
    submit() {
      this.$refs.invoiceForm.validate((valid) => {

        if(valid) {
          let params = {}
          Object.assign(params, this.feeApplyOpenInvoice, this.invoiceForm,{feeAgreementManagementId:this.feeAgreementManagementId})
          console.log(params, '------params')
            
          api.ackApplyInvoice(params).then(res => {
            if(res.success) {
              this.getLists({pageNum: this.$route.query.pageNum, pageSize: this.$route.query.pageSize})
              this.invoiceDialog = false
              this.$message.success('申请开票成功')
            }else {
              this.$message.error('申请开票失败')
            }
          })
        }
      })

    },
    openInfoDialog(row, info) {
      this.sellerTechInvoiceList = []
      this.sellerInformationInvoiceList = []
      this.feeApplyOpenInvoice = {}
      this.infoDialogTitle = info
      let param = {
        feeAgreementManagementId: row.feeAgreementManagementId
      }
      if(info === '发票信息') {
         api.getEnterInvoice(param).then(res => {
          if (res.success) {
            this.feeApplyOpenInvoice = res.datas.feeApplyOpenInvoice;
            this.feeApplyOpenInvoice.techFeeInvoiceList.map(el => {
                let temp = {
                    invoiceCode: el.invoiceCode,
                    invoiceNo: el.invoiceNo,
                    invoiceAmount: el.invoiceAmount,
                    invoiceDate: el.invoiceDate,
                    amount: el.amount,
                };
                this.sellerTechInvoiceList.push(temp);
            })
            this.feeApplyOpenInvoice.infoFeeInvoiceList.map(el => {
                let temp = {
                    invoiceCode: el.invoiceCode,
                    invoiceNo: el.invoiceNo,
                    invoiceAmount: el.invoiceAmount,
                    invoiceDate: el.invoiceDate,
                    amount: el.amount,
                };
                this.sellerInformationInvoiceList.push(temp);
            })
            this.infoDialog = true;
          }
        });
      }else if(info === '快递信息') {
         api.getEnterExpressInfo(param).then(res => {
          if(res.success) {
            this.feeExpressInformation = res.datas.feeExpressInformation
            this.infoDialog = true
          }
        })
      }
    },
    getImgURL() {
      return api.getImgUrl()
    },
    preview(row) {
      let param = { feeAgreementManagementId: row.feeAgreementManagementId }
      api.downloadFeeAgreement(param).then(res => {
        if(res.success) {
           window.open(api.getImgURL(res.datas.filePath))
          console.log(res, '--------downloadFeeAgreement-res')
        }
      })
    },
    download(row) {
      let param = { feeAgreementManagementId: row.feeAgreementManagementId }
      api.downloadFeeAgreement(param).then(res => {
        if(res.success) {
          let filePath = res.datas.filePath
          let fileName = res.datas.fileName
          console.log(res, '--------downloadFeeAgreement-res')
          api.filesdownsingle({
            filePath: filePath,
          }).then(res => {
             console.log(filePath, fileName, '--------downloadFeeAgreement-res')
            console.log(res, '--------------res')
              openDownloadDialog(res, fileName)
          }).catch((err) => {
          })
        }
      })
    },
    handelClick() {
      this.$router.push('/Business/address')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/moreDetail/moudleDetail.scss";

.path {
  margin-left: 20px;
}

.header-content {
  background-color: white;
  border: 1px solid #999;
  width: 100%;
  height: 175px;

  .search-content {
    width: 100%;
    height: 36px;
    background-color: #2ea1ff;

    .text {
      line-height: 36px;
      height: 36px;
      text-align: center;
      color: #ffffff;
    }
  }

  .header-content-body {
    background-color: white;
    width: 100%;
    height: 139px;

    .itemTop {
      margin-top: 10px;
    }

    .fl {
      float: left;
    }

    .fr {
      float: right;
    }

    .header-content-body-item {
      margin-left: 45px;
      width: 40%;
      background-color: red;
      height: 30px;

      .text {
        line-height: 30px;
        height: 30px;
        text-align: center;
      }

      .text-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }
}
.common-Dialog {
  position: absolute;
  color: #333;
  .el-dialog__body {
    padding: 6px 25px 10px;
    color: #333;
  }
  .top-title {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 4px 20px 0;
    height: 28px;
    width: 100%;
    font-size: 16px;
    // color: #fff;
    // background-color: #2937a6;
  }
  .top-close {
    position: absolute;
    top: 4px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .common-title {
    position: relative;
    height: 44px;
    line-height: 44px;
    border-bottom: 1px solid #666;
    &>i {
      position: absolute;
      top: 8px;
      left: 0;
      width: 7px;
      height: 28px;
      background-color: #d19f53;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    }
    &>.fl {
      font-size: 16px;
      font-weight: 700;
      padding-left: 16px;
    }
  }
  .common-info {
    padding: 20px 5px 25px;
    display: flex;
    align-items: center;
    img {
      width: 35px;
      height: 35px;
    }
    p {
      margin: 0;
      padding-left: 14px;
      &:nth-of-type(2) {
        margin-top: -5px;
      }
    }
  }
  .common-form1 {
    width: 740px;
    margin: 10px;
    padding: 15px 30px;
    border: 1px dotted #333;
    border-radius: 5px;
    .common-form {
      margin-left: 20px;
    }
    .el-row {
      margin: 18px -10px;
    }
    .el-form-item {
      margin-bottom: 0;
    }
    .el-form-item__label {
      text-align: left!important;
      width: auto!important;
      color: #333;
    }
    .el-form-item__content {
      margin-left: 100px!important;
    }
    .el-select {
      width: 220px;
    }
    // .el-input {
    //   width: 220px!important;
    // }
    .applySendAddr .el-select {
      width: 500px!important;
    }
   
  }
  .detail-title {
    display: block;
    // width: 750px;
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #333;
  }
  .common-form2 {
    width: 750px;
    padding: 0 10px;
    .el-row {
      margin: 30px -10px;
      margin-top: 20px;
    }
  }
  .dialog-footer {
    text-align: center;
    .el-button {
      width: 140px;
      margin: 0 65px 20px;
    }
  }
  
  .space-line {
    height: 1px;
    margin-top: 10px;
    border-bottom: 1px solid #666;
  }
  .expressInfo {
    border: 0;
    .el-col {
      img {
        width: 270px;
        vertical-align: top;
      }
      span:nth-of-type(1){
        margin-right: 100px;
      }
    }
  }
  .invoiceInfo {
    padding: 0 15px;
    border: 0;
  }
}
// 下拉框选择项宽度限制
.account-select-option.el-select-dropdown {
  width: 200px!important;
}

</style>

<style lang="scss">
// .el-dialog__headerbtn {
//     display: none;
//   }
.invoiceDialog .el-form-item__label {
  text-align: left!important;
}   
.address-select-option{
  width: 500px!important;
  .el-select-dropdown__item{
    display: inline-block;
  }
  .el-select-dropdown__item span {
    min-width: 205px;
    display: inline-block;
  }
}
</style>



